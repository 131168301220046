<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Generar Posts
      </v-btn>
    </template>
    <v-card>
      <v-toolbar light>
        <v-spacer></v-spacer>
        <v-btn
          icon
          light
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="posts"
        dense
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Posts generados</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="genPost" class="mx-2"><v-icon>mdi-sync</v-icon>Regenerar posts</v-btn>
            <v-btn color="primary" class="mx-2" @click="save"><v-icon>mdi-content-save-all</v-icon>Guardar posts</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <div>
            <v-btn
              color="primary"
              @click="genPost"
              :disabled="loading"
              class="ma-10"
            >
              Generar Posts
            </v-btn>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-card-actions>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import redaApp from '../api/redaApp'
import moment from 'moment'
export default {
  name: 'GeneratePosts',
  data () {
    return {
      dialog: false,
      posts: [],
      loading: false
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Fecha', value: 'date' },
        { text: 'Contenido', value: 'text' },
        { text: 'Imagenes a buscar', value: 'search_phrase' },
        { text: 'Frase atractiva', value: 'catchy_phrase' },
        { text: 'CTA', value: 'cta' },
        { text: 'Link', value: 'action_link' },
        { text: 'Hashtags sugeridos', value: 'hashtags' },
        { text: '', value: 'actions' }
      ]
    }
  },
  methods: {
    genPost () {
      this.loading = true
      redaApp.genCalendar(`Dame el calendario de contenidos para facebook del proximo mes (hoy es ${moment().format('LL')}) con almenos 3 posts por semana`)
        .then((items) => {
          items.forEach(item => {
            this.posts.push(item)
          })
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.loading = false
        })
    },
    deleteItem (item) {
      const i = this.posts.indexOf(item)
      this.posts.splice(i, 1)
    },
    save () {
      this.loading = true
      redaApp.createItems('posts', this.posts, 'id', true)
        .then(({ success, errors }) => {
          this.loading = false
          this.dialog = false
          this.showSuccess(success + ' posts guardados correctamente')
          if (errors > 0) this.showError(errors + ' posts no pudieron ser guardados')
        })
        .catch((error) => {
          this.loading = false
          console.error(error)
        })
    },
    ...mapActions('message', ['showSuccess', 'showError'])
  }
}
</script>
