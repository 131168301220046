<template>
  <v-container>
    <orders-table :loading="loading" :orders="orders"/>
  </v-container>
</template>

<script>
import firebaseApi from '../api/firebaseApi'
import redaApp from '../api/redaApp'
import { mapGetters } from 'vuex'
import OrdersTable from '@/components/OrdersTable'

export default {
  name: 'Orders',
  data: () => ({
    orders: [],
    customers: {},
    products: {},
    loading: true,
    listenerOrders: null
  }),
  components: {
    OrdersTable
  },
  mounted: async function () {
    this.customers = await redaApp.getIndexedItems('customers')
    this.products = await redaApp.getIndexedItems('products')
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerOrders()
    console.log('beforeDestroy')
  },
  methods: {
    async reset () {
      this.loading = true
      if (this.listenerOrders) this.listenerOrders()
      this.listenerOrders = firebaseApi.db.collection('orders')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.orders = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const order = doc.data()
            const items = order.items.map((item) => ({ ...item, product: this.products[item.product] }))
            const status = order.completed > 0 ? order.completed < 1 ? '04' : '05' : '03'
            this.orders.push({
              ...order,
              customer: this.customers[order.customer],
              items,
              status,
              num: i + 1,
              uid: doc.id
            })
          }
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters('company', [
      'getCompany'
    ])
  }
}
</script>
