<template>
  <v-container>
    <AccountPayable />
  </v-container>
</template>
<script>
import AccountPayable from '@/components/AccountPayable.vue'

export default {
  name: 'AccountReceivable',
  components: { AccountPayable },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  watch: {
  },

  /* created () {
  }, */

  methods: {
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style type="text/css">
</style>
