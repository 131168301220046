<template>
  <v-container  class="pa-0">
    <Pos :issuer="true"></Pos>
  </v-container>
</template>
<script type="text/javascript">
import Pos from '@/components/Pos.vue'
export default {
  name: 'IssueVoucher',
  components: { Pos }
}
</script>
