<template>
  <v-data-table
    :headers="headers"
    :items="getPrices"
    sort-by="priceList"
    hide-default-footer
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Precios</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark small v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select :items="getCompany.priceList" label="Lista de precios" v-model="editedItem.priceList" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field type="number" v-model="editedItem.price" label="Valor"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="editedItem.currency"
                      :items="[
                        {text: 'Colones', value: 'CRC'},
                        {text: 'Dolares', value: 'USD'}
                      ]"
                      label="Moneda"
                      ></v-select>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, /* mapState, */ mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: false,
    headers: [
      { text: 'Lista', value: 'priceList' },
      { text: 'Valor', value: 'price' },
      { text: 'Moneda', value: 'currency' },
      { text: 'Acciones', value: 'action', sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      priceList: 'general',
      price: 0,
      currency: 'CRC'
    },
    defaultItem: {
      priceList: 'general',
      price: 0,
      currency: 'CRC'
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo precio' : 'Editar precio'
    },
    ...mapGetters('priceList', [
      'getPrices'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  methods: {

    editItem (item) {
      this.editedIndex = this.getPrices.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      // const index = this.desserts.indexOf(item)
      confirm('¿Estás seguro de que deseas eliminar este elemento?') && this.deletePrice(item)
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save () {
      this.insertPrice(this.editedItem)
      /* if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        } */
      this.close()
    },
    ...mapActions('priceList', [
      'cleanPrices',
      'setPrices',
      'insertPrice',
      'deletePrice'
    ])
  }
}
</script>
