var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vouchers,"sort-by":"date","sort-desc":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.received ? 'Compras' : 'Ventas')+" de "+_vm._s(_vm.product.description))])],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format('l'))+" ")]}},{key:"item.voucher",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/voucher/' + item.voucher}},[_vm._v(_vm._s(item.voucher))])]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [(item.receiver)?_c('router-link',{attrs:{"to":'/customer/' + item.receiver}},[_vm._v(_vm._s(item.receiver))]):_vm._e()]}},{key:"item.issuer",fn:function(ref){
var item = ref.item;
return [(item.issuer)?_c('router-link',{attrs:{"to":'/customer/' + item.issuer}},[_vm._v(_vm._s(item.issuer))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }