<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
          icon
        >
        <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <v-card>
      <VoucherView :id="voucher" :receipts="true"></VoucherView></v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import VoucherView from '@/views/VoucherView.vue'

export default {
  name: 'DialogVoucher',
  props: [
    'voucher'
  ],
  components: {
    VoucherView
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
  },
  computed: {
  },
  mounted () {
  }
}
</script>
