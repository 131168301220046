<template>
  <v-card>
    <v-card-title v-if="title">
      {{ titleText }}
      <v-spacer></v-spacer>
      <v-btn  @click="newItem(false)" v-show="false"><v-icon color="success">mdi-plus</v-icon></v-btn>
      <v-btn @click="downloadExcel" v-if="!activatable"><v-icon>mdi-file-excel</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card :loading="loading" >
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editedItem.id"
                    label="Número"
                    @keyup.enter="save"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editedItem.code"
                    label="Código"
                    @keyup.enter="save"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    label="Nombre"
                    @keyup.enter="save"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.description"
                    label="Descripción"
                    @keyup.enter="save"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-checkbox
                    v-model="editedItem.editable"
                    label="Editable"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-treeview
        :items="accountsItems"
        :activatable="activatable"
        :active.sync="active"
        return-object
        height="320px"
      >
        <template v-slot:label="{ item }">
          {{item.id}}: {{item.name}}
        </template>
        <template v-slot:append="{ item }" v-if="!activatable">
          <v-icon color="success" @click="newItem(item)">mdi-plus</v-icon>
          <v-btn
              icon
              color="info"
              :to="`/account/${item.id}`"
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          <v-icon color="error" v-if="item.editable"  @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import tools from '@/api/tools'

const zfill = (number, width) => {
  const numberOutput = Math.abs(number) /* Valor absoluto del número */
  const { length } = number.toString() /* Largo del número */
  const zero = '0' /* String de cero */

  if (width <= length) {
    if (number < 0) {
      return (`-${numberOutput.toString()}`)
    }
    return numberOutput.toString()
  }
  if (number < 0) {
    return (`-${zero.repeat(width - length)}${numberOutput.toString()}`)
  }
  return ((zero.repeat(width - length)) + numberOutput.toString())
}
export default {
  name: 'Accounts',
  components: { },
  props: {
    activatable: {
      type: Boolean,
      default: false
    },
    title: {
      type: Boolean,
      default: true
    },
    titleText: {
      type: String,
      default: 'Plan de cuentas contables'
    },
    filter: {
      type: [String, Number]
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    father: null,
    defaultItem: {
      id: null,
      code: '',
      name: '',
      description: '',
      subaccounts: [],
      editable: true
    },
    editedItem: {
      id: null,
      code: '',
      name: '',
      description: '',
      subaccounts: [],
      editable: true
    },
    headersExcel: [
      { text: 'Código', value: 'code' },
      { text: 'Cuenta', value: 'name' },
      { text: 'Descripción', value: 'description' }
    ],
    active: []
  }),
  methods: {
    downloadExcel () {
      tools.table2excel(this.accounts, this.headersExcel, 'Plan de cuentas')
    },
    editItem (item) {
      // this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = {
        subaccounts: item.subaccounts,
        description: item.description,
        code: item.code,
        editable: item.editable,
        id: item.id,
        name: item.name
      }
      this.father = null
      this.dialog = true
    },

    newItem (item) {
      if (!item) {
        const nextId = this.accountsItems.length + 1
        const nextIdString = String(nextId)

        this.editedItem.id = Number(nextIdString)
        this.editedItem.code = nextIdString
      } else {
        const nextId = item.subaccounts.length + 1
        const nextIdString = String(item.id) + zfill(nextId, 2)

        this.father = item.id
        this.editedItem.id = Number(nextIdString)
        this.editedItem.code = `${item.code}.${zfill(nextId, 2)}`
      }
      this.dialog = true
    },

    deleteItem (item) {
      this.deleteAccount(item)
        .then((msj) => {
          console.log(msj)
        })
        .catch((error) => {
          console.error(error)
        })
      this.closeDelete()
    },

    close () {
      this.loading = false
      this.dialog = false
      this.father = null
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.father = null
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    save () {
      if (!this.loading) {
        this.loading = true
        const newAccount = { company: this.getCompany.identification, ...this.editedItem }
        if (this.father === null) {
          this.insertAccount(newAccount)
            .then((account) => {
              console.log(`Account ${account.code} updated!`)
            })
            .catch((error) => {
              console.error(error)
            })
            . finally(() => {
              this.close()
            })
        } else {
          this.insertAccount({ father: this.father, ...newAccount })
            .then((account) => {
              const fatherAccount = this.accounts.find((item) => item.id === this.father)
              const father = { ...fatherAccount }
              const subaccounts = father.subaccounts
              subaccounts.push(account.id)
              return this.insertAccount({ id: father.id, subaccounts })
            })
            .then(() => {
              console.log(`Account ${this.editedItem.code} created!`)
            })
            .catch((error) => {
              console.error(error)
            })
            . finally(() => {
              this.close()
            })
        }
      }
    },
    ...mapActions([]),
    ...mapActions('accounting', [
      'insertAccount',
      'deleteAccount'
    ])
  },
  computed: {
    accountsItems () {
      if (!this.filter) return this.getAccounts
      const key = typeof this.filter === 'string' ? 'code' : 'id'
      const accounts = tools.findByCodeOrId(this.getAccounts, this.filter, key)
      return [accounts]
    },
    formTitle () {
      return this.father !== null ? 'Crear cuenta contable' : 'Editar cuenta contable'
    },
    selected () {
      return this.accountsItems.find(({ id }) => id === this.active[0])
    },
    ...mapState('accounting', ['accounts']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('accounting', ['getAccounts', 'getAccountsDetails'])
  },
  watch: {
    active (newValue) {
      this.$emit('input', newValue[0])
      // if (this.getAccountsDetails.find((account) => account.id === newValue[0].id)) {
      //   this.$emit('input', newValue[0])
      // } else {
      //   this.$emit('input', undefined)
      // }
    }
  }
}
</script>
