var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("D-104 - Declaración Jurada del Impuesto al Valor Agregado - IVA")]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCompany.activities.length > 1),expression:"getCompany.activities.length > 1"}],attrs:{"items":_vm.getCompany.activities,"item-value":"code","item-text":"description","label":"Actividad económica"},model:{value:(_vm.activityCode),callback:function ($$v) {_vm.activityCode=$$v},expression:"activityCode"}})],1),_vm._l((_vm.getTaxReturnsD104),function(item,i){return [_c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1),_c('v-list-item-action',[_vm._v(_vm._s(_vm._f("numberFormat")(item.value)))])],1),_vm._l((item.items),function(item2){return [_c('v-card-text',{key:item2.id},[_c('v-list-item',{key:i},[_c('v-list-item-content',[_vm._v(" "+_vm._s(item2.text)+" ")]),_c('v-list-item-action',[_vm._v(_vm._s(_vm._f("numberFormat")(item2.value)))])],1),(!item2.table)?_c('v-simple-table',{key:item2.id,attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item2.items),function(item3){return _c('tr',{key:item3.id},[_c('td',[_vm._v(" "+_vm._s(item3.text)+" ")]),_c('td',{staticClass:"text-right"},[_c('input',{staticStyle:{"text-align":"right","max-width":"90px"},attrs:{"readonly":""},domProps:{"value":_vm._f("numberFormat")(item3.value)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copy(item3.value | _vm.numberFormat, item3.text)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar")])])],1)])}),0)]},proxy:true}],null,true)}):_c('v-data-table',{attrs:{"headers":[
            {text: 'Servicios', value: 'detail'},
            {text: 'Afectas 1%', value: '1'},
            {text: 'Afectas 2%', value: '2'},
            {text: 'Afectas 4%', value: '4'},
            {text: 'Afectas 8%', value: '8'},
            {text: 'Afectas 13%', value: '13'}
          ],"items":item2.items,"dense":"","hide-default-footer":"","items-per-page":item2.items.length},scopedSlots:_vm._u([{key:"item.1",fn:function(ref){
          var item = ref.item;
return [_c('input',{staticStyle:{"text-align":"right","max-width":"90px"},attrs:{"readonly":""},domProps:{"value":_vm._f("numberFormat")(item[1])}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copy(item[1], item.detail)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar")])])]}},{key:"item.2",fn:function(ref){
          var item = ref.item;
return [_c('input',{staticStyle:{"text-align":"right","max-width":"90px"},attrs:{"readonly":""},domProps:{"value":_vm._f("numberFormat")(item[2])}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copy(item[2], item.detail)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar")])])]}},{key:"item.4",fn:function(ref){
          var item = ref.item;
return [_c('input',{staticStyle:{"text-align":"right","max-width":"90px"},attrs:{"readonly":""},domProps:{"value":_vm._f("numberFormat")(item[4])}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copy(item[4], item.detail)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar")])])]}},{key:"item.8",fn:function(ref){
          var item = ref.item;
return [_c('input',{staticStyle:{"text-align":"right","max-width":"90px"},attrs:{"readonly":""},domProps:{"value":item[8]}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copy(item[8], item.detail)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar")])])]}},{key:"item.13",fn:function(ref){
          var item = ref.item;
return [_c('input',{staticStyle:{"text-align":"right","max-width":"90px"},attrs:{"readonly":""},domProps:{"value":_vm._f("numberFormat")(item[13])}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copy(item[13], item.detail)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar")])])]}}],null,true)})],1)]})]}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }