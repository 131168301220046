/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment'
import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools';

const { api } = fbApi

// initial state
const initialState = {
  chats: [],
  chat: {
    id: null,
    messages: [],
    type: null
  },
  listenerChats: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getChats: (state) => {
    const chats = []
    state.chats.forEach((chat) => {
      const name = chat.id
      const lastMessage = chat.messages[chat.messages.length - 1]
      const lastTimestamp = Number(chat.messages[chat.messages.length - 1].date)
      chats.push({ ...chat, name, lastMessage, lastTimestamp })
    })
    chats.sort((x, y) => {
      return x.lastTimestamp - y.lastTimestamp
    })
    return chats.reverse()
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const chatsQuerySnapshot = await fbApi.db.collection('chats')
        .where('company', '==', company)
        .get()
      const chats = []
      chatsQuerySnapshot.forEach((doc) => chats.push({ uid: doc.id, ...doc.data() }))
      commit('setChats', chats)
      const listenerChats = chatsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newChats = []
        querySnapshot.forEach((doc) => newChats.push({ uid: doc.id, ...doc.data() }))
        commit('setChats', newChats)
      })
      commit('setListenerChats', listenerChats)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar caja diaria')
    }
  },
  sentChat ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('chats', {
        ...payload,
        company: rootGetters['company/getCompany'].identification
      }, 'code', true)
        .then((asset) => resolve(asset))
        .catch((error) => reject(error))
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listenerChats) {
      state.listenerChats()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setChats (state, payload) {
    if (!payload) state.listenerChats()
    state.chats = payload
  },
  setListenerChats (state, listener) {
    state.listenerChats = listener
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
