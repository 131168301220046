<template>
  <v-container>
    <v-card
      :disabled="loading"
      elevation="2"
      :loading="loading"
      v-if="!error"
    >
      <v-row v-if="loading" align="center" justify="center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
          v-if="loading"
        ></v-progress-circular>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="4">
          <v-card-title>
            <v-row  align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <img :src="logoApp" width="200">
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-list
              two-line
              subheader
            >
              <v-subheader>Detalle</v-subheader>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ `${currencySimbol} ${voucher.total}` }}</v-list-item-title>
                  <v-list-item-subtitle>Total documento</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ `${currencySimbol} ${voucher.paid}` }}</v-list-item-title>
                  <v-list-item-subtitle>- Pagos anteriores</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="voucher.chargeProcessingServiceFee && docTypeCode === '11'">
                <v-list-item-content>
                  <v-list-item-title>{{ `${currencySimbol} ${chargeProcessingServiceFee}` }}</v-list-item-title>
                  <v-list-item-subtitle>+ Servicios de procesamiento electrónico</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">{{ `${currencySimbol} ${total}` }}</v-list-item-title>
                <v-list-item-subtitle>Total a pagar</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-title>
          <v-card-text align="center" justify="center" v-if="!isONVO">
            <div>Procesado por</div>
            <a href="https://onvopay.com/" target='_blank'>
              <v-img src="@/assets/og-onvopay-banner.png" max-width="100"></v-img>
            </a>
          </v-card-text>
        </v-col>
        <v-divider
          vertical
        ></v-divider>
        <v-col cols="12" sm="8">
          <div class="title">
            Pagar {{ docType }}
          </div>
          <div class="title">
            N° {{ docType === 'Factura Proforma' ? sequence : voucher.voucher }}
          </div>
          <v-tabs
            v-if="voucher.outstandingBalance > 0"
            v-model="tab"
            icons-and-text
          >
            <v-tab><img :width="images.sinpeM.width" :src="images.sinpeM.name"></v-tab>
            <v-tab><img :width="images.sinpeI.width" :src="images.sinpeI.name"></v-tab>
            <v-tab><img :width="images.card.width" :src="images.card.name"></v-tab>
          </v-tabs>
          <v-alert type="success" v-else>Documento pagado</v-alert>
          <v-alert type="warning" v-if="voucher.awaitingConfirm">Esperando verificación: Anteriormente se registró un intento de pago por SINPE y lo estamos verificando, realizar un nuevo intento de pago solo si existe certeza de que la transacción anterior no se debitó en su entidad bancaria</v-alert>
          <v-tabs-items v-model="tab" v-if="voucher.outstandingBalance > 0">
            <v-tab-item>
              <SinpeMovil
                :receiver="voucher.receiver"
                :currencySimbol="currencySimbol"
                :amout="total"
              ></SinpeMovil>
            </v-tab-item>
            <v-tab-item>
              <SinpeIBAN
                :receiver="voucher.receiver"
                :currencySimbol="currencySimbol"
                :amout="total"
              ></SinpeIBAN>
            </v-tab-item>
            <v-tab-item>
              <CreditCard
              ></CreditCard>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <v-row v-else align="center" justify="center">
      <v-alert
        type="error"
      >{{ errorMessage }}</v-alert>
    </v-row>
  </v-container>
</template>
<script>
import CreditCard from '@/components/CreditCard.vue'
import SinpeMovil from '@/components/SinpeMovil.vue'
import SinpeIBAN from '@/components/SinpeIBAN.vue'
import tools from '@/api/tools'
import redaApp from '@/api/redaApp'
import sinpeMO from '@/assets/Asset_SINPE_M.png'
import sinpeIO from '@/assets/Asset_SINPE_IBAN.jpg'
import cardO from '@/assets/Asset_Tarjetas.png'
import sinpeMR from '@/assets/sinpe.png'
import sinpeIR from '@/assets/sinpe-iban.png'
import cardR from '@/assets/cards.png'
import logoONVO from '@/assets/logo2.png'
import logo from '@/assets/logoBlack2.svg'

export default {
  name: 'PayVoucher',
  components: { SinpeMovil, CreditCard, SinpeIBAN },
  async mounted () {
    try {
      const voucher = await redaApp.paymentsVoucher({
        key: this.$route.query.key
      })
      this.voucher = { ...voucher }
    } catch (error) {
      this.error = true
      this.errorMessage = Number(error.response?.status) === 400
        ? 'El documento solicitado no existe'
        : 'No se puede procesar su pago, intente más tarde'
    }
    this.loading = false
  },
  data: () => ({
    voucher: {},
    loading: true,
    error: false,
    tab: 0
  }),
  computed: {
    isONVO () {
      return process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com'
    },
    logoApp () {
      return process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com'
        ? logoONVO
        : logo
    },
    images () {
      if (process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com') {
        return {
          sinpeM: { name: sinpeMO, width: '172' },
          sinpeI: { name: sinpeIO, width: '172' },
          card: { name: cardO, width: '200' }
        }
      }
      return {
        sinpeM: { name: sinpeMR, width: '90' },
        sinpeI: { name: sinpeIR, width: '100' },
        card: { name: cardR, width: '200' }
      }
    },
    chargeProcessingServiceFee () {
      return tools.round10(this.voucher?.chargeProcessingServiceFee * this.voucher?.outstandingBalance, -2)
    },
    currencySimbol () {
      return this.voucher?.currency === 'USD' ? '$' : '₡'
    },
    total () {
      const total = (this.docTypeCode === '11' ? this.voucher?.chargeProcessingServiceFee + 1 : 1) * this.voucher?.outstandingBalance
      return tools.round10(total)
    },
    docTypeCode () {
      const type = this.voucher.voucher.substring(29, 31)
      return type
    },
    docType () {
      const types = {
        '01': 'Factura Electrónica',
        '02': 'Nota de Débito Electrónica',
        '03': 'Nota de Crédito Electrónica',
        '04': 'Tiquete Electrónico',
        '08': 'Factura Electrónica de Compras',
        '09': 'Factura Electrónica de Exportación',
        10: 'Factura Digital',
        11: 'Factura Proforma'
      }
      const type = this.voucher.voucher.substring(29, 31)
      return types[type]
    },
    sequence () {
      return this.voucher.voucher.substring(21, 41)
    }
  },
  methods: {
    errorOnSubmit (e) {
      this.loading = false
    },
    async getPayments () {
      try {
        const voucher = await redaApp.paymentsVoucher({
          key: this.$route.query.key
        })
        this.voucher = { ...voucher }
      } catch (error) {
        this.error = true
        this.errorMessage = Number(error.response?.status) === 400
          ? 'El documento solicitado no existe'
          : 'No se puede procesar su pago, intente más tarde'
      }
      this.loading = false
    }
  }
}
</script>
