<template>
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="num"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Ordenes de producción</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on"><v-icon left>mdi-plus</v-icon>Nueva</v-btn>
          </template>
          <v-card
            :loading="loading"
          >
            <v-card-title>
              <span class="headline">Nueva orden de producción</span>
            </v-card-title>

            <v-card-text>
              <RedaForm :options="form" v-model="item"></RedaForm>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn text @click="close" :disabled="loading">Cancelar</v-btn>
              <v-btn color="primary" @click="save" :disabled="loading">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.product="{ item }">
      {{ item.commercialCode }}: {{ item.product.description }}
    </template>
    <template v-slot:item.created="{ item }">
      {{ item.created | dateFormat }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$router.push('/productionOrder/' + item.uid)"
      >
        mdi-information
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item)"
        v-if="false"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import redaApp from '../api/redaApp'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'ProductionOrders',
  props: {
    productionOrders: Array,
    products: Array
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      loading: false,
      dialog: false,
      item: {
        product: null,
        quantity: 1,
        drm: [],
        task: null,
        customization: null,
        others: null,
        cc: null,
        orders: []
      },
      defaultItem: {
        product: null,
        quantity: 1,
        drm: [],
        task: null,
        customization: null,
        others: null,
        cc: null,
        orders: []
      }
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    headers () {
      return [
        { text: 'N°', value: 'num' },
        { text: 'Creada', value: 'created' },
        { text: 'Producto', value: 'product' },
        { text: 'Cantidad', value: 'quantity', align: 'end' },
        { text: '', value: 'actions' }
      ]
    },
    items () {
      const items = JSON.parse(JSON.stringify(this.productionOrders))
      // eslint-disable-next-line no-unused-expressions
      items?.sort((a, b) => a.created - b.created)
      return items.map((item, i) => ({ ...item, num: i + 1 })) || []
    },
    form () {
      return {
        inputs: [
          {
            id: '001',
            label: 'Producto',
            name: 'product',
            value: 'product',
            type: 'autocomplete',
            items: this.products,
            itemValue: 'commercialCode',
            itemText: 'description',
            rules: 'required',
            cols: 8
          },
          {
            id: '002',
            label: 'Cantidad',
            name: 'quantity',
            value: 'quantity',
            type: 'number',
            cols: 4
          },
          // {
          //   id: '003',
          //   label: 'Materia prima directa',
          //   name: 'drm',
          //   value: 'drm',
          //   type: 'table',
          //   cols: 12
          // },
          {
            id: '005',
            label: 'Observaciones',
            name: 'others',
            value: 'others',
            type: 'textarea',
            cols: 12
          },
          {
            id: '004',
            label: 'Departamento',
            name: 'cc',
            value: 'cc',
            type: 'select',
            itemText: 'name',
            itemValue: 'code',
            items: this.getCostCenters.filter(({ type }) => type === 'CCP'),
            cols: 12
          }
        ]
      }
    },
    ...mapGetters('user', ['getUser']),
    ...mapGetters('siinco', ['getCostCenters'])
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    close () {
      this.dialog = false
      this.$emit('close')
    },
    save () {
      this.loading = true
      const newItem = { ...this.item }
      if (!this.item?.uid) {
        newItem.created = Number(moment().format('x'))
        newItem.createdBy = this.getUser.uid
      }
      redaApp.createProductionOrder(newItem).then((po) => {
        this.$emit('submit')
        this.showSuccess('Orden creada')
        this.reset()
      })
        .catch((e) => {
          this.showError('Error al crear orden de producción')
          console.error(e)
        })
        .finally(() => { this.loading = false })
    },
    reset () {
      if (this.po) {
        this.item.product = this.po.product || null
        this.item.quantity = this.po.quantity || 1
        this.item.drm = this.po.drm?.type || []
        this.item.task = this.po.task || null
        this.item.customization = this.po.customization || []
        this.item.others = this.po.others || null
        this.item.cc = this.po.cc || null
        this.item.orders = this.po.orders || null
      } else {
        this.item = { ...this.defaultItem }
      }
      this.close()
    },
    ...mapActions('message', ['showError', 'showSuccess'])
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
