export default {
  sales: {
    1: [
      {
        id: 'CASILLA_02111_RDB_2_1',
        cabys: [
          '2111100010000',
          '2111100020100',
          '2111100030100',
          '2111100030300',
          '2111300010000',
          '2111300020100',
          '2111300020200',
          '2111300030200',
          '2112100010000',
          '2112100020100',
          '2112100020200',
          '2112100020400',
          '2112100020500',
          '2112100020600',
          '2112100030200',
          '2112100030300',
          '2113100010000',
          '2113100020100',
          '2113100030100',
          '2113100030300',
          '2113300010000',
          '2113300020100',
          '2113300020200',
          '2113300030200',
          '2114100010000',
          '2114100020100',
          '2114100020200',
          '2114100020400',
          '2114100020500',
          '2114100020600',
          '2114100030200',
          '2114100030300',
          '2115100010100',
          '2115100010200',
          '2115100019900',
          '2115100020100',
          '2115100020200',
          '2115100029900',
          '2115300010200',
          '2115300010300',
          '2115300010400',
          '2115300019900',
          '2115300020200',
          '2115300020300',
          '2115300020400',
          '2115300029900',
          '2116001019900',
          '2116001029900',
          '2118401010101',
          '2118401010103',
          '2118401010105',
          '2118401010107',
          '2118401010110',
          '2118401010111',
          '2118401019901',
          '2118401019906',
          '2118401019907',
          '2118402000101',
          '2118402000103',
          '2118402000105',
          '2118402000107',
          '2118402000111',
          '2118402009901',
          '2118402009907',
          '2118403000103',
          '2118403000105',
          '2118403000107',
          '2118499010001',
          '2121100000100',
          '2121100000200',
          '2121100000300',
          '2121100000400',
          '2121100009900',
          '2121200000100',
          '2121200000200',
          '2121300000000',
          '2121400000100',
          '2121400009900',
          '2121500000100',
          '2121500000200',
          '2121500000300',
          '2121500000400',
          '2121500000500',
          '2121500009900',
          '2121600000100',
          '2121600000200',
          '2121600000300',
          '2121600000400',
          '2121600000500',
          '2121600009900',
          '2121900010100',
          '2121900019900',
          '2121900020000',
          '2121900030000',
          '2121900040000',
          '2121900050000',
          '2121900060000',
          '2121900070000',
          '2121900990000',
          '2122101000100',
          '2122101000200',
          '2122101000300',
          '2122101000400',
          '2122101000500',
          '2122101000600',
          '2122101000700',
          '2122101000800',
          '2122101000900',
          '2122101001000',
          '2122101001100',
          '2122101001200',
          '2122101001300',
          '2122101001400',
          '2122101001600',
          '2122101001700',
          '2122101001800',
          '2122101001900',
          '2122101002000',
          '2122101002100',
          '2122101009900',
          '2122102000100',
          '2122102000200',
          '2122102000300',
          '2122102000400',
          '2122102000500',
          '2122102000701',
          '2122200000100',
          '2122200000200',
          '2122200000300',
          '2122200000400',
          '2122200000500',
          '2122200000600',
          '2122200000700',
          '2122200000800',
          '2122200000900',
          '2122200001000',
          '2122200001100',
          '2122200001200',
          '2122200001300',
          '2122200001400',
          '2122200001500',
          '2122200001600',
          '2122200001700',
          '2122200001900',
          '2122200002000',
          '2122200002100',
          '2122200002200',
          '2122200002300',
          '2122200002400',
          '2122200009900',
          '2122300000100',
          '2122300000200',
          '2122300000300',
          '2122300000400',
          '2122300000601',
          '2122300000700',
          '2124202000100',
          '2124203020200',
          '2124203029900',
          '2131300009900',
          '2131900000300',
          '2131900000700',
          '2131900000800',
          '2142100000000',
          '2149302000100',
          '2149302000200',
          '2149302000300',
          '2149302000400',
          '2149302000500',
          '2149302000600',
          '2149302000700',
          '2149303000100',
          '2149399000100',
          '2149399000200',
          '2149399000300',
          '2149399000400',
          '2149399000500',
          '2149399000600',
          '2149399000700',
          '2149399000800',
          '2149399000900',
          '2149399001000',
          '2149399001100',
          '2149399001200',
          '2149400000100',
          '2149400000300',
          '2149400000400',
          '2149400000500',
          '2149400000600',
          '2149400000700',
          '2149400000800',
          '2149400009900',
          '2161100009900',
          '2161200009900',
          '2163100000000',
          '2163200000000',
          '2164100000000',
          '2164200000000',
          '2165100000000',
          '2165200009900',
          '2166100000000',
          '2166200000000',
          '2169101000100',
          '2169101000200',
          '2170000000100',
          '2170000000200',
          '2170000000300',
          '2170000000600',
          '2211001010000',
          '2211001020100',
          '2211001020200',
          '2211001030000',
          '2211002010000',
          '2211002020100',
          '2211002020200',
          '2211002030000',
          '2221100000100',
          '2221100000200',
          '2221200000100',
          '2223099030200',
          '2223099040000',
          '2224100000300',
          '2225101010200',
          '2225101010300',
          '2225101019900',
          '2225101020200',
          '2225101029900',
          '2225101990200',
          '2229001010100',
          '2229001010200',
          '2229001020100',
          '2229002010100',
          '2229002010200',
          '2229002020100',
          '2311000000100',
          '2312000000500',
          '2313001000400',
          '2314000010100',
          '2314000010200',
          '2314000020100',
          '2314000020200',
          '2314000020300',
          '2314000990500',
          '2314000990601',
          '2314000991000',
          '2316100000100',
          '2316200000100',
          '2318000000300',
          '2322001000200',
          '2322001000400',
          '2341000000200',
          '2342001000200',
          '2349001010100',
          '2349001010300',
          '2349001020100',
          '2349001020300',
          '2349002010100',
          '2349002010200',
          '2349002010300',
          '2349002010400',
          '2349002010600',
          '2349002010700',
          '2349002010900',
          '2349002011100',
          '2349002011500',
          '2349002020100',
          '2349002020200',
          '2349002020300',
          '2349002020400',
          '2349002020500',
          '2349002020800',
          '2351102000100',
          '2351102000200',
          '2351102009900',
          '2352001010000',
          '2352001999900',
          '2363000000000',
          '2364000000000',
          '2371000000100',
          '2371000000200',
          '2391102010100',
          '2391102010200',
          '2391300000100',
          '2391300000200',
          '2391300000300',
          '2392101000100',
          '2392101000200',
          '2392402000100',
          '2392402000200',
          '2392500000100',
          '2392500000200',
          '2392600000100',
          '2392600000200',
          '2392700000100',
          '2392700009900',
          '2399501010100',
          '2399501010200',
          '2399501029900',
          '2399501090000',
          '2399501100000',
          '2399601000100',
          '2399601000200',
          '2399602000100',
          '2399901010000',
          '2399901020000',
          '2399902010000',
          '2399902020000',
          '2399905000300',
          '2399908000100',
          '2399908000200',
          '2399909000300',
          '2399999000199',
          '2820105010100',
          '2820105020100',
          '2820203010100',
          '2820203020100',
          '2820204020100',
          '2820305010100',
          '2820305020100',
          '2820306000100',
          '2820403000100',
          '2820404000100',
          '2821003000100',
          '2922001020301',
          '2932003020000',
          '2932004020000',
          '2933003020000',
          '2933004020000',
          '3219200020000',
          '3219301000000',
          '3219601010000',
          '3219601020000',
          '3219603010100',
          '3219603010200',
          '3219603019900',
          '3219603020100',
          '3219603020200',
          '3219603029900',
          '3219604000000',
          '3219605000000',
          '3219606000000',
          '3219607000000',
          '3219999000600',
          '3270000000400',
          '3424010000200',
          '3432002000200',
          '3512001000100',
          '3532302000600',
          '3532304000100',
          '3532304000600',
          '3532399000500',
          '3542099010000',
          '3544001010000',
          '3627002039900',
          '3641001999900',
          '3641099999900',
          '3694000990300',
          '3694000990400',
          '3699002000100',
          '3891101000100',
          '3891104000100',
          '3891107000000',
          '3891110010100',
          '3891111009900',
          '3899301010100',
          '3899301020100',
          '3899302010100',
          '3899302010200',
          '3899302010300',
          '3899302010400',
          '3899302019900',
          '3899800000000',
          '4291201990500',
          '4291303010000',
          '4291501000100',
          '4651002020100',
          '4651002020200',
          '4823200000200',
          '4823200000300',
          '4823200000400',
          '4823200000500',
          '4823200000700'
        ],
        text: 'Venta de bienes relacionados a la canasta básica tributaria'
      },
      { id: 'CASILLA_02111_RDB_2_2', cabys: [], text: 'Venta de servicios relacionados a la canasta básica tributaria' },
      {
        id: 'CASILLA_02112_RDB_3_1',
        cabys: [
          '0111100000100',
          '0111100009900',
          '0111200000100',
          '0111200009900',
          '0112201010100',
          '0112201020100',
          '0112202010100',
          '0112202020100',
          '0112203010000',
          '0112203020000',
          '0112299010100',
          '0112299020100',
          '0113200000000',
          '0117200000000',
          '0121202010100',
          '0121202020100',
          '0121402010100',
          '0121402021100',
          '0121999010500',
          '0121999010600',
          '0121999020500',
          '0121999020600',
          '0122100000100',
          '0122100000200',
          '0122100000300',
          '0122100000400',
          '0122100000500',
          '0122100000600',
          '0122100000700',
          '0122100000800',
          '0122100009900',
          '0122900000100',
          '0122900000200',
          '0122900000300',
          '0122900000400',
          '0122900000500',
          '0122900000600',
          '0122900000700',
          '0122900000800',
          '0122900009900',
          '0123100010100',
          '0123100020600',
          '0123100020800',
          '0123100021200',
          '0123200000100',
          '0123400010100',
          '0123400010200',
          '0123400010300',
          '0123400010400',
          '0123400010500',
          '0123400010600',
          '0123400019900',
          '0123400020100',
          '0123400020200',
          '0123400020300',
          '0123400020400',
          '0123400020500',
          '0123400020600',
          '0123400029900',
          '0123501000100',
          '0123501009900',
          '0123502000100',
          '0123502000200',
          '0123502000300',
          '0123505000000',
          '0123902000100',
          '0123902000200',
          '0123902000300',
          '0123902000400',
          '0123902000500',
          '0123902009900',
          '0124101010000',
          '0124101020000',
          '0124900000100',
          '0124900000200',
          '0125101010100',
          '0125101019900',
          '0125101020100',
          '0125101029900',
          '0125200000100',
          '0125200000200',
          '0125300010100',
          '0125300010200',
          '0125300010300',
          '0125300010400',
          '0125300019900',
          '0125300020100',
          '0125300020200',
          '0125300020300',
          '0125300020400',
          '0125300020500',
          '0125300020600',
          '0125300020700',
          '0125300020800',
          '0125300020900',
          '0125300029900',
          '0125401010000',
          '0125401020100',
          '0125401020200',
          '0125902000100',
          '0125902000200',
          '0125902000300',
          '0125902009900',
          '0125903000100',
          '0129004010100',
          '0129004010200',
          '0129004020100',
          '0129004020200',
          '0129008010100',
          '0129008010200',
          '0129008010300',
          '0129008010400',
          '0129008019900',
          '0129008020100',
          '0129008020200',
          '0129008020300',
          '0129008020400',
          '0129008029900',
          '0129009000000',
          '0129015010000',
          '0129015020000',
          '0129016010000',
          '0129016020000',
          '0129017010100',
          '0129017019900',
          '0129017020100',
          '0129017029900',
          '0129019010100',
          '0129019010200',
          '0129019020100',
          '0129019020200',
          '0131100010000',
          '0131100020100',
          '0131100020200',
          '0131100020300',
          '0131100020400',
          '0131100020500',
          '0131100020600',
          '0131100020700',
          '0131100020800',
          '0131100020900',
          '0131100021000',
          '0131100021100',
          '0131100021200',
          '0131100029900',
          '0131200010000',
          '0131200020100',
          '0131200020200',
          '0131200020300',
          '0131200020400',
          '0131301000100',
          '0131302000100',
          '0131302000200',
          '0131601010000',
          '0131601020100',
          '0131601020200',
          '0131601020300',
          '0131601020400',
          '0131601020500',
          '0131601020600',
          '0131601020700',
          '0131601020800',
          '0131601020900',
          '0131601021000',
          '0131601021100',
          '0131601021200',
          '0131601021300',
          '0131601021400',
          '0131601021500',
          '0131601021600',
          '0131601021700',
          '0131601021800',
          '0131601029900',
          '0131700000100',
          '0131700000200',
          '0131700000300',
          '0131700000400',
          '0131700000500',
          '0131700000600',
          '0131700000700',
          '0131700000800',
          '0131700009900',
          '0131800010000',
          '0131800020100',
          '0131800020200',
          '0131800020300',
          '0131800020400',
          '0131800020500',
          '0131800020600',
          '0131800029900',
          '0132200000200',
          '0132200000300',
          '0132200000400',
          '0132200000500',
          '0132200009900',
          '0134100000100',
          '0134100000200',
          '0134100000300',
          '0134100000400',
          '0134100000500',
          '0134100000600',
          '0134100000700',
          '0134100000800',
          '0134100000900',
          '0134100001000',
          '0134100001100',
          '0134100001200',
          '0134100001300',
          '0134100001400',
          '0134100001500',
          '0134100009900',
          '0135907000000',
          '0135909000000',
          '0135914000000',
          '0135918000000',
          '0135919000000',
          '0135920000000',
          '0135922000000',
          '0135924000000',
          '0135925000000',
          '0135927000000',
          '0135929000000',
          '0141100000000',
          '0141200009900',
          '0142201000000',
          '0144100009900',
          '0144400020100',
          '0144400029900',
          '0144901009900',
          '0146000000000',
          '0149101000100',
          '0149101009900',
          '0149200000000',
          '0149901009900',
          '0151002010100',
          '0151002010200',
          '0151002020100',
          '0151002020200',
          '0151002020300',
          '0151002020400',
          '0151002020500',
          '0151002020600',
          '0151002020700',
          '0151002020800',
          '0151002020900',
          '0151002029900',
          '0152000010000',
          '0152000020000',
          '0153001010000',
          '0153001020000',
          '0153099010000',
          '0153099020000',
          '0154001010000',
          '0154001020000',
          '0154099010000',
          '0154099020000',
          '0155001010000',
          '0155001020000',
          '0159101010000',
          '0159101020000',
          '0159102010000',
          '0159102020000',
          '0161001010000',
          '0161001020000',
          '0161099010000',
          '0161099020000',
          '0164000000100',
          '0164000009900',
          '0165100000100',
          '0165402009900',
          '0165500000100',
          '0165600000000',
          '0165700000100',
          '0165700000200',
          '0169902010000',
          '0169902020000',
          '0169908010000',
          '0169908020000',
          '0170102000100',
          '0170102000200',
          '0170102000300',
          '0170102000400',
          '0170102000500',
          '0170300000000',
          '0170400000000',
          '0191100000000',
          '0193010010000',
          '0193013010000',
          '0193020010000',
          '0221100000000',
          '0229200000000',
          '0231200000000',
          '0291000000100',
          '0421101020000',
          '0421102020000',
          '0421103020000',
          '0421104020000',
          '0421199020000',
          '0421201020000',
          '0421202020000',
          '0421203020000',
          '0421204020000',
          '0421299020000',
          '0422101020000',
          '0422102020000',
          '0422199020000',
          '0422201020000',
          '0422202020000',
          '0422299020000',
          '0423101020000',
          '0423102020000',
          '0423103020000',
          '0423104020000',
          '0423199020000',
          '0423201020000',
          '0423202020000',
          '0423203020000',
          '0423204020000',
          '0423299020000',
          '0424101020000',
          '0424102020000',
          '0424103020000',
          '0424104020000',
          '0424105020000',
          '0424106020000',
          '0424199020000',
          '0424201020000',
          '0424202020000',
          '0424203020000',
          '0424204020000',
          '0424205020000',
          '0424206020000',
          '0424299020000',
          '0425101020000',
          '0425102020000',
          '0425103020000',
          '0425104020000',
          '0425105020000',
          '0425199020000',
          '0425201020000',
          '0425202020000',
          '0425203020000',
          '0425204020000',
          '0425205020000',
          '0425299020000',
          '0426101020000',
          '0426102020000',
          '0426103020000',
          '0426104020000',
          '0426105020000',
          '0426106020000',
          '0426107020000',
          '0426108020000',
          '0426199020000',
          '0426201020000',
          '0426202020000',
          '0426203020000',
          '0426204020000',
          '0426205020000',
          '0426206020000',
          '0426207020000',
          '0426208020000',
          '0426299020000',
          '0429101020000',
          '0429102020100',
          '0429102029900',
          '0429103020000',
          '0429104020000',
          '0429105020000',
          '0429106020000',
          '0429107020000',
          '0429108020000',
          '0429110020000',
          '0429199020000',
          '0429201020000',
          '0429202020100',
          '0429202029900',
          '0429203020000',
          '0429204020000',
          '0429205020000',
          '0429206020000',
          '0429207020000',
          '0429208020000',
          '0429210020000',
          '0429299020000'
        ],
        text: 'Venta de bienes agropecuarios incluidos en la canasta básica tributaria'
      },
      { id: 'CASILLA_02112_RDB_3_2', cabys: [], text: 'Venta de servicios agropecuarios incluidos en la canasta básica tributaria' },
      { id: 'CASILLA_02112_RDB_3_3', cabys: [], text: 'Venta de bienes de capital agropecuarios incluidos en la canasta básica tributaria' },
      {
        id: 'CASILLA_020600_MON',
        cabys: [
          '0111200000200',
          '0112201010200',
          '0112201020200',
          '0112202010200',
          '0112202020200',
          '0112299010200',
          '0112299020200',
          '0114200000100',
          '0114200009900',
          '0141200000100',
          '0149101000200',
          '0149901000100',
          '3912001010100',
          '3912001010200'
        ],
        text: 'Venta de materias primas e insumos de acuerdo al artículo 11, inciso 3, subinciso c) de la LIVA'
      },
      {
        id: 'CASILLA_020700_MON',
        cabys: [
          '3912001010300',
          '3912001019900',
          '0112100000000',
          '0113100000000',
          '0114100000000'
        ],
        text: 'Venta de productos veterinarios, insumos agropecuarios y pesca'
      }
    ],
    2: [
      {
        id: 'CASILLA_020900_MON',
        cabys: [
          '2399999001600',
          '3562101000101',
          '3562101000102',
          '3562101000103',
          '3562101000104',
          '3562101000130',
          '3562101000151',
          '3562101000202',
          '3562101000203',
          '3562101000204',
          '3562101000205',
          '3562101000206',
          '3562101000207',
          '3562101000208',
          '3562101000209',
          '3562101000210',
          '3562101000211',
          '3562101000212',
          '3562101000213',
          '3562101000214',
          '3562101000215',
          '3562101000216',
          '3562101000217',
          '3562101000218',
          '3562101000219',
          '3562101000221',
          '3562101000222',
          '3562101000223',
          '3562101000301',
          '3562101000302',
          '3562101000303',
          '3562101000354',
          '3562101000401',
          '3562101000402',
          '3562101000405',
          '3562101000406',
          '3562101000407',
          '3562101000408',
          '3562101000411',
          '3562102010101',
          '3562102010102',
          '3562102010103',
          '3562102010104',
          '3562102010105',
          '3562102010110',
          '3562102010201',
          '3562102010202',
          '3562102010203',
          '3562102010204',
          '3562102010205',
          '3562102010206',
          '3562102010207',
          '3562102010210',
          '3562102010301',
          '3562102010302',
          '3562102010310',
          '3562102010401',
          '3562102010402',
          '3562102010403',
          '3562102010404',
          '3562102010405',
          '3562102010501',
          '3562102010502',
          '3562102010600',
          '3562102010700',
          '3562102010800',
          '3562102020101',
          '3562102020102',
          '3562102020103',
          '3562102020104',
          '3562102020105',
          '3562102020106',
          '3562102020107',
          '3562102020108',
          '3562102020151',
          '3562102020153',
          '3562102020201',
          '3562102020202',
          '3562102020301',
          '3562102020302',
          '3562102020303',
          '3562102020304',
          '3562102020305',
          '3562102020306',
          '3562102020307',
          '3562102020353',
          '3562102020354',
          '3562102020401',
          '3562102020402',
          '3562102020403',
          '3562102020404',
          '3562102020405',
          '3562102020406',
          '3562102020407',
          '3562102020408',
          '3562102020409',
          '3562102020410',
          '3562102020411',
          '3562102020501',
          '3562102020502',
          '3562102020503',
          '3562102020504',
          '3562102020505',
          '3562102020506',
          '3562102020507',
          '3562102020508',
          '3562102020509',
          '3562102020510',
          '3562102020511',
          '3562102020512',
          '3562102020513',
          '3562102020514',
          '3562102020551',
          '3562102020571',
          '3562102020577',
          '3562102030000',
          '3562103010101',
          '3562103010103',
          '3562103010104',
          '3562103010105',
          '3562103010106',
          '3562103010107',
          '3562103010108',
          '3562103010109',
          '3562103010130',
          '3562103010201',
          '3562103010202',
          '3562103010203',
          '3562103010204',
          '3562103010205',
          '3562103010206',
          '3562103010207',
          '3562103010208',
          '3562103010209',
          '3562103010210',
          '3562103010211',
          '3562103010212',
          '3562103010213',
          '3562103010214',
          '3562103010215',
          '3562103010216',
          '3562103010217',
          '3562103010218',
          '3562103010219',
          '3562103010221',
          '3562103010253',
          '3562103010302',
          '3562103010304',
          '3562103010305',
          '3562103010401',
          '3562103010402',
          '3562103010430',
          '3562103010501',
          '3562103010503',
          '3562103010601',
          '3562103010602',
          '3562103010603',
          '3562103010604',
          '3562103010605',
          '3562103010606',
          '3562103010607',
          '3562103010608',
          '3562103010609',
          '3562103010610',
          '3562103010611',
          '3562103010612',
          '3562103010613',
          '3562103010614',
          '3562103010630',
          '3562103010658',
          '3562103020101',
          '3562103020103',
          '3562103020104',
          '3562103020201',
          '3562103020202',
          '3562103020203',
          '3562103020204',
          '3562103020205',
          '3562103030101',
          '3562103030102',
          '3562103030103',
          '3562103030104',
          '3562103030105',
          '3562103030106',
          '3562103030107',
          '3562103030108',
          '3562103030109',
          '3562103030130',
          '3562103030134',
          '3562103030201',
          '3562103030202',
          '3562103030203',
          '3562103030204',
          '3562103030231',
          '3562103030300',
          '3562103040101',
          '3562103040102',
          '3562103040103',
          '3562103040104',
          '3562103040105',
          '3562103040106',
          '3562103040107',
          '3562103040204',
          '3562103040300',
          '3562103050100',
          '3562103050200',
          '3562103060001',
          '3562103060002',
          '3562103060003',
          '3562103060004',
          '3562103060005',
          '3562103060006',
          '3562103060007',
          '3562103060008',
          '3562104000101',
          '3562104000102',
          '3562104000103',
          '3562104000104',
          '3562104000105',
          '3562104000155',
          '3562104000201',
          '3562104000202',
          '3562104000204',
          '3562104000205',
          '3562104000210',
          '3562104000211',
          '3562104000212',
          '3562104000213',
          '3562104000214',
          '3562104000251',
          '3562104000254',
          '3562105010101',
          '3562105010102',
          '3562105010103',
          '3562105010104',
          '3562105010201',
          '3562105010301',
          '3562105010302',
          '3562105010303',
          '3562105020101',
          '3562105020103',
          '3562105020104',
          '3562105020105',
          '3562105020106',
          '3562105020107',
          '3562105020108',
          '3562105030000',
          '3562106000101',
          '3562106000102',
          '3562106000151',
          '3562106000201',
          '3562106000202',
          '3562106000203',
          '3562106000204',
          '3562106000205',
          '3562106000206',
          '3562106000207',
          '3562106000208',
          '3562106000209',
          '3562106000220',
          '3562106000230',
          '3562106000252',
          '3562106000253',
          '3562106000256',
          '3562106000257',
          '3562106000258',
          '3562106000301',
          '3562106000302',
          '3562106000303',
          '3562106000305',
          '3562106000306',
          '3562106000307',
          '3562106000308',
          '3562106000351',
          '3562106000353',
          '3562106000355',
          '3562106000401',
          '3562106000402',
          '3562106000403',
          '3562106000404',
          '3562106000410',
          '3562106000411',
          '3562106000412',
          '3562106000413',
          '3562106000414',
          '3562106000415',
          '3562106000416',
          '3562106000417',
          '3562106000418',
          '3562106000419',
          '3562106000421',
          '3562106000461',
          '3562106000465',
          '3562106000501',
          '3562106000502',
          '3562106000503',
          '3562106000504',
          '3562106000506',
          '3562106000507',
          '3562106000510',
          '3562106000511',
          '3562106000520',
          '3562106000601',
          '3562106000602',
          '3562106000603',
          '3562106000604',
          '3562106000605',
          '3562106000701',
          '3562106000702',
          '3562106000703',
          '3562106000704',
          '3562106000705',
          '3562106000706',
          '3562106000707',
          '3562107010101',
          '3562107010102',
          '3562107010103',
          '3562107010104',
          '3562107010105',
          '3562107010106',
          '3562107010107',
          '3562107010108',
          '3562107010109',
          '3562107010110',
          '3562107010111',
          '3562107010112',
          '3562107010151',
          '3562107010154',
          '3562107010202',
          '3562107010203',
          '3562107010204',
          '3562107010301',
          '3562107010401',
          '3562107010402',
          '3562107010403',
          '3562107010404',
          '3562107020101',
          '3562107020151',
          '3562107020200',
          '3562107020301',
          '3562107020302',
          '3562107020303',
          '3562107020304',
          '3562107020305',
          '3562107020330',
          '3562107020354',
          '3562107030100',
          '3562107040001',
          '3562107040002',
          '3562107040003',
          '3562107040004',
          '3562107040005',
          '3562107040006',
          '3562107040052',
          '3562107040053',
          '3562107050101',
          '3562107050102',
          '3562107050103',
          '3562107050104',
          '3562107050105',
          '3562107050106',
          '3562107050107',
          '3562107050201',
          '3562107050301',
          '3562107050302',
          '3562107050303',
          '3562107050304',
          '3562107060001',
          '3562107060002',
          '3562107060051',
          '3562107070001',
          '3562107070002',
          '3562107070003',
          '3562107070004',
          '3562107070006',
          '3562107070051',
          '3562108000101',
          '3562108000102',
          '3562108000103',
          '3562108000104',
          '3562108000105',
          '3562108000106',
          '3562108000107',
          '3562108000108',
          '3562108000109',
          '3562108000110',
          '3562108000111',
          '3562108000156',
          '3562108000162',
          '3562108000201',
          '3562108000301',
          '3562109000101',
          '3562109000102',
          '3562109000103',
          '3562109000104',
          '3562109000201',
          '3562109000202',
          '3562109000203',
          '3562109000204',
          '3562109000301',
          '3562109000302',
          '3562110010101',
          '3562110010102',
          '3562110010103',
          '3562110010104',
          '3562110010105',
          '3562110010106',
          '3562110010130',
          '3562110010201',
          '3562110010202',
          '3562110010203',
          '3562110010204',
          '3562110010230',
          '3562110010301',
          '3562110010302',
          '3562110010303',
          '3562110010304',
          '3562110010305',
          '3562110010306',
          '3562110010330',
          '3562110010401',
          '3562110010402',
          '3562110010403',
          '3562110010404',
          '3562110010405',
          '3562110010406',
          '3562110010430',
          '3562110010454',
          '3562110010456',
          '3562110010501',
          '3562110020101',
          '3562110020102',
          '3562110020103',
          '3562110020201',
          '3562110020202',
          '3562110020203',
          '3562110020204',
          '3562110020205',
          '3562110020206',
          '3562110020207',
          '3562110020208',
          '3562110020209',
          '3562110020210',
          '3562110020211',
          '3562110020212',
          '3562110020231',
          '3562110020301',
          '3562110020401',
          '3562110020402',
          '3562110020403',
          '3562110020404',
          '3562110020405',
          '3562110020406',
          '3562110020407',
          '3562110020408',
          '3562110020409',
          '3562110020410',
          '3562110020411',
          '3562110020412',
          '3562110020413',
          '3562110020414',
          '3562110020415',
          '3562110020416',
          '3562110020417',
          '3562110020418',
          '3562110020419',
          '3562110020420',
          '3562110020421',
          '3562110020422',
          '3562110020423',
          '3562110020424',
          '3562110020425',
          '3562110020501',
          '3562110020502',
          '3562110020503',
          '3562110020601',
          '3562110020602',
          '3562110020603',
          '3562110020701',
          '3562110020702',
          '3562110020703',
          '3562110020704',
          '3562110020705',
          '3562110020706',
          '3562110020707',
          '3562110020751',
          '3562110020752',
          '3562110020801',
          '3562110020802',
          '3562110020803',
          '3562110020804',
          '3562110020805',
          '3562110020806',
          '3562110020901',
          '3562110020902',
          '3562110020903',
          '3562110020904',
          '3562110020905',
          '3562110021001',
          '3562110021002',
          '3562110021003',
          '3562110021005',
          '3562110021006',
          '3562110021008',
          '3562110030101',
          '3562111010101',
          '3562111010102',
          '3562111010103',
          '3562111010104',
          '3562111010200',
          '3562111020000',
          '3562111030101',
          '3562111030102',
          '3562111030200',
          '3562111030301',
          '3562111030302',
          '3562111030303',
          '3562111030304',
          '3562111030305',
          '3562111030306',
          '3562111030320',
          '3562111030355',
          '3562111040101',
          '3562111040102',
          '3562111040103',
          '3562111040200',
          '3562111050100',
          '3562111050200',
          '3562111050300',
          '3562111050400',
          '3562111050500',
          '3562111060101',
          '3562111060201',
          '3562111070101',
          '3562111070102',
          '3562111070103',
          '3562111070104',
          '3562111070105',
          '3562111070106',
          '3562111070107',
          '3562111070108',
          '3562111070130',
          '3562111070131',
          '3562111070132',
          '3562111080100',
          '3562111080200',
          '3562111080300',
          '3562112010101',
          '3562112010102',
          '3562112010103',
          '3562112010104',
          '3562112010105',
          '3562112010106',
          '3562112010107',
          '3562112010108',
          '3562112010109',
          '3562112010110',
          '3562112010111',
          '3562112010113',
          '3562112010120',
          '3562112010130',
          '3562112010200',
          '3562112020001',
          '3562112020002',
          '3562112020003',
          '3562112020004',
          '3562112020005',
          '3562112020030',
          '3562112020051',
          '3562112030101',
          '3562112030102',
          '3562112030201',
          '3562112030202',
          '3562112030203',
          '3562112030301',
          '3562112030302',
          '3562112030303',
          '3562112030304',
          '3562112030305',
          '3562112030306',
          '3562112030307',
          '3562112030308',
          '3562112030309',
          '3562112030310',
          '3562112030330',
          '3562112030401',
          '3562112030402',
          '3562112030451',
          '3562112030501',
          '3562112030502',
          '3562112030600',
          '3562113000000',
          '3562114010101',
          '3562114010102',
          '3562114010103',
          '3562114010104',
          '3562114010105',
          '3562114010106',
          '3562114010107',
          '3562114010108',
          '3562114010109',
          '3562114010201',
          '3562114010202',
          '3562114010203',
          '3562114020000',
          '3562115000101',
          '3562115000102',
          '3562115000103',
          '3562115000104',
          '3562115000105',
          '3562115000106',
          '3562115000107',
          '3562115000201',
          '3562115000202',
          '3562115000203',
          '3562115000204',
          '3562115000205',
          '3562115000206',
          '3562115000207',
          '3562115000208',
          '3562115000209',
          '3562115000210',
          '3562115000211',
          '3562115000212',
          '3562115000213',
          '3562115000214',
          '3562115000215',
          '3562115000216',
          '3562115000217',
          '3562115000218',
          '3562115000301',
          '3562115000302',
          '3562115000303',
          '3562115000304',
          '3562115000305',
          '3562115000306',
          '3562115000307',
          '3562115000308',
          '3562115000309',
          '3562115000310',
          '3562115000311',
          '3562115000312',
          '3562115000313',
          '3562115000314',
          '3562115000315',
          '3562201000101',
          '3562201000102',
          '3562201000103',
          '3562201000104',
          '3562201000107',
          '3562201000108',
          '3562201000109',
          '3562201000110',
          '3562201000111',
          '3562201000112',
          '3562201000201',
          '3562201000202',
          '3562201000204',
          '3562201000205',
          '3562201000206',
          '3562201000207',
          '3562201000208',
          '3562201000209',
          '3562201000210',
          '3562201000211',
          '3562201000212',
          '3562201000251',
          '3562201000301',
          '3562201000302',
          '3562201000303',
          '3562201000304',
          '3562201000305',
          '3562201000306',
          '3562201000307',
          '3562201000308',
          '3562201000309',
          '3562201000310',
          '3562201000311',
          '3562201000313',
          '3562201000315',
          '3562201000316',
          '3562201000317',
          '3562201000318',
          '3562201000319',
          '3562201000321',
          '3562201000322',
          '3562201000323',
          '3562201000324',
          '3562201000325',
          '3562201000326',
          '3562201000327',
          '3562201000330',
          '3562201000356',
          '3562201000401',
          '3562201000402',
          '3562201000403',
          '3562201000404',
          '3562201000405',
          '3562201000406',
          '3562201000407',
          '3562201000408',
          '3562201000409',
          '3562201000410',
          '3562201000411',
          '3562201000412',
          '3562201000501',
          '3562201000502',
          '3562201000503',
          '3562201000504',
          '3562201000505',
          '3562201000506',
          '3562201000507',
          '3562201000601',
          '3562201000602',
          '3562201000603',
          '3562201000701',
          '3562201000704',
          '3562201000705',
          '3562201000707',
          '3562202010101',
          '3562202010102',
          '3562202010103',
          '3562202010201',
          '3562202010202',
          '3562202010204',
          '3562202020101',
          '3562202020102',
          '3562202020201',
          '3562202020301',
          '3562202020302',
          '3562202020303',
          '3562202020305',
          '3562202020306',
          '3562202020307',
          '3562202020308',
          '3562202020309',
          '3562202020330',
          '3562202020401',
          '3562202020402',
          '3562202020403',
          '3562202020404',
          '3562202020405',
          '3562202020406',
          '3562202020407',
          '3562202020408',
          '3562202020410',
          '3562202020411',
          '3562202020413',
          '3562202020414',
          '3562202020430',
          '3562202020501',
          '3562202020502',
          '3562202020503',
          '3562202020504',
          '3562202020505',
          '3562202020506',
          '3562202020507',
          '3562202020508',
          '3562202020509',
          '3562203010101',
          '3562203010102',
          '3562203010103',
          '3562203010104',
          '3562203010105',
          '3562203010106',
          '3562203010107',
          '3562203010108',
          '3562203010109',
          '3562203010110',
          '3562203010111',
          '3562203010201',
          '3562203010202',
          '3562203010203',
          '3562203010204',
          '3562203010205',
          '3562203010207',
          '3562203010208',
          '3562203010209',
          '3562203010210',
          '3562203010303',
          '3562203010306',
          '3562203010401',
          '3562203010402',
          '3562203010403',
          '3562203010404',
          '3562203010501',
          '3562203010502',
          '3562203010503',
          '3562203010504',
          '3562203010510',
          '3562203020101',
          '3562203020102',
          '3562203020103',
          '3562203020104',
          '3562203020105',
          '3562203020151',
          '3562203020153',
          '3562203020201',
          '3562203020251',
          '3562203030001',
          '3562203030002',
          '3562203030003',
          '3562203030004',
          '3562203030005',
          '3562204010101',
          '3562204010102',
          '3562204010103',
          '3562204010105',
          '3562204010106',
          '3562204010107',
          '3562204010108',
          '3562204010109',
          '3562204010110',
          '3562204010201',
          '3562204010202',
          '3562204010203',
          '3562204010204',
          '3562204020101',
          '3562204020102',
          '3562204020103',
          '3562204020104',
          '3562204020110',
          '3562204020201',
          '3562204020202',
          '3562204020203',
          '3562204020204',
          '3562204020301',
          '3562204020302',
          '3562204030101',
          '3562204030102',
          '3562204030103',
          '3562204030104',
          '3562204030105',
          '3562204030106',
          '3562204030107',
          '3562204030108',
          '3562204030109',
          '3562204030110',
          '3562204030201',
          '3562204030202',
          '3562204030203',
          '3562204030204',
          '3562204030210',
          '3562204030301',
          '3562204030302',
          '3562204030303',
          '3562204030304',
          '3562204030310',
          '3562204040100',
          '3562204040200',
          '3562204050101',
          '3562204050102',
          '3562204050103',
          '3562204050104',
          '3562204050105',
          '3562204050106',
          '3562204050107',
          '3562204050108',
          '3562204050109',
          '3562204050110',
          '3562204050111',
          '3562204050112',
          '3562204050113',
          '3562204050114',
          '3562204050115',
          '3562204050116',
          '3562204050117',
          '3562204050130',
          '3562204050131',
          '3562204050201',
          '3562204050202',
          '3562204050203',
          '3562204050300',
          '3562204050402',
          '3562204060100',
          '3562204060200',
          '3562205000102',
          '3562205000103',
          '3562205000104',
          '3562205000107',
          '3562205000110',
          '3562205000155',
          '3562205000201',
          '3562205000301',
          '3562205000302',
          '3562205000303',
          '3562205000304',
          '3562205000305',
          '3562301010101',
          '3562301010102',
          '3562301010103',
          '3562301010104',
          '3562301010105',
          '3562301010106',
          '3562301010107',
          '3562301010108',
          '3562301010109',
          '3562301010152',
          '3562301010201',
          '3562301010251',
          '3562301010301',
          '3562301010303',
          '3562301010402',
          '3562301020101',
          '3562301020102',
          '3562301020103',
          '3562301020104',
          '3562301020105',
          '3562301020108',
          '3562301020112',
          '3562301020113',
          '3562301020151',
          '3562301020171',
          '3562301020201',
          '3562301020202',
          '3562301020203',
          '3562301020204',
          '3562301020303',
          '3562301020304',
          '3562301020307',
          '3562301020308',
          '3562301020309',
          '3562301020401',
          '3562301020402',
          '3562301020403',
          '3562301020404',
          '3562301020405',
          '3562301020406',
          '3562301020407',
          '3562301020501',
          '3562301020507',
          '3562301020511',
          '3562301030101',
          '3562301030102',
          '3562301030103',
          '3562301030104',
          '3562301030105',
          '3562301030106',
          '3562301030107',
          '3562301030108',
          '3562301030109',
          '3562301030110',
          '3562301030111',
          '3562301030112',
          '3562301030113',
          '3562301030114',
          '3562301030115',
          '3562301030116',
          '3562301030117',
          '3562301030118',
          '3562301030119',
          '3562301030121',
          '3562301030122',
          '3562301030123',
          '3562301030124',
          '3562301030125',
          '3562301030126',
          '3562301030127',
          '3562301030130',
          '3562301030151',
          '3562301030201',
          '3562301030202',
          '3562301030203',
          '3562301030204',
          '3562301030306',
          '3562301030307',
          '3562301030308',
          '3562301030309',
          '3562301040102',
          '3562301040104',
          '3562301040105',
          '3562301040107',
          '3562301040108',
          '3562301040109',
          '3562301040113',
          '3562301040114',
          '3562301040120',
          '3562301040138',
          '3562301040152',
          '3562301040154',
          '3562301040155',
          '3562301040157',
          '3562301040158',
          '3562301040159',
          '3562301040163',
          '3562301040170',
          '3562301040201',
          '3562301040301',
          '3562301040302',
          '3562301040303',
          '3562301040304',
          '3562301040305',
          '3562301040306',
          '3562301040307',
          '3562301040308',
          '3562301040309',
          '3562301040310',
          '3562301040311',
          '3562301040312',
          '3562301040313',
          '3562301040314',
          '3562301040315',
          '3562301040316',
          '3562301040318',
          '3562301040319',
          '3562301040321',
          '3562301040351',
          '3562301040352',
          '3562301040353',
          '3562301040354',
          '3562301050101',
          '3562301050202',
          '3562301050203',
          '3562301050204',
          '3562301050205',
          '3562301050206',
          '3562301050207',
          '3562301050209',
          '3562301050210',
          '3562301050211',
          '3562301050213',
          '3562301050215',
          '3562301050216',
          '3562301050217',
          '3562301050218',
          '3562301050221',
          '3562301050222',
          '3562301050223',
          '3562301050300',
          '3562302010101',
          '3562302010102',
          '3562302010103',
          '3562302010104',
          '3562302010105',
          '3562302010106',
          '3562302010107',
          '3562302010152',
          '3562302010153',
          '3562302010157',
          '3562302010201',
          '3562302010202',
          '3562302010301',
          '3562302010302',
          '3562302010304',
          '3562302010305',
          '3562302010306',
          '3562302020101',
          '3562302020201',
          '3562302020300',
          '3562302030101',
          '3562302030102',
          '3562302030103',
          '3562302030104',
          '3562302030106',
          '3562302030201',
          '3562302030202',
          '3562302030203',
          '3562302030204',
          '3562302030205',
          '3562302030206',
          '3562302030207',
          '3562302040101',
          '3562302040201',
          '3562302040202',
          '3562302040203',
          '3562302040204',
          '3562302040301',
          '3562302040401',
          '3562302040501',
          '3562302050101',
          '3562302050201',
          '3562302050301',
          '3562302050401',
          '3562302050501',
          '3562302050502',
          '3562302050503',
          '3562302050504',
          '3562302050505',
          '3562302050552',
          '3562302060101',
          '3562302060102',
          '3562302060103',
          '3562302060104',
          '3562302060107',
          '3562302060108',
          '3562302060109',
          '3562302060150',
          '3562302060151',
          '3562302060152',
          '3562302060171',
          '3562302060201',
          '3562302060301',
          '3562302060305',
          '3562302060351',
          '3562302060401',
          '3562302060501',
          '3562302060601',
          '3562302060602',
          '3562302060603',
          '3562302060651',
          '3562302060673',
          '3562302060701',
          '3562302060801',
          '3562302060900',
          '3562302061001',
          '3562302070000',
          '3562303010101',
          '3562303010102',
          '3562303010103',
          '3562303010104',
          '3562303010105',
          '3562303010106',
          '3562303010107',
          '3562303010108',
          '3562303010109',
          '3562303010113',
          '3562303010201',
          '3562303010202',
          '3562303010203',
          '3562303010204',
          '3562303010205',
          '3562303010206',
          '3562303010207',
          '3562303010208',
          '3562303010209',
          '3562303010301',
          '3562303010302',
          '3562303010401',
          '3562303020102',
          '3562303020103',
          '3562303020104',
          '3562303020105',
          '3562303020107',
          '3562303020108',
          '3562303020109',
          '3562303020110',
          '3562303020111',
          '3562303020112',
          '3562303020113',
          '3562303020182',
          '3562303020202',
          '3562303020203',
          '3562303020204',
          '3562303020205',
          '3562303020207',
          '3562303020301',
          '3562303020401',
          '3562303020500',
          '3562303020603',
          '3562303030101',
          '3562303030102',
          '3562303030103',
          '3562303030104',
          '3562303030201',
          '3562303030202',
          '3562303030301',
          '3562303030302',
          '3562303030401',
          '3562303030501',
          '3562303040101',
          '3562303040102',
          '3562303040103',
          '3562303040104',
          '3562303040201',
          '3562303040202',
          '3562303050101',
          '3562303050102',
          '3562303050103',
          '3562303050104',
          '3562303050105',
          '3562303050106',
          '3562303050107',
          '3562303050112',
          '3562303050113',
          '3562303050114',
          '3562303050201',
          '3562303050202',
          '3562303060101',
          '3562303060102',
          '3562304000101',
          '3562304000102',
          '3562304000131',
          '3562304000201',
          '3562304000202',
          '3562304000301',
          '3562304000302',
          '3562304000303',
          '3562304000307',
          '3562304000401',
          '3562304000402',
          '3562304000403',
          '3562304000404',
          '3562304000501',
          '3562304000502',
          '3562304000504',
          '3562304000551',
          '3562304000554',
          '3562304000601',
          '3562304000701',
          '3562304000702',
          '3562304000707',
          '3562304000710',
          '3562304000711',
          '3562304000717',
          '3562304000719',
          '3562304000720',
          '3562304000721',
          '3562304000723',
          '3562304000724',
          '3562304000726',
          '3562304000727',
          '3562304000728',
          '3562304000730',
          '3562304000732',
          '3562305010101',
          '3562305010104',
          '3562305010105',
          '3562305010106',
          '3562305010108',
          '3562305010109',
          '3562305010110',
          '3562305010111',
          '3562305010112',
          '3562305010200',
          '3562305010301',
          '3562305010302',
          '3562305010303',
          '3562305010304',
          '3562305010305',
          '3562305010306',
          '3562305010307',
          '3562305010401',
          '3562305010402',
          '3562305010403',
          '3562305010501',
          '3562305010502',
          '3562305010503',
          '3562305010504',
          '3562305010505',
          '3562305020101',
          '3562305020102',
          '3562305020103',
          '3562305020104',
          '3562305020151',
          '3562305020153',
          '3562305020201',
          '3562305020202',
          '3562305020203',
          '3562305020204',
          '3562305020205',
          '3562305020256',
          '3562305020301',
          '3562305020351',
          '3562305030101',
          '3562305030102',
          '3562305030103',
          '3562305030104',
          '3562305030105',
          '3562305030151',
          '3562305030153',
          '3562305030154',
          '3562305030201',
          '3562305030202',
          '3562305030203',
          '3562306010101',
          '3562306010102',
          '3562306010103',
          '3562306010105',
          '3562306010106',
          '3562306010107',
          '3562306010112',
          '3562306010114',
          '3562306010115',
          '3562306010116',
          '3562306010117',
          '3562306010119',
          '3562306010123',
          '3562306010127',
          '3562306010201',
          '3562306010202',
          '3562306010203',
          '3562306010204',
          '3562306010205',
          '3562306010206',
          '3562306010207',
          '3562306010208',
          '3562306010209',
          '3562306010210',
          '3562306010211',
          '3562306010212',
          '3562306010213',
          '3562306010214',
          '3562306010301',
          '3562306010302',
          '3562306020102',
          '3562306020105',
          '3562306020106',
          '3562306020107',
          '3562306020112',
          '3562306020168',
          '3562306020202',
          '3562306020203',
          '3562306020204',
          '3562306020206',
          '3562306020207',
          '3562306020212',
          '3562306020252',
          '3562306020301',
          '3562306030102',
          '3562306030103',
          '3562306030117',
          '3562306030123',
          '3562306030202',
          '3562306030203',
          '3562306030253',
          '3562306030301',
          '3562306040106',
          '3562306040201',
          '3562306050100',
          '3562306050200',
          '3562306060102',
          '3562306060103',
          '3562306060107',
          '3562306060112',
          '3562306060113',
          '3562306060201',
          '3562306060202',
          '3562306060203',
          '3562306060204',
          '3562306060205',
          '3562306060206',
          '3562307010101',
          '3562307010102',
          '3562307010103',
          '3562307010104',
          '3562307010105',
          '3562307010106',
          '3562307010107',
          '3562307010108',
          '3562307010109',
          '3562307010110',
          '3562307010111',
          '3562307010112',
          '3562307010113',
          '3562307010114',
          '3562307010115',
          '3562307010116',
          '3562307010155',
          '3562307010201',
          '3562307020101',
          '3562307020102',
          '3562307020151',
          '3562307020201',
          '3562307030101',
          '3562307030102',
          '3562307030201',
          '3562307030202',
          '3562307040001',
          '3562307040002',
          '3562308010001',
          '3562308010002',
          '3562308010003',
          '3562308010004',
          '3562308010005',
          '3562308010006',
          '3562308010007',
          '3562308010008',
          '3562308010009',
          '3562308010010',
          '3562308010011',
          '3562308010012',
          '3562308010013',
          '3562308010014',
          '3562308010015',
          '3562308010016',
          '3562308020101',
          '3562308020102',
          '3562308020103',
          '3562308020104',
          '3562308020105',
          '3562308020106',
          '3562308020107',
          '3562308020108',
          '3562308020109',
          '3562308020112',
          '3562308020113',
          '3562308020115',
          '3562308020202',
          '3562308020203',
          '3562308020204',
          '3562308020205',
          '3562308020206',
          '3562308020207',
          '3562308020210',
          '3562308020212',
          '3562308020301',
          '3562308020302',
          '3562308020303',
          '3562308030001',
          '3562308030002',
          '3562308030003',
          '3562308030004',
          '3562308030005',
          '3562308030006',
          '3562308030007',
          '3562308030008',
          '3562308030009',
          '3562308030010',
          '3562308040101',
          '3562308040102',
          '3562308040103',
          '3562308040104',
          '3562308040106',
          '3562308040107',
          '3562308040108',
          '3562308040109',
          '3562308040110',
          '3562308040201',
          '3562308040202',
          '3562308040204',
          '3562308040205',
          '3562308040206',
          '3562308040207',
          '3562308040208',
          '3562308040301',
          '3562308040302',
          '3562308040303',
          '3562308040304',
          '3562308040305',
          '3562308040306',
          '3562308050101',
          '3562308050102',
          '3562308050152',
          '3562308050153',
          '3562308050154',
          '3562309010101',
          '3562309010102',
          '3562309010103',
          '3562309010104',
          '3562309010105',
          '3562309010106',
          '3562309010107',
          '3562309010108',
          '3562309010201',
          '3562309010202',
          '3562309010203',
          '3562309010204',
          '3562309010205',
          '3562309010206',
          '3562309010207',
          '3562309010208',
          '3562309010209',
          '3562309010210',
          '3562309010211',
          '3562309010301',
          '3562309010302',
          '3562309010303',
          '3562309010304',
          '3562309010401',
          '3562309010402',
          '3562309010403',
          '3562309010404',
          '3562309010405',
          '3562309010406',
          '3562309010452',
          '3562309010501',
          '3562309010502',
          '3562309010503',
          '3562309010505',
          '3562309010506',
          '3562309010507',
          '3562309010508',
          '3562309010509',
          '3562309010510',
          '3562309010511',
          '3562309010512',
          '3562309010513',
          '3562309010514',
          '3562309020101',
          '3562309020102',
          '3562309020103',
          '3562309020104',
          '3562309020105',
          '3562309020106',
          '3562309020201',
          '3562309020202',
          '3562309020203',
          '3562309020204',
          '3562309020205',
          '3562309020206',
          '3562309020207',
          '3562309020208',
          '3562309020209',
          '3562309020210',
          '3562309020211',
          '3562309020212',
          '3562309020213',
          '3562309020214',
          '3562309020215',
          '3562401010101',
          '3562401010102',
          '3562401010103',
          '3562401010104',
          '3562401010106',
          '3562401010107',
          '3562401010108',
          '3562401010120',
          '3562401010201',
          '3562401010202',
          '3562401010203',
          '3562401010204',
          '3562401010205',
          '3562401010206',
          '3562401010207',
          '3562401010208',
          '3562401010209',
          '3562401010210',
          '3562401010211',
          '3562401010212',
          '3562401010213',
          '3562401010214',
          '3562401010215',
          '3562401010216',
          '3562401010217',
          '3562401010218',
          '3562401010219',
          '3562401010220',
          '3562401010252',
          '3562401010260',
          '3562401010301',
          '3562401010302',
          '3562401010303',
          '3562401010304',
          '3562401010305',
          '3562401010306',
          '3562401010307',
          '3562401010308',
          '3562401010309',
          '3562401010310',
          '3562401010311',
          '3562401010312',
          '3562401010313',
          '3562401010314',
          '3562401010315',
          '3562401010316',
          '3562401010317',
          '3562401010318',
          '3562401010319',
          '3562401010320',
          '3562401010321',
          '3562401010322',
          '3562401010323',
          '3562401010324',
          '3562401010354',
          '3562401020101',
          '3562401020102',
          '3562402010100',
          '3562402010200',
          '3562402010300',
          '3562402010400',
          '3562402010501',
          '3562402010551',
          '3562402010600',
          '3562402010700',
          '3562402020101',
          '3562402020102',
          '3562402020201',
          '3562402020202',
          '3562403010100',
          '3562403010201',
          '3562403010202',
          '3562403010203',
          '3562403010204',
          '3562403010205',
          '3562403010206',
          '3562403010209',
          '3562403010210',
          '3562403010211',
          '3562403010212',
          '3562403010213',
          '3562403020101',
          '3562403020102',
          '3562403020103',
          '3562403020152',
          '3562404010101',
          '3562404010102',
          '3562404010103',
          '3562404010104',
          '3562404010109',
          '3562404010110',
          '3562404010112',
          '3562404010113',
          '3562404010114',
          '3562404010115',
          '3562404010116',
          '3562404010122',
          '3562404010132',
          '3562404010133',
          '3562404010134',
          '3562404010201',
          '3562404010202',
          '3562404010203',
          '3562404010204',
          '3562404010205',
          '3562404010206',
          '3562404010207',
          '3562404010301',
          '3562405010100',
          '3562405010201',
          '3562405010251',
          '3562405010301',
          '3562405010302',
          '3562405010401',
          '3562405010402',
          '3562405010403',
          '3562405010404',
          '3562405010405',
          '3562405010452',
          '3562405020101',
          '3562405020102',
          '3562405020103',
          '3562405020201',
          '3562405020202',
          '3562405020351',
          '3562406010101',
          '3562406010102',
          '3562406010103',
          '3562406010104',
          '3562406010201',
          '3562406010202',
          '3562406010204',
          '3562406010205',
          '3562406010207',
          '3562406010208',
          '3562406010209',
          '3562406010210',
          '3562406010211',
          '3562406010212',
          '3562406010213',
          '3562406010214',
          '3562406020101',
          '3562406020102',
          '3562406020103',
          '3562406020104',
          '3562406020105',
          '3562406020106',
          '3562406020151',
          '3562406020201',
          '3562406020202',
          '3562406020203',
          '3562406020204',
          '3562406020205',
          '3562406020206',
          '3562406020207',
          '3562406020208',
          '3562406020209',
          '3562406020210',
          '3562406020211',
          '3562406020212',
          '3562406020253',
          '3562406020301',
          '3562406020302',
          '3562406030000',
          '3562407010101',
          '3562407010102',
          '3562407010103',
          '3562407010201',
          '3562407010202',
          '3562407010203',
          '3562407010204',
          '3562407010205',
          '3562407010206',
          '3562407010207',
          '3562407010208',
          '3562407010209',
          '3562407010210',
          '3562407010211',
          '3562407010219',
          '3562407010221',
          '3562407010230',
          '3562407010301',
          '3562407010302',
          '3562407010303',
          '3562407010304',
          '3562407010305',
          '3562407010306',
          '3562407010307',
          '3562407010308',
          '3562407010309',
          '3562407010310',
          '3562407010311',
          '3562407010312',
          '3562407010313',
          '3562407010314',
          '3562407010315',
          '3562407010316',
          '3562407010317',
          '3562407010318',
          '3562407010319',
          '3562407010321',
          '3562407010401',
          '3562407010402',
          '3562407020101',
          '3562407020104',
          '3562407020201',
          '3562407020202',
          '3562407020203',
          '3562407020204',
          '3562407020301',
          '3562407020302',
          '3562407020303',
          '3562407020304',
          '3562407020400',
          '3562407030101',
          '3562407030102',
          '3562407030103',
          '3562407030201',
          '3562407030202',
          '3562407030203',
          '3562407030204',
          '3562407030205',
          '3562407030301',
          '3562407030302',
          '3562407030303',
          '3562407030304',
          '3562407030305',
          '3562407030306',
          '3562407030401',
          '3562407040101',
          '3562407040102',
          '3562407040201',
          '3562407040202',
          '3562407040203',
          '3562407040204',
          '3562407040205',
          '3562407040230',
          '3562407040301',
          '3562407040302',
          '3562407040303',
          '3562407040304',
          '3562407040305',
          '3562407040400',
          '3562408000101',
          '3562408000102',
          '3562408000103',
          '3562408000200',
          '3562408000301',
          '3562408000302',
          '3562408000303',
          '3562408000304',
          '3562408000305',
          '3562408000352',
          '3562408000400',
          '3562408000501',
          '3562408000502',
          '3562408000503',
          '3562408000504',
          '3562408000505',
          '3562408000506',
          '3562408000601',
          '3562408000701',
          '3562408000702',
          '3562408000703',
          '3562408000704',
          '3562408000801',
          '3562408000802',
          '3562408000803',
          '3562408000830',
          '3562408000901',
          '3562408000902',
          '3562408000903',
          '3562408000904',
          '3562408000905',
          '3562408000906',
          '3562408000908',
          '3562408000910',
          '3562408000957',
          '3562408000958',
          '3562408000959',
          '3562408001001',
          '3562408001002',
          '3562408001003',
          '3562408001004',
          '3562408001005',
          '3562408001006',
          '3562408001030',
          '3562408001101',
          '3562408001130',
          '3562408001201',
          '3562408001202',
          '3562408001203',
          '3562408001204',
          '3562408001205',
          '3562408001206',
          '3562408001207',
          '3562408001208',
          '3562408001253',
          '3562409000101',
          '3562409000102',
          '3562409000103',
          '3562409000104',
          '3562409000105',
          '3562409000106',
          '3562409000107',
          '3562409000108',
          '3562409000109',
          '3562409000110',
          '3562409000111',
          '3562409000112',
          '3562409000113',
          '3562409000201',
          '3562409000202',
          '3562409000300',
          '3562410010101',
          '3562410010102',
          '3562410010103',
          '3562410010201',
          '3562410010202',
          '3562410010203',
          '3562410010205',
          '3562410010301',
          '3562410010302',
          '3562410010303',
          '3562410010304',
          '3562410010305',
          '3562410010306',
          '3562410010351',
          '3562410010353',
          '3562410010354',
          '3562410010401',
          '3562410010451',
          '3562410010501',
          '3562410010502',
          '3562410010503',
          '3562410010504',
          '3562410010505',
          '3562410010506',
          '3562410010551',
          '3562410010552',
          '3562410010601',
          '3562410010602',
          '3562410010603',
          '3562410010604',
          '3562410010605',
          '3562410010630',
          '3562410020101',
          '3562410020201',
          '3562411000100',
          '3562411000201',
          '3562411000202',
          '3562411000203',
          '3562411000206',
          '3562411000208',
          '3562411000209',
          '3562411000230',
          '3562411000301',
          '3562411000400',
          '3562411000501',
          '3562411000502',
          '3562411000503',
          '3562411000504',
          '3562411000505',
          '3562411000506',
          '3562411000601',
          '3562411000602',
          '3562411000603',
          '3562411000604',
          '3562411000605',
          '3562411000606',
          '3562411000608',
          '3562411000609',
          '3562411000610',
          '3562411000611',
          '3562411000612',
          '3562411000613',
          '3562411000616',
          '3562411000618',
          '3562411000621',
          '3562411000622',
          '3562411000623',
          '3562411000624',
          '3562411000625',
          '3562411000652',
          '3562411000657',
          '3563001010101',
          '3563001010102',
          '3563001010103',
          '3563001010104',
          '3563001010105',
          '3563001010106',
          '3563001010107',
          '3563001010108',
          '3563001010109',
          '3563001010110',
          '3563001010111',
          '3563001010151',
          '3563001010201',
          '3563001010301',
          '3563001010302',
          '3563001010303',
          '3563001010305',
          '3563001010306',
          '3563001010330',
          '3563001010401',
          '3563001010402',
          '3563001010403',
          '3563001010501',
          '3563001010510',
          '3563001010601',
          '3563001010602',
          '3563001010604',
          '3563001010605',
          '3563001010606',
          '3563001010607',
          '3563001010608',
          '3563001010611',
          '3563001010612',
          '3563001010613',
          '3563001010614',
          '3563001010615',
          '3563001010616',
          '3563001010617',
          '3563001010618',
          '3563001010619',
          '3563001010620',
          '3563001010702',
          '3563001010801',
          '3563001010802',
          '3563001010803',
          '3563001010805',
          '3563001010806',
          '3563001010809',
          '3563001010811',
          '3563001010812',
          '3563001010813',
          '3563001010814',
          '3563001010815',
          '3563001010816',
          '3563001010817',
          '3563001010866',
          '3563001020100',
          '3563001020200',
          '3563001020300',
          '3563001020400',
          '3563001020500',
          '3563002010101',
          '3563002010102',
          '3563002010103',
          '3563002010201',
          '3563002010301',
          '3563002010302',
          '3563002010303',
          '3563002010304',
          '3563002010305',
          '3563002010306',
          '3563002010400',
          '3563002020101',
          '3563002020102',
          '3563002020103',
          '3563002020201',
          '3563002020202',
          '3563002030101',
          '3563002030102',
          '3563002030103',
          '3563002030201',
          '3563002030202',
          '3563002030203',
          '3563002030204',
          '3563002030205',
          '3563002030206',
          '3563002030301',
          '3563002030302',
          '3563002030303',
          '3563002030304',
          '3563002030401',
          '3563002030402',
          '3563002030403',
          '3563002030404',
          '3563003010101',
          '3563003010102',
          '3563003010103',
          '3563003010104',
          '3563003010105',
          '3563003010106',
          '3563003010107',
          '3563003010108',
          '3563003010109',
          '3563003010110',
          '3563003010111',
          '3563003010112',
          '3563003010113',
          '3563003010114',
          '3563003010115',
          '3563003010116',
          '3563003010117',
          '3563003010201',
          '3563003010202',
          '3563003010203',
          '3563003010204',
          '3563003010205',
          '3563003010206',
          '3563003010207',
          '3563003010208',
          '3563003010209',
          '3563003010301',
          '3563003010302',
          '3563003010303',
          '3563003010304',
          '3563003010305',
          '3563003010306',
          '3563003010307',
          '3563003010308',
          '3563003010309',
          '3563003010310',
          '3563003010401',
          '3563003010402',
          '3563003020101',
          '3563003020102',
          '3563003020103',
          '3563003020201',
          '3563003020202',
          '3563003030101',
          '3563003030103',
          '3563003030104',
          '3563003030106',
          '3563003030107',
          '3563003030109',
          '3563003030153',
          '3563003030157',
          '3563003030201',
          '3563003030202',
          '3563003030203',
          '3563003030204',
          '3563003030302',
          '3563003030303',
          '3563003030304',
          '3563003030305',
          '3563003030306',
          '3563003030307',
          '3563003030401',
          '3563003040101',
          '3563003040102',
          '3563003040103',
          '3563003040104',
          '3563003040201',
          '3563003040202',
          '3563003040203',
          '3563003040204',
          '3563003040205',
          '3563003040206',
          '3563003040207',
          '3563003040208',
          '3563003040301',
          '3563003040302',
          '3563003040303',
          '3563003040304',
          '3563003040306',
          '3563003040331',
          '3563003050101',
          '3563003050102',
          '3563003050103',
          '3563003050200',
          '3563003050301',
          '3563003060101',
          '3563003060102',
          '3563003060103',
          '3563003060104',
          '3563003060105',
          '3563003060106',
          '3563003060107',
          '3563003060108',
          '3563003060109',
          '3563003060110',
          '3563003060111',
          '3563003060112',
          '3563003060113',
          '3563003060114',
          '3563003060115',
          '3563003060116',
          '3563003060117',
          '3563003060201',
          '3563003060202',
          '3563003060203',
          '3563003060204',
          '3563003060205',
          '3563003060206',
          '3563003060207',
          '3563003060208',
          '3563003060209',
          '3563003060210',
          '3563003060211',
          '3563003060212',
          '3563003070101',
          '3563003070102',
          '3563003070103',
          '3563003070104',
          '3563003070105',
          '3563003070106',
          '3563003070107',
          '3563003070108',
          '3563003070109',
          '3563003070110',
          '3563003070130',
          '3563003070201',
          '3563003070202',
          '3563003070203',
          '3563003080101',
          '3563003080201',
          '3563003090101',
          '3563003090102',
          '3563003090201',
          '3563003090202',
          '3563003090251',
          '3563003090301',
          '3563003090302',
          '3563003090303',
          '3563003090304',
          '3563003090305',
          '3563003090401',
          '3563004010101',
          '3563004010103',
          '3563004010200',
          '3563004010301',
          '3563004010302',
          '3563004010303',
          '3563004010304',
          '3563004010305',
          '3563004010306',
          '3563004010307',
          '3563004010308',
          '3563004010309',
          '3563004010310',
          '3563004010311',
          '3563004010312',
          '3563004010313',
          '3563004010401',
          '3563004010402',
          '3563004010403',
          '3563004010404',
          '3563004010406',
          '3563004010407',
          '3563004010408',
          '3563004010409',
          '3563004010410',
          '3563004010411',
          '3563004010430',
          '3563004010452',
          '3563004010501',
          '3563004010503',
          '3563004010506',
          '3563004010510',
          '3563004010511',
          '3563004010512',
          '3563004010513',
          '3563004010514',
          '3563004010515',
          '3563004010516',
          '3563004020101',
          '3563004020102',
          '3563004020103',
          '3563004020104',
          '3563004020151',
          '3563004020152',
          '3563004020153',
          '3563004020201',
          '3563004020202',
          '3563004020301',
          '3563004020302',
          '3563004020303',
          '3563004020304',
          '3563101010101',
          '3563101010102',
          '3563101010201',
          '3563101010301',
          '3563101010302',
          '3563101010303',
          '3563101010304',
          '3563101010305',
          '3563101010306',
          '3563101010401',
          '3563101020101',
          '3563101020102',
          '3563101020103',
          '3563101020104',
          '3563101020105',
          '3563101020201',
          '3563101020202',
          '3563101020203',
          '3563101030101',
          '3563101030102',
          '3563101030201',
          '3563101030202',
          '3563101030203',
          '3563101030204',
          '3563101030205',
          '3563101030301',
          '3563101030302',
          '3563102010101',
          '3563102010102',
          '3563102010103',
          '3563102010201',
          '3563102010202',
          '3563102010203',
          '3563102010204',
          '3563102010205',
          '3563102010206',
          '3563102010207',
          '3563102010208',
          '3563102010209',
          '3563102010210',
          '3563102010211',
          '3563102010212',
          '3563102010213',
          '3563102010214',
          '3563102010215',
          '3563102010217',
          '3563102020001',
          '3563102030101',
          '3563103010101',
          '3563103010102',
          '3563103010103',
          '3563103010104',
          '3563103010105',
          '3563103020101',
          '3563103020102',
          '3563103020103',
          '3563103020201',
          '3563103020202',
          '3563103020252',
          '3563103020301',
          '3563103020401',
          '3563103020402',
          '3563103030001',
          '3563104010001',
          '3563105010001',
          '3563105010002',
          '3563105010003',
          '3563105020101',
          '3563105020102',
          '3563105020103',
          '3563105020104',
          '3563105020201',
          '3563105020202',
          '3563105020203',
          '3563105020204',
          '3563201010101',
          '3563201010102',
          '3563201010103',
          '3563201010104',
          '3563201010105',
          '3563201010106',
          '3563201010107',
          '3563201010108',
          '3563201010109',
          '3563201010110',
          '3563201010111',
          '3563201010112',
          '3563201010113',
          '3563201010120',
          '3563201010156',
          '3563201020001',
          '3563201020002',
          '3563201020052',
          '3563201030101',
          '3563201030102',
          '3563201030103',
          '3563201030104',
          '3563201030105',
          '3563201030106',
          '3563201030107',
          '3563201030108',
          '3563201030109',
          '3563201030110',
          '3563201030111',
          '3563201030112',
          '3563201030113',
          '3563201030114',
          '3563201030115',
          '3563201030116',
          '3563201030117',
          '3563201030118',
          '3563201030119',
          '3563201030120',
          '3563201030151',
          '3563201030201',
          '3563201030202',
          '3563201030203',
          '3563201030204',
          '3563201030205',
          '3563201030206',
          '3563201030207',
          '3563201030208',
          '3563201030209',
          '3563201030210',
          '3563201030230',
          '3563201030301',
          '3563201030302',
          '3563201030303',
          '3563201030304',
          '3563201030305',
          '3563201030306',
          '3563201030401',
          '3563201030402',
          '3563201030501',
          '3563201030502',
          '3563201030503',
          '3563201030504',
          '3563201030505',
          '3563201030550',
          '3563201040101',
          '3563201040102',
          '3563201040103',
          '3563201040104',
          '3563201040105',
          '3563201040106',
          '3563201040107',
          '3563201040108',
          '3563201040109',
          '3563201040110',
          '3563201040111',
          '3563201040112',
          '3563201040201',
          '3563201040202',
          '3563201040203',
          '3563201040204',
          '3563201040205',
          '3563201040206',
          '3563201040207',
          '3563201040208',
          '3563201040209',
          '3563201040210',
          '3563201040211',
          '3563201040212',
          '3563201040213',
          '3563201040214',
          '3563201040301',
          '3563201040302',
          '3563201040303',
          '3563201040304',
          '3563201040305',
          '3563201040306',
          '3563201040307',
          '3563201040308',
          '3563201040309',
          '3563201040310',
          '3563201040311',
          '3563201040312',
          '3563201040313',
          '3563201040314',
          '3563201040315',
          '3563201040316',
          '3563201040317',
          '3563201040318',
          '3563201040351',
          '3563201040352',
          '3563201040354',
          '3563201040362',
          '3563201040363',
          '3563201040364',
          '3563201040401',
          '3563201040402',
          '3563201040403',
          '3563201040501',
          '3563201040502',
          '3563201040602',
          '3563201040603',
          '3563201040604',
          '3563201040605',
          '3563201040606',
          '3563201040651',
          '3563201040652',
          '3563201040655',
          '3563201040701',
          '3563201040702',
          '3563201040703',
          '3563201040754',
          '3563201050101',
          '3563201050102',
          '3563201050103',
          '3563201050201',
          '3563201050202',
          '3563201050203',
          '3563201050204',
          '3563201050205',
          '3563201050206',
          '3563201050207',
          '3563201050208',
          '3563201050220',
          '3563201050301',
          '3563201050302',
          '3563201050303',
          '3563201050320',
          '3563201050401',
          '3563201050402',
          '3563201050403',
          '3563201050404',
          '3563201050405',
          '3563201050406',
          '3563201050407',
          '3563201050408',
          '3563201050409',
          '3563201050420',
          '3563201050501',
          '3563201050502',
          '3563201050503',
          '3563201050504',
          '3563201050505',
          '3563201050506',
          '3563201050507',
          '3563201060101',
          '3563201060102',
          '3563201060103',
          '3563201060105',
          '3563201060106',
          '3563201060107',
          '3563201060108',
          '3563201060109',
          '3563201060110',
          '3563201060111',
          '3563201060112',
          '3563201060113',
          '3563201060114',
          '3563201060115',
          '3563201060116',
          '3563201060201',
          '3563201060202',
          '3563201060301',
          '3563201060302',
          '3563201070101',
          '3563201070102',
          '3563201070201',
          '3563201070203',
          '3563201070204',
          '3563201070205',
          '3563201070206',
          '3563201070207',
          '3563201070208',
          '3563201070209',
          '3563201070210',
          '3563201070211',
          '3563201070212',
          '3563201070213',
          '3563201080101',
          '3563201080102',
          '3563201080103',
          '3563201080104',
          '3563201080105',
          '3563201080106',
          '3563201080107',
          '3563201080108',
          '3563201080109',
          '3563201080110',
          '3563201080111',
          '3563201080112',
          '3563201080113',
          '3563201080114',
          '3563201080115',
          '3563201080116',
          '3563201080117',
          '3563201080118',
          '3563201080119',
          '3563201080121',
          '3563201080122',
          '3563201080123',
          '3563201080201',
          '3563201080202',
          '3563201080203',
          '3563201080204',
          '3563201080205',
          '3563201080206',
          '3563201080207',
          '3563201080208',
          '3563201090001',
          '3563201090002',
          '3563201090003',
          '3563201090004',
          '3563201090005',
          '3563201090006',
          '3563201090007',
          '3563201090008',
          '3563201090009',
          '3563201090010',
          '3563201090011',
          '3563201090012',
          '3563201090013',
          '3563201100101',
          '3563201100102',
          '3563201100103',
          '3563201100104',
          '3563201100105',
          '3563201100201',
          '3563201100202',
          '3563201100301',
          '3563201100401',
          '3563201100402',
          '3563201100403',
          '3563201100501',
          '3563201100502',
          '3563201100503',
          '3563201100551',
          '3563201100601',
          '3563201100602',
          '3563201100603',
          '3563201100604',
          '3563201100605',
          '3563201100606',
          '3563201100607',
          '3563201100608',
          '3563201100609',
          '3563201100610',
          '3563201100611',
          '3563202000101',
          '3563202000102',
          '3563202000201',
          '3563202000202',
          '3563202000301',
          '3563202000302',
          '3563202000303',
          '3563202000304',
          '3563202000305',
          '3563202000401',
          '3563202000404',
          '3563202000405',
          '3563202000406',
          '3563203010101',
          '3563203010102',
          '3563203010103',
          '3563203010201',
          '3563203010202',
          '3563203010203',
          '3563203010204',
          '3563203010205',
          '3563203010230',
          '3563203010301',
          '3563203010351',
          '3563203010401',
          '3563203010402',
          '3563203010403',
          '3563203010501',
          '3563203010502',
          '3563203010503',
          '3563203010504',
          '3563203010505',
          '3563203010506',
          '3563203010507',
          '3563203010601',
          '3563203010602',
          '3563203010603',
          '3563203010604',
          '3563203010605',
          '3563203010606',
          '3563203010607',
          '3563203010608',
          '3563203020001',
          '3563203020002',
          '3563203020003',
          '3563204010101',
          '3563204010201',
          '3563204010202',
          '3563204010203',
          '3563204010204',
          '3563204010206',
          '3563204010209',
          '3563204010211',
          '3563204010212',
          '3563204010213',
          '3563204010214',
          '3563204010215',
          '3563204010302',
          '3563204010303',
          '3563204010401',
          '3563204010402',
          '3563204010501',
          '3563204010502',
          '3563204010503',
          '3563204010504',
          '3563204010505',
          '3563204010507',
          '3563204010508',
          '3563204010509',
          '3563204010510',
          '3563204010601',
          '3563204010602',
          '3563204010603',
          '3563204010604',
          '3563204010605',
          '3563204010606',
          '3563204010607',
          '3563204010608',
          '3563204010609',
          '3563204010610',
          '3563204010611',
          '3563204010612',
          '3563204010613',
          '3563204010701',
          '3563204010702',
          '3563204010703',
          '3563204010704',
          '3563204010705',
          '3563204010706',
          '3563204010801',
          '3563204010802',
          '3563204010803',
          '3563204010901',
          '3563204010902',
          '3563204010903',
          '3563204010904',
          '3563204010905',
          '3563204010906',
          '3563204010907',
          '3563204010908',
          '3563204010909',
          '3563204010951',
          '3563204010952',
          '3563204010953',
          '3563204010954',
          '3563204010955',
          '3563204010956',
          '3563204010957',
          '3563204011001',
          '3563204011002',
          '3563204011003',
          '3563204011004',
          '3563204011005',
          '3563204011006',
          '3563204011007',
          '3563204011008',
          '3563204011009',
          '3563204011010',
          '3563204011011',
          '3563204011012',
          '3563204011013',
          '3563204011014',
          '3563204011015',
          '3563204011016',
          '3563204011017',
          '3563204011018',
          '3563204011019',
          '3563204011020',
          '3563204011021',
          '3563204011022',
          '3563204011023',
          '3563204011024',
          '3563204011101',
          '3563204011102',
          '3563204011105',
          '3563204011106',
          '3563204011107',
          '3563204011108',
          '3563204011109',
          '3563204011110',
          '3563204011111',
          '3563204011112',
          '3563204011113',
          '3563204011117',
          '3563204011118',
          '3563204011119',
          '3563204011121',
          '3563204011123',
          '3563205010101',
          '3563205010102',
          '3563205010103',
          '3563205010104',
          '3563205010105',
          '3563205010106',
          '3563205020101',
          '3563205020102',
          '3563205020201',
          '3563205020202',
          '3563205020203',
          '3563205020204',
          '3563205020205',
          '3563205020206',
          '3563205020207',
          '3563205020208',
          '3563205020209',
          '3563205020210',
          '3563205020211',
          '3563205020212',
          '3563205020213',
          '3563205020214',
          '3563205020215',
          '3563205020216',
          '3563205020217',
          '3563205020218',
          '3563205020219',
          '3563205020221',
          '3563205020222',
          '3563205020230',
          '3563205020301',
          '3563206010101',
          '3563206010201',
          '3563206010301',
          '3563206010302',
          '3563206010351',
          '3563206010401',
          '3563206010501',
          '3563206010551',
          '3563206010552',
          '3563206010553',
          '3563206010601',
          '3563206010602',
          '3563206010603',
          '3563206010604',
          '3563206010605',
          '3563206010606',
          '3563206010607',
          '3563206010608',
          '3563206010609',
          '3563206010610',
          '3563206010701',
          '3563206010702',
          '3563206010751',
          '3563206010752',
          '3563206010801',
          '3563206010901',
          '3563206010902',
          '3563206010952',
          '3563206011001',
          '3563206011051',
          '3563206011052',
          '3563206011101',
          '3563206011201',
          '3563206011202',
          '3563206011203',
          '3563206011210',
          '3563206011301',
          '3563206011400',
          '3563206020101',
          '3563206020102',
          '3563206020103',
          '3563206020201',
          '3563206020202',
          '3563206020203',
          '3563206020301',
          '3563206020302',
          '3563206020320',
          '3563206020401',
          '3563206020451',
          '3563206020452',
          '3563206020453',
          '3563206020454',
          '3563206020501',
          '3563206020601',
          '3563206020602',
          '3563206020603',
          '3563206020604',
          '3563206020701',
          '3563206020801',
          '3563206020802',
          '3563206020901',
          '3563206020951',
          '3563206021001',
          '3563206021002',
          '3563206021003',
          '3563206021101',
          '3563206021201',
          '3563206021202',
          '3563206021203',
          '3563206021301',
          '3563206030001',
          '3563206030002',
          '3563206030003',
          '3563206030004',
          '3563206030005',
          '3563206030006',
          '3563206030007',
          '3563206030008',
          '3563206030009',
          '3563206030010',
          '3563206030011',
          '3563206030012',
          '3563206030013',
          '3563206040000',
          '3563301010101',
          '3563301010102',
          '3563301010103',
          '3563301010105',
          '3563301010106',
          '3563301010107',
          '3563301010108',
          '3563301010109',
          '3563301010201',
          '3563301010202',
          '3563301010203',
          '3563301010301',
          '3563301010302',
          '3563301010303',
          '3563301010401',
          '3563301010402',
          '3563301010403',
          '3563301010404',
          '3563301010405',
          '3563301010406',
          '3563301010407',
          '3563301010408',
          '3563301010501',
          '3563301010601',
          '3563301010602',
          '3563301010603',
          '3563301010604',
          '3563301020101',
          '3563301020103',
          '3563301020104',
          '3563301020105',
          '3563301020202',
          '3563301020203',
          '3563301020204',
          '3563301020205',
          '3563301020206',
          '3563301020207',
          '3563301020301',
          '3563301020302',
          '3563301020303',
          '3563301020304',
          '3563301020305',
          '3563301020306',
          '3563301020307',
          '3563301020308',
          '3563301020309',
          '3563301020352',
          '3563301020353',
          '3563301020359',
          '3563301030101',
          '3563301030102',
          '3563301030103',
          '3563301030104',
          '3563301030105',
          '3563301030106',
          '3563301030201',
          '3563301030202',
          '3563301030301',
          '3563301030401',
          '3563301030402',
          '3563301030403',
          '3563301030404',
          '3563301030501',
          '3563301040101',
          '3563301040201',
          '3563301040202',
          '3563301040203',
          '3563301040204',
          '3563301040205',
          '3563301040206',
          '3563301040207',
          '3563301040208',
          '3563301040209',
          '3563301040210',
          '3563301040211',
          '3563301040301',
          '3563301040302',
          '3563301040303',
          '3563301040304',
          '3563301050101',
          '3563301050102',
          '3563301050103',
          '3563301050104',
          '3563301050105',
          '3563301050201',
          '3563301050301',
          '3563301050302',
          '3563301050303',
          '3563301050305',
          '3563301050306',
          '3563301050307',
          '3563301050308',
          '3563301050309',
          '3563301050310',
          '3563301050311',
          '3563301050312',
          '3563301050313',
          '3563301050314',
          '3563301050315',
          '3563301050316',
          '3563301050317',
          '3563301050318',
          '3563301050319',
          '3563301050321',
          '3563301050322',
          '3563301050323',
          '3563301050324',
          '3563301050325',
          '3563301050326',
          '3563301050327',
          '3563301050328',
          '3563301050329',
          '3563301050331',
          '3563301050332',
          '3563301050401',
          '3563301050403',
          '3563301050404',
          '3563301050405',
          '3563301050406',
          '3563301050407',
          '3563301050501',
          '3563301050502',
          '3563301050503',
          '3563301050504',
          '3563301050505',
          '3563301050506',
          '3563301050507',
          '3563301050508',
          '3563301050509',
          '3563301050510',
          '3563301050511',
          '3563301050512',
          '3563301050513',
          '3563301050514',
          '3563301050515',
          '3563301050516',
          '3563301050517',
          '3563301050518',
          '3563301050519',
          '3563301050521',
          '3563301050522',
          '3563301050523',
          '3563301050524',
          '3563301050525',
          '3563301050526',
          '3563301050527',
          '3563301050528',
          '3563301050529',
          '3563301050531',
          '3563301050532',
          '3563301050533',
          '3563301050534',
          '3563301050535',
          '3563301050536',
          '3563301050537',
          '3563301050538',
          '3563301050539',
          '3563301050540',
          '3563301050541',
          '3563301050542',
          '3563301050543',
          '3563301050544',
          '3563301050545',
          '3563301050546',
          '3563301050547',
          '3563301050548',
          '3563301050550',
          '3563301050601',
          '3563301050602',
          '3563301050603',
          '3563301050605',
          '3563301050607',
          '3563301050608',
          '3563301050609',
          '3563301050610',
          '3563301050611',
          '3563301050614',
          '3563301050616',
          '3563301050617',
          '3563301050618',
          '3563301050619',
          '3563301050622',
          '3563301050623',
          '3563301050624',
          '3563301050625',
          '3563301050627',
          '3563301050629',
          '3563301050632',
          '3563301050633',
          '3563301050635',
          '3563301050636',
          '3563301050637',
          '3563301050638',
          '3563301050639',
          '3563301050640',
          '3563301050641',
          '3563301050642',
          '3563301050643',
          '3563301050644',
          '3563301050645',
          '3563301050646',
          '3563301050647',
          '3563301050648',
          '3563301050649',
          '3563301050650',
          '3563301050651',
          '3563301050652',
          '3563301050653',
          '3563301050654',
          '3563301050655',
          '3563301050656',
          '3563301050657',
          '3563301050658',
          '3563301050659',
          '3563301050660',
          '3563301050661',
          '3563301050701',
          '3563302010101',
          '3563302010102',
          '3563302010103',
          '3563302010104',
          '3563302010201',
          '3563302010202',
          '3563302010203',
          '3563302010301',
          '3563302010302',
          '3563302010303',
          '3563302010304',
          '3563302010305',
          '3563302010351',
          '3563302010400',
          '3563302020101',
          '3563302020102',
          '3563302020103',
          '3563302020201',
          '3563302020202',
          '3563302020203',
          '3563302020204',
          '3563302020205',
          '3563302020301',
          '3563302020302',
          '3563302020303',
          '3563302020304',
          '3563302020305',
          '3563302020306',
          '3563302020401',
          '3563302020402',
          '3563302020403',
          '3563303000102',
          '3563303000103',
          '3563303000109',
          '3563303000110',
          '3563303000112',
          '3563303000113',
          '3563303000114',
          '3563303000115',
          '3563303000116',
          '3563303000117',
          '3563303000201',
          '3563303000202',
          '3563303000203',
          '3563303000204',
          '3563303000205',
          '3563303000206',
          '3563303000207',
          '3563303000208',
          '3563303000209',
          '3563303000210',
          '3563303000211',
          '3563303000212',
          '3563303000213',
          '3563303000214',
          '3563303000215',
          '3563303000260',
          '3563303000261',
          '3563303000301',
          '3563303000302',
          '3563303000401',
          '3563303000402',
          '3563303000403',
          '3563303000404',
          '3563303000405',
          '3563303000407',
          '3563303000408',
          '3563303000409',
          '3563303000410',
          '3563303000411',
          '3563303000412',
          '3563303000413',
          '3563303000414',
          '3563303000415',
          '3563303000416',
          '3563303000417',
          '3563303000418',
          '3563303000419',
          '3563304000102',
          '3563304000103',
          '3563304000104',
          '3563304000106',
          '3563304000110',
          '3563304000113',
          '3563304000115',
          '3563304000118',
          '3563304000119',
          '3563304000121',
          '3563304000122',
          '3563304000123',
          '3563304000124',
          '3563304000125',
          '3563304000126',
          '3563304000127',
          '3563304000128',
          '3563304000129',
          '3563304000131',
          '3563304000132',
          '3563304000133',
          '3563304000134',
          '3563304000135',
          '3563304000136',
          '3563304000137',
          '3563304000138',
          '3563304000139',
          '3563304000140',
          '3563304000201',
          '3563304000202',
          '3563304000203',
          '3563304000204',
          '3563304000205',
          '3563304000206',
          '3563304000301',
          '3563304000302',
          '3563304000303',
          '3563304000304',
          '3563304000305',
          '3563304000307',
          '3563304000308',
          '3563304000309',
          '3563304000310',
          '3563304000311',
          '3563304000312',
          '3563304000313',
          '3563304000314',
          '3563304000315',
          '3563304000316',
          '3563304000317',
          '3563304000401',
          '3563304000402',
          '3563304000403',
          '3563304000501',
          '3563304000502',
          '3563304000503',
          '3563304000504',
          '3563304000505',
          '3563304000506',
          '3563304000507',
          '3563401010101',
          '3563401010102',
          '3563401010103',
          '3563401010105',
          '3563401010106',
          '3563401010201',
          '3563401010202',
          '3563401010203',
          '3563401010204',
          '3563401010205',
          '3563401010206',
          '3563401010207',
          '3563401010208',
          '3563401010209',
          '3563401010210',
          '3563401010211',
          '3563401010212',
          '3563401010213',
          '3563401010214',
          '3563401010215',
          '3563401010216',
          '3563401010217',
          '3563401010251',
          '3563401010255',
          '3563401010301',
          '3563401010302',
          '3563401010304',
          '3563401010305',
          '3563401010306',
          '3563401010356',
          '3563401010401',
          '3563401010402',
          '3563401010403',
          '3563401010404',
          '3563401010405',
          '3563401010406',
          '3563401010407',
          '3563401010408',
          '3563401010409',
          '3563401010410',
          '3563401010411',
          '3563401010412',
          '3563401010413',
          '3563401010414',
          '3563401010415',
          '3563401010416',
          '3563401010417',
          '3563401010418',
          '3563401010451',
          '3563401010452',
          '3563401010453',
          '3563401010456',
          '3563401010501',
          '3563401010502',
          '3563401010503',
          '3563401010504',
          '3563401010601',
          '3563401010602',
          '3563401010603',
          '3563401010604',
          '3563401010605',
          '3563401010606',
          '3563401010607',
          '3563401010701',
          '3563401010702',
          '3563401010704',
          '3563401010705',
          '3563401010707',
          '3563401010712',
          '3563401010713',
          '3563401010714',
          '3563401010717',
          '3563401010718',
          '3563401010721',
          '3563401010722',
          '3563401010723',
          '3563401010724',
          '3563401010725',
          '3563401010726',
          '3563401010768',
          '3563401020101',
          '3563401020102',
          '3563401020103',
          '3563401020200',
          '3563401030103',
          '3563401030201',
          '3563401030202',
          '3563401030203',
          '3563401030204',
          '3563401030205',
          '3563401030301',
          '3563401030302',
          '3563401030400',
          '3563402000101',
          '3563402000102',
          '3563402000103',
          '3563402000104',
          '3563402000105',
          '3563402000106',
          '3563402000107',
          '3563402000108',
          '3563402000109',
          '3563402000110',
          '3563402000111',
          '3563402000112',
          '3563402000113',
          '3563402000114',
          '3563402000115',
          '3563402000116',
          '3563402000117',
          '3563402000118',
          '3563402000119',
          '3563402000121',
          '3563402000122',
          '3563402000123',
          '3563402000124',
          '3563402000125',
          '3563402000126',
          '3563402000127',
          '3563402000128',
          '3563402000201',
          '3563402000202',
          '3563402000300',
          '3563402000402',
          '3563402000403',
          '3563402000405',
          '3563402000406',
          '3563402000410',
          '3563403010101',
          '3563403010102',
          '3563403010104',
          '3563403010201',
          '3563403010301',
          '3563403010302',
          '3563403010303',
          '3563403010304',
          '3563403010305',
          '3563403010306',
          '3563403010307',
          '3563403010308',
          '3563403010309',
          '3563403010310',
          '3563403010311',
          '3563403010401',
          '3563403020101',
          '3563403020102',
          '3563403020103',
          '3563403020104',
          '3563403020105',
          '3563403020151',
          '3563403020152',
          '3563403020153',
          '3563403020171',
          '3563403020172',
          '3563403020173',
          '3563403020202',
          '3563403020203',
          '3563403020252',
          '3563403020253',
          '3563403020272',
          '3563403020273',
          '3563403020301',
          '3563403020351',
          '3563403020401',
          '3563403020402',
          '3563403020403',
          '3563403020404',
          '3563403020405',
          '3563403020406',
          '3563403020407',
          '3563403020408',
          '3563403020409',
          '3563403020430',
          '3563403020455',
          '3563403030101',
          '3563404000101',
          '3563404000102',
          '3563404000103',
          '3563404000151',
          '3563404000201',
          '3563404000202',
          '3563404000203',
          '3563404000204',
          '3563404000205',
          '3563404000301',
          '3563404000302',
          '3563404000401',
          '3563404000402',
          '3563405010101',
          '3563405010102',
          '3563405010103',
          '3563405010104',
          '3563405010105',
          '3563405010106',
          '3563405010107',
          '3563405010108',
          '3563405010201',
          '3563405010202',
          '3563405010203',
          '3563405010204',
          '3563405010205',
          '3563405010206',
          '3563405010207',
          '3563405010208',
          '3563405010209',
          '3563405010301',
          '3563405010302',
          '3563405010401',
          '3563405010402',
          '3563405010403',
          '3563405010404',
          '3563405010405',
          '3563405010406',
          '3563405010453',
          '3563406000101',
          '3563406000172',
          '3563406000201',
          '3563406000202',
          '3563406000203',
          '3563406000252',
          '3563406000301',
          '3563406000302',
          '3563406000303',
          '3563406000304',
          '3563406000305',
          '3563406000306',
          '3563406000307',
          '3563501010101',
          '3563501010102',
          '3563501010201',
          '3563501010202',
          '3563501010204',
          '3563501010205',
          '3563501010206',
          '3563501010207',
          '3563501010208',
          '3563501010301',
          '3563501010302',
          '3563501010303',
          '3563501010401',
          '3563501010501',
          '3563501010502',
          '3563501010503',
          '3563501010504',
          '3563501010505',
          '3563501010506',
          '3563501010551',
          '3563501010601',
          '3563501010603',
          '3563501010604',
          '3563501010605',
          '3563501010607',
          '3563501010610',
          '3563501010611',
          '3563501010613',
          '3563501010614',
          '3563501010615',
          '3563501010663',
          '3563501020101',
          '3563501020102',
          '3563501020103',
          '3563501020104',
          '3563501020105',
          '3563501020152',
          '3563501020153',
          '3563501020201',
          '3563501020202',
          '3563501020203',
          '3563501020204',
          '3563501020205',
          '3563501020206',
          '3563501020207',
          '3563501020208',
          '3563501020209',
          '3563501020210',
          '3563501020220',
          '3563501020251',
          '3563501020252',
          '3563501020253',
          '3563501020254',
          '3563501020257',
          '3563501020258',
          '3563501020301',
          '3563501020401',
          '3563501020402',
          '3563501020403',
          '3563501020404',
          '3563502010101',
          '3563502010102',
          '3563502010103',
          '3563502010104',
          '3563502010105',
          '3563502010108',
          '3563502010110',
          '3563502010151',
          '3563502010153',
          '3563502010155',
          '3563502010156',
          '3563502010158',
          '3563502010159',
          '3563502010179',
          '3563502010201',
          '3563502010202',
          '3563502010203',
          '3563502010252',
          '3563502010272',
          '3563502010301',
          '3563502010303',
          '3563502010304',
          '3563502010305',
          '3563502010352',
          '3563502010354',
          '3563502010374',
          '3563502010401',
          '3563502010402',
          '3563502010501',
          '3563502010601',
          '3563502010602',
          '3563502010701',
          '3563502010702',
          '3563502010703',
          '3563502010704',
          '3563502010801',
          '3563502010802',
          '3563502010803',
          '3563502010806',
          '3563502010807',
          '3563502010808',
          '3563502010809',
          '3563502010813',
          '3563502010814',
          '3563502010815',
          '3563502010817',
          '3563502010818',
          '3563502010819',
          '3563502010901',
          '3563502010902',
          '3563502010903',
          '3563502010905',
          '3563502010906',
          '3563502020101',
          '3563502020102',
          '3563502020103',
          '3563502020104',
          '3563502020105',
          '3563502020106',
          '3563502020107',
          '3563502020108',
          '3563502020109',
          '3563502020110',
          '3563502020111',
          '3563502020112',
          '3563502020114',
          '3563502020115',
          '3563502020116',
          '3563502020151',
          '3563502020155',
          '3563502020157',
          '3563502020159',
          '3563502020165',
          '3563502020171',
          '3563502020175',
          '3563502020177',
          '3563502020179',
          '3563502020201',
          '3563502020202',
          '3563502020203',
          '3563502020204',
          '3563502020205',
          '3563502020251',
          '3563502020252',
          '3563502020253',
          '3563502020254',
          '3563502020271',
          '3563502020272',
          '3563502020273',
          '3563502020274',
          '3563502020301',
          '3563502020303',
          '3563502020304',
          '3563502020305',
          '3563502020351',
          '3563502020353',
          '3563502020354',
          '3563502020371',
          '3563502020373',
          '3563502020374',
          '3563502020402',
          '3563502020403',
          '3563502020404',
          '3563502020405',
          '3563502020406',
          '3563502020407',
          '3563502020408',
          '3563502020409',
          '3563502020410',
          '3563502030101',
          '3563502030102',
          '3563502030104',
          '3563502030107',
          '3563502030151',
          '3563502030152',
          '3563502030172',
          '3563502030201',
          '3563502030301',
          '3563502030302',
          '3563502030303',
          '3563502030304',
          '3563502030305',
          '3563502030306',
          '3563502030307',
          '3563502030401',
          '3563502030402',
          '3563502030403',
          '3563502030405',
          '3563502030406',
          '3563502030407',
          '3563502030408',
          '3563503000101',
          '3563503000102',
          '3563503000103',
          '3563503000104',
          '3563503000130',
          '3563503000201',
          '3563503000202',
          '3563503000203',
          '3563503000204',
          '3563503000205',
          '3563503000252',
          '3563503000254',
          '3563503000301',
          '3563503000302',
          '3563503000303',
          '3563503000401',
          '3563503000402',
          '3563503000403',
          '3563503000451',
          '3563503000501',
          '3563503000601',
          '3563503000602',
          '3563503000603',
          '3563503000604',
          '3563503000701',
          '3563503000702',
          '3563503000703',
          '3563503000704',
          '3563503000705',
          '3563503000706',
          '3563503000803',
          '3563503000807',
          '3563503000809',
          '3563503000810',
          '3563503000811',
          '3563503000812',
          '3563503000813',
          '3563503000814',
          '3563503000815',
          '3563503000816',
          '3563503000817',
          '3563503000818',
          '3563503000819',
          '3563503000821',
          '3563503000822',
          '3563503000823',
          '3563503000824',
          '3563503000830',
          '3563504010101',
          '3563504010102',
          '3563504010103',
          '3563504010104',
          '3563504010105',
          '3563504010108',
          '3563504010109',
          '3563504010110',
          '3563504010111',
          '3563504010112',
          '3563504010201',
          '3563504010202',
          '3563504010301',
          '3563504010330',
          '3563504020101',
          '3563504020102',
          '3563504020103',
          '3563504020104',
          '3563504020105',
          '3563504020106',
          '3563504020201',
          '3563504020301',
          '3563504020302',
          '3563504020303',
          '3563504020304',
          '3563504020305',
          '3563504020306',
          '3563504020307',
          '3563504020308',
          '3563504020309',
          '3563504020401',
          '3563504020402',
          '3563504020403',
          '3563504020501',
          '3563504020502',
          '3563504020503',
          '3563504020504',
          '3563505010101',
          '3563505010102',
          '3563505010103',
          '3563505010104',
          '3563505010105',
          '3563505010106',
          '3563505010107',
          '3563505010201',
          '3563505010202',
          '3563505010203',
          '3563505010204',
          '3563505010205',
          '3563505010206',
          '3563505010207',
          '3563505010208',
          '3563505010209',
          '3563505010210',
          '3563505010301',
          '3563505010302',
          '3563505010303',
          '3563505010304',
          '3563505010401',
          '3563505010402',
          '3563505010403',
          '3563505010404',
          '3563505010405',
          '3563505010406',
          '3563505010407',
          '3563505010408',
          '3563505010409',
          '3563505010501',
          '3563505010502',
          '3563505010503',
          '3563505010504',
          '3563505010505',
          '3563505010601',
          '3563505010602',
          '3563505010603',
          '3563505010604',
          '3563505010605',
          '3563505010701',
          '3563505010702',
          '3563505010703',
          '3563505010801',
          '3563505010802',
          '3563505010803',
          '3563505010804',
          '3563505010805',
          '3563505010806',
          '3563505010901',
          '3563505010902',
          '3563505010903',
          '3563505010904',
          '3563505010905',
          '3563505010906',
          '3563505010907',
          '3563505011001',
          '3563505011107',
          '3563505011108',
          '3563505011110',
          '3563505011111',
          '3563505011112',
          '3563505011113',
          '3563505011114',
          '3563505011115',
          '3563505011116',
          '3563505011117',
          '3563505020101',
          '3563505020102',
          '3563505020103',
          '3563505020104',
          '3563505020105',
          '3563505020106',
          '3563505020107',
          '3563505020108',
          '3563505020109',
          '3563505020110',
          '3563505020111',
          '3563505020112',
          '3563505020113',
          '3563505020114',
          '3563505020115',
          '3563505020116',
          '3563505020117',
          '3563505020118',
          '3563505020119',
          '3563505020121',
          '3563505020122',
          '3563505020123',
          '3563505020124',
          '3563505020156',
          '3563505020201',
          '3563505020202',
          '3563505020251',
          '3563505020301',
          '3563505020303',
          '3563505020304',
          '3563505020351',
          '3563505020401',
          '3563505020501',
          '3563505020601',
          '3563505020602',
          '3563505020603',
          '3563505020604',
          '3563505020605',
          '3563505030101',
          '3563505030102',
          '3563505030103',
          '3563505030104',
          '3563505030105',
          '3563505030106',
          '3563505030107',
          '3563505030108',
          '3563505030109',
          '3563505030110',
          '3563505030111',
          '3563505030112',
          '3563505030115',
          '3563505030116',
          '3563505030119',
          '3563505030120',
          '3563505030121',
          '3563505030122',
          '3563505030201',
          '3563505030202',
          '3563505030301',
          '3563505030302',
          '3563505030303',
          '3563505030304',
          '3563505030305',
          '3563505030401',
          '3563505030402',
          '3563505030403',
          '3563505030404',
          '3563505030405',
          '3563505030406',
          '3563505030407',
          '3563505030408',
          '3563505030409',
          '3563505030410',
          '3563505030411',
          '3563505030412',
          '3563505030413',
          '3563505030501',
          '3563505030502',
          '3563505030503',
          '3563505030601',
          '3563505030602',
          '3563505030603',
          '3563505030604',
          '3563505030701',
          '3563505030702',
          '3563505030703',
          '3563505030801',
          '3563505030802',
          '3563505030803',
          '3563505030804',
          '3563505030805',
          '3563505030806',
          '3563505030807',
          '3563505030808',
          '3563505030809',
          '3563505030810',
          '3563505030811',
          '3563505030812',
          '3563505030813',
          '3563505030815',
          '3563505030816',
          '3563505030818',
          '3563505030819',
          '3563505030901',
          '3563505030902',
          '3563505030903',
          '3563505030904',
          '3563505030905',
          '3563505030906',
          '3563506010101',
          '3563506010102',
          '3563506010103',
          '3563506010104',
          '3563506010105',
          '3563506010106',
          '3563506010107',
          '3563506010108',
          '3563506010109',
          '3563506010110',
          '3563506010111',
          '3563506010112',
          '3563506010113',
          '3563506010114',
          '3563506010115',
          '3563506010116',
          '3563506010117',
          '3563506010118',
          '3563506010119',
          '3563506010121',
          '3563506010123',
          '3563506010202',
          '3563506010203',
          '3563506010204',
          '3563506010205',
          '3563506010206',
          '3563506010207',
          '3563506010208',
          '3563506010209',
          '3563506010210',
          '3563506010301',
          '3563506010302',
          '3563506010303',
          '3563506010304',
          '3563506010305',
          '3563506010306',
          '3563506010402',
          '3563506010403',
          '3563506010501',
          '3563506010502',
          '3563506010503',
          '3563506010504',
          '3563506010505',
          '3563506010506',
          '3563506010507',
          '3563506010508',
          '3563506010509',
          '3563506010510',
          '3563506010511',
          '3563506010512',
          '3563506010513',
          '3563506010514',
          '3563506010515',
          '3563506010516',
          '3563506010517',
          '3563506010518',
          '3563506010519',
          '3563506010521',
          '3563506010522',
          '3563506010523',
          '3563506010524',
          '3563506010525',
          '3563506010526',
          '3563506020101',
          '3563506020102',
          '3563506020103',
          '3563506020104',
          '3563506020105',
          '3563506020106',
          '3563506020107',
          '3563506020108',
          '3563506020109',
          '3563506020110',
          '3563506020111',
          '3563506020112',
          '3563506020113',
          '3563506020201',
          '3563506020202',
          '3563506020301',
          '3563506020302',
          '3563506020303',
          '3563506020304',
          '3563506020305',
          '3563506020306',
          '3563506020307',
          '3563506020308',
          '3563506020309',
          '3563506020310',
          '3563506020311',
          '3563506020312',
          '3563506020313',
          '3563506020314',
          '3563506020315',
          '3563506020316',
          '3563506020317',
          '3563506020318',
          '3563506020321',
          '3563506020322',
          '3563506030101',
          '3563506030102',
          '3563506030103',
          '3563506030200',
          '3563506040101',
          '3563506040102',
          '3563506040103',
          '3563506040104',
          '3563506040105',
          '3563506040152',
          '3563506040153',
          '3563506040201',
          '3563506040202',
          '3563506040230',
          '3563507010101',
          '3563507010102',
          '3563507010103',
          '3563507010130',
          '3563507010151',
          '3563507010201',
          '3563507010202',
          '3563507010301',
          '3563507010302',
          '3563507010303',
          '3563507020101',
          '3563507020103',
          '3563507020104',
          '3563507020201',
          '3563507020202',
          '3563507020203',
          '3563507020204',
          '3563507020205',
          '3563507020301',
          '3563507020302',
          '3563507020303',
          '3563507020304',
          '3563507020305',
          '3563507020306',
          '3563507020351',
          '3563507030101',
          '3563507030102',
          '3563507030103',
          '3563507030104',
          '3563507030152',
          '3563507040100',
          '3563507040201',
          '3563507040202',
          '3563507040203',
          '3563507040204',
          '3563507040205',
          '3563507040206',
          '3563507040207',
          '3563507040208',
          '3563507040210',
          '3563507040211',
          '3563507040212',
          '3563507040213',
          '3563507040214',
          '3563507040259',
          '3563601010101',
          '3563601010102',
          '3563601010104',
          '3563601010105',
          '3563601010152',
          '3563601010201',
          '3563601010202',
          '3563601010203',
          '3563601010204',
          '3563601010205',
          '3563601010206',
          '3563601010207',
          '3563601010251',
          '3563601010301',
          '3563601010302',
          '3563601010303',
          '3563601010304',
          '3563601010401',
          '3563601010402',
          '3563601010403',
          '3563601010453',
          '3563601010501',
          '3563601010502',
          '3563601010504',
          '3563601010505',
          '3563601010506',
          '3563601010507',
          '3563601010508',
          '3563601010509',
          '3563601010510',
          '3563601010511',
          '3563601010552',
          '3563601020101',
          '3563601020102',
          '3563601020103',
          '3563601020106',
          '3563601020201',
          '3563601020202',
          '3563601020251',
          '3563601020301',
          '3563601020302',
          '3563601020401',
          '3563601020451',
          '3563601020501',
          '3563601020502',
          '3563601020503',
          '3563601020504',
          '3563601020505',
          '3563601020601',
          '3563601020602',
          '3563601020603',
          '3563601020604',
          '3563601020605',
          '3563601020606',
          '3563601020701',
          '3563601020702',
          '3563601030102',
          '3563601030103',
          '3563601030201',
          '3563601030202',
          '3563601030301',
          '3563601030302',
          '3563601030401',
          '3563601030402',
          '3563601030501',
          '3563601030502',
          '3563601030503',
          '3563602010101',
          '3563602010102',
          '3563602010201',
          '3563602010301',
          '3563602010302',
          '3563602010303',
          '3563602010304',
          '3563602020101',
          '3563602020102',
          '3563602020103',
          '3563602020104',
          '3563602020105',
          '3563602020106',
          '3563602020151',
          '3563602020201',
          '3563602020202',
          '3563602020301',
          '3563602020302',
          '3563602020401',
          '3563602020501',
          '3563602020601',
          '3563602020602',
          '3563602030101',
          '3563602030201',
          '3563602030202',
          '3563603010101',
          '3563603010102',
          '3563603010103',
          '3563603010104',
          '3563603010105',
          '3563603010154',
          '3563603010201',
          '3563603010202',
          '3563603010251',
          '3563603010301',
          '3563603010302',
          '3563603010303',
          '3563603010304',
          '3563603010351',
          '3563603010352',
          '3563603010353',
          '3563603010354',
          '3563603010401',
          '3563603010402',
          '3563603010403',
          '3563603010404',
          '3563603010405',
          '3563603010406',
          '3563603020101',
          '3563603020102',
          '3563603020103',
          '3563603020104',
          '3563603020201',
          '3563603020202',
          '3563603020203',
          '3563603020204',
          '3563603020205',
          '3563603020206',
          '3563701010102',
          '3563701010103',
          '3563701010104',
          '3563701010105',
          '3563701010106',
          '3563701010107',
          '3563701010108',
          '3563701010109',
          '3563701010110',
          '3563701010111',
          '3563701010112',
          '3563701010113',
          '3563701010114',
          '3563701010115',
          '3563701010201',
          '3563701010202',
          '3563701010203',
          '3563701010205',
          '3563701010206',
          '3563701010207',
          '3563701010208',
          '3563701010301',
          '3563701010302',
          '3563701010303',
          '3563701010304',
          '3563701010305',
          '3563701010306',
          '3563701010307',
          '3563701010308',
          '3563701010351',
          '3563701010401',
          '3563701010402',
          '3563701010403',
          '3563701010404',
          '3563701010405',
          '3563701010406',
          '3563701010407',
          '3563701010408',
          '3563701010409',
          '3563701010411',
          '3563701010412',
          '3563701010413',
          '3563701010452',
          '3563701010453',
          '3563701010457',
          '3563701010458',
          '3563701010460',
          '3563701010501',
          '3563701010502',
          '3563701010503',
          '3563701010505',
          '3563701010506',
          '3563701010507',
          '3563701010508',
          '3563701010509',
          '3563701010510',
          '3563701010530',
          '3563701020101',
          '3563701020102',
          '3563701020103',
          '3563701020151',
          '3563701020152',
          '3563701020153',
          '3563702000101',
          '3563702000102',
          '3563702000103',
          '3563702000105',
          '3563702000106',
          '3563702000109',
          '3563702000110',
          '3563702000111',
          '3563702000112',
          '3563702000113',
          '3563702000114',
          '3563702000115',
          '3563702000116',
          '3563702000117',
          '3563702000118',
          '3563702000119',
          '3563702000120',
          '3563702000121',
          '3563702000201',
          '3563702000202',
          '3563702000203',
          '3563702000204',
          '3563702000230',
          '3563702000301',
          '3563702000302',
          '3563702000303',
          '3563702000304',
          '3563702000401',
          '3563702000402',
          '3563702000403',
          '3563703010101',
          '3563703010202',
          '3563703010203',
          '3563703010302',
          '3563703010303',
          '3563703010304',
          '3563703010305',
          '3563703010306',
          '3563703010307',
          '3563703010308',
          '3563703010309',
          '3563703010310',
          '3563703010311',
          '3563703010312',
          '3563703010313',
          '3563703010314',
          '3563703010315',
          '3563703010316',
          '3563703010317',
          '3563703010318',
          '3563703010319',
          '3563703010400',
          '3563703010501',
          '3563703010502',
          '3563703010504',
          '3563703010505',
          '3563703010506',
          '3563703010507',
          '3563703010508',
          '3563703010509',
          '3563703010510',
          '3563703010511',
          '3563703010512',
          '3563703010513',
          '3563703010601',
          '3563703010602',
          '3563703010603',
          '3563703010604',
          '3563703010605',
          '3563703010606',
          '3563703010607',
          '3563703010608',
          '3563703010609',
          '3563703020101',
          '3563703020102',
          '3563703020103',
          '3563703020104',
          '3563703020105',
          '3563703020106',
          '3563703020107',
          '3563703020108',
          '3563703020109',
          '3563703020201',
          '3563703020202',
          '3563703020203',
          '3563703020204',
          '3563703020205',
          '3563703020206',
          '3563703020207',
          '3563703020254',
          '3563703020301',
          '3563703020303',
          '3563703020401',
          '3563703030102',
          '3563703030201',
          '3563703030202',
          '3563703030203',
          '3563703030251',
          '3563703030253',
          '3563703030302',
          '3563703030303',
          '3563703030304',
          '3563703030305',
          '3563703030306',
          '3563703030307',
          '3563703030308',
          '3563703030309',
          '3563703030310',
          '3563703030311',
          '3563703030312',
          '3563703030313',
          '3563703030314',
          '3563703030353',
          '3563703030363',
          '3563703030400',
          '3563703040101',
          '3563703040102',
          '3563703040103',
          '3563703040104',
          '3563703040105',
          '3563703040106',
          '3563703040107',
          '3563703040108',
          '3563703040109',
          '3563703040110',
          '3563703040111',
          '3563703040112',
          '3563703040120',
          '3563703040151',
          '3563703040154',
          '3563703040155',
          '3563703040157',
          '3563703040174',
          '3563703040201',
          '3563703040202',
          '3563703040203',
          '3563703040204',
          '3563703040205',
          '3563703040206',
          '3563703040301',
          '3563703040302',
          '3563703040303',
          '3563703040304',
          '3563703040353',
          '3563703040401',
          '3563703040402',
          '3563703040403',
          '3563703040405',
          '3563703040406',
          '3563703040407',
          '3563703040408',
          '3563703040409',
          '3563703040410',
          '3563704010101',
          '3563704010102',
          '3563704010103',
          '3563704010104',
          '3563704010105',
          '3563704010106',
          '3563704010107',
          '3563704010108',
          '3563704010109',
          '3563704010110',
          '3563704010111',
          '3563704010112',
          '3563704010113',
          '3563704010201',
          '3563704010202',
          '3563704010203',
          '3563704010204',
          '3563704010205',
          '3563704010206',
          '3563704010207',
          '3563704010208',
          '3563704010209',
          '3563704010210',
          '3563704010211',
          '3563704010213',
          '3563704010214',
          '3563704010215',
          '3563704010216',
          '3563704020101',
          '3563704020103',
          '3563704020104',
          '3563704020105',
          '3563704020106',
          '3563704020107',
          '3563704020108',
          '3563704020109',
          '3563704020110',
          '3563704020111',
          '3563704020112',
          '3563704020120',
          '3563704020201',
          '3563704020202',
          '3563704020203',
          '3563704020204',
          '3563704020205',
          '3563704020207',
          '3563704020209',
          '3563704020210',
          '3563704020211',
          '3563704020212',
          '3563704020213',
          '3563704020214',
          '3563704020215',
          '3563704020216',
          '3563704020217',
          '3563704020218',
          '3563704020219',
          '3563704020220',
          '3563704020221',
          '3563704020222',
          '3563704020223',
          '3563704020224',
          '3563704020225',
          '3563704020226',
          '3563704020227',
          '3563704020228',
          '3563704030101',
          '3563704030102',
          '3563704030201',
          '3563704030202',
          '3563704040000',
          '3563705010101',
          '3563705010102',
          '3563705010104',
          '3563705010106',
          '3563705010107',
          '3563705010108',
          '3563705010109',
          '3563705010110',
          '3563705010152',
          '3563705010154',
          '3563705010156',
          '3563705010157',
          '3563705010159',
          '3563705010201',
          '3563705010202',
          '3563705010203',
          '3563705010204',
          '3563705010205',
          '3563705010206',
          '3563705010207',
          '3563705010251',
          '3563705010252',
          '3563705010254',
          '3563705010256',
          '3563705010301',
          '3563705010302',
          '3563705010303',
          '3563705010304',
          '3563705010305',
          '3563705010306',
          '3563705010352',
          '3563705010353',
          '3563705010401',
          '3563705010402',
          '3563705010403',
          '3563705010404',
          '3563705010405',
          '3563705010406',
          '3563705010407',
          '3563705010408',
          '3563705010409',
          '3563705010452',
          '3563705010455',
          '3563705010501',
          '3563705010503',
          '3563705010504',
          '3563705010505',
          '3563705010506',
          '3563705010507',
          '3563705010509',
          '3563705010551',
          '3563705010553',
          '3563705010555',
          '3563705010600',
          '3563705010701',
          '3563705010702',
          '3563705010703',
          '3563705010704',
          '3563705010705',
          '3563705010707',
          '3563705010708',
          '3563705010709',
          '3563705010711',
          '3563705010712',
          '3563705010713',
          '3563705010715',
          '3563705010716',
          '3563705010717',
          '3563705010718',
          '3563705010719',
          '3563705010721',
          '3563705010722',
          '3563705010723',
          '3563705010724',
          '3563705010725',
          '3563705010726',
          '3563705010727',
          '3563705010728',
          '3563705010729',
          '3563705010731',
          '3563705010732',
          '3563705010753',
          '3563705010758',
          '3563706010101',
          '3563706010102',
          '3563706010130',
          '3563706010201',
          '3563706010202',
          '3563706010203',
          '3563706010204',
          '3563706010205',
          '3563706010206',
          '3563706010207',
          '3563706010208',
          '3563706010209',
          '3563706010252',
          '3563706010253',
          '3563706010301',
          '3563706010302',
          '3563706010330',
          '3563706010331',
          '3563801010101',
          '3563801010102',
          '3563801010103',
          '3563801010104',
          '3563801010105',
          '3563801010107',
          '3563801010109',
          '3563801010110',
          '3563801010111',
          '3563801010112',
          '3563801010113',
          '3563801010114',
          '3563801010115',
          '3563801010116',
          '3563801010117',
          '3563801010118',
          '3563801010119',
          '3563801010120',
          '3563801010121',
          '3563801010122',
          '3563801010123',
          '3563801010124',
          '3563801010125',
          '3563801010126',
          '3563801010127',
          '3563801010130',
          '3563801010201',
          '3563801010202',
          '3563801010203',
          '3563801010204',
          '3563801010205',
          '3563801010301',
          '3563801010302',
          '3563801010303',
          '3563801010305',
          '3563801010306',
          '3563801010307',
          '3563801010308',
          '3563801010309',
          '3563801010401',
          '3563801010402',
          '3563801010403',
          '3563801010404',
          '3563801010405',
          '3563801010406',
          '3563801010407',
          '3563801010408',
          '3563801010501',
          '3563801010502',
          '3563801010503',
          '3563801010504',
          '3563801010505',
          '3563801010506',
          '3563801010507',
          '3563801010508',
          '3563801010509',
          '3563801010510',
          '3563801010511',
          '3563801010512',
          '3563801010513',
          '3563801010514',
          '3563801010515',
          '3563801010516',
          '3563801010517',
          '3563801010518',
          '3563801010519',
          '3563801020101',
          '3563801020102',
          '3563801020103',
          '3563801020104',
          '3563801020105',
          '3563801020106',
          '3563801020107',
          '3563801020108',
          '3563801020109',
          '3563801020110',
          '3563801020111',
          '3563801020112',
          '3563801020113',
          '3563801020114',
          '3563801020115',
          '3563801020201',
          '3563801020202',
          '3563801020203',
          '3563801020204',
          '3563801020301',
          '3563801020302',
          '3563801020303',
          '3563801020304',
          '3563801020305',
          '3563801020306',
          '3563801020307',
          '3563801020308',
          '3563801020309',
          '3563801020310',
          '3563801020311',
          '3563801030101',
          '3563801030102',
          '3563801030103',
          '3563801030104',
          '3563801030105',
          '3563801030106',
          '3563801030107',
          '3563801030108',
          '3563801030109',
          '3563801030110',
          '3563801030111',
          '3563801030201',
          '3563801030202',
          '3563801030203',
          '3563801030204',
          '3563801030205',
          '3563801030301',
          '3563801030302',
          '3563801040101',
          '3563801040102',
          '3563801040103',
          '3563801040104',
          '3563801040105',
          '3563801040151',
          '3563801040201',
          '3563801040202',
          '3563801040203',
          '3563801040204',
          '3563801040205',
          '3563801040206',
          '3563801040207',
          '3563801040208',
          '3563801040209',
          '3563801040210',
          '3563801040251',
          '3563801040258',
          '3563801040301',
          '3563801040302',
          '3563801040303',
          '3563801040304',
          '3563801040305',
          '3563801040354',
          '3563801040401',
          '3563801040402',
          '3563801040403',
          '3563801040404',
          '3563801040405',
          '3563801040406',
          '3563801040451',
          '3563801040452',
          '3563801040454',
          '3563801040455',
          '3563801040501',
          '3563801040502',
          '3563801040503',
          '3563801040504',
          '3563801040505',
          '3563801040601',
          '3563801040602',
          '3563801040605',
          '3563801040606',
          '3563801050101',
          '3563801050102',
          '3563801050103',
          '3563801050104',
          '3563801050105',
          '3563801050106',
          '3563801050154',
          '3563801050156',
          '3563801050201',
          '3563801050202',
          '3563801050203',
          '3563801050251',
          '3563801060101',
          '3563801060102',
          '3563801060103',
          '3563801060104',
          '3563801060105',
          '3563801060106',
          '3563801060151',
          '3563801060152',
          '3563801060153',
          '3563801060155',
          '3563801060156',
          '3563801060201',
          '3563801060202',
          '3563801060203',
          '3563801060204',
          '3563801060205',
          '3563801060206',
          '3563801060207',
          '3563801060208',
          '3563801060209',
          '3563801060210',
          '3563801060211',
          '3563801060251',
          '3563801070001',
          '3563801070002',
          '3563801070003',
          '3563801070004',
          '3563801070005',
          '3563801070006',
          '3563801070007',
          '3563801070030',
          '3563801080101',
          '3563801080102',
          '3563801080151',
          '3563801080200',
          '3563801090101',
          '3563801090102',
          '3563801090151',
          '3563801090201',
          '3563801100101',
          '3563801100102',
          '3563801100103',
          '3563801100104',
          '3563801100105',
          '3563801100106',
          '3563801100107',
          '3563801100108',
          '3563801100109',
          '3563801100110',
          '3563801100111',
          '3563801100112',
          '3563801100113',
          '3563801100114',
          '3563801100115',
          '3563801100118',
          '3563801100119',
          '3563801100120',
          '3563801100121',
          '3563801100122',
          '3563801100123',
          '3563801100124',
          '3563801100125',
          '3563801100126',
          '3563802010001',
          '3563802010002',
          '3563802010003',
          '3563802010004',
          '3563802010005',
          '3563802010006',
          '3563802010007',
          '3563802010008',
          '3563802010009',
          '3563802010010',
          '3563802010011',
          '3563802010012',
          '3563802010013',
          '3563802010014',
          '3563802010015',
          '3563802010016',
          '3563802010030',
          '3563802020001',
          '3563802020003',
          '3563802020006',
          '3563802020007',
          '3563802020008',
          '3563802030001',
          '3563802030002',
          '3563802030003',
          '3563802030004',
          '3563802030005',
          '3563802030006',
          '3563802030007',
          '3563802040101',
          '3563802040102',
          '3563802040103',
          '3563802040104',
          '3563802040130',
          '3563802040200',
          '3563803010001',
          '3563803010002',
          '3563803010003',
          '3563803010004',
          '3563803010005',
          '3563803010006',
          '3563803010007',
          '3563803010008',
          '3563803010030',
          '3563803020001',
          '3563803020002',
          '3563803020003',
          '3563803030001',
          '3563803030002',
          '3563803030004',
          '3563803030005',
          '3563803030006',
          '3563803040000',
          '3563901000101',
          '3563901000102',
          '3563901000103',
          '3563901000104',
          '3563901000105',
          '3563901000107',
          '3563901000108',
          '3563901000109',
          '3563901000110',
          '3563901000111',
          '3563901000120',
          '3563902000101',
          '3563902000102',
          '3563902000103',
          '3563902000104',
          '3563902000105',
          '3563902000106',
          '3563902000108',
          '3563902000109',
          '3563902000113',
          '3563902000114',
          '3563902000115',
          '3563902000116',
          '3563902000117',
          '3563902000118',
          '3563902000119',
          '3563902000120',
          '3563902000121',
          '3563902000122',
          '3563902000123',
          '3563902000124',
          '3563902000125',
          '3563902000126',
          '3563902000127',
          '3563902000129',
          '3563902000131',
          '3563902000132',
          '3563902000133',
          '3563902000134',
          '3563902000135',
          '3563902000136',
          '3563902000137',
          '3563902000138',
          '3563902000201',
          '3563902000202',
          '3563902000203',
          '3563902000301',
          '3563902000302',
          '3563902000303',
          '3563902000304',
          '3563902000305',
          '3563902000306',
          '3563902000307',
          '3563902000308',
          '3563902000309',
          '3563902000310',
          '3563902000401',
          '3563902000402',
          '3563902000403',
          '3563902000404',
          '3563902000405',
          '3563902000406',
          '3563902000407',
          '3563902000408',
          '3563902000409',
          '3563902000410',
          '3563902000501',
          '3563902000601',
          '3563902000700',
          '3563902000800',
          '3563902000901',
          '3563902000902',
          '3563902000903',
          '3563902000904',
          '3563902000905',
          '3563902001002',
          '3563902001003',
          '3563902001101',
          '3563903010101',
          '3563903010102',
          '3563903010103',
          '3563903010201',
          '3563903010202',
          '3563903010203',
          '3563903010204',
          '3563903010205',
          '3563903010206',
          '3563903020101',
          '3563903020102',
          '3563903020103',
          '3563903030101',
          '3563903030102',
          '3563903030153',
          '3563903030201',
          '3563903030202',
          '3563903030203',
          '3563903030204',
          '3569104990000'
        ],
        text: 'Venta de medicamentos, materias primas, insumos, maquinaria, equipo y reactivos para su producción'
      },
      {
        id: 'CASILLA_021000_MON',
        cabys: [
          '7131100000100',
          '7131100009900',
          '7132100000000',
          '7132200000000',
          '7133700000000',
          '7133901000100',
          '7133901000200',
          '7133901000300',
          '7133901000400',
          '7133901009900'
        ],
        text: 'Venta de primas de seguros personales'
      },
      { id: 'CASILLA_021100_MON', cabys: [], text: 'Venta de bienes y servicios que hagan las instituciones estatales de educación superior y otras autorizadas, así como las realizadas a dichas entidades' },
      {
        id: 'CASILLA_021200_MON',
        cabys: [
          '9291100000000',
          '9291900000100',
          '9291900000200',
          '9291900000300',
          '9291900000400',
          '9291900009900'
        ],
        text: 'Venta de otros servicios de educación privada no acreditados por el MEP y/o CONESUP'
      }
    ],
    4: [
      {
        id: 'CASILLA_02311_RDB_2_1',
        cabys: [
          '6413400000100',
          '6424100000000',
          '6424200000000'
        ],
        text: 'Venta de boletos o pasajes aéreos nacionales'
      },
      {
        id: 'CASILLA_02311_RDB_2_2',
        cabys: [
          '6413400000200',
          '6424300000000',
          '6424400000000'
        ],
        text: 'Venta de boletos o pasajes aéreos internacionales'
      },
      {
        id: 'CASILLA_021500_MON',
        cabys: [
          '9310100000100',
          '9310100000200',
          '9310100000300',
          '9310100000400',
          '9310100000500',
          '9310100000600',
          '9310100000700',
          '9310100000800',
          '9310100000900',
          '9310100001000',
          '9310100001100',
          '9310100001200',
          '9310100001300',
          '9310100001400',
          '9310100001500',
          '9310100001600',
          '9310100001700',
          '9310100001800',
          '9310100001900',
          '9310100002000',
          '9310100002100',
          '9310100002200',
          '9310100002300',
          '9310100002400',
          '9310100002500',
          '9310100002600',
          '9310100002700',
          '9310100002800',
          '9310100002900',
          '9310100003000',
          '9310100009900',
          '9310201010101',
          '9310201010102',
          '9310201010103',
          '9310201010104',
          '9310201010105',
          '9310201010106',
          '9310201010107',
          '9310201010108',
          '9310201010109',
          '9310201010110',
          '9310201010111',
          '9310201010112',
          '9310201010113',
          '9310201010114',
          '9310201010115',
          '9310201010116',
          '9310201010117',
          '9310201010118',
          '9310201010119',
          '9310201010120',
          '9310201010121',
          '9310201010122',
          '9310201010123',
          '9310201010124',
          '9310201010125',
          '9310201010126',
          '9310201010127',
          '9310201010128',
          '9310201010129',
          '9310201010130',
          '9310201010131',
          '9310201010132',
          '9310201010133',
          '9310201010134',
          '9310201010135',
          '9310201010136',
          '9310201010137',
          '9310201010138',
          '9310201010139',
          '9310201010201',
          '9310201010202',
          '9310201010203',
          '9310201010204',
          '9310201010205',
          '9310201010206',
          '9310201010207',
          '9310201010208',
          '9310201010209',
          '9310201010210',
          '9310201010301',
          '9310201010302',
          '9310201010303',
          '9310201010304',
          '9310201010305',
          '9310201010306',
          '9310201010307',
          '9310201010308',
          '9310201010309',
          '9310201010310',
          '9310201010311',
          '9310201010312',
          '9310201010313',
          '9310201010401',
          '9310201010402',
          '9310201010403',
          '9310201010404',
          '9310201010405',
          '9310201010501',
          '9310201010502',
          '9310201010503',
          '9310201010504',
          '9310201010505',
          '9310201010506',
          '9310201010507',
          '9310201010508',
          '9310201010509',
          '9310201010510',
          '9310201010511',
          '9310201010512',
          '9310201010513',
          '9310201010514',
          '9310201020101',
          '9310201020102',
          '9310201020103',
          '9310201020104',
          '9310201020105',
          '9310201020106',
          '9310201020107',
          '9310201020108',
          '9310201020109',
          '9310201020110',
          '9310201020111',
          '9310201020112',
          '9310201020113',
          '9310201020201',
          '9310201020202',
          '9310201020203',
          '9310201020204',
          '9310201020205',
          '9310201020206',
          '9310201020207',
          '9310201020301',
          '9310201020302',
          '9310201020303',
          '9310201020304',
          '9310201020305',
          '9310201020306',
          '9310201020307',
          '9310201020308',
          '9310201020309',
          '9310201020310',
          '9310201020311',
          '9310201020312',
          '9310201020313',
          '9310201020314',
          '9310201020315',
          '9310201020316',
          '9310201020401',
          '9310201020402',
          '9310201020403',
          '9310201020404',
          '9310201030101',
          '9310201030102',
          '9310201030103',
          '9310201030104',
          '9310201030105',
          '9310201030106',
          '9310201030107',
          '9310201030108',
          '9310201030109',
          '9310201030110',
          '9310201030111',
          '9310201030112',
          '9310201030113',
          '9310201030114',
          '9310201030115',
          '9310201030116',
          '9310201030117',
          '9310201030118',
          '9310201030119',
          '9310201030120',
          '9310201030121',
          '9310201040101',
          '9310201040102',
          '9310201040103',
          '9310201040104',
          '9310201040105',
          '9310201040106',
          '9310201040107',
          '9310201040108',
          '9310201040109',
          '9310201040201',
          '9310201040202',
          '9310201040301',
          '9310201040302',
          '9310201040401',
          '9310201040402',
          '9310201040501',
          '9310201040502',
          '9310201050101',
          '9310201050102',
          '9310201060101',
          '9310201060102',
          '9310201060103',
          '9310201060104',
          '9310201060105',
          '9310201060106',
          '9310201060107',
          '9310201060108',
          '9310201060109',
          '9310201060110',
          '9310201060111',
          '9310201060112',
          '9310201060113',
          '9310201060114',
          '9310201070101',
          '9310201070102',
          '9310201070103',
          '9310201070104',
          '9310201070105',
          '9310201070106',
          '9310201070107',
          '9310201070108',
          '9310201070109',
          '9310201070110',
          '9310201070111',
          '9310201070112',
          '9310201070113',
          '9310201070201',
          '9310201070202',
          '9310201070203',
          '9310201070204',
          '9310201070205',
          '9310201070301',
          '9310201070302',
          '9310201070303',
          '9310201070304',
          '9310201070305',
          '9310201070306',
          '9310201070307',
          '9310201070308',
          '9310201070309',
          '9310201070310',
          '9310201070401',
          '9310201070402',
          '9310201070403',
          '9310201070404',
          '9310201070405',
          '9310201070406',
          '9310201070407',
          '9310201070408',
          '9310201070409',
          '9310201070501',
          '9310201070502',
          '9310201070503',
          '9310201070504',
          '9310201070505',
          '9310201070506',
          '9310201070507',
          '9310201070508',
          '9310201070509',
          '9310201070601',
          '9310201070602',
          '9310201070603',
          '9310201070604',
          '9310201070605',
          '9310201070606',
          '9310201070607',
          '9310201070608',
          '9310201070609',
          '9310201070701',
          '9310201070702',
          '9310201070703',
          '9310201070704',
          '9310201070705',
          '9310201070706',
          '9310201070707',
          '9310201070708',
          '9310201070709',
          '9310201070710',
          '9310201070801',
          '9310201070802',
          '9310201070803',
          '9310201070804',
          '9310201070805',
          '9310201070806',
          '9310201070807',
          '9310201070808',
          '9310201070809',
          '9310201070810',
          '9310201070811',
          '9310201080101',
          '9310201080102',
          '9310201080103',
          '9310201080104',
          '9310201080105',
          '9310201080106',
          '9310201080107',
          '9310201080108',
          '9310201080109',
          '9310201080110',
          '9310201080111',
          '9310201080112',
          '9310201080113',
          '9310201080201',
          '9310201080202',
          '9310201080203',
          '9310201080204',
          '9310201080205',
          '9310201080206',
          '9310201080207',
          '9310201080208',
          '9310201080209',
          '9310201080301',
          '9310201080302',
          '9310201080303',
          '9310201080304',
          '9310201080305',
          '9310201080306',
          '9310201080307',
          '9310201080308',
          '9310201080309',
          '9310201080401',
          '9310201080402',
          '9310201080403',
          '9310201080404',
          '9310201080405',
          '9310201080406',
          '9310201080407',
          '9310201080408',
          '9310201080409',
          '9310201080501',
          '9310201080502',
          '9310201080503',
          '9310201080504',
          '9310201080505',
          '9310201080506',
          '9310201080507',
          '9310201080508',
          '9310201080509',
          '9310201080510',
          '9310201080601',
          '9310201080602',
          '9310201080603',
          '9310201080604',
          '9310201080605',
          '9310201080606',
          '9310201080607',
          '9310201080608',
          '9310201080609',
          '9310201080701',
          '9310201080702',
          '9310201080703',
          '9310201080704',
          '9310201080705',
          '9310201080706',
          '9310201080707',
          '9310201080708',
          '9310201080709',
          '9310201080801',
          '9310201080802',
          '9310201080803',
          '9310201080804',
          '9310201080805',
          '9310201080806',
          '9310201080807',
          '9310201080808',
          '9310201080809',
          '9310201080901',
          '9310201080902',
          '9310201080903',
          '9310201080904',
          '9310201080905',
          '9310201080906',
          '9310201080907',
          '9310201080908',
          '9310201080909',
          '9310201081001',
          '9310201081002',
          '9310201081003',
          '9310201081004',
          '9310201081005',
          '9310201081006',
          '9310201081007',
          '9310201081008',
          '9310201081009',
          '9310201081101',
          '9310201081102',
          '9310201081103',
          '9310201081104',
          '9310201081105',
          '9310201081106',
          '9310201081107',
          '9310201081108',
          '9310201081109',
          '9310201081201',
          '9310201081202',
          '9310201081203',
          '9310201081204',
          '9310201081205',
          '9310201081206',
          '9310201081207',
          '9310201081208',
          '9310201081209',
          '9310201090101',
          '9310201090102',
          '9310201090201',
          '9310201090202',
          '9310201090203',
          '9310201090301',
          '9310201090302',
          '9310201090303',
          '9310201090401',
          '9310201090402',
          '9310201090403',
          '9310201090404',
          '9310201090501',
          '9310201090502',
          '9310201090503',
          '9310201090504',
          '9310201090505',
          '9310201090506',
          '9310201090601',
          '9310201090602',
          '9310201090603',
          '9310201100101',
          '9310201100102',
          '9310201100103',
          '9310201100104',
          '9310201100105',
          '9310201100106',
          '9310201100107',
          '9310201100108',
          '9310201100109',
          '9310201100110',
          '9310201100111',
          '9310201100112',
          '9310201100113',
          '9310201100114',
          '9310201100115',
          '9310201100116',
          '9310201100117',
          '9310201110101',
          '9310201110102',
          '9310201110103',
          '9310201110104',
          '9310201110105',
          '9310201990000',
          '9310202010101',
          '9310202010102',
          '9310202010103',
          '9310202010104',
          '9310202010105',
          '9310202010106',
          '9310202010107',
          '9310202010108',
          '9310202010109',
          '9310202010110',
          '9310202010111',
          '9310202010112',
          '9310202010113',
          '9310202010114',
          '9310202010115',
          '9310202010116',
          '9310202010201',
          '9310202010202',
          '9310202010203',
          '9310202010204',
          '9310202010205',
          '9310202010206',
          '9310202010207',
          '9310202010208',
          '9310202010209',
          '9310202010210',
          '9310202010211',
          '9310202010212',
          '9310202010213',
          '9310202010214',
          '9310202010215',
          '9310202010301',
          '9310202010302',
          '9310202010303',
          '9310202010304',
          '9310202010305',
          '9310202010306',
          '9310202010307',
          '9310202010308',
          '9310202010309',
          '9310202010310',
          '9310202010311',
          '9310202010312',
          '9310202010313',
          '9310202010314',
          '9310202010315',
          '9310202010316',
          '9310202010317',
          '9310202010318',
          '9310202010319',
          '9310202010320',
          '9310202010321',
          '9310202010322',
          '9310202010323',
          '9310202020101',
          '9310202020102',
          '9310202020103',
          '9310202020104',
          '9310202020105',
          '9310202020106',
          '9310202020107',
          '9310202020108',
          '9310202020109',
          '9310202020110',
          '9310202020111',
          '9310202020112',
          '9310202020113',
          '9310202020114',
          '9310202020115',
          '9310202020201',
          '9310202020202',
          '9310202020203',
          '9310202020204',
          '9310202020205',
          '9310202020206',
          '9310202020207',
          '9310202020208',
          '9310202020209',
          '9310202020210',
          '9310202020211',
          '9310202020301',
          '9310202020302',
          '9310202020303',
          '9310202020304',
          '9310202020305',
          '9310202020306',
          '9310202020307',
          '9310202020308',
          '9310202020401',
          '9310202020402',
          '9310202020403',
          '9310202020404',
          '9310202020405',
          '9310202020501',
          '9310202020502',
          '9310202020503',
          '9310202020504',
          '9310202020505',
          '9310202020506',
          '9310202020507',
          '9310202020508',
          '9310202020509',
          '9310202020601',
          '9310202020602',
          '9310202020603',
          '9310202020604',
          '9310202020605',
          '9310202030101',
          '9310202030102',
          '9310202030103',
          '9310202030104',
          '9310202030105',
          '9310202030106',
          '9310202030201',
          '9310202030202',
          '9310202030203',
          '9310202030204',
          '9310202030205',
          '9310202030206',
          '9310202030207',
          '9310202030208',
          '9310202030301',
          '9310202030302',
          '9310202030303',
          '9310202030304',
          '9310202030401',
          '9310202030402',
          '9310202030403',
          '9310202030404',
          '9310202040101',
          '9310202040102',
          '9310202040103',
          '9310202040104',
          '9310202040105',
          '9310202040106',
          '9310202040107',
          '9310202040108',
          '9310202040109',
          '9310202040110',
          '9310202050101',
          '9310202050102',
          '9310202050201',
          '9310202050202',
          '9310202050203',
          '9310202050301',
          '9310202050302',
          '9310202050401',
          '9310202050402',
          '9310202060101',
          '9310202060102',
          '9310202060103',
          '9310202060104',
          '9310202060105',
          '9310202060106',
          '9310202060107',
          '9310202060108',
          '9310202060109',
          '9310202060201',
          '9310202060202',
          '9310202060203',
          '9310202060204',
          '9310202060205',
          '9310202060206',
          '9310202060207',
          '9310202060208',
          '9310202060209',
          '9310202060210',
          '9310202060211',
          '9310202060212',
          '9310202060213',
          '9310202060214',
          '9310202990000',
          '9310203010101',
          '9310203010102',
          '9310203010103',
          '9310203010104',
          '9310203010105',
          '9310203010106',
          '9310203010107',
          '9310203010108',
          '9310203010109',
          '9310203010110',
          '9310203010111',
          '9310203010112',
          '9310203010113',
          '9310203010114',
          '9310203010115',
          '9310203010201',
          '9310203010202',
          '9310203010203',
          '9310203010204',
          '9310203010205',
          '9310203010206',
          '9310203010207',
          '9310203010208',
          '9310203010301',
          '9310203010302',
          '9310203010303',
          '9310203010304',
          '9310203010305',
          '9310203010306',
          '9310203020101',
          '9310203020102',
          '9310203020103',
          '9310203020104',
          '9310203020105',
          '9310203020106',
          '9310203020107',
          '9310203020108',
          '9310203020109',
          '9310203020110',
          '9310203020111',
          '9310203020112',
          '9310203020201',
          '9310203020202',
          '9310203020203',
          '9310203020204',
          '9310203020205',
          '9310203020206',
          '9310203020207',
          '9310203020208',
          '9310203020209',
          '9310203020210',
          '9310203020301',
          '9310203020302',
          '9310203020303',
          '9310203020304',
          '9310203020401',
          '9310203020402',
          '9310203020501',
          '9310203020502',
          '9310203020503',
          '9310203020504',
          '9310203020505',
          '9310203030101',
          '9310203030102',
          '9310203030103',
          '9310203030104',
          '9310203030105',
          '9310203030106',
          '9310203030107',
          '9310203030108',
          '9310203030109',
          '9310203030110',
          '9310203030201',
          '9310203030202',
          '9310203030203',
          '9310203030204',
          '9310203030205',
          '9310203040101',
          '9310203040102',
          '9310203040103',
          '9310203040104',
          '9310203050101',
          '9310203050102',
          '9310203050201',
          '9310203050202',
          '9310203050203',
          '9310203050204',
          '9310203050301',
          '9310203050302',
          '9310203050401',
          '9310203050402',
          '9310203050403',
          '9310203050404',
          '9310203060101',
          '9310203060102',
          '9310203060103',
          '9310203060104',
          '9310203060105',
          '9310203060106',
          '9310203060107',
          '9310203060108',
          '9310203990000',
          '9310204010101',
          '9310204010102',
          '9310204010103',
          '9310204010104',
          '9310204010105',
          '9310204010106',
          '9310204010107',
          '9310204010108',
          '9310204010109',
          '9310204010110',
          '9310204010111',
          '9310204010201',
          '9310204010202',
          '9310204010203',
          '9310204010204',
          '9310204010205',
          '9310204010206',
          '9310204020101',
          '9310204020102',
          '9310204020103',
          '9310204020104',
          '9310204020105',
          '9310204020106',
          '9310204020107',
          '9310204020108',
          '9310204020109',
          '9310204030101',
          '9310204030102',
          '9310204030103',
          '9310204030104',
          '9310204030105',
          '9310204030106',
          '9310204030107',
          '9310204030108',
          '9310204030109',
          '9310204030201',
          '9310204030202',
          '9310204030203',
          '9310204030204',
          '9310204030205',
          '9310204030206',
          '9310204030207',
          '9310204030301',
          '9310204030302',
          '9310204030303',
          '9310204030304',
          '9310204030305',
          '9310204030306',
          '9310204030307',
          '9310204030401',
          '9310204030402',
          '9310204030403',
          '9310204030404',
          '9310204030405',
          '9310204030406',
          '9310204030407',
          '9310204030408',
          '9310204030409',
          '9310204030410',
          '9310204030411',
          '9310204030412',
          '9310204030413',
          '9310204030501',
          '9310204030502',
          '9310204030503',
          '9310204030504',
          '9310204030505',
          '9310204030506',
          '9310204030507',
          '9310204030508',
          '9310204030509',
          '9310204030510',
          '9310204030601',
          '9310204030602',
          '9310204030603',
          '9310204030604',
          '9310204030605',
          '9310204030606',
          '9310204030607',
          '9310204030608',
          '9310204030609',
          '9310204030610',
          '9310204030701',
          '9310204030702',
          '9310204030703',
          '9310204030704',
          '9310204030705',
          '9310204030706',
          '9310204030707',
          '9310204030708',
          '9310204030709',
          '9310204030710',
          '9310204030711',
          '9310204030712',
          '9310204030713',
          '9310204030714',
          '9310204030715',
          '9310204030716',
          '9310204030801',
          '9310204030802',
          '9310204030803',
          '9310204030804',
          '9310204030805',
          '9310204030806',
          '9310204030901',
          '9310204030902',
          '9310204030903',
          '9310204040101',
          '9310204040102',
          '9310204040103',
          '9310204040104',
          '9310204040105',
          '9310204050101',
          '9310204050102',
          '9310204050103',
          '9310204050104',
          '9310204050105',
          '9310204060101',
          '9310204060102',
          '9310204060103',
          '9310204060104',
          '9310204060105',
          '9310204060106',
          '9310204060107',
          '9310204060108',
          '9310204060109',
          '9310204060110',
          '9310204060111',
          '9310204060112',
          '9310204060113',
          '9310204060114',
          '9310204060115',
          '9310204060116',
          '9310204060117',
          '9310204060118',
          '9310204060119',
          '9310204070101',
          '9310204070102',
          '9310204070103',
          '9310204070104',
          '9310204070105',
          '9310204070106',
          '9310204070107',
          '9310204070108',
          '9310204070109',
          '9310204070110',
          '9310204070111',
          '9310204070201',
          '9310204070202',
          '9310204070203',
          '9310204070204',
          '9310204070205',
          '9310204070206',
          '9310204070207',
          '9310204070208',
          '9310204070209',
          '9310204070210',
          '9310204070211',
          '9310204070212',
          '9310204070213',
          '9310204070214',
          '9310204070215',
          '9310204080101',
          '9310204080102',
          '9310204990000',
          '9310205010101',
          '9310205010102',
          '9310205010103',
          '9310205010104',
          '9310205010105',
          '9310205010106',
          '9310205010107',
          '9310205010108',
          '9310205010109',
          '9310205010110',
          '9310205010111',
          '9310205010112',
          '9310205010113',
          '9310205010114',
          '9310205010115',
          '9310205010116',
          '9310205010117',
          '9310205010118',
          '9310205010119',
          '9310205010120',
          '9310205010121',
          '9310205010122',
          '9310205010201',
          '9310205010202',
          '9310205010203',
          '9310205010204',
          '9310205010205',
          '9310205010206',
          '9310205010207',
          '9310205020101',
          '9310205020102',
          '9310205020103',
          '9310205020104',
          '9310205020105',
          '9310205020106',
          '9310205020107',
          '9310205020108',
          '9310205020109',
          '9310205020110',
          '9310205020111',
          '9310205020112',
          '9310205020113',
          '9310205020114',
          '9310205020115',
          '9310205020116',
          '9310205020117',
          '9310205020118',
          '9310205020119',
          '9310205020120',
          '9310205020121',
          '9310205020122',
          '9310205020123',
          '9310205020201',
          '9310205020202',
          '9310205020203',
          '9310205020204',
          '9310205020205',
          '9310205020301',
          '9310205020302',
          '9310205020303',
          '9310205020304',
          '9310205020305',
          '9310205020306',
          '9310205020307',
          '9310205020308',
          '9310205020309',
          '9310205020310',
          '9310205020311',
          '9310205020312',
          '9310205020313',
          '9310205020314',
          '9310205020315',
          '9310205020316',
          '9310205020317',
          '9310205020318',
          '9310205020319',
          '9310205020401',
          '9310205020402',
          '9310205030101',
          '9310205030102',
          '9310205030103',
          '9310205030104',
          '9310205030105',
          '9310205030106',
          '9310205030107',
          '9310205030108',
          '9310205030109',
          '9310205030110',
          '9310205030111',
          '9310205030201',
          '9310205030301',
          '9310205030401',
          '9310205030402',
          '9310205030403',
          '9310205030501',
          '9310205030502',
          '9310205040101',
          '9310205040102',
          '9310205990000',
          '9310206010101',
          '9310206010102',
          '9310206010103',
          '9310206010104',
          '9310206010105',
          '9310206010106',
          '9310206010201',
          '9310206010301',
          '9310206010302',
          '9310206010303',
          '9310206010304',
          '9310206010305',
          '9310206010306',
          '9310206010307',
          '9310206010401',
          '9310206010402',
          '9310206010403',
          '9310206010404',
          '9310206010405',
          '9310206020101',
          '9310206020102',
          '9310206020103',
          '9310206020104',
          '9310206020201',
          '9310206020202',
          '9310206020203',
          '9310206020301',
          '9310206020302',
          '9310206020303',
          '9310206020401',
          '9310206020402',
          '9310206020403',
          '9310206020404',
          '9310206030101',
          '9310206030102',
          '9310206030103',
          '9310206030104',
          '9310206030105',
          '9310206030201',
          '9310206030202',
          '9310206030203',
          '9310206030204',
          '9310206030301',
          '9310206030302',
          '9310206030303',
          '9310206030304',
          '9310206030401',
          '9310206030402',
          '9310206030403',
          '9310206030404',
          '9310206040101',
          '9310206040201',
          '9310206040202',
          '9310206040203',
          '9310206040204',
          '9310206040301',
          '9310206040302',
          '9310206040303',
          '9310206040401',
          '9310206040402',
          '9310206040403',
          '9310206040404',
          '9310206040405',
          '9310206040406',
          '9310206040407',
          '9310206040408',
          '9310206040501',
          '9310206040502',
          '9310206040503',
          '9310206040504',
          '9310206040505',
          '9310206040506',
          '9310206040601',
          '9310206040602',
          '9310206040603',
          '9310206040604',
          '9310206040605',
          '9310206040606',
          '9310206040607',
          '9310206040608',
          '9310206050101',
          '9310206050102',
          '9310206050103',
          '9310206050104',
          '9310206050105',
          '9310206050106',
          '9310206050107',
          '9310206050108',
          '9310206050109',
          '9310206050110',
          '9310206050111',
          '9310206050112',
          '9310206050113',
          '9310206050114',
          '9310206050115',
          '9310206050201',
          '9310206050202',
          '9310206050203',
          '9310206050204',
          '9310206050205',
          '9310206050206',
          '9310206050207',
          '9310206050208',
          '9310206050209',
          '9310206050210',
          '9310206050211',
          '9310206050301',
          '9310206050401',
          '9310206050402',
          '9310206050501',
          '9310206050601',
          '9310206050602',
          '9310206050603',
          '9310206050604',
          '9310206050605',
          '9310206050701',
          '9310206050702',
          '9310206050703',
          '9310206050704',
          '9310206050705',
          '9310206050801',
          '9310206050802',
          '9310206050803',
          '9310206050804',
          '9310206050805',
          '9310206050806',
          '9310206050807',
          '9310206050808',
          '9310206050809',
          '9310206050810',
          '9310206060101',
          '9310206060102',
          '9310206060103',
          '9310206060104',
          '9310206060105',
          '9310206060106',
          '9310206060107',
          '9310206060108',
          '9310206060109',
          '9310206060110',
          '9310206060111',
          '9310206060112',
          '9310206060201',
          '9310206060202',
          '9310206060203',
          '9310206060204',
          '9310206060205',
          '9310206060206',
          '9310206060207',
          '9310206060208',
          '9310206060301',
          '9310206060401',
          '9310206060402',
          '9310206060403',
          '9310206060404',
          '9310206060405',
          '9310206060406',
          '9310206070101',
          '9310206070102',
          '9310206070201',
          '9310206070202',
          '9310206070301',
          '9310206070302',
          '9310206070303',
          '9310206070304',
          '9310206070305',
          '9310206070306',
          '9310206070307',
          '9310206070308',
          '9310206070309',
          '9310206070310',
          '9310206070311',
          '9310206070312',
          '9310206070313',
          '9310206070314',
          '9310206070315',
          '9310206070316',
          '9310206070317',
          '9310206070318',
          '9310206070319',
          '9310206070320',
          '9310206070321',
          '9310206070322',
          '9310206070323',
          '9310206070324',
          '9310206070325',
          '9310206070326',
          '9310206070327',
          '9310206070328',
          '9310206070329',
          '9310206070330',
          '9310206070331',
          '9310206070332',
          '9310206070333',
          '9310206070334',
          '9310206070335',
          '9310206070336',
          '9310206070337',
          '9310206070338',
          '9310206070339',
          '9310206070340',
          '9310206070341',
          '9310206080101',
          '9310206080102',
          '9310206080103',
          '9310206080104',
          '9310206080105',
          '9310206080106',
          '9310206080107',
          '9310206080108',
          '9310206080109',
          '9310206080110',
          '9310206080111',
          '9310206080112',
          '9310206080113',
          '9310206090101',
          '9310206090102',
          '9310206090103',
          '9310206090104',
          '9310206090105',
          '9310206090106',
          '9310206090107',
          '9310206090108',
          '9310206090109',
          '9310206090110',
          '9310206090111',
          '9310206090112',
          '9310206090113',
          '9310206090114',
          '9310206090115',
          '9310206090116',
          '9310206090117',
          '9310206090201',
          '9310206090202',
          '9310206090203',
          '9310206090204',
          '9310206090205',
          '9310206090206',
          '9310206090207',
          '9310206090208',
          '9310206090209',
          '9310206090210',
          '9310206090211',
          '9310206090212',
          '9310206090213',
          '9310206090301',
          '9310206090302',
          '9310206090303',
          '9310206090304',
          '9310206090305',
          '9310206090306',
          '9310206100101',
          '9310206100102',
          '9310206100103',
          '9310206100104',
          '9310206100105',
          '9310206100106',
          '9310206100107',
          '9310206100108',
          '9310206100109',
          '9310206100110',
          '9310206100111',
          '9310206100112',
          '9310206100201',
          '9310206100202',
          '9310206110101',
          '9310206110102',
          '9310206110103',
          '9310206110104',
          '9310206110105',
          '9310206110106',
          '9310206110107',
          '9310206110108',
          '9310206110109',
          '9310206110110',
          '9310206110111',
          '9310206110112',
          '9310206110113',
          '9310206110114',
          '9310206110115',
          '9310206110116',
          '9310206110201',
          '9310206110202',
          '9310206110203',
          '9310206120101',
          '9310206120102',
          '9310206120103',
          '9310206120104',
          '9310206120105',
          '9310206120106',
          '9310206120107',
          '9310206120108',
          '9310206120109',
          '9310206120110',
          '9310206120111',
          '9310206120112',
          '9310206120113',
          '9310206120114',
          '9310206120115',
          '9310206120201',
          '9310206120202',
          '9310206120203',
          '9310206120204',
          '9310206120205',
          '9310206120206',
          '9310206120207',
          '9310206120208',
          '9310206120209',
          '9310206120210',
          '9310206120301',
          '9310206120302',
          '9310206120303',
          '9310206120304',
          '9310206120305',
          '9310206120306',
          '9310206120307',
          '9310206120308',
          '9310206120309',
          '9310206120310',
          '9310206130101',
          '9310206130102',
          '9310206130103',
          '9310206130104',
          '9310206130105',
          '9310206130106',
          '9310206130107',
          '9310206130108',
          '9310206130109',
          '9310206130110',
          '9310206130201',
          '9310206130202',
          '9310206130203',
          '9310206130204',
          '9310206130205',
          '9310206130206',
          '9310206130207',
          '9310206140101',
          '9310206140102',
          '9310206140103',
          '9310206140104',
          '9310206140201',
          '9310206140202',
          '9310206140203',
          '9310206140204',
          '9310206150101',
          '9310206150102',
          '9310206150103',
          '9310206150104',
          '9310206150105',
          '9310206150106',
          '9310206150107',
          '9310206150108',
          '9310206150109',
          '9310206150110',
          '9310206150111',
          '9310206150112',
          '9310206150113',
          '9310206150114',
          '9310206150115',
          '9310206150116',
          '9310206150117',
          '9310206150201',
          '9310206150202',
          '9310206150203',
          '9310206150204',
          '9310206150205',
          '9310206150206',
          '9310206150207',
          '9310206150301',
          '9310206150302',
          '9310206150303',
          '9310206150304',
          '9310206150305',
          '9310206150306',
          '9310206150307',
          '9310206150308',
          '9310206150309',
          '9310206150310',
          '9310206150311',
          '9310206150312',
          '9310206150313',
          '9310206150314',
          '9310206150315',
          '9310206150316',
          '9310206150317',
          '9310206150318',
          '9310206150319',
          '9310206150320',
          '9310206990000',
          '9310207010101',
          '9310207010102',
          '9310207010103',
          '9310207010104',
          '9310207010105',
          '9310207010106',
          '9310207010107',
          '9310207010108',
          '9310207010109',
          '9310207010110',
          '9310207010111',
          '9310207010112',
          '9310207010113',
          '9310207010114',
          '9310207010115',
          '9310207010116',
          '9310207010117',
          '9310207010118',
          '9310207010119',
          '9310207010120',
          '9310207010121',
          '9310207010122',
          '9310207010201',
          '9310207010202',
          '9310207010203',
          '9310207010204',
          '9310207010301',
          '9310207010302',
          '9310207010303',
          '9310207010401',
          '9310207010402',
          '9310207010403',
          '9310207010404',
          '9310207010405',
          '9310207010406',
          '9310207010407',
          '9310207010408',
          '9310207010409',
          '9310207010410',
          '9310207010411',
          '9310207010412',
          '9310207010413',
          '9310207010414',
          '9310207010415',
          '9310207010416',
          '9310207010417',
          '9310207010418',
          '9310207010419',
          '9310207010501',
          '9310207010502',
          '9310207010503',
          '9310207010504',
          '9310207010505',
          '9310207010506',
          '9310207010507',
          '9310207010508',
          '9310207010601',
          '9310207010602',
          '9310207010603',
          '9310207010604',
          '9310207010605',
          '9310207010606',
          '9310207010607',
          '9310207010701',
          '9310207010702',
          '9310207010703',
          '9310207010704',
          '9310207010705',
          '9310207010706',
          '9310207010801',
          '9310207010802',
          '9310207010803',
          '9310207010804',
          '9310207010805',
          '9310207010901',
          '9310207010902',
          '9310207010903',
          '9310207010904',
          '9310207010905',
          '9310207010906',
          '9310207010907',
          '9310207010908',
          '9310207010909',
          '9310207010910',
          '9310207010911',
          '9310207010912',
          '9310207011001',
          '9310207011002',
          '9310207011003',
          '9310207011004',
          '9310207011005',
          '9310207011006',
          '9310207011007',
          '9310207011008',
          '9310207011009',
          '9310207011010',
          '9310207011011',
          '9310207011012',
          '9310207011013',
          '9310207011014',
          '9310207011015',
          '9310207011016',
          '9310207011017',
          '9310207011018',
          '9310207011019',
          '9310207011020',
          '9310207011021',
          '9310207011022',
          '9310207011023',
          '9310207011024',
          '9310207011025',
          '9310207011026',
          '9310207011027',
          '9310207011028',
          '9310207011029',
          '9310207020101',
          '9310207020102',
          '9310207020103',
          '9310207020104',
          '9310207020105',
          '9310207020106',
          '9310207020107',
          '9310207020108',
          '9310207020109',
          '9310207020110',
          '9310207020111',
          '9310207020112',
          '9310207020113',
          '9310207020114',
          '9310207020115',
          '9310207020116',
          '9310207020117',
          '9310207020118',
          '9310207020119',
          '9310207020120',
          '9310207020121',
          '9310207020122',
          '9310207020123',
          '9310207020124',
          '9310207020201',
          '9310207020202',
          '9310207020203',
          '9310207020204',
          '9310207020205',
          '9310207020206',
          '9310207020207',
          '9310207020208',
          '9310207020209',
          '9310207020210',
          '9310207020211',
          '9310207020212',
          '9310207020213',
          '9310207020214',
          '9310207020215',
          '9310207020216',
          '9310207020217',
          '9310207020218',
          '9310207020219',
          '9310207020220',
          '9310207020221',
          '9310207020222',
          '9310207020301',
          '9310207020302',
          '9310207020303',
          '9310207020304',
          '9310207020305',
          '9310207020306',
          '9310207020307',
          '9310207020308',
          '9310207020309',
          '9310207020310',
          '9310207020311',
          '9310207020312',
          '9310207020313',
          '9310207020314',
          '9310207020315',
          '9310207020316',
          '9310207020317',
          '9310207020318',
          '9310207020319',
          '9310207020320',
          '9310207020321',
          '9310207020322',
          '9310207020323',
          '9310207020324',
          '9310207020325',
          '9310207020326',
          '9310207020327',
          '9310207020328',
          '9310207020329',
          '9310207020330',
          '9310207020331',
          '9310207030101',
          '9310207030102',
          '9310207030103',
          '9310207030104',
          '9310207030105',
          '9310207030106',
          '9310207030107',
          '9310207030108',
          '9310207030109',
          '9310207030110',
          '9310207030111',
          '9310207030201',
          '9310207030301',
          '9310207030302',
          '9310207030303',
          '9310207030304',
          '9310207030305',
          '9310207030306',
          '9310207030307',
          '9310207030308',
          '9310207030309',
          '9310207030310',
          '9310207030311',
          '9310207030312',
          '9310207030313',
          '9310207030314',
          '9310207030401',
          '9310207030402',
          '9310207030403',
          '9310207030404',
          '9310207030405',
          '9310207030406',
          '9310207030407',
          '9310207030408',
          '9310207040101',
          '9310207040102',
          '9310207040103',
          '9310207040104',
          '9310207040105',
          '9310207040106',
          '9310207040107',
          '9310207040108',
          '9310207040109',
          '9310207040110',
          '9310207040111',
          '9310207040201',
          '9310207040202',
          '9310207040203',
          '9310207040204',
          '9310207040205',
          '9310207040206',
          '9310207040207',
          '9310207040208',
          '9310207040209',
          '9310207040210',
          '9310207040211',
          '9310207040212',
          '9310207040301',
          '9310207040302',
          '9310207040303',
          '9310207040401',
          '9310207040402',
          '9310207040403',
          '9310207040404',
          '9310207050101',
          '9310207050102',
          '9310207050103',
          '9310207050104',
          '9310207050105',
          '9310207050106',
          '9310207050107',
          '9310207050108',
          '9310207050109',
          '9310207050110',
          '9310207050201',
          '9310207050202',
          '9310207050203',
          '9310207050204',
          '9310207050205',
          '9310207050206',
          '9310207050207',
          '9310207050208',
          '9310207050209',
          '9310207050210',
          '9310207050301',
          '9310207050302',
          '9310207050303',
          '9310207050304',
          '9310207050305',
          '9310207050306',
          '9310207050307',
          '9310207050308',
          '9310207050309',
          '9310207050310',
          '9310207050401',
          '9310207050402',
          '9310207050403',
          '9310207050404',
          '9310207050405',
          '9310207050406',
          '9310207050407',
          '9310207050408',
          '9310207050409',
          '9310207050410',
          '9310207050501',
          '9310207050502',
          '9310207050503',
          '9310207050504',
          '9310207050505',
          '9310207050506',
          '9310207050507',
          '9310207050508',
          '9310207050509',
          '9310207050510',
          '9310207050601',
          '9310207050602',
          '9310207050603',
          '9310207050604',
          '9310207050605',
          '9310207050606',
          '9310207050607',
          '9310207050608',
          '9310207050609',
          '9310207050610',
          '9310207060101',
          '9310207060102',
          '9310207060103',
          '9310207060104',
          '9310207060105',
          '9310207060106',
          '9310207060107',
          '9310207990000',
          '9310208010101',
          '9310208010102',
          '9310208010103',
          '9310208010104',
          '9310208010105',
          '9310208010106',
          '9310208010107',
          '9310208010108',
          '9310208010109',
          '9310208010110',
          '9310208010111',
          '9310208010112',
          '9310208010113',
          '9310208010201',
          '9310208010202',
          '9310208010203',
          '9310208010204',
          '9310208010205',
          '9310208010206',
          '9310208010207',
          '9310208010208',
          '9310208010209',
          '9310208010210',
          '9310208010211',
          '9310208010212',
          '9310208010213',
          '9310208010214',
          '9310208010215',
          '9310208010301',
          '9310208010302',
          '9310208010303',
          '9310208010304',
          '9310208010305',
          '9310208010306',
          '9310208010307',
          '9310208010308',
          '9310208010309',
          '9310208010310',
          '9310208010311',
          '9310208010312',
          '9310208010313',
          '9310208010314',
          '9310208010315',
          '9310208010316',
          '9310208010401',
          '9310208010402',
          '9310208010403',
          '9310208010404',
          '9310208010405',
          '9310208010406',
          '9310208010407',
          '9310208010408',
          '9310208010409',
          '9310208010410',
          '9310208010501',
          '9310208010502',
          '9310208010503',
          '9310208010504',
          '9310208010505',
          '9310208010506',
          '9310208010507',
          '9310208010508',
          '9310208010509',
          '9310208010510',
          '9310208010601',
          '9310208010602',
          '9310208010603',
          '9310208010604',
          '9310208010605',
          '9310208010606',
          '9310208010607',
          '9310208010608',
          '9310208010609',
          '9310208010610',
          '9310208010611',
          '9310208010612',
          '9310208010613',
          '9310208010614',
          '9310208010701',
          '9310208010702',
          '9310208010703',
          '9310208010704',
          '9310208010705',
          '9310208010706',
          '9310208010707',
          '9310208010708',
          '9310208010709',
          '9310208010710',
          '9310208010711',
          '9310208010712',
          '9310208010713',
          '9310208010714',
          '9310208010715',
          '9310208010716',
          '9310208010717',
          '9310208010718',
          '9310208010719',
          '9310208010720',
          '9310208010721',
          '9310208010801',
          '9310208010802',
          '9310208010803',
          '9310208010804',
          '9310208010805',
          '9310208010806',
          '9310208010807',
          '9310208010808',
          '9310208010809',
          '9310208010810',
          '9310208010811',
          '9310208010901',
          '9310208010902',
          '9310208010903',
          '9310208010904',
          '9310208010905',
          '9310208010906',
          '9310208010907',
          '9310208010908',
          '9310208011001',
          '9310208011002',
          '9310208011003',
          '9310208011004',
          '9310208011005',
          '9310208011006',
          '9310208011007',
          '9310208011008',
          '9310208011009',
          '9310208011010',
          '9310208011011',
          '9310208011012',
          '9310208011013',
          '9310208011014',
          '9310208020101',
          '9310208020102',
          '9310208020103',
          '9310208020104',
          '9310208020105',
          '9310208020106',
          '9310208020107',
          '9310208020108',
          '9310208020109',
          '9310208020110',
          '9310208020111',
          '9310208020112',
          '9310208020113',
          '9310208020114',
          '9310208020115',
          '9310208020116',
          '9310208020117',
          '9310208020118',
          '9310208020119',
          '9310208020120',
          '9310208020121',
          '9310208020122',
          '9310208020123',
          '9310208020124',
          '9310208020125',
          '9310208020126',
          '9310208020127',
          '9310208020128',
          '9310208020129',
          '9310208020130',
          '9310208020131',
          '9310208020132',
          '9310208020133',
          '9310208020134',
          '9310208020135',
          '9310208020136',
          '9310208020137',
          '9310208020138',
          '9310208020139',
          '9310208020140',
          '9310208020141',
          '9310208020142',
          '9310208020201',
          '9310208020202',
          '9310208020203',
          '9310208020204',
          '9310208020301',
          '9310208020302',
          '9310208020303',
          '9310208020304',
          '9310208020305',
          '9310208020306',
          '9310208020307',
          '9310208020308',
          '9310208020309',
          '9310208020310',
          '9310208020311',
          '9310208020312',
          '9310208020313',
          '9310208020314',
          '9310208020315',
          '9310208020316',
          '9310208020317',
          '9310208020318',
          '9310208020319',
          '9310208020320',
          '9310208020321',
          '9310208020322',
          '9310208020323',
          '9310208020324',
          '9310208020325',
          '9310208020326',
          '9310208020327',
          '9310208020328',
          '9310208020329',
          '9310208020330',
          '9310208020331',
          '9310208020332',
          '9310208020333',
          '9310208020334',
          '9310208020335',
          '9310208020336',
          '9310208020337',
          '9310208020338',
          '9310208020339',
          '9310208020340',
          '9310208020341',
          '9310208020342',
          '9310208020343',
          '9310208020344',
          '9310208020345',
          '9310208020346',
          '9310208020347',
          '9310208020348',
          '9310208020349',
          '9310208020350',
          '9310208020351',
          '9310208020352',
          '9310208020353',
          '9310208020354',
          '9310208020401',
          '9310208020402',
          '9310208020403',
          '9310208020404',
          '9310208020405',
          '9310208020406',
          '9310208020407',
          '9310208020408',
          '9310208020409',
          '9310208020410',
          '9310208020411',
          '9310208020412',
          '9310208020413',
          '9310208020414',
          '9310208020415',
          '9310208020416',
          '9310208020417',
          '9310208020418',
          '9310208020419',
          '9310208020420',
          '9310208020421',
          '9310208020422',
          '9310208020423',
          '9310208020424',
          '9310208020425',
          '9310208020426',
          '9310208020427',
          '9310208020428',
          '9310208020429',
          '9310208020430',
          '9310208020501',
          '9310208020502',
          '9310208020503',
          '9310208020504',
          '9310208020505',
          '9310208020506',
          '9310208020507',
          '9310208020508',
          '9310208020509',
          '9310208020510',
          '9310208020511',
          '9310208020512',
          '9310208020513',
          '9310208020514',
          '9310208020515',
          '9310208020516',
          '9310208020517',
          '9310208020518',
          '9310208020519',
          '9310208020520',
          '9310208020521',
          '9310208020522',
          '9310208020523',
          '9310208020524',
          '9310208020525',
          '9310208020526',
          '9310208020527',
          '9310208020528',
          '9310208020529',
          '9310208020530',
          '9310208020531',
          '9310208020532',
          '9310208020533',
          '9310208020534',
          '9310208020535',
          '9310208020536',
          '9310208020537',
          '9310208020538',
          '9310208020539',
          '9310208020540',
          '9310208020541',
          '9310208020542',
          '9310208020601',
          '9310208020602',
          '9310208020603',
          '9310208020604',
          '9310208020605',
          '9310208020606',
          '9310208020607',
          '9310208020701',
          '9310208020702',
          '9310208020703',
          '9310208020704',
          '9310208020705',
          '9310208020706',
          '9310208020707',
          '9310208020708',
          '9310208020709',
          '9310208020710',
          '9310208020711',
          '9310208020712',
          '9310208020713',
          '9310208020714',
          '9310208020715',
          '9310208020716',
          '9310208020717',
          '9310208020718',
          '9310208020719',
          '9310208020720',
          '9310208020721',
          '9310208020722',
          '9310208020723',
          '9310208020724',
          '9310208020725',
          '9310208020726',
          '9310208020727',
          '9310208020728',
          '9310208020729',
          '9310208020730',
          '9310208020731',
          '9310208020732',
          '9310208020733',
          '9310208020734',
          '9310208020801',
          '9310208020802',
          '9310208020803',
          '9310208020804',
          '9310208020805',
          '9310208020806',
          '9310208020901',
          '9310208020902',
          '9310208020903',
          '9310208020904',
          '9310208020905',
          '9310208020906',
          '9310208020907',
          '9310208020908',
          '9310208020909',
          '9310208020910',
          '9310208020911',
          '9310208020912',
          '9310208020913',
          '9310208020914',
          '9310208020915',
          '9310208020916',
          '9310208020917',
          '9310208020918',
          '9310208020919',
          '9310208020920',
          '9310208020921',
          '9310208020922',
          '9310208020923',
          '9310208020924',
          '9310208020925',
          '9310208020926',
          '9310208020927',
          '9310208020928',
          '9310208020929',
          '9310208020930',
          '9310208020931',
          '9310208020932',
          '9310208020933',
          '9310208020934',
          '9310208020935',
          '9310208020936',
          '9310208020937',
          '9310208020938',
          '9310208020939',
          '9310208020940',
          '9310208020941',
          '9310208020942',
          '9310208020943',
          '9310208020944',
          '9310208021001',
          '9310208021002',
          '9310208021003',
          '9310208021004',
          '9310208021005',
          '9310208021006',
          '9310208021007',
          '9310208021008',
          '9310208021009',
          '9310208021010',
          '9310208021011',
          '9310208021012',
          '9310208021013',
          '9310208021014',
          '9310208021015',
          '9310208021016',
          '9310208021017',
          '9310208021018',
          '9310208021019',
          '9310208021020',
          '9310208021021',
          '9310208021022',
          '9310208021023',
          '9310208021024',
          '9310208021025',
          '9310208021026',
          '9310208021027',
          '9310208021028',
          '9310208021029',
          '9310208021030',
          '9310208021101',
          '9310208021102',
          '9310208021103',
          '9310208021104',
          '9310208021105',
          '9310208021106',
          '9310208021107',
          '9310208030101',
          '9310208030102',
          '9310208030103',
          '9310208030104',
          '9310208030105',
          '9310208030106',
          '9310208030107',
          '9310208030108',
          '9310208030109',
          '9310208030110',
          '9310208030111',
          '9310208030112',
          '9310208030113',
          '9310208030114',
          '9310208030115',
          '9310208030116',
          '9310208030117',
          '9310208030118',
          '9310208030119',
          '9310208030120',
          '9310208030121',
          '9310208030122',
          '9310208030123',
          '9310208030124',
          '9310208030125',
          '9310208030126',
          '9310208030127',
          '9310208030128',
          '9310208030129',
          '9310208030130',
          '9310208030131',
          '9310208030132',
          '9310208030133',
          '9310208030134',
          '9310208030135',
          '9310208030136',
          '9310208030137',
          '9310208030201',
          '9310208030202',
          '9310208030203',
          '9310208030204',
          '9310208030205',
          '9310208030206',
          '9310208030207',
          '9310208030208',
          '9310208030209',
          '9310208030210',
          '9310208030211',
          '9310208030212',
          '9310208030213',
          '9310208030214',
          '9310208030215',
          '9310208030216',
          '9310208030217',
          '9310208030218',
          '9310208030219',
          '9310208030220',
          '9310208030221',
          '9310208030222',
          '9310208030223',
          '9310208030224',
          '9310208030225',
          '9310208030226',
          '9310208030301',
          '9310208030302',
          '9310208030303',
          '9310208030304',
          '9310208030305',
          '9310208030306',
          '9310208030307',
          '9310208030308',
          '9310208030309',
          '9310208030310',
          '9310208030311',
          '9310208030312',
          '9310208030313',
          '9310208030314',
          '9310208030315',
          '9310208030316',
          '9310208030317',
          '9310208030318',
          '9310208030319',
          '9310208030320',
          '9310208030321',
          '9310208030322',
          '9310208030323',
          '9310208030324',
          '9310208030325',
          '9310208030326',
          '9310208030327',
          '9310208030328',
          '9310208030329',
          '9310208030330',
          '9310208030331',
          '9310208030332',
          '9310208030333',
          '9310208030334',
          '9310208030335',
          '9310208030336',
          '9310208030337',
          '9310208030338',
          '9310208030339',
          '9310208030340',
          '9310208030341',
          '9310208030342',
          '9310208030343',
          '9310208030344',
          '9310208030345',
          '9310208030346',
          '9310208030347',
          '9310208030348',
          '9310208030349',
          '9310208030350',
          '9310208030401',
          '9310208030402',
          '9310208030403',
          '9310208030404',
          '9310208030405',
          '9310208030406',
          '9310208030407',
          '9310208030408',
          '9310208030409',
          '9310208030410',
          '9310208030411',
          '9310208030412',
          '9310208030413',
          '9310208030414',
          '9310208030415',
          '9310208030416',
          '9310208030417',
          '9310208030418',
          '9310208030419',
          '9310208030420',
          '9310208030421',
          '9310208030422',
          '9310208030423',
          '9310208030424',
          '9310208030425',
          '9310208030426',
          '9310208030427',
          '9310208030428',
          '9310208030429',
          '9310208030430',
          '9310208030431',
          '9310208030432',
          '9310208030433',
          '9310208030434',
          '9310208030435',
          '9310208030436',
          '9310208030437',
          '9310208030438',
          '9310208030439',
          '9310208030440',
          '9310208030441',
          '9310208030442',
          '9310208030443',
          '9310208030444',
          '9310208030445',
          '9310208030446',
          '9310208030447',
          '9310208030448',
          '9310208030449',
          '9310208030450',
          '9310208030451',
          '9310208030452',
          '9310208030453',
          '9310208030454',
          '9310208030455',
          '9310208030456',
          '9310208030457',
          '9310208030458',
          '9310208030459',
          '9310208040101',
          '9310208040102',
          '9310208040103',
          '9310208040104',
          '9310208040105',
          '9310208040106',
          '9310208040107',
          '9310208040108',
          '9310208040109',
          '9310208040110',
          '9310208040111',
          '9310208040112',
          '9310208040113',
          '9310208040114',
          '9310208040115',
          '9310208040116',
          '9310208040117',
          '9310208040118',
          '9310208040119',
          '9310208040120',
          '9310208040121',
          '9310208040122',
          '9310208040123',
          '9310208040124',
          '9310208040125',
          '9310208040126',
          '9310208040127',
          '9310208040128',
          '9310208040129',
          '9310208040130',
          '9310208040131',
          '9310208040132',
          '9310208040133',
          '9310208040134',
          '9310208040135',
          '9310208040136',
          '9310208040137',
          '9310208040138',
          '9310208040139',
          '9310208040140',
          '9310208040141',
          '9310208040142',
          '9310208040143',
          '9310208040144',
          '9310208040145',
          '9310208040146',
          '9310208040147',
          '9310208040148',
          '9310208050101',
          '9310208050102',
          '9310208050103',
          '9310208050104',
          '9310208050105',
          '9310208050106',
          '9310208050107',
          '9310208050108',
          '9310208050109',
          '9310208050110',
          '9310208050201',
          '9310208050202',
          '9310208050203',
          '9310208050204',
          '9310208050205',
          '9310208050206',
          '9310208050207',
          '9310208050208',
          '9310208050209',
          '9310208050210',
          '9310208050301',
          '9310208050302',
          '9310208050303',
          '9310208050304',
          '9310208050305',
          '9310208050306',
          '9310208050307',
          '9310208050308',
          '9310208050309',
          '9310208050310',
          '9310208050311',
          '9310208050312',
          '9310208050401',
          '9310208050501',
          '9310208050502',
          '9310208050503',
          '9310208050504',
          '9310208050505',
          '9310208050601',
          '9310208050602',
          '9310208050603',
          '9310208050604',
          '9310208050605',
          '9310208050606',
          '9310208050607',
          '9310208050701',
          '9310208050702',
          '9310208050703',
          '9310208050704',
          '9310208050705',
          '9310208060101',
          '9310208060102',
          '9310208060103',
          '9310208060104',
          '9310208060105',
          '9310208060106',
          '9310208060107',
          '9310208060108',
          '9310208060109',
          '9310208060110',
          '9310208060111',
          '9310208060112',
          '9310208060113',
          '9310208060114',
          '9310208060115',
          '9310208060116',
          '9310208060117',
          '9310208060118',
          '9310208060119',
          '9310208060120',
          '9310208990000',
          '9310209010101',
          '9310209010102',
          '9310209010103',
          '9310209010104',
          '9310209010105',
          '9310209010106',
          '9310209010107',
          '9310209010108',
          '9310209010109',
          '9310209010110',
          '9310209010111',
          '9310209010112',
          '9310209010113',
          '9310209010114',
          '9310209010115',
          '9310209010116',
          '9310209010117',
          '9310209010118',
          '9310209010119',
          '9310209010120',
          '9310209010121',
          '9310209010201',
          '9310209010202',
          '9310209010203',
          '9310209010204',
          '9310209010205',
          '9310209010206',
          '9310209010207',
          '9310209010208',
          '9310209010209',
          '9310209010210',
          '9310209010211',
          '9310209010212',
          '9310209010213',
          '9310209010214',
          '9310209010215',
          '9310209010216',
          '9310209010217',
          '9310209010218',
          '9310209010219',
          '9310209010220',
          '9310209010221',
          '9310209010301',
          '9310209010302',
          '9310209010303',
          '9310209010304',
          '9310209010305',
          '9310209010306',
          '9310209010307',
          '9310209010308',
          '9310209010309',
          '9310209010310',
          '9310209010311',
          '9310209010312',
          '9310209010313',
          '9310209010314',
          '9310209010315',
          '9310209010316',
          '9310209010317',
          '9310209010318',
          '9310209010319',
          '9310209010401',
          '9310209010402',
          '9310209010403',
          '9310209010404',
          '9310209010405',
          '9310209010406',
          '9310209010407',
          '9310209010408',
          '9310209010409',
          '9310209010410',
          '9310209010411',
          '9310209010412',
          '9310209010413',
          '9310209010414',
          '9310209010415',
          '9310209010416',
          '9310209010417',
          '9310209010418',
          '9310209010419',
          '9310209010420',
          '9310209020101',
          '9310209020102',
          '9310209020103',
          '9310209020201',
          '9310209020202',
          '9310209020203',
          '9310209020301',
          '9310209020302',
          '9310209020303',
          '9310209020304',
          '9310209020305',
          '9310209020306',
          '9310209020307',
          '9310209020308',
          '9310209020309',
          '9310209020310',
          '9310209020401',
          '9310209020402',
          '9310209020501',
          '9310209020502',
          '9310209020503',
          '9310209020504',
          '9310209020505',
          '9310209020506',
          '9310209020507',
          '9310209020508',
          '9310209020509',
          '9310209020510',
          '9310209020601',
          '9310209020602',
          '9310209020603',
          '9310209030101',
          '9310209030102',
          '9310209030103',
          '9310209030104',
          '9310209030105',
          '9310209030106',
          '9310209030107',
          '9310209030108',
          '9310209030109',
          '9310209030110',
          '9310209030111',
          '9310209030112',
          '9310209030113',
          '9310209030114',
          '9310209030115',
          '9310209030116',
          '9310209030117',
          '9310209030118',
          '9310209030119',
          '9310209030120',
          '9310209030121',
          '9310209030122',
          '9310209030123',
          '9310209030124',
          '9310209030125',
          '9310209030126',
          '9310209030127',
          '9310209030128',
          '9310209030129',
          '9310209030130',
          '9310209030131',
          '9310209030132',
          '9310209030133',
          '9310209030134',
          '9310209030135',
          '9310209040101',
          '9310209040102',
          '9310209040103',
          '9310209040104',
          '9310209040105',
          '9310209040106',
          '9310209040107',
          '9310209040201',
          '9310209050101',
          '9310209050102',
          '9310209050201',
          '9310209050202',
          '9310209050203',
          '9310209060101',
          '9310209060102',
          '9310209060103',
          '9310209060104',
          '9310209060105',
          '9310209060106',
          '9310209060107',
          '9310209060108',
          '9310209060109',
          '9310209060110',
          '9310209060111',
          '9310209060112',
          '9310209060113',
          '9310209060114',
          '9310209060115',
          '9310209060116',
          '9310209060117',
          '9310209060118',
          '9310209060119',
          '9310209060120',
          '9310209060121',
          '9310209060122',
          '9310209060123',
          '9310209060124',
          '9310209060125',
          '9310209060126',
          '9310209060127',
          '9310209060128',
          '9310209060129',
          '9310209060130',
          '9310209060131',
          '9310209060132',
          '9310209060133',
          '9310209060134',
          '9310209060135',
          '9310209060136',
          '9310209060137',
          '9310209060138',
          '9310209060139',
          '9310209060140',
          '9310209990000',
          '9310210010101',
          '9310210010102',
          '9310210010103',
          '9310210010104',
          '9310210010105',
          '9310210010106',
          '9310210010107',
          '9310210010108',
          '9310210010109',
          '9310210010110',
          '9310210010111',
          '9310210010112',
          '9310210010113',
          '9310210010114',
          '9310210010115',
          '9310210010116',
          '9310210010117',
          '9310210010118',
          '9310210010119',
          '9310210010120',
          '9310210010121',
          '9310210010122',
          '9310210010201',
          '9310210010202',
          '9310210010203',
          '9310210010204',
          '9310210010205',
          '9310210010206',
          '9310210010207',
          '9310210010208',
          '9310210010209',
          '9310210010210',
          '9310210010211',
          '9310210010212',
          '9310210010213',
          '9310210010214',
          '9310210010215',
          '9310210010216',
          '9310210010217',
          '9310210010218',
          '9310210010219',
          '9310210010301',
          '9310210010302',
          '9310210010303',
          '9310210010304',
          '9310210010305',
          '9310210010306',
          '9310210010307',
          '9310210010308',
          '9310210010309',
          '9310210010310',
          '9310210010311',
          '9310210010312',
          '9310210010401',
          '9310210010402',
          '9310210010403',
          '9310210010501',
          '9310210010502',
          '9310210010503',
          '9310210010504',
          '9310210010505',
          '9310210010506',
          '9310210010507',
          '9310210010508',
          '9310210010509',
          '9310210010510',
          '9310210010511',
          '9310210010512',
          '9310210010513',
          '9310210010514',
          '9310210010601',
          '9310210010602',
          '9310210010603',
          '9310210010604',
          '9310210010605',
          '9310210010606',
          '9310210010607',
          '9310210010608',
          '9310210010609',
          '9310210010610',
          '9310210010611',
          '9310210010612',
          '9310210010613',
          '9310210010614',
          '9310210010615',
          '9310210010616',
          '9310210010617',
          '9310210010618',
          '9310210010619',
          '9310210010620',
          '9310210010621',
          '9310210010622',
          '9310210010623',
          '9310210010624',
          '9310210010625',
          '9310210010626',
          '9310210010627',
          '9310210010628',
          '9310210010629',
          '9310210010630',
          '9310210010701',
          '9310210010801',
          '9310210010802',
          '9310210010901',
          '9310210010902',
          '9310210010903',
          '9310210010904',
          '9310210010905',
          '9310210011001',
          '9310210011002',
          '9310210011003',
          '9310210011004',
          '9310210011005',
          '9310210011101',
          '9310210011102',
          '9310210020101',
          '9310210020102',
          '9310210020103',
          '9310210020104',
          '9310210020105',
          '9310210020106',
          '9310210020107',
          '9310210020108',
          '9310210020109',
          '9310210020110',
          '9310210020111',
          '9310210020112',
          '9310210020113',
          '9310210020114',
          '9310210020115',
          '9310210020116',
          '9310210020117',
          '9310210020118',
          '9310210020119',
          '9310210020120',
          '9310210020121',
          '9310210020122',
          '9310210020123',
          '9310210020124',
          '9310210020201',
          '9310210020202',
          '9310210020203',
          '9310210020204',
          '9310210020205',
          '9310210020206',
          '9310210020207',
          '9310210020208',
          '9310210020301',
          '9310210020302',
          '9310210020303',
          '9310210020304',
          '9310210020305',
          '9310210020306',
          '9310210020307',
          '9310210020308',
          '9310210020309',
          '9310210020310',
          '9310210020311',
          '9310210020312',
          '9310210020313',
          '9310210020314',
          '9310210020315',
          '9310210020316',
          '9310210020317',
          '9310210020318',
          '9310210020319',
          '9310210020320',
          '9310210020321',
          '9310210020322',
          '9310210020323',
          '9310210020324',
          '9310210020325',
          '9310210020326',
          '9310210020401',
          '9310210020402',
          '9310210020403',
          '9310210020404',
          '9310210020405',
          '9310210020406',
          '9310210020407',
          '9310210020408',
          '9310210020501',
          '9310210020502',
          '9310210020503',
          '9310210020601',
          '9310210020602',
          '9310210020603',
          '9310210020604',
          '9310210020605',
          '9310210020606',
          '9310210020607',
          '9310210020608',
          '9310210020609',
          '9310210020610',
          '9310210020611',
          '9310210020612',
          '9310210020613',
          '9310210020614',
          '9310210020615',
          '9310210020616',
          '9310210020617',
          '9310210020618',
          '9310210020619',
          '9310210020620',
          '9310210020621',
          '9310210020622',
          '9310210020623',
          '9310210020624',
          '9310210020625',
          '9310210020701',
          '9310210020702',
          '9310210020703',
          '9310210020704',
          '9310210020801',
          '9310210020802',
          '9310210020803',
          '9310210020804',
          '9310210020805',
          '9310210020806',
          '9310210020807',
          '9310210020808',
          '9310210020809',
          '9310210020810',
          '9310210020901',
          '9310210020902',
          '9310210020903',
          '9310210020904',
          '9310210020905',
          '9310210020906',
          '9310210020907',
          '9310210020908',
          '9310210020909',
          '9310210020910',
          '9310210020911',
          '9310210020912',
          '9310210020913',
          '9310210020914',
          '9310210020915',
          '9310210020916',
          '9310210020917',
          '9310210020918',
          '9310210020919',
          '9310210020920',
          '9310210020921',
          '9310210020922',
          '9310210021001',
          '9310210021002',
          '9310210021003',
          '9310210021004',
          '9310210021005',
          '9310210021006',
          '9310210021007',
          '9310210021008',
          '9310210021009',
          '9310210021010',
          '9310210021011',
          '9310210021012',
          '9310210021013',
          '9310210021014',
          '9310210021015',
          '9310210021016',
          '9310210021017',
          '9310210021018',
          '9310210021019',
          '9310210021101',
          '9310210021102',
          '9310210021103',
          '9310210021104',
          '9310210021201',
          '9310210021202',
          '9310210021203',
          '9310210021204',
          '9310210021205',
          '9310210021206',
          '9310210021207',
          '9310210021208',
          '9310210021209',
          '9310210021210',
          '9310210021211',
          '9310210021212',
          '9310210021213',
          '9310210021214',
          '9310210021215',
          '9310210021216',
          '9310210021217',
          '9310210021218',
          '9310210021219',
          '9310210021220',
          '9310210021221',
          '9310210021222',
          '9310210021223',
          '9310210021224',
          '9310210021225',
          '9310210021226',
          '9310210021227',
          '9310210021228',
          '9310210021229',
          '9310210030101',
          '9310210030102',
          '9310210030103',
          '9310210030104',
          '9310210030105',
          '9310210030106',
          '9310210030107',
          '9310210030108',
          '9310210030109',
          '9310210030110',
          '9310210030111',
          '9310210030112',
          '9310210030113',
          '9310210030114',
          '9310210030115',
          '9310210030201',
          '9310210030202',
          '9310210030301',
          '9310210030302',
          '9310210030303',
          '9310210030304',
          '9310210030305',
          '9310210030306',
          '9310210040101',
          '9310210040102',
          '9310210040103',
          '9310210040104',
          '9310210040105',
          '9310210040106',
          '9310210040107',
          '9310210040108',
          '9310210040109',
          '9310210040110',
          '9310210040111',
          '9310210040112',
          '9310210040113',
          '9310210040114',
          '9310210040115',
          '9310210040116',
          '9310210040117',
          '9310210040118',
          '9310210040119',
          '9310210040120',
          '9310210040201',
          '9310210040202',
          '9310210040301',
          '9310210040302',
          '9310210040303',
          '9310210040304',
          '9310210040305',
          '9310210040306',
          '9310210040307',
          '9310210040308',
          '9310210040309',
          '9310210040310',
          '9310210040311',
          '9310210040312',
          '9310210040313',
          '9310210040314',
          '9310210040315',
          '9310210040316',
          '9310210040317',
          '9310210040318',
          '9310210040319',
          '9310210040320',
          '9310210040321',
          '9310210040322',
          '9310210040323',
          '9310210040324',
          '9310210040325',
          '9310210040326',
          '9310210040327',
          '9310210040328',
          '9310210040329',
          '9310210040330',
          '9310210040331',
          '9310210040332',
          '9310210040333',
          '9310210040401',
          '9310210040402',
          '9310210040403',
          '9310210040404',
          '9310210040405',
          '9310210040406',
          '9310210040407',
          '9310210040408',
          '9310210040501',
          '9310210040502',
          '9310210050101',
          '9310210050102',
          '9310210050103',
          '9310210050104',
          '9310210050105',
          '9310210050106',
          '9310210050107',
          '9310210050108',
          '9310210050109',
          '9310210050110',
          '9310210050111',
          '9310210050112',
          '9310210050113',
          '9310210050114',
          '9310210050115',
          '9310210050116',
          '9310210050117',
          '9310210050118',
          '9310210050119',
          '9310210050120',
          '9310210050121',
          '9310210050201',
          '9310210050202',
          '9310210050203',
          '9310210050204',
          '9310210050205',
          '9310210050206',
          '9310210050207',
          '9310210050208',
          '9310210050209',
          '9310210050210',
          '9310210050211',
          '9310210050212',
          '9310210050213',
          '9310210050214',
          '9310210050215',
          '9310210050216',
          '9310210050217',
          '9310210050218',
          '9310210050219',
          '9310210050220',
          '9310210050221',
          '9310210050222',
          '9310210050223',
          '9310210050224',
          '9310210050225',
          '9310210050226',
          '9310210050227',
          '9310210050228',
          '9310210050229',
          '9310210050230',
          '9310210050231',
          '9310210050232',
          '9310210050233',
          '9310210050234',
          '9310210050235',
          '9310210050236',
          '9310210050237',
          '9310210050301',
          '9310210050302',
          '9310210050303',
          '9310210050304',
          '9310210050305',
          '9310210050306',
          '9310210050307',
          '9310210050401',
          '9310210060101',
          '9310210060102',
          '9310210060103',
          '9310210060104',
          '9310210060105',
          '9310210060106',
          '9310210060107',
          '9310210060108',
          '9310210060109',
          '9310210060110',
          '9310210060111',
          '9310210060112',
          '9310210060113',
          '9310210060114',
          '9310210060115',
          '9310210060116',
          '9310210060117',
          '9310210060118',
          '9310210060119',
          '9310210060120',
          '9310210060121',
          '9310210060201',
          '9310210060202',
          '9310210060203',
          '9310210060204',
          '9310210060205',
          '9310210060206',
          '9310210060207',
          '9310210060208',
          '9310210060209',
          '9310210060210',
          '9310210060211',
          '9310210060212',
          '9310210060213',
          '9310210060214',
          '9310210060215',
          '9310210060216',
          '9310210060217',
          '9310210060218',
          '9310210060219',
          '9310210060220',
          '9310210060221',
          '9310210060222',
          '9310210060223',
          '9310210060224',
          '9310210060225',
          '9310210060226',
          '9310210060227',
          '9310210060228',
          '9310210060229',
          '9310210060230',
          '9310210060231',
          '9310210060232',
          '9310210060233',
          '9310210060234',
          '9310210060235',
          '9310210060236',
          '9310210060237',
          '9310210060238',
          '9310210060239',
          '9310210060240',
          '9310210060241',
          '9310210060242',
          '9310210060243',
          '9310210060301',
          '9310210060302',
          '9310210060303',
          '9310210060304',
          '9310210060305',
          '9310210060306',
          '9310210060401',
          '9310210070101',
          '9310210070102',
          '9310210070103',
          '9310210070104',
          '9310210070105',
          '9310210070106',
          '9310210070107',
          '9310210070108',
          '9310210070109',
          '9310210070110',
          '9310210070111',
          '9310210070112',
          '9310210070113',
          '9310210070114',
          '9310210070115',
          '9310210070116',
          '9310210070117',
          '9310210070118',
          '9310210070119',
          '9310210070120',
          '9310210070121',
          '9310210070201',
          '9310210070202',
          '9310210070203',
          '9310210070204',
          '9310210070205',
          '9310210070206',
          '9310210070207',
          '9310210070208',
          '9310210070209',
          '9310210070210',
          '9310210070211',
          '9310210070212',
          '9310210070213',
          '9310210070214',
          '9310210070215',
          '9310210070216',
          '9310210070217',
          '9310210070218',
          '9310210070219',
          '9310210070220',
          '9310210070221',
          '9310210070222',
          '9310210070223',
          '9310210070224',
          '9310210070225',
          '9310210070226',
          '9310210070227',
          '9310210070228',
          '9310210070229',
          '9310210070301',
          '9310210070302',
          '9310210070303',
          '9310210070304',
          '9310210070305',
          '9310210070306',
          '9310210070307',
          '9310210070308',
          '9310210070401',
          '9310210070402',
          '9310210070403',
          '9310210070404',
          '9310210070405',
          '9310210070406',
          '9310210070407',
          '9310210070408',
          '9310210070409',
          '9310210070410',
          '9310210070411',
          '9310210070501',
          '9310210070502',
          '9310210070503',
          '9310210070504',
          '9310210070505',
          '9310210070506',
          '9310210070507',
          '9310210070508',
          '9310210070509',
          '9310210070510',
          '9310210070511',
          '9310210070512',
          '9310210070513',
          '9310210080101',
          '9310210080102',
          '9310210080103',
          '9310210080104',
          '9310210080105',
          '9310210080106',
          '9310210080107',
          '9310210080108',
          '9310210080109',
          '9310210080110',
          '9310210080111',
          '9310210080112',
          '9310210080201',
          '9310210080202',
          '9310210080203',
          '9310210080204',
          '9310210080205',
          '9310210080206',
          '9310210080207',
          '9310210080208',
          '9310210080209',
          '9310210080210',
          '9310210080211',
          '9310210080212',
          '9310210080213',
          '9310210080214',
          '9310210090101',
          '9310210090102',
          '9310210090103',
          '9310210090104',
          '9310210090105',
          '9310210090106',
          '9310210090107',
          '9310210090108',
          '9310210090109',
          '9310210090110',
          '9310210090111',
          '9310210090112',
          '9310210090113',
          '9310210090114',
          '9310210090115',
          '9310210090116',
          '9310210090117',
          '9310210090118',
          '9310210090119',
          '9310210090120',
          '9310210090121',
          '9310210090122',
          '9310210090201',
          '9310210090202',
          '9310210090203',
          '9310210090204',
          '9310210090205',
          '9310210090206',
          '9310210090207',
          '9310210090301',
          '9310210090401',
          '9310210090402',
          '9310210090403',
          '9310210100101',
          '9310210100102',
          '9310210100103',
          '9310210100104',
          '9310210100105',
          '9310210100106',
          '9310210100107',
          '9310210100108',
          '9310210100109',
          '9310210100110',
          '9310210100111',
          '9310210100112',
          '9310210100113',
          '9310210100114',
          '9310210100115',
          '9310210100116',
          '9310210100117',
          '9310210100118',
          '9310210100119',
          '9310210100120',
          '9310210100121',
          '9310210100122',
          '9310210100123',
          '9310210100201',
          '9310210100202',
          '9310210100203',
          '9310210100204',
          '9310210100205',
          '9310210100206',
          '9310210100207',
          '9310210100208',
          '9310210100209',
          '9310210100210',
          '9310210100211',
          '9310210100212',
          '9310210100213',
          '9310210100214',
          '9310210100215',
          '9310210100216',
          '9310210100217',
          '9310210100218',
          '9310210100219',
          '9310210100220',
          '9310210100221',
          '9310210100222',
          '9310210100223',
          '9310210100224',
          '9310210100225',
          '9310210100226',
          '9310210100227',
          '9310210100228',
          '9310210100229',
          '9310210100230',
          '9310210100231',
          '9310210100232',
          '9310210100233',
          '9310210100234',
          '9310210100235',
          '9310210100236',
          '9310210100237',
          '9310210100301',
          '9310210100302',
          '9310210100303',
          '9310210100304',
          '9310210100305',
          '9310210110101',
          '9310210110102',
          '9310210110103',
          '9310210110104',
          '9310210110105',
          '9310210110106',
          '9310210110107',
          '9310210110201',
          '9310210110202',
          '9310210110203',
          '9310210110204',
          '9310210110205',
          '9310210110206',
          '9310210110207',
          '9310210110208',
          '9310210110209',
          '9310210110210',
          '9310210110211',
          '9310210110301',
          '9310210110302',
          '9310210110303',
          '9310210110304',
          '9310210110305',
          '9310210110306',
          '9310210110307',
          '9310210110308',
          '9310210110309',
          '9310210110310',
          '9310210110311',
          '9310210110312',
          '9310210110313',
          '9310210110314',
          '9310210110315',
          '9310210110316',
          '9310210110317',
          '9310210110318',
          '9310210110319',
          '9310210110320',
          '9310210110321',
          '9310210110322',
          '9310210110323',
          '9310210110324',
          '9310210110325',
          '9310210110326',
          '9310210110327',
          '9310210110328',
          '9310210110329',
          '9310210110330',
          '9310210110331',
          '9310210110332',
          '9310210110333',
          '9310210110334',
          '9310210110335',
          '9310210110336',
          '9310210110337',
          '9310210110338',
          '9310210110339',
          '9310210110340',
          '9310210110401',
          '9310210110402',
          '9310210110403',
          '9310210110404',
          '9310210110405',
          '9310210110406',
          '9310210110501',
          '9310210110502',
          '9310210110503',
          '9310210110504',
          '9310210110505',
          '9310210110506',
          '9310210110507',
          '9310210110508',
          '9310210110509',
          '9310210110510',
          '9310210110511',
          '9310210110512',
          '9310210110513',
          '9310210110514',
          '9310210110515',
          '9310210110516',
          '9310210110517',
          '9310210110601',
          '9310210120101',
          '9310210120102',
          '9310210120103',
          '9310210120104',
          '9310210120105',
          '9310210120106',
          '9310210120107',
          '9310210120108',
          '9310210120109',
          '9310210120110',
          '9310210120111',
          '9310210120112',
          '9310210120113',
          '9310210120114',
          '9310210120115',
          '9310210120116',
          '9310210120117',
          '9310210120118',
          '9310210120119',
          '9310210120120',
          '9310210120121',
          '9310210120122',
          '9310210120201',
          '9310210120202',
          '9310210120203',
          '9310210120301',
          '9310210120302',
          '9310210120303',
          '9310210120304',
          '9310210120305',
          '9310210120306',
          '9310210120307',
          '9310210120308',
          '9310210120309',
          '9310210120310',
          '9310210120311',
          '9310210120312',
          '9310210120313',
          '9310210120314',
          '9310210120315',
          '9310210120316',
          '9310210120317',
          '9310210120318',
          '9310210120319',
          '9310210120320',
          '9310210120321',
          '9310210120322',
          '9310210120323',
          '9310210120324',
          '9310210120401',
          '9310210120501',
          '9310210120502',
          '9310210120503',
          '9310210120504',
          '9310210120505',
          '9310210120506',
          '9310210120507',
          '9310210120508',
          '9310210130101',
          '9310210130102',
          '9310210130103',
          '9310210130104',
          '9310210130105',
          '9310210130106',
          '9310210130107',
          '9310210130108',
          '9310210130109',
          '9310210130110',
          '9310210130111',
          '9310210130112',
          '9310210130113',
          '9310210130114',
          '9310210130115',
          '9310210130116',
          '9310210130117',
          '9310210130118',
          '9310210130119',
          '9310210130120',
          '9310210130121',
          '9310210130201',
          '9310210130202',
          '9310210130203',
          '9310210130204',
          '9310210130205',
          '9310210130206',
          '9310210130207',
          '9310210130208',
          '9310210130209',
          '9310210130210',
          '9310210130211',
          '9310210130212',
          '9310210130213',
          '9310210130214',
          '9310210130215',
          '9310210130216',
          '9310210130217',
          '9310210130218',
          '9310210130219',
          '9310210130220',
          '9310210130221',
          '9310210130222',
          '9310210130223',
          '9310210130224',
          '9310210130225',
          '9310210130226',
          '9310210130227',
          '9310210130228',
          '9310210130229',
          '9310210130230',
          '9310210130231',
          '9310210130232',
          '9310210130233',
          '9310210130234',
          '9310210130235',
          '9310210130301',
          '9310210130302',
          '9310210130303',
          '9310210130304',
          '9310210130305',
          '9310210130401',
          '9310210130402',
          '9310210130403',
          '9310210130404',
          '9310210130405',
          '9310210130406',
          '9310210130407',
          '9310210140101',
          '9310210140102',
          '9310210140201',
          '9310210140202',
          '9310210140203',
          '9310210140204',
          '9310210140205',
          '9310210140206',
          '9310210140207',
          '9310210140208',
          '9310210140209',
          '9310210140210',
          '9310210140211',
          '9310210140212',
          '9310210140213',
          '9310210140214',
          '9310210140215',
          '9310210140216',
          '9310210140217',
          '9310210140218',
          '9310210150101',
          '9310210150102',
          '9310210150103',
          '9310210150104',
          '9310210150105',
          '9310210150106',
          '9310210150107',
          '9310210150108',
          '9310210150109',
          '9310210150110',
          '9310210150111',
          '9310210150112',
          '9310210150113',
          '9310210150114',
          '9310210150115',
          '9310210150116',
          '9310210150117',
          '9310210150118',
          '9310210150119',
          '9310210150120',
          '9310210150121',
          '9310210150122',
          '9310210150123',
          '9310210150124',
          '9310210150125',
          '9310210150126',
          '9310210150201',
          '9310210150202',
          '9310210150203',
          '9310210150204',
          '9310210150205',
          '9310210150206',
          '9310210150207',
          '9310210150208',
          '9310210150209',
          '9310210150210',
          '9310210150211',
          '9310210150212',
          '9310210150213',
          '9310210150214',
          '9310210150215',
          '9310210150216',
          '9310210150217',
          '9310210150218',
          '9310210150219',
          '9310210150220',
          '9310210150221',
          '9310210150222',
          '9310210150223',
          '9310210150224',
          '9310210150225',
          '9310210150226',
          '9310210150227',
          '9310210150301',
          '9310210150302',
          '9310210150303',
          '9310210150304',
          '9310210150305',
          '9310210150306',
          '9310210150307',
          '9310210150308',
          '9310210150309',
          '9310210150310',
          '9310210150311',
          '9310210150312',
          '9310210150313',
          '9310210150314',
          '9310210150315',
          '9310210150316',
          '9310210150317',
          '9310210150318',
          '9310210150401',
          '9310210150402',
          '9310210150403',
          '9310210150404',
          '9310210150405',
          '9310210150406',
          '9310210150407',
          '9310210150408',
          '9310210150409',
          '9310210150410',
          '9310210150411',
          '9310210150412',
          '9310210150413',
          '9310210150414',
          '9310210150415',
          '9310210150416',
          '9310210150417',
          '9310210150418',
          '9310210150419',
          '9310210150420',
          '9310210150421',
          '9310210150501',
          '9310210150502',
          '9310210150503',
          '9310210150504',
          '9310210150505',
          '9310210150506',
          '9310210150507',
          '9310210150508',
          '9310210150509',
          '9310210150510',
          '9310210150511',
          '9310210150512',
          '9310210160101',
          '9310210160102',
          '9310210160103',
          '9310210160104',
          '9310210160105',
          '9310210160106',
          '9310210160107',
          '9310210160108',
          '9310210160109',
          '9310210160110',
          '9310210160111',
          '9310210160201',
          '9310210160301',
          '9310210160302',
          '9310210160303',
          '9310210160304',
          '9310210160305',
          '9310210160306',
          '9310210160307',
          '9310210160401',
          '9310210160402',
          '9310210160403',
          '9310210160404',
          '9310210160501',
          '9310210160502',
          '9310210170101',
          '9310210170102',
          '9310210170103',
          '9310210170104',
          '9310210170105',
          '9310210170106',
          '9310210170107',
          '9310210170108',
          '9310210170109',
          '9310210170110',
          '9310210170201',
          '9310210170202',
          '9310210170203',
          '9310210170204',
          '9310210170205',
          '9310210170301',
          '9310210170302',
          '9310210170303',
          '9310210170304',
          '9310210170305',
          '9310210170401',
          '9310210170402',
          '9310210170403',
          '9310210170404',
          '9310210180101',
          '9310210180102',
          '9310210180103',
          '9310210180104',
          '9310210180105',
          '9310210180106',
          '9310210180107',
          '9310210180201',
          '9310210180202',
          '9310210180301',
          '9310210180302',
          '9310210180303',
          '9310210180401',
          '9310210180402',
          '9310210180403',
          '9310210180404',
          '9310210180501',
          '9310210180502',
          '9310210180503',
          '9310210180504',
          '9310210180601',
          '9310210180602',
          '9310210180603',
          '9310210180604',
          '9310210180605',
          '9310210180606',
          '9310210180701',
          '9310210180702',
          '9310210180703',
          '9310210180704',
          '9310210180705',
          '9310210190101',
          '9310210190102',
          '9310210190103',
          '9310210190104',
          '9310210190105',
          '9310210190106',
          '9310210190107',
          '9310210190108',
          '9310210190109',
          '9310210190110',
          '9310210990000',
          '9310211010101',
          '9310211010102',
          '9310211010103',
          '9310211010104',
          '9310211010105',
          '9310211010106',
          '9310211010107',
          '9310211010108',
          '9310211010109',
          '9310211010110',
          '9310211010111',
          '9310211010112',
          '9310211010113',
          '9310211010114',
          '9310211010115',
          '9310211010116',
          '9310211010117',
          '9310211010118',
          '9310211010119',
          '9310211010120',
          '9310211010121',
          '9310211010122',
          '9310211010123',
          '9310211010124',
          '9310211010125',
          '9310211010126',
          '9310211010127',
          '9310211010128',
          '9310211010129',
          '9310211010130',
          '9310211010131',
          '9310211010132',
          '9310211010133',
          '9310211010134',
          '9310211010135',
          '9310211010136',
          '9310211010137',
          '9310211010138',
          '9310211010139',
          '9310211010140',
          '9310211010141',
          '9310211010142',
          '9310211010143',
          '9310211010144',
          '9310211010145',
          '9310211010146',
          '9310211010147',
          '9310211010148',
          '9310211010149',
          '9310211010150',
          '9310211010151',
          '9310211010152',
          '9310211010153',
          '9310211010154',
          '9310211010201',
          '9310211010202',
          '9310211010203',
          '9310211010204',
          '9310211010205',
          '9310211010206',
          '9310211010207',
          '9310211010208',
          '9310211010209',
          '9310211010210',
          '9310211010211',
          '9310211010212',
          '9310211010213',
          '9310211010214',
          '9310211010215',
          '9310211010216',
          '9310211010217',
          '9310211010218',
          '9310211010219',
          '9310211010220',
          '9310211010221',
          '9310211010222',
          '9310211010223',
          '9310211010224',
          '9310211010301',
          '9310211010302',
          '9310211010303',
          '9310211010304',
          '9310211010305',
          '9310211010306',
          '9310211010307',
          '9310211010308',
          '9310211010309',
          '9310211010310',
          '9310211010311',
          '9310211010312',
          '9310211010313',
          '9310211010314',
          '9310211010315',
          '9310211010316',
          '9310211010317',
          '9310211010318',
          '9310211010319',
          '9310211010320',
          '9310211010321',
          '9310211010322',
          '9310211010323',
          '9310211010324',
          '9310211010325',
          '9310211010326',
          '9310211010327',
          '9310211010328',
          '9310211010329',
          '9310211010330',
          '9310211010331',
          '9310211010332',
          '9310211010333',
          '9310211010334',
          '9310211010335',
          '9310211010336',
          '9310211010337',
          '9310211010401',
          '9310211010402',
          '9310211010403',
          '9310211010404',
          '9310211010405',
          '9310211010406',
          '9310211010407',
          '9310211010408',
          '9310211010409',
          '9310211010410',
          '9310211010411',
          '9310211010412',
          '9310211010413',
          '9310211010414',
          '9310211010415',
          '9310211010416',
          '9310211010417',
          '9310211010418',
          '9310211010419',
          '9310211010420',
          '9310211010421',
          '9310211010422',
          '9310211010423',
          '9310211010424',
          '9310211010425',
          '9310211010426',
          '9310211010427',
          '9310211010428',
          '9310211010429',
          '9310211010430',
          '9310211010431',
          '9310211010432',
          '9310211010433',
          '9310211010434',
          '9310211010435',
          '9310211010436',
          '9310211010437',
          '9310211010438',
          '9310211010439',
          '9310211010440',
          '9310211010441',
          '9310211010442',
          '9310211010443',
          '9310211010444',
          '9310211010445',
          '9310211010446',
          '9310211010447',
          '9310211010448',
          '9310211010449',
          '9310211010450',
          '9310211010451',
          '9310211010452',
          '9310211010453',
          '9310211010454',
          '9310211010455',
          '9310211010501',
          '9310211010502',
          '9310211010503',
          '9310211010504',
          '9310211010505',
          '9310211010506',
          '9310211010507',
          '9310211010508',
          '9310211010509',
          '9310211010510',
          '9310211010511',
          '9310211010512',
          '9310211010513',
          '9310211010514',
          '9310211010601',
          '9310211010602',
          '9310211010603',
          '9310211010604',
          '9310211010605',
          '9310211010606',
          '9310211010607',
          '9310211010608',
          '9310211010609',
          '9310211010610',
          '9310211010611',
          '9310211010612',
          '9310211010613',
          '9310211010614',
          '9310211010615',
          '9310211010616',
          '9310211010617',
          '9310211010618',
          '9310211010619',
          '9310211010620',
          '9310211010621',
          '9310211010622',
          '9310211010623',
          '9310211010624',
          '9310211010625',
          '9310211010626',
          '9310211010627',
          '9310211010628',
          '9310211010701',
          '9310211010702',
          '9310211010703',
          '9310211010704',
          '9310211010705',
          '9310211010706',
          '9310211010707',
          '9310211010708',
          '9310211010709',
          '9310211020101',
          '9310211020102',
          '9310211020103',
          '9310211020104',
          '9310211020105',
          '9310211020106',
          '9310211020107',
          '9310211020108',
          '9310211020109',
          '9310211020110',
          '9310211020111',
          '9310211020112',
          '9310211020113',
          '9310211020114',
          '9310211020115',
          '9310211020116',
          '9310211020117',
          '9310211020118',
          '9310211020119',
          '9310211020120',
          '9310211020121',
          '9310211020122',
          '9310211020123',
          '9310211020124',
          '9310211020125',
          '9310211020126',
          '9310211020127',
          '9310211020201',
          '9310211020202',
          '9310211020203',
          '9310211020204',
          '9310211020205',
          '9310211020206',
          '9310211020207',
          '9310211020208',
          '9310211020209',
          '9310211020210',
          '9310211020211',
          '9310211020212',
          '9310211020213',
          '9310211020214',
          '9310211020215',
          '9310211020216',
          '9310211020217',
          '9310211020218',
          '9310211020219',
          '9310211020220',
          '9310211020221',
          '9310211020222',
          '9310211020223',
          '9310211020224',
          '9310211020225',
          '9310211020301',
          '9310211020302',
          '9310211020303',
          '9310211020304',
          '9310211020305',
          '9310211020306',
          '9310211020307',
          '9310211020308',
          '9310211020309',
          '9310211020310',
          '9310211020311',
          '9310211020312',
          '9310211020313',
          '9310211020314',
          '9310211020315',
          '9310211020316',
          '9310211020401',
          '9310211020402',
          '9310211020403',
          '9310211020404',
          '9310211020405',
          '9310211020406',
          '9310211020407',
          '9310211020408',
          '9310211020409',
          '9310211020410',
          '9310211020411',
          '9310211020412',
          '9310211020413',
          '9310211020414',
          '9310211020415',
          '9310211020416',
          '9310211020417',
          '9310211020501',
          '9310211020502',
          '9310211020503',
          '9310211020504',
          '9310211020505',
          '9310211020506',
          '9310211020507',
          '9310211020508',
          '9310211020509',
          '9310211020510',
          '9310211020511',
          '9310211020512',
          '9310211020513',
          '9310211020514',
          '9310211020515',
          '9310211020516',
          '9310211020517',
          '9310211020518',
          '9310211020519',
          '9310211020520',
          '9310211020521',
          '9310211020522',
          '9310211020523',
          '9310211020524',
          '9310211020525',
          '9310211020526',
          '9310211020601',
          '9310211020602',
          '9310211020603',
          '9310211020604',
          '9310211030101',
          '9310211030102',
          '9310211030103',
          '9310211030104',
          '9310211030105',
          '9310211030106',
          '9310211030107',
          '9310211030108',
          '9310211030109',
          '9310211030110',
          '9310211030111',
          '9310211030112',
          '9310211030113',
          '9310211030114',
          '9310211030115',
          '9310211030116',
          '9310211030117',
          '9310211030118',
          '9310211030119',
          '9310211030120',
          '9310211030121',
          '9310211030122',
          '9310211030123',
          '9310211030124',
          '9310211030125',
          '9310211030126',
          '9310211030127',
          '9310211030128',
          '9310211030129',
          '9310211030201',
          '9310211030202',
          '9310211030203',
          '9310211030204',
          '9310211030205',
          '9310211030206',
          '9310211030207',
          '9310211030208',
          '9310211030209',
          '9310211030210',
          '9310211030211',
          '9310211030212',
          '9310211030213',
          '9310211030214',
          '9310211030215',
          '9310211030216',
          '9310211030217',
          '9310211030218',
          '9310211030219',
          '9310211030220',
          '9310211030221',
          '9310211030222',
          '9310211030223',
          '9310211030224',
          '9310211030225',
          '9310211030226',
          '9310211030227',
          '9310211030228',
          '9310211030229',
          '9310211030230',
          '9310211030231',
          '9310211030232',
          '9310211030233',
          '9310211030234',
          '9310211030301',
          '9310211030302',
          '9310211030303',
          '9310211030304',
          '9310211030305',
          '9310211030306',
          '9310211030307',
          '9310211030308',
          '9310211030309',
          '9310211030310',
          '9310211030311',
          '9310211030312',
          '9310211030313',
          '9310211030314',
          '9310211030315',
          '9310211030316',
          '9310211030317',
          '9310211030318',
          '9310211030319',
          '9310211030320',
          '9310211030321',
          '9310211030322',
          '9310211030323',
          '9310211030324',
          '9310211030325',
          '9310211030326',
          '9310211030327',
          '9310211030328',
          '9310211030329',
          '9310211030330',
          '9310211030331',
          '9310211030332',
          '9310211030333',
          '9310211030334',
          '9310211030335',
          '9310211030336',
          '9310211030337',
          '9310211030338',
          '9310211030339',
          '9310211030340',
          '9310211030341',
          '9310211030342',
          '9310211030343',
          '9310211030344',
          '9310211030345',
          '9310211030346',
          '9310211030347',
          '9310211030348',
          '9310211030349',
          '9310211030350',
          '9310211030351',
          '9310211030352',
          '9310211030353',
          '9310211030354',
          '9310211030355',
          '9310211030356',
          '9310211030357',
          '9310211030358',
          '9310211030359',
          '9310211030360',
          '9310211030361',
          '9310211030362',
          '9310211030363',
          '9310211030364',
          '9310211030401',
          '9310211030402',
          '9310211030403',
          '9310211030404',
          '9310211030405',
          '9310211030406',
          '9310211030407',
          '9310211030408',
          '9310211030409',
          '9310211030410',
          '9310211030411',
          '9310211030412',
          '9310211030413',
          '9310211030414',
          '9310211030415',
          '9310211030416',
          '9310211030417',
          '9310211030418',
          '9310211030419',
          '9310211030420',
          '9310211030421',
          '9310211030422',
          '9310211030423',
          '9310211030424',
          '9310211030501',
          '9310211030502',
          '9310211030503',
          '9310211030504',
          '9310211030505',
          '9310211030506',
          '9310211030507',
          '9310211030508',
          '9310211030509',
          '9310211030510',
          '9310211030511',
          '9310211030512',
          '9310211030513',
          '9310211030514',
          '9310211030515',
          '9310211030516',
          '9310211030517',
          '9310211030518',
          '9310211030519',
          '9310211030520',
          '9310211030521',
          '9310211030522',
          '9310211030523',
          '9310211030524',
          '9310211030525',
          '9310211030526',
          '9310211030527',
          '9310211030528',
          '9310211030529',
          '9310211030530',
          '9310211030531',
          '9310211030532',
          '9310211030533',
          '9310211030601',
          '9310211030602',
          '9310211030603',
          '9310211030604',
          '9310211030605',
          '9310211030606',
          '9310211030607',
          '9310211030608',
          '9310211030609',
          '9310211030610',
          '9310211030611',
          '9310211030612',
          '9310211030613',
          '9310211030614',
          '9310211030615',
          '9310211030616',
          '9310211030617',
          '9310211030701',
          '9310211030702',
          '9310211030703',
          '9310211030704',
          '9310211030705',
          '9310211030706',
          '9310211030707',
          '9310211030708',
          '9310211030709',
          '9310211030710',
          '9310211030711',
          '9310211030712',
          '9310211030713',
          '9310211030714',
          '9310211030715',
          '9310211030716',
          '9310211030717',
          '9310211030801',
          '9310211030802',
          '9310211030803',
          '9310211030804',
          '9310211030805',
          '9310211030806',
          '9310211030807',
          '9310211030808',
          '9310211030809',
          '9310211030810',
          '9310211030811',
          '9310211030812',
          '9310211030813',
          '9310211030814',
          '9310211030815',
          '9310211030816',
          '9310211030817',
          '9310211030901',
          '9310211030902',
          '9310211030903',
          '9310211030904',
          '9310211030905',
          '9310211030906',
          '9310211030907',
          '9310211040101',
          '9310211040102',
          '9310211040103',
          '9310211040104',
          '9310211040105',
          '9310211040106',
          '9310211040107',
          '9310211040108',
          '9310211040109',
          '9310211040110',
          '9310211040111',
          '9310211040201',
          '9310211040202',
          '9310211040301',
          '9310211040302',
          '9310211040303',
          '9310211040304',
          '9310211040305',
          '9310211040306',
          '9310211050101',
          '9310211050102',
          '9310211050103',
          '9310211050104',
          '9310211050105',
          '9310211050106',
          '9310211050201',
          '9310211050202',
          '9310211050301',
          '9310211050302',
          '9310211050303',
          '9310211050304',
          '9310211050401',
          '9310211050402',
          '9310211050403',
          '9310211050404',
          '9310211050501',
          '9310211050502',
          '9310211050503',
          '9310211060101',
          '9310211060102',
          '9310211060103',
          '9310211990000',
          '9310212010101',
          '9310212010102',
          '9310212010201',
          '9310212010202',
          '9310212010203',
          '9310212010204',
          '9310212010205',
          '9310212010206',
          '9310212010301',
          '9310212010302',
          '9310212010303',
          '9310212010304',
          '9310212010305',
          '9310212010306',
          '9310212010401',
          '9310212010402',
          '9310212010403',
          '9310212010404',
          '9310212010405',
          '9310212010406',
          '9310212010407',
          '9310212010501',
          '9310212010502',
          '9310212010503',
          '9310212010504',
          '9310212010505',
          '9310212010506',
          '9310212010507',
          '9310212010508',
          '9310212010509',
          '9310212010510',
          '9310212010511',
          '9310212010512',
          '9310212010513',
          '9310212010514',
          '9310212010515',
          '9310212010516',
          '9310212010517',
          '9310212010601',
          '9310212010602',
          '9310212010603',
          '9310212010604',
          '9310212010605',
          '9310212010606',
          '9310212010607',
          '9310212010608',
          '9310212010609',
          '9310212010610',
          '9310212010611',
          '9310212010612',
          '9310212010701',
          '9310212010702',
          '9310212010703',
          '9310212010704',
          '9310212010705',
          '9310212010801',
          '9310212010802',
          '9310212010803',
          '9310212010804',
          '9310212010805',
          '9310212010901',
          '9310212010902',
          '9310212010903',
          '9310212010904',
          '9310212010905',
          '9310212010906',
          '9310212010907',
          '9310212011001',
          '9310212011002',
          '9310212011003',
          '9310212011004',
          '9310212011005',
          '9310212011006',
          '9310212011007',
          '9310212020101',
          '9310212020102',
          '9310212020103',
          '9310212020104',
          '9310212020105',
          '9310212020106',
          '9310212020107',
          '9310212020108',
          '9310212020201',
          '9310212020301',
          '9310212020401',
          '9310212020402',
          '9310212020403',
          '9310212020404',
          '9310212020405',
          '9310212020501',
          '9310212020502',
          '9310212020503',
          '9310212020504',
          '9310212020505',
          '9310212020506',
          '9310212020507',
          '9310212020508',
          '9310212030101',
          '9310212030102',
          '9310212030103',
          '9310212030104',
          '9310212030105',
          '9310212030106',
          '9310212030107',
          '9310212030108',
          '9310212030109',
          '9310212030201',
          '9310212030301',
          '9310212030401',
          '9310212030402',
          '9310212030403',
          '9310212030404',
          '9310212030501',
          '9310212030502',
          '9310212030503',
          '9310212030504',
          '9310212030505',
          '9310212030506',
          '9310212030507',
          '9310212040101',
          '9310212040102',
          '9310212040103',
          '9310212040104',
          '9310212040201',
          '9310212050101',
          '9310212050102',
          '9310212050103',
          '9310212050104',
          '9310212050201',
          '9310212050202',
          '9310212050203',
          '9310212050204',
          '9310212050301',
          '9310212050302',
          '9310212050303',
          '9310212050401',
          '9310212050402',
          '9310212050403',
          '9310212050404',
          '9310212050405',
          '9310212060101',
          '9310212070101',
          '9310212070102',
          '9310212070103',
          '9310212070105',
          '9310212070106',
          '9310212070107',
          '9310212070108',
          '9310212070109',
          '9310212070110',
          '9310212070111',
          '9310212070112',
          '9310212070113',
          '9310212070114',
          '9310212070115',
          '9310212070116',
          '9310212070117',
          '9310212070118',
          '9310212070119',
          '9310212070201',
          '9310212070202',
          '9310212070203',
          '9310212070204',
          '9310212070205',
          '9310212070206',
          '9310212070207',
          '9310212070208',
          '9310212070209',
          '9310212070210',
          '9310212070211',
          '9310212070212',
          '9310212070301',
          '9310212070302',
          '9310212070303',
          '9310212070304',
          '9310212070305',
          '9310212070306',
          '9310212070307',
          '9310212070308',
          '9310212070309',
          '9310212070310',
          '9310212070311',
          '9310212070312',
          '9310212070313',
          '9310212070314',
          '9310212070315',
          '9310212070316',
          '9310212070317',
          '9310212070318',
          '9310212070319',
          '9310212070320',
          '9310212070321',
          '9310212070322',
          '9310212070323',
          '9310212070324',
          '9310212070325',
          '9310212070326',
          '9310212070327',
          '9310212070328',
          '9310212070329',
          '9310212070330',
          '9310212070331',
          '9310212070332',
          '9310212070401',
          '9310212070402',
          '9310212070403',
          '9310212070404',
          '9310212070405',
          '9310212070406',
          '9310212070407',
          '9310212070408',
          '9310212070409',
          '9310212070410',
          '9310212070411',
          '9310212990000',
          '9319200000000',
          '9319800000000',
          '9319901000000',
          '9319999000000',
          '9321000000000',
          '9330102000000',
          '9330302000000',
          '8351000000000',
          '8352000000000',
          '8359000000000'
        ],
        text: 'Servicios de salud privados'
      }
    ],
    8: [],
    13: [
      {
        id: 'CASILLA_022400_MON',
        cabys: [
          '0115100000000',
          '0115200000000',
          '0116100000000',
          '0116200000000',
          '0117100000000',
          '0118100000000',
          '0118200000000',
          '0119100000100',
          '0119100009900',
          '0119200000100',
          '0119200009900',
          '0119300000100',
          '0119300009900',
          '0119400000100',
          '0119400009900',
          '0119500000100',
          '0119500009900',
          '0119900000100',
          '0119900009900',
          '0121100000000',
          '0121201010000',
          '0121201020100',
          '0121201020200',
          '0121201020300',
          '0121201020400',
          '0121201020500',
          '0121202019900',
          '0121202020200',
          '0121202029900',
          '0121301010100',
          '0121301019900',
          '0121301020100',
          '0121301029900',
          '0121302010000',
          '0121302020000',
          '0121401010100',
          '0121401019900',
          '0121401020100',
          '0121401029900',
          '0121402019900',
          '0121402020100',
          '0121402020200',
          '0121402020300',
          '0121402020400',
          '0121402020500',
          '0121402020600',
          '0121402020700',
          '0121402020800',
          '0121402020900',
          '0121402021000',
          '0121402029900',
          '0121500010000',
          '0121500020000',
          '0121600010000',
          '0121600020000',
          '0121901010000',
          '0121901020100',
          '0121901020200',
          '0121999010100',
          '0121999010200',
          '0121999010300',
          '0121999010400',
          '0121999010700',
          '0121999010800',
          '0121999010900',
          '0121999011000',
          '0121999019900',
          '0121999020100',
          '0121999020200',
          '0121999020300',
          '0121999020400',
          '0121999020700',
          '0121999020800',
          '0121999020900',
          '0121999021000',
          '0121999029900',
          '0123100019900',
          '0123100020100',
          '0123100020200',
          '0123100020300',
          '0123100020400',
          '0123100020500',
          '0123100020700',
          '0123100020900',
          '0123100021000',
          '0123100021100',
          '0123100029900',
          '0123200000200',
          '0123300000000',
          '0123503000000',
          '0123504000000',
          '0123506000100',
          '0123506009900',
          '0123507000100',
          '0123507009900',
          '0123901000000',
          '0123902000600',
          '0123903000000',
          '0123904000000',
          '0123999000000',
          '0124102010000',
          '0124102020000',
          '0124200000000',
          '0124300000000',
          '0124900009900',
          '0125102010100',
          '0125102019900',
          '0125102020100',
          '0125102029900',
          '0125402010000',
          '0125402020000',
          '0125403010000',
          '0125403020000',
          '0125499010000',
          '0125499020000',
          '0125901000000',
          '0125903000200',
          '0125999000000',
          '0126000000100',
          '0126000000200',
          '0126000000300',
          '0126000000400',
          '0126000000500',
          '0126000000600',
          '0126000000700',
          '0126000000800',
          '0126000000900',
          '0126000001000',
          '0126000001100',
          '0126000001200',
          '0126000001300',
          '0126000001400',
          '0126000001500',
          '0126000001600',
          '0126000001700',
          '0126000001800',
          '0126000001900',
          '0126000002000',
          '0126000002100',
          '0126000002200',
          '0126000009900',
          '0127001000100',
          '0127001000200',
          '0127001000300',
          '0127001000400',
          '0127001000500',
          '0127001000600',
          '0127001000700',
          '0127001009900',
          '0127002000000',
          '0129001010000',
          '0129001020000',
          '0129002010000',
          '0129002020000',
          '0129003010000',
          '0129003020000',
          '0129005010000',
          '0129005020000',
          '0129006010000',
          '0129006020000',
          '0129007010000',
          '0129007020000',
          '0129010010000',
          '0129010020000',
          '0129011000000',
          '0129012010000',
          '0129012020000',
          '0129013000000',
          '0129014010000',
          '0129014020000',
          '0129018010000',
          '0129018020000',
          '0129020010000',
          '0129020020000',
          '0129099010000',
          '0129099020000',
          '0131301000200',
          '0131400000000',
          '0131500000000',
          '0131602010000',
          '0131602020100',
          '0131602020200',
          '0131602020300',
          '0131602029900',
          '0131603010000',
          '0131603020000',
          '0131901000000',
          '0131902000000',
          '0131903000000',
          '0131904000000',
          '0131999000000',
          '0132100000000',
          '0132200000100',
          '0132300010100',
          '0132300019900',
          '0132300020100',
          '0132300020200',
          '0132300020300',
          '0132300020400',
          '0132300020500',
          '0132300020600',
          '0132300020700',
          '0132300020800',
          '0132300020900',
          '0132300021000',
          '0132300021100',
          '0132300021200',
          '0132300021300',
          '0132300021400',
          '0132300021500',
          '0132300021600',
          '0132300021700',
          '0132300021800',
          '0132300021900',
          '0132300022000',
          '0132300022100',
          '0132300029900',
          '0132401000000',
          '0132402000000',
          '0132403000100',
          '0132403000200',
          '0132403000300',
          '0132403000400',
          '0132403000500',
          '0132403000600',
          '0132403000700',
          '0132403000800',
          '0132403000900',
          '0132403001000',
          '0132403001100',
          '0132403001200',
          '0132403009900',
          '0132404000000',
          '0132901000000',
          '0132999000000',
          '0133000000100',
          '0133000000200',
          '0133000000300',
          '0133000000400',
          '0133000000500',
          '0133000000600',
          '0133000000700',
          '0133000000800',
          '0133000000900',
          '0133000001000',
          '0133000001100',
          '0133000009900',
          '0134201000100',
          '0134201000200',
          '0134201000300',
          '0134201000400',
          '0134201000500',
          '0134201000600',
          '0134201000700',
          '0134201000800',
          '0134201000900',
          '0134201001000',
          '0134201001100',
          '0134201001200',
          '0134201001300',
          '0134201001400',
          '0134201001500',
          '0134201001600',
          '0134201001700',
          '0134201001800',
          '0134201001900',
          '0134201009900',
          '0134202000000',
          '0134300000000',
          '0134400000100',
          '0134400009900',
          '0134501000100',
          '0134501000200',
          '0134502000000',
          '0134600000100',
          '0134600000200',
          '0134600000300',
          '0134600000400',
          '0134600009900',
          '0134900000000',
          '0135100000000',
          '0135200000000',
          '0135301010000',
          '0135301020000',
          '0135302010000',
          '0135302020000',
          '0135303010000',
          '0135303020000',
          '0135400010000',
          '0135400020000',
          '0135501000100',
          '0135501000200',
          '0135501000300',
          '0135501000400',
          '0135501000500',
          '0135501009900',
          '0135502000000',
          '0135503000000',
          '0135504000000',
          '0135599000000',
          '0135600000100',
          '0135600000200',
          '0135901000000',
          '0135902000000',
          '0135903000000',
          '0135904000000',
          '0135905000000',
          '0135906000000',
          '0135908000000',
          '0135910000000',
          '0135911000000',
          '0135912000100',
          '0135912000200',
          '0135913000000',
          '0135915000000',
          '0135916000000',
          '0135917000100',
          '0135917000200',
          '0135921000000',
          '0135923000000',
          '0135926000000',
          '0135928000000',
          '0135930000000',
          '0135931000100',
          '0135931000200',
          '0135931000300',
          '0135931009900',
          '0135932000000',
          '0135933000000',
          '0135934000000',
          '0135935000000',
          '0135936000000',
          '0135937000000',
          '0135998000100',
          '0135998000200',
          '0135998009900',
          '0135999000000',
          '0136000000100',
          '0136000000200',
          '0136000000300',
          '0136000000400',
          '0136000000500',
          '0136000000600',
          '0136000000700',
          '0136000000800',
          '0136000009900',
          '0137100010000',
          '0137100020000',
          '0137200010000',
          '0137200020000',
          '0137300010000',
          '0137300020000',
          '0137400010000',
          '0137400020000',
          '0137500010000',
          '0137500020000',
          '0137600010000',
          '0137600020000',
          '0137700010000',
          '0137700020000',
          '0137901010000',
          '0137901020000',
          '0137901030000',
          '0137902000000',
          '0137903000000',
          '0137999000000',
          '0142100000000',
          '0142299000000',
          '0143100000000',
          '0143200000000',
          '0144100000100',
          '0144200000100',
          '0144200009900',
          '0144300000100',
          '0144300009900',
          '0144400010100',
          '0144400019900',
          '0144500000100',
          '0144500009900',
          '0144600000100',
          '0144600009900',
          '0144700000100',
          '0144700009900',
          '0144800000100',
          '0144800009900',
          '0144901000100',
          '0144900000100',
          '0144900009900',
          '0145000000000',
          '0149199000100',
          '0149199009900',
          '0149999000000',
          '0151001000000',
          '0152000030000',
          '0154002010000',
          '0154002020000',
          '0155002010000',
          '0155002020000',
          '0155099010000',
          '0155099020000',
          '0159901010000',
          '0159901020000',
          '0159902010000',
          '0159902020000',
          '0159903010000',
          '0159903020000',
          '0159904010000',
          '0159904020000',
          '0162000000100',
          '0162000000200',
          '0162000009900',
          '0163000000000',
          '0165100009900',
          '0165200000100',
          '0165200000200',
          '0165200000300',
          '0165200000400',
          '0165200000500',
          '0165200000600',
          '0165200000700',
          '0165200000800',
          '0165200000900',
          '0165200001000',
          '0165200001100',
          '0165200001200',
          '0165200009900',
          '0165301000100',
          '0165301009900',
          '0165302000000',
          '0165303000100',
          '0165303009900',
          '0165304000100',
          '0165304009900',
          '0165401000100',
          '0165401009900',
          '0165402000100',
          '0165403000100',
          '0165403009900',
          '0165404000100',
          '0165404009900',
          '0165405000100',
          '0165405009900',
          '0165500000200',
          '0165800000000',
          '0165900000100',
          '0165900000200',
          '0169100000000',
          '0169901010000',
          '0169901020000',
          '0169903010000',
          '0169903020000',
          '0169904010000',
          '0169904020000',
          '0169905010000',
          '0169905020000',
          '0169906010000',
          '0169906020000',
          '0169907010000',
          '0169907020000',
          '0169999010000',
          '0169999990000',
          '0170101000000',
          '0170102009900',
          '0170200000000',
          '0170501000100',
          '0170501000200',
          '0170501000300',
          '0170502000100',
          '0170502000200',
          '0170502000300',
          '0170600000000',
          '0170700000000',
          '0170800000000',
          '0170900000000',
          '0180100000000',
          '0180201010000',
          '0180201020000',
          '0180202010000',
          '0180202020000',
          '0180300000000',
          '0180900000000',
          '0191200000000',
          '0191300000100',
          '0191300000200',
          '0191900000100',
          '0191900009900',
          '0192100000000',
          '0192201000000',
          '0192202000000',
          '0192299000000',
          '0192901000000',
          '0192902000000',
          '0192903000000',
          '0192904000000',
          '0192905000000',
          '0192999000000',
          '0193001010000',
          '0193001020000',
          '0193001030000',
          '0193002010000',
          '0193002020000',
          '0193002030000',
          '0193003010000',
          '0193003020000',
          '0193003030000',
          '0193004010000',
          '0193004020000',
          '0193004030000',
          '0193005010000',
          '0193005020000',
          '0193005030000',
          '0193006010000',
          '0193006020000',
          '0193006030000',
          '0193007010000',
          '0193007020000',
          '0193007030000',
          '0193007040000',
          '0193008010000',
          '0193008020000',
          '0193008030000',
          '0193009010000',
          '0193009020000',
          '0193009030000',
          '0193010020000',
          '0193010030000',
          '0193011010000',
          '0193011020000',
          '0193012010000',
          '0193012020000',
          '0193013020000',
          '0193014010000',
          '0193014020000',
          '0193015010000',
          '0193015020000',
          '0193016010000',
          '0193016020000',
          '0193017010000',
          '0193017020000',
          '0193018010000',
          '0193018020000',
          '0193019010000',
          '0193019020000',
          '0193020020000',
          '0193021010000',
          '0193021020000',
          '0193022010000',
          '0193022020000',
          '0193023010000',
          '0193023020000',
          '0193024010000',
          '0193024020000',
          '0193099010000',
          '0193099020000',
          '0194001000000',
          '0194002000000',
          '0194003000000',
          '0194004000000',
          '0194005000000',
          '0194006000000',
          '0194007000000',
          '0194099000000',
          '0195000010100',
          '0195000010200',
          '0195000010300',
          '0195000019900',
          '0195000020100',
          '0195000020200',
          '0195000029900',
          '0196101010100',
          '0196101010200',
          '0196101010300',
          '0196101010400',
          '0196101010500',
          '0196101010600',
          '0196101010700',
          '0196101019900',
          '0196101020100',
          '0196101020200',
          '0196101020300',
          '0196101020400',
          '0196101020500',
          '0196101020600',
          '0196101020700',
          '0196101029900',
          '0196102010000',
          '0196102020000',
          '0196103000100',
          '0196103009900',
          '0196104000100',
          '0196104000200',
          '0196104009900',
          '0196105000100',
          '0196105000200',
          '0196105000300',
          '0196105000400',
          '0196105000500',
          '0196105000600',
          '0196105000700',
          '0196105009900',
          '0196106000000',
          '0196107000000',
          '0196108000000',
          '0196109000000',
          '0196110000000',
          '0196111000000',
          '0196112000000',
          '0196113000100',
          '0196113000200',
          '0196113000300',
          '0196113000400',
          '0196113000500',
          '0196113000600',
          '0196113000700',
          '0196113000800',
          '0196113000900',
          '0196113001000',
          '0196113001100',
          '0196113009900',
          '0196114000100',
          '0196114000200',
          '0196114000300',
          '0196114000400',
          '0196114000500',
          '0196114009900',
          '0196199000000',
          '0196201000100',
          '0196201000200',
          '0196201000300',
          '0196201000400',
          '0196201000500',
          '0196201000600',
          '0196201000700',
          '0196201000800',
          '0196201000900',
          '0196201001000',
          '0196201001100',
          '0196201001200',
          '0196201001300',
          '0196201001400',
          '0196201001500',
          '0196201001600',
          '0196201001700',
          '0196201001800',
          '0196201009900',
          '0196202000000',
          '0196299000000',
          '0196300000100',
          '0196300009900',
          '0197000000100',
          '0197000000200',
          '0197000000300',
          '0197000009900',
          '0199001000100',
          '0199001000200',
          '0199099000100',
          '0199099000200',
          '0199099009900',
          '0211100990100',
          '0211100990200',
          '0211100999900',
          '0211200990100',
          '0211200999900',
          '0211900990100',
          '0211900999900',
          '0212100000000',
          '0212200990100',
          '0212200999900',
          '0212300990100',
          '0212300999900',
          '0212900000000',
          '0213100990100',
          '0213100999900',
          '0213200000000',
          '0213300000000',
          '0214000990100',
          '0214000999900',
          '0215100990000',
          '0215200000000',
          '0215300000000',
          '0215400000000',
          '0215500000000',
          '0219100000000',
          '0219200000100',
          '0219200000200',
          '0219200000300',
          '0219200000400',
          '0219200000500',
          '0219200000600',
          '0219200000700',
          '0219200000800',
          '0219200000900',
          '0219200001000',
          '0219200001100',
          '0219200001200',
          '0219200001300',
          '0219200001400',
          '0219200001500',
          '0219200001600',
          '0219200001700',
          '0219200001800',
          '0219200001900',
          '0219200002000',
          '0219200002100',
          '0219200002200',
          '0219200002300',
          '0219200009900',
          '0219300000000',
          '0219400000100',
          '0219400000200',
          '0219400000300',
          '0219400000400',
          '0219400000500',
          '0219400009900',
          '0219500000100',
          '0219500000200',
          '0219500000300',
          '0219500000400',
          '0219500000500',
          '0219500009900',
          '0219600000000',
          '0219901000100',
          '0219901000200',
          '0219901000300',
          '0219901009900',
          '0219902000100',
          '0219902000200',
          '0219902009900',
          '0219999000100',
          '0219999009900',
          '0221200000000',
          '0229100000000',
          '0229900000000',
          '0231100000000',
          '0232100000000',
          '0232200000000',
          '0241100000000',
          '0241900000000',
          '0242000000100',
          '0242000000200',
          '0291000009900',
          '0292000000000',
          '0293000000000',
          '0294100000000',
          '0294201000000',
          '0294202000000',
          '0294301000000',
          '0294399000000',
          '0294400000000',
          '0295100000000',
          '0295200000000',
          '0295300000000',
          '0295400000000',
          '0295501000000',
          '0295502000000',
          '0295503000000',
          '0295598000000',
          '0295599000000',
          '0295901000000',
          '0295902000000',
          '0295999000000',
          '0296000000000',
          '0311000000000',
          '0312001000100',
          '0312001009900',
          '0312099000100',
          '0312099000200',
          '0312099000300',
          '0312099009900',
          '0313100000000',
          '0313200000000',
          '0321101010000',
          '0321101990000',
          '0321102010000',
          '0321102990000',
          '0321103010000',
          '0321103990000',
          '0321199010000',
          '0321199990000',
          '0321901000000',
          '0321902000000',
          '0321999000000',
          '0322000000000',
          '0323000000000',
          '0324100000000',
          '0324901010000',
          '0324901020000',
          '0324902010000',
          '0324902020000',
          '0324999010000',
          '0324999020000',
          '0325001000000',
          '0325002000000',
          '0325003000000',
          '0325004000000',
          '0325005000000',
          '0325006010000',
          '0325006990000',
          '0325007000000',
          '0325099010000',
          '0325099020000',
          '0325099030000',
          '0325099040000',
          '0325099990000',
          '0411101000000',
          '0411102000000',
          '0411201000000',
          '0411202000000',
          '0419101010000',
          '0419101990000',
          '0419102010000',
          '0419102990000',
          '0419103010000',
          '0419103990000',
          '0419199000000',
          '0419200000000',
          '0421101010000',
          '0421102010000',
          '0421103010000',
          '0421104010000',
          '0421199010000',
          '0421201010000',
          '0421202010000',
          '0421203010000',
          '0421204010000',
          '0421299010000',
          '0422101010000',
          '0422102010000',
          '0422199010000',
          '0422201010000',
          '0422202010000',
          '0422299010000',
          '0423101010000',
          '0423102010000',
          '0423103010000',
          '0423104010000',
          '0423199010000',
          '0423201010000',
          '0423202010000',
          '0423203010000',
          '0423204010000',
          '0423299010000',
          '0424101010000',
          '0424102010000',
          '0424103010000',
          '0424104010000',
          '0424105010000',
          '0424106010000',
          '0424199010000',
          '0424201010000',
          '0424202010000',
          '0424203010000',
          '0424204010000',
          '0424205010000',
          '0424206010000',
          '0424299010000',
          '0425101010000',
          '0425102010000',
          '0425103010000',
          '0425104010000',
          '0425105010000',
          '0425199010000',
          '0425201010000',
          '0425202010000',
          '0425203010000',
          '0425204010000',
          '0425205010000',
          '0425299010000',
          '0426101010000',
          '0426102010000',
          '0426103010000',
          '0426104010000',
          '0426105010000',
          '0426106010000',
          '0426107010000',
          '0426108010000',
          '0426199010000',
          '0426201010000',
          '0426202010000',
          '0426203010000',
          '0426204010000',
          '0426205010000',
          '0426206010000',
          '0426207010000',
          '0426208010000',
          '0426299010000',
          '0429101010000',
          '0429102010100',
          '0429102019900',
          '0429103010000',
          '0429104010000',
          '0429105010000',
          '0429106010000',
          '0429107010000',
          '0429108010000',
          '0429109010000',
          '0429109020000',
          '0429110010000',
          '0429199010000',
          '0429201010000',
          '0429202010100',
          '0429202019900',
          '0429203010000',
          '0429204010000',
          '0429205010000',
          '0429206010000',
          '0429207010000',
          '0429208010000',
          '0429209010000',
          '0429209020000',
          '0429210010000',
          '0429299010000',
          '0431100010000',
          '0431100020000',
          '0431200010000',
          '0431200020000',
          '0432100010000',
          '0432100020000',
          '0432200010000',
          '0432200020000',
          '0433100010000',
          '0433100020000',
          '0433200010000',
          '0433200020000',
          '0434100010000',
          '0434100020000',
          '0434200010000',
          '0434200020000',
          '0435101010000',
          '0435101020000',
          '0435199010000',
          '0435199020000',
          '0435201010000',
          '0435201020000',
          '0435299010000',
          '0435299020000',
          '0436100010000',
          '0436100020000',
          '0436200010000',
          '0436200020000',
          '0439100010000',
          '0439100020000',
          '0439200010000',
          '0439200020000',
          '0441100010000',
          '0441100020000',
          '0441200010000',
          '0441200020000',
          '0442100010000',
          '0442100020000',
          '0442200010000',
          '0442200020000',
          '0443100010000',
          '0443100020000',
          '0443200010000',
          '0443200020000',
          '0444100010000',
          '0444100020000',
          '0444200010000',
          '0444200020000',
          '0445101010000',
          '0445101020000',
          '0445102010000',
          '0445102020000',
          '0445201010000',
          '0445201020000',
          '0445202010000',
          '0445202020000',
          '0446101010000',
          '0446101020000',
          '0446199010000',
          '0446199020000',
          '0446201010000',
          '0446201020000',
          '0446299010000',
          '0446299020000',
          '0447100010000',
          '0447100020000',
          '0447200010000',
          '0447200020000',
          '0449100010000',
          '0449100020000',
          '0449200010000',
          '0449200020000',
          '0451100010000',
          '0451100020000',
          '0451200010000',
          '0451200020000',
          '0452100010000',
          '0452100020000',
          '0452200010000',
          '0452200020000',
          '0453000010000',
          '0453000020000',
          '0459000010000',
          '0459000020000',
          '0491100000000',
          '0491201000000',
          '0491202000100',
          '0491202000200',
          '0491202000300',
          '0491202000400',
          '0491202000500',
          '0491202000600',
          '0491202000700',
          '0491202000800',
          '0491202000900',
          '0491202009900',
          '0491301000000',
          '0491302000100',
          '0491302000200',
          '0491302000300',
          '0491302000400',
          '0491302000500',
          '0491302000600',
          '0491302000700',
          '0491302000800',
          '0491302000900',
          '0491302009900',
          '0492000000000',
          '0493100000000',
          '0493200000000',
          '0493300000000',
          '0493400000000',
          '1101000000100',
          '1101000000200',
          '1101000009900',
          '1102000000000',
          '1103000000000',
          '1104000000000',
          '1105000000000',
          '1201000000100',
          '1201000009900',
          '1202000000100',
          '1202000000200',
          '1203000000000',
          '1300000000100',
          '1300000000200',
          '1410000000000',
          '1421000000000',
          '1422000000000',
          '1423000000000',
          '1424000000000',
          '1429001000000',
          '1429002000000',
          '1429003000000',
          '1429004000000',
          '1429005000000',
          '1429006000000',
          '1429007000000',
          '1429008000000',
          '1429099000000',
          '1511000000000',
          '1512001000100',
          '1512001000200',
          '1512001000300',
          '1512002000000',
          '1513001000100',
          '1513001009900',
          '1513002000100',
          '1513002000200',
          '1513003000000',
          '1513099000000',
          '1520001000100',
          '1520001009900',
          '1520002000000',
          '1520003000100',
          '1520003009900',
          '1520004000000',
          '1520005000000',
          '1520099000000',
          '1531001000100',
          '1531001009900',
          '1531002000000',
          '1532001000100',
          '1532001000200',
          '1532001000300',
          '1532001000400',
          '1532001009900',
          '1532002000000',
          '1532003000000',
          '1532004000100',
          '1532004000200',
          '1532004009900',
          '1532005000100',
          '1532005009900',
          '1533000000100',
          '1533000009900',
          '1540001000100',
          '1540001009900',
          '1540002000100',
          '1540002009900',
          '1540003000100',
          '1540003009900',
          '1540099000100',
          '1540099000200',
          '1540099000300',
          '1540099000400',
          '1540099009900',
          '1611001000100',
          '1611001000200',
          '1611002000100',
          '1611002000200',
          '1612000000000',
          '1619001000000',
          '1619002000000',
          '1619003000000',
          '1619004000000',
          '1619005000000',
          '1619006000000',
          '1619007000000',
          '1619099000000',
          '1620001000000',
          '1620002000100',
          '1620002009900',
          '1620003000000',
          '1631000000100',
          '1631000009900',
          '1632001000000',
          '1632002000100',
          '1632002009900',
          '1632003000000',
          '1632004000000',
          '1632099000000',
          '1633001000000',
          '1633002000000',
          '1639001000000',
          '1639002000000',
          '1639003000000',
          '1639004000000',
          '1639005000000',
          '1639006000000',
          '1639007000100',
          '1639007000200',
          '1639008000000',
          '1639009000000',
          '1639010000000',
          '1639099000000',
          '1710000000000',
          '1720000000000',
          '1730000000000',
          '1740001000000',
          '1740002000000',
          '1800000000000',
          '2111100020200',
          '2111100020300',
          '2111100029900',
          '2111100030200',
          '2111100030400',
          '2111100030500',
          '2111100030600',
          '2111100039900',
          '2111200010000',
          '2111200020100',
          '2111200029900',
          '2111200030000',
          '2111300020300',
          '2111300029900',
          '2111300030100',
          '2111300030300',
          '2111300039900',
          '2111400000000',
          '2111501010000',
          '2111501020100',
          '2111501029900',
          '2111501030000',
          '2111599010000',
          '2111599020000',
          '2111599030000',
          '2111600000000',
          '2111800000000',
          '2111900000000',
          '2112100020300',
          '2112100020700',
          '2112100030100',
          '2112100030400',
          '2112200010000',
          '2112200020000',
          '2112200030000',
          '2112300010000',
          '2112300020000',
          '2112300030000',
          '2112400010000',
          '2112400020000',
          '2112400030000',
          '2112500010000',
          '2112500020000',
          '2112500030000',
          '2113100020200',
          '2113100020300',
          '2113100029900',
          '2113100030200',
          '2113100030400',
          '2113100030500',
          '2113100030600',
          '2113100039900',
          '2113200010000',
          '2113200020100',
          '2113200029900',
          '2113200030000',
          '2113300020300',
          '2113300029900',
          '2113300030100',
          '2113300030300',
          '2113300039900',
          '2113400000000',
          '2113501010000',
          '2113501020000',
          '2113501030000',
          '2113599010000',
          '2113599020000',
          '2113599030000',
          '2113600000000',
          '2113800000100',
          '2113800000200',
          '2113900000000',
          '2114100020300',
          '2114100020700',
          '2114100030100',
          '2114100030400',
          '2114200010000',
          '2114200020000',
          '2114200030000',
          '2114300010000',
          '2114300020000',
          '2114300030000',
          '2114400010000',
          '2114400020000',
          '2114400030000',
          '2114500010000',
          '2114500020000',
          '2114500030000',
          '2115100010300',
          '2115100010400',
          '2115100020300',
          '2115100020400',
          '2115200010000',
          '2115200020000',
          '2115300010100',
          '2115300020100',
          '2115500010000',
          '2115500020000',
          '2115600010000',
          '2115600020000',
          '2115901010000',
          '2115901020000',
          '2115902010000',
          '2115902020000',
          '2115903010000',
          '2115903020000',
          '2115999010000',
          '2115999020000',
          '2116001010100',
          '2116001020100',
          '2116002010100',
          '2116002019900',
          '2116002020100',
          '2116002029900',
          '2116003010000',
          '2116003020000',
          '2116004010000',
          '2116004020000',
          '2116005010000',
          '2116005020000',
          '2116099010000',
          '2116099020000',
          '2117000000000',
          '2118100000100',
          '2118100000200',
          '2118100009900',
          '2118200000100',
          '2118200000200',
          '2118301000000',
          '2118302000000',
          '2118303000000',
          '2118304000100',
          '2118304000200',
          '2118304000300',
          '2118304000400',
          '2118399000100',
          '2118399009900',
          '2118401010102',
          '2118401010104',
          '2118401010106',
          '2118401010108',
          '2118401010109',
          '2118401010199',
          '2118401019902',
          '2118401019903',
          '2118401019904',
          '2118401019905',
          '2118401019999',
          '2118401990001',
          '2118401990002',
          '2118401990003',
          '2118401990004',
          '2118401990005',
          '2118401990006',
          '2118401990007',
          '2118401990099',
          '2118402000102',
          '2118402000104',
          '2118402000106',
          '2118402000108',
          '2118402000109',
          '2118402000110',
          '2118402000199',
          '2118402009902',
          '2118402009903',
          '2118402009904',
          '2118402009905',
          '2118402009906',
          '2118402009999',
          '2118403000101',
          '2118403000102',
          '2118403000104',
          '2118403000106',
          '2118403000108',
          '2118403000199',
          '2118403009901',
          '2118403009902',
          '2118403009903',
          '2118403009904',
          '2118403009999',
          '2118499010099',
          '2118499990099',
          '2118500000000',
          '2118601000000',
          '2118602000000',
          '2118603000000',
          '2118699000000',
          '2118901000000',
          '2118902000100',
          '2118902000200',
          '2118902009900',
          '2118903000100',
          '2118903009900',
          '2118904000100',
          '2118904000200',
          '2118904009900',
          '2118905000000',
          '2118999000000',
          '2119001000100',
          '2119001000200',
          '2119001000300',
          '2119001009900',
          '2119002000000',
          '2119003000000',
          '2121400000200',
          '2122101001500',
          '2122102000600',
          '2122102000799',
          '2122102009900',
          '2122200001800',
          '2122300000500',
          '2122300000699',
          '2122300009900',
          '2122400000100',
          '2122400000200',
          '2122400009900',
          '2122500000000',
          '2122600000000',
          '2122700000100',
          '2122700000200',
          '2123101000100',
          '2123101000200',
          '2123101000300',
          '2123101009900',
          '2123102000000',
          '2123103000000',
          '2123200000100',
          '2123200009900',
          '2123300000000',
          '2123400000000',
          '2124100000000',
          '2124201000000',
          '2124202009900',
          '2124203010100',
          '2124203019900',
          '2124203020100',
          '2124203020300',
          '2124299000000',
          '2124300000100',
          '2124300000200',
          '2125100010100',
          '2125100019900',
          '2125100020100',
          '2125100029900',
          '2125100030000',
          '2125200010100',
          '2125200010200',
          '2125200010300',
          '2125200010400',
          '2125200010500',
          '2125200010600',
          '2125200010700',
          '2125200010800',
          '2125200020100',
          '2125200020200',
          '2125200030000',
          '2125300010100',
          '2125300019900',
          '2125300020100',
          '2125300029900',
          '2125300030000',
          '2125400010100',
          '2125400019900',
          '2125400020100',
          '2125400029900',
          '2125400030000',
          '2125501010100',
          '2125501019900',
          '2125501020100',
          '2125501029900',
          '2125501030000',
          '2125502010100',
          '2125502019900',
          '2125502020100',
          '2125502029900',
          '2125502030000',
          '2125601010100',
          '2125601019900',
          '2125601020100',
          '2125601029900',
          '2125601030000',
          '2125602010100',
          '2125602019900',
          '2125602020100',
          '2125602029900',
          '2125602030000',
          '2125901010100',
          '2125901019900',
          '2125901020100',
          '2125901029900',
          '2125901030000',
          '2125902000000',
          '2126100000000',
          '2126200000000',
          '2126300000000',
          '2126400000000',
          '2126500000000',
          '2126600000100',
          '2126600000200',
          '2126700000100',
          '2126700000200',
          '2126800000100',
          '2126800000200',
          '2126900000100',
          '2126900000200',
          '2127000000100',
          '2127000000200',
          '2127000000300',
          '2127000000400',
          '2127000009900',
          '2128000000100',
          '2128000000200',
          '2128000000300',
          '2128000000400',
          '2128000000500',
          '2128000000600',
          '2128000009900',
          '2129101000100',
          '2129101009900',
          '2129199000000',
          '2129900000100',
          '2129900009900',
          '2131100000000',
          '2131200000000',
          '2131300000100',
          '2131900000100',
          '2131900000200',
          '2131900000400',
          '2131900000500',
          '2131900000600',
          '2131900009900',
          '2132100000100',
          '2132100000200',
          '2132900000000',
          '2133000000100',
          '2133000000200',
          '2133000000300',
          '2133000000400',
          '2133000000500',
          '2133000000600',
          '2133000000700',
          '2133000000800',
          '2133000009900',
          '2134000000100',
          '2134000000200',
          '2134000000300',
          '2134000000400',
          '2134000000500',
          '2134000000600',
          '2134000000700',
          '2134000000800',
          '2134000000900',
          '2134000001000',
          '2134000009900',
          '2139100000000',
          '2139200000100',
          '2139200000200',
          '2139301000100',
          '2139301009900',
          '2139302000100',
          '2139302000200',
          '2139302000300',
          '2139302009900',
          '2139303000100',
          '2139303000200',
          '2139399000100',
          '2139399000200',
          '2139399009900',
          '2139400000100',
          '2139400000200',
          '2139400000300',
          '2139501000100',
          '2139501000200',
          '2139502000000',
          '2139503000000',
          '2139599000000',
          '2139600000000',
          '2139701000000',
          '2139702010100',
          '2139702019900',
          '2139702990100',
          '2139702999900',
          '2139901000100',
          '2139901000200',
          '2139901000300',
          '2139902000100',
          '2139902000200',
          '2139902000300',
          '2139903010100',
          '2139903010200',
          '2139903010300',
          '2139903010400',
          '2139903010500',
          '2139903010600',
          '2139903010700',
          '2139903019900',
          '2139903020100',
          '2139903020200',
          '2139903020300',
          '2139903020400',
          '2139903020500',
          '2139903020600',
          '2139903020700',
          '2139903029900',
          '2139999000100',
          '2139999000200',
          '2139999000300',
          '2139999000400',
          '2139999000500',
          '2139999000600',
          '2139999000700',
          '2139999000800',
          '2139999000900',
          '2139999001000',
          '2139999001100',
          '2139999001200',
          '2139999001300',
          '2139999009900',
          '2141100000000',
          '2141200000000',
          '2141901000100',
          '2141901000200',
          '2141901000300',
          '2141901000400',
          '2141901000500',
          '2141901000600',
          '2141901009900',
          '2141999000100',
          '2141999000200',
          '2141999000300',
          '2141999000400',
          '2141999000500',
          '2141999000600',
          '2141999000700',
          '2141999000800',
          '2141999000900',
          '2141999001000',
          '2141999001100',
          '2141999001200',
          '2141999001300',
          '2141999001400',
          '2141999009900',
          '2142200010000',
          '2142200020000',
          '2142300010000',
          '2142300020000',
          '2142400010000',
          '2142400020000',
          '2142901000100',
          '2142901000200',
          '2142902010000',
          '2142902020000',
          '2142903010000',
          '2142903020000',
          '2142904010000',
          '2142904020000',
          '2142905010000',
          '2142905020000',
          '2142906010000',
          '2142906020000',
          '2142999000100',
          '2142999000200',
          '2142999009900',
          '2143100010100',
          '2143100010200',
          '2143100020100',
          '2143100020200',
          '2143200010000',
          '2143200020000',
          '2143300010000',
          '2143300020000',
          '2143400010100',
          '2143400010200',
          '2143400020000',
          '2143400030000',
          '2143500010000',
          '2143500020000',
          '2143500030000',
          '2143900010100',
          '2143900010200',
          '2143900010300',
          '2143900010400',
          '2143900010500',
          '2143900019900',
          '2143900020100',
          '2143900020200',
          '2143900020300',
          '2143900020400',
          '2143900020500',
          '2143900020600',
          '2143900020700',
          '2143900020800',
          '2143900029900',
          '2143900030100',
          '2143900030200',
          '2143900039900',
          '2149100000000',
          '2149200000000',
          '2149301000100',
          '2149301000200',
          '2149301000300',
          '2149301000400',
          '2149301000500',
          '2149301009900',
          '2149302009900',
          '2149303009900',
          '2149399009900',
          '2149400000200',
          '2149500000100',
          '2149500000200',
          '2149500000300',
          '2149500000400',
          '2149500000500',
          '2149500000600',
          '2149500000700',
          '2149500009900',
          '2149600000100',
          '2149600000200',
          '2149600000300',
          '2149600000400',
          '2149600000500',
          '2149600009900',
          '2149900010100',
          '2149900019900',
          '2149900020100',
          '2149900020200',
          '2149900020300',
          '2149900020400',
          '2149900020500',
          '2149900020600',
          '2149900020700',
          '2149900020800',
          '2149900029900',
          '2149900990100',
          '2149900990200',
          '2149900990300',
          '2149900990400',
          '2149900990500',
          '2149900999900',
          '2151101000100',
          '2151101000200',
          '2151102000000',
          '2151200000000',
          '2151300000000',
          '2151400000000',
          '2151500000000',
          '2151900000000',
          '2152100000100',
          '2152100000200',
          '2152200000000',
          '2152300000100',
          '2152300009900',
          '2152400000000',
          '2152500000000',
          '2152600000000',
          '2152900000100',
          '2152900000200',
          '2152900009900',
          '2159000000000',
          '2161100000100',
          '2161200000100',
          '2162100000000',
          '2162200000000',
          '2165200000100',
          '2165200000200',
          '2167100000000',
          '2167200000000',
          '2167300000000',
          '2168100000000',
          '2168200000000',
          '2169102000100',
          '2169102000200',
          '2169103000100',
          '2169103000200',
          '2169199000100',
          '2169199000200',
          '2169300000000',
          '2170000000400',
          '2170000000500',
          '2170000009900',
          '2180000000000',
          '2191001010000',
          '2191001990000',
          '2191002000000',
          '2191003000000',
          '2191004000000',
          '2191005000000',
          '2191006010000',
          '2191006990000',
          '2191007000000',
          '2191008000000',
          '2191009000000',
          '2191099000000',
          '2192000000100',
          '2192000009900',
          '2193100000000',
          '2193200000000',
          '2211099010000',
          '2211099020100',
          '2211099020200',
          '2211099030000',
          '2212000000100',
          '2212000000200',
          '2213000000000',
          '2221200000200',
          '2221900000000',
          '2222100000000',
          '2222200000000',
          '2222900000100',
          '2222900009900',
          '2223001000100',
          '2223001000200',
          '2223099010000',
          '2223099020000',
          '2223099030100',
          '2223099039900',
          '2223099050000',
          '2223099990000',
          '2224100000100',
          '2224100000200',
          '2224100009900',
          '2224200000000',
          '2224900000000',
          '2225101010100',
          '2225101020100',
          '2225101990100',
          '2225102010100',
          '2225102010200',
          '2225102010300',
          '2225102010400',
          '2225102019900',
          '2225102020100',
          '2225102020200',
          '2225102020300',
          '2225102020400',
          '2225102020500',
          '2225102029900',
          '2225199000100',
          '2225199009900',
          '2225200000000',
          '2225300000000',
          '2225400000000',
          '2225900000000',
          '2226000000000',
          '2227000000100',
          '2227000009900',
          '2229001019900',
          '2229001020200',
          '2229001029900',
          '2229001990100',
          '2229001990200',
          '2229001999900',
          '2229002019900',
          '2229002020200',
          '2229002029900',
          '2229002990100',
          '2229002990200',
          '2229002999900',
          '2229099000000',
          '2230000000100',
          '2230000009900',
          '2311000000200',
          '2312000000100',
          '2312000000200',
          '2312000000300',
          '2312000000400',
          '2312000009900',
          '2313001000100',
          '2313001000200',
          '2313001000300',
          '2313001000500',
          '2313001009900',
          '2313002000100',
          '2313002009900',
          '2314000010300',
          '2314000019900',
          '2314000020400',
          '2314000020500',
          '2314000029900',
          '2314000990100',
          '2314000990200',
          '2314000990300',
          '2314000990400',
          '2314000990699',
          '2314000990700',
          '2314000990800',
          '2314000990900',
          '2314000991100',
          '2314000991200',
          '2314000991300',
          '2314000999900',
          '2316100000200',
          '2316200000200',
          '2317001000100',
          '2317001000200',
          '2317001009900',
          '2317002000000',
          '2317099000000',
          '2318000000100',
          '2318000000200',
          '2321001000100',
          '2321001000200',
          '2321001000300',
          '2321001000400',
          '2321002000100',
          '2321002009900',
          '2321003000000',
          '2321004000100',
          '2321004000200',
          '2321099000100',
          '2321099000200',
          '2321099000300',
          '2321099009900',
          '2322001000100',
          '2322001000300',
          '2322001009900',
          '2322002000000',
          '2322003000000',
          '2322004000000',
          '2322005000100',
          '2322005009900',
          '2323000000000',
          '2331100000100',
          '2331100000200',
          '2331901000100',
          '2331901009900',
          '2331902000000',
          '2331903000100',
          '2331903000200',
          '2331903009900',
          '2331904000000',
          '2331905010100',
          '2331905019900',
          '2331905990100',
          '2331905990200',
          '2331905990300',
          '2331905990400',
          '2331905999900',
          '2331906000100',
          '2331906009900',
          '2331999000000',
          '2332000000100',
          '2332000009900',
          '2341000000100',
          '2341000009900',
          '2342001000100',
          '2342001009900',
          '2342002000000',
          '2342099000000',
          '2343000000000',
          '2349001010200',
          '2349001019900',
          '2349001020200',
          '2349001029900',
          '2349002010500',
          '2349002010800',
          '2349002011000',
          '2349002011200',
          '2349002011300',
          '2349002011400',
          '2349002019900',
          '2349002020600',
          '2349002020700',
          '2349002029900',
          '2351101000000',
          '2351199000000',
          '2351200000000',
          '2352001990100',
          '2352099010000',
          '2352099990000',
          '2353001000000',
          '2353002000000',
          '2353099000000',
          '2354000000100',
          '2354000009900',
          '2361000000100',
          '2361000000200',
          '2362000000100',
          '2362000000200',
          '2365000000100',
          '2365000009900',
          '2366000000100',
          '2366000000200',
          '2366000000300',
          '2366000000400',
          '2366000000500',
          '2366000009900',
          '2367000000100',
          '2367000000200',
          '2367000000300',
          '2367000000400',
          '2367000000500',
          '2367000000600',
          '2367000000700',
          '2367000009900',
          '2372100000100',
          '2372100000200',
          '2372100000300',
          '2372100009900',
          '2372200000000',
          '2391101000000',
          '2391102020000',
          '2391200000100',
          '2391200000200',
          '2391200000300',
          '2391200000400',
          '2391400000100',
          '2391400009900',
          '2392199000100',
          '2392199000200',
          '2392200000100',
          '2392200000200',
          '2392301000100',
          '2392301000200',
          '2392302000100',
          '2392302000200',
          '2392303000100',
          '2392303000200',
          '2392304000100',
          '2392304000200',
          '2392401000100',
          '2392401000200',
          '2392403000100',
          '2392403000200',
          '2392404000100',
          '2392404000200',
          '2392405000100',
          '2392405000200',
          '2392800000100',
          '2392800000200',
          '2392901000100',
          '2392901000200',
          '2392902000100',
          '2392902000200',
          '2392903000100',
          '2392903000200',
          '2392904000100',
          '2392904000200',
          '2392905000100',
          '2392905000200',
          '2392999000100',
          '2392999009900',
          '2399101000100',
          '2399101000200',
          '2399101000300',
          '2399101000400',
          '2399101000500',
          '2399101000600',
          '2399101000700',
          '2399101009900',
          '2399102000100',
          '2399102000200',
          '2399102009900',
          '2399103000100',
          '2399103009900',
          '2399104000100',
          '2399104000200',
          '2399199000100',
          '2399199000200',
          '2399199009900',
          '2399200000000',
          '2399301000100',
          '2399301000200',
          '2399302000100',
          '2399302000200',
          '2399303000100',
          '2399303000200',
          '2399303000300',
          '2399303009900',
          '2399400000100',
          '2399400009900',
          '2399501019900',
          '2399501020100',
          '2399501030000',
          '2399501040000',
          '2399501050000',
          '2399501060000',
          '2399501070000',
          '2399501080000',
          '2399501110000',
          '2399501990000',
          '2399502000100',
          '2399502000200',
          '2399502000300',
          '2399502000400',
          '2399502000500',
          '2399502000600',
          '2399502000700',
          '2399502009900',
          '2399602009900',
          '2399699000000',
          '2399700000000',
          '2399903000100',
          '2399903000200',
          '2399903000300',
          '2399903000400',
          '2399903000500',
          '2399903000600',
          '2399903009900',
          '2399904000100',
          '2399904000200',
          '2399904000300',
          '2399904009900',
          '2399905000100',
          '2399905000200',
          '2399905000400',
          '2399905000500',
          '2399905009900',
          '2399906000100',
          '2399906000200',
          '2399906000300',
          '2399906000400',
          '2399907000100',
          '2399907000201',
          '2399907000299',
          '2399907000301',
          '2399907000399',
          '2399907000400',
          '2399908000300',
          '2399909000100',
          '2399909000200',
          '2399909009900',
          '2399999000101',
          '2399999000200',
          '2399999000300',
          '2399999000400',
          '2399999000500',
          '2399999000600',
          '2399999000700',
          '2399999000800',
          '2399999000900',
          '2399999001000',
          '2399999001100',
          '2399999001200',
          '2399999001300',
          '2399999001400',
          '2399999001500',
          '2399999001700',
          '2399999001800',
          '2399999001900',
          '2399999002000',
          '2399999002100',
          '2399999002200',
          '2399999009900',
          '2411000000100',
          '2411000000200',
          '2411000009900',
          '2413100000100',
          '2413100000200',
          '2413100000300',
          '2413100000400',
          '2413100000500',
          '2413100000600',
          '2413100000700',
          '2413100000800',
          '2413100009900',
          '2413900000100',
          '2413900000200',
          '2413900009900',
          '2421100000000',
          '2421200000100',
          '2421200000200',
          '2422000000000',
          '2423000000000',
          '2431000000000',
          '2432000010000',
          '2432000020100',
          '2432000020200',
          '2441001000100',
          '2441001000200',
          '2441002000000',
          '2441099000000',
          '2449001000000',
          '2449002000100',
          '2449002000200',
          '2449002000300',
          '2449002000400',
          '2449003000100',
          '2449003000200',
          '2449004000100',
          '2449004000200',
          '2449004009900',
          '2449005000100',
          '2449005000200',
          '2449005009900',
          '2449006000100',
          '2449006009900',
          '2449099000100',
          '2449099000200',
          '2449099000300',
          '2449099009900',
          '2501000000100',
          '2501000000200',
          '2501000000300',
          '2501000009900',
          '2502000000300',
          '2509000000100',
          '2509000000200',
          '2509000000300',
          '2509000000400',
          '2509000000500',
          '2509000000600',
          '2509000000700',
          '2509000009900',
          '2611000000000',
          '2613000000100',
          '2613000000200',
          '2614000000000',
          '2615000010100',
          '2615000010200',
          '2615000020100',
          '2615000029900',
          '2615000030000',
          '2617000000000',
          '2619000010100',
          '2619000010200',
          '2619000020100',
          '2619000020200',
          '2619000030100',
          '2619000030200',
          '2619000040100',
          '2619000040200',
          '2619000990100',
          '2619000999900',
          '2621000000100',
          '2621000000200',
          '2621000000300',
          '2621000000400',
          '2621000009900',
          '2622000000000',
          '2631000000100',
          '2631000000200',
          '2631000000300',
          '2632000000000',
          '2633000000000',
          '2634000010000',
          '2634000020100',
          '2634000029900',
          '2634000030000',
          '2635000000100',
          '2635000009900',
          '2636000010100',
          '2636000010200',
          '2636000020100',
          '2636000020200',
          '2637000010100',
          '2637000010200',
          '2637000020100',
          '2637000020200',
          '2638000000100',
          '2638000000200',
          '2638000000300',
          '2638000000400',
          '2638000000500',
          '2638000000600',
          '2638000009900',
          '2641001010000',
          '2641001020000',
          '2641002010100',
          '2641002019900',
          '2641002020000',
          '2642100000100',
          '2642100000200',
          '2642100000300',
          '2642100000400',
          '2642100009900',
          '2642200000100',
          '2642200000200',
          '2643000010100',
          '2643000010200',
          '2643000020100',
          '2643000020200',
          '2643000030100',
          '2643000030200',
          '2643000040100',
          '2643000040200',
          '2644000010100',
          '2644000010200',
          '2644000010300',
          '2644000019900',
          '2644000020100',
          '2644000020200',
          '2644000029900',
          '2644000990100',
          '2644000990200',
          '2644000999900',
          '2645000000100',
          '2645000000200',
          '2646100000100',
          '2646100000200',
          '2646100009900',
          '2646200000000',
          '2651000000100',
          '2651000000200',
          '2651000009900',
          '2652000000000',
          '2653000000000',
          '2654000010100',
          '2654000010200',
          '2654000019900',
          '2654000020100',
          '2654000020200',
          '2654000029900',
          '2655000000000',
          '2656000010100',
          '2656000019900',
          '2656000020100',
          '2656000029900',
          '2657000000100',
          '2657000009900',
          '2659000000100',
          '2659000000200',
          '2659000009900',
          '2661000010000',
          '2661000020000',
          '2661000030000',
          '2661000040000',
          '2661000050000',
          '2662000010000',
          '2662000020000',
          '2662000030000',
          '2662000040000',
          '2662000050000',
          '2663000010000',
          '2663000020000',
          '2663000030000',
          '2663000040000',
          '2663000050000',
          '2669000010000',
          '2669000020000',
          '2669000030000',
          '2669000040000',
          '2669000050000',
          '2671001000100',
          '2671001000200',
          '2671002000100',
          '2671002000200',
          '2671002009900',
          '2671003000000',
          '2672001010000',
          '2672001020000',
          '2672001030000',
          '2672001040000',
          '2672002010000',
          '2672002020000',
          '2672002030000',
          '2672002040000',
          '2672003000000',
          '2672004010000',
          '2672004020000',
          '2672004030000',
          '2672004040000',
          '2672005010000',
          '2672005020000',
          '2672005030000',
          '2672005040000',
          '2673001010000',
          '2673001020000',
          '2673001030000',
          '2673001040000',
          '2673002010000',
          '2673002020000',
          '2673002030000',
          '2673002040000',
          '2674001010000',
          '2674001990000',
          '2674002010000',
          '2674002990000',
          '2674099010000',
          '2674099990000',
          '2675000010000',
          '2675000020000',
          '2675000030000',
          '2675000040000',
          '2676001010100',
          '2676001010200',
          '2676001019900',
          '2676001020100',
          '2676001020200',
          '2676001029900',
          '2676001030100',
          '2676001030200',
          '2676001039900',
          '2676001040100',
          '2676001040200',
          '2676001049900',
          '2676002010000',
          '2676002020000',
          '2676002030000',
          '2676002040000',
          '2677001010000',
          '2677001020000',
          '2677002010000',
          '2677002020000',
          '2677002030000',
          '2677002040000',
          '2679001000100',
          '2679001000200',
          '2679001009900',
          '2679002000100',
          '2679002009900',
          '2679099010000',
          '2679099020100',
          '2679099020200',
          '2679099020300',
          '2679099020400',
          '2679099029900',
          '2679099990100',
          '2679099990200',
          '2679099990300',
          '2679099990400',
          '2679099999900',
          '2681001010000',
          '2681001020000',
          '2681001990000',
          '2681002000000',
          '2681003010000',
          '2681003020000',
          '2682001010000',
          '2682001020000',
          '2682001990000',
          '2682002000000',
          '2682003010000',
          '2682003020000',
          '2682003990000',
          '2683000000100',
          '2683000009900',
          '2684000000100',
          '2684000009900',
          '2685000000000',
          '2686000009900',
          '2688000000000',
          '2689001000000',
          '2689099009900',
          '2711000000100',
          '2711000000200',
          '2712002010100',
          '2712002010200',
          '2712002020100',
          '2712002020200',
          '2712003010000',
          '2712003020000',
          '2712004010000',
          '2712004990000',
          '2712005010000',
          '2712005990000',
          '2712099010000',
          '2712099020000',
          '2713001010000',
          '2713001020000',
          '2713002010000',
          '2713002020000',
          '2713003010000',
          '2713003020000',
          '2714001010100',
          '2714001010200',
          '2714001020100',
          '2714001020200',
          '2714001990000',
          '2714002000000',
          '2714099000100',
          '2714099000200',
          '2714099009900',
          '2715000000100',
          '2715000000200',
          '2715000000300',
          '2715000009900',
          '2716001010100',
          '2716001019900',
          '2716001990100',
          '2716001999900',
          '2716002010100',
          '2716002019900',
          '2716002990100',
          '2716002990200',
          '2716002999900',
          '2716003000000',
          '2716004000000',
          '2716099000000',
          '2717000000000',
          '2718000000100',
          '2718000000200',
          '2718000000300',
          '2718000000400',
          '2718000009900',
          '2719001000100',
          '2719001009900',
          '2719002000100',
          '2719002009900',
          '2719003000100',
          '2719003000200',
          '2719003009900',
          '2719004990100',
          '2719004999900',
          '2719099010000',
          '2719099020000',
          '2719099030000',
          '2719099040100',
          '2719099049900',
          '2719099050000',
          '2719099990000',
          '2721000000100',
          '2721000000200',
          '2722001000100',
          '2722001000200',
          '2722002000100',
          '2722002000200',
          '2722003000100',
          '2722003000200',
          '2722004000100',
          '2722004000200',
          '2722099000100',
          '2722099000200',
          '2722099009900',
          '2723000000100',
          '2723000000200',
          '2729000000000',
          '2731001000100',
          '2731001000200',
          '2731001000300',
          '2731001000400',
          '2731001000500',
          '2731001000600',
          '2731001000700',
          '2731001009900',
          '2731002000100',
          '2731002000200',
          '2731002000300',
          '2731002009900',
          '2731003000100',
          '2731003000200',
          '2732001000100',
          '2732001009900',
          '2732099000100',
          '2732099000200',
          '2732099000300',
          '2732099000400',
          '2732099009900',
          '2791101019900',
          '2791101020000',
          '2791101990100',
          '2791101990200',
          '2791101990300',
          '2791102000100',
          '2791102009900',
          '2791199000100',
          '2791199000200',
          '2791199009900',
          '2791201000000',
          '2791202010100',
          '2791202019900',
          '2791202020000',
          '2791300000100',
          '2791300000200',
          '2791300000300',
          '2792100000100',
          '2792100000200',
          '2792100000300',
          '2792100000400',
          '2792100009900',
          '2792200000100',
          '2792200009900',
          '2799101000000',
          '2799102010100',
          '2799102019900',
          '2799102020100',
          '2799102029900',
          '2799103010000',
          '2799103020100',
          '2799103029900',
          '2799200000100',
          '2799200000200',
          '2799200000300',
          '2799200009900',
          '2799300000000',
          '2799400000000',
          '2799500000000',
          '2799600000100',
          '2799600000200',
          '2799600009900',
          '2799700000100',
          '2799700000200',
          '2799700000300',
          '2799700000400',
          '2799700000500',
          '2799700000600',
          '2799700009900',
          '2799801010000',
          '2799801020000',
          '2799802010000',
          '2799802990100',
          '2799802999900',
          '2799803000000',
          '2799804000000',
          '2799805000000',
          '2799806000000',
          '2799807000000',
          '2799808000000',
          '2799899000100',
          '2799899000200',
          '2799899009900',
          '2799900000000',
          '2811000010000',
          '2811000020100',
          '2811000020200',
          '2811000990100',
          '2811000990200',
          '2819001010100',
          '2819001010200',
          '2819001010300',
          '2819001010400',
          '2819001020100',
          '2819001020200',
          '2819001020300',
          '2819001020400',
          '2819099010100',
          '2819099010200',
          '2819099010300',
          '2819099010400',
          '2819099020100',
          '2819099020200',
          '2819099020300',
          '2819099020400',
          '2820001000100',
          '2820001000200',
          '2820001009900',
          '2820002000100',
          '2820002000200',
          '2820002009900',
          '2820101010000',
          '2820101020000',
          '2820102000000',
          '2820103000000',
          '2820104000000',
          '2820105019900',
          '2820105029900',
          '2820201000000',
          '2820202000000',
          '2820203019900',
          '2820203029900',
          '2820204010000',
          '2820204029900',
          '2820204030000',
          '2820299000100',
          '2820299009900',
          '2820301010000',
          '2820301020000',
          '2820302000000',
          '2820303000000',
          '2820304000000',
          '2820305019900',
          '2820305029900',
          '2820306009900',
          '2820307000000',
          '2820401000100',
          '2820401000200',
          '2820401000300',
          '2820402000000',
          '2820403000200',
          '2820403009900',
          '2820404000200',
          '2820404009900',
          '2820499000100',
          '2820499000200',
          '2820499009900',
          '2820501010000',
          '2820501020000',
          '2820501990000',
          '2820502010000',
          '2820502020000',
          '2820502990000',
          '2820601000000',
          '2820602000000',
          '2820701000000',
          '2820702000100',
          '2820702000200',
          '2820702000300',
          '2820702009900',
          '2820703000000',
          '2820704000000',
          '2820799010100',
          '2820799019900',
          '2820799990000',
          '2820801000100',
          '2820801000200',
          '2820802000100',
          '2820802000200',
          '2820802000300',
          '2820899000100',
          '2820899009800',
          '2820899009900',
          '2820901000000',
          '2820902000000',
          '2820999000000',
          '2829000000100',
          '2829000000200',
          '2829000000300',
          '2829000000400',
          '2829000000500',
          '2829000000600',
          '2829000000700',
          '2829000000800',
          '2829000000900',
          '2829000001000',
          '2829000001100',
          '2829000001200',
          '2829000009900',
          '2821002000000',
          '2821003000200',
          '2821003009900',
          '2821004000000',
          '2821005000000',
          '2824100000100',
          '2824100009900',
          '2824201010100',
          '2824201010200',
          '2824201019900',
          '2824201990000',
          '2824299000100',
          '2824299009900',
          '2824301000100',
          '2824301000200',
          '2824301009900',
          '2824399000100',
          '2824399000200',
          '2824399009900',
          '2825001000000',
          '2825002000100',
          '2825002000200',
          '2825002009900',
          '2826101000000',
          '2826102000000',
          '2826103000000',
          '2826200000100',
          '2826200000200',
          '2826200009900',
          '2826900000100',
          '2826900009900',
          '2831000000100',
          '2831000000200',
          '2832000000100',
          '2832000000200',
          '2832000009900',
          '2833000000100',
          '2833000009900',
          '2911000000100',
          '2911000000200',
          '2911000009900',
          '2912001010100',
          '2912001010200',
          '2912001990100',
          '2912001990200',
          '2912002010000',
          '2912002990000',
          '2913001010100',
          '2913001010200',
          '2913001020100',
          '2913001020200',
          '2913002010000',
          '2913002020000',
          '2913003000000',
          '2921001000000',
          '2921099000000',
          '2922001010100',
          '2922001010200',
          '2922001010300',
          '2922001010400',
          '2922001010500',
          '2922001020100',
          '2922001020200',
          '2922001020399',
          '2922001020400',
          '2922001020500',
          '2922001990100',
          '2922001990200',
          '2922002010000',
          '2922002020000',
          '2922002030000',
          '2922002990000',
          '2922003010000',
          '2922003020000',
          '2922003990000',
          '2922004010000',
          '2922004020000',
          '2922004990000',
          '2922005010000',
          '2922005020000',
          '2922005990000',
          '2922006010100',
          '2922006019900',
          '2922006020100',
          '2922006029900',
          '2922006990100',
          '2922006999900',
          '2922007010000',
          '2922007020000',
          '2922007990000',
          '2922099010100',
          '2922099019900',
          '2922099020100',
          '2922099029900',
          '2922099990100',
          '2922099999900',
          '2923000000000',
          '2929000000100',
          '2929000009900',
          '2931001000000',
          '2931002000000',
          '2931003000000',
          '2931004000000',
          '2932001010000',
          '2932001990000',
          '2932002010000',
          '2932002990000',
          '2932003010000',
          '2932003990000',
          '2932004010000',
          '2932004990000',
          '2933001010000',
          '2933001990000',
          '2933002010000',
          '2933002990000',
          '2933003010000',
          '2933003990000',
          '2933004010000',
          '2933004990000',
          '2934001010000',
          '2934001990000',
          '2934002010000',
          '2934002990000',
          '2934003010000',
          '2934003990000',
          '2934004010000',
          '2934004990000',
          '2942001010000',
          '2942001990000',
          '2942002010000',
          '2942002990000',
          '2942003010000',
          '2942003990000',
          '2942004010000',
          '2942004990000',
          '2949001010000',
          '2949001020000',
          '2949002010000',
          '2949002020000',
          '2949003010000',
          '2949003020000',
          '2949004010000',
          '2949004020000',
          '2951001000100',
          '2951001009900',
          '2951002000100',
          '2951002009900',
          '2952001000000',
          '2952002000000',
          '2952003000000',
          '2952004000000',
          '2960001010000',
          '2960001020000',
          '2960001030000',
          '2960001990000',
          '2960002010000',
          '2960002020000',
          '2960003010000',
          '2960003990000',
          '2960099010000',
          '2960099020000',
          '2960099030000',
          '2960099990000',
          '3110100000100',
          '3110100000200',
          '3110100009900',
          '3110201000100',
          '3110201000200',
          '3110201000300',
          '3110201000400',
          '3110201000500',
          '3110201000600',
          '3110201009900',
          '3110299000100',
          '3110299000200',
          '3110299000300',
          '3110299000400',
          '3110299000500',
          '3110299000600',
          '3110299000700',
          '3110299009900',
          '3110900000000',
          '3121100000000',
          '3121200000000',
          '3121900000000',
          '3122000000000',
          '3123000000100',
          '3123000009900',
          '3131000000100',
          '3131000009900',
          '3132000000000',
          '3133000000100',
          '3133000009900',
          '3141100000100',
          '3141100009900',
          '3141200010100',
          '3141200019900',
          '3141200990000',
          '3142101010100',
          '3142101019900',
          '3142101990000',
          '3142102010100',
          '3142102019900',
          '3142102990100',
          '3142102999900',
          '3142103010100',
          '3142103019900',
          '3142103020100',
          '3142103029900',
          '3142103990000',
          '3142104010100',
          '3142104019900',
          '3142104020100',
          '3142104029900',
          '3142104990100',
          '3142104999900',
          '3142201010100',
          '3142201019900',
          '3142201990000',
          '3142202010100',
          '3142202019900',
          '3142202990100',
          '3142202999900',
          '3142203010100',
          '3142203019900',
          '3142203020100',
          '3142203029900',
          '3142203990000',
          '3142204010100',
          '3142204019900',
          '3142204020100',
          '3142204029900',
          '3142204990100',
          '3142204990200',
          '3142204999900',
          '3143100010100',
          '3143100019900',
          '3143100020100',
          '3143100029900',
          '3143100030100',
          '3143100039900',
          '3143100990100',
          '3143100999900',
          '3143200010100',
          '3143200019900',
          '3143200990100',
          '3143200999900',
          '3143901010100',
          '3143901019900',
          '3143901990100',
          '3143901999900',
          '3143902010100',
          '3143902019900',
          '3143902020100',
          '3143902029900',
          '3143902030100',
          '3143902039900',
          '3143902990100',
          '3143902999900',
          '3143999000100',
          '3143999009900',
          '3144100010100',
          '3144100010200',
          '3144100019900',
          '3144100020100',
          '3144100020200',
          '3144100029900',
          '3144100030100',
          '3144100030200',
          '3144100039900',
          '3144200000100',
          '3144200000200',
          '3144200009900',
          '3144900000100',
          '3144900000200',
          '3144900009900',
          '3145001010100',
          '3145001019900',
          '3145001020100',
          '3145001029900',
          '3145001990100',
          '3145001999900',
          '3145002010100',
          '3145002019900',
          '3145002020100',
          '3145002029900',
          '3145002990000',
          '3145099010100',
          '3145099019900',
          '3145099020100',
          '3145099029900',
          '3145099990100',
          '3145099999900',
          '3151100000000',
          '3151200000100',
          '3151200000200',
          '3151200009900',
          '3152000000000',
          '3160001010000',
          '3160001020100',
          '3160001020200',
          '3160001029900',
          '3160002010100',
          '3160002010200',
          '3160002019900',
          '3160002990100',
          '3160002990200',
          '3160002999900',
          '3160099010100',
          '3160099019900',
          '3160099990100',
          '3160099990200',
          '3160099990300',
          '3160099990400',
          '3160099990500',
          '3160099990600',
          '3160099999900',
          '3170001000000',
          '3170002000000',
          '3170003000000',
          '3170004000000',
          '3170099000100',
          '3170099000200',
          '3170099009900',
          '3191100000100',
          '3191100000200',
          '3191100009900',
          '3191200010100',
          '3191200010200',
          '3191200010300',
          '3191200010400',
          '3191200019900',
          '3191200990100',
          '3191200990200',
          '3191200990300',
          '3191200990400',
          '3191200999900',
          '3191300000100',
          '3191300000200',
          '3191300000300',
          '3191300000400',
          '3191300000500',
          '3191300009900',
          '3191400010100',
          '3191400010200',
          '3191400010300',
          '3191400010400',
          '3191400010500',
          '3191400010600',
          '3191400010700',
          '3191400010800',
          '3191400019900',
          '3191400990100',
          '3191400990200',
          '3191400990300',
          '3191400990400',
          '3191400990500',
          '3191400990600',
          '3191400990700',
          '3191400990800',
          '3191400990900',
          '3191400991000',
          '3191400991100',
          '3191400999900',
          '3192100000100',
          '3192100009900',
          '3192201000100',
          '3192201000200',
          '3192201009900',
          '3192202000100',
          '3192202000200',
          '3192202000300',
          '3192202009900',
          '3192301010100',
          '3192301010200',
          '3192301019900',
          '3192301020100',
          '3192301020200',
          '3192301020300',
          '3192301029900',
          '3192301990000',
          '3192302010100',
          '3192302010200',
          '3192302019900',
          '3192302020100',
          '3192302020200',
          '3192302029900',
          '3192302030100',
          '3192302030200',
          '3192302030300',
          '3192302039900',
          '3192302990100',
          '3192302990200',
          '3192302999900',
          '3211100000000',
          '3211201010100',
          '3211201010200',
          '3211201020100',
          '3211201020200',
          '3211202010100',
          '3211202010200',
          '3211202020100',
          '3211202020200',
          '3211300000100',
          '3211300000200',
          '3211300000300',
          '3212100000100',
          '3212100000200',
          '3212200000000',
          '3212900010100',
          '3212900010200',
          '3212900010300',
          '3212900020100',
          '3212900020200',
          '3212900020300',
          '3212900030100',
          '3212900030200',
          '3212900039900',
          '3212900990100',
          '3212900999900',
          '3213100000000',
          '3213200000100',
          '3213200000200',
          '3213200000300',
          '3213200009900',
          '3213301010000',
          '3213301990000',
          '3213399010000',
          '3213399990000',
          '3213401000100',
          '3213401009900',
          '3213402000000',
          '3213499000100',
          '3213499009900',
          '3213500000000',
          '3213601000000',
          '3213602000000',
          '3213603000000',
          '3213604000000',
          '3213605000000',
          '3213699000100',
          '3213699009900',
          '3213700000100',
          '3213700000200',
          '3213700000300',
          '3213700009900',
          '3214100000100',
          '3214100009900',
          '3214200000000',
          '3214301010100',
          '3214301010200',
          '3214301019900',
          '3214301020100',
          '3214301020200',
          '3214301029900',
          '3214302010000',
          '3214302020000',
          '3214303000100',
          '3214303009900',
          '3214304000100',
          '3214304009900',
          '3214399000100',
          '3214399000200',
          '3214399009900',
          '3214901010000',
          '3214901020000',
          '3214901990000',
          '3214902000100',
          '3214902000200',
          '3214902009900',
          '3214903010100',
          '3214903019900',
          '3214903020100',
          '3214903020200',
          '3214903029900',
          '3214904010100',
          '3214904019900',
          '3214904020100',
          '3214904029900',
          '3214905010100',
          '3214905010200',
          '3214905010300',
          '3214905019900',
          '3214905020100',
          '3214905020200',
          '3214905029900',
          '3214905030100',
          '3214905039900',
          '3214905990100',
          '3214905990200',
          '3214905999900',
          '3214999010000',
          '3214999020000',
          '3214999030000',
          '3214999990100',
          '3214999999900',
          '3215100000000',
          '3215201010100',
          '3215201019900',
          '3215201990000',
          '3215299000000',
          '3215301000100',
          '3215301009900',
          '3215302010100',
          '3215302010200',
          '3215302990000',
          '3215303000100',
          '3215303000200',
          '3215303009900',
          '3215399000100',
          '3215399009900',
          '3219100000100',
          '3219100000200',
          '3219100000300',
          '3219100000400',
          '3219100009900',
          '3219200010100',
          '3219200010200',
          '3219200010300',
          '3219200010400',
          '3219200019900',
          '3219200990000',
          '3219302010000',
          '3219302020000',
          '3219302030100',
          '3219302039900',
          '3219303000000',
          '3219304000000',
          '3219305009900',
          '3219306009900',
          '3219399000000',
          '3219401000000',
          '3219402000000',
          '3219500000000',
          '3219602010000',
          '3219602020000',
          '3219699010000',
          '3219699020000',
          '3219700010000',
          '3219700020000',
          '3219800000000',
          '3219901000100',
          '3219901000200',
          '3219902000100',
          '3219902000200',
          '3219903990100',
          '3219903990200',
          '3219903999900',
          '3219904000000',
          '3219905000100',
          '3219905000200',
          '3219906000100',
          '3219906009900',
          '3219999000100',
          '3219999000200',
          '3219999000300',
          '3219999000400',
          '3219999000500',
          '3219999009900',
          '3223000000000',
          '3230000000000',
          '3241000000000',
          '3242000000000',
          '3249000000000',
          '3251100000000',
          '3251200000000',
          '3252000000000',
          '3253000000000',
          '3254000000000',
          '3255000000000',
          '3261000000100',
          '3261000000200',
          '3261000000300',
          '3261000009900',
          '3262000000100',
          '3262000000200',
          '3262000000300',
          '3262000000400',
          '3262000009900',
          '3263001000100',
          '3263001009900',
          '3263002000000',
          '3269000000100',
          '3269000009900',
          '3270000000100',
          '3270000000200',
          '3270000000300',
          '3270000000500',
          '3270000000600',
          '3270000000700',
          '3270000000800',
          '3270000000900',
          '3270000001000',
          '3270000009900',
          '3280000000000',
          '3310001000000',
          '3310002000000',
          '3310003000000',
          '3320000000000',
          '3331100030000',
          '3331100990000',
          '3332000009900',
          '3333000000100',
          '3335000000000',
          '3336002000100',
          '3336002000200',
          '3336002000300',
          '3336002009900',
          '3337001000100',
          '3337001009900',
          '3337099990100',
          '3337099999900',
          '3338000010100',
          '3338000010200',
          '3338000019900',
          '3338000990100',
          '3338000990200',
          '3338000999900',
          '3339001000000',
          '3339099000000',
          '3341000000100',
          '3341000000200',
          '3342100000100',
          '3342100000200',
          '3342100000300',
          '3342100000400',
          '3350000010100',
          '3350000010200',
          '3350000019900',
          '3350000020100',
          '3350000029900',
          '3350000030100',
          '3350000039900',
          '3350000040100',
          '3350000040200',
          '3350000990100',
          '3350000990200',
          '3350000990300',
          '3361000000000',
          '3362000000000',
          '3363000000000',
          '3369000000000',
          '3411001010100',
          '3411001019900',
          '3411001020100',
          '3411001020200',
          '3411001020300',
          '3411001029900',
          '3411002010100',
          '3411002010200',
          '3411002019900',
          '3411002020000',
          '3411002030000',
          '3411002040100',
          '3411002040200',
          '3411002040300',
          '3411002040400',
          '3411002040500',
          '3411002049900',
          '3411003010100',
          '3411003010200',
          '3411003010300',
          '3411003010400',
          '3411003019900',
          '3411003020100',
          '3411003020200',
          '3411003020300',
          '3411003029900',
          '3411003030100',
          '3411003030200',
          '3411003030300',
          '3411003030400',
          '3411003030500',
          '3411003030600',
          '3411003039900',
          '3411003040100',
          '3411003040200',
          '3411003040300',
          '3411003049900',
          '3411003050000',
          '3411003060100',
          '3411003060200',
          '3411003069900',
          '3411004010100',
          '3411004010200',
          '3411004019900',
          '3411004020000',
          '3411004990000',
          '3411099000000',
          '3412000000100',
          '3412000000200',
          '3412000000300',
          '3412000000400',
          '3412000000500',
          '3412000009900',
          '3413100000000',
          '3413901010100',
          '3413901010200',
          '3413901010300',
          '3413901010400',
          '3413901010500',
          '3413901010600',
          '3413901010700',
          '3413901010800',
          '3413901019900',
          '3413901020100',
          '3413901029900',
          '3413901030100',
          '3413901030200',
          '3413901039900',
          '3413901040100',
          '3413901040200',
          '3413901040300',
          '3413901040400',
          '3413901040500',
          '3413901049900',
          '3413901050000',
          '3413902010100',
          '3413902010200',
          '3413902010300',
          '3413902010400',
          '3413902019900',
          '3413902020100',
          '3413902029900',
          '3413903010100',
          '3413903010200',
          '3413903010300',
          '3413903010400',
          '3413903010500',
          '3413903010600',
          '3413903010700',
          '3413903019900',
          '3413903020100',
          '3413903020200',
          '3413903020300',
          '3413903029900',
          '3413904010000',
          '3413904990000',
          '3413905000000',
          '3414001010100',
          '3414001010200',
          '3414001010300',
          '3414001020100',
          '3414001020200',
          '3414001020300',
          '3414001020400',
          '3414001029900',
          '3414001030100',
          '3414001030200',
          '3414001030300',
          '3414001030400',
          '3414001030500',
          '3414001030600',
          '3414001030700',
          '3414001030800',
          '3414001039900',
          '3414001040000',
          '3414001050000',
          '3414001060000',
          '3414001070000',
          '3414001080000',
          '3414001090000',
          '3414001100000',
          '3414001990000',
          '3414002010100',
          '3414002010200',
          '3414002010300',
          '3414002010400',
          '3414002010500',
          '3414002010600',
          '3414002019900',
          '3414002020000',
          '3414002030100',
          '3414002030200',
          '3414002030300',
          '3414002039900',
          '3414003010100',
          '3414003010200',
          '3414003010300',
          '3414003010400',
          '3414003019900',
          '3414003020000',
          '3414003030100',
          '3414003030200',
          '3414003030300',
          '3414003030400',
          '3414003030500',
          '3414003039900',
          '3414004010100',
          '3414004010200',
          '3414004010300',
          '3414004010400',
          '3414004010500',
          '3414004019900',
          '3414004020100',
          '3414004029900',
          '3414004990000',
          '3415001010100',
          '3415001010200',
          '3415001010300',
          '3415001010400',
          '3415001010500',
          '3415001019900',
          '3415001020100',
          '3415001029900',
          '3415001030000',
          '3415001040100',
          '3415001040200',
          '3415001049900',
          '3415001050100',
          '3415001059900',
          '3415002010100',
          '3415002010200',
          '3415002010300',
          '3415002019900',
          '3415002020000',
          '3415002030000',
          '3415002990000',
          '3415003000000',
          '3415004010100',
          '3415004019900',
          '3415004020000',
          '3415005000100',
          '3415005009900',
          '3415006000000',
          '3415007000000',
          '3415008010000',
          '3415008020100',
          '3415008029900',
          '3415008990000',
          '3416001010100',
          '3416001019900',
          '3416001020000',
          '3416001030000',
          '3416001990000',
          '3416002000100',
          '3416002009900',
          '3416003000100',
          '3416003000200',
          '3416003000300',
          '3416003000400',
          '3416003009900',
          '3416004010000',
          '3416004020100',
          '3416004029900',
          '3416004030000',
          '3416004040000',
          '3416004990100',
          '3416004990200',
          '3416004990300',
          '3416004999900',
          '3416005000100',
          '3416005009900',
          '3417001010100',
          '3417001019900',
          '3417001020000',
          '3417001030000',
          '3417001040100',
          '3417001040200',
          '3417001040300',
          '3417001049900',
          '3417002000100',
          '3417002009900',
          '3417003010100',
          '3417003010200',
          '3417003019900',
          '3417003020100',
          '3417003029900',
          '3417003030100',
          '3417003030200',
          '3417003039900',
          '3417003040000',
          '3417003050000',
          '3417004000000',
          '3417005010100',
          '3417005010200',
          '3417005010300',
          '3417005010400',
          '3417005019900',
          '3417005020100',
          '3417005020200',
          '3417005020300',
          '3417005029900',
          '3417005030000',
          '3417005990000',
          '3417006000100',
          '3417006009900',
          '3417099000000',
          '3418001000100',
          '3418001009900',
          '3418002000100',
          '3418002000200',
          '3421001010000',
          '3421001020100',
          '3421001029900',
          '3421001030100',
          '3421001039900',
          '3421001040000',
          '3421002000100',
          '3421002000200',
          '3421002009900',
          '3422001000000',
          '3422002000100',
          '3422002009900',
          '3422003000100',
          '3422003009900',
          '3422004000100',
          '3422004009900',
          '3422005000000',
          '3422006000000',
          '3422007000100',
          '3422007009900',
          '3422008000100',
          '3422008000200',
          '3422008000300',
          '3422008009900',
          '3423101010000',
          '3423101020100',
          '3423101029900',
          '3423101030000',
          '3423102000000',
          '3423103000000',
          '3423104000100',
          '3423104000200',
          '3423104000300',
          '3423104000400',
          '3423104000500',
          '3423105010100',
          '3423105010200',
          '3423105019900',
          '3423105020000',
          '3423105030000',
          '3423106010000',
          '3423106020000',
          '3423107010000',
          '3423107020000',
          '3423107030000',
          '3423107990000',
          '3423108000000',
          '3423109000100',
          '3423109009900',
          '3423110010100',
          '3423110010200',
          '3423110010300',
          '3423110019900',
          '3423110020000',
          '3423111000100',
          '3423111009900',
          '3423112000100',
          '3423112009900',
          '3423113010100',
          '3423113010200',
          '3423113010300',
          '3423113010400',
          '3423113010500',
          '3423113020100',
          '3423113029900',
          '3423113030000',
          '3423114010000',
          '3423114020000',
          '3423115000000',
          '3423116000000',
          '3423200000100',
          '3423200009900',
          '3423301000100',
          '3423301009900',
          '3423302000000',
          '3424001010100',
          '3424001010200',
          '3424001019900',
          '3424001020000',
          '3424001990000',
          '3424002010100',
          '3424002010200',
          '3424002010300',
          '3424002010400',
          '3424002010500',
          '3424002010600',
          '3424002010700',
          '3424002019900',
          '3424002020000',
          '3424002030100',
          '3424002039900',
          '3424002040100',
          '3424002049900',
          '3424003000100',
          '3424003000200',
          '3424003009900',
          '3424004000000',
          '3424005010100',
          '3424005010200',
          '3424005010300',
          '3424005019900',
          '3424005020000',
          '3424006000100',
          '3424006009900',
          '3424007010100',
          '3424007019900',
          '3424007020000',
          '3424008010100',
          '3424008019900',
          '3424008020000',
          '3424008030100',
          '3424008039900',
          '3424008040100',
          '3424008049900',
          '3424008050100',
          '3424008059900',
          '3424008060000',
          '3424008070000',
          '3424008080000',
          '3424008090000',
          '3424008100100',
          '3424008109900',
          '3424008990000',
          '3424009000100',
          '3424009009900',
          '3424010000100',
          '3424010000300',
          '3424010000400',
          '3424010000500',
          '3424010000600',
          '3424010000700',
          '3424010000800',
          '3424010000900',
          '3424010009900',
          '3424011010100',
          '3424011019900',
          '3424011020100',
          '3424011029900',
          '3424011030000',
          '3424011040100',
          '3424011049900',
          '3424011050100',
          '3424011059900',
          '3424011060000',
          '3424011990000',
          '3425001010100',
          '3425001010200',
          '3425001019900',
          '3425001020100',
          '3425001029900',
          '3425001030100',
          '3425001039900',
          '3425001040100',
          '3425001049900',
          '3425001050000',
          '3425001060000',
          '3425001990000',
          '3425002010000',
          '3425002020100',
          '3425002029900',
          '3425002030000',
          '3425002040000',
          '3425002990000',
          '3425003010100',
          '3425004010100',
          '3425004010200',
          '3425004019900',
          '3425004020000',
          '3425004030000',
          '3426000000100',
          '3426000009900',
          '3427001010100',
          '3427001019900',
          '3427001020000',
          '3427002010100',
          '3427002019900',
          '3427002020100',
          '3427002020200',
          '3427002029900',
          '3427003010100',
          '3427003019900',
          '3427003020000',
          '3427003990100',
          '3427003999900',
          '3427099010100',
          '3427099010200',
          '3427099020000',
          '3427099990000',
          '3428001010000',
          '3428001990000',
          '3428002010000',
          '3428002020000',
          '3428002990000',
          '3428003010000',
          '3428003990000',
          '3428004000000',
          '3429000000100',
          '3429000009900',
          '3431001010000',
          '3431001020000',
          '3431001030000',
          '3431001040000',
          '3431001050000',
          '3431001060000',
          '3431001070100',
          '3431001079900',
          '3431001990000',
          '3431002000000',
          '3431003000000',
          '3432001000100',
          '3432001000200',
          '3432001009900',
          '3432002000100',
          '3432002009900',
          '3433000000100',
          '3433000009900',
          '3434001010000',
          '3434001020000',
          '3434001030000',
          '3434001040000',
          '3434001990000',
          '3434002000000',
          '3434099000100',
          '3434099000200',
          '3440001000000',
          '3440002000000',
          '3440003000100',
          '3440003000200',
          '3440003009900',
          '3440004000100',
          '3440004000200',
          '3440004000300',
          '3440004009900',
          '3440005000000',
          '3451000000100',
          '3451000009900',
          '3452000000000',
          '3453000000000',
          '3454001000100',
          '3454001000200',
          '3454001000300',
          '3454001000400',
          '3454001000500',
          '3454001000600',
          '3454001009900',
          '3454002000100',
          '3454002000200',
          '3455001000000',
          '3455002000100',
          '3455002000200',
          '3455002000300',
          '3455002009900',
          '3456000000100',
          '3456000009900',
          '3457000000100',
          '3457000009900',
          '3461100000100',
          '3461100009900',
          '3461200000100',
          '3461200009900',
          '3461300000100',
          '3461300009900',
          '3461400000000',
          '3461500000000',
          '3461600000000',
          '3461901000000',
          '3461902000000',
          '3461999000000',
          '3462100000100',
          '3462100009900',
          '3462900000100',
          '3462900009900',
          '3463100000100',
          '3463100009900',
          '3463200000100',
          '3463200009900',
          '3463900000100',
          '3463900009900',
          '3464100000000',
          '3464200000000',
          '3464300000100',
          '3464300000200',
          '3464400000100',
          '3464400000200',
          '3464500000000',
          '3464600000100',
          '3464600009900',
          '3464900000100',
          '3464900009900',
          '3465100000100',
          '3465100009900',
          '3465200000000',
          '3465301000100',
          '3465301009900',
          '3465302000000',
          '3465401010100',
          '3465401010200',
          '3465401019900',
          '3465401020000',
          '3465401990000',
          '3465402010100',
          '3465402010200',
          '3465402019900',
          '3465402020000',
          '3465402990000',
          '3465901000000',
          '3465999000000',
          '3466100010100',
          '3466100010200',
          '3466100010300',
          '3466100019900',
          '3466100020100',
          '3466100029900',
          '3466100030100',
          '3466100039900',
          '3466100040100',
          '3466100049900',
          '3466100990100',
          '3466100990200',
          '3466100990300',
          '3466100990400',
          '3466100990500',
          '3466100999900',
          '3466200010100',
          '3466200019900',
          '3466200020100',
          '3466200029900',
          '3466200990100',
          '3466200990200',
          '3466200999900',
          '3466300000100',
          '3466300000200',
          '3466300009900',
          '3466401010000',
          '3466401020100',
          '3466401029900',
          '3466401039900',
          '3466401990100',
          '3466401999900',
          '3466499010000',
          '3466499020100',
          '3466499029900',
          '3466499990100',
          '3466499999900',
          '3466601000100',
          '3466601009900',
          '3466602010100',
          '3466602019900',
          '3466602020000',
          '3466602030000',
          '3466602040000',
          '3466602050000',
          '3466602060000',
          '3466602990100',
          '3466602990200',
          '3466602990300',
          '3466602990400',
          '3466602990500',
          '3466602999900',
          '3466603000100',
          '3466603000200',
          '3466603000300',
          '3466603000400',
          '3466603000500',
          '3466603009900',
          '3466604010000',
          '3466604020100',
          '3466604029900',
          '3466604030000',
          '3466604990100',
          '3466604999900',
          '3466605010100',
          '3466605019900',
          '3466605020100',
          '3466605020200',
          '3466605029900',
          '3466605030000',
          '3466605040000',
          '3466605050000',
          '3466605990100',
          '3466605999900',
          '3466699000000',
          '3466901010000',
          '3466901020000',
          '3466901990000',
          '3466902010100',
          '3466902019900',
          '3466902990100',
          '3466902999900',
          '3466903000000',
          '3466999010000',
          '3466999020000',
          '3466999990100',
          '3466999990200',
          '3466999999900',
          '3471001000100',
          '3471001000200',
          '3471002000100',
          '3471002000200',
          '3471099000000',
          '3472001000100',
          '3472001009900',
          '3472002000100',
          '3472002009900',
          '3472099000000',
          '3473001010000',
          '3473001020100',
          '3473001029900',
          '3473001030100',
          '3473001039900',
          '3473002000100',
          '3473002009900',
          '3473003000000',
          '3473004000100',
          '3473004009900',
          '3473099000000',
          '3474001000000',
          '3474002000000',
          '3474003000000',
          '3474004000100',
          '3474004000200',
          '3474004009900',
          '3474005000100',
          '3474005009900',
          '3474006000100',
          '3474006009900',
          '3474007000000',
          '3474099010100',
          '3474099010200',
          '3474099019900',
          '3474099990100',
          '3474099990200',
          '3474099999900',
          '3479001000100',
          '3479001000200',
          '3479001000300',
          '3479001009900',
          '3479002010100',
          '3479002019900',
          '3479002020100',
          '3479002029900',
          '3479002030000',
          '3479002990100',
          '3479002999900',
          '3479003000100',
          '3479003009900',
          '3479004000100',
          '3479004009900',
          '3479005010100',
          '3479005010200',
          '3479005010300',
          '3479005019900',
          '3479005020000',
          '3479005030100',
          '3479005030200',
          '3479005039900',
          '3479006000000',
          '3479007010000',
          '3479007020000',
          '3479008010100',
          '3479008010200',
          '3479008020000',
          '3479008030100',
          '3479008039900',
          '3479008990000',
          '3479009000100',
          '3479009009900',
          '3479010000000',
          '3480001010000',
          '3480001990100',
          '3480001999900',
          '3480002000100',
          '3480002009900',
          '3480003010100',
          '3480003019900',
          '3480003020100',
          '3480003029900',
          '3480004010000',
          '3480004990100',
          '3480004999900',
          '3480005010000',
          '3480005990100',
          '3480005999900',
          '3480006000100',
          '3480006009900',
          '3480007000100',
          '3480007009900',
          '3480008000100',
          '3480008009900',
          '3480099010000',
          '3480099990100',
          '3480099999900',
          '3511001000100',
          '3511001000200',
          '3511001000300',
          '3511001000400',
          '3511001000500',
          '3511001000600',
          '3511001000700',
          '3511001009900',
          '3511002000100',
          '3511002000200',
          '3511002000300',
          '3511002000400',
          '3511002000500',
          '3511002000600',
          '3511002009900',
          '3511003000100',
          '3511003000200',
          '3511003000300',
          '3511003000400',
          '3511003000500',
          '3511003000600',
          '3511003000700',
          '3511003000800',
          '3511003000900',
          '3511003001000',
          '3511003001100',
          '3511003001200',
          '3511003001300',
          '3511003001400',
          '3511003001500',
          '3511003001600',
          '3511003009900',
          '3511004000100',
          '3511004000200',
          '3511004000300',
          '3511004000400',
          '3511004000500',
          '3511005000100',
          '3511005000200',
          '3511005000300',
          '3511005000400',
          '3511099000100',
          '3511099000200',
          '3511099000300',
          '3511099000400',
          '3511099000500',
          '3511099000600',
          '3511099000700',
          '3511099000800',
          '3511099009900',
          '3512001009900',
          '3512002000000',
          '3513001000100',
          '3513001000200',
          '3513001000300',
          '3513001009900',
          '3513099000100',
          '3513099000200',
          '3513099000300',
          '3513099009900',
          '3514000000100',
          '3514000009900',
          '3531001010000',
          '3531001020000',
          '3531001990000',
          '3531002000000',
          '3531003000100',
          '3531003009900',
          '3531099000100',
          '3531099009900',
          '3532101010101',
          '3532101010199',
          '3532101010200',
          '3532101019900',
          '3532101020100',
          '3532101020200',
          '3532101029900',
          '3532101990000',
          '3532102010000',
          '3532102020000',
          '3532103000000',
          '3532201010000',
          '3532201020000',
          '3532201030100',
          '3532201030200',
          '3532201040000',
          '3532201050000',
          '3532201060000',
          '3532201990100',
          '3532201990200',
          '3532201990300',
          '3532201990400',
          '3532201990500',
          '3532201990600',
          '3532201990700',
          '3532201999900',
          '3532202010000',
          '3532202990100',
          '3532202999900',
          '3532301000100',
          '3532301000200',
          '3532301000300',
          '3532301000400',
          '3532301000500',
          '3532301009900',
          '3532302000100',
          '3532302000200',
          '3532302000300',
          '3532302000400',
          '3532302000500',
          '3532302009900',
          '3532303000100',
          '3532303000200',
          '3532303000300',
          '3532303000400',
          '3532303000500',
          '3532303000600',
          '3532303000700',
          '3532303000800',
          '3532303000900',
          '3532303001000',
          '3532303009900',
          '3532304000400',
          '3532304000500',
          '3532304009900',
          '3532305000100',
          '3532305000200',
          '3532305000300',
          '3532306010100',
          '3532306010200',
          '3532306010300',
          '3532306010400',
          '3532306020100',
          '3532306020200',
          '3532306020300',
          '3532306020400',
          '3532306020500',
          '3532306030100',
          '3532306030200',
          '3532307000100',
          '3532307000200',
          '3532307000300',
          '3532307000400',
          '3532307000500',
          '3532307000600',
          '3532307009900',
          '3532399000100',
          '3532399000300',
          '3532399000400',
          '3532399009900',
          '3532901000000',
          '3532902000000',
          '3533101000000',
          '3533102000000',
          '3533199000000',
          '3533200010100',
          '3533200010200',
          '3533200019900',
          '3533200990100',
          '3533200990200',
          '3533200999900',
          '3533301000000',
          '3533302000000',
          '3533303000000',
          '3533304000000',
          '3533305000000',
          '3533399000000',
          '3533401000000',
          '3533499000000',
          '3541001010000',
          '3541001020000',
          '3541001030000',
          '3541001040000',
          '3541001990000',
          '3541002010000',
          '3541002020000',
          '3541002030000',
          '3541002040000',
          '3541002050000',
          '3541002060000',
          '3541002990000',
          '3541003000000',
          '3541004000000',
          '3541005010000',
          '3541005020000',
          '3541005030000',
          '3541005040000',
          '3541005990100',
          '3541005999900',
          '3542001010000',
          '3542001990000',
          '3542002010100',
          '3542002019900',
          '3542002990100',
          '3542002999900',
          '3542003000100',
          '3542003009900',
          '3542004000100',
          '3542004009900',
          '3542005000100',
          '3542005009900',
          '3542006000100',
          '3542006009900',
          '3542099020000',
          '3542099990000',
          '3543001010100',
          '3543001019900',
          '3543001020000',
          '3543001990100',
          '3543001990200',
          '3543001999900',
          '3543002010100',
          '3543002019900',
          '3543002020100',
          '3543002029900',
          '3543002990000',
          '3543003000000',
          '3543004010000',
          '3543004990000',
          '3544001990000',
          '3544003000000',
          '3544004000100',
          '3544004000200',
          '3544005990000',
          '3544099010100',
          '3544099010200',
          '3544099010300',
          '3544099019900',
          '3544099999900',
          '3545001010000',
          '3545001990100',
          '3545001990200',
          '3545001999900',
          '3545002000000',
          '3546000000100',
          '3546000009900',
          '3547000000100',
          '3547000000200',
          '3549100000000',
          '3549901000100',
          '3549901000200',
          '3549901009900',
          '3549902010000',
          '3549902990100',
          '3549902990200',
          '3549902990300',
          '3549903010000',
          '3549903020000',
          '3549903030000',
          '3549903040000',
          '3549904010000',
          '3549904020000',
          '3549904030100',
          '3549904039900',
          '3549905010100',
          '3549905010200',
          '3549905019900',
          '3549905990100',
          '3549905999900',
          '3549906010000',
          '3549906020000',
          '3549999010100',
          '3549999019900',
          '3549999020000',
          '3549999030000',
          '3549999040000',
          '3549999050000',
          '3549999060100',
          '3549999069900',
          '3549999070000',
          '3549999080000',
          '3549999090000',
          '3549999100000',
          '3549999110000',
          '3549999120000',
          '3549999130000',
          '3549999140000',
          '3549999150100',
          '3549999159900',
          '3549999990100',
          '3549999990200',
          '3549999990300',
          '3549999990400',
          '3549999990500',
          '3549999990600',
          '3549999999900',
          '3551000010100',
          '3551000010200',
          '3551000010300',
          '3551000010400',
          '3551000019900',
          '3551000020100',
          '3551000020200',
          '3551000020300',
          '3551000020400',
          '3551000029900',
          '3552001000100',
          '3552001000200',
          '3552001009900',
          '3552002000100',
          '3552002000200',
          '3552002000300',
          '3552002009900',
          '3552003010100',
          '3552003010200',
          '3552003010300',
          '3552003990100',
          '3552003990200',
          '3552003990300',
          '3552003999900',
          '3552004000100',
          '3552004000200',
          '3552004009900',
          '3553000010100',
          '3553000010200',
          '3553000010300',
          '3553000010400',
          '3553000019900',
          '3553000020100',
          '3553000029900',
          '3554000010100',
          '3554000019900',
          '3554000020100',
          '3554000029900',
          '3555000010100',
          '3555000010200',
          '3555000010300',
          '3555000020000',
          '3555000990000',
          '3556000000000',
          '3561100000100',
          '3561201000000',
          '3561202000000',
          '3561203000000',
          '3561204000000',
          '3561205000000',
          '3561206000000',
          '3561301000000',
          '3561302000000',
          '3561303000000',
          '3561401000000',
          '3561402000000',
          '3561501000000',
          '3561502000000',
          '3561503000000',
          '3564001000000',
          '3564002000000',
          '3564003000000',
          '3564004000000',
          '3564005000000',
          '3564006000000',
          '3564007000000',
          '3564008000000',
          '3564009000000',
          '3564010000000',
          '3564011000000',
          '3564012000000',
          '3564013000000',
          '3564014000000',
          '3564099000000',
          '3565101010000',
          '3565101020000',
          '3565101030000',
          '3565101040000',
          '3565101990000',
          '3565102010000',
          '3565102020000',
          '3565102030000',
          '3565102990000',
          '3565103000000',
          '3565104000000',
          '3565901010000',
          '3565901020000',
          '3565901030000',
          '3565901040000',
          '3565901990000',
          '3565902010000',
          '3565902020000',
          '3565902030000',
          '3565902990000',
          '3565903000000',
          '3565904000000',
          '3569101000100',
          '3569101009900',
          '3569102000000',
          '3569103000000',
          '3569104010100',
          '3569104010200',
          '3569104019900',
          '3569105010100',
          '3569105010200',
          '3569105019900',
          '3569105990100',
          '3569105990200',
          '3569105999900',
          '3569199009900',
          '3569204000000',
          '3569205000000',
          '3569299020000',
          '3569299990000',
          '3611100000000',
          '3611200000100',
          '3611200000200',
          '3611301000000',
          '3611302000000',
          '3611303000000',
          '3611304000000',
          '3611399000000',
          '3611400010000',
          '3611400020100',
          '3611400020200',
          '3611400020300',
          '3611400020400',
          '3611400029900',
          '3611400030000',
          '3611400040000',
          '3611500000100',
          '3611500009900',
          '3612000000100',
          '3612000000200',
          '3612000000300',
          '3612000009900',
          '3621000000100',
          '3621000009900',
          '3622001010100',
          '3622001019900',
          '3622001020100',
          '3622001029900',
          '3622001990100',
          '3622001990200',
          '3622001999900',
          '3622002000100',
          '3622002000200',
          '3622003010100',
          '3622003010200',
          '3622003019900',
          '3622003020100',
          '3622003020200',
          '3622003020300',
          '3622003029900',
          '3623001010000',
          '3623001020100',
          '3623001029900',
          '3623002010000',
          '3623002020100',
          '3623002029900',
          '3623003010000',
          '3623003020100',
          '3623003029900',
          '3623004010000',
          '3623004020100',
          '3623004029900',
          '3624000010100',
          '3624000010200',
          '3624000010300',
          '3624000010400',
          '3624000019900',
          '3624000020100',
          '3624000020200',
          '3625000000100',
          '3625000000200',
          '3625000000300',
          '3625000000400',
          '3625000000500',
          '3625000009900',
          '3626001010100',
          '3626001010200',
          '3626001019900',
          '3626001999900',
          '3626099000100',
          '3626099000200',
          '3626099009900',
          '3627001020100',
          '3627001020200',
          '3627001029900',
          '3627001990000',
          '3627002010100',
          '3627002019900',
          '3627002020100',
          '3627002029900',
          '3627002030100',
          '3627002049900',
          '3627002050000',
          '3627002060000',
          '3627002070000',
          '3627002080100',
          '3627002089900',
          '3627002090000',
          '3627002109900',
          '3627002990100',
          '3627002990200',
          '3627002999900',
          '3627003010100',
          '3627003019900',
          '3627003020000',
          '3627003030100',
          '3627003039900',
          '3631000010100',
          '3631000019900',
          '3631000020100',
          '3631000029900',
          '3631000030100',
          '3631000039900',
          '3631000040000',
          '3631000050000',
          '3631000990100',
          '3631000999900',
          '3632001010000',
          '3632001020000',
          '3632001990000',
          '3632002010000',
          '3632002020000',
          '3632002030100',
          '3632002030200',
          '3632002039900',
          '3632002990100',
          '3632002999900',
          '3632098010100',
          '3632098010200',
          '3632098019900',
          '3632098020000',
          '3632098990100',
          '3632098990200',
          '3632098999900',
          '3632099010100',
          '3632099010200',
          '3632099019900',
          '3632099020000',
          '3632099030100',
          '3632099030200',
          '3632099030300',
          '3632099030400',
          '3632099030500',
          '3632099030600',
          '3632099039900',
          '3632099040100',
          '3632099040200',
          '3632099049900',
          '3632099990100',
          '3632099999900',
          '3633001010100',
          '3633001010200',
          '3633001019900',
          '3633001020000',
          '3633001990000',
          '3633002010100',
          '3633002019900',
          '3633002999900',
          '3633003000100',
          '3633003009900',
          '3633004010100',
          '3633004019900',
          '3633004020100',
          '3633004029900',
          '3633005010000',
          '3633005990000',
          '3633006010000',
          '3633006020100',
          '3633006029900',
          '3633006030000',
          '3633006990000',
          '3633007010000',
          '3633007020000',
          '3633007990000',
          '3633099010000',
          '3633099020100',
          '3633099020200',
          '3633099030000',
          '3633099040000',
          '3633099990200',
          '3633099999900',
          '3639001010000',
          '3639001020000',
          '3639001030100',
          '3639001039900',
          '3639001040000',
          '3639001990000',
          '3639099010000',
          '3639099020000',
          '3639099030000',
          '3639099040100',
          '3639099040200',
          '3639099040300',
          '3639099040400',
          '3639099990100',
          '3639099990200',
          '3639099999900',
          '3641001010100',
          '3641001010200',
          '3641001019900',
          '3641001990100',
          '3641001990200',
          '3641099010100',
          '3641099010200',
          '3641099010300',
          '3641099019900',
          '3641099990100',
          '3649001010100',
          '3649001010200',
          '3649001010300',
          '3649001019900',
          '3649001990100',
          '3649001990200',
          '3649001990300',
          '3649001999900',
          '3649002000100',
          '3649002000200',
          '3649002000300',
          '3649002000400',
          '3649002000500',
          '3649002000600',
          '3649002000700',
          '3649002009900',
          '3649003010100',
          '3649003010200',
          '3649003010300',
          '3649003990100',
          '3649003990200',
          '3649003999900',
          '3649099000100',
          '3649099000200',
          '3649099000300',
          '3649099000400',
          '3649099000500',
          '3649099000600',
          '3649099009900',
          '3691000000100',
          '3691000009900',
          '3692000000100',
          '3692000000200',
          '3692000000300',
          '3692000009900',
          '3693001000000',
          '3693002000100',
          '3693002009900',
          '3693003010000',
          '3693003990000',
          '3693004000000',
          '3693099000000',
          '3694000010100',
          '3694000010200',
          '3694000010300',
          '3694000010400',
          '3694000010500',
          '3694000010600',
          '3694000010700',
          '3694000990100',
          '3694000990200',
          '3694000990500',
          '3694000990600',
          '3694000999900',
          '3695001000100',
          '3695001009900',
          '3695002000000',
          '3695003000000',
          '3695099010000',
          '3695099020000',
          '3695099030000',
          '3695099040000',
          '3695099990000',
          '3696000000100',
          '3696000009900',
          '3697100010100',
          '3697100010200',
          '3697100010300',
          '3697100019900',
          '3697100020100',
          '3697100020200',
          '3697100020300',
          '3697100029900',
          '3697200000000',
          '3698000000000',
          '3699001000000',
          '3699002000200',
          '3699002000300',
          '3699002009900',
          '3699003000000',
          '3699004000000',
          '3699005000100',
          '3699005009900',
          '3699006000100',
          '3699006000200',
          '3699006009900',
          '3699007019900',
          '3699007990100',
          '3699008000100',
          '3699008009900',
          '3699009000100',
          '3699009000200',
          '3699009009900',
          '3699010000100',
          '3699010000200',
          '3699010000300',
          '3699010009900',
          '3699011000100',
          '3699011000200',
          '3699011000300',
          '3699011009900',
          '3699012000100',
          '3699012000200',
          '3699012000300',
          '3699012000400',
          '3699012000500',
          '3699012000600',
          '3699012000700',
          '3699012000800',
          '3699012000900',
          '3699012001000',
          '3699012001100',
          '3699012001200',
          '3699012001300',
          '3699012001400',
          '3699012001500',
          '3699012001600',
          '3699012001700',
          '3699012001800',
          '3699012001900',
          '3699012002000',
          '3699012002100',
          '3699012009900',
          '3699099010000',
          '3699099020000',
          '3699099030000',
          '3699099040000',
          '3699099050000',
          '3699099060000',
          '3699099990100',
          '3699099990200',
          '3699099990300',
          '3699099990400',
          '3699099999900',
          '3711100010100',
          '3711100019900',
          '3711100990100',
          '3711100990200',
          '3711100990300',
          '3711100999900',
          '3711201010100',
          '3711201019900',
          '3711201020000',
          '3711202000000',
          '3711203000100',
          '3711203009900',
          '3711300010100',
          '3711300010200',
          '3711300010300',
          '3711300010400',
          '3711300019900',
          '3711300020000',
          '3711400000100',
          '3711400000200',
          '3711501010100',
          '3711501019900',
          '3711501990100',
          '3711501999900',
          '3711502010000',
          '3711502990100',
          '3711502999900',
          '3711601010100',
          '3711601019900',
          '3711601990100',
          '3711601990200',
          '3711602000000',
          '3711700000000',
          '3712101000100',
          '3712101009900',
          '3712199000000',
          '3712901000000',
          '3712902000000',
          '3712999000000',
          '3719101000100',
          '3719101009900',
          '3719102000100',
          '3719102009900',
          '3719103000000',
          '3719104000000',
          '3719105000000',
          '3719200000100',
          '3719200000200',
          '3719200000300',
          '3719301010100',
          '3719301010200',
          '3719301019900',
          '3719301020100',
          '3719301020200',
          '3719301020300',
          '3719301029900',
          '3719399010000',
          '3719399020100',
          '3719399020200',
          '3719399030100',
          '3719399030200',
          '3719401000000',
          '3719402000000',
          '3719403000000',
          '3719501000100',
          '3719501009900',
          '3719600000000',
          '3719700000100',
          '3719700009900',
          '3719901010000',
          '3719901020000',
          '3719902000000',
          '3719903010000',
          '3719903020000',
          '3719903030000',
          '3719903040000',
          '3719904010000',
          '3719904020000',
          '3719904030000',
          '3719904990000',
          '3719999010000',
          '3719999020000',
          '3719999990000',
          '3721001000100',
          '3721001000200',
          '3721001000300',
          '3721001000400',
          '3721002000100',
          '3721002000200',
          '3721003000100',
          '3721003000200',
          '3722101010100',
          '3722101010200',
          '3722101010300',
          '3722101010400',
          '3722101010500',
          '3722101010600',
          '3722101010700',
          '3722101019900',
          '3722101020100',
          '3722101020200',
          '3722101020300',
          '3722101020400',
          '3722101020500',
          '3722101020600',
          '3722101020700',
          '3722101029900',
          '3722101990100',
          '3722101990200',
          '3722101990300',
          '3722101990400',
          '3722101990500',
          '3722101990600',
          '3722101990700',
          '3722101999900',
          '3722199010000',
          '3722199020000',
          '3722199990000',
          '3722200010000',
          '3722200020000',
          '3722200990000',
          '3729101010100',
          '3729101010200',
          '3729101019900',
          '3729101020000',
          '3729101990000',
          '3729102000000',
          '3729103000000',
          '3729201000100',
          '3729201009900',
          '3729299000000',
          '3729900010100',
          '3729900010200',
          '3729900010300',
          '3729900019900',
          '3729900020100',
          '3729900020200',
          '3729900020300',
          '3729900029900',
          '3729900990100',
          '3729900990200',
          '3729900990300',
          '3729900999900',
          '3731000000100',
          '3731000000200',
          '3731000000300',
          '3731000009900',
          '3732000000100',
          '3732000000200',
          '3732000000300',
          '3732000009900',
          '3733000000000',
          '3734001000000',
          '3734099000000',
          '3735001000100',
          '3735001009900',
          '3735002000100',
          '3735002009900',
          '3735003000100',
          '3735003009900',
          '3735004000100',
          '3735004009900',
          '3736000000100',
          '3736000009900',
          '3737000000100',
          '3737000000200',
          '3737000009900',
          '3741000000000',
          '3742001000000',
          '3742002000100',
          '3742002009900',
          '3742003000000',
          '3743000000000',
          '3744001000100',
          '3744001009900',
          '3744099000100',
          '3744099009900',
          '3745000000100',
          '3745000000200',
          '3751000000000',
          '3752000000000',
          '3753001000100',
          '3753001009900',
          '3753099000000',
          '3754000000100',
          '3754000000200',
          '3754000000300',
          '3754000009900',
          '3755000000100',
          '3755000009900',
          '3756000000000',
          '3757001010100',
          '3757001019900',
          '3757001020100',
          '3757001020200',
          '3757001029900',
          '3757001030100',
          '3757001039900',
          '3757001990100',
          '3757001999900',
          '3757099010100',
          '3757099019900',
          '3757099020100',
          '3757099020200',
          '3757099029900',
          '3757099030100',
          '3757099039900',
          '3757099990100',
          '3757099999900',
          '3761001010000',
          '3761001020000',
          '3761099010100',
          '3761099010200',
          '3761099020100',
          '3761099020200',
          '3769001000000',
          '3769002010100',
          '3769002019900',
          '3769002020000',
          '3769002030100',
          '3769002039900',
          '3769002990100',
          '3769002999900',
          '3769003000000',
          '3791001010000',
          '3791001020100',
          '3791001020200',
          '3791001029900',
          '3791001039900',
          '3791002010000',
          '3791002020100',
          '3791002020200',
          '3791002029900',
          '3791002990100',
          '3791002999900',
          '3792000010100',
          '3792000010200',
          '3792000010300',
          '3792000010400',
          '3792000010500',
          '3792000010600',
          '3792000010700',
          '3792000019900',
          '3792000020100',
          '3792000029900',
          '3792000990100',
          '3792000990200',
          '3792000990300',
          '3792000990400',
          '3792000990500',
          '3792000990600',
          '3792000990700',
          '3792000990800',
          '3793000000100',
          '3793000000200',
          '3793000009900',
          '3794000990100',
          '3794000990200',
          '3794000999900',
          '3795001000000',
          '3795002000000',
          '3795003000100',
          '3795003009900',
          '3796000000000',
          '3799001010100',
          '3799001019900',
          '3799001020100',
          '3799001029900',
          '3799001990000',
          '3799002000100',
          '3799002009900',
          '3799003000000',
          '3799004000000',
          '3799099000000',
          '3811100010100',
          '3811100010200',
          '3811100010300',
          '3811100010400',
          '3811100020100',
          '3811100020200',
          '3811100020300',
          '3811100029900',
          '3811100030100',
          '3811100039900',
          '3811200010000',
          '3811200020000',
          '3811200030000',
          '3811200990100',
          '3811200990200',
          '3811200990300',
          '3811900000100',
          '3811900009900',
          '3812100000100',
          '3812100000200',
          '3812100000300',
          '3812100009900',
          '3812200000100',
          '3812200000200',
          '3812200000300',
          '3812200009900',
          '3813000000000',
          '3814001010100',
          '3814001010200',
          '3814001010300',
          '3814001019900',
          '3814001020101',
          '3814001020102',
          '3814001020103',
          '3814001020104',
          '3814001020105',
          '3814001020199',
          '3814001029901',
          '3814001029902',
          '3814001029903',
          '3814001029904',
          '3814001029999',
          '3814001990100',
          '3814001990200',
          '3814001990300',
          '3814001990400',
          '3814001999900',
          '3814099010000',
          '3814099990000',
          '3815001000000',
          '3815002000100',
          '3815002009900',
          '3816000000100',
          '3816000000200',
          '3816000000300',
          '3816000000400',
          '3816000000500',
          '3816000009900',
          '3821001000000',
          '3821002000000',
          '3822001000000',
          '3822002000000',
          '3822003000000',
          '3822004000000',
          '3822099000000',
          '3823001000000',
          '3823002000000',
          '3823099000000',
          '3824001010100',
          '3824001019900',
          '3824001020000',
          '3824002010100',
          '3824002019900',
          '3824002020000',
          '3824003010000',
          '3824003990000',
          '3824004010000',
          '3824004990000',
          '3825000000100',
          '3825000009900',
          '3831001010000',
          '3831001020000',
          '3831099000000',
          '3832001000000',
          '3832002000000',
          '3832003000000',
          '3832099010000',
          '3832099990000',
          '3833001000000',
          '3833002000000',
          '3833003000000',
          '3833004000000',
          '3833099000000',
          '3834001000000',
          '3834099000000',
          '3835001000000',
          '3835099010000',
          '3835099990000',
          '3836000000100',
          '3836000000200',
          '3836000000300',
          '3836000000400',
          '3836000000500',
          '3836000000600',
          '3836000000700',
          '3836000009900',
          '3841000000100',
          '3841000009900',
          '3842000000100',
          '3842000000200',
          '3842000000300',
          '3842000000400',
          '3842000009900',
          '3843000000000',
          '3844000010100',
          '3844000010200',
          '3844000019900',
          '3844000020100',
          '3844000020200',
          '3844000020300',
          '3844000020400',
          '3844000029900',
          '3844000030100',
          '3844000039900',
          '3844000040000',
          '3844000050100',
          '3844000050200',
          '3844000060000',
          '3844000990000',
          '3845000000100',
          '3845000000200',
          '3845000000300',
          '3845000000400',
          '3845000000500',
          '3845000009900',
          '3851000000100',
          '3851000000200',
          '3851000000300',
          '3851000000400',
          '3851000009900',
          '3852001000000',
          '3852002000000',
          '3853000000000',
          '3854001000000',
          '3854002000000',
          '3854003000000',
          '3854004000000',
          '3854005000000',
          '3855000000000',
          '3856000000100',
          '3856000000200',
          '3856000000300',
          '3856000000400',
          '3856000009900',
          '3857000000000',
          '3858100000000',
          '3858200000000',
          '3859001000000',
          '3859002000000',
          '3859003000000',
          '3859099000000',
          '3860000000000',
          '3870101000000',
          '3870102000000',
          '3870199000000',
          '3870201000000',
          '3870202000000',
          '3870299000000',
          '3870301000000',
          '3870302000000',
          '3870399000000',
          '3870401000000',
          '3870402000000',
          '3870499000000',
          '3891101000200',
          '3891102000000',
          '3891103000000',
          '3891104000200',
          '3891104009900',
          '3891105000000',
          '3891106000000',
          '3891108000000',
          '3891109000000',
          '3891110019900',
          '3891110020000',
          '3891111000100',
          '3891199000000',
          '3891201000100',
          '3891201000200',
          '3891201000300',
          '3891202010000',
          '3891202020000',
          '3891202030100',
          '3891202030200',
          '3891202030300',
          '3891202990100',
          '3891202999900',
          '3892100010100',
          '3892100019900',
          '3892100020000',
          '3892100030000',
          '3892200000100',
          '3892200000200',
          '3892200000300',
          '3892300010000',
          '3892300020100',
          '3892300020200',
          '3892300029900',
          '3892300030100',
          '3892300039900',
          '3892400000100',
          '3892400000200',
          '3893000000000',
          '3894100000000',
          '3894200000000',
          '3895000000000',
          '3896101010100',
          '3896101019900',
          '3896101020100',
          '3896101029900',
          '3896102000000',
          '3896103000000',
          '3896200000000',
          '3896301000000',
          '3896302000000',
          '3897101000000',
          '3897102000000',
          '3897200010100',
          '3897200010200',
          '3897200010300',
          '3897200020000',
          '3897200030000',
          '3899100000100',
          '3899100000200',
          '3899100009900',
          '3899200010100',
          '3899200019900',
          '3899200020100',
          '3899200020200',
          '3899200020300',
          '3899200020400',
          '3899200029900',
          '3899301019900',
          '3899301029900',
          '3899301030000',
          '3899301040000',
          '3899301990100',
          '3899301999900',
          '3899302020100',
          '3899302020200',
          '3899302029900',
          '3899302990100',
          '3899302999900',
          '3899303010000',
          '3899303020000',
          '3899303030000',
          '3899304010000',
          '3899304020000',
          '3899304030000',
          '3899304990000',
          '3899305000100',
          '3899305000200',
          '3899399010000',
          '3899399020000',
          '3899399030000',
          '3899399040000',
          '3899399050000',
          '3899399990000',
          '3899401010100',
          '3899401010200',
          '3899401020100',
          '3899401020200',
          '3899401029900',
          '3899402000100',
          '3899402000200',
          '3899403010100',
          '3899403019900',
          '3899403020000',
          '3899403030000',
          '3899403040000',
          '3899500000100',
          '3899500000200',
          '3899500009900',
          '3899600000000',
          '3899701000100',
          '3899701009900',
          '3899799010100',
          '3899799010200',
          '3899799990000',
          '3899901010100',
          '3899901019900',
          '3899901990100',
          '3899901999900',
          '3899902000000',
          '3899903010000',
          '3899903990000',
          '3899904000000',
          '3899905010100',
          '3899905019900',
          '3899905990100',
          '3899905999900',
          '3899906010000',
          '3899906990000',
          '3899907010000',
          '3899907020000',
          '3899907990100',
          '3899907990200',
          '3899907999900',
          '3899908000000',
          '3899909000000',
          '3899910000000',
          '3899911010000',
          '3899911020000',
          '3911001010100',
          '3911001019900',
          '3911001020000',
          '3911001990000',
          '3911002000100',
          '3911002000200',
          '3911002009900',
          '3911003000100',
          '3911003009900',
          '3911099000100',
          '3911099000200',
          '3911099000300',
          '3911099000400',
          '3911099000500',
          '3911099009900',
          '3912001020000',
          '3912002000100',
          '3912002000200',
          '3912002009900',
          '3913000000100',
          '3913000009900',
          '3914100000000',
          '3914900000000',
          '3915000000100',
          '3915000000200',
          '3916000000000',
          '3917000000000',
          '3918000000000',
          '3921100000000',
          '3921200000100',
          '3921200000200',
          '3921300000000',
          '3921400000000',
          '3921500000000',
          '3921600000100',
          '3921600000200',
          '3921800000000',
          '3922000000000',
          '3923000000000',
          '3924000000000',
          '3925000000000',
          '3926000000100',
          '3926000009900',
          '3927000000100',
          '3927000000200',
          '3927000000300',
          '3927000009900',
          '3928100000000',
          '3928200000000',
          '3928300000000',
          '3929000000000',
          '3931000000000',
          '3932000000000',
          '3933100000000',
          '3933200000000',
          '3933300000000',
          '3934000000000',
          '3935000000000',
          '3936100000000',
          '3936200000000',
          '3936300000000',
          '3936400000000',
          '3936500000000',
          '3936600000000',
          '3936700000000',
          '3936800000000',
          '3937000000000',
          '3938000000000',
          '3991000000000',
          '3992000000000',
          '3993100000000',
          '3994000000000',
          '3995000000000',
          '3999000000000',
          '4111100000000',
          '4111200000000',
          '4111300000000',
          '4111400000000',
          '4111501000000',
          '4111502000000',
          '4111503000000',
          '4111504000000',
          '4111505000000',
          '4111506000000',
          '4111507000000',
          '4111508000000',
          '4111599000000',
          '4111600000000',
          '4111701000000',
          '4111702000000',
          '4112101010000',
          '4112101990000',
          '4112102010000',
          '4112102990000',
          '4112201010000',
          '4112201020000',
          '4112202010000',
          '4112202020000',
          '4121101010000',
          '4121101020000',
          '4121102010100',
          '4121102010200',
          '4121102010300',
          '4121102020000',
          '4121199010100',
          '4121199010200',
          '4121199010300',
          '4121199010400',
          '4121199020100',
          '4121199020200',
          '4121199020300',
          '4121199020400',
          '4121200010000',
          '4121200020000',
          '4121301010100',
          '4121301010200',
          '4121301010300',
          '4121301010400',
          '4121301020100',
          '4121301020200',
          '4121301020300',
          '4121301020400',
          '4121399010000',
          '4121399020000',
          '4121401000100',
          '4121401000200',
          '4121499000000',
          '4122100010100',
          '4122100010200',
          '4122100010300',
          '4122100010400',
          '4122100020100',
          '4122100020200',
          '4122100020300',
          '4122100020400',
          '4122200000100',
          '4122200000200',
          '4122301000100',
          '4122301000200',
          '4122301000300',
          '4122301000400',
          '4122301000500',
          '4122399000000',
          '4122401000000',
          '4122402000000',
          '4123101010100',
          '4123101010200',
          '4123101020000',
          '4123101030000',
          '4123101040100',
          '4123101049900',
          '4123101050100',
          '4123101059900',
          '4123101060000',
          '4123101070100',
          '4123101079900',
          '4123101080000',
          '4123101090000',
          '4123101990100',
          '4123101990200',
          '4123101999900',
          '4123102010000',
          '4123102020000',
          '4123102030100',
          '4123102039900',
          '4123102040100',
          '4123102049900',
          '4123102050000',
          '4123102060000',
          '4123102990000',
          '4123200000100',
          '4123200000200',
          '4123301000100',
          '4123301009900',
          '4123302000100',
          '4123302009900',
          '4123400000000',
          '4123901000100',
          '4123901000200',
          '4123901009900',
          '4123902000100',
          '4123902009900',
          '4124101000000',
          '4124199010100',
          '4124199010200',
          '4124199019900',
          '4124199020000',
          '4124199990000',
          '4124201000100',
          '4124201009900',
          '4124299010000',
          '4124299020100',
          '4124299020200',
          '4124299029900',
          '4124299030000',
          '4124299040000',
          '4124299050100',
          '4124299059900',
          '4124299990100',
          '4124299999900',
          '4124301000000',
          '4124399000100',
          '4124399000200',
          '4124399009900',
          '4124401000100',
          '4124401009900',
          '4124402010000',
          '4124402020100',
          '4124402029900',
          '4125101010000',
          '4125101020000',
          '4125101030000',
          '4125102010000',
          '4125102020000',
          '4125199010000',
          '4125199990000',
          '4125201000000',
          '4125202000000',
          '4125301000000',
          '4125302000000',
          '4125303000000',
          '4125399000000',
          '4126101000000',
          '4126102010100',
          '4126102019900',
          '4126102020100',
          '4126102029900',
          '4126201010100',
          '4126201019900',
          '4126201020000',
          '4126202010100',
          '4126202019900',
          '4126202020000',
          '4126300010000',
          '4126300020100',
          '4126300020200',
          '4126300020300',
          '4126300029900',
          '4126300030000',
          '4126300990100',
          '4126300990200',
          '4126300999900',
          '4126401000000',
          '4126499000100',
          '4126499009900',
          '4126501000000',
          '4126599000100',
          '4126599009900',
          '4126601000000',
          '4126699000000',
          '4126701000000',
          '4126702000000',
          '4126703000000',
          '4126799000000',
          '4127100000000',
          '4127200000000',
          '4127300000000',
          '4128101000000',
          '4128199000000',
          '4128201000100',
          '4128201009900',
          '4128299000100',
          '4128299009900',
          '4128301010100',
          '4128301010200',
          '4128301019900',
          '4128301990100',
          '4128301990200',
          '4128301999900',
          '4128302010000',
          '4128302990000',
          '4128303010100',
          '4128303010200',
          '4128303019900',
          '4128303990100',
          '4128303990200',
          '4128303990300',
          '4128303999900',
          '4128400000100',
          '4128400000200',
          '4128400009900',
          '4128501000000',
          '4128599010000',
          '4128599990000',
          '4128601000000',
          '4128699000000',
          '4128701010000',
          '4128701990000',
          '4128702010100',
          '4128702010200',
          '4128702010300',
          '4128702010400',
          '4128702019900',
          '4128702990100',
          '4128702990200',
          '4128702990300',
          '4128702999900',
          '4128703010100',
          '4128703010200',
          '4128703010300',
          '4128703019900',
          '4128703990100',
          '4128703990200',
          '4128703990300',
          '4128703999900',
          '4128800010100',
          '4128800019900',
          '4128800990000',
          '4128900000100',
          '4128900000200',
          '4128900000300',
          '4128900009900',
          '4129101000100',
          '4129101000200',
          '4129102000000',
          '4129200010000',
          '4129200020100',
          '4129200029900',
          '4129301000100',
          '4129301000200',
          '4129301000300',
          '4129301000400',
          '4129301000500',
          '4129301009900',
          '4129399000100',
          '4129399000200',
          '4129399000300',
          '4129399000400',
          '4129399009900',
          '4131000010000',
          '4131000020000',
          '4131000030100',
          '4131000039900',
          '4132000010000',
          '4132000020000',
          '4132000030000',
          '4132000040000',
          '4133001010000',
          '4133001020000',
          '4133001030000',
          '4133002010000',
          '4133002020000',
          '4133002030000',
          '4133003010000',
          '4133003020000',
          '4133003030000',
          '4133004010000',
          '4133004020000',
          '4133004030000',
          '4134000000000',
          '4135001010000',
          '4135001020000',
          '4135002010000',
          '4135002020000',
          '4141101000000',
          '4141102000000',
          '4141201000000',
          '4141202000000',
          '4141301000100',
          '4141301000200',
          '4141301000300',
          '4141301009900',
          '4141302000100',
          '4141302000200',
          '4141302000300',
          '4141302009900',
          '4141303000000',
          '4142101000000',
          '4142102000000',
          '4142201000000',
          '4142202000000',
          '4143101000000',
          '4143102000000',
          '4143200000000',
          '4144101000000',
          '4144199000100',
          '4144199009900',
          '4144201000000',
          '4144202000000',
          '4144301000000',
          '4144302000000',
          '4151101000100',
          '4151101000200',
          '4151102000000',
          '4151201000000',
          '4151202000100',
          '4151202000200',
          '4151202009900',
          '4151301000100',
          '4151301000200',
          '4151301009900',
          '4151302000100',
          '4151302000200',
          '4151302009900',
          '4151401000100',
          '4151401009900',
          '4151402010100',
          '4151402019900',
          '4151402020100',
          '4151402029900',
          '4151402030000',
          '4151402990000',
          '4151501000100',
          '4151501000200',
          '4151502000100',
          '4151502000200',
          '4151601010000',
          '4151601020000',
          '4151601030000',
          '4151602010100',
          '4151602010200',
          '4151602019900',
          '4151602020000',
          '4152100000000',
          '4152201010000',
          '4152201020000',
          '4152202010000',
          '4152202020000',
          '4152301000000',
          '4152302000000',
          '4152401010000',
          '4152401020000',
          '4152402010000',
          '4152402020000',
          '4153101000100',
          '4153101000200',
          '4153102000000',
          '4153201010000',
          '4153201020000',
          '4153202010100',
          '4153202019900',
          '4153202020000',
          '4153301000100',
          '4153301009900',
          '4153302000100',
          '4153302009900',
          '4153401010000',
          '4153401990100',
          '4153401999900',
          '4153402010000',
          '4153402990000',
          '4153501010000',
          '4153501990100',
          '4153501999900',
          '4153502010100',
          '4153502019900',
          '4153502990100',
          '4153502999900',
          '4153601010100',
          '4153601019900',
          '4153601020000',
          '4153602010000',
          '4153602020000',
          '4154201000000',
          '4154202000000',
          '4154401000100',
          '4154401009900',
          '4154402000000',
          '4154501000000',
          '4154502000000',
          '4154700000000',
          '4160101010000',
          '4160101020000',
          '4160102010000',
          '4160102020000',
          '4160103000000',
          '4160104000000',
          '4160105000000',
          '4160106000000',
          '4160107000000',
          '4160108000000',
          '4160109000000',
          '4160110000000',
          '4160199010000',
          '4160199020000',
          '4160201010000',
          '4160201990000',
          '4160202010000',
          '4160202020000',
          '4160202990000',
          '4160203000000',
          '4160204010000',
          '4160204020000',
          '4160204990000',
          '4160205000000',
          '4160206000000',
          '4160207000000',
          '4160208000000',
          '4160209000000',
          '4160210000000',
          '4160299000000',
          '4160301000100',
          '4160301000200',
          '4160302000100',
          '4160302000200',
          '4160303000100',
          '4160303000200',
          '4160304000100',
          '4160304000200',
          '4160400000100',
          '4160400000200',
          '4211000000100',
          '4211000000200',
          '4212001000100',
          '4212001000200',
          '4212002000100',
          '4212002000200',
          '4219001010100',
          '4219001010200',
          '4219001010300',
          '4219001010400',
          '4219001019900',
          '4219001020000',
          '4219002000100',
          '4219002000200',
          '4219002000300',
          '4219002009900',
          '4221001010000',
          '4221001020000',
          '4221001990000',
          '4221002000000',
          '4222001000100',
          '4222001009900',
          '4222002000000',
          '4232001010000',
          '4232001020100',
          '4232001029900',
          '4232002000000',
          '4233001000000',
          '4233002000000',
          '4234201000000',
          '4234202000000',
          '4291101010000',
          '4291101020100',
          '4291101020200',
          '4291101029900',
          '4291101030100',
          '4291101039900',
          '4291101990000',
          '4291102010000',
          '4291102020100',
          '4291102029900',
          '4291103010000',
          '4291103020100',
          '4291103029900',
          '4291201010100',
          '4291201010200',
          '4291201019900',
          '4291201990100',
          '4291201990200',
          '4291201990300',
          '4291201990400',
          '4291201999900',
          '4291202000100',
          '4291202000200',
          '4291202000300',
          '4291202000400',
          '4291202009900',
          '4291203000100',
          '4291203000200',
          '4291203000300',
          '4291203000400',
          '4291203009900',
          '4291204000100',
          '4291204009900',
          '4291301010000',
          '4291301020100',
          '4291301020200',
          '4291301029900',
          '4291301990000',
          '4291302010100',
          '4291302010200',
          '4291302019900',
          '4291302020100',
          '4291302020200',
          '4291302990000',
          '4291303990000',
          '4291303020100',
          '4291303029900',
          '4291401000000',
          '4291402000100',
          '4291402009900',
          '4291403000000',
          '4291501009900',
          '4291502000000',
          '4291503000100',
          '4291503000200',
          '4291599000000',
          '4291600000000',
          '4292101010000',
          '4292101020000',
          '4292101030100',
          '4292101030200',
          '4292101030300',
          '4292101030400',
          '4292101030500',
          '4292101039900',
          '4292101040000',
          '4292101050000',
          '4292101990100',
          '4292101990200',
          '4292101990300',
          '4292101990400',
          '4292101999900',
          '4292102010100',
          '4292102019900',
          '4292102020100',
          '4292102020200',
          '4292102020300',
          '4292102029900',
          '4292102030000',
          '4292103010100',
          '4292103010200',
          '4292103010300',
          '4292103020100',
          '4292103020200',
          '4292103020300',
          '4292103029900',
          '4292103030000',
          '4292103040100',
          '4292103049900',
          '4292104010100',
          '4292104010200',
          '4292104010300',
          '4292104020000',
          '4292199010000',
          '4292199020000',
          '4292199030000',
          '4292199990100',
          '4292199990200',
          '4292199990300',
          '4292199990400',
          '4292199990500',
          '4292199990600',
          '4292199990700',
          '4292199990800',
          '4292199990900',
          '4292199991000',
          '4292199991100',
          '4292199991200',
          '4292199991300',
          '4292199999900',
          '4292201010000',
          '4292201020000',
          '4292201030100',
          '4292201030200',
          '4292201030300',
          '4292201039900',
          '4292201040000',
          '4292201050000',
          '4292201060000',
          '4292201070000',
          '4292201080000',
          '4292201990000',
          '4292202010000',
          '4292202020000',
          '4292202030000',
          '4292202040100',
          '4292202049900',
          '4292202990000',
          '4292203000000',
          '4293101000100',
          '4293101000200',
          '4293101000300',
          '4293101000400',
          '4293101000500',
          '4293101009900',
          '4293102010100',
          '4293102019900',
          '4293102020100',
          '4293102020200',
          '4293102990100',
          '4293102990200',
          '4293102990300',
          '4293102999900',
          '4293201000100',
          '4293201000200',
          '4293201000300',
          '4293201000400',
          '4293201000500',
          '4293201009900',
          '4293299000100',
          '4293299009900',
          '4294100000100',
          '4294100000200',
          '4294100000300',
          '4294100000400',
          '4294100000500',
          '4294100000600',
          '4294100000700',
          '4294100009900',
          '4294201000100',
          '4294201000200',
          '4294201009900',
          '4294202010100',
          '4294202019900',
          '4294202020100',
          '4294202029900',
          '4294301010100',
          '4294301019900',
          '4294301990100',
          '4294301999900',
          '4294302000000',
          '4294303010100',
          '4294303010200',
          '4294303019900',
          '4294303990100',
          '4294303990200',
          '4294303999900',
          '4294401010100',
          '4294401010200',
          '4294401010300',
          '4294401019900',
          '4294401020100',
          '4294401020200',
          '4294401029900',
          '4294401030100',
          '4294401030200',
          '4294401030300',
          '4294401039900',
          '4294401040100',
          '4294401049900',
          '4294401050100',
          '4294401050200',
          '4294401059900',
          '4294401060100',
          '4294401060200',
          '4294401060300',
          '4294401069900',
          '4294401070100',
          '4294401079900',
          '4294401080100',
          '4294401080200',
          '4294401089900',
          '4294402010000',
          '4294402020100',
          '4294402020200',
          '4294402020300',
          '4294402029900',
          '4294403010000',
          '4294403020000',
          '4294403990000',
          '4294501000000',
          '4294502000100',
          '4294502009900',
          '4294599000000',
          '4294601010000',
          '4294601990100',
          '4294601999900',
          '4294602000000',
          '4295001010000',
          '4295001990000',
          '4295099010000',
          '4295099020000',
          '4295099030000',
          '4295099990000',
          '4299101010100',
          '4299101010200',
          '4299101019900',
          '4299101020000',
          '4299102010000',
          '4299102020000',
          '4299201010000',
          '4299201020100',
          '4299201020200',
          '4299201020300',
          '4299201020400',
          '4299201020500',
          '4299201029900',
          '4299201030000',
          '4299202000000',
          '4299203010100',
          '4299203010200',
          '4299203019900',
          '4299203020100',
          '4299203029900',
          '4299203030100',
          '4299203030200',
          '4299203039900',
          '4299203040000',
          '4299203050000',
          '4299203060000',
          '4299203070100',
          '4299203079900',
          '4299203080000',
          '4299203090000',
          '4299203100000',
          '4299203990100',
          '4299203990200',
          '4299203999900',
          '4299300000000',
          '4299400000000',
          '4299501000000',
          '4299502000100',
          '4299502000200',
          '4299502009900',
          '4299503000000',
          '4299599000000',
          '4299601000000',
          '4299602000000',
          '4299603000000',
          '4299701010100',
          '4299701010200',
          '4299701019900',
          '4299701020100',
          '4299701029900',
          '4299702010000',
          '4299702020000',
          '4299702030000',
          '4299702990000',
          '4299800000100',
          '4299800009900',
          '4299901010100',
          '4299901010200',
          '4299901019900',
          '4299901020100',
          '4299901029900',
          '4299901990100',
          '4299901990200',
          '4299901990300',
          '4299901990400',
          '4299901990500',
          '4299901990600',
          '4299901990700',
          '4299901990800',
          '4299901990900',
          '4299901999900',
          '4299902010100',
          '4299902019900',
          '4299902020100',
          '4299902020200',
          '4299902029900',
          '4299902030100',
          '4299902039900',
          '4299902990100',
          '4299902990200',
          '4299902990300',
          '4299902990400',
          '4299902990500',
          '4299902990600',
          '4299902990700',
          '4299902990800',
          '4299902990900',
          '4299902991000',
          '4299902991100',
          '4299902991200',
          '4299902991300',
          '4299902991400',
          '4299902991500',
          '4299902991600',
          '4299902999900',
          '4299903010100',
          '4299903019900',
          '4299903990100',
          '4299903990200',
          '4299903990300',
          '4299903990400',
          '4299903990500',
          '4299903999900',
          '4299904010000',
          '4299904990000',
          '4299905010000',
          '4299905990100',
          '4299905990200',
          '4299905990300',
          '4299905990400',
          '4299905990500',
          '4299905990600',
          '4299905990700',
          '4299905990800',
          '4299905990900',
          '4299905991000',
          '4299905991100',
          '4299905991200',
          '4299905991300',
          '4299905999900',
          '4299906000100',
          '4299906000200',
          '4299906000300',
          '4299906009900',
          '4299907000100',
          '4299907000200',
          '4299907009900',
          '4299908000000',
          '4299999010100',
          '4299999019900',
          '4299999990100',
          '4299999990200',
          '4299999999900',
          '4311001010100',
          '4311001019900',
          '4311001990000',
          '4311002010000',
          '4311002990000',
          '4312101000000',
          '4312102000000',
          '4312103000000',
          '4312200000000',
          '4312300000000',
          '4313100000000',
          '4313201000000',
          '4313202000100',
          '4313202000200',
          '4313300000000',
          '4313400000000',
          '4314101000000',
          '4314199000100',
          '4314199000200',
          '4314200000000',
          '4314300000000',
          '4315100000000',
          '4315200000000',
          '4315300000000',
          '4315400000000',
          '4315500000000',
          '4315600000000',
          '4321101000000',
          '4321102000000',
          '4321901000000',
          '4321902000000',
          '4321903000000',
          '4321999000000',
          '4322001010100',
          '4322001019900',
          '4322001020100',
          '4322001020200',
          '4322001020300',
          '4322001020400',
          '4322001020500',
          '4322001020600',
          '4322001029900',
          '4322002000000',
          '4323001010000',
          '4323001029900',
          '4323002000100',
          '4323002000200',
          '4323002009900',
          '4324001010100',
          '4324001019900',
          '4324001020100',
          '4324001029900',
          '4324001030100',
          '4324001039900',
          '4324001040100',
          '4324001049900',
          '4324001050000',
          '4324001990000',
          '4324002010000',
          '4324002020000',
          '4324002030100',
          '4324002039900',
          '4324002990000',
          '4325100000000',
          '4325201000000',
          '4325202000000',
          '4325301000000',
          '4325302000000',
          '4325399000000',
          '4325401010000',
          '4325401020000',
          '4325401990000',
          '4325499990000',
          '4331001000000',
          '4331002000100',
          '4331002000200',
          '4331002000300',
          '4331003000000',
          '4331099000000',
          '4332001000100',
          '4332001009900',
          '4332002000000',
          '4332003010100',
          '4332003019900',
          '4332003020000',
          '4332004000000',
          '4332005000000',
          '4332006000000',
          '4332007000000',
          '4332008000100',
          '4332008000200',
          '4332008000300',
          '4332008009900',
          '4332009000000',
          '4333101000000',
          '4333199000000',
          '4333201000000',
          '4333202000000',
          '4341001000100',
          '4341001000200',
          '4341002000100',
          '4341002009900',
          '4342001000100',
          '4342001009900',
          '4342002010100',
          '4342002010200',
          '4342002019900',
          '4342002020100',
          '4342002029900',
          '4343001000000',
          '4343002000000',
          '4343003010000',
          '4343003020000',
          '4351001010000',
          '4351001020100',
          '4351001029900',
          '4351002010000',
          '4351002020000',
          '4351003010100',
          '4351003019900',
          '4351003020000',
          '4352001010000',
          '4352001020000',
          '4352001990000',
          '4352002010000',
          '4352002020000',
          '4352099010100',
          '4352099019900',
          '4352099990100',
          '4352099999900',
          '4353001010100',
          '4353001019900',
          '4353001990000',
          '4353002000100',
          '4353002009900',
          '4354001000000',
          '4354002000000',
          '4355001000000',
          '4355002010000',
          '4355002020100',
          '4355002029900',
          '4355002030000',
          '4355002990000',
          '4356000000100',
          '4356000000200',
          '4356000000300',
          '4356000009900',
          '4357001000000',
          '4357002000100',
          '4357002009900',
          '4357003000000',
          '4357004000000',
          '4357099000100',
          '4357099000200',
          '4357099000300',
          '4357099009900',
          '4358000000100',
          '4358000000200',
          '4358000009900',
          '4391101000100',
          '4391101000200',
          '4391102000000',
          '4391103000000',
          '4391104000000',
          '4391201000100',
          '4391201000200',
          '4391202000000',
          '4391203000100',
          '4391203009900',
          '4391204000000',
          '4391301000000',
          '4391302000100',
          '4391302009900',
          '4391399000100',
          '4391399000200',
          '4391399000300',
          '4391399009900',
          '4391401010100',
          '4391401010200',
          '4391401010300',
          '4391401010400',
          '4391401019900',
          '4391401030100',
          '4391401030200',
          '4391401039900',
          '4391401990000',
          '4391402990000',
          '4391501000100',
          '4391501009900',
          '4391502000100',
          '4391502009900',
          '4392101000000',
          '4392102010100',
          '4392102019900',
          '4392102020100',
          '4392102029900',
          '4392102030100',
          '4392102039900',
          '4392102040100',
          '4392102049900',
          '4392102050100',
          '4392102059900',
          '4392102060100',
          '4392102069900',
          '4392102070100',
          '4392102079900',
          '4392102080100',
          '4392102089900',
          '4392102090000',
          '4392102100000',
          '4392102110000',
          '4392102990100',
          '4392102990200',
          '4392102990300',
          '4392102999900',
          '4392103010000',
          '4392103020000',
          '4392103030000',
          '4392103990100',
          '4392103999900',
          '4392104000000',
          '4392105000000',
          '4392106010100',
          '4392106019900',
          '4392106020100',
          '4392106029900',
          '4392106990100',
          '4392106999900',
          '4392201000000',
          '4392202000000',
          '4392203000100',
          '4392203000200',
          '4392203009900',
          '4392299000100',
          '4392299000200',
          '4392299009900',
          '4392301000000',
          '4392302000000',
          '4392303000000',
          '4392399000100',
          '4392399009900',
          '4392400000100',
          '4392400000200',
          '4392400009900',
          '4393101010100',
          '4393101010200',
          '4393101019900',
          '4393101020100',
          '4393101029900',
          '4393102010000',
          '4393102020000',
          '4393102030000',
          '4393102990000',
          '4393201990000',
          '4393202010000',
          '4393202020100',
          '4393202029900',
          '4393202990000',
          '4393299010000',
          '4393299020000',
          '4393299990000',
          '4393300000100',
          '4393300009900',
          '4393401000100',
          '4393401009900',
          '4393499000100',
          '4393499009900',
          '4393500000000',
          '4394101000000',
          '4394102000100',
          '4394102009900',
          '4394103000100',
          '4394103000200',
          '4394103000300',
          '4394103009900',
          '4394104000100',
          '4394104009900',
          '4394201010100',
          '4394201010200',
          '4394201019900',
          '4394201020100',
          '4394201029900',
          '4394202000000',
          '4394203000000',
          '4394301000100',
          '4394301009900',
          '4394302010100',
          '4394302010200',
          '4394302019900',
          '4394302990100',
          '4394302990200',
          '4394302999900',
          '4394303010100',
          '4394303019900',
          '4394303990000',
          '4394304000000',
          '4394400000100',
          '4394400009900',
          '4394901000000',
          '4394902000000',
          '4394903000000',
          '4394904000000',
          '4394905010000',
          '4394905020000',
          '4394999000000',
          '4411101000000',
          '4411102000000',
          '4411199000000',
          '4411200000100',
          '4411200000200',
          '4411200009900',
          '4411301000000',
          '4411302000000',
          '4411399000000',
          '4411401000000',
          '4411402000000',
          '4411500000100',
          '4411500000200',
          '4411500000300',
          '4411500009900',
          '4411900000100',
          '4411900000200',
          '4411900009900',
          '4412100000100',
          '4412100009900',
          '4412200000000',
          '4412300010000',
          '4412300020000',
          '4412300030000',
          '4412300990000',
          '4412401010000',
          '4412401990000',
          '4412402000000',
          '4412403000000',
          '4412404000000',
          '4412499000000',
          '4412500000100',
          '4412500000200',
          '4412500000300',
          '4412500000400',
          '4412600000000',
          '4412700010100',
          '4412700019900',
          '4412700990100',
          '4412700990200',
          '4412700990300',
          '4412700999900',
          '4412801010100',
          '4412801019900',
          '4412801020000',
          '4412802000100',
          '4412802009900',
          '4412803000100',
          '4412803009900',
          '4412899010100',
          '4412899019900',
          '4412899990000',
          '4412901010000',
          '4412901990000',
          '4412902000000',
          '4412903010100',
          '4412903010200',
          '4412903019900',
          '4412903020000',
          '4412903030000',
          '4413100000000',
          '4413200000000',
          '4413901000000',
          '4413902000000',
          '4414100000000',
          '4414200000000',
          '4414901010000',
          '4414901990000',
          '4414902010000',
          '4414902990000',
          '4414903010000',
          '4414903990000',
          '4414904010000',
          '4414904990000',
          '4414905010000',
          '4414905990000',
          '4415001010100',
          '4415001019900',
          '4415001990100',
          '4415001999900',
          '4415099010000',
          '4415099020000',
          '4415099030100',
          '4415099030200',
          '4415099990000',
          '4416000000100',
          '4416000000200',
          '4416000009900',
          '4419100000000',
          '4419201000000',
          '4419299000000',
          '4419300000000',
          '4419401000000',
          '4419402000000',
          '4419403000000',
          '4419404000000',
          '4419499000100',
          '4419499009900',
          '4419801000100',
          '4419801000200',
          '4419801000300',
          '4419801000400',
          '4419801000500',
          '4419801000600',
          '4419801000700',
          '4419801000800',
          '4419801000900',
          '4419801009900',
          '4419802000100',
          '4419802000200',
          '4419802000300',
          '4419802000400',
          '4419802009900',
          '4419901000000',
          '4419902010000',
          '4419902020000',
          '4419902990000',
          '4419903010000',
          '4419903020100',
          '4419903020200',
          '4419903020300',
          '4419903020400',
          '4419903029900',
          '4419903990100',
          '4419903990200',
          '4419903990300',
          '4419903999900',
          '4421101000000',
          '4421102000000',
          '4421103000000',
          '4421104000000',
          '4421105000000',
          '4421106000000',
          '4421201000000',
          '4421202000000',
          '4421203000000',
          '4421301000100',
          '4421301009900',
          '4421399000100',
          '4421399009900',
          '4421401000000',
          '4421402000000',
          '4421403000000',
          '4421404000000',
          '4421405010000',
          '4421405990000',
          '4421500000000',
          '4421601010100',
          '4421601010200',
          '4421601020000',
          '4421601030000',
          '4421601990000',
          '4421602010000',
          '4421602020000',
          '4421602030000',
          '4421602040000',
          '4421602990000',
          '4421701000000',
          '4421702000000',
          '4421703000000',
          '4421704000000',
          '4421799000000',
          '4421801000000',
          '4421802000000',
          '4421803000000',
          '4421899000000',
          '4422101000000',
          '4422102000000',
          '4422199000000',
          '4422201000000',
          '4422202010000',
          '4422202990000',
          '4422203000000',
          '4422204000000',
          '4422205000000',
          '4422206000000',
          '4422207000000',
          '4422208000000',
          '4422299000000',
          '4423101010000',
          '4423101990000',
          '4423199010100',
          '4423199019900',
          '4423199990000',
          '4423201000100',
          '4423201000200',
          '4423202000100',
          '4423202009900',
          '4423299000100',
          '4423299009900',
          '4424101010000',
          '4424101990000',
          '4424102010000',
          '4424102990000',
          '4424103010000',
          '4424103990000',
          '4424199000000',
          '4424201010000',
          '4424201990000',
          '4424202000000',
          '4425101000000',
          '4425102000000',
          '4425103000000',
          '4425104000000',
          '4425201000000',
          '4425202000000',
          '4425301010000',
          '4425301020100',
          '4425301029900',
          '4425302000000',
          '4425500000000',
          '4425600000000',
          '4431001010000',
          '4431001020000',
          '4431001030000',
          '4431002010000',
          '4431002990000',
          '4432001000000',
          '4432002000100',
          '4432002009900',
          '4441100000000',
          '4441201000100',
          '4441201009900',
          '4441299000100',
          '4441299009900',
          '4442100000100',
          '4442100009900',
          '4442200000100',
          '4442200009900',
          '4442300000100',
          '4442300009900',
          '4442400000000',
          '4442501000000',
          '4442502000000',
          '4442503000000',
          '4442504000000',
          '4442505000000',
          '4442599000000',
          '4442601000000',
          '4442699000000',
          '4442701000000',
          '4442799000000',
          '4442900000000',
          '4443001000000',
          '4443002010000',
          '4443002990000',
          '4443003000000',
          '4444001010000',
          '4444001020000',
          '4444001030100',
          '4444001030200',
          '4444001039900',
          '4444002000100',
          '4444002009900',
          '4444003000000',
          '4446100000000',
          '4446200000000',
          '4451100000000',
          '4451301000000',
          '4451302000000',
          '4451303010000',
          '4451303990000',
          '4451399000000',
          '4451501000000',
          '4451502000000',
          '4451601000000',
          '4451602000000',
          '4451603000000',
          '4451604000000',
          '4451605000000',
          '4451606010100',
          '4451606010200',
          '4451606020000',
          '4451606990000',
          '4451607000000',
          '4451699000000',
          '4451700000000',
          '4451801010100',
          '4451801019900',
          '4451801020100',
          '4451801029900',
          '4451899010100',
          '4451899019900',
          '4451899020100',
          '4451899029900',
          '4452201000000',
          '4452202000000',
          '4452203000000',
          '4452300000000',
          '4461101000000',
          '4461102010000',
          '4461102990000',
          '4461103000000',
          '4461104000000',
          '4461105000000',
          '4461106000000',
          '4461200000000',
          '4461300000000',
          '4461400000000',
          '4462100000100',
          '4462100009900',
          '4462201000000',
          '4462202000000',
          '4462203000000',
          '4462901000000',
          '4462902000000',
          '4462903000000',
          '4462904000000',
          '4462999000000',
          '4463001000000',
          '4463002000000',
          '4463099000000',
          '4464001000000',
          '4464002010100',
          '4464002019900',
          '4464002990100',
          '4464002999900',
          '4464003000100',
          '4464003000200',
          '4464003009900',
          '4464004000100',
          '4464004009900',
          '4464005000100',
          '4464005000200',
          '4464005009900',
          '4464006000100',
          '4464006000200',
          '4464006009900',
          '4464007010000',
          '4464007020000',
          '4464007030000',
          '4464008000100',
          '4464008000200',
          '4464008009900',
          '4464009000000',
          '4473001000000',
          '4473002000000',
          '4473003000000',
          '4473004000000',
          '4473099000000',
          '4474000000100',
          '4474000009900',
          '4475000000000',
          '4476000000000',
          '4481101000000',
          '4481102000000',
          '4481103010000',
          '4481103020000',
          '4481103990000',
          '4481201000000',
          '4481202000000',
          '4481203010000',
          '4481203020000',
          '4481203990000',
          '4481300000000',
          '4481400000000',
          '4481501000000',
          '4481502000000',
          '4481601000100',
          '4481601000200',
          '4481602010100',
          '4481602010200',
          '4481602020000',
          '4481602030000',
          '4481602990000',
          '4481603010000',
          '4481603020000',
          '4481603030100',
          '4481603039900',
          '4481604010100',
          '4481604019900',
          '4481604020000',
          '4481605000000',
          '4481606010000',
          '4481606020000',
          '4481606030000',
          '4481606990000',
          '4481701000000',
          '4481702010000',
          '4481702990000',
          '4481703000000',
          '4481704010000',
          '4481704020000',
          '4481704030000',
          '4481704040000',
          '4481704990000',
          '4481800000000',
          '4482101010000',
          '4482101020000',
          '4482101030000',
          '4482101990000',
          '4482102000000',
          '4482199010000',
          '4482199990000',
          '4482201000000',
          '4482202000000',
          '4482203000000',
          '4482299000000',
          '4482300000000',
          '4482400000100',
          '4482400009900',
          '4482500000000',
          '4482601000000',
          '4482602000000',
          '4482603000000',
          '4482699000000',
          '4483101000000',
          '4483102000000',
          '4483103000000',
          '4483104010000',
          '4483104020000',
          '4483104990100',
          '4483104999900',
          '4483105000000',
          '4483106010000',
          '4483106020000',
          '4483106030000',
          '4483106990000',
          '4483107000000',
          '4483200000100',
          '4483200009900',
          '4483300000000',
          '4491100000000',
          '4491200000000',
          '4491301000000',
          '4491302000000',
          '4491303000000',
          '4491304000000',
          '4491305000000',
          '4491306000000',
          '4491307000000',
          '4491399000000',
          '4491401000000',
          '4491402010000',
          '4491402020000',
          '4491402990000',
          '4491403010000',
          '4491403990000',
          '4491501000000',
          '4491502000000',
          '4491503000000',
          '4491504000000',
          '4491505000000',
          '4491599000000',
          '4491601000000',
          '4491602000000',
          '4491603000000',
          '4491604010100',
          '4491604019900',
          '4491604020000',
          '4491604030000',
          '4491604040100',
          '4491604049900',
          '4491700000000',
          '4491801000000',
          '4491802010000',
          '4491802020000',
          '4491802990000',
          '4491803000000',
          '4491804000000',
          '4491901010100',
          '4491901010200',
          '4491901990000',
          '4491902000000',
          '4491903000000',
          '4491904000000',
          '4491905000000',
          '4491906000000',
          '4491907000000',
          '4491908000100',
          '4491908009900',
          '4491999010000',
          '4491999020100',
          '4491999020200',
          '4491999029900',
          '4491999030000',
          '4491999040000',
          '4491999050000',
          '4491999990100',
          '4491999999900',
          '4492101000000',
          '4492199000000',
          '4492201000000',
          '4492202010000',
          '4492202020000',
          '4492202990000',
          '4492299000000',
          '4492300000000',
          '4492901010000',
          '4492901990000',
          '4492902000000',
          '4492903000000',
          '4492904000000',
          '4511000000000',
          '4513000000000',
          '4514101000000',
          '4514102010000',
          '4514102990000',
          '4514103000000',
          '4514199000000',
          '4514201000000',
          '4514299010000',
          '4514299990000',
          '4515000000000',
          '4516001000000',
          '4516099010000',
          '4516099020000',
          '4516099030000',
          '4516099990000',
          '4517001000000',
          '4517002000000',
          '4518001000000',
          '4518002000000',
          '4518099000000',
          '4522001000000',
          '4522002000100',
          '4522002000200',
          '4522002009900',
          '4522003000000',
          '4522099000000',
          '4523000000000',
          '4524000000000',
          '4525000000000',
          '4526100000100',
          '4526100000200',
          '4526100000300',
          '4526100009900',
          '4526200000000',
          '4526300000000',
          '4526400000000',
          '4526500000000',
          '4526600000000',
          '4526900000000',
          '4527100000000',
          '4527200000000',
          '4528100000000',
          '4528900000000',
          '4529000000000',
          '4611101000000',
          '4611102000100',
          '4611102000200',
          '4611102000300',
          '4611102000400',
          '4611201000000',
          '4611202000000',
          '4611203000100',
          '4611203000200',
          '4611203000300',
          '4611204000100',
          '4611204000200',
          '4611204000300',
          '4611204000400',
          '4611301000100',
          '4611301000200',
          '4611301000300',
          '4611302000000',
          '4611303000000',
          '4611399010000',
          '4611399990000',
          '4612101000100',
          '4612101000200',
          '4612101000300',
          '4612199000100',
          '4612199000200',
          '4612199000300',
          '4612199000400',
          '4612201010000',
          '4612201020000',
          '4612201990000',
          '4612202010000',
          '4612202020000',
          '4612202030100',
          '4612202039900',
          '4612202040000',
          '4612202990000',
          '4612203000000',
          '4613100000100',
          '4613100009900',
          '4613200000000',
          '4621101000000',
          '4621102000100',
          '4621102009900',
          '4621103000000',
          '4621104010000',
          '4621104020000',
          '4621104030000',
          '4621199000000',
          '4621201000100',
          '4621201000200',
          '4621201000300',
          '4621201000400',
          '4621201009900',
          '4621202000000',
          '4621203000100',
          '4621203000200',
          '4621203009900',
          '4621204000100',
          '4621204000200',
          '4621204009900',
          '4621205000100',
          '4621205000200',
          '4621205000300',
          '4621205000400',
          '4621205000500',
          '4621205000600',
          '4621205000700',
          '4621205000800',
          '4621205000900',
          '4621205001000',
          '4621205001100',
          '4621205009900',
          '4621206010000',
          '4621206990100',
          '4621206999900',
          '4621299010000',
          '4621299020000',
          '4621299030000',
          '4621299040000',
          '4621299050000',
          '4621299060100',
          '4621299069900',
          '4621299070100',
          '4621299070200',
          '4621299079900',
          '4621299990000',
          '4621300000100',
          '4621300000200',
          '4621300009900',
          '4621400000000',
          '4621500000100',
          '4621500000200',
          '4621500009900',
          '4622000000100',
          '4622000000200',
          '4622000000300',
          '4622000000400',
          '4622000009900',
          '4631000000100',
          '4631000009900',
          '4632000000000',
          '4633000000000',
          '4634001010100',
          '4634001019900',
          '4634001020100',
          '4634001020200',
          '4634001029900',
          '4634002010100',
          '4634002019900',
          '4634002020100',
          '4634002029900',
          '4635000000100',
          '4635000009900',
          '4636000000000',
          '4641001010100',
          '4641001010200',
          '4641001019900',
          '4641001020100',
          '4641001020200',
          '4641001029900',
          '4641002000000',
          '4641003000000',
          '4641004000000',
          '4641005000000',
          '4641099000000',
          '4642001000100',
          '4642001000200',
          '4642001009900',
          '4642002000000',
          '4642003000000',
          '4642004000000',
          '4642005000000',
          '4642099000000',
          '4643001000100',
          '4643001009900',
          '4643002000100',
          '4643002000200',
          '4643002009900',
          '4651001000000',
          '4651002010000',
          '4651002020300',
          '4651002020400',
          '4651002029900',
          '4651002990000',
          '4651003010100',
          '4651003010200',
          '4651003019900',
          '4651003020000',
          '4651003030000',
          '4651003990000',
          '4651004000000',
          '4651005000000',
          '4651006000000',
          '4653101000100',
          '4653101000200',
          '4653101009900',
          '4653102000000',
          '4653103000000',
          '4653104010000',
          '4653104990000',
          '4653105000000',
          '4653200000000',
          '4653900000100',
          '4653900000200',
          '4653900009900',
          '4654100000100',
          '4654100000200',
          '4654100009900',
          '4654201000000',
          '4654202000000',
          '4654203000000',
          '4691001000100',
          '4691001000200',
          '4691001000300',
          '4691001000400',
          '4691001000500',
          '4691001000600',
          '4691001000700',
          '4691001009900',
          '4691002010100',
          '4691002010200',
          '4691002010300',
          '4691002019900',
          '4691002020000',
          '4691003000000',
          '4691004000000',
          '4692101000000',
          '4692199000000',
          '4692901000000',
          '4692902000000',
          '4692999010000',
          '4692999990000',
          '4693100000000',
          '4693200000000',
          '4693901010000',
          '4693901020000',
          '4693901990000',
          '4693999010100',
          '4693999019900',
          '4693999020000',
          '4693999039900',
          '4693999040000',
          '4693999050000',
          '4693999060100',
          '4693999069900',
          '4693999070000',
          '4693999080000',
          '4693999090000',
          '4693999990100',
          '4693999999900',
          '4694001000100',
          '4694001009900',
          '4694002000000',
          '4695001000100',
          '4695001009900',
          '4695002000000',
          '4695099000000',
          '4696001000000',
          '4696002010100',
          '4696002019900',
          '4696002020000',
          '4696003010000',
          '4696003020000',
          '4696003990100',
          '4696003999900',
          '4696004000000',
          '4696099000100',
          '4696099000200',
          '4696099000300',
          '4696099009900',
          '4711001000000',
          '4711002000000',
          '4711003000100',
          '4711003000200',
          '4711099000000',
          '4712001010000',
          '4712001990100',
          '4712001990200',
          '4712002010100',
          '4712002010200',
          '4712002010300',
          '4712002010400',
          '4712002019900',
          '4712002020100',
          '4712002020200',
          '4712002020300',
          '4712002029900',
          '4713000000000',
          '4714001000000',
          '4714002000000',
          '4714003000000',
          '4714004010000',
          '4714004990000',
          '4714099000000',
          '4715001000000',
          '4715002000000',
          '4715003010100',
          '4715003019900',
          '4715003020000',
          '4715003030000',
          '4715003990000',
          '4715004000000',
          '4716001010100',
          '4716001010200',
          '4716001019900',
          '4716001020000',
          '4716001030000',
          '4716001990000',
          '4716002010100',
          '4716002010200',
          '4716002019900',
          '4716002020000',
          '4716002030000',
          '4716002990000',
          '4716003010100',
          '4716003010200',
          '4716003019900',
          '4716003020000',
          '4716003030000',
          '4716003990000',
          '4716099010100',
          '4716099010200',
          '4716099019900',
          '4716099020000',
          '4716099030000',
          '4716099990000',
          '4717100000000',
          '4717200000000',
          '4717301000000',
          '4717302000000',
          '4717303000000',
          '4721100000000',
          '4721201000000',
          '4721299000100',
          '4721299000200',
          '4721299009900',
          '4721300000000',
          '4721400000000',
          '4721500000000',
          '4722100000000',
          '4722200000000',
          '4722301000000',
          '4722399010100',
          '4722399010200',
          '4722399010300',
          '4722399010400',
          '4722399019900',
          '4722399020100',
          '4722399029900',
          '4722399990100',
          '4722399999900',
          '4731101000100',
          '4731101000200',
          '4731101009900',
          '4731199000100',
          '4731199000200',
          '4731199009900',
          '4731201000000',
          '4731299000000',
          '4731301000100',
          '4731301000200',
          '4731301009900',
          '4731302000000',
          '4731303000000',
          '4731401010000',
          '4731401990000',
          '4731402010000',
          '4731402020000',
          '4731499010000',
          '4731499020000',
          '4731501010000',
          '4731501990000',
          '4731502010000',
          '4731502020000',
          '4731599010000',
          '4731599020000',
          '4732101010000',
          '4732101990000',
          '4732102010000',
          '4732102990000',
          '4732103000000',
          '4732199000000',
          '4732301000100',
          '4732301009900',
          '4732399000000',
          '4733001000000',
          '4733002000100',
          '4733002000200',
          '4733002009900',
          '4733003000100',
          '4733003000200',
          '4733003009900',
          '4733004000000',
          '4733005000000',
          '4740100000100',
          '4740100000200',
          '4740100000300',
          '4740201000000',
          '4740202000000',
          '4740299010000',
          '4740299990100',
          '4740299990200',
          '4740299999900',
          '4740301000100',
          '4740301009900',
          '4740399000100',
          '4740399000200',
          '4740399000300',
          '4740399000400',
          '4740399000500',
          '4740399000600',
          '4740399000700',
          '4740399000800',
          '4740399000900',
          '4740399001000',
          '4740399001100',
          '4740399001200',
          '4740399009900',
          '4753001000100',
          '4753001000200',
          '4753001009900',
          '4753002000100',
          '4753002009900',
          '4753099000000',
          '4754000000000',
          '4755000000100',
          '4755000000200',
          '4759001010000',
          '4759001020000',
          '4759001030000',
          '4759001990100',
          '4759001990200',
          '4759099000000',
          '4761001010000',
          '4761001020000',
          '4761001030000',
          '4761001990000',
          '4761002010100',
          '4761002010200',
          '4761002010300',
          '4761002010400',
          '4761002019900',
          '4761002990000',
          '4762001000100',
          '4762001000200',
          '4762001000300',
          '4762001000400',
          '4762001009900',
          '4762099000000',
          '4769200000000',
          '4769900000000',
          '4781100000000',
          '4781200000000',
          '4781300000000',
          '4781400000000',
          '4782100000000',
          '4782200000000',
          '4782900000000',
          '4791000000100',
          '4791000000200',
          '4792001010100',
          '4792001010200',
          '4792001019900',
          '4792001990000',
          '4792002000000',
          '4812198010000',
          '4812198020000',
          '4812198990000',
          '4812199010000',
          '4812200000000',
          '4815001010100',
          '4815001019900',
          '4815001020100',
          '4815001029900',
          '4815002010100',
          '4815002019900',
          '4815002040100',
          '4815002040200',
          '4815002040300',
          '4815002990100',
          '4815002999900',
          '4815006010100',
          '4815009010100',
          '4815012020100',
          '4815012030101',
          '4815012030201',
          '4815012039901',
          '4815012999900',
          '4815013020000',
          '4815013040000',
          '4815019010000',
          '4815019990000',
          '4815036010100',
          '4815036010200',
          '4815036019900',
          '4815036020100',
          '4815036020200',
          '4815036029900',
          '4815036030100',
          '4815036030200',
          '4815036990100',
          '4815036990200',
          '4815036990300',
          '4815036990400',
          '4815036990500',
          '4815036990600',
          '4815036990700',
          '4815036990800',
          '4815036999900',
          '4816099000100',
          '4817102030000',
          '4817109000200',
          '4817109000300',
          '4817109009900',
          '4817299000000',
          '4818001019900',
          '4818001999900',
          '4818002000000',
          '4818098000000',
          '4821101000000',
          '4821102000000',
          '4821199000100',
          '4821199000200',
          '4821199000300',
          '4821199009900',
          '4821201000000',
          '4821202000000',
          '4821203010100',
          '4821203019900',
          '4821203990100',
          '4821203999900',
          '4821901000000',
          '4821902010100',
          '4821902019900',
          '4821902020100',
          '4821902029900',
          '4821902030100',
          '4821902039900',
          '4821902040100',
          '4821902049900',
          '4821902990100',
          '4821902999900',
          '4821903010100',
          '4821903019900',
          '4821903990100',
          '4821903999900',
          '4821999000000',
          '4822001010000',
          '4822001990000',
          '4822099010100',
          '4822099019900',
          '4822099020100',
          '4822099029900',
          '4823100000000',
          '4823200000100',
          '4823200000600',
          '4823200009900',
          '4823300000100',
          '4823300000200',
          '4823300009900',
          '4824100000000',
          '4824200000000',
          '4824300000100',
          '4824300000200',
          '4824300000300',
          '4824300009900',
          '4824400000000',
          '4824901010000',
          '4824901990100',
          '4824901990200',
          '4824901999900',
          '4824999010000',
          '4824999020000',
          '4824999990000',
          '4825101010100',
          '4825101019900',
          '4825101990100',
          '4825101999900',
          '4825102000100',
          '4825102009900',
          '4825103000100',
          '4825103009900',
          '4825104000100',
          '4825104009900',
          '4825105000100',
          '4825105009900',
          '4825106010100',
          '4825106019900',
          '4825106990100',
          '4825106999900',
          '4825199000100',
          '4825199009900',
          '4825201010000',
          '4825201990000',
          '4825202019900',
          '4825202029900',
          '4825202039900',
          '4825202990000',
          '4825298000000',
          '4825299000000',
          '4825301000000',
          '4825303000000',
          '4825304000000',
          '4825306000000',
          '4825313010000',
          '4825313990000',
          '4825314010000',
          '4825314990000',
          '4825315990000',
          '4825399019900',
          '4825399990000',
          '4826100000000',
          '4826200000100',
          '4826200009900',
          '4826301000000',
          '4826302000100',
          '4826302009900',
          '4826303000100',
          '4826303009900',
          '4826400000100',
          '4826400000200',
          '4826400009900',
          '4826600000100',
          '4826600009900',
          '4826901010000',
          '4826901020000',
          '4826901990000',
          '4826999010000',
          '4826999020000',
          '4826999030100',
          '4826999030200',
          '4826999030300',
          '4826999039900',
          '4826999990000',
          '4828101000000',
          '4828102010100',
          '4828102019900',
          '4828102020100',
          '4828102029900',
          '4828102990000',
          '4828103000000',
          '4828104000000',
          '4828105000000',
          '4828106019900',
          '4828106020000',
          '4828107000100',
          '4828107000200',
          '4828107000300',
          '4828107009900',
          '4828108000100',
          '4828108009900',
          '4828199009900',
          '4828200000000',
          '4828300000100',
          '4828300000200',
          '4828401000100',
          '4828401000200',
          '4828401009900',
          '4828402000000',
          '4828500000000',
          '4831101010000',
          '4831101020000',
          '4831101039900',
          '4831101990000',
          '4831102000000',
          '4831201000000',
          '4831202000000',
          '4831203000100',
          '4831203009900',
          '4831299000100',
          '4831299009900',
          '4831300000100',
          '4831300000200',
          '4831300000300',
          '4831300009900',
          '4831401000100',
          '4831401009900',
          '4831402000200',
          '4831402009900',
          '4831500000100',
          '4831500000200',
          '4831500009900',
          '4832100000000',
          '4832201010000',
          '4832201020000',
          '4832201990100',
          '4832201990200',
          '4832201990300',
          '4832201990400',
          '4832201999900',
          '4832202000000',
          '4832300000000',
          '4832901000100',
          '4832901000200',
          '4832901009900',
          '4832902000100',
          '4832902000200',
          '4832902009900',
          '4832903000100',
          '4832903009900',
          '4832999020000',
          '4832999990100',
          '4832999999900',
          '4834101010000',
          '4834101020000',
          '4834101030000',
          '4834101990000',
          '4834102010000',
          '4834102020100',
          '4834102020200',
          '4834102029900',
          '4834102030100',
          '4834102039900',
          '4834102040000',
          '4834102990000',
          '4834103010000',
          '4834103020000',
          '4834103990000',
          '4834200000100',
          '4834200009900',
          '4835101000000',
          '4835102000100',
          '4835102009900',
          '4835200000000',
          '4835301000100',
          '4835301000200',
          '4835302000100',
          '4835302009900',
          '4835303000000',
          '4835399000000',
          '4835400000000',
          '4841001010100',
          '4841001019900',
          '4841001020100',
          '4841001029900',
          '4841001990000',
          '4841002010100',
          '4841002010200',
          '4841002019900',
          '4841002020100',
          '4841002029900',
          '4841002990000',
          '4842001000100',
          '4842001009900',
          '4842002000000',
          '4842099010100',
          '4842099019900',
          '4842099020100',
          '4842099029900',
          '4842099990100',
          '4842099999900',
          '4843001010000',
          '4843001990100',
          '4843001999900',
          '4843002000000',
          '4844001010100',
          '4844001010200',
          '4844001019900',
          '4844001020000',
          '4844001990000',
          '4844002010000',
          '4844002990000',
          '4844003010000',
          '4844003020000',
          '4844003030000',
          '4844003990000',
          '4849001000100',
          '4849001000200',
          '4849001000300',
          '4849001000400',
          '4849002000100',
          '4849002000200',
          '4849003000100',
          '4849003000200',
          '4849099000100',
          '4849099000200',
          '4849099000300',
          '4849099000400',
          '4911500000000',
          '4911900000100',
          '4911900000200',
          '4911900000300',
          '4911900000400',
          '4911900000500',
          '4911900000600',
          '4911900000700',
          '4911900000800',
          '4911900009900',
          '4912101000000',
          '4912199010100',
          '4912199019900',
          '4912199990000',
          '4912901000100',
          '4912901000200',
          '4912901009900',
          '4912902010100',
          '4912902019900',
          '4912902020000',
          '4912902990100',
          '4912902999900',
          '4912903010100',
          '4912903019900',
          '4912903020000',
          '4912904010100',
          '4912904019900',
          '4912904020100',
          '4912904029900',
          '4912904990000',
          '4912905000100',
          '4912905000200',
          '4912905009900',
          '4912906010100',
          '4912906010200',
          '4912906019900',
          '4912906020100',
          '4912906029900',
          '4912906030100',
          '4912906039900',
          '4912907010100',
          '4912907019900',
          '4912907020000',
          '4912908010100',
          '4912908019900',
          '4912908020000',
          '4912909010100',
          '4912909019900',
          '4912909020000',
          '4912910010100',
          '4912910019900',
          '4912910020000',
          '4912911010000',
          '4912911020000',
          '4912999000100',
          '4912999000200',
          '4912999000300',
          '4912999000400',
          '4912999000500',
          '4912999000600',
          '4912999000700',
          '4912999000800',
          '4912999000900',
          '4912999001000',
          '4912999001100',
          '4912999001200',
          '4912999001300',
          '4912999001400',
          '4912999001500',
          '4912999009900',
          '4921000000100',
          '4921000000200',
          '4921000000300',
          '4921000000400',
          '4921000000500',
          '4921000000600',
          '4921000009900',
          '4922100000000',
          '4922200000000',
          '4922900000100',
          '4922900000200',
          '4922900009900',
          '4923101000000',
          '4923102000100',
          '4923102009900',
          '4923199000100',
          '4923199009900',
          '4923200000100',
          '4923200009900',
          '4931100000000',
          '4931200000000',
          '4931300000000',
          '4931400000000',
          '4931500000000',
          '4931600000000',
          '4931900000100',
          '4931900009900',
          '4932000000000',
          '4939000010100',
          '4939000010200',
          '4939000020000',
          '4939000990000',
          '4941000000000',
          '4949000000100',
          '4949000000200',
          '4949000000300',
          '4949000000400',
          '4949000000500',
          '4949000009900',
          '4951100000000',
          '4951200000000',
          '4951900000000',
          '4952000000000',
          '4953100000000',
          '4953200000000',
          '4953300000000',
          '4954001000100',
          '4954001000200',
          '4954001000300',
          '4954001009900',
          '4954002000000',
          '4954003000000',
          '4961000000000',
          '4962100000100',
          '4962100000200',
          '4962200000100',
          '4962200009900',
          '4962300010100',
          '4962300010200',
          '4962300020000',
          '4963000000000',
          '4964000000100',
          '4964000000200',
          '4964000000300',
          '4964000009900',
          '4992100000100',
          '4992100000200',
          '4992100000300',
          '4993001000100',
          '4993001009900',
          '4993099000100',
          '4993099009900',
          '4994100000100',
          '4994100009900',
          '4994299000100',
          '4994299000200',
          '4994299000300',
          '4994299000400',
          '4994299000500',
          '4994299000600',
          '4994299000700',
          '4994299000800',
          '4994299000900',
          '4994299001000'
        ],
        text: 'Bienes'
      },
      {
        id: 'CASILLA_022600_MON',
        cabys: [
          '5311100000000',
          '5311200000000',
          '5312100000000',
          '5312200000000',
          '5312900000000',
          '5321100000000',
          '5321200000000',
          '5321300000000',
          '5322100000000',
          '5322200000000',
          '5323100000000',
          '5323200000000',
          '5323300000000',
          '5323400000000',
          '5324100000000',
          '5324200000000',
          '5325100000000',
          '5325200000000',
          '5325301000000',
          '5325302000000',
          '5325303000000',
          '5326100000000',
          '5326200000000',
          '5326900000000',
          '5327000000000',
          '5329000000000',
          '5411100000000',
          '5411200000000',
          '5412100000000',
          '5412200000000',
          '5412900000000',
          '5421100000000',
          '5421200000000',
          '5421300000000',
          '5422100000000',
          '5422200000000',
          '5423100000000',
          '5423200000000',
          '5423300000000',
          '5423400000000',
          '5424100000000',
          '5424200000000',
          '5425100000000',
          '5425200000000',
          '5425300000000',
          '5426100000000',
          '5426200000000',
          '5426900000000',
          '5427000000000',
          '5429000000000',
          '5431000000000',
          '5432000000100',
          '5432000009900',
          '5433000000100',
          '5433000009900',
          '5434100000000',
          '5434200000000',
          '5440000000000',
          '5451100000000',
          '5451200000000',
          '5452100000000',
          '5452200000000',
          '5453000000000',
          '5454000000000',
          '5455000000000',
          '5456000000000',
          '5457000000000',
          '5459000000000',
          '5461100000000',
          '5461200000000',
          '5461300000000',
          '5461400000000',
          '5461900000000',
          '5462100000000',
          '5462200000000',
          '5463100000000',
          '5463200000000',
          '5464000000000',
          '5465000000000',
          '5469100000000',
          '5469900000000',
          '5471000000000',
          '5472000000000',
          '5473000000000',
          '5474000000000',
          '5475000000000',
          '5476000000000',
          '5477000000000',
          '5479000000000',
          '6121100000000',
          '6121200000000',
          '6121300000000',
          '6121400009900',
          '6121500000000',
          '6121900000000',
          '6122100000000',
          '6122200000000',
          '6122300000000',
          '6122400000000',
          '6122500000000',
          '6122600000000',
          '6122700000000',
          '6122800000000',
          '6122900009900',
          '6123100000000',
          '6123200000000',
          '6123300000000',
          '6123400000000',
          '6124100000000',
          '6124200000000',
          '6124300000000',
          '6124400000000',
          '6124500000000',
          '6124600000000',
          '6125100000000',
          '6125200000000',
          '6125300000000',
          '6125400000000',
          '6125500000000',
          '6125600000000',
          '6125900000000',
          '6126100000000',
          '6126200000000',
          '6126300000000',
          '6126400000000',
          '6126500000000',
          '6127100000000',
          '6127200000000',
          '6127300000000',
          '6127400000000',
          '6127500000000',
          '6127600000000',
          '6128100000000',
          '6128200000000',
          '6128300000000',
          '6128400000000',
          '6128500000000',
          '6128600000000',
          '6128700000000',
          '6128800000000',
          '6128900000000',
          '6129100000000',
          '6129200000000',
          '6129300000000',
          '6129400000000',
          '6129500000000',
          '6129700000000',
          '6129800000000',
          '6129900000000',
          '6251100000000',
          '6251200000000',
          '6251400000000',
          '6251500000000',
          '6251900000000',
          '6252100000000',
          '6252200000000',
          '6252300000000',
          '6252400000000',
          '6252500000000',
          '6252600000000',
          '6252700000000',
          '6252800000000',
          '6252900000000',
          '6253100000000',
          '6253200000000',
          '6253300000000',
          '6253400000000',
          '6254100000000',
          '6254200000000',
          '6254300000000',
          '6254400000000',
          '6254500000000',
          '6254600000000',
          '6255100000000',
          '6255200000000',
          '6255300000000',
          '6255400000000',
          '6255500000000',
          '6255600000000',
          '6255900000000',
          '6256100000000',
          '6256200000000',
          '6256300000000',
          '6256400000000',
          '6256500000000',
          '6257200000000',
          '6257300000000',
          '6257400000000',
          '6257500000000',
          '6257600000000',
          '6258100000000',
          '6258200000000',
          '6258300000000',
          '6258400000000',
          '6258500000000',
          '6258600000000',
          '6258700000000',
          '6258800000000',
          '6258900000000',
          '6259100000000',
          '6259400000000',
          '6259700000000',
          '6259800000000',
          '6259900000000',
          '6311100000000',
          '6311200000000',
          '6311300000000',
          '6311400000000',
          '6312000000000',
          '6313000000000',
          '6321000000000',
          '6322000000000',
          '6329000000000',
          '6331000000000',
          '6332000000000',
          '6339100000000',
          '6339200000000',
          '6339300000000',
          '6339900000000',
          '6340001000100',
          '6340001000200',
          '6340001000300',
          '6340001000400',
          '6340001009900',
          '6340002010100',
          '6340002010200',
          '6340002010300',
          '6340002010400',
          '6340002019900',
          '6340002020100',
          '6340002020200',
          '6340002020300',
          '6340002020400',
          '6340002029900',
          '6411100000000',
          '6411200000000',
          '6411300000000',
          '6411401009900',
          '6411402000000',
          '6411499000000',
          '6411500000000',
          '6411600000000',
          '6411700000000',
          '6411800000000',
          '6411900000000',
          '6412100000000',
          '6412200000000',
          '6412900000000',
          '6413100000000',
          '6413200000000',
          '6413300000000',
          '6421000000000',
          '6422100000000',
          '6422200000000',
          '6422300000000',
          '6423100000000',
          '6423200000000',
          '6423900000000',
          '6425000000000',
          '6511100000100',
          '6511100009900',
          '6511200000100',
          '6511200009900',
          '6511300000100',
          '6511300009900',
          '6511400000100',
          '6511400009900',
          '6511500000000',
          '6511600000000',
          '6511700000100',
          '6511700009900',
          '6511800000100',
          '6511800009900',
          '6511900000100',
          '6511900009900',
          '6512100000000',
          '6512200000000',
          '6512300000000',
          '6512400000000',
          '6512500000000',
          '6512600000000',
          '6512900000000',
          '6513100000000',
          '6513900000000',
          '6521100000000',
          '6521200000000',
          '6521300000000',
          '6521900000000',
          '6522100000000',
          '6522200000000',
          '6522900000000',
          '6531100000000',
          '6531900000000',
          '6532000000000',
          '6601100000000',
          '6601200000000',
          '6602100000000',
          '6602200000000',
          '6602300000000',
          '6602400000000',
          '6603100000100',
          '6603100000200',
          '6603200000000',
          '6711000000000',
          '6719000000000',
          '6721000000000',
          '6722000000000',
          '6729000000000',
          '6731000000000',
          '6739000000000',
          '6741000000000',
          '6742000009900',
          '6743000000000',
          '6744000000000',
          '6749000000100',
          '6749000000200',
          '6749000009900',
          '6751100000000',
          '6751200000000',
          '6752100000000',
          '6752200000000',
          '6753100000000',
          '6753200000000',
          '6759000000000',
          '6761000000000',
          '6762000000000',
          '6763000009900',
          '6764000000000',
          '6791000000000',
          '6799000000000',
          '6801100000000',
          '6801200000000',
          '6801300000000',
          '6802100000000',
          '6802200000000',
          '6803000000000',
          '6911100000000',
          '6911200000200',
          '6911200009900',
          '6912000000000',
          '6921000000200',
          '6921000009900',
          '6922000000000',
          '6923000000000',
          '7111000000000',
          '7112103000000',
          '7112104000000',
          '7112105009900',
          '7112106009900',
          '7112107009900',
          '7112203000000',
          '7112204000000',
          '7112205009900',
          '7112206009900',
          '7112207009900',
          '7113100000000',
          '7113200000000',
          '7113399000000',
          '7113401000000',
          '7113402000000',
          '7113500000000',
          '7113901000000',
          '7113902000000',
          '7113903000000',
          '7113904000000',
          '7113907000000',
          '7113909000000',
          '7113999000000',
          '7114001000100',
          '7114002010100',
          '7114002990000',
          '7119002009900',
          '7119099000000',
          '7120000000000',
          '7131200000100',
          '7131200000200',
          '7131200000300',
          '7131300000000',
          '7133100000000',
          '7133200000000',
          '7133300000000',
          '7133400010100',
          '7133400010200',
          '7133400019900',
          '7133400020100',
          '7133400020200',
          '7133400990100',
          '7133400990200',
          '7133400990300',
          '7133400990400',
          '7133400990500',
          '7133400990600',
          '7133400990700',
          '7133400999900',
          '7133500000000',
          '7133600000100',
          '7133600000200',
          '7133902000100',
          '7133902009900',
          '7141000000100',
          '7141000009900',
          '7142000000000',
          '7143100000000',
          '7143200000000',
          '7143300000000',
          '7143400000000',
          '7143500000000',
          '7143600000100',
          '7143600000200',
          '7143901000000',
          '7143902000000',
          '7151100000000',
          '7151200000000',
          '7151900000000',
          '7152100000100',
          '7152100009900',
          '7152200000000',
          '7152300000000',
          '7153001009900',
          '7153099000000',
          '7154100000100',
          '7154100000200',
          '7154100000300',
          '7154100009900',
          '7154200000100',
          '7154200000200',
          '7154200000300',
          '7154200009900',
          '7155100000000',
          '7155200000000',
          '7155900000000',
          '7159100000000',
          '7159200009900',
          '7159300000100',
          '7159300000200',
          '7159300009900',
          '7159900000200',
          '7159900009900',
          '7161000000000',
          '7162000000000',
          '7163000000000',
          '7169000000000',
          '7170100000000',
          '7170200000000',
          '7211100000200',
          '7211200000100',
          '7211200000300',
          '7212100000100',
          '7212100000200',
          '7212100000300',
          '7212100000400',
          '7212100009900',
          '7212200000000',
          '7212300000000',
          '7213000000000',
          '7221100000000',
          '7221200000000',
          '7221300000000',
          '7222100000000',
          '7222200000000',
          '7222300000000',
          '7223000000000',
          '7224000000000',
          '7311100000000',
          '7311200000000',
          '7311300000000',
          '7311400000000',
          '7311500000000',
          '7311600000000',
          '7311700000000',
          '7312100000000',
          '7312200000000',
          '7312300000000',
          '7312400000000',
          '7312500000000',
          '7312900000000',
          '7321000000000',
          '7322000000000',
          '7323000000000',
          '7324000000000',
          '7325000000000',
          '7326000000000',
          '7327000000000',
          '7329000000000',
          '7331100000000',
          '7331200000000',
          '7332000000000',
          '7333000000000',
          '7334000000000',
          '7335000000000',
          '7339000000000',
          '8111100000000',
          '8111200000000',
          '8111300000000',
          '8111400000000',
          '8111500000000',
          '8111600000000',
          '8111900000000',
          '8112100000000',
          '8112200000000',
          '8112300000000',
          '8112400000000',
          '8112500000000',
          '8112600000000',
          '8112900000000',
          '8113100000000',
          '8113200000000',
          '8113300000000',
          '8113400000000',
          '8113500000000',
          '8113600000000',
          '8113900000000',
          '8121100000000',
          '8121200000000',
          '8121300000000',
          '8121400000000',
          '8121900000000',
          '8122100000000',
          '8122200000000',
          '8122300000000',
          '8122400000000',
          '8122900000000',
          '8123100000000',
          '8123200000000',
          '8123300000000',
          '8123400000000',
          '8123900000000',
          '8130100000000',
          '8130200000000',
          '8130300000000',
          '8140000000000',
          '8211000000000',
          '8212000000100',
          '8212000000200',
          '8212000000300',
          '8212000000400',
          '8212000000500',
          '8212000000600',
          '8212000009900',
          '8213000000000',
          '8219100000000',
          '8219900000000',
          '8221000000000',
          '8222100000000',
          '8222200000000',
          '8222300000000',
          '8231000000000',
          '8232000000000',
          '8240000000000',
          '8311100000000',
          '8311200000000',
          '8311300000000',
          '8311400000000',
          '8311500000000',
          '8311600000000',
          '8311700000000',
          '8311800000000',
          '8312100000000',
          '8312900000000',
          '8313100000100',
          '8313100000200',
          '8313100000300',
          '8313100000400',
          '8313100009900',
          '8313200000100',
          '8313200000200',
          '8313200000300',
          '8313200000400',
          '8313200000500',
          '8313200009900',
          '8314100000100',
          '8314100000200',
          '8314100000300',
          '8314100000400',
          '8314100009900',
          '8314200000100',
          '8314200000200',
          '8314200000300',
          '8314200000400',
          '8314200009900',
          '8314300000000',
          '8315100000000',
          '8315200000100',
          '8315200000200',
          '8315900000100',
          '8315900000200',
          '8315900000300',
          '8315900009900',
          '8316100000000',
          '8316200000000',
          '8319000000000',
          '8321100000000',
          '8321200000000',
          '8321300000000',
          '8321400000000',
          '8322100000000',
          '8322200000000',
          '8322300000000',
          '8323100000000',
          '8323200000000',
          '8331000000000',
          '8332100000000',
          '8332200000000',
          '8332300000000',
          '8332400000000',
          '8332500000000',
          '8332600000000',
          '8332700000000',
          '8332900000000',
          '8333000000000',
          '8341100000000',
          '8341200000000',
          '8341300000000',
          '8342100000000',
          '8342200000000',
          '8343000000000',
          '8344100000000',
          '8344200000000',
          '8344300000000',
          '8344400000000',
          '8344900000000',
          '8361100000000',
          '8361200000000',
          '8361900000000',
          '8362000000000',
          '8363100000000',
          '8363200009900',
          '8363300000000',
          '8363900000000',
          '8370000000000',
          '8381100000000',
          '8381200000000',
          '8381300000000',
          '8381400000100',
          '8381400009900',
          '8381500000000',
          '8381900000000',
          '8382000000000',
          '8391100000000',
          '8391200000000',
          '8391900000000',
          '8392000000000',
          '8393100000100',
          '8393100000200',
          '8393100000300',
          '8393100000400',
          '8393100000500',
          '8393100000600',
          '8393100000700',
          '8393100009900',
          '8393900000000',
          '8394000000000',
          '8395001000000',
          '8395002009900',
          '8396000000000',
          '8399000000000',
          '8411000000000',
          '8412000000000',
          '8413100000000',
          '8413200000000',
          '8413300000000',
          '8414000000000',
          '8415000000000',
          '8419000000000',
          '8421000000000',
          '8422100000000',
          '8422200000000',
          '8429000000000',
          '8431200000000',
          '8431300000000',
          '8432100000000',
          '8432200000000',
          '8433100000000',
          '8433200000000',
          '8434100000000',
          '8434200000000',
          '8439100000000',
          '8439200000000',
          '8439300000000',
          '8439400000000',
          '8439900000000',
          '8441000000000',
          '8442000000000',
          '8451000000000',
          '8452000000000',
          '8461100009900',
          '8461200000000',
          '8462100009900',
          '8462200000000',
          '8463100000000',
          '8463200000000',
          '8463300000000',
          '8463400000000',
          '8511100000000',
          '8511200000000',
          '8512100000000',
          '8512200000000',
          '8512300000000',
          '8512400000000',
          '8512500000000',
          '8521000000000',
          '8522000000000',
          '8523000000000',
          '8524000000000',
          '8525000000000',
          '8529000000100',
          '8529000000200',
          '8529000000300',
          '8529000009900',
          '8531000000000',
          '8532000000000',
          '8533000000000',
          '8534000000000',
          '8540000000000',
          '8551100000000',
          '8551200000000',
          '8551300000000',
          '8551400000000',
          '8551900000000',
          '8552100000000',
          '8552200000000',
          '8552300000000',
          '8552400000000',
          '8553100000000',
          '8553900000000',
          '8554000000000',
          '8555000000000',
          '8556100000000',
          '8556200000000',
          '8591000000000',
          '8592000000000',
          '8593100000000',
          '8593900000000',
          '8594000000000',
          '8595100000000',
          '8595200000000',
          '8595300000000',
          '8595400000000',
          '8596100000000',
          '8596200000000',
          '8597000000000',
          '8599100000000',
          '8599900009900',
          '8611100000100',
          '8611100009900',
          '8611200000000',
          '8611300000000',
          '8611900000100',
          '8611900000200',
          '8611900000300',
          '8611900000400',
          '8611900000500',
          '8611900009900',
          '8612100000000',
          '8612200000100',
          '8612200000200',
          '8612200000300',
          '8612200009900',
          '8612900000100',
          '8612900000200',
          '8612900000300',
          '8612900000400',
          '8612900009900',
          '8613100000000',
          '8613200000000',
          '8614100000000',
          '8614200000000',
          '8615100000000',
          '8615200000000',
          '8615300000000',
          '8615400000000',
          '8621100000000',
          '8621900000000',
          '8622100000000',
          '8622900000000',
          '8631100000000',
          '8631200000000',
          '8632000000000',
          '8633000000000',
          '8634000000000',
          '8635000000000',
          '8711000000000',
          '8712000000000',
          '8713000000000',
          '8714100000100',
          '8714100000200',
          '8714100009900',
          '8714200000000',
          '8714300000000',
          '8714900000000',
          '8715100000000',
          '8715200000100',
          '8715200000200',
          '8715200000300',
          '8715200009900',
          '8715300000100',
          '8715300000200',
          '8715300000300',
          '8715300009900',
          '8715400000100',
          '8715400000200',
          '8715400000300',
          '8715500000000',
          '8715600000100',
          '8715600000200',
          '8715600000300',
          '8715600000400',
          '8715600000500',
          '8715600000600',
          '8715600000700',
          '8715600000800',
          '8715600009900',
          '8715700000000',
          '8715900000000',
          '8721000000000',
          '8722000000000',
          '8723000000000',
          '8724000000000',
          '8729000000100',
          '8729000000200',
          '8729000000300',
          '8729000000400',
          '8729000009900',
          '8731000000000',
          '8732000000100',
          '8732000000200',
          '8732000000300',
          '8732000000400',
          '8732000000500',
          '8732000009900',
          '8733100000000',
          '8733200000000',
          '8733300000000',
          '8734000000000',
          '8735000000000',
          '8736000000000',
          '8739000000000',
          '8811001009900',
          '8811099000000',
          '8812000000000',
          '8813000000000',
          '8814000000000',
          '8815000000000',
          '8816100000000',
          '8816200000000',
          '8816300000000',
          '8816400000000',
          '8816500000000',
          '8816600000000',
          '8816700000000',
          '8816900000000',
          '8817000000000',
          '8818100000000',
          '8818200000000',
          '8818300000000',
          '8818400000000',
          '8819000000000',
          '8821100000000',
          '8821200000000',
          '8821300000000',
          '8821400000000',
          '8821500000000',
          '8821600000000',
          '8821700000000',
          '8821900000000',
          '8822100000000',
          '8822200000000',
          '8822300000000',
          '8823100000000',
          '8823200000000',
          '8823300000000',
          '8831100000000',
          '8831200000000',
          '8831300000000',
          '8831400000000',
          '8831900000000',
          '8832100000000',
          '8832200000000',
          '8832900000000',
          '8841100000000',
          '8841200000000',
          '8842100000000',
          '8842200000000',
          '8842300000000',
          '8842400000000',
          '8842500000000',
          '8842600000000',
          '8842700000000',
          '8842900000000',
          '8843000000000',
          '8851100000000',
          '8851200000000',
          '8852000000000',
          '8853100000000',
          '8853200000000',
          '8853300000000',
          '8853400000000',
          '8853500000000',
          '8853600000000',
          '8853700000000',
          '8853900000000',
          '8860100000000',
          '8860200000000',
          '8871100000000',
          '8871200000000',
          '8871300000000',
          '8872000000000',
          '8873100000000',
          '8873200000000',
          '8873300000000',
          '8873900000000',
          '8874100000000',
          '8874200000000',
          '8874300000000',
          '8874400000000',
          '8874500000000',
          '8874600000000',
          '8874700000000',
          '8874800000000',
          '8874900000000',
          '8875100000000',
          '8875200000000',
          '8875300000000',
          '8875400000000',
          '8875500000000',
          '8875600000000',
          '8875700000000',
          '8875900000000',
          '8876100000000',
          '8876200000000',
          '8876300000000',
          '8876400000000',
          '8876500000000',
          '8876600000000',
          '8876700000000',
          '8876800000000',
          '8876900000000',
          '8877100000000',
          '8877200000000',
          '8877300000000',
          '8877400000000',
          '8877500000000',
          '8877600000000',
          '8877900000000',
          '8881100000000',
          '8881200000000',
          '8881300000000',
          '8882100000000',
          '8882200000000',
          '8882300000000',
          '8882400000000',
          '8882500000000',
          '8882600000000',
          '8882700000000',
          '8882900000000',
          '8890100000000',
          '8890200000000',
          '8890300000000',
          '8890400000000',
          '8890500000000',
          '8890600000000',
          '8890700000000',
          '8890900000000',
          '8911000000000',
          '8912100000000',
          '8912200000000',
          '8912300000000',
          '8920000000000',
          '8931000000000',
          '8932000000000',
          '8933000000000',
          '8941000000000',
          '8942000000000',
          '9010001000000',
          '9010002000000',
          '9111100000000',
          '9111200000000',
          '9111300000000',
          '9111400000000',
          '9111900000000',
          '9112100000000',
          '9112200000000',
          '9112300000000',
          '9112400000000',
          '9113100000000',
          '9113200000000',
          '9113300000000',
          '9113400000000',
          '9113500000000',
          '9113600000000',
          '9113700000000',
          '9113800000000',
          '9119100000000',
          '9119900000000',
          '9121000000000',
          '9122000000000',
          '9123000000000',
          '9124000000000',
          '9125000000000',
          '9126000000000',
          '9127000000000',
          '9128000000000',
          '9129000000000',
          '9131000000000',
          '9132000000000',
          '9133000000000',
          '9134000000000',
          '9210000000300',
          '9220000000300',
          '9231000000300',
          '9232000000300',
          '9233000000300',
          '9234000000300',
          '9241000000300',
          '9242000000300',
          '9251000000300',
          '9252000000300',
          '9291200000000',
          '9292000000100',
          '9292000000200',
          '9292000000300',
          '9292000000400',
          '9292000009900',
          '9310212070104',
          '9310300000100',
          '9310300000200',
          '9310300000300',
          '9310300000400',
          '9310300000500',
          '9310300000600',
          '9310300009900',
          '9319400000000',
          '9330400000000',
          '9341200000000',
          '9349100000100',
          '9351000000100',
          '9352000000000',
          '9353000000000',
          '9359000000000',
          '9411000000000',
          '9412000000000',
          '9421100000000',
          '9421200000000',
          '9421900000000',
          '9422100000000',
          '9422900000000',
          '9423100000000',
          '9423900000000',
          '9431100000000',
          '9431200000000',
          '9431300000000',
          '9431900000000',
          '9432100000000',
          '9432200000000',
          '9433100000000',
          '9433200000000',
          '9433300000000',
          '9433900000000',
          '9441100000000',
          '9441200000000',
          '9441300000000',
          '9442000000000',
          '9443000000000',
          '9449000000000',
          '9451000000000',
          '9459000000000',
          '9490000000000',
          '9511000009900',
          '9512000990000',
          '9520000009900',
          '9591000000000',
          '9592000000000',
          '9599100000000',
          '9599200000000',
          '9599300000000',
          '9599400000000',
          '9599500000000',
          '9599600000000',
          '9599700000000',
          '9599800000000',
          '9599900009900',
          '9611100000000',
          '9611200000000',
          '9611300000000',
          '9612100000000',
          '9612200009900',
          '9612300000000',
          '9613100000000',
          '9613200000000',
          '9613300000000',
          '9613400000000',
          '9613500000000',
          '9613600000000',
          '9613700000000',
          '9613900000000',
          '9614000000000',
          '9615000000000',
          '9621000000000',
          '9622000000000',
          '9623000000000',
          '9629000000000',
          '9631000000000',
          '9632000000000',
          '9633000000000',
          '9641100000000',
          '9641200000000',
          '9642100000000',
          '9642200000000',
          '9651100000000',
          '9651200000100',
          '9651200000200',
          '9651200000300',
          '9651200000400',
          '9651200000500',
          '9651200000600',
          '9651200000700',
          '9651200000800',
          '9651200000900',
          '9651200009900',
          '9652000000100',
          '9652000000200',
          '9652000000300',
          '9652000000400',
          '9652000000500',
          '9652000009900',
          '9659000000000',
          '9661000000000',
          '9662000000000',
          '9691000000000',
          '9692100000000',
          '9692900000000',
          '9693000000000',
          '9699000000000',
          '9711000000000',
          '9712000000000',
          '9713000000000',
          '9714000000000',
          '9715000000000',
          '9721000000100',
          '9721000000200',
          '9721000000300',
          '9721000000400',
          '9721000009900',
          '9722001000000',
          '9722002000000',
          '9723000000100',
          '9723000000200',
          '9723000009900',
          '9729000000000',
          '9731000000000',
          '9732000000100',
          '9732000000200',
          '9732000000300',
          '9732000000400',
          '9732000000500',
          '9732000000600',
          '9732000009900',
          '9791000000000',
          '9799000000000',
          '9800000000000',
          '9900000000000'
        ],
        text: 'Servicios'
      }
    ],
    0: [
      {
        id: 'CASILLA_023400_MON',
        cabys: [],
        text: 'Exportación de bienes'
      },
      {
        id: 'CASILLA_023500_MON',
        cabys: [],
        text: 'Exportación de servicios'
      },
      {
        id: 'CASILLA_023600_MON',
        cabys: [
          '1632002000200',
          '2502000000100',
          '2502000000200',
          '2616000000000',
          '2686000000100',
          '2689099000100',
          '2712001010100',
          '2712001010200',
          '2712001020100',
          '2712001020200',
          '2719001000200',
          '2719004010000',
          '2719004990200',
          '2791101010100',
          '2821001000000',
          '3191400991200',
          '3219305000100',
          '3219306000100',
          '3219903010100',
          '3219903010200',
          '3219903019900',
          '3219999000700',
          '3219999000800',
          '3221000000000',
          '3222000000100',
          '3222000000200',
          '3222000009900',
          '3229100000000',
          '3229200000000',
          '3229900000100',
          '3229900009900',
          '3331100010000',
          '3331100020100',
          '3331100029900',
          '3331200000000',
          '3332000000100',
          '3333000000200',
          '3333000000300',
          '3333000009900',
          '3334200000000',
          '3334900000000',
          '3336001000100',
          '3336001000200',
          '3336001000300',
          '3337099010100',
          '3337099010200',
          '3337099019900',
          '3342900000000',
          '3425003019900',
          '3425003990000',
          '3466401030100',
          '3466499030100',
          '3466499039900',
          '3532201990800',
          '3532304000200',
          '3532304000300',
          '3532304000700',
          '3532399000200',
          '3544001020100',
          '3544001020200',
          '3544001020300',
          '3544001020400',
          '3544001029900',
          '3544002000000',
          '3544005010000',
          '3544005020100',
          '3544005029900',
          '3544099990100',
          '3544099990200',
          '3544099990300',
          '3569106000100',
          '3569106000200',
          '3569106009900',
          '3569107000100',
          '3569107009900',
          '3569199000100',
          '3569199000200',
          '3569199000300',
          '3569199000400',
          '3569201000000',
          '3569202000000',
          '3569203000000',
          '3569206010000',
          '3569206990000',
          '3569207000100',
          '3569207000200',
          '3569207000300',
          '3569207000400',
          '3569207000500',
          '3569207000600',
          '3569207000700',
          '3569207000800',
          '3569207009900',
          '3569299010000',
          '3626001990100',
          '3626099000300',
          '3627001010000',
          '3627002040100',
          '3627002080200',
          '3627002100100',
          '3627002100200',
          '3627002100300',
          '3627002990300',
          '3627002990400',
          '3633001010300',
          '3633002010200',
          '3633002990100',
          '3633003000200',
          '3633099990100',
          '3695099050000',
          '3699007010100',
          '3699007010200',
          '3699007010300',
          '3699007010400',
          '3699007010500',
          '3699007010600',
          '3699007010700',
          '3699007010800',
          '3699007010900',
          '3699007020100',
          '3699007020200',
          '3699007020300',
          '3699007020400',
          '3699007020500',
          '3699007029900',
          '3699007030100',
          '3699007030200',
          '3699007030300',
          '3699007030400',
          '3699007039900',
          '3699007990200',
          '3699007990300',
          '3699007990400',
          '3699007990500',
          '3699007990600',
          '3699007990700',
          '3699007999900',
          '3719502010000',
          '3719502020000',
          '3719502030000',
          '3719502040100',
          '3719502049900',
          '3719502050000',
          '3719502990000',
          '3719503000100',
          '3719503000200',
          '3719503000300',
          '3719503000400',
          '3719503009900',
          '3719504000100',
          '3719504000200',
          '3719504000300',
          '3719599010100',
          '3719599010200',
          '3719599019900',
          '3719599990100',
          '3719599990200',
          '3719599990300',
          '3719599999900',
          '3791001030100',
          '3791002990200',
          '3794000010100',
          '3794000010200',
          '3794000019900',
          '3993900000000',
          '4299901020200',
          '4299902991700',
          '4299902991800',
          '4323001020100',
          '4323002000300',
          '4325499010000',
          '4342002010300',
          '4391399000400',
          '4391399000500',
          '4391401020100',
          '4391401020200',
          '4391401020300',
          '4391401029900',
          '4391402010000',
          '4393102040100',
          '4393102040200',
          '4393102049900',
          '4393201010000',
          '4393201020000',
          '4393201030000',
          '4442800000000',
          '4693999030100',
          '4769100000000'
        ],
        text: 'Venta local de bienes'
      },
      {
        id: 'CASILLA_023700_MON',
        cabys: [
          '6121400000100',
          '6122900000100',
          '6742000000100',
          '6763000000100',
          '8363200000100',
          '8395002000100',
          '8431100000000',
          '8461100000100',
          '8462100000100',
          '8599900000100',
          '8715400000400',
          '8811001000100',
          '9322100000000',
          '9322200000000',
          '9322300000000',
          '9330101000000',
          '9330200000000',
          '9330301000000',
          '9341100000000',
          '9349100009900',
          '9349200000000',
          '9349300000000',
          '9351000009900',
          '9511000000100',
          '9512000010100',
          '9512000010200',
          '9512000010300',
          '9512000010400',
          '9512000010500',
          '9512000010600',
          '9512000010700',
          '9512000010800',
          '9512000010900',
          '9512000019900',
          '9520000000100',
          '9599900000100',
          '9612200000100'
        ],
        text: 'Servicios prestados a nivel local'
      },
      {
        id: 'CASILLA_023800_MON',
        cabys: [
          '7112101000000',
          '7112102000000',
          '7112105000100',
          '7112106000100',
          '7112107000100',
          '7112199000000',
          '7112201000000',
          '7112202000000',
          '7112205000100',
          '7112206000100',
          '7112207000100',
          '7112299000000',
          '7113301000000',
          '7113905000000',
          '7113906000000',
          '7113908000000',
          '7113910000000',
          '7114001000200',
          '7114001009900',
          '7114002010200',
          '7114002019900',
          '7119001000000',
          '7119002000100',
          '7153001000100',
          '7159200000100',
          '7159300000300',
          '7159900000100',
          '7164000000000'
        ],
        text: 'Créditos para descuento de facturas y arrendamientos financieros u operativos en función financiera'
      },
      {
        id: 'CASILLA_023900_MON',
        cabys: [
          '7211100000100',
          '7211200000200'
        ],
        text: 'Arrendamientos destinados a viviendas y accesorios, así como los lugares de culto religioso'
      },
      {
        id: 'CASILLA_024000_MON',
        cabys: [],
        text: 'Arrendamientos utilizados por micro y pequeñas empresas'
      },
      {
        id: 'CASILLA_024100_MON',
        cabys: ['6911200000100'],
        text: 'Suministro de energía eléctrica residencial no mayor a 280 Kw/h'
      },
      {
        id: 'CASILLA_024200_MON',
        cabys: ['6921000000100'],
        text: 'Venta o entrega de agua residencial no mayor a 30m³'
      },
      {
        id: 'CASILLA_024300_MON',
        cabys: [],
        text: 'Autoconsumo de bienes y servicios sin aplicación previa de manera total o parcial de los créditos'
      },
      {
        id: 'CASILLA_024400_MON',
        cabys: [
          '4811001010000',
          '4811001020000',
          '4811001030100',
          '4811001030200',
          '4811001030300',
          '4811001039900',
          '4811001990100',
          '4811001999900',
          '4811002010100',
          '4811002019900',
          '4811002990100',
          '4811002999900',
          '4811003000000',
          '4811004000000',
          '4811098000100',
          '4811098000200',
          '4811098000300',
          '4811098000400',
          '4811098009900',
          '4812101000000',
          '4812102000000',
          '4812103000000',
          '4812104000000',
          '4812105000000',
          '4812106000000',
          '4812107000000',
          '4812108000000',
          '4812109000000',
          '4812110010000',
          '4812110020000',
          '4812110030000',
          '4812110040000',
          '4812110050000',
          '4812110060000',
          '4812110070100',
          '4812110079900',
          '4812110080000',
          '4812110090000',
          '4812110100000',
          '4812110110000',
          '4812110120000',
          '4812110130000',
          '4812110140000',
          '4812110150000',
          '4812110160000',
          '4812110990000',
          '4812111000000',
          '4812198030100',
          '4812198030200',
          '4812198039900',
          '4812198040000',
          '4812198050000',
          '4812198060000',
          '4812199990000',
          '4813001000000',
          '4813002010100',
          '4813002019900',
          '4813002990100',
          '4813002990200',
          '4813002990300',
          '4813002999900',
          '4813003000100',
          '4813003000200',
          '4813003009900',
          '4813004000100',
          '4813004000200',
          '4813004000300',
          '4813004009900',
          '4813005010100',
          '4813005010200',
          '4813005019900',
          '4813005990100',
          '4813005990200',
          '4813005999900',
          '4813006010000',
          '4813006020000',
          '4813006030000',
          '4813006990000',
          '4813007000100',
          '4813007000200',
          '4813007000300',
          '4813007000400',
          '4813007000500',
          '4813007009900',
          '4813008000100',
          '4813008000200',
          '4813008000300',
          '4813008000400',
          '4813008000500',
          '4813008000600',
          '4813008000700',
          '4813008000800',
          '4813008000900',
          '4813008001000',
          '4813008001100',
          '4813008001200',
          '4813008001300',
          '4813008001400',
          '4813008001500',
          '4813008001600',
          '4813008001700',
          '4813008001800',
          '4813008001900',
          '4813008002000',
          '4813008002100',
          '4813008002200',
          '4813008002300',
          '4813008002400',
          '4813008002500',
          '4813008002600',
          '4813008009900',
          '4813009000000',
          '4813010000000',
          '4813011010100',
          '4813011010200',
          '4813011019900',
          '4813011990100',
          '4813011999900',
          '4813098010100',
          '4813098010200',
          '4813098010300',
          '4813098020100',
          '4813098020200',
          '4813098029900',
          '4813098030100',
          '4813098030200',
          '4813098030300',
          '4813098039900',
          '4813098040000',
          '4813098050000',
          '4813098990100',
          '4813098990200',
          '4813098990300',
          '4813098990400',
          '4813098990500',
          '4813098990600',
          '4813098990700',
          '4813098990800',
          '4813098990900',
          '4813098991000',
          '4813098991100',
          '4813098991200',
          '4813098999900',
          '4813099000100',
          '4813099000200',
          '4813099000300',
          '4813099000400',
          '4813099000500',
          '4813099000600',
          '4813099000700',
          '4813099000800',
          '4813099000900',
          '4813099001000',
          '4813099001100',
          '4813099009900',
          '4814000000100',
          '4814000009900',
          '4815001010200',
          '4815001020200',
          '4815002010200',
          '4815002020000',
          '4815002030000',
          '4815003000100',
          '4815003009900',
          '4815004000100',
          '4815004000200',
          '4815004000300',
          '4815004000400',
          '4815004009900',
          '4815005010100',
          '4815005019900',
          '4815005020000',
          '4815005990000',
          '4815006010200',
          '4815006020000',
          '4815007010000',
          '4815007020000',
          '4815007030000',
          '4815007990000',
          '4815008000100',
          '4815008009900',
          '4815009019900',
          '4815009020100',
          '4815009029900',
          '4815010010000',
          '4815010020100',
          '4815010020200',
          '4815010020300',
          '4815010029900',
          '4815011000100',
          '4815011000200',
          '4815011009900',
          '4815012010100',
          '4815012010200',
          '4815012010300',
          '4815012019900',
          '4815012020200',
          '4815012020300',
          '4815012029900',
          '4815012030199',
          '4815012030299',
          '4815012039999',
          '4815012040100',
          '4815012040200',
          '4815012040300',
          '4815012050100',
          '4815012050200',
          '4815012050300',
          '4815012059900',
          '4815012990100',
          '4815013010000',
          '4815013030100',
          '4815013030200',
          '4815013039900',
          '4815014010100',
          '4815014019900',
          '4815014020000',
          '4815014030000',
          '4815014040000',
          '4815014990000',
          '4815015010000',
          '4815015020000',
          '4815015030000',
          '4815015040000',
          '4815015050000',
          '4815015060000',
          '4815015070000',
          '4815015080000',
          '4815015090000',
          '4815015100000',
          '4815015110000',
          '4815015990000',
          '4815016000000',
          '4815017010000',
          '4815017020000',
          '4815017030000',
          '4815017040000',
          '4815017050000',
          '4815017990000',
          '4815018000000',
          '4815019020100',
          '4815019029900',
          '4815020010000',
          '4815020990000',
          '4815021010000',
          '4815021020000',
          '4815021990000',
          '4815022010000',
          '4815022020000',
          '4815022990000',
          '4815023000000',
          '4815024010100',
          '4815024010200',
          '4815024019900',
          '4815024990000',
          '4815025000000',
          '4815026000000',
          '4815027000000',
          '4815028000000',
          '4815029000000',
          '4815030000000',
          '4815031000000',
          '4815032000000',
          '4815033000000',
          '4815034000000',
          '4815035000000',
          '4815037010100',
          '4815037010200',
          '4815037010300',
          '4815037010400',
          '4815037010500',
          '4815037010600',
          '4815037019900',
          '4815037020100',
          '4815037020200',
          '4815037020300',
          '4815037029900',
          '4815037030100',
          '4815037030200',
          '4815037030300',
          '4815037030400',
          '4815037030500',
          '4815037039900',
          '4815037040100',
          '4815037040200',
          '4815037040300',
          '4815037040400',
          '4815037040500',
          '4815037040600',
          '4815037040700',
          '4815037049900',
          '4815037050100',
          '4815037050200',
          '4815037059900',
          '4815037060100',
          '4815037069900',
          '4815037070100',
          '4815037070200',
          '4815037070300',
          '4815037079900',
          '4815037080100',
          '4815037080200',
          '4815037080300',
          '4815037089900',
          '4815037090100',
          '4815037090200',
          '4815037090300',
          '4815037099900',
          '4815037100100',
          '4815037100200',
          '4815037100300',
          '4815037109900',
          '4815037990100',
          '4815037999900',
          '4815098010100',
          '4815098019900',
          '4815098020000',
          '4815098030100',
          '4815098030200',
          '4815098030300',
          '4815098039900',
          '4815098040100',
          '4815098040200',
          '4815098049900',
          '4815098050100',
          '4815098059900',
          '4815098060100',
          '4815098060200',
          '4815098069900',
          '4815098070100',
          '4815098070200',
          '4815098070300',
          '4815098079900',
          '4815098080100',
          '4815098080200',
          '4815098080300',
          '4815098080400',
          '4815098089900',
          '4815098090100',
          '4815098090200',
          '4815098099900',
          '4815098100100',
          '4815098109900',
          '4815098110100',
          '4815098119900',
          '4815098120100',
          '4815098120200',
          '4815098129900',
          '4815098990100',
          '4815098990200',
          '4815098990300',
          '4815098990400',
          '4815098999900',
          '4815099000100',
          '4815099000200',
          '4815099000300',
          '4815099000400',
          '4815099000500',
          '4815099000600',
          '4815099000700',
          '4815099000800',
          '4815099000900',
          '4815099001000',
          '4815099001100',
          '4815099001200',
          '4815099001300',
          '4815099001400',
          '4815099001500',
          '4815099001600',
          '4815099001700',
          '4815099001800',
          '4815099001900',
          '4815099002000',
          '4815099002100',
          '4815099002200',
          '4815099002300',
          '4815099009900',
          '4816001010000',
          '4816001020100',
          '4816001029900',
          '4816001030000',
          '4816002010000',
          '4816002020100',
          '4816002020200',
          '4816002029900',
          '4816002030100',
          '4816002030200',
          '4816002039900',
          '4816002040000',
          '4816002050000',
          '4816002990000',
          '4816099000200',
          '4816099000300',
          '4816099000400',
          '4816099009900',
          '4817101010100',
          '4817101010200',
          '4817101010300',
          '4817101010400',
          '4817101019900',
          '4817101020100',
          '4817101020200',
          '4817101020300',
          '4817101020400',
          '4817101020500',
          '4817101020600',
          '4817101020700',
          '4817101020800',
          '4817101029900',
          '4817101030100',
          '4817101030200',
          '4817101039900',
          '4817101990100',
          '4817101999900',
          '4817102010100',
          '4817102010200',
          '4817102010300',
          '4817102019900',
          '4817102020000',
          '4817102990000',
          '4817103010100',
          '4817103019900',
          '4817103020100',
          '4817103020200',
          '4817103020300',
          '4817103029900',
          '4817103030000',
          '4817103040100',
          '4817103040200',
          '4817103040300',
          '4817103049900',
          '4817103050100',
          '4817103059900',
          '4817103060100',
          '4817103060200',
          '4817103060300',
          '4817103060400',
          '4817103069900',
          '4817103070000',
          '4817103990100',
          '4817103999900',
          '4817104010000',
          '4817104020000',
          '4817104030100',
          '4817104030200',
          '4817104039900',
          '4817104040100',
          '4817104040200',
          '4817104040300',
          '4817104049900',
          '4817104050100',
          '4817104059900',
          '4817104060100',
          '4817104069900',
          '4817104070100',
          '4817104079900',
          '4817104080100',
          '4817104089900',
          '4817104090100',
          '4817104099900',
          '4817104990100',
          '4817104990200',
          '4817104990300',
          '4817104990400',
          '4817104990500',
          '4817104999900',
          '4817105010100',
          '4817105019900',
          '4817105020000',
          '4817105030100',
          '4817105030200',
          '4817105030300',
          '4817105030400',
          '4817105030500',
          '4817105039900',
          '4817105990000',
          '4817106010000',
          '4817106020000',
          '4817106030100',
          '4817106030200',
          '4817106030300',
          '4817106030400',
          '4817106030500',
          '4817106039900',
          '4817106040100',
          '4817106040200',
          '4817106040300',
          '4817106049900',
          '4817106050100',
          '4817106050200',
          '4817106050300',
          '4817106050400',
          '4817106050500',
          '4817106059900',
          '4817106060100',
          '4817106060200',
          '4817106069900',
          '4817106070100',
          '4817106070200',
          '4817106070300',
          '4817106070400',
          '4817106070500',
          '4817106079900',
          '4817106980000',
          '4817106990000',
          '4817107010100',
          '4817107010200',
          '4817107010300',
          '4817107010400',
          '4817107019900',
          '4817107020100',
          '4817107020200',
          '4817107029900',
          '4817108010100',
          '4817108010200',
          '4817108010300',
          '4817108019900',
          '4817108020100',
          '4817108020200',
          '4817108029900',
          '4817108030000',
          '4817108040000',
          '4817108990000',
          '4817109000100',
          '4817199000100',
          '4817199000200',
          '4817199000300',
          '4817199000400',
          '4817199000500',
          '4817199009900',
          '4817201010000',
          '4817201020000',
          '4817201030000',
          '4817201990000',
          '4817202000000',
          '4817203010000',
          '4817203020000',
          '4817203030100',
          '4817203030200',
          '4817203990000',
          '4817204000000',
          '4818001010100',
          '4818001020100',
          '4818001020200',
          '4818001020300',
          '4818001020400',
          '4818001030100',
          '4818001030200',
          '4818001030300',
          '4818001040000',
          '4818001050100',
          '4818001059900',
          '4818001990100',
          '4818001990200',
          '4825101010200',
          '4825101990200',
          '4825104000200',
          '4825202010100',
          '4825202020100',
          '4825202030100',
          '4825302000000',
          '4825305000000',
          '4825307000000',
          '4825308000000',
          '4825309000000',
          '4825310010000',
          '4825310020000',
          '4825311010000',
          '4825311020000',
          '4825312000000',
          '4825315010000',
          '4825399010100',
          '4828106010100',
          '4828199000100',
          '4828199000200',
          '4831101030100',
          '4831101030200',
          '4831101030300',
          '4831402000100',
          '4832999010000'
        ],
        text: 'Venta de sillas de ruedas y similares, equipo ortopédico, prótesis y equipo médico'
      },
      {
        id: 'CASILLA_024500_MON',
        cabys: [],
        text: 'Venta de bienes y servicios a instituciones públicas y privadas exentas'
      },
      {
        id: 'CASILLA_024600_MON',
        cabys: [
          '9210000000100',
          '9210000000200',
          '9220000000100',
          '9220000000200',
          '9231000000100',
          '9231000000200',
          '9232000000100',
          '9232000000200',
          '9233000000100',
          '9233000000200',
          '9234000000100',
          '9234000000200',
          '9241000000100',
          '9241000000200',
          '9242000000100',
          '9242000000200',
          '9251000000100',
          '9251000000200',
          '9252000000100',
          '9252000000200'
        ],
        text: 'Aranceles por matrícula y créditos de cursos en universidades públicas y servicios de educación privada autorizados por el MEP y/o CONESUP'
      },
      {
        id: 'CASILLA_024700_MON',
        cabys: ['6411401000100'],
        text: 'Servicios de transporte terrestre de pasajeros y cabotaje de personas con concesión'
      },
      {
        id: 'CASILLA_024800_MON',
        cabys: [
          '4911100000000',
          '4911200000100',
          '4911200000200',
          '4911200000300',
          '4911301000100',
          '4911301000200',
          '4911302000100',
          '4911302000200',
          '4911303000100',
          '4911303000200',
          '4911304000100',
          '4911304000200',
          '4911305000100',
          '4911305000200',
          '4911306010100',
          '4911306010200',
          '4911306020100',
          '4911306020200',
          '4911307010100',
          '4911307010200',
          '4911307020100',
          '4911307020200',
          '4911308010100',
          '4911308010200',
          '4911308020100',
          '4911308020200',
          '4911308030100',
          '4911308030200',
          '4911308040100',
          '4911308040200',
          '4911308050100',
          '4911308050200',
          '4911308060000',
          '4911308070000',
          '4911308080000',
          '4911309000100',
          '4911309000200',
          '4911310000100',
          '4911310000200',
          '4911311000100',
          '4911311000200',
          '4911312000000',
          '4911313000000',
          '4911314000000',
          '4911315000000',
          '4911399000000',
          '4911401000100',
          '4911401000200',
          '4911401000300',
          '4911402000100',
          '4911402000200',
          '4911402000300',
          '4911403000100',
          '4911403000200',
          '4911403000300',
          '4911404000000',
          '4911405000100',
          '4911405000200',
          '4911405000300',
          '4911406000100',
          '4911406000200',
          '4911406000300',
          '4911407000100',
          '4911407000200',
          '4911407000300',
          '4911408000100',
          '4911408000200',
          '4911409000100',
          '4911409000200',
          '4911410000100',
          '4911410000200',
          '4911411000100',
          '4911411000200',
          '4911412000100',
          '4911412000200',
          '4911413000100',
          '4911413000200',
          '4911413000300',
          '4911414000100',
          '4911414000200',
          '4911414000300',
          '4911415000100',
          '4911415000200',
          '4911415000300',
          '4911416000100',
          '4911416000200',
          '4911417000000',
          '4911418000000',
          '4911419000100',
          '4911419000200',
          '4911419000300',
          '4911420000000',
          '4911421000000',
          '4911422000000',
          '4911423000000',
          '4911424000000',
          '4911425000000',
          '4911426000000',
          '4911427000000',
          '4911499000000',
          '4911600000000',
          '4991100000000',
          '4991200000100',
          '4991200000200',
          '4991200000300',
          '4991200000400',
          '4991301000000',
          '4991302000000',
          '4992200000100',
          '4992200000200',
          '4994201000000'
        ],
        text: 'Venta, arrendamiento y leasing de autobuses y embarcarciones'
      },
      {
        id: 'CASILLA_024900_MON',
        cabys: ['8811001000100'],
        text: 'Comisiones por el servicio de subasta ganadera y transacción de animales vivos en dichas subastas'
      },
      {
        id: 'CASILLA_025000_MON',
        cabys: [
          '0211100010100',
          '0211100010200',
          '0211100019900',
          '0211200010100',
          '0211200019900',
          '0211900010100',
          '0211900019900',
          '0212200010100',
          '0212200019900',
          '0212300010100',
          '0212300019900',
          '0213100010100',
          '0213100019900',
          '0214000010100',
          '0214000019900',
          '0215100010000'
        ],
        text: 'Venta, comercialización y matanza de animales vivos (semovientes) de industria pecuaria'
      }
    ]
  }
}
