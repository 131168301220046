<template>
  <v-container fluid>
    <v-data-table
      :headers="headersItems"
      :items="getItemsTable"
      class="elevation-1"
      show-select
      v-model="selected"
      :loading="sendingMessage"
      item-key="id"
      loading-text="Cargando... Por favor espere"
      sort-by="date"
      sort-desc
      single-expand
      show-expand
      dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Compras y gastos pendientes de Clasificar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            :items="[
              {value: 'commercialCode', text: 'Código del producto'},
              {value: 'code', text: 'CABYS'},
            ]"
            v-model="classifyBy"
            class="mt-7"
            style="width: 180px"
            dense
            label="Clasificar por"
          ></v-select>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-btn @click="clickUpload" color="secondary" class="mx-2"  :loading="uploadingVoucher"><v-icon left>mdi-upload</v-icon>Subir</v-btn>
                <v-btn @click="sendMessages" color="primary" class="mx-2" :disabled="disabledSend" :loading="sendingMessage">Enviar</v-btn>
                <input type="file" ref="xml" accept="application/xml" @change="handleFileUpload" hidden>
              </v-col>
            </v-row>
          </v-form>
        </v-toolbar>
      </template>
      <!--<template v-slot:item.code="{ item }">
        <router-link :to="`/pendingItem/${item.code}`">{{ item.code }}</router-link>
      </template>-->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p v-for="voucher in item.vouchers" :key="voucher">
            <router-link :to="'/voucher/'+voucher">{{voucher}}</router-link>
          </p>
        </td>
      </template>
      <template v-slot:item.type="{ item }">
        <v-select
          :items="item.value < getbaseSalary25PC
            ? [
                {value: '01', text: 'Inventario'},
                {value: '03', text: 'Gastos y costos'},
              ]
              : [
                {value: '01', text: 'Inventario'},
                {value: '02', text: 'Activo fijo'},
                {value: '03', text: 'Gastos y costos'},
              ]
          "
          style="width: 180px"
          v-model="item.type"
          dense
        ></v-select>
      </template>
      <template v-slot:item.to="{ item }" dense>
        <v-select
          :items="[
            {value: '01', text: 'Producto Existente'},
            {value: '02', text: 'Producto Nuevo'},
          ]"
          v-model="item.to"
          style="width: 180px"
          dense
          v-if="item.type === '01'"
        ></v-select>
        <!--<v-select
          :items="getTypeConcept"
          v-model="item.concept"
          label="Concepto"
          style="width: 180px"
          dense
          v-if="item.type === '03'"
        ></v-select>-->
        <DialogAccounts
          v-if="item.type === '03'"
          label="Cuenta de gasto"
          :showField="true"
          v-model="item.concept"
          :filter="'5'"
        ></DialogAccounts>
        <v-autocomplete
          :items="depreciationCodes"
          v-model="item.category"
          label="Categoria Hacienda"
          style="width: 180px"
          dense
          item-text="asset"
          return-object
          v-if="item.type === '02'"
        ></v-autocomplete>
      </template>
      <template v-slot:item.product="{ item }" dense>
        <v-autocomplete
          :items="itemsProductsCode"
          v-model="item.product"
          :filter="customFilter"
          item-text="name"
          item-value="code"
          dense
          v-if="item.to === '01' && item.type === '01'"
          style="width: 180px"
        ></v-autocomplete>
        <v-text-field
          label="Descripción"
          v-model="item.product"
          v-if="item.to === '02' || item.type === '03'"
          style="width: 180px"
        >
        </v-text-field>
        <v-text-field
          label="Precio de venta"
          type="number"
          v-model="item.newPrice"
          min="1"
          v-if="item.to === '02'"
        >
        </v-text-field>
      </template>
      <template v-slot:item.costCenter="{ item }" dense>
        <v-autocomplete
          :items="getCostCenters"
          item-text="name"
          item-value="code"
          label="Centro de Costo"
          v-model="item.cc"
          dense
          style="width: 180px"
          v-if="item.type !== '01'"
        ></v-autocomplete>
        <v-select
          :items="getCellarsEntry"
          v-model="item.cellar"
          label="Bodega"
          item-text="name"
          item-value="code"
          style="width: 180px"
          dense
          v-else
        ></v-select>
      </template>
      <template v-slot:item.factor="{ item }" dense>
        <v-text-field
          label="Factor"
          type="number"
          v-model="item.factor"
          min="1"
          v-if="item.type === '01' && (item.to === '02' || item.to === '01')"
        >
        </v-text-field>
        <v-select
          :items="getTypeADItems"
          v-model="item.typeCost"
          label="Clasificación"
          style="width: 180px"
          dense
          v-if="item.type === '03'"
        ></v-select>
      </template>
    </v-data-table>
  </v-container>
</template>
<script type="text/javascript">
// import xml2js from 'xml2js'
// import moment from 'moment'
import axios from 'axios'
// import tools from '@/api/tools.js'
import depreciationCodes from '@/assets/depreciation'
import { mapGetters, mapActions, mapState } from 'vuex'
// import redaApp from '../api/redaApp'
import DialogAccounts from '@/components/DialogAccounts.vue'
import firebaseApi from '../api/firebaseApi'
export default {
  name: 'PendingVouchers',
  components: {
    DialogAccounts
  },
  mounted () {
    // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))

    this.reset()
    this.listener = firebaseApi.db.collection('voucherItems')
      .where('receiver', '==', this.getCompany.identification)
      .where('classified', '==', false)
      .onSnapshot(({ docs }) => {
        this.voucherItems = []
        for (let i = 0; i < docs.length; i++) {
          const doc = docs[i]
          this.voucherItems.push({ ...doc.data(), uid: doc.id })
        }
      })
  },
  beforeDestroy () {
    console.log('beforeDestroy')
    this.listener()
    this.listenerProducts()
  },

  // updated () {console.log(this.selected);},
  data () {
    return {
      classifyBy: 'commercialCode',
      listener: null,
      depreciationCodes,
      expanded: [],
      itemsTable: [],
      voucherItems: [],
      selected: [],
      sendingMessage: false,
      uploadingVoucher: false,
      listenerProducts: null,
      products: []
    }
  },
  methods: {
    reset () {
      if (this.listenerProducts) this.listenerProducts()
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerProducts = firebaseApi.db.collection('products')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.products = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.products.push({ ...doc.data(), uid: doc.id })
          }
        })
    },
    removeDuplicate () {
      this.getItemsDuplicate.forEach(item => {
        this.deleteVoucherReceiver(item)
          .then(() => {
            console.log(item.key)
          })
          .catch(error => {
            console.error(error)
          })
      })
    },
    submitVR ({ voucher, xml }) {
      return new Promise((resolve, reject) => {
        this.insertVoucherReceiver(voucher)
          .then(() => {
            return this.insertXml({ key: voucher.key, xml: xml })
          })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })/**/
      })
    },
    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.name === null ? '' : item.name.toLowerCase()
      const textTwo = item.code === null ? '' : item.code.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    downloadXml (key) {
      this.downloadXmlFE({ key: key, xml: 'xml' })
    },
    handleFileUpload () {
      this.uploadingVoucher = true
      const xmlFile = this.$refs.xml.files[0]
      // this.certName = this.$refs.file.files[0].name;
      if (xmlFile.type !== 'text/xml' && xmlFile.type !== 'application/xml') {
        alert('El archivo debe ser un XML')
        return
      }
      const formData = new FormData()
      formData.append('xml', xmlFile)
      axios.post(process.env.VUE_APP_API_BASE_URL + '/api/upload-xml-voucher', formData)
        .then(() => {
          return this.showSuccess('Comprobante agragado.')
        })
        .catch(error => {
          console.error(error)
          return this.showError(error)
        })
        .finally(() => {
          this.uploadingVoucher = false
        })
      // console.log(document.getElementById('input-285'));
    },
    clickUpload () {
      this.$refs.xml.click()
    },
    deleteItem (item) {
      // const index = this.desserts.indexOf(item)
      this.$vex.dialog.confirm({
        message: 'Are you absolutely sure you want to destroy the alien planet?',
        callback: (value) => {
          if (value) {
            this.deleteVoucherReceiver(item)
              .then(() => { this.$router.push('resetPending') })
              .catch(error => {
                console.log(error)
              })
          }
        }
      })
    },
    getProductTmp (code) {
      const product = this.products.find(item => {
        return item.commercialCode === code
      })
      return product
    },
    getItem (items, code) {
      const product = items.find(item => {
        return item.commercialCode === code
      })
      return product
    },
    newAsset ({ category, cc, product, value, item }) {
      return new Promise((resolve, reject) => {
        this.insertFixedAsset({
          id: null,
          costCenters: [{ cc, quantity: item.quantity }],
          acquisitionDate: item.date,
          description: product,
          category: category.asset,
          annualDepreciationFee: category.annualDepreciation,
          usefulLife: category.usefulLife,
          depreciationMethod: '01',
          docsRef: [
            { type: 'voucher', key: item.voucher }
          ],
          historicalCost: Number(value) + Number(item.otherChargesItem),
          voucher: item.voucher
        })
          .then((asset) => {
            resolve(asset)
          })
          .catch((error) => {
            reject(error)
            console.error(error)
            this.showError('Error al cargar artículo ' + item.voucher)
          })
      })
    },
    newAO ({ concept, cc, typeCost, product, code, item }) {
      return new Promise((resolve, reject) => {
        const alias = {
          code,
          concept: concept.id,
          costCenter: cc,
          description: product,
          type: typeCost
        }
        this.insertAddressingOrderAlias(alias)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            console.error(error)
            this.showError('Error al cargar artículo ' + item.code)
            reject(error)
          })
      })
    }, /**/
    inventary (itemInventory) {
      return new Promise((resolve, reject) => {
        // let product = {}
        const { item } = itemInventory
        if (itemInventory.to === '02') {
          const taxes = []
          if (item.taxes && this.getCompany.regimen?.codigo === 1) {
            item.taxes.forEach(tax => {
              taxes.push({
                code: tax.code,
                rateCode: tax.rateCode,
                rate: tax.rate
              })
            })
          }
          const newProduct = {
            company: this.getCompany.identification,
            commercialCode: item.commercialCode,
            unit: item.unit,
            description: itemInventory.product,
            inventoryable: true,
            prices: [{ priceList: 'general', currency: 'CRC', price: Number(itemInventory.newPrice) }],
            taxes: taxes
          }
          console.log({ ...newProduct })
          this.insertProduct(newProduct)
            .then(productRes => {
              // product = Object.assign({}, productRes)
              const alias = {
                company: this.getCompany.identification,
                code: this.classifyBy === 'commercialCode'
                  ? item.commercialCode?.toString() + '|' + item.issuer?.toString()
                  : item.code,
                factor: Number(itemInventory.factor),
                cellar: itemInventory.cellar,
                product: item.commercialCode
              }
              return this.insertInventoryAliases(alias)
            })
            .then(() => {
              this.showSuccess('Artículo ' + item.commercialCode + ' clasificado')
              resolve()
            })
            .catch(error => {
              console.error(error)
              this.showError('Error al cargar artículo ' + item.commercialCode)
              reject(error)
            })
        } else {
          const alias = {
            company: this.getCompany.identification,
            code: this.classifyBy === 'commercialCode'
              ? item.commercialCode?.toString() + '|' + item.issuer?.toString()
              : item.code,
            factor: Number(itemInventory.factor),
            cellar: itemInventory.cellar,
            product: itemInventory.product
          }
          this.insertInventoryAliases(alias)
            .then(() => {
              this.showSuccess('Artículo ' + item.commercialCode + ' clasificado')
              resolve()
            })
            .catch(error => {
              console.error(error)
              this.showError('Error al cargar artículo ' + item.commercialCode)
              reject(error)
            })
        }
      })
    }, /**/
    sendMessages () {
      this.sendingMessage = true
      const stack = []
      this.selected.forEach(item => {
        stack.push(this.sortOutDetails(item)
          .then(key => {
            return this.showSuccess(`Artículo ${item.code} clasificado correctamente`)
          })
          .catch(error => {
            console.log(error)
            this.showError('El artículo ' + item.code + ' no pudó ser enviado')
          }))
      })
      Promise.allSettled(stack).finally(() => {
        this.sendingMessage = false
      })
    },
    async sortOutDetails (item) {
      if (item.type === '01') {
        await this.inventary(item)
      } else if (item.type === '02') {
        await this.newAsset(item)
      } else if (item.type === '03') {
        await this.newAO(item)
      }
      return item
    },
    ...mapActions('products', [
      'insertProduct'
    ]),
    ...mapActions('siinco', [
      'insertAddressingOrder',
      'insertAddressingOrderAlias'
    ]),
    ...mapActions('message', [
      'showSuccess',
      'showError'
    ]),
    ...mapActions('fixedAssets', [
      'insertFixedAsset'
    ]),
    ...mapActions('messages', [
      'newMessage'
    ]),
    ...mapActions('vouchers', [
      'insertVoucherReceiver',
      'insertXml',
      'deleteVoucherReceiver',
      'downloadXmlFE'
    ]),
    ...mapActions('inventories', [
      'insertInventory',
      'insertInventoryAliases'
    ])
  },
  computed: {
    headersItems () {
      return [
        { text: 'Codigo', value: this.classifyBy },
        { text: 'Detalle', value: 'description' },
        { text: 'Tipo', value: 'type' },
        { text: '', value: 'to' },
        { text: '', value: 'product' },
        { text: '', value: 'costCenter' },
        { text: '', value: 'factor' },
        { text: '', value: 'data-table-expand' }
      ]
    },
    getProducts () {
      return this.products
    },
    itemsProductsCode () {
      const items = []
      for (var i = this.getProducts.length - 1; i >= 0; i--) {
        if (this.getProducts[i]) {
          items.push({ name: this.getProducts[i].description, code: this.getProducts[i].commercialCode })
        }
      }
      return items
    },
    disabledSend () {
      return !(this.selected.length > 0)
    },
    getItemsDuplicate () {
      const keys = []
      const duplicate = []
      this.getItemsTable.forEach(voucher => {
        const index = keys.indexOf(voucher.key)
        if (index > -1) {
          duplicate.push(Object.assign({}, voucher))
        } else {
          keys.push(voucher.key)
        }
      })
      return duplicate
    },
    getItemsTable () {
      const itemsTable = []
      this.voucherItems.forEach((item, i) => {
        let itemInventory
        if (
          // item.unit === 'Sp' ||
          // item.unit === 'Al' ||
          // item.unit === 'Alc' ||
          // item.unit === 'Cm' ||
          // item.unit === 'I' ||
          // item.unit === 'Os' ||
          // item.unit === 'Spe' ||
          // item.unit === 'St'
          item.unit
        ) {
          itemInventory = {
            id: i,
            code: item.code,
            commercialCode: item.commercialCode,
            description: item.description,
            quantity: item.quantity,
            type: '03',
            to: null,
            concept: 50202,
            cc: '2',
            cellar: null,
            typeCost: '08',
            category: null,
            product: item.description,
            factor: 1,
            newPrice: item.subtotal,
            value: item.subtotal,
            item
          }
        } else {
          itemInventory = {
            id: i,
            code: item.code,
            commercialCode: item.commercialCode,
            description: item.description,
            quantity: item.quantity,
            type: '01',
            to: '02',
            concept: 50202,
            cc: '2',
            cellar: null,
            typeCost: '08',
            category: null,
            product: item.description,
            factor: 1,
            newPrice: item.subtotal,
            value: item.subtotal,
            item
          }
        }
        itemsTable.push(itemInventory)
      })
      return itemsTable.reduce((acc, curr) => {
        const index = acc.findIndex(({ code, commercialCode, item }) => {
          if (this.classifyBy === 'code') return code === curr.code
          return (curr.item?.commercialCode + curr.item?.issuer) === (commercialCode?.toString() + item.issuer?.toString())
        })
        if (index === -1) {
          const item = { ...curr, vouchers: [curr.item.voucher] }
          acc.push(item)
        } else if (curr.value > this.getbaseSalary25PC) {
          const item = { ...curr, vouchers: [curr.item.voucher] }
          acc.push(item)
        } else {
          acc[index].vouchers.push(curr.item.voucher)
        }
        return acc
      }, [])
    },
    // ...mapGetters('products', ['getProducts']),
    ...mapGetters('user', ['getUser']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('inventories', ['getInventoryAliases', 'getInventories', 'getCellarsEntry']),
    ...mapGetters('vouchers', ['getVouchersReceived']),
    ...mapState('vouchers', ['loadingVouchers']),
    ...mapGetters('cashDaily', ['getCashDaily']),
    ...mapGetters('siinco', ['getbaseSalary25PC', 'getTypeConcept', 'getCostCenters', 'getTypeADItems', 'getAddressingOrdersAliases'])
    // ...mapGetters(['getVouchersReceived','getCashDaily','getPendingVouchers','getInventoryAliases', 'getInventories','getCompany','products','getUser']),
  }
}
</script>
<style type="text/css">

</style>
