var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" Formulario de personalización "),_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Vista previa ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.isMounted)?_c('v-card',[_c('v-card-title',[_vm._v("Vista previa")]),_c('v-card-text',[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-btn',[_vm._v(" Formulario ")]),_c('v-btn',[_vm._v(" Mostrar valores ")])],1)],1),_c('v-card-text',[(_vm.display)?_c('RedaDisplay',{attrs:{"options":{
          inputs: _vm.items
        },"value":_vm.tmp},on:{"input":function($event){return _vm.setTmp($event)}}}):_c('RedaForm',{attrs:{"options":{
          inputs: _vm.items
        },"value":_vm.tmp}})],1)],1):_vm._e()],1)],1),_c('v-card-text',[_vm._l((_vm.items),function(input,i){return _c('CustomizationProductItem',{key:i,staticClass:"my-2",attrs:{"value":input},on:{"input":function($event){return _vm.update($event, i)},"delete":function($event){return _vm.deleteItem(i)}}})}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Agregar campo")],1)],2),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }