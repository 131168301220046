<template>
  <v-container  class="d-flex justify-center">
    <v-card :loading="loading" width="448">
      <v-card-title>
        Cambiar contraseña
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12">
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                id="oldPassword"
                label="Contraseña Actual"
                name="oldPassword"
                :type="showPassword ? 'text' : 'password'"
                v-model="oldPassword"
                @click:append="showPassword = !showPassword"
                @keyup.enter="auth"
                outlined
                v-if="!createPass"
              ></v-text-field>
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                id="password"
                label="Nueva Contraseña"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                @click:append="showPassword = !showPassword"
                @keyup.enter="updatePassword"
                outlined
              ></v-text-field>
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                id="confirmPassword"
                label="Confirmar Contraseña"
                name="confirmPassword"
                :type="showPassword ? 'text' : 'password'"
                v-model="confirmPassword"
                @click:append="showPassword = !showPassword"
                @keyup.enter="updatePassword"
                outlined
              ></v-text-field>
              <p class="error--text">{{errors}}</p>
              <p class="success--text">{{success}}</p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    <v-card-actions>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="auto">
        <v-btn text @click="clean">Limpiar</v-btn>
        <v-btn color="primary" @click="submitUpdatePassword">cambiar contraseña</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'UpdatePassword',
  props: {
    create: String
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      // Aquí es donde se declararían las variables de tu componente
      showPassword: false,
      oldPassword: null,
      password: null,
      confirmPassword: null,
      loading: false,
      errors: null,
      success: null
    }
  },
  computed: {
    createPass () {
      return this.create === 'new'
    }
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
    submitUpdatePassword () {
      if (!this.oldPassword && !this.create) {
        this.errors = 'Debe ingresar la contraseña actual'
        return
      }
      if (!this.password) {
        this.errors = 'Debe ingresar una nueva contraseña'
        return
      }
      if (this.password !== this.confirmPassword) {
        this.errors = 'Nueva contraseña y confirmar contraseña deben ser iguales'
        return
      }
      this.loading = true
      this.updatePassword({
        oldPassword: this.oldPassword,
        newPassword: this.password
      })
        .then((message) => {
          this.clean()
          this.showSuccess(message)
          this.$router.push({ name: 'Home', query: { updated: 'private' } })
        })
        .catch((error) => {
          this.errors = error
        })
        .finally(() => {
          this.loading = false
        })
    },
    clean () {
      this.showPassword = false
      this.oldPassword = null
      this.password = null
      this.confirmPassword = null
      this.loading = false
      this.errors = null
    },
    ...mapActions('message', ['showSuccess']),
    ...mapActions('user', ['updatePassword'])
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
