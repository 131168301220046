<template>
  <v-card>
    <v-card-text v-if="false">
      <v-btn-toggle v-model="display">
        <v-btn>
          <v-icon>mdi-table</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-chart-line</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-text>

    <v-card-text>
      <v-container fluid>
          <v-data-iterator
            :items="getFinancialReasons"
            :items-per-page="getFinancialReasons.length"
            hide-default-footer
          >

            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                  sm="6"
                >
                  <v-card>
                    <v-card-title class="subheading font-weight-bold">
                      {{ item.name }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                      <v-list-item v-for="ratio in item.ratios" :key="ratio.id">
                        <v-list-item-content>{{ ratio.name }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ ratio.value }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
/* import IncomeStatement from '@/components/IncomeStatement'
import StatementFinancialPosition from '@/components/StatementFinancialPosition'
import StatementOfCashFlows from '@/components/StatementOfCashFlows'
import StatementOfChangesInEquity from '@/components/StatementOfChangesInEquity'
import Chart from '@/components/Chart' */
export default {
  components: {
  },
  data: () => ({
    tab: 0,
    display: 0
  }),

  computed: {
    ...mapGetters('accounting', ['getFinancialReasons'])
  },

  methods: {
    ...mapMutations([]),
    ...mapActions([])
  }
}
</script>
