<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="info"
          dark
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <Task :item="item" :tasksIndexed="tasksIndexed" :tasks="tasks" @close="dialog = false" :staff="staff"></Task>
    </v-dialog>
  </div>
</template>
<script>
import Task from '@/components/Task.vue'
export default {
  name: 'TaskDialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    staff: Array,
    tasks: Array,
    item: Object,
    tasksIndexed: Object
  },
  components: {
    Task
  }
}
</script>
