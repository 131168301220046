<template>
  <v-data-table
    :headers="headers"
    :items="getLedger"
    item-key="code"
    class="elevation-1"
    :sort-by="['code']"
    hide-default-footer
    :items-per-page="getLedger.length"
    dense
  >
    <template v-slot:item.previousBalance="{ item }">
      {{ item.previousBalance | currencyFormat }}
    </template>
    <template v-slot:item.debit="{ item }">
      {{ item.debit | currencyFormat }}
    </template>
    <template v-slot:item.credit="{ item }">
      {{ item.credit | currencyFormat }}
    </template>
    <template v-slot:item.balance="{ item }">
      {{ item.balance | currencyFormat }}
    </template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Balance de comprobación</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:body.append>
      <tr>
        <td>Total</td>
        <td colspan="2"></td>
        <td class="text-right">
          {{ total.debit | currencyFormat }}
        </td>
        <td class="text-right">
          {{ total.credit | currencyFormat }}
        </td>
        <td></td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
// import accounting from '@/api/accounting'
import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'GeneralLedger',
  data: () => ({
    menuFrom: false,
    menuTo: false,
    from: null,
    to: moment().format('YYYY-MM-DD'),
    headers: [
      {
        text: 'Cuenta',
        align: 'start',
        sortable: false,
        value: 'code'
      },
      {
        text: 'Nombre de la cuenta',
        value: 'name'
      },
      { text: 'Saldo anterior', value: 'previousBalance', align: 'end' },
      { text: 'Débitos', value: 'debit', align: 'end' },
      { text: 'Créditos', value: 'credit', align: 'end' },
      { text: 'Saldo actual', value: 'balance', align: 'end' }
    ]
  }),
  methods: {
    ...mapActions([]),
    ...mapActions('accounting', [])
  },
  computed: {
    /* ledger () {
      const from = Number.isNaN(Number(this.from))
        ? Number(moment(this.from).hours(0).format('x'))
        : Number(this.from)
      const to = Number.isNaN(Number(this.to))
        ? Number(moment(this.to).hours(23).minutes(59).seconds(59)
          .format('x'))
        : Number(this.to)
      return accounting.summarize({ journal: this.getJournal, fromDate: from, toDate: to })
    }, /**/
    total () {
      let debit = 0
      let credit = 0
      this.getLedger.forEach((acc) => {
        debit += Number(acc.debit)
        credit += Number(acc.credit)
      })
      return { debit, credit }
    },
    ...mapState([]),
    ...mapState('accounting', []),
    ...mapGetters([]),
    ...mapGetters('accounting', [
      'getJournal', 'getLedger'
    ])
  }
}
</script>
