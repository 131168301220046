<template>
  <v-data-table
    class="elevation-1"
    :calculate-widths="table.calculateWidths || false"
    :caption="table.caption"
    :custom-filter="table.customFilter"
    :custom-group="table.customGroup"
    :custom-sort="table.customSort"
    :dark="table.dark || false"
    :dense="table.dense || false"
    :disable-filtering="table.disableFiltering || false"
    :disable-pagination="table.disablePagination || false"
    :disable-sort="table.disableSort || false"
    :expand-icon="table.expandIcon || '$expand'"
    :expanded="table.expanded || []"
    :fixed-header="table.fixedHeader || false"
    :footer-props="table.footerProps"
    :group-by="table.groupBy || []"
    :group-desc="table.groupDesc || []"
    :header-props="table.headerProps"
    :headers="options.headers || []"
    :headers-length="table.headersLength"
    :height="table.height"
    :hide-default-footer="table.hideDefaultFooter || false"
    :hide-default-header="table.hideDefaultHeader || false"
    :item-class="table.itemClass"
    :item-key="table.itemKey || 'id'"
    :items="value"
    :items-per-page="table.itemsPerPage || 10"
    :light="table.light || false"
    :loader-height="table.loaderHeight || 4"
    :loading="table.loading || false"
    :loading-text="table.loadingText || '$vuetify.dataIterator.loadingText'"
    :locale="table.locale || 'en-US'"
    :mobile-breakpoint="table.mobileBreakpoint || 600"
    :multi-sort="table.multiSort || false"
    :must-sort="table.mustSort || false"
    :no-data-text="table.noDataText || '$vuetify.noDataText'"
    :no-results-text="table.noResultsText || '$vuetify.dataIterator.noResultsText'"
    :options="table.options || {}"
    :page="table.page || 1"
    :search="table.search"
    :selectable-key="table.selectableKey || 'isSelectable'"
    :server-items-length="table.serverItemsLength || -1"
    :show-expand="table.showExpand || false"
    :show-group-by="table.showGroupBy || false"
    :show-select="table.showSelect || false"
    :single-expand="table.singleExpand || false"
    :single-select="table.singleSelect || false"
    :sort-by="table.sortBy || []"
    :sort-desc="table.sortDesc || []"
    :value="table.value || []"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ options.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <ImportData :name="options.importName" :humanName="options.title" v-if="options.importName"></ImportData>
        <v-dialog
          v-model="dialog"
          persistent
          v-if="!options.insert && !options.readonly"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="options.btnColor || 'primary'"
              dark
              :icon="!!options.btnIconType"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ options.btnIcon || 'mdi-plus'}}</v-icon>
              {{ options.btnText }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <RedaForm
                @valid="setValid"
                v-model="editedItem"
                :options="options.form"
                @cancel="close"
                @submitForm="save"
                :inactive="!dialog"
              ></RedaForm>
            </v-card-text>

            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="!valid"
              >
                Guardar
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
        <v-btn
          :color="options.btnColor || 'primary'"
          dark
          :icon="!!options.btnIconType"
          class="mb-2"
          @click="insertDefaultItem()"
          v-if="options.insert && options.insert.simpleInsert"
        >
          <v-icon>{{ options.btnIcon || 'mdi-plus'}}</v-icon>
          {{ options.btnText }}
        </v-btn>
        <v-row v-if="options.insert && !options.insert.simpleInsert && !options.readonly">
          <v-col cols="8">
            <v-autocomplete
              dense
              :items="options.insert.items"
              @input="insertItem"
              v-model="tmp"
              prepend-inner-icon="mdi-magnify"
              autofocus
              return-object
              :item-text="options.insert.itemText || 'text'"
              :item-value="options.insert.itemValue || 'value'"
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item[options.insert.itemValue] || data.item.value"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item[options.insert.itemText] || data.item.text"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col >
          <v-col v-if="options.customInsert" cols="4">
            <component
              :is="options.customInsert.component"
              @insert="newCustomInsert($event)"
              @input="newCustomInsert($event)"
            ></component>
          </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              ¿Estás seguro de que quieres eliminar este artículo?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Aceptar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-for="({
        value,
        editable,
        text,
        valueName,
      }, i) in headers" v-slot:[valueName]="{ item }">
      <v-edit-dialog
        :return-value.sync="item[value]"
        @save="inputEdit"
        large
        persistent
        :key="i"
      >
        <div>{{ item[value].toString() }}</div>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            {{ editable.title }}
          </div>
          <v-autocomplete
            dense
            v-model="item[value]"
            :items="editable.items || []"
            :item-text="editable.itemText || 'text'"
            :item-value="editable.itemValue || 'value'"
            :multiple="!!editable.multiple"
            :chips="!!editable.chips"
            deletable-chips
            hide-selected
            small-chips
            v-if="editable.type === 'autocomplete'"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item[options.insert.itemValue] || data.item.value"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item[options.insert.itemText] || data.item.text"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-text-field
            :type="editable.type"
            v-model="item[value]"
            :label="text"
            single-line
            autofocus
            v-else
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.orderedList="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        v-if="options.info"
        @click="$router.push(options.info.path + item[options.info.id || 'id'])"
      >
        {{ options.info.icon || 'mdi-information' }}
      </v-icon>
      <v-icon
        small
        class="mr-2"
        v-if="options.edit"
        @click="editItem(item)"
      >
        {{ options.editIcon || 'mdi-pencil' }}
      </v-icon>
      <v-icon
        small
        v-if="options.delete"
        :color="options.deletecolor || 'error'"
        @click="deleteItem(item)"
      >
        {{ options.deleteIcon || 'mdi-delete' }}
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import ImportData from './ImportData.vue'
export default {
  components: { ImportData },
  props: {
    table: {
      type: Object
    },
    options: {
      type: Object
    },
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {},
    isMounted: false,
    valid: false,
    tmp: {}
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? this.options.newItemText : this.options.editItemText
    },
    headers () {
      const headers = []
      this.options.headers.filter((header) => header.editable).forEach((header) => {
        headers.push({ ...header, valueName: `item.${header.value}` })
      })
      return headers
    }
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.close()
      }
    },
    dialogDelete (val) {
      if (!val) {
        this.closeDelete()
      }
    }
  },

  created () {
    this.editedItem = { ...this.options.defaultItem }
  },

  mounted () {
    this.isMounted = true
  },

  methods: {
    newCustomInsert (item) {
      const insertItem = { ...this.options.defaultItem }
      this.options.customInsert.inserts.forEach(({ objKey, inputKey }) => {
        if (Array.isArray(insertItem[inputKey])) {
          insertItem[inputKey].push(item[objKey])
          return
        }
        insertItem[inputKey] = item[objKey]
      })
      this.editedItem = { ...insertItem }
      this.editedIndex = -1
      this.save()
      this.tmp = {}
    },
    insertItem () {
      const insertItem = { ...this.options.defaultItem }
      this.options.insert.inserts.forEach(({ objKey, inputKey }) => {
        if (Array.isArray(insertItem[inputKey])) {
          insertItem[inputKey].push(this.tmp[objKey])
          return
        }
        insertItem[inputKey] = this.tmp[objKey]
      })
      this.editedItem = { ...insertItem }
      this.editedIndex = -1
      this.save()
      this.tmp = {}
    },
    insertDefaultItem () {
      this.editedItem = { ...this.options.defaultItem }
      this.editedIndex = -1
      this.save()
      this.tmp = {}
    },
    updateItemEditable (item, key, e, type) {
      const editedItem = { ...item }
      editedItem[key] = type === 'number'
        ? Number(e.target.value)
        : e.target.value
      this.editedIndex = this.value.indexOf(item)
      this.editedItem = { ...editedItem }
      this.save()
    },
    setValid (value) {
      this.valid = value
    },
    initialize () {},

    editItem (item) {
      this.editedIndex = this.value.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.value.indexOf(item)
      this.editedItem = { ...item }
      if (this.options.deleteConfirm) {
        this.dialogDelete = true
      } else {
        this.deleteItemConfirm()
      }
    },

    deleteItemConfirm () {
      const tableValue = [...this.value]
      this.$emit('deleteItem', tableValue[this.editedIndex])
      tableValue.splice(this.editedIndex, 1)
      this.$emit('input', tableValue)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.options.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.options.defaultItem }
        this.editedIndex = -1
      })
    },

    inputEdit () {
      const items = []
      this.value.forEach((value) => {
        const newValue = { ...value }
        this.headers.forEach((header) => {
          if (header.editable.type === 'number') {
            newValue[header.value] = Number(newValue[header.value])
          }
        })
        items.push(newValue)
      })
      this.$emit('input', items)
    },

    save () {
      const tableValue = [...this.value]
      if (this.editedIndex > -1) {
        this.$emit('updateItem', this.editedItem)
        // Object.assign(tableValue[this.editedIndex], this.editedItem);
        tableValue.splice(this.editedIndex, 1, this.editedItem)
      } else {
        this.$emit('newItem', this.editedItem)
        const newItem = { ...this.editedItem }
        this.headers.forEach((header) => {
          if (header.editable.type === 'number') {
            newItem[header.value] = Number(newItem[header.value])
          }
        })
        tableValue.push(newItem)
        console.log({ ...newItem })
      }
      this.$emit('input', tableValue)
      this.close()
    }
  }
}
</script>
