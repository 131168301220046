import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import UpdatePassword from '@/views/UpdatePassword.vue'
import TakeOrders from '@/views/TakeOrders.vue'
import Customers from '@/views/Customers.vue'
import Products from '@/views/Products.vue'
import Orders from '@/views/Orders.vue'
import BookingEngine from '@/views/BookingEngine.vue'
import Biller from '@/views/Biller.vue'
import Cellar from '@/views/Cellar.vue'
import Vouchers from '@/views/Vouchers.vue'
import VouchersReceived from '@/views/VouchersReceived.vue'
import PendingVouchers from '@/views/PendingVouchers.vue'
import Voucher from '@/views/Voucher.vue'
import ChartOfAccounts from '@/views/ChartOfAccounts.vue'
import AccountingEntries from '@/views/AccountingEntries.vue'
import Company from '@/views/Company.vue'
import Production from '@/views/Production.vue'
import AccountReceivableView from '@/views/AccountReceivableView.vue'
import AccountReceivable from '@/views/AccountReceivable.vue'
import IssueVoucher from '@/views/IssueVoucher.vue'
import FinancialReporting from '@/views/FinancialReporting.vue'
import FixedAssets from '@/views/FixedAssets.vue'
import Payments from '@/views/Payments.vue'
import Receipts from '@/views/Receipts.vue'
import Staff from '@/views/Staff.vue'
import Customer from '@/views/Customer.vue'
import Product from '@/views/Product.vue'
import Public from '@/views/Public.vue'
import TaxReturn from '@/views/TaxReturn.vue'
import CashDaily from '@/views/CashDaily.vue'
import Subscriptions from '@/views/Subscriptions.vue'
import Subscription from '@/views/Subscription.vue'
import Posts from '@/views/Posts.vue'
import Calendar from '@/views/CalendarView.vue'
import Post from '@/views/Post.vue'
import Chats from '@/views/Chats.vue'
import Account from '@/views/Account.vue'
import store from '@/store'
import AccountingConfig from '@/views/AccountingConfig.vue'
import PendingItem from '@/views/PendingItem.vue'
import BanksAndCash from '@/views/BanksAndCash.vue'
import Blogs from '@/views/Blogs.vue'
import Tasks from '@/views/Tasks.vue'
import Receipt from '@/views/Receipt.vue'
import Companies from '@/views/Companies.vue'
import ProductionOrder from '@/views/ProductionOrder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/productionOrder/:id',
    name: 'ProductionOrder',
    component: ProductionOrder,
    props: true
  },
  {
    path: '/receipt/:uid',
    name: 'Receipt',
    component: Receipt,
    props: true
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: Account,
    props: true
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: Blogs,
    meta: { restricted: true }
  },
  {
    path: '/banksAndCash',
    name: 'BanksAndCash',
    component: BanksAndCash,
    meta: { restricted: true }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: { restricted: true }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { restricted: true }
  },
  {
    path: '/posts',
    name: 'Posts',
    component: Posts
  },
  {
    path: '/accountingConfig',
    name: 'AccountingConfig',
    component: AccountingConfig,
    meta: { restricted: true }
  },
  {
    path: '/pendingItem/:code',
    name: 'PendingItem',
    component: PendingItem,
    props: true
  },
  {
    path: '/post/:uid',
    name: 'Post',
    component: Post,
    props: true
  },
  {
    path: '/chats',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/public/:component',
    name: 'Public',
    component: Public,
    props: true,
    meta: { public: true }
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product,
    props: true
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: { restricted: true }
  },
  {
    path: '/taxReturn',
    name: 'TaxReturn',
    component: TaxReturn,
    meta: { restricted: true }
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: Customer,
    props: true
  },
  {
    path: '/updatePassword/:create',
    name: 'UpdatePassword',
    component: UpdatePassword,
    props: true
  },
  {
    path: '/staff/:staff',
    name: 'Staff',
    component: Staff,
    props: true
  },
  {
    path: '/cashDaily',
    name: 'CashDaily',
    component: CashDaily,
    meta: { restricted: true }
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts,
    meta: { restricted: true }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: { restricted: true }
  },
  {
    path: '/fixedAssets',
    name: 'FixedAssets',
    component: FixedAssets,
    meta: { restricted: true }
  },
  {
    path: '/financialReporting',
    name: 'FinancialReporting',
    component: FinancialReporting,
    meta: { restricted: true }
  },
  {
    path: '/accountReceivableView',
    name: 'AccountReceivableView',
    component: AccountReceivableView,
    meta: { restricted: true }
  },
  {
    path: '/accountReceivable',
    name: 'AccountReceivable',
    component: AccountReceivable,
    meta: { restricted: true }
  },
  {
    path: '/issueVoucher',
    name: 'IssueVoucher',
    component: IssueVoucher,
    meta: { restricted: true }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/chartOfAccounts',
    name: 'ChartOfAccounts',
    component: ChartOfAccounts,
    meta: { restricted: true }
  },
  {
    path: '/production',
    name: 'Production',
    component: Production,
    meta: { restricted: true }
  },
  {
    path: '/accountingEntries',
    name: 'AccountingEntries',
    component: AccountingEntries,
    meta: { restricted: true }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: { restricted: true }
  },
  {
    path: '/biller',
    name: 'Biller',
    component: Biller,
    meta: { restricted: true }
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: Vouchers,
    meta: { restricted: true }
  },
  {
    path: '/pendingVouchers',
    name: 'PendingVouchers',
    component: PendingVouchers,
    meta: { restricted: true }
  },
  {
    path: '/vouchersReceived',
    name: 'VouchersReceived',
    component: VouchersReceived,
    meta: { restricted: true }
  },
  {
    path: '/voucher/:id',
    name: 'Voucher',
    component: Voucher,
    props: true
  },
  {
    path: '/subscription/:id',
    name: 'Subscription',
    component: Subscription,
    props: true
  },
  {
    path: '/cellar',
    name: 'Cellar',
    component: Cellar,
    meta: { restricted: true }
  },
  {
    path: '/takeOrders',
    name: 'TakeOrders',
    component: TakeOrders,
    meta: { restricted: true }
  },
  {
    path: '/bookingEngine',
    name: 'BookingEngine',
    component: BookingEngine,
    meta: { restricted: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { restricted: true }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: { restricted: true }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: { restricted: true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { restricted: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const changePass = store.getters['user/getChangePass']
  const logged = store.getters['user/getLogged']
  const authorization = store.getters['user/getAuthorizations']?.includes(to.name)

  if (!to.matched.some(record => record.meta.public)) {
    if (logged) {
      if (changePass) {
        if (to.name === 'UpdatePassword') {
          next()
        } else {
          if (to.query.updated) {
            next()
          } else {
            next({ name: 'UpdatePassword', params: { create: 'new' } })
          }
        }
        return
      }
      if (to.matched.some(record => record.meta.restricted)) {
        if (authorization) {
          next()
        } else {
          next('/')
        }
      } else {
        next()
      }
    } else {
      next('/login')
    }
  } else {
    if (to.name === 'Login' && logged) {
      next('/')
    } else {
      next()
    }
  }
})

export default router
