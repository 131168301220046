var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getPosts,"loading":_vm.loading,"sort-by":"date","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Calendario de publicaciones para RRSS")]),_c('v-spacer'),_c('GeneratePosts')],1)]},proxy:true},_vm._l((_vm.headers.filter(function (ref) {
      var editable = ref.editable;

      return !!editable;
})),function(ref,i){
      var value = ref.value;
      var editable = ref.editable;
      var text = ref.text;
      var valueName = ref.valueName;
return {key:valueName,fn:function(ref){
      var item = ref.item;
return [_c('v-edit-dialog',{key:i,attrs:{"return-value":item[value],"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, value, $event)},"update:return-value":function($event){return _vm.$set(item, value, $event)},"save":function($event){return _vm.inputEdit(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" "+_vm._s(editable.title)+" ")]),(editable.type === 'textarea')?_c('v-textarea',{attrs:{"label":text,"autofocus":""},model:{value:(item[value]),callback:function ($$v) {_vm.$set(item, value, $$v)},expression:"item[value]"}}):_c('v-text-field',{attrs:{"type":editable.type,"label":text,"single-line":"","autofocus":""},model:{value:(item[value]),callback:function ($$v) {_vm.$set(item, value, $$v)},expression:"item[value]"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item[value]))])])]}}}),{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.$router.push('/post/'+item.uid)}}},[_vm._v(" mdi-information ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.dateString",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date, 'x').format('LLL'))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }