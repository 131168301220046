<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Producto código {{ id }}
    </div>
    <v-card
      elevation="2"
      class="d-flex flex-row justify-space-around my-10"
    >
      <div class="ma-5 pl-11">
        <div class="text-center caption">
          Ventas
        </div>
        <div class="title text-center" v-if="false">
          {{ product.summary.netTotal }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Compras
        </div>
        <div class="title text-center">
          {{ product.commercialCode }}
        </div>
      </div>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
      v-if="!loading"
      :loading="sending"
    >
      <v-tabs v-model="tab">
        <v-tab>
          Información generales
        </v-tab>
        <v-tab>
          Compras y ventas
        </v-tab>
        <v-tab>
          Información avanzada
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <RedaForm
            :options="form"
            v-model="tmp.product"
            @submitForm="submitProduct"
            :loading="sending"
            ></RedaForm>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <Period></Period>
          <product-vouchers :product="product.commercialCode"></product-vouchers>
          <product-vouchers :product="product.commercialCode" :received="true" v-if="product.inventoryable"></product-vouchers>
        </v-tab-item>
        <v-tab-item>
          <advanced-product-info :product="product" @update="updateProduct"></advanced-product-info>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
    </v-card>
    <Movements :product="product.commercialCode" v-if="false"></Movements>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductVouchers from '@/components/ProductVouchers.vue'
import Movements from '@/components/Movements.vue'
import Period from '@/components/Period.vue'
import AdvancedProductInfo from '@/components/AdvancedProductInfo.vue'

export default {
  name: 'Product',
  props: ['id'],
  components: { ProductVouchers, Period, Movements, AdvancedProductInfo },
  data: () => ({
    tab: 0,
    tmp: {
      product: {}
    },
    provinces: [],
    counties: [],
    districts: [],
    product: {},
    loading: true,
    sending: false
  }),
  methods: {
    updateProduct () {
      this.getProduct(this.id)
        .then((product) => {
          this.product = product
          this.tmp.product = { ...this.product }
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loading = false
        })
    },
    submitProduct () {
      this.sending = true
      this.insertProduct({ ...this.tmp.product })
        .then(() => this.showSuccess('Producto guardado'))
        .catch((e) => {
          this.showError('Ocurrio un error al guardar producto')
          console.error(e)
        })
        .finally(() => { this.sending = false })
    },
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ]),
    ...mapActions('products', ['insertProduct', 'getProduct'])
  },
  computed: {
    form () {
      return {
        submitText: 'Actualizar producto',
        showSubmit: true,
        inputs: [
          {
            id: 'de01',
            label: 'CABYS',
            items: [
            ],
            name: 'cabys',
            value: 'cabys',
            type: 'text',
            cols: 6
          },
          {
            id: 'de01',
            text: 'Código',
            label: 'Código',
            name: 'commercialCode',
            value: 'commercialCode',
            type: 'display',
            cols: 6
          },
          {
            id: 'de02',
            label: 'Detalle',
            name: 'description',
            value: 'description',
            type: 'text',
            cols: 12
          },
          {
            id: 'de04',
            label: 'Inventariable',
            name: 'inventoryable',
            value: 'inventoryable',
            type: 'checkbox',
            cols: 6
          },
          {
            id: 'de05',
            label: 'Unidad de medida',
            name: 'unit',
            value: 'unit',
            type: 'select',
            items: [],
            rules: 'required',
            cols: 6
          },
          {
            id: 'de06',
            name: 'taxes',
            value: 'taxes',
            type: 'table',
            options: {
              title: 'Impuestos',
              readonly: true,
              headers: [
                { text: 'Impuesto', value: 'code' },
                { text: 'Tipo de tarifa', value: 'rateCode' },
                { text: 'Tarifa', value: 'rate' }
              ]
            },
            table: {
              dense: true,
              hideDefaultFooter: true
            },
            cols: 12
          },
          {
            id: 'de07',
            label: 'Precios',
            name: 'prices',
            value: 'prices',
            type: 'table',
            options: {
              title: 'Lista de precios',
              headers: [
                { text: 'Tipo', value: 'priceList' },
                { text: 'Moneda', value: 'currency' },
                { text: 'Precio', value: 'price' },
                { text: '', value: 'actions' }
              ],
              edit: true,
              delete: true,
              form: {
                newItemText: 'Nuevo precio',
                editItemText: 'Editar precio',
                showSubmit: true,
                submitText: 'Guardar',
                showCancel: true,
                cancelText: 'Cancelar',
                inputs: [
                  {
                    id: 'p00',
                    label: 'Tipo',
                    name: 'priceList',
                    value: 'priceList',
                    type: 'select',
                    items: this.getCompany.priceList,
                    rules: 'required',
                    cols: 12
                  },
                  {
                    id: 'p01',
                    label: 'Moneda',
                    name: 'currency',
                    value: 'currency',
                    type: 'select',
                    items: ['CRC', 'USD'],
                    rules: 'required',
                    cols: 12
                  },
                  {
                    id: 'p02',
                    label: 'Precio',
                    name: 'price',
                    value: 'price',
                    type: 'number',
                    rules: 'required|decimal:2',
                    cols: 12
                  }
                ]
              },
              defaultItem: {
                priceList: 'general',
                currency: 'CRC',
                price: 0
              }
            },
            table: {
              dense: true,
              hideDefaultFooter: true
            },
            cols: 12
          }
        ]
      }
    },
    ...mapGetters('company', ['getCompany'])
  },
  mounted () {
    this.getProduct(this.id)
      .then((product) => {
        this.product = product
        this.tmp.product = { ...this.product }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
