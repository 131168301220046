var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"headline my-7 ml-2"},[_vm._v(" Contabilidad ")]),_c('v-card',{staticClass:"my-10",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" Importar saldo inicial ")]),_c('v-card-text',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 mx-2",attrs:{"loading":_vm.loading}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-import")]),_vm._v(" Importar saldo inicial desde Excel ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v(" Importación de saldo inicial ")]),_c('v-card-text',[_c('p',[_vm._v("Puedes crear el saldo inicial de las cuentas contables a partir de una hoja de cálculo de Excel")]),_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"excel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Cargar hoja de cálculo","prepend-icon":"mdi-microsoft-excel","error-messages":errors,"clearable":"","required":"","readonly":""},on:{"click":_vm.clickUpload},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"dataFile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('input',{ref:"file",attrs:{"type":"file","hidden":""},on:{"change":_vm.handleFileUpload,"input":validate}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-text',[_vm._v(" Descarga la plantilla "),_c('a',{on:{"click":_vm.downloadExcel}},[_vm._v("aquí")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Importar datos ")])],1)],1)]}}])})],1)],1),_c('v-card-title',[_vm._v(" Cuentas por defecto del sistema ")]),_c('v-card-text',[_c('RedaForm',{attrs:{"options":_vm.form},on:{"submitForm":_vm.submitAccoutingConfig},model:{value:(_vm.tmp.config),callback:function ($$v) {_vm.$set(_vm.tmp, "config", $$v)},expression:"tmp.config"}})],1)],1),_c('v-card',{staticClass:"my-10",attrs:{"elevation":"2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }