<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn :loading="loading" icon v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <NewTask
        @close="dialog = false"
        @submit="refresh"
        :tasks="tasks"
        :staff="staff"
        :task="item"
      ></NewTask>
    </v-dialog>
  </div>
</template>
<script>
import NewTask from '@/components/NewTask.vue'
export default {
  name: 'TaskEdit',
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  props: {
    staff: Array,
    tasks: Array,
    item: Object,
    tasksIndexed: Object
  },
  components: {
    NewTask
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    }
  }
}
</script>
