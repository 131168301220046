var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("Compañia")]),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"off","label":"Usuario para la facturación electrónica*","error-messages":errors,"autofocus":"","readonly":_vm.apiValid,"required":""},model:{value:(_vm.api.user),callback:function ($$v) {_vm.$set(_vm.api, "user", $$v)},expression:"api.user"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"passwordApi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"off","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"id":"passwordApi","label":"Contraseña para la facturación electrónica*","error-messages":errors,"name":"passwordApi","type":_vm.showPassword ? 'text' : 'password',"readonly":_vm.apiValid,"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.api.password),callback:function ($$v) {_vm.$set(_vm.api, "password", $$v)},expression:"api.password"}})]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"apiValid","rules":"required"}},[_c('v-checkbox',{staticStyle:{"display":"none"},model:{value:(_vm.apiValid),callback:function ($$v) {_vm.apiValid=$$v},expression:"apiValid"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.apiValid),expression:"!apiValid"}],attrs:{"color":"secondary","loading":_vm.loadingApi},on:{"click":_vm.validateApi}},[_vm._v(" Verificar Usuario ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.apiValid),expression:"apiValid"}],on:{"click":function($event){_vm.apiValid = false}}},[_vm._v(" Editar Usuario ")])],1),_c('v-col',[(_vm.apiValid)?_c('v-container',{staticClass:"success--text text-left"},[_vm._v("Usuario verificado")]):_vm._e(),(_vm.apiError)?_c('v-container',{staticClass:"error--text text-left"},[_vm._v(_vm._s(_vm.apiError))]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Llave Crptógrafica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Llave Criptográfica*","prepend-icon":"mdi-paperclip","error-messages":errors,"clearable":!_vm.certValid,"required":"","readonly":""},on:{"click":_vm.clickUpload},model:{value:(_vm.certName),callback:function ($$v) {_vm.certName=$$v},expression:"certName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"certName","rules":"required|mimes:application/x-pkcs12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('input',{ref:"file",attrs:{"type":"file","accept":"application/x-pkcs12","hidden":""},on:{"change":_vm.handleFileUpload,"input":validate}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"pin","rules":"required|digits:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Pin*","error-messages":errors,"readonly":_vm.certValid,"required":""},model:{value:(_vm.api.pin),callback:function ($$v) {_vm.$set(_vm.api, "pin", $$v)},expression:"api.pin"}})]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"certName","rules":"required|mimes:application/x-pkcs12"}}),_c('ValidationProvider',{attrs:{"name":"certValid","rules":"required"}},[_c('v-checkbox',{staticStyle:{"display":"none"},model:{value:(_vm.certValid),callback:function ($$v) {_vm.certValid=$$v},expression:"certValid"}})],1),_c('ValidationProvider',{attrs:{"name":"","rules":"required"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.environment),callback:function ($$v) {_vm.environment=$$v},expression:"environment"}},[_c('v-radio',{attrs:{"label":"Pruebas","value":"stag"}}),_c('v-radio',{attrs:{"label":"Producción","value":"prod"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.certValid),expression:"!certValid"}],attrs:{"color":"secondary","loading":_vm.loadingCert},on:{"click":_vm.checkcert}},[_vm._v(" Verificar Llave ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.certValid),expression:"certValid"}],on:{"click":function($event){_vm.certValid = false}}},[_vm._v(" Editar Llave ")])],1),_c('v-col',[(_vm.certValid)?_c('v-container',{staticClass:"success--text text-left"},[_vm._v("Llave verificada")]):_vm._e(),(_vm.certError)?_c('v-container',{staticClass:"error--text text-left"},[_vm._v(_vm._s(_vm.certError))]):_vm._e()],1)],1)],1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.newApiVouchers}},[_vm._v(" Guardar ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }