<template>
  <v-row  align="center" justify="center" class="my-auto">
    <v-card v-if="success" align="center" justify="center">
      <v-img
      ><v-icon size="100px"
      >mdi-cash-check</v-icon></v-img>
      <v-card-title class="headline">Pago Exitoso</v-card-title>
      <v-card-text>
        <v-alert type="success">
          ¡Su pago se ha procesado exitosamente!
        </v-alert>
        <div>
          <p>Gracias por realizar su pago en línea. Su {{ voucherType }} ha sido procesada correctamente.</p>
        </div>
      </v-card-text>
      <v-card-actions><v-btn v-if="false" color="primary" :href="site" block x-large>Finalizar</v-btn></v-card-actions>
    </v-card>
    <v-card v-if="waitingVerification" max-width="600" align="center" justify="center">
      <v-img
      ><v-icon size="100px"
      >mdi-cash-sync</v-icon></v-img>
      <v-card-title class="headline">Esperando verificación</v-card-title>
      <v-card-text>
        <v-alert type="info">
          ¡Seguimos buscando tu pago!
        </v-alert>
        <div>
          <p>Entendemos lo importante que es tu tiempo y queremos mantenerte informado. Te notificaremos por correo electrónico una vez que hayamos verificado el pago a través de SINPE Móvil.</p>
          <p>Si tienes alguna pregunta o inquietud en este proceso, no dudes en visitar nuestra página <a href="https://soporteonvo.zohodesk.com/portal/es/home">Soporte ONVO</a> o contactarnos a través de WhatsApp al número 7019-6686.</p>
          <p>Agradecemos tu paciencia y estamos aquí para brindarte la asistencia que necesites.</p>
        </div>
      </v-card-text>
      <v-card-actions><v-btn v-if="false" color="primary" :href="site" block x-large>Finalizar</v-btn></v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    voucherType () {
      const types = {
        '01': 'Factura Electrónica',
        '02': 'Nota de Débito Electrónica',
        '03': 'Nota de Crédito Electrónica',
        '04': 'Tiquete Electrónico',
        '08': 'Factura Electrónica de Compras',
        '09': 'Factura Electrónica de Exportación',
        10: 'Factura Digital'
      }
      const type = this.$route.query.key.substring(29, 31)
      return types[type]
    },
    site () {
      return this.$route.query.site || 'https://redabitscr.com'
    },
    success () {
      return this.$route.query.message === 'success'
    },
    waitingVerification () {
      return this.$route.query.message === 'awaiting'
    }
  }
}
</script>
