<template>
  <DataTableCRUD
    :table="table"
    :options="options"
    :value="getItems"
    @updateItem="insertItem($event)"
    @newItem="insertItem($event)"
  ></DataTableCRUD>
</template>

<script>
import { mapActions } from 'vuex'
// import tools from '@/api/tools.js'
export default {
  props: ['moduleName'],
  name: 'RedaTableView',
  data () {
    return {}
  },
  methods: {
    insertItem (item) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(this.moduleName + '/' + this.moduleVuex.insertItem, item)
          .then((asset) => {
            resolve(asset)
            this.showSuccess(this.options.messageSuccess || 'Registrado exitosamente')
          })
          .catch((error) => {
            console.error(error)
            this.showError(this.options.messageError || 'Error al registrar')
          })
      })
    },
    ...mapActions('message', [
      'showSuccess',
      'showError'
    ])
  },
  computed: {
    moduleVuex () {
      const singularItem = this.singularItem ?? this.moduleName.slice(0, this.moduleName.length - 1)
      const items = `${this.moduleName}/get${this.moduleName[0].toUpperCase() + this.moduleName.slice(1)}`
      const item = `${this.moduleName}/get${singularItem[0].toUpperCase() + singularItem.slice(1)}`
      const insertItem = `insert${singularItem[0].toUpperCase() + singularItem.slice(1)}`
      return {
        singularItem,
        moduleName: this.moduleName,
        items,
        item,
        insertItem
      }
    },
    getItems () {
      return this.$store.getters[`${this.moduleVuex.items}`]
    },
    table () {
      return {
        dense: true
      }
    },
    options () {
      console.log(this.$store.getters[`${this.moduleName}/getOptions`])
      return this.$store.getters[`${this.moduleName}/getOptions`]
    }
  }
}
</script>
