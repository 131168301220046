<template>
    <div>
      <v-list>
        <v-list-item v-for="(value, key) in obj" :key="key">
          <v-list-item-content>
            <v-list-item-title>{{ key }}</v-list-item-title>
            <template v-if="Array.isArray(value)">
              <v-data-table :headers="getHeaders(value)" :items="value" hide-default-footer></v-data-table>
            </template>
            <template v-else-if="typeof value === 'object' && value !== null">
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(innerValue, innerKey) in value" :key="innerKey">
                    <v-list-item-content>
                      <v-list-item-title>{{ innerKey }}</v-list-item-title>
                      <template v-if="Array.isArray(innerValue)">
                        <v-data-table :headers="getHeaders(innerValue)" :items="innerValue" hide-default-footer></v-data-table>
                      </template>
                      <template v-else-if="typeof innerValue === 'object' && innerValue !== null">
                        <!-- Recursively render nested objects -->
                        <DisplayObject :obj="innerValue"></DisplayObject>
                      </template>
                      <template v-else>
                        <v-list-item-subtitle>{{ innerValue }}</v-list-item-subtitle>
                      </template>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
            <template v-else>
              <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      required: true
    }
  },
  methods: {
    getHeaders (items) {
      if (items.length === 0) {
        return []
      }
      const keys = Object.keys(items[0])
      return keys.map(key => ({ text: key, value: key }))
    }
  }
}
</script>
