<template>
  <v-card>
    <v-card-title class="subheading font-weight-bold">
      Nº {{ item.num }}
    </v-card-title>
    <v-card-subtitle>{{ item.dateString }}</v-card-subtitle>

    <v-divider></v-divider>

    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Cuenta
            </th>
            <th class="text-left">
              Nombre de la cuenta
            </th>
            <th class="text-left">
              Debe
            </th>
            <th class="text-left">
              Haber
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="account in item.accounts"
            :key="account.account.code"
          >
            <td>{{ account.account.code }}</td>
            <td>{{ account.account.name }}</td>
            <td class="text-right">{{ account.debit | currencyFormat }}</td>
            <td class="text-right">{{ account.credit | currencyFormat }}</td>
          </tr>
          <tr>
            <th>Total</th>
            <th></th>
            <th class="text-right">{{ item.debits | currencyFormat }}</th>
            <th class="text-right">{{ item.credits | currencyFormat }}</th>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Detalle</v-list-item-title>
          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="voucher">
        <v-list-item-content>
          <v-list-item-title>Comprobante</v-list-item-title>
          <v-list-item-subtitle><router-link :to="'/voucher/' + voucher">{{ voucher }}</router-link></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="downloadPdf(voucher)">
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!-- <v-card-title>Detalle</v-card-title>
    <v-card-text>{{ item.description }}</v-card-text> -->
    <!-- <v-card-actions>
      <v-btn
        @click="$emit('editEntry')"
        icon
      >
        <v-icon dark>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'Entry',
  components: {
  },
  props: ['item'],
  data: () => ({
  }),
  methods: {
    ...mapActions('vouchers', ['downloadDoc', 'downloadPdf']),
    ...mapActions([]),
    ...mapActions('accounting', [])
  },
  computed: {
    voucher () {
      if (this.item.voucher) return this.item.voucher
      const regex = /\b\d{50}\b/g
      const numbersArray = this.item.description.match(regex)
      if (numbersArray) return numbersArray[0]
      return null
    },
    ...mapState([]),
    ...mapState('accounting', []),
    ...mapGetters([]),
    ...mapGetters('accounting', [])
  }
}
</script>
