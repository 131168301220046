<template>
  <v-card :loading="loadingEmit">
    <v-card-title class="text-center pb-0">
      <v-row>
        <v-col cols="12" sm="6" class="d-flex align-center justify-left">Ingresar Pedido</v-col>
        <v-col cols="12" sm="6" class="py-0 my-0">
          <!-- <v-select
            :items="[
              {value: '01', text: 'Factura electrónica', },
              {value: '02', text: 'Nota de débito electrónica', },
              {value: '03', text: 'Nota de crédito electrónica', },
              {value: '04', text: 'Tiquete electrónico', },
              {value: '08', text: 'Factura electrónica de compras', },
              {value: '10', text: 'Tiquete Régimen Simplificado', },
            ]"
            :value="voucherType"
            @input="setVoucherType"
            label="Tipo de comprobante"
            required
          ></v-select> -->
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text >
    <v-row>
      <v-col cols="12" sm="4" class="py-0 my-0">
        <v-container fluid class="py-0 my-0">
          <v-col cols="12" class="py-0 my-0">
            <v-subheader class="text-left pl-0 ml-0">Datos del cliente</v-subheader>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <RedaForm
              ref="clientData"
              :options="formClient"
              v-model="tmp.client"
              @input="setClientTMP($event)"
            ></RedaForm>
            <!-- <v-container style="height: 150px;" v-show="!getClient">
                <v-btn fab color="secondary" @click.stop="dialogClient = true">
                   <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-container>
            <v-dialog v-model="dialogClient" persistent max-width="600px">
              <v-card >
                <v-card-title>
                  <span class="headline">Cliente</span>
                </v-card-title>
                <v-card-text>
                  <RedaForm
                    :options="formClient"
                    v-model="tmp.client"
                  ></RedaForm>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogClient = false; cleanClient()">Cerrar</v-btn>
                  <v-btn color="primary" @click="insertClient">Agregar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-simple-table v-if="getClient" dense>
              <tbody>
                <tr>
                  <th><v-icon>mdi-account-box</v-icon></th>
                  <td> {{getClient.identification}} </td>
                </tr>
                <tr>
                  <th><v-icon>mdi-account</v-icon></th>
                  <td> {{getClient.name}} </td>
                </tr>
                <tr>
                  <th><v-icon>mdi-email</v-icon></th>
                  <td> {{getClient.email}} </td>
                </tr>
                <tr>
                  <th><v-icon>mdi-phone</v-icon></th>
                  <td> {{getClient.phone}} </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div class="d-flex flex-row-reverse pa-0 ma-0">
              <v-btn color="error" @click="deleteClient(); cleanClient()" v-if="getClient" icon><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn @click.stop="dialogClient = true; tmp.client = { ...getClient }" v-if="getClient" icon><v-icon>mdi-pencil</v-icon></v-btn>
            </div> -->
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-subheader class="text-left pl-0 ml-0">Dirección</v-subheader>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <RedaForm
            ref="shippingAddress"
            :options="formOptionsAddress"
            :value="getAddress"
            @input="setShippingAddress($event)"></RedaForm>
          </v-col>
          <v-col cols="12"  class="py-0 my-0">
          </v-col>
          <v-col cols="12" class="py-0 my-0">
          </v-col>
        </v-container>
        <!-- <v-container fluid style="height: 270px;" class="pb-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              Dirección
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <RedaForm
              ref="shippingAddress"
              :options="formOptionsAddress"
              :value="getAddress"
              @input="setShippingAddress($event)"></RedaForm>
            </v-col>
            <v-col cols="12"  class="py-0 my-0">
            </v-col>
            <v-col cols="12" class="py-0 my-0">
            </v-col>
        </v-container> -->
        <div class="text-left" v-if="isMovil">
          <v-btn class="mx-2" x-large :disabled="loadingEmit" @click.stop="newOrder" color="primary">Realizar pedido</v-btn>
          <v-btn class="mx-2" x-large  @click="cleanOrder" >Limpiar</v-btn>
        </div>
        </v-col>
        <v-col cols="12" sm="8">
        <v-container fluid>
          <v-card min-height="550">
          <v-card-text>
          <v-subheader class="py-0 my-0">Detalle de productos o servicios</v-subheader>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-row class="py-0 my-0">
                <v-col cols="12" sm="4">
                  <v-row class="py-0 my-0">
                    <v-col cols="9" class="xr-0 py-0 my-0">
                      <v-text-field
                        @keyup.enter="insertProductFromCode"
                        v-model="tmp.commandCode"
                        prepend-inner-icon="fa-barcode"
                        autofocus
                        :key="keyCommand"
                        class="xr-0 py-0 my-0"
                      ></v-text-field>
                    </v-col >
                    <v-col cols="3" class="xl-0 py-0 my-0 px-0" >
                      <v-btn fab color="secondary" @click.stop="dialogItems = true" small>
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                </v-col>
                <v-col cols="12" sm="4" class="xr-0 py-0 my-0">
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersItems"
            :items="itemsDataTable"
            :hide-default-footer="true"
            :disable-sort="true"
            dense
            :items-per-page="itemsDataTable.length"
            height="300"
          >
            <template v-slot:item.index="{ item }">
              <v-btn color="error" icon @click="deleteItem(item.index)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
            <template v-slot:item.quantity="{ item }">
              <input type="number" :value="getItems[item.index].quantity" @change="updateItemQuantity" :id="item.index" style="width: 70px" min="0">
            </template>
          </v-data-table>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="8" class="pa-0 ma-0">
              <v-textarea
                label="Observaciones"
                outlined
                clearable
                :value="getObservations"
                @input="setObservations"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4"  class="py-0 my-0">
              <v-list dense>
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="title pa-0 ma-0 text-right">{{summary.grossTotal}}</v-list-item-title>
                      <v-list-item-subtitle class="pa-0 ma-0 text-right">Total Venta Neta</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="title pa-0 ma-0 text-right">{{summary.taxTotal}}</v-list-item-title>
                      <v-list-item-subtitle class="pa-0 ma-0 text-right">Total Impuesto</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="title pa-0 ma-0 text-right">{{summary.netTotal}}</v-list-item-title>
                      <v-list-item-subtitle class="pa-0 ma-0 text-right">Total Comprobante</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-col>
          </v-row>

          <v-dialog v-model="dialogItems" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Producto o servicio</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <RedaForm
                    ref="formItem"
                    :options="formItem"
                    v-model="tmp.item"
                  ></RedaForm>
                  <RedaForm
                    v-if="tmp.item.taxed"
                    ref="formTaxes"
                    :options="formTaxes"
                    v-model="tmp.taxes"
                    @input="setRate"
                  ></RedaForm>
                  <v-row>
                    <v-col cols="12">
                      <h3>Total IVA: {{ itemTaxNet }}</h3>
                      <h3>Total Linea: {{ itemNetTotal }}</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="keyCommand++; dialogItems = false; cleanItem()">Cerrar</v-btn>
                <v-btn color="primary" @click="submitItem">Agregar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        </v-card>
        </v-container>
      </v-col>
    </v-row>
    </v-card-text>
    <v-card-actions>
      <div class="text-left" v-if="!isMovil">
        <v-btn class="mx-2" x-large :disabled="loadingEmit" @click.stop="newOrder" color="primary">Realizar pedido</v-btn>
        <v-btn class="mx-2" x-large :disabled="loadingEmit" @click="cleanOrder" >Limpiar</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script type="text/javascript">
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapMutations, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import tools from '@/api/tools'
import { units } from '@/assets/codes.js'

export default {
  name: 'NewOrder',
  data: () => ({
    rateCodeValues: {
      '01': 0,
      '02': 1,
      '03': 2,
      '04': 4,
      '05': 0,
      '06': 4,
      '07': 8,
      '08': 13
    },
    keyCommand: 1,
    dialogItems: false,
    dialogClient: false,
    loadingEmit: false,
    isMounted: false,
    headersItems: [
      { text: 'Cantidad', value: 'quantity' },
      { text: 'Detalle', value: 'description' },
      { text: 'Precio Unitario', value: 'unitPrice' },
      { text: 'IVA %', value: 'taxRate' },
      { text: 'Total', value: 'netTotal' },
      { text: 'Remover', value: 'index' }
    ],
    addressLocationItems: {
      provinces: [],
      conties: [],
      districts: []
    },
    tmp: {
      client: {
        id: null,
        typeId: null,
        identification: null,
        name: null,
        email: null,
        phone: null,
        priceList: 'normal',
        search: null
      },
      clientDefault: {
        id: null,
        typeId: null,
        identification: null,
        name: null,
        email: null,
        phone: null,
        priceList: 'normal',
        search: null
      },
      item: {
        cabys: null,
        code: null,
        quantity: 1,
        description: null,
        unitPrice: null,
        taxed: false,
        netTotal: 0,
        unit: 'Unid',
        search: null
      },
      taxes: {
        code: '01',
        rateCode: '08',
        rate: 13
      },
      itemDefault: {
        cabys: null,
        code: null,
        quantity: 1,
        description: null,
        unitPrice: null,
        taxed: false,
        netTotal: 0,
        unit: 'Unid',
        search: null
      },
      commandCode: null,
      searchCode: null,
      searchClient: null
    },
    priceList: 'general'
  }),
  mounted () {
    this.isMounted = true
    tools.getProvince()
      .then((provinces) => {
        // this.infoLocationItems.provinces = provinces;
        this.addressLocationItems.provinces = provinces
      })
      .catch((error) => {
        console.error(error)
      })
  },
  watch: {
    'tmp.item.search': function (newValue, oldValue) {
      if (newValue) {
        this.tmp.taxes = { ...newValue.taxes[0] }
      }
    },
    'tmp.client.search': function (newValue, oldValue) {
      if (newValue && newValue.addresses) {
        this.setShippingAddress({ ...newValue.addresses[0] })
      } else {
        this.setShippingAddress({
          province: null,
          conty: null,
          district: null,
          others: null
        })
      }
    }
  },
  methods: {
    ...mapMutations('orders', [
      'setClientDefault',
      'updateQuantity',
      'setObservations',
      'setOrderDefault',
      'setAddress',
      'setClient',
      'deleteItem'
    ]),
    ...mapActions('orders', [
      'submitOrder',
      'insertClient',
      'addItem'
    ]),
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ]),
    cleanOrder () {
      this.tmp.client = {
        ...{
          typeId: null,
          identification: null,
          name: null,
          email: null,
          phone: null,
          priceList: 'normal',
          search: null
        }
      }
      // this.$refs.clientData.clean();
      // this.$refs.shippingAddress.clean();
      // this.setOrderDefault();
    },
    setClientTMP ({ id, typeId, identification, name, email, phone }) {
      this.setClient({ id, typeId, identification, name, email, phone })
    },
    cleanItem: function () {
      this.tmp.item = {
        cabys: null,
        code: null,
        quantity: 1,
        description: null,
        unitPrice: null,
        taxed: false,
        netTotal: 0,
        unit: 'Unid'
      }
      this.tmp.taxes = {
        code: '01',
        rateCode: '08',
        rate: 13
      }
      this.tmp.searchCode = null
    },
    setRate: function () {
      this.tmp.taxes.rate = this.rateCodeValues[this.tmp.taxes.rateCode]
    },
    submitItem () {
      this.$refs.formItem.validate()
        .then((success) => {
          if (success) {
            if (this.tmp.item.taxed) return this.$refs.formTaxes.validate()
            return true
          } else {
            throw new Error('Los datos del producto no son válidos')
          }
        })
        .then((successTaxes) => {
          if (successTaxes) {
            return this.insertItem()
          } else {
            throw new Error('Los datos del IVA no son válidos')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    insertItem () {
      let newTaxes = {}
      let newItem = {}
      const product = this.getItems.find((item) => {
        // return this.customFilter(item, this.tmp.searchCode);
        return item.commercialCode === this.tmp.searchCode
      })
      if (!product) {
        /* if (!this.checkStock({code: this.tmp.item.code, quantity: round10(this.tmp.item.quantity, -5)})) {
          let errMsj = 'No hay suficientes productos codigo '+this.tmp.item.code+' en bodega.'
          this.showError(errMsj)
          return
        } */
        newTaxes = {
          code: this.tmp.taxes.code,
          rateCode: this.tmp.taxes.rateCode,
          rate: this.tmp.taxes.rate
          // taxFactor: this.itemTaxFactor,
          // total: this.itemTaxNet
        }
        let unitPrice = Number(this.tmp.item.unitPrice)
        if (this.priceList === 'general') {
          console.log(this.priceList === 'general')
          const product = this.getProductsItems.find((prod) => (prod.code === this.tmp.item.code))
          console.log(product)
          if (product && product.pricingConditions && product.pricingConditions.length > 0) {
            if (product.pricingConditions[0].min <= tools.round10(this.tmp.item.quantity, -5)) {
              const wholesaler = product.prices.find(({ priceList }) => priceList === 'mayorista')
              unitPrice = Number(wholesaler.price)
            }
          }
        }
        newItem = {
          cabys: this.tmp.item.cabys,
          commercialCode: this.tmp.item.code,
          quantity: tools.round10(this.tmp.item.quantity, -5),
          unit: this.tmp.item.unit,
          description: this.tmp.item.description,
          unitPrice: unitPrice
          // total: this.itemSubtotal,
          // subtotal: this.itemSubtotal,
          // taxableBase: this.itemSubtotal,
          // taxNet: this.itemTaxNet,
          // netTotal: this.itemNetTotal,
        }
        if (this.tmp.item.taxed) {
          newItem.taxes = [newTaxes]
        }
        // this.invoice.items.push(newItem)
        this.addItem(newItem)
      } else {
        const indexItem = this.getItems.indexOf(product)
        const quantityItem = Number(product.quantity) + Number(this.tmp.item.quantity)
        this.updateQuantity({ index: indexItem, quantity: quantityItem })
      }
      this.dialogItems = false
      this.cleanItem()
    },
    newOrder () {
      this.loadingEmit = true
      this.$vuetify.goTo(0)
      this.$refs.clientData.validate()
        .then((successClient) => {
          if (successClient) {
            this.setClientTMP(this.tmp.client)
            return this.$refs.shippingAddress.validate()
          } else {
            throw new Error('Los datos del cliente no son válidos')
          }
        })
        .then((successAddress) => {
          if (successAddress) {
            return this.submitOrder()
          } else {
            throw new Error('Los datos de la dirección no son válidos')
          }
        })
        .then((msj) => {
          this.showSuccess(msj)
          this.$refs.clientData.clean()
          this.$refs.shippingAddress.clean()
          this.setOrderDefault()
          console.log(msj)
        })
        .finally(() => {
          this.loadingEmit = false
        })
        .catch((error) => {
          this.showError(error)
          console.error(error)
        })
    },
    updateItemQuantity (event) {
      const quantityItem = event.target.value
      const indexItem = event.target.id

      this.updateQuantity({ index: indexItem, quantity: quantityItem })
      /* let check = this.checkStock({code: this.getItems[indexItem].commercialCode, quantity: quantityItem});
      if (check) {
        this.updateQuantity({index: indexItem, quantity: quantityItem})
      } else {
        event.target.value = this.getItems[indexItem].quantity
        let errMsj = 'No hay suficientes productos codigo '+this.getItems[indexItem].commercialCode+' en bodega.'
        this.showError(errMsj)
      }
      /*
        let quantity = e.target.value
        let invoiceItems = this.invoice.items[index]
        this.tmp.searchCode = invoiceItems.commercialCode
        this.getProductByCode()
        this.tmp.item.quantity = quantity
        this.insertItem()
        this.tmp.commandCode = null /* */
    },
    getProductByCode () {
      const product = this.getProducts.find((product) => {
        return product.commercialCode === this.tmp.searchCode
        // return this.customFilter(product, this.tmp.searchCode);
      })
      if (!product) { return false }
      let unitPrice = 0
      let normalPrice
      product.prices.forEach(price => {
        unitPrice += price.priceList === this.priceList ? price.price : 0
        if (price.type === 'general') {
          normalPrice = price.price
        }
      })
      unitPrice = unitPrice === 0 ? normalPrice : unitPrice
      this.tmp.item = {
        ...{
          cabys: product.cabys,
          code: product.commercialCode,
          quantity: 1,
          description: product.description,
          unitPrice: unitPrice,
          taxed: !!product.taxes[0],
          unit: product.unit
        }
      }
      if (product.taxes[0]) {
        this.tmp.taxes = {
          code: product.taxes[0].code,
          rateCode: product.taxes[0].rateCode,
          rate: product.taxes[0].rate
        }
      }
      return true
    },
    insertProductFromCode () {
      const commandCode = this.tmp.commandCode
      if (!/^[*+]-?\d+([.]\d+)?$/.test(commandCode)) {
        this.tmp.searchCode = commandCode
        if (!this.getProductByCode()) {
          // alert("El código "+commandCode+" no está registrado")
          this.showError('El código ' + commandCode + ' no está registrado')
        } else {
          this.insertItem()
          this.tmp.commandCode = null
        }
      } else {
        const indexItem = this.getItems.length - 1
        const quantityItem = commandCode[0] === '+'
          ? Number(commandCode.substr(1)) + Number(this.getItems[indexItem].quantity)
          : Number(commandCode.substr(1)) * Number(this.getItems[indexItem].quantity)
        this.updateQuantity({ index: indexItem, quantity: quantityItem })
        this.tmp.commandCode = null
      }
    },
    cleanClient () {
      this.tmp.client = { ...this.clientDefault }
    },
    setShippingAddress ({
      province, conty, district, others
    }) {
      if (province && province !== null) {
        tools.getCounty(province.value)
          .then((counties) => {
            this.addressLocationItems.conties = counties
            return (conty && conty !== null)
              ? tools.getDistrict(province.value, conty.value)
              : false
          })
          .then((districts) => {
            this.addressLocationItems.districts = districts || []
          })
          .catch((error) => {
            console.error(error)
          })
      }
      this.setAddress({
        province,
        conty,
        district,
        others
      })
    },
    getProvince () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
          .then(response => {
            const provinces = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              provinces.push({
                text: value,
                value: String(key)
              })
            }
            this.provinces = provinces
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCounty () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.tmp.receiver.location.province + '/cantones.json')
          .then(response => {
            const counties = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              counties.push({
                text: value,
                value: String(key)
              })
            }
            this.counties = counties
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.tmp.receiver.location.province + '/canton/' + this.tmp.receiver.location.county + '/distritos.json')
          .then(response => {
            const districts = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              districts.push({
                text: value,
                value: String(key)
              })
            }
            this.districts = districts
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  computed: {
    ...mapGetters('user', []),
    ...mapGetters('customers', [
      'getCustomers'
    ]),
    ...mapGetters('orders', [
      'getClient',
      'getItems',
      'getAddress',
      'getItems',
      'getObservations',
      'servicesTaxableTotal',
      'servicesExentTotal',
      'goodsTaxableTotal',
      'goodsExentTotal',
      'taxableTotal',
      'exentTotal',
      'subtotal',
      'netTotal',
      'summary'
    ]),
    ...mapGetters('products', [
      'getProducts'
    ]),
    getClientTMP () {
      return { ...this.getClient, search: this.tmp.searchClient }
    },
    getProductsItems () {
      const items = []
      this.getProducts.forEach((item) => {
        let unitPrice = 0
        let normalPrice
        item.prices.forEach(price => {
          unitPrice += price.priceList === this.priceList ? price.price : 0
          if (price.type === 'general') {
            normalPrice = price.price
          }
        })
        items.push({
          cabys: item.cabys,
          code: item.commercialCode,
          quantity: 1,
          description: item.description,
          unitPrice: unitPrice === 0 ? normalPrice : unitPrice,
          taxed: !!item.taxes[0],
          taxes: item.taxes,
          unit: item.unit,
          prices: item.prices,
          pricingConditions: item.pricingConditions
        })
      })
      return items
    },
    isMovil: function () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    itemSubtotal: function () {
      return tools.round10((this.tmp.item.unitPrice == null ? 0 : this.tmp.item.unitPrice) * (this.tmp.item.quantity == null ? 1 : this.tmp.item.quantity), -5)
    },
    itemTaxFactor: function () {
      return tools.round10(this.tmp.taxes.rate / 100, -5)
    },
    itemTaxNet: function () {
      return tools.round10(this.tmp.item.taxed ? this.itemSubtotal * this.itemTaxFactor : 0, -5)
    },
    itemNetTotal: function () {
      return tools.round10(this.itemSubtotal + this.itemTaxNet, -5)
    },
    itemsDataTable () {
      const items = []
      for (let i = this.getItems.length - 1; i >= 0; i--) {
        items.push({
          index: i,
          quantity: this.getItems[i].quantity,
          description: this.getItems[i].description,
          subtotal: this.getItems[i].subtotal,
          unitPrice: this.getItems[i].unitPrice,
          taxRate: this.getItems[i].taxes ? this.getItems[i].taxes[0].rate : 0,
          netTotal: this.getItems[i].netTotal
        })
      }
      return items
    },
    formClient () {
      return {
        inputs: [
          {
            outlined: true,
            id: 'a0',
            dense: true,
            label: 'Clientes registrados',
            name: 'search',
            value: 'search',
            type: 'autocomplete',
            items: this.getCustomers,
            itemValue: 'id',
            itemText: 'name',
            returnObject: true,
            inserts: [
              { objKey: 'id', inputKey: 'id' },
              { objKey: 'typeId', inputKey: 'typeId' },
              { objKey: 'identification', inputKey: 'identification' },
              { objKey: 'name', inputKey: 'name' },
              { objKey: 'email', inputKey: 'email' },
              { objKey: 'phone', inputKey: 'phone' }
              // { objKey: 'priceList', inputKey: 'priceList' },
            ],
            cols: 12,
            filter: (item, queryText) => {
              const textOne = item.identification.toLowerCase()
              const textTwo = item.name.toLowerCase()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            }
          },
          {
            outlined: true,
            id: 'a00',
            dense: true,
            label: 'Tipo de cédula',
            name: 'Tipo de cédula',
            value: 'typeId',
            type: 'select',
            items: [
              { value: '01', text: 'Física' },
              { value: '02', text: 'Jurídica' },
              { value: '03', text: 'DIMEX' },
              { value: '04', text: 'NITE' }
            ],
            rules: '',
            cols: 5
          },
          {
            outlined: true,
            id: 'a000',
            dense: true,
            label: 'Cédula',
            name: 'Cédula',
            value: 'identification',
            type: 'text',
            rules: 'integer|max_value:999999999999|min_value:100000000',
            cols: 7
          },
          {
            outlined: true,
            id: 'a001',
            dense: true,
            label: 'Nombre',
            name: 'Nombre',
            value: 'name',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            outlined: true,
            id: 'a002',
            dense: true,
            label: 'Email',
            name: 'email',
            value: 'email',
            type: 'text',
            rules: 'email',
            cols: 6
          },
          {
            outlined: true,
            id: 'a003',
            dense: true,
            label: 'Teléfono',
            name: 'phone',
            value: 'phone',
            type: 'text',
            rules: 'required|digits:8',
            cols: 6
          }
        ]
      }
    },
    formItem () {
      return {
        inputs: [
          {
            outlined: true,
            id: 'b000',
            dense: true,
            label: 'Productos registrados',
            name: 'search',
            value: 'search',
            type: 'autocomplete',
            items: this.getProductsItems,
            itemValue: 'commercialCode',
            itemText: 'description',
            returnObject: true,
            inserts: [
              { objKey: 'cabys', inputKey: 'cabys' },
              { objKey: 'code', inputKey: 'code' },
              { objKey: 'quantity', inputKey: 'quantity' },
              { objKey: 'unitPrice', inputKey: 'unitPrice' },
              { objKey: 'description', inputKey: 'description' },
              { objKey: 'taxed', inputKey: 'taxed' }
            ],
            cols: 12,
            filter: (item, queryText) => {
              const textOne = item.description.toLowerCase()
              const textTwo = item.code.toLowerCase()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            }
          },
          {
            outlined: true,
            id: 'b001',
            dense: true,
            label: 'cabys',
            name: 'CAByS',
            value: 'cabys',
            type: 'text',
            rules: 'length:13',
            cols: 12
          }, /* */
          {
            outlined: true,
            id: 'b002',
            dense: true,
            label: 'Código',
            name: 'Código',
            value: 'code',
            type: 'text',
            rules: 'required|max:20',
            cols: 3
          },
          {
            outlined: true,
            id: 'b007',
            dense: true,
            label: 'Unidad',
            name: 'Unidad',
            value: 'unit',
            type: 'select',
            items: units,
            rules: 'required',
            cols: 3
          },
          {
            outlined: true,
            id: 'b003',
            dense: true,
            label: 'Cantidad',
            name: 'Cantidad',
            value: 'quantity',
            type: 'number',
            rules: 'required|numeric',
            cols: 3
          },
          {
            outlined: true,
            id: 'b005',
            dense: true,
            label: 'Precio Unitário',
            name: 'Precio Unitário',
            value: 'unitPrice',
            type: 'number',
            rules: 'required|numeric',
            cols: 3
          },
          {
            outlined: true,
            id: 'b004',
            dense: true,
            label: 'Detalle',
            name: 'Detalle',
            value: 'description',
            type: 'text',
            rules: 'required|max:200',
            cols: 12
          },
          {
            outlined: true,
            id: 'b006',
            dense: true,
            label: 'IVA',
            name: 'IVA',
            value: 'taxed',
            type: 'checkbox',
            rules: '',
            cols: 6
          }
        ]
      }
    },
    formTaxes () {
      return {
        inputs: [
          {
            outlined: true,
            id: 'd001',
            dense: true,
            label: 'Tarifa Impuesto Valor Agregado',
            name: 'Tarifa Impuesto Valor Agregado',
            value: 'rateCode',
            type: 'select',
            rules: 'required',
            cols: 6,
            items: [
              { value: '01', text: 'Tarifa 0% (Exento)' },
              { value: '02', text: 'Tarifa reducida 1%' },
              { value: '03', text: 'Tarifa reducida 2%' },
              { value: '04', text: 'Tarifa reducida 4%' },
              { value: '05', text: 'Transitorio 0%' },
              { value: '06', text: 'Transitorio 4%' },
              { value: '07', text: 'Transitorio 8%' },
              { value: '08', text: 'Tarifa general 13%' }
            ]
          }
        ]
      }
    },
    formOptionsAddress () {
      return {
        inputs: [
          {
            outlined: true,
            id: 'b001',
            dense: true,
            label: 'Provincia',
            name: 'Provincia',
            value: 'province',
            type: 'select',
            rules: 'required',
            cols: 4,
            items: this.addressLocationItems.provinces,
            returnObject: true
          },
          {
            outlined: true,
            id: 'b002',
            dense: true,
            label: 'Cantón',
            name: 'Cantón',
            value: 'conty',
            type: 'select',
            rules: 'required',
            cols: 4,
            items: this.addressLocationItems.conties,
            returnObject: true
          },
          {
            outlined: true,
            id: 'b007',
            dense: true,
            label: 'Distrito',
            name: 'Distrito',
            value: 'district',
            type: 'select',
            rules: 'required',
            cols: 4,
            items: this.addressLocationItems.districts,
            returnObject: true
          },
          {
            outlined: true,
            id: 'b003',
            dense: true,
            label: 'Dirección exacta',
            name: 'Dirección exacta',
            value: 'others',
            type: 'text',
            rules: 'required',
            cols: 12
          }
        ]
      }
    }
  }
}
</script>
