/* eslint-disable handle-callback-err */
import axios from 'axios'
export const rpp = {
  itsRunning () {
    return new Promise((resolve, reject) => {
      const config = {
        method: 'get',
        url: 'http://127.0.0.1:3000/',
        headers: { }
      }

      axios.request(config)
        .then((response) => {
          resolve(response.data === 'its running!')
        })
        .catch((error) => {
          console.log('reda-pos-printer not found')
          resolve(false)
        })
    })
  },
  print ({ data, options }) {
    return new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: 'http://127.0.0.1:3000/print',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { data, options }
      }

      axios.request(config)
        .then((response) => {
          resolve(JSON.stringify(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
