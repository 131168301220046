var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.voucher.receipts,"sort-by":"date","dense":""},scopedSlots:_vm._u([{key:"item.dateString",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(Number(item.date)).format('LL'))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Pagos recibidos")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.amountOutstanding > 0)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"success","dark":"","icon":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cash-plus")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('NewReceipt',{attrs:{"voucher":_vm.voucher,"amountOutstanding":_vm.amountOutstanding},on:{"cancel":function($event){_vm.dialog = false}}})],1)],1)]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.amount,item.currency))+" ")]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paymentMethodsItems[item.paymentType])+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"error","disabled":item.amount < 0},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-cash-remove ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }