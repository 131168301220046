var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.voucher.data.items.length,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Direccionamiento de compras y gastos")]),_c('v-spacer')],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.items),function(option,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([(!(option.text === 'Descargar Respuesta Hacienda' && item.data.documentType === '10'))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","color":option.color || 'info',"disabled":(option.text === 'Realizar pago' && (Number(item.amountOutstanding) <= 0 || item.deleted || !item.isVoucherSale)) || (option.text === 'Descargar Respuesta Hacienda' && (item.data.documentType === '11' || item.data.documentType === '10'))},on:{"click":function($event){$event.stopPropagation();option.text === 'Realizar pago' ? _vm.newPayVoucher(item) : option.callback(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(option.icon)+" ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(option.text))])])})}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.value,_vm.voucher.data.summary.currency))+" ")]}},{key:"item.addressing",fn:function(ref){
var item = ref.item;
return [(item.inventory)?_c('div',[_vm._v("Inventario en "+_vm._s(item.inventory.product))]):_vm._e(),(item.ao)?_c('div',[_vm._v("Gasto en "+_vm._s(_vm.getAccountsCatalogIndexed[item.ao.concept].name))]):_vm._e(),(item.fixedAsset)?_c('div',[_vm._v("Activo "+_vm._s(item.fixedAsset))]):_vm._e(),(!item.classified)?_c('div',[_vm._v("Pendiente")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }