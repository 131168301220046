var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tasks,"sort-by":"priority","search":_vm.search,"dense":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title'),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-3 py-2 mr-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.end))+" ")]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.deadline))+" ")]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.priorityObj[item.priority].color}},[_vm._v(" "+_vm._s(_vm.priorityObj[item.priority].text)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.statusObj[item.status].color}},[_vm._v(" "+_vm._s(_vm.statusObj[item.status].text)+" ")]):_vm._e()]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignee ? item.assignee.name : null)+" ")]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('TaskActions',{attrs:{"tasksIndexed":_vm.tasksIndexed,"item":item},on:{"updated":_vm.emitUpdated}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(false)?_c('TaskDialog',{attrs:{"item":item,"tasksIndexed":_vm.tasksIndexed,"tasks":_vm.tasks,"staff":_vm.staff}}):_vm._e(),_c('TaskEdit',{attrs:{"item":item,"tasks":_vm.tasks,"staff":_vm.staff},on:{"refresh":_vm.emitUpdated}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }