var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersItems,"items":_vm.getItemsTable,"show-select":"","loading":_vm.sendingMessage,"item-key":"id","loading-text":"Cargando... Por favor espere","sort-by":"date","sort-desc":"","single-expand":"","show-expand":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Compras y gastos pendientes de Clasificar")]),_c('v-spacer'),_c('v-select',{staticClass:"mt-7",staticStyle:{"width":"180px"},attrs:{"items":[
            {value: 'commercialCode', text: 'Código del producto'},
            {value: 'code', text: 'CABYS'} ],"dense":"","label":"Clasificar por"},model:{value:(_vm.classifyBy),callback:function ($$v) {_vm.classifyBy=$$v},expression:"classifyBy"}}),_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"secondary","loading":_vm.uploadingVoucher},on:{"click":_vm.clickUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v("Subir")],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","disabled":_vm.disabledSend,"loading":_vm.sendingMessage},on:{"click":_vm.sendMessages}},[_vm._v("Enviar")]),_c('input',{ref:"xml",attrs:{"type":"file","accept":"application/xml","hidden":""},on:{"change":_vm.handleFileUpload}})],1)],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.vouchers),function(voucher){return _c('p',{key:voucher},[_c('router-link',{attrs:{"to":'/voucher/'+voucher}},[_vm._v(_vm._s(voucher))])],1)}),0)]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"180px"},attrs:{"items":item.value < _vm.getbaseSalary25PC
          ? [
              {value: '01', text: 'Inventario'},
              {value: '03', text: 'Gastos y costos'} ]
            : [
              {value: '01', text: 'Inventario'},
              {value: '02', text: 'Activo fijo'},
              {value: '03', text: 'Gastos y costos'} ],"dense":""},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"item.to",fn:function(ref){
            var item = ref.item;
return [(item.type === '01')?_c('v-select',{staticStyle:{"width":"180px"},attrs:{"items":[
          {value: '01', text: 'Producto Existente'},
          {value: '02', text: 'Producto Nuevo'} ],"dense":""},model:{value:(item.to),callback:function ($$v) {_vm.$set(item, "to", $$v)},expression:"item.to"}}):_vm._e(),(item.type === '03')?_c('DialogAccounts',{attrs:{"label":"Cuenta de gasto","showField":true,"filter":'5'},model:{value:(item.concept),callback:function ($$v) {_vm.$set(item, "concept", $$v)},expression:"item.concept"}}):_vm._e(),(item.type === '02')?_c('v-autocomplete',{staticStyle:{"width":"180px"},attrs:{"items":_vm.depreciationCodes,"label":"Categoria Hacienda","dense":"","item-text":"asset","return-object":""},model:{value:(item.category),callback:function ($$v) {_vm.$set(item, "category", $$v)},expression:"item.category"}}):_vm._e()]}},{key:"item.product",fn:function(ref){
        var item = ref.item;
return [(item.to === '01' && item.type === '01')?_c('v-autocomplete',{staticStyle:{"width":"180px"},attrs:{"items":_vm.itemsProductsCode,"filter":_vm.customFilter,"item-text":"name","item-value":"code","dense":""},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}):_vm._e(),(item.to === '02' || item.type === '03')?_c('v-text-field',{staticStyle:{"width":"180px"},attrs:{"label":"Descripción"},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}):_vm._e(),(item.to === '02')?_c('v-text-field',{attrs:{"label":"Precio de venta","type":"number","min":"1"},model:{value:(item.newPrice),callback:function ($$v) {_vm.$set(item, "newPrice", $$v)},expression:"item.newPrice"}}):_vm._e()]}},{key:"item.costCenter",fn:function(ref){
        var item = ref.item;
return [(item.type !== '01')?_c('v-autocomplete',{staticStyle:{"width":"180px"},attrs:{"items":_vm.getCostCenters,"item-text":"name","item-value":"code","label":"Centro de Costo","dense":""},model:{value:(item.cc),callback:function ($$v) {_vm.$set(item, "cc", $$v)},expression:"item.cc"}}):_c('v-select',{staticStyle:{"width":"180px"},attrs:{"items":_vm.getCellarsEntry,"label":"Bodega","item-text":"name","item-value":"code","dense":""},model:{value:(item.cellar),callback:function ($$v) {_vm.$set(item, "cellar", $$v)},expression:"item.cellar"}})]}},{key:"item.factor",fn:function(ref){
        var item = ref.item;
return [(item.type === '01' && (item.to === '02' || item.to === '01'))?_c('v-text-field',{attrs:{"label":"Factor","type":"number","min":"1"},model:{value:(item.factor),callback:function ($$v) {_vm.$set(item, "factor", $$v)},expression:"item.factor"}}):_vm._e(),(item.type === '03')?_c('v-select',{staticStyle:{"width":"180px"},attrs:{"items":_vm.getTypeADItems,"label":"Clasificación","dense":""},model:{value:(item.typeCost),callback:function ($$v) {_vm.$set(item, "typeCost", $$v)},expression:"item.typeCost"}}):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }