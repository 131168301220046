<template>
  <v-container>
    <Period></Period>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      v-for="(item, i) in getCompany.taxReturn"
      :key="i"
    >
      <v-tab>
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, i) in getCompany.taxReturn"
        :key="i"
      >
        <v-container fluid>
          <component :is="item"></component>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import D105IVA from '@/components/D105IVA.vue'
import D104 from '@/components/D104.vue'
import Period from '@/components/Period.vue'
// import accounting from '@/api/accounting';
// import { db } from '@/api/firebase';

export default {
  name: 'TaxReturn',
  components: {
    D105IVA,
    D104,
    Period
  },
  data: () => ({
    tab: 0
  }),
  methods: {
    ...mapActions([]),
    ...mapActions('accounting', [])
  },
  computed: {
    ...mapState([]),
    ...mapState('accounting', []),
    ...mapGetters([]),
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
