export default [{ asset: 'Abanicos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Afiladoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Agitadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Aire acondicionado (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Alarmas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Alfombras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Alternadores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Amplificadores (fotografía y sonido)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Amasadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Andamiajes (andamios)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Andariveles', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Antenas y torres', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Aparatos telefónicos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Apisonadoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Aplanadoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Apuntadoras de metal para construcción', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Aguapulpas', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Arados', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Archivadores (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Arcas pavimentadas, ornamentales, cercas y planchés', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Armas de fuego', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Arrancadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Arroceras (maquinaria y equipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Ascensores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Aserraderos (maquinaria)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Aspiradoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Atomizadores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Autobuses', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Autoclaves', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Automóviles (uso en empresas)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Automóviles (taxis)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Automóviles (de alquiler)', annualDepreciation: 34, usefulLife: 3 },
  { asset: 'Aventadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Aviones', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Balanzas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Baldes o cubetas', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Bandas transportadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Baños sauna y turcos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Barberías (sillas y equipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Barredoras callejeras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Barrenadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Barrenos y brocas', annualDepreciation: 25, usefulLife: 4 },
  { asset: 'Barriles de hierro', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Barriles de madera', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Básculas (toda clase)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Baterías de cocina', annualDepreciation: 25, usefulLife: 4 },
  { asset: 'Batidoras de concreto', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Beneficios de café', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Bibliotecas (mobiliario y equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Bicicletas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Billares y "pooles"', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Bloqueras (hidráulicas o mecánicas)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Bobinadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Boleadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Boliches (maquinaria y equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Bombas de trasiego', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'bombas para trasegar concreto', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Botes (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Buques de ultramar', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Buques fluviales', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Buques pesqueros', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Butacas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cabezales', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Cable-carriles', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cables', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cadenas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cajas para caudales', annualDepreciation: 3, usefulLife: 40 },
  { asset: 'Cajas registradoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Caladoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Calculadoras (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Calderas y accesorios', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Calentadores de agua', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Calentadores ambientales', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cámaras cinematográficas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cámaras fotográficas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cámaras de refrigeración', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cámaras de televisión', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Camas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Camiones cisterna', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Camiones (con equipo de lubricación)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Camiones (con equipo de extinguidor de incendios', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Camiones (transporte de carga)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Camiones (trasporte remunerado de personas)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Canales de concreto o acero', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Canales de madera', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Canalizadoras y zanjadoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Canteadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cañerías de vapor, válvula y accesorios', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cargadores', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Carretas de hierro', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Carretas de madera', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Carretillos', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Carretones', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Carrocerías de madera o metal', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Carruseles (caballitos, ruedas de chicago, Carros chocones, toboganes y similares)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Casas rodantes', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Catalinas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Centrales telefónicas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Centrífugas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cepilladoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cercas eléctricas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cernedoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cervecerías', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Chancadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Chapeadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Chapulines', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cilindros de metal', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cine (equipo de proyección y sonido)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cizallas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Clarificadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Clasificadores de arena y piedra', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Clasificadoras todo tipo (excepto dearena y piedra)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Clínica odontológica (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Clínicas hospitalarias (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Clínicas veterinarias (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Clisés', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Cocinas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Codificadores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Colchones, mantelería, ropa de cama', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Campactadoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Compresores (en construcción)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Compresores (toda clase)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Computadoras', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Condensadores (banco de)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Conductores (ingenios)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Congeladores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Construcción (maquinaria de)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Contenedores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Convertidores de electricidad', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cortadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cortinas para teatro y cine', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cortinas para todo uso', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Cosechadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Cosedoras de sacos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Crisoles', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cribas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cristalizadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Cuchillas para maquinaria', annualDepreciation: 25, usefulLife: 4 },
  { asset: 'Cultivadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Curtiembres (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Demolición (maquinaria y equipo)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Descargadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Desfibradoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Desmenuzadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Desmontadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Despulpadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Despuntadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Destilerías', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Devanadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Dinamómetros', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Dínamos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Disolutores', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Dobladoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Dosificadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Dragas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Ebanistería (maquinaria)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Edificios de cemento, ladrillo, metal', annualDepreciation: 2, usefulLife: 50 },
  { asset: 'Edificios de madera, primera', annualDepreciation: 4, usefulLife: 25 },
  { asset: 'Edificios de madera, segunda', annualDepreciation: 6, usefulLife: 17 },
  { asset: 'Electrotipia (talleres)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Elevadores de material', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Embarcaciones deportivas de placer', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Empalmadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Encuadernación (talleres)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Engomadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Enlatadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Enrolladoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Ensambladoras (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de aire acondicionado', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de computación', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Equipo de iluminación', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de iluminación cine y otros', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de iluminación T V', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de ingeniería', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de irrigación Permanente', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Equipo de irrigación Pivote central', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Equipo de irrigación Por gravedad', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Equipo de irrigación Goteo', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Equipo de irrigación Aspersión', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de laboratorios', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de lavandería', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Equipo de odontología', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de oficina y mobiliario', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de ordeño', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de proyección (cine y sonido)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de proyección y sonido (TV)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de radio y telefonía', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de rayos x y fluoroscopía', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de refrigeración', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Equipo de relojería', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de sonido', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo de topografía', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo Electrónico especializado', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Equipo fotográfico (electrónico)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Equipo fotográfico (mecánico)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo instrumental para profesionales', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo instrumental quirúrgico', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo médico', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo para baños sauna y turcos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Equipo para pesca, excepto embarcaciones', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Equipo y maquinaria (actividades agropecuarias)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Escaleras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Escaleras eléctricas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Escritorios (toda clase)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Esmeriladores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Espectrofotómetros', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Estantería', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Estañones', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Estereotopía (talleres)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Etiquetadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Estufas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Evaporadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Excavadoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Exhibidores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Extintores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Extractores de aire', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Extrusoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fábrica de artículos de madera', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de baterías y acumuladores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fábrica de bebidas gaseosas y cervezas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de calzado', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de cemento', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Fábrica de cigarrillos y productos similares', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Fábrica de embutidos y similares', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de hielo', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fábrica de ladrillo, mosaico, bloque, tubos y otros de origen similar', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de licores', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Fábrica de madera laminada', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de pilas secas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos alimenticios y golosinas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos elaborados con metal', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos de caucho, de hule natural o sintético, recauchadoras similares', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos de cuero y piel', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de derivados de la carne', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos derivados de harina vegetal', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos derivados de petróleo o del carbón', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fábrica de productos elaborados con papel cartón, celofán y similares', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos enlatados', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos lácteos', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de productos vegetales enlatados', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de ropa', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fábrica de tejidos (toda clase)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fábrica de vidrios, lentes, cristalería, losa y similares', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de vinos', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica de extractores o procesadora de aceites vegetales', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fábrica extractora o procesadora de aceites y otros elementos químicos', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fajas transportadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Faros', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fermentadores (tanques)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Ferrocarriles', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Filtros de todo tipo', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fotocopiadoras', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Fotograbados (talleres)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fotografía (equipo electrónico)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Fotografía (equipo mecánico)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Frigoríficos (equipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Fresadoras', annualDepreciation: 5, usefulLife: 15 },
  { asset: 'Fumigadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fundición de artículos de metal', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Funerarias (equipo)', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Furgones', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Fuselajes (naves aéreas)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Gasolineras (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Gatas hidráulicas y mecánicas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Generadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Gimnasios (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Glaseadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Góndolas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Granuladoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Grúas puente', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Grúas sobre camión orugas o torres', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Grúas viajeras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Guillotinas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Güinches', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Hélices de naves aéreas 2000 horas', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Herramientas manuales de cualquier clase', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Hiladoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Homogenizadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Hormas (todo tipo)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Hornos (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Hospitales (equipo médico en general)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Hospitales (equipo electrónico)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Hospitales (mobiliario)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Hoteles (mobiliario)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Hoteles (ropa de cama, mantelería y colchones)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Hoteles (utensilios de comedor y cocina)', annualDepreciation: 25, usefulLife: 4 },
  { asset: 'Imprentas (maquinaria y equipo)', annualDepreciation: 8, usefulLife: 13 },
  { asset: 'Incineradores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Indicadores y medidores (toda clase)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Industria metalmecánica', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Ingenieros y refinería de azúcar', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Instalaciones adicionales (todo tipo)', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Instalaciones complementarias (área pavimentadas, cercas, ornamentales y planchés)', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Instalaciones eléctricas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Instrumentos de agrimensura', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Instrumentos musicales', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Invernaderos de zarán', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Invernaderos de otros materiales', annualDepreciation: 25, usefulLife: 4 },
  { asset: 'Inyectoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Juegos electrónicos', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Laminadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Línea férrea', annualDepreciation: 3, usefulLife: 45 },
  { asset: 'Laboratorios clínicos', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Lanchas y lanchones', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Lavadoras (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Lavanderías (maquinaria y equipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Lecherías', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Libros (bibliotecas especializadas)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Lijadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Líneas de transmisión eléctrica', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Litografías', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Locomotoras', annualDepreciation: 4, usefulLife: 25 },
  { asset: 'Llenadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Lonas (manteados)', annualDepreciation: 25, usefulLife: 4 },
  { asset: 'Machihembradoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Mangueras', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Mantelería, colchones y ropa de cama', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Maquinaria de aserraderos', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Maquinaria para trabajar mármol o granito', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Maquinaria y equipo de actividades agropecuarias', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Maquinaria y equipo de construcción (construcción, reparación, demolición, caminos, urbanizaciones, puentes, edificios, canales, represas, drenajes líneas férreas, etc', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Maquinaria y equipo de minería', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Máquinas de vapor', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Marmitas', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Mazas para molino', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Medidores e indicadores (toda clase)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Máquina para enderezar chasises', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Mezcladoras de concreto estacionario o sobre camión', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Mezcladoras de concreto portátiles', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Mezcladoras de uso industrial', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Microbuses (trasporte remunerado)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Mobiliario y equipo de oficina', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Microscopios', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Moldes y troqueles', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Moldaduras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Moledoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Molinos (toda clase)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Montacargas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Motocicletas y similares', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Motoniveladoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Mototraíllas', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Motores eléctricos', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Motores de naves aéreas (6000 horas)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Motosierras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Mueblería (maquinaria)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Muelles de concreto', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Muelles de madera', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Muelles de metal', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Muros de concreto, piedra y mixto', annualDepreciation: 2, usefulLife: 50 },
  { asset: 'Naves aéreas', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Neveras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Niveladoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Odontología (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Ordeñadoras mecánicas', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Palas Mecánicas', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Panaderías (máquinas)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Paneles y control de mando', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Panel fotovoltaico', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Paredes o muros de concreto, piedra o mixto', annualDepreciation: 2, usefulLife: 50 },
  { asset: 'Pavimentadoras', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Peladoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Perforadoras (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Picadoras de pasto', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Piscinas', annualDepreciation: 2, usefulLife: 50 },
  { asset: 'Plantas de asfalto o concreto', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Plantas eléctricas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Plantas hidráulicas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Poliductos', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Poligrafía (talleres)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Pozos', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Prensas (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Profesionales (equipo industrial)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Proyectores (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Pulidoras (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Pulverizadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Quebradores de piedra', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Quemadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Radar (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Radiodifusoras (incluye radiotelefonía y equipo especializado)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Radios (todo tipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Rampas', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Ranchos (instalaciones en centros de turismo y recreo', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Reaserradoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Recanteadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Recauchadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Recibidores de café', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Rectificadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Reductores', annualDepreciation: 3, usefulLife: 40 },
  { asset: 'Refrigeradoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Registradoras (toda clase)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Relojerías (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Relojes', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Remolcadores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Remolques', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Retroexcavadores', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Romanas de plataforma', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Ropa de cama, colchones, mantelería', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Rotativas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Rótulos luminosos', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Rótulos y señales en general', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Salones de belleza (equipo)', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Secadoras (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Selladoras de lata', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Selladores de polietileno', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Separadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Serigrafía (talleres)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Serpentines', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Sifones', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Silos (todo tipo)', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Soldaduras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Sopladores de aire', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Surcadoras', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Techos', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Taladros', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Tamices', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Tanques (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Taxis', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Teclas', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Telares', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Televisores (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Televisores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Tendedoras de líneas', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Tenerías', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Teñidoras (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Tiendas de campaña', annualDepreciation: 20, usefulLife: 5 },
  { asset: 'Topografía (equipo)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Tornapules', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Tornos', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Torres y antenas', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Tortilladoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Tostadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Tractores (uso agrícola)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Tractores (otras actividades)', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Trailers para uso agrícola', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Traíllas', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Transformadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Transportadores', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Trapiches', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Troqueladoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Tuberías (todo tipo)', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Turbogeneradores', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Vagones', annualDepreciation: 5, usefulLife: 20 },
  { asset: 'Vagonetas de volteo', annualDepreciation: 15, usefulLife: 7 },
  { asset: 'Vehículos de carga (livianos)', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Ventiladores y extractores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Vibradores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Volteadores', annualDepreciation: 10, usefulLife: 10 },
  { asset: 'Vulcanizadores', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Yates', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Zanjadores y canalizadoras', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Zarandas (todo tipo)', annualDepreciation: 7, usefulLife: 15 },
  { asset: 'Ipecacuana', annualDepreciation: 50, usefulLife: 2 }]
