<template>
  <v-data-table
    :headers="headers"
    :items="getItems"
    sort-by="id"
    :search="search"
    @click:row.self="toContact"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{staff ? 'Personal' : 'Contactos'}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <ImportData name="customers" humanName="contactos"></ImportData>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on"><v-icon left>mdi-plus</v-icon>Nuevo</v-btn>
          </template>
          <v-card
            :loading="loading"
          >
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-select
                      :items="[
                        { value: '01', text: 'Física' },
                        { value: '02', text: 'Jurídica' },
                        { value: '03', text: 'DIMEX' },
                        { value: '04', text: 'NITE' },
                      ]"
                      label="Tipo*"
                      v-model="editedItem.typeId"
                      required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field type="number" label="Numero de cédula*" autofocus v-if="dialog" hint="sin guiones ni espacios" v-model="editedItem.identification" @change="getByIdentification(editedItem.identification)" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Nombre completo*" v-model="editedItem.name" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field type="email" label="Email*" v-model="editedItem.email" required></v-text-field>
                    </v-col>
                    <v-col cols="12"  sm="6">
                      <v-text-field label="Telefono" v-model="editedItem.phone"></v-text-field>
                    </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                    :items="getCompany.priceList"
                    label="Tipo de Precio*"
                    v-model="editedItem.priceList"
                    required
                    ></v-select>
                  </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"  sm="4">
                      <!--<ValidationProvider v-slot="{ errors }" name="province" rules="required">-->
                        <v-select label="Provincia*" v-model="editedItem.location.province" @change="getCounty" :items="provinces" required></v-select>
                      <!--</ValidationProvider>-->
                    </v-col>
                    <v-col cols="12"  sm="4">
                      <!--<ValidationProvider v-slot="{ errors }" name="county" rules="required">-->
                        <v-select label="Cantón*" v-model="editedItem.location.county" :items="counties" @change="getDistrict" :disabled="editedItem.location.province === null" required></v-select>
                      <!--</ValidationProvider>-->
                    </v-col>
                    <v-col cols="12"  sm="4">
                      <!--<ValidationProvider v-slot="{ errors }" name="district" rules="required">-->
                        <v-select label="Distrito*" v-model="editedItem.location.district" :items="districts" :disabled="editedItem.location.county === null" required></v-select>
                      <!--</ValidationProvider>-->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <!--<ValidationProvider v-slot="{ errors }" name="others" rules="max:250|required">-->
                        <v-text-field label="Otras señas*" :counter="250" v-model="editedItem.location.others" required></v-text-field>
                      <!--</ValidationProvider>-->
                    </v-col>
                  </v-row>
                </v-container>
                <small>*campos obligatorios</small>
              </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click.stop="$router.push('/customer/'+item.uid)"
      >
        mdi-information
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ImportData from '@/components/ImportData.vue'
// import { db, fb } from '@/api/firebase.js'
import axios from 'axios'

export default {
  name: 'Clients',
  props: ['contacts', 'staff'],
  components: { ImportData },
  data: () => ({
    dialog: false,
    search: null,
    loading: false,
    provinces: [],
    counties: [],
    districts: [],
    items: [],
    editedIndex: -1,
    editedItem: {
      uid: null,
      typeId: null,
      id: null,
      identification: null,
      name: null,
      email: null,
      phone: null,
      location: {
        province: null,
        county: null,
        district: null,
        others: null
      },
      priceList: 'general',
      staff: null
    },
    defaultItem: {
      uid: null,
      id: null,
      typeId: null,
      identification: null,
      name: null,
      email: null,
      phone: null,
      location: {
        province: null,
        county: null,
        district: null,
        others: null
      },
      priceList: 'general',
      staff: null
    }
  }),
  mounted () {
    this.getProvince()
  },

  computed: {
    headers () {
      const headers = [
        {
          text: 'ID',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Cédula',
          align: 'left',
          value: 'identification'
        },
        this.staff ? null : { text: 'Tipo', value: 'typeId' },
        { text: 'Nombre', value: 'name' },
        { text: 'Correo', value: 'email' },
        { text: 'Teléfono', value: 'phone' },
        this.staff ? null : { text: 'Tipo de Precio', value: 'priceList' },
        this.staff ? null : { text: 'Acciones', value: 'action', sortable: false }
      ]
      return headers.filter((item) => item)
    },
    getItems () {
      return this.contacts || this.getCustomers
    },
    formTitle () {
      return this.staff ? 'Nuevo Personal' : 'Nuevo Contacto'
    },
    ...mapGetters('customers', [
      'getCustomers'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  /* created () {
      this.initialize()
    }, */

  methods: {
    getByIdentification: function () {
      const index = this.getCustomers.findIndex(customer => {
        return customer.identification === this.editedItem.identification
      })
      // console.log(index)
      if (index > -1) {
        this.editedItem.uid = this.getCustomers[index].uid
        this.editedItem.typeId = this.getCustomers[index].typeId
        this.editedItem.name = this.getCustomers[index].name
        this.editedItem.email = this.getCustomers[index].email
        this.editedItem.phone = this.getCustomers[index].phone || null
        this.editedItem.priceList = this.getCustomers[index].priceList
        this.editedItem.staff = this.getCustomers[index].staff || null

        if (this.getCustomers[index].location) {
          this.editedItem.location.province = this.getCustomers[index].location.province
          this.editedItem.location.others = this.getCustomers[index].location.others
          this.getCounty()
            .then(() => {
              this.editedItem.location.county = this.getCustomers[index].location.county
              return this.getDistrict()
            })
            .then(() => {
              this.editedItem.location.district = this.getCustomers[index].location.district
            })
            .catch(error => {
              console.error(error)
            })
        }
      } else {
        axios
          .get('https://api.hacienda.go.cr/fe/ae?identificacion=' + this.editedItem.identification)
          .then(response => {
          // var receiver = {id: id, typeId: response.data.tipoIdentificacion, name: response.data.nombre}
            this.editedItem.typeId = response.data.tipoIdentificacion
            this.editedItem.name = response.data.nombre
          })
      }
    },
    toContact (item) {
      this.$router.push('/customer/' + item.uid)
    },
    getById: function () {
      axios
        .get('https://api.hacienda.go.cr/fe/ae?identificacion=' + this.editedItem.identification)
        .then(response => {
          // var receiver = {id: id, typeId: response.data.tipoIdentificacion, name: response.data.nombre}
          this.editedItem.typeId = response.data.tipoIdentificacion
          this.editedItem.name = response.data.nombre
        })
    },
    getProvince () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
          .then(response => {
            const provinces = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              provinces.push({
                text: value,
                value: String(key)
              })
            }
            this.provinces = provinces
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCounty () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.editedItem.location.province + '/cantones.json')
          .then(response => {
            const counties = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              counties.push({
                text: value,
                value: String(key)
              })
            }
            this.counties = counties
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.editedItem.location.province + '/canton/' + this.editedItem.location.county + '/distritos.json')
          .then(response => {
            const districts = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              districts.push({
                text: value,
                value: String(key)
              })
            }
            this.districts = districts
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /* initialize () {
        this.items = this.getCompany.customers
      }, */

    editItem (item) {
      this.editedIndex = this.getItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      if (confirm('¿Estás seguro de que deseas eliminar este elemento?')) {
        this.deleteCustomer(item)
      }
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save () {
      this.loading = true
      const editedItem = { ...this.editedItem }
      if (!this.editItem.staff && this.staff) {
        const last = Number(this.getCompany.staff[this.getCompany.staff.length - 1]?.id)
        editedItem.staff = last + 1
      }
      this.insertCustomer(editedItem)
        .then(() => {
          this.close()
        })
        .finally(() => {
          this.loading = false
        })
        // var companiesRef;
        /* db.collection("companies").where("identification", "==", this.getCompany.identification).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                let companiesRef = doc.ref;
              if (this.editedIndex > -1) {
                companiesRef.update({
                  customers: fb.firestore.FieldValue.arrayRemove(this.getCompany.customers[this.editedIndex])
                });
                companiesRef.update({
                  customers: fb.firestore.FieldValue.arrayUnion(this.editedItem)
                });
                //Object.assign(this.items[this.editedIndex], this.editedItem)
              } else {
                companiesRef.update({
                  customers: fb.firestore.FieldValue.arrayUnion(this.editedItem)
                });
                //this.items.push(this.editedItem)
                //console.log(db.FieldValue)
              }
              this.close()
            });
        }); */
    },
    ...mapActions('customers', ['insertCustomer', 'deleteCustomer'])
  }
}
</script>
<style type="text/css">

</style>
