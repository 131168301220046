<template>
  <Entries></Entries>
</template>
<script>
import Entries from '@/components/Entries.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'GeneralJournal',
  components: {
    Entries
  },
  data: () => ({
  }),
  methods: {
    ...mapActions([]),
    ...mapActions('accounting', [])
  },
  computed: {
    ...mapState([]),
    ...mapState('accounting', []),
    ...mapGetters([]),
    ...mapGetters('accounting', [])
  }
}
</script>
