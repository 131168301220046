<template>
  <v-data-table
    :headers="headers"
    :items="getCashDaily ? getCashDaily.movements : []"
    sort-by="date"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Caja Diaria</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="archingDialog" max-width="600px">
          <v-card>
            <v-card-title class="text-center">
              <span class="headline">Cierre de Caja</span>
            </v-card-title>
            <v-card-text>
              <v-list two-line dense>
                <template v-if="false">
                <v-subheader>Ventas</v-subheader>

                <v-list-item v-for="(item, index) in salesCondition" :key="'a'+index">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{item.value}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.key}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider></template>
                <v-subheader>Metodos de Pago de Ventas</v-subheader>

                <v-list-item v-for="(item, index) in paymentMethods"  :key="'v'+index">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{item.value}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.key}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-subheader>Total Pagos desde caja diaria</v-subheader>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-receipt'"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{payments['01']}}</v-list-item-title>
                    <v-list-item-subtitle>Pagos en efectivo</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-subheader>Balance de Caja</v-subheader>

                <v-list-item v-for="(item, index) in cashBalance"  :key="index">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{item.value}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

              </v-list>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="archingDialog = false" color="primary">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >Cerrar caja diaria</v-btn>
          </template>
          <v-card :loanding="loandingClose">
            <v-card-title>
              Cierre de caja
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field type="number" v-model="editedItem.amount" label="Monto Efectivo"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field type="number" v-model="editedItem.card" label="Monto tarjeta"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field type="number" v-model="editedItem.bank" label="Monto transferencia"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close">Cancelar</v-btn>
              <v-btn color="primary" @click="save">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.date="{ item }">
        {{getDateFormat(item.date)}}
    </template>
    <template v-slot:item.amount="{ item }">
        {{item.amount['01']}}
    </template>
    <template v-slot:item.movent="{ item }">
      {{movements[item.movent]}}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions/* mapState, mapMutations */ } from 'vuex'
import moment from 'moment'
import printer from '@/api/printer.js'
export default {
  name: 'CashDaily',
  data: () => ({
    archingDialog: false,
    dialog: false,
    loandingClose: false,
    headers: [
      {
        text: 'Fecha y hora',
        value: 'date'
      },
      { text: 'Movimiento', value: 'movent' },
      { text: 'Comprobante', value: 'voucher' },
      { text: 'Monto', value: 'amount' }
    ],
    movements: {
      OC: 'Apertula',
      CP: 'Cierre Parcial',
      CC: 'Cierre'
    },
    editedIndex: -1,
    editedItem: {
      movent: 'CC',
      amount: 0,
      card: 0,
      bank: 0
    },
    defaultItem: {
      movent: 'CC',
      amount: 0,
      card: 0,
      bank: 0
    },
    iconCondition: {
      '01': 'mdi-cash-register',
      '02': 'mdi-credit-card-clock-outline',
      '03': 'mdi-briefcase-clock',
      '04': 'mdi-home-lock',
      // "05": "Arrendamiento con Opción de Compra",
      // "06": "Arrendamiento en Función Financiera",
      99: 'mdi-text-box-outline'
    },
    iconPaymentMethods: {
      '01': 'mdi-cash-multiple',
      '02': 'mdi-credit-card-outline',
      '03': 'mdi-draw',
      '04': 'mdi-bank-transfer',
      99: 'mdi-account-cash-outline'
    },
    cashReport: {}
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

    salesCondition () {
      if (Object.keys(this.cashReport).length === 0) { return {} }
      const salesCondition = []
      const salesConditionCash = this.cashReport.arching.sales.salesCondition
      const itemsSalesCondition = {
        '01': 'Contado',
        '02': 'Crédito',
        '03': 'Consignación',
        '04': 'Apartado',
        // "05": "Arrendamiento con Opción de Compra",
        // "06": "Arrendamiento en Función Financiera",
        99: 'Otros'
      }
      Object.entries(salesConditionCash).forEach(entry => {
        const [key, value] = entry
        salesCondition.push({
          key: itemsSalesCondition[key],
          text: itemsSalesCondition[key],
          value: value,
          code: key,
          icon: this.iconCondition[key]
        })
      })
      return salesCondition
    },

    paymentMethods () {
      if (Object.keys(this.cashReport).length === 0) { return {} }
      const paymentMethods = []
      const paymentMethodsCash = this.cashReport.arching.sales.paymentMethods
      const itemsPaymentMethods = {
        '01': 'Efectivo',
        '02': 'Tarjeta',
        '03': 'Cheque',
        '04': 'Transferencia',
        99: 'Otros'
      }
      Object.entries(paymentMethodsCash).forEach(entry => {
        const [key, value] = entry
        paymentMethods.push({
          key: itemsPaymentMethods[key],
          text: itemsPaymentMethods[key],
          value: value,
          code: key,
          icon: this.iconPaymentMethods[key]
        })
      })
      return paymentMethods
    },

    closeCashAmount () {
      if (Object.keys(this.cashReport).length === 0) { return {} }
      const closeCashAmount = {
        '01': 0,
        '02': 0,
        '03': 0,
        '04': 0,
        99: 0
      }
      const closeCashAmountTotal = []
      const movements = this.cashReport.movements
      const itemsPaymentMethods = {
        '01': 'Efectivo',
        '02': 'Tarjeta',
        '03': 'Cheque',
        '04': 'Transferencia',
        99: 'Otros'
      }
      movements.forEach(item => {
        const amount = Object.assign({}, item.amount)
        closeCashAmount['01'] += Number(amount['01'])
        closeCashAmount['02'] += Number(amount['02'])
        closeCashAmount['03'] += Number(amount['03'])
        closeCashAmount['04'] += Number(amount['04'])
        closeCashAmount['99'] += Number(amount['99'])
      })
      Object.entries(closeCashAmount).forEach(entry => {
        const [key, value] = entry
        closeCashAmountTotal.push({
          key: itemsPaymentMethods[key],
          text: itemsPaymentMethods[key],
          value: value,
          code: key,
          icon: this.iconPaymentMethods[key]
        })
      })
      return closeCashAmountTotal
    },

    payments () {
      if (Object.keys(this.cashReport).length === 0) { return {} }
      return this.cashReport.arching.payments
    },

    cashBalance () {
      if (Object.keys(this.cashReport).length === 0) { return {} }
      // const open = this.cashReport.movements[0].amount['01']
      const amount = [
        { code: '01', value: this.cashReport.arching.amount['01'], text: 'Efectivo', icon: 'mdi-cash-multiple' },
        { code: '02', value: this.cashReport.arching.amount['02'], text: 'Tarjeta', icon: 'mdi-credit-card-outline' },
        { code: '03', value: this.cashReport.arching.amount['03'], text: 'Cheque', icon: 'mdi-draw' },
        { code: '04', value: this.cashReport.arching.amount['04'], text: 'Transferencia', icon: 'mdi-bank-transfer' },
        { code: '99', value: this.cashReport.arching.amount['99'], text: 'Otros', icon: 'mdi-account-cash-outline' }
      ]
      /* amount.forEach(item => {
        const sales = this.paymentMethods.find(item2 => {
          return item2.code === item.code
        })
        const cash = this.closeCashAmount.find(item3 => {
          return item3.code === item.code
        })
        const balance = item.code === '01'
          ? ((Number(sales.value) + Number(open)) - cash.value) - this.payments
          : sales.value - cash.value
        item.value += balance
      }) */
      return amount
    },

    ...mapGetters('user', ['getUser']),
    ...mapGetters('cashDaily', ['getCashDaily', 'getArching'])
  },

  watch: {
    dialog (val) {
      val || this.close()
      if (val) {
        printer.cash()
          .catch(error => {
            console.error(error)
          })
      }
    }
  },

  created () {
  },

  methods: {

    printArching (cash) {
      return new Promise((resolve, reject) => {
        printer.printArching(cash)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      const index = this.desserts.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
    },

    close () {
      this.loandingClose = false
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    save () {
      this.loandingClose = true
      const arching = { ...this.getArching }
      this.closeCash({
        '01': Number(this.editedItem.amount),
        '02': Number(this.editedItem.card),
        '03': 0,
        '04': Number(this.editedItem.bank),
        99: 0
      })
        .then(cash => {
          this.close()
          this.cashReport = Object.assign({}, { ...cash, arching })
          cash.user = this.getUser
          this.printArching(cash)
            .catch(error => {
              console.error(error)
            })
          this.archingDialog = true
        })
        .catch(error => {
          this.close()
          this.showError('Ha ocurrido un error')
          console.error(error)
        })
        /* if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()/**/
    },

    getDateFormat (date) {
      return moment(date, 'x').format('LLL')
    },

    ...mapActions('cashDaily', ['newArching', 'closeCash', 'showError'])
  }
}
</script>
