var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"code","sort-desc":"","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Saldos")]),_c('div',{staticClass:"flex-grow-1"})],1)]},proxy:true},{key:"item.previousBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.previousBalance))+" ")]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.debit))+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.credit))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.balance))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',[_vm._v("Total")]),_c('th',{staticClass:"text-right title"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.total.previousBalance))+" ")]),_c('th',{staticClass:"text-right title"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.total.debit))+" ")]),_c('th',{staticClass:"text-right title"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.total.credit))+" ")]),_c('th',{staticClass:"text-right title",class:_vm.total.balance > 0 ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.total.balance))+" ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }