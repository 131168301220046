<template>
  <v-data-table
    :headers="headers"
    :items="vouchers"
    sort-by="receiver"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Documentos emitidos por facturación recurrente</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="500px">
          <NewReceipt
            :voucher="voucher"
            :amountOutstanding="amountOutstanding"
            receiptsFrom="01"
            @cancel="dialog = false"
          ></NewReceipt>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <div class="d-flex flex-row">
        <v-btn
          icon
          class="d-flex justify-center"
          :to="'/voucher/'+item.key"
        >
          <v-icon
            small
          >
            mdi-information
          </v-icon>
        </v-btn>
        <v-btn
          class="d-flex justify-center"
          icon
        >
          <v-icon
            small
            @click="newReceipt(item)"
          >
            mdi-cash-plus
          </v-icon>
      </v-btn>
      </div>
    </template>
    <template
      v-slot:body.append
    >
      <tr>
        <td>
          Total cuentas por cobrar
        </td>
        <td>
        </td>
        <td>
        </td>
        <td>
        </td>
        <td>
          {{totalAccountReceivable}}
        </td>
        <td>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NewReceipt from '@/components/NewReceipt.vue'
// import axios from 'axios'

export default {
  name: 'SubscriptionVouchers',
  props: [
    'vouchers'
  ],
  components: {
    NewReceipt
  },
  data: () => ({
    voucher: null,
    amountOutstanding: null,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'Consecutivo',
        align: 'left',
        value: 'data.sequence'
      },
      { text: 'Cliente', value: 'data.receiver.name' },
      { text: 'Fecha de emisión', value: 'data.date' },
      { text: 'Fecha de vencimiento', value: 'expirationDate' },
      { text: 'Saldo pendiente', value: 'amountOutstanding' },
      { text: 'Acciones', value: 'action', sortable: false }
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      typeId: null,
      identification: null,
      name: null,
      email: null,
      phone: null,
      priceList: 'normal'
    },
    defaultItem: {
      id: null,
      typeId: null,
      identification: null,
      name: null,
      email: null,
      phone: null,
      priceList: 'normal'
    }
  }),

  computed: {
    totalAccountReceivable () {
      let total = 0
      this.vouchers.forEach(({ amountOutstanding }) => {
        total += amountOutstanding
      })
      return total
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Editar Cliente'
    },
    ...mapGetters('vouchers', [
      'getVouchers'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  /* created () {
      this.initialize()
    }, */

  methods: {
    /* initialize () {
        this.items = this.getCompany.customers
      }, */
    newReceipt (item) {
      this.voucher = item.voucher
      this.amountOutstanding = item.amountOutstanding
      this.dialog = true
    },

    editItem (item) {
      this.editedIndex = this.getCustomers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      if (confirm('¿Estás seguro de que deseas eliminar este elemento?')) {
        this.deleteCustomer(item)
      }
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.voucher = null
        this.amountOutstanding = null
      }, 300)
    },

    save () {
      this.loading = true
      this.insertCustomer(this.editedItem)
        .then(() => {
          setTimeout(() => {
            this.loading = false
            this.close()
          }, 3000)
        })
        // var companiesRef;
        /* db.collection("companies").where("identification", "==", this.getCompany.identification).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                let companiesRef = doc.ref;
              if (this.editedIndex > -1) {
                companiesRef.update({
                  customers: fb.firestore.FieldValue.arrayRemove(this.getCompany.customers[this.editedIndex])
                });
                companiesRef.update({
                  customers: fb.firestore.FieldValue.arrayUnion(this.editedItem)
                });
                //Object.assign(this.items[this.editedIndex], this.editedItem)
              } else {
                companiesRef.update({
                  customers: fb.firestore.FieldValue.arrayUnion(this.editedItem)
                });
                //this.items.push(this.editedItem)
                //console.log(db.FieldValue)
              }
              this.close()
            });
        }); */
    },
    ...mapActions('customers', ['insertCustomer', 'deleteCustomer'])
  }
}
</script>
<style type="text/css">

</style>
