export default {
  'storage/unknown': 'Ocurrió un error desconocido.',
  'storage/object-not-found': 'No existe ningún objeto en la referencia deseada.',
  'storage/bucket-not-found': 'No se configuró ningún bucket para Cloud Storage.',
  'storage/project-not-found': 'No se configuró ningún proyecto para Cloud Storage.',
  'storage/quota-exceeded': 'Se superó la cuota del bucket de Cloud Storage. Si estás en el nivel sin costo, deberás actualizar a un plan pagado. Si estás en un plan pagado, comunícate con el personal de asistencia de Firebase.',
  'storage/unauthenticated': 'El usuario no se autenticó. Vuelve a intentarlo después de realizar la autenticación.',
  'storage/unauthorized': 'El usuario no está autorizado para realizar la acción deseada. Consulta las reglas de seguridad para asegurarte de que sean correctas.',
  'storage/retry-limit-exceeded': 'Se superó el límite de tiempo máximo permitido para una operación (de carga, descarga, eliminación, etc.). Vuelve a subirlo.',
  'storage/invalid-checksum': 'El archivo del cliente no coincide con la suma de verificación del archivo que recibió el servidor. Vuelve a subirlo.',
  'storage/canceled': 'El usuario canceló la operación.',
  'storage/invalid-event-name': 'Se proporcionó un nombre de evento no válido. El modo debe ser uno de los siguientes: `running`, `progress` o `pause`.',
  'storage/invalid-url': 'Se proporcionó una URL no válida a refFromURL(). Debes usar el formato gs://bucket/object o https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=&ltTOKEN>',
  'storage/invalid-argument': 'El argumento que se pase a put() debe ser un arreglo de tipo `File`, `Blob` o `UInt8`. El argumento que se pase a putString() debe ser una string sin procesar `Base64` o `Base64URL`.',
  'storage/no-default-bucket': 'No se estableció ningún bucket en la propiedad storageBucket de tu configuración.',
  'storage/cannot-slice-blob': 'Ocurre generalmente cuando hubo un cambio en el archivo local (se borró, se volvió a guardar, etc.) Intenta volver a subirlo después de verificar que el archivo no haya cambiado.',
  'storage/server-file-wrong-size': 'El archivo del cliente no coincide con el tamaño del archivo que recibió el servidor. Vuelve a subirlo.'
}
