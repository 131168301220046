<template>
  <v-card>
    <v-card-title>
      Estructura organizacional (Centros de costos)
      <v-spacer></v-spacer>
      <v-btn  @click="newItem(false)"><v-icon color="primary">mdi-plus</v-icon></v-btn>
      <v-btn @click="downloadExcel"><v-icon>mdi-file-excel</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card :loading="loading" >
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <RedaForm
              :options="formCC"
              v-model="editedItem"
              @cancel="close"
              @submitForm="save"
            ></RedaForm>
          </v-card-text>

        </v-card>
      </v-dialog>
      <v-treeview
        :items="getCostCentersTree"
        item-children="subCostCenters"
      >
        <template v-slot:label="{ item }">
          {{item.name}}
        </template>
        <template v-slot:append="{ item }">
          <v-icon color="success" @click="newItem(item)">mdi-plus</v-icon>
          <v-icon  v-if="item.editable" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon color="error" v-if="item.editable"  @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions/*, mapState */ } from 'vuex'
import tools from '@/api/tools'

const zfill = (number, width) => {
  const numberOutput = Math.abs(number) /* Valor absoluto del número */
  const { length } = number.toString() /* Largo del número */
  const zero = '0' /* String de cero */

  if (width <= length) {
    if (number < 0) {
      return (`-${numberOutput.toString()}`)
    }
    return numberOutput.toString()
  }
  if (number < 0) {
    return (`-${zero.repeat(width - length)}${numberOutput.toString()}`)
  }
  return ((zero.repeat(width - length)) + numberOutput.toString())
}
export default {
  name: 'CostCenters',
  data: () => ({
    loading: false,
    dialog: false,
    parentCostCenter: null,
    defaultItem: {
      code: null,
      name: null,
      type: null,
      description: null,
      subCostCenters: [],
      parentCostCenter: null,
      editable: true
    },
    editedItem: {
      code: null,
      name: null,
      type: null,
      description: null,
      subCostCenters: [],
      parentCostCenter: null,
      editable: true
    },
    headersExcel: [
      { text: 'Código', value: 'code' },
      { text: 'Cuenta', value: 'name' },
      { text: 'Descripción', value: 'description' }
    ]
  }),
  methods: {
    downloadExcel () {
      tools.table2excel(this.accounts, this.headersExcel, 'Plan de cuentas')
    },
    editItem (item) {
      // this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = {
        code: item.code,
        name: item.name,
        type: item.type,
        description: item.description,
        subCostCenters: item.subCostCenters,
        parentCostCenter: item.parentCostCenter,
        editable: item.editable
      }
      this.parentCostCenter = null
      this.dialog = true
    },

    newItem (item) {
      if (!item) {
        const nextId = this.getCostCenters.length + 1
        const nextIdString = String(nextId)

        this.editedItem.code = nextIdString
      } else {
        const nextId = item.subCostCenters.length + 1
        // const nextIdString = String(item.code) + zfill(nextId, 2)

        this.parentCostCenter = item.code
        this.editedItem.parentCostCenter = item.code
        this.editedItem.code = `${item.code}${zfill(nextId, 2)}`
      }
      this.dialog = true
    },

    deleteItem (item) {
      this.deleteCostCenter(item)
        .then((msj) => {
          console.log(msj)
        })
        .catch((error) => {
          console.error(error)
        })
      this.closeDelete()
    },

    close () {
      this.loading = false
      this.dialog = false
      this.parentCostCenter = null
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.parentCostCenter = null
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    save () {
      if (!this.loading) {
        this.loading = true
        const newCC = { ...this.editedItem }
        if (!this.parentCostCenter) {
          this.insertCostCenter(newCC)
            .then((cc) => {
              console.log(`Cost center ${cc.code} updated!`)
            })
            .catch((error) => {
              console.error(error)
            })
            . finally(() => {
              this.close()
            })
        } else {
          this.insertCostCenter({ parentCostCenter: this.parentCostCenter, ...newCC })
            .then((cc) => {
              const parentCostCenterAccount = this.getCostCenters.find((item) => item.code === this.parentCostCenter)
              const parentCostCenter = { ...parentCostCenterAccount }
              parentCostCenter.subCostCenters.push(cc.code)
              return this.insertCostCenter(parentCostCenter)
            })
            .then(() => {
              console.log(`Cost center ${this.editedItem.code} created!`)
            })
            .catch((error) => {
              console.error(error)
            })
            . finally(() => {
              this.close()
            })
        }
      }
    },
    ...mapActions([]),
    ...mapActions('siinco', [
      'insertCostCenter',
      'deleteCostCenter'
    ])
  },
  computed: {
    valid () {
      return this.$refs
    },
    formTitle () {
      return this.parentCostCenter !== null ? 'Nuevo centro de costo' : 'Editar centro de costo'
    },
    formCC () {
      return {
        inputs: [
          {},
          {
            id: '001',
            label: 'Nombre',
            name: 'name',
            value: 'name',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '002',
            label: 'Tipo',
            name: 'type',
            value: 'type',
            type: 'select',
            rules: 'required',
            items: this.getTypeCCItems,
            cols: 12
          },
          {
            id: '003',
            label: 'Descripción',
            name: 'description',
            value: 'description',
            type: 'text',
            cols: 12
          },
          {
            id: '004',
            label: 'Editable',
            name: 'editable',
            value: 'editable',
            type: 'checkbox',
            cols: 12
          }
        ],
        submitText: 'Crear',
        cancelText: 'Cancelar',
        showSubmit: true,
        showCancel: true
      }
    },
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('siinco', ['getCostCenters', 'getTypeCCItems', 'getCostCentersTree'])
  }
}
</script>
