var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.transactions,"sort-by":"date","sort-desc":"","search":_vm.search,"dense":""},on:{"click:row":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.goTo.apply(null, arguments)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Transacciones")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-3 py-2 mr-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"success","dark":"","icon":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cash-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('NewReceipt',{attrs:{"item":_vm.edit,"bank":true},on:{"cancel":function($event){_vm.dialog = false}}})],1)],1)]},proxy:true},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paymentMethodsItems[item.paymentType])+" ")]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.debit,item.currency))+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.credit,item.currency))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.goTo(item)}}},[_vm._v(" mdi-information ")]),_c('v-icon',{attrs:{"small":"","color":"error","disabled":item.amount < 0},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-cash-remove ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }