<template>
  <v-container>
    <v-tabs
    >
      <v-tab>Ordenes de producción</v-tab>

      <v-tab-item>
        <ProductionOrders
          :productionOrders="productionOrders"
          :products="products"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import firebaseApi from '../api/firebaseApi'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import ProductionOrders from '@/components/ProductionOrders.vue'

export default {
  name: 'Production',

  components: {
    ProductionOrders
  },

  data () {
    return {
      e1: 1,
      loading: false,
      products: [],
      listener: null,
      productionOrders: []
      // Aquí es donde se declararían las variables de tu componente
    }
  },

  computed: {
    ...mapGetters('company', ['getCompany'])
  },

  methods: {
    async getProducts () {
      this.products = []
      this.productsIndexed = {}
      this.loading = true
      try {
        const querySnapshot = await firebaseApi.db.collection('products')
          .where('company', '==', this.getCompany.identification)
          .where('inventoryable', '==', true).get()
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const doc = querySnapshot.docs[i]
          const product = { ...doc.data(), uid: doc.id }
          this.products.push(product)
          this.productsIndexed[product.commercialCode] = product
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async reset () {
      this.loading = true
      if (this.listener) this.listener()
      this.listener = firebaseApi.db.collection('productionOrders')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.productionOrders = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const productionOrder = doc.data()
            const product = this.productsIndexed[productionOrder.product]
            this.productionOrders.push({
              ...productionOrder,
              commercialCode: product.commercialCode,
              product,
              uid: doc.id
            })
          }
          this.loading = false
        })
    },
    ...mapMutations([]),
    ...mapActions([])
  },
  mounted: async function () {
    await this.getProducts()
    this.reset()
  },
  beforeDestroy: function () {
    this.listener()
    console.log('beforeDestroy')
  }
}
</script>
