/* eslint-disable camelcase */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { db, fb } from '@/api/firebaseApi'
import moment from 'moment'
import tools from '@/api/tools'
// import cabysD104 from '@/assets/cabysD104'
// import redaApp from '../../api/redaApp'
import accountingTools from '../../api/accounting'

// const services = [
//   'Al',
//   'Alc',
//   'Cm',
//   'I',
//   'Os',
//   'Sp',
//   'Spe',
//   'St'
// ]

/* const sambleJournal = [
  {
    id: 1,
    date: moment('01/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 16000000, credit: 0,
      },
      {
        code: '3.1.1.1', name: 'Capital social', debit: 0, credit: 16000000,
      },
    ],
    description: 'Aporte inicial',
    docs: [],
  },
  {
    id: 2,
    date: moment('05/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.2.2.2', name: 'Mobiliario y equipos de oficina', debit: 800000, credit: 0,
      },
      {
        code: '3.1.1.1', name: 'Capital social', debit: 0, credit: 800000,
      },
    ],
    description: 'Aporte socio',
    docs: [],
  },
  {
    id: 3,
    date: moment('07/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.2.2.1.1', name: 'Terreno', debit: 1500000, credit: 0,
      },
      {
        code: '1.2.2.1.2', name: 'Bodega', debit: 3500000, credit: 0,
      },
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 0, credit: 1600000,
      },
      {
        code: '2.1.2', name: 'Documento por pagar', debit: 0, credit: 3400000,
      },
    ],
    description: 'Compra terreno y edificio',
    docs: [],
  },
  {
    id: 4,
    date: moment('11/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.2.2.2', name: 'Mobiliario y equipos de oficina', debit: 3600000, credit: 0,
      },
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 0, credit: 3600000,
      },
    ],
    description: 'Compra mobiliario y equipos de oficina',
    docs: [],
  },
  {
    id: 5,
    date: moment('13/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.2.2.1.3', name: 'Motocicletas', debit: 5600000, credit: 0,
      },
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 0, credit: 3600000,
      },
      {
        code: '2.1.1.1', name: 'Cuentas por pagar', debit: 0, credit: 2000000,
      },
    ],
    description: 'Compra de motocicletas',
    docs: [],
  },
  {
    id: 6,
    date: moment('20/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 1000000, credit: 0,
      },
      {
        code: '2.1.1.2', name: 'Ingresos no devengados', debit: 0, credit: 1000000,
      },
    ],
    description: 'Ahorro pequeña empresa de la zona',
    docs: [],
  },
  {
    id: 7,
    date: moment('26/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '2.1.1.1', name: 'Cuentas por pagar', debit: 200000, credit: 0,
      },
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 0, credit: 200000,
      },
    ],
    description: 'Abono de la cuenta por pagar',
    docs: [],
  },
  {
    id: 8,
    date: moment('28/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '1.1.2.1', name: 'Inversión transitoria', debit: 4000000, credit: 0,
      },
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 0, credit: 4000000,
      },
    ],
    description: 'Compra de un certificado',
    docs: [],
  },
  {
    id: 9,
    date: moment('31/07/2016', 'DD-MM-YYYY').format('x'),
    accounts: [
      {
        code: '2.1.1.1', name: 'Cuentas por pagar', debit: 280000, credit: 0,
      },
      {
        code: '1.1.1.1', name: 'Efectivo', debit: 0, credit: 280000,
      },
    ],
    description: 'Abono a cuenta por pagar',
    docs: [],
  },
];/* */

function getChildren (account, accounts) {
  const children = []
  const subaccounts = account.subaccounts || []

  subaccounts.forEach((item) => {
    const accountTmpFilter = accounts.find((acc) => acc.id === item)
    const accountTmp = { ...accountTmpFilter }
    accountTmp.children = getChildren(accountTmpFilter, accounts)
    children.push(accountTmp)
  })
  return children
}
const initialState = {
  journal: [],
  accounts: [],
  paymentType: {
    '01': 101080101,
    '02': 101080102,
    '03': 101080102,
    '04': 101080102,
    99: 101080102
  },
  ledger: [],
  incomeStatement: [],
  statementOfFinancialPosition: [],
  statementOfCashFlows: [],
  statementOfChangesInEquity: [],
  accountReceivable: 10202,
  accountPayable: 20202,
  revenueFromSaleOfGoods: 40101,
  revenueFromRenderingOfServices: 40102,
  currentTaxLiabilitiesIVA: 2020301,
  propertyPlantAndEquipment: 10101,
  inventoriesHeldForSale: 1020101,
  materialsAndSuppliesToProduction: 1020103,
  workInProgress: 1020102,
  currentTaxAssetsIVA: 1020301,
  administrativeExpense: 503,
  distributionCosts: 502,
  retainedEarnings: 302,
  cashAndCashEquivalents: '1.01.08',
  from: 0,
  to: Number(moment().format('x')),
  listenerJournal: null,
  listenerAccounts: null
}
const accounting = {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations: {
    reset (state) {
      if (state.listenerJournal) {
        state.listenerJournal()
      }
      if (state.listenerAccounts) {
        state.listenerAccounts()
      }
      for (const [key, value] of Object.entries(initialState)) {
        state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
      }
    },
    setAccounts (state, accounts) {
      if (!accounts) state.listenerAccounts()
      state.accounts = accounts
    },
    setDefaultAccounts (state, defaultAccounts) {
      console.log(defaultAccounts)
      state.accountReceivable = defaultAccounts?.accountReceivable || 10202
      state.accountPayable = defaultAccounts?.accountPayable || 20202
      state.revenueFromSaleOfGoods = defaultAccounts?.revenueFromSaleOfGoods || 40101
      state.revenueFromRenderingOfServices = defaultAccounts?.revenueFromRenderingOfServices || 40102
      state.currentTaxLiabilitiesIVA = defaultAccounts?.currentTaxLiabilitiesIVA || 2020301
    },
    setJournal (state, entries) {
      if (!entries) state.listenerJournal()
      state.journal = entries
    },
    setListenerJournal (state, listener) {
      state.listenerJournal = listener
    },
    setListenerAccounts (state, listener) {
      state.listenerAccounts = listener
    },
    setAccounting (state, payload) {
      // state.accounts = payload.accounts
      state.journal = payload.journal
      state.ledger = payload.ledger
      state.incomeStatement = payload.incomeStatement
      state.statementOfFinancialPosition = payload.statementOfFinancialPosition
      state.statementOfCashFlows = payload.statementOfCashFlows
      state.statementOfChangesInEquity = payload.statementOfChangesInEquity
    }
  },
  actions: {
    newEntry ({ state, commit, dispatch, getters, rootGetters }, newEntry) {
      return new Promise((resolve, reject) => {
        const entry = { ...newEntry }
        if (!entry.id) {
          entry.id = getters.getJournal.length + 1
        }
        const debits = entry.accounts.reduce((acc, cur) => acc + cur.debit, 0)
        const credits = entry.accounts.reduce((acc, cur) => acc + cur.credit, 0)
        if (debits !== credits || (debits + credits) === 0) {
          if ((debits + credits) === 0) {
            reject(new Error('La entrada no puede estar en 0'))
          }
          reject(new Error(`La entrada no cumple con la partida doble. Débitos: ${debits}, Créditos: ${credits}`))
        } else {
          db.collection('journal')
            .where('company', '==', rootGetters['company/getCompany'].identification)
            .where('id', '==', entry.id)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                db.collection('journal').add({
                  ...entry,
                  company: rootGetters['company/getCompany'].identification
                })
                  .then(() => {
                    resolve(entry)
                  })
              } else {
                querySnapshot.forEach((doc) => {
                  doc.ref.update(entry)
                    .then(() => { resolve(entry) })
                    .catch((error) => reject(error))
                })
              }
            })
        }/* */
        // resolve({ ...entry, id: (getters.getJournal.length + 1) });
      })
    },
    async runAccounting ({ state, commit, dispatch, getters, rootGetters }, { to, from }) {
      // const accounting = await redaApp.runAccounting({ to, from })
      // commit('setAccounting', accounting)
    },
    async init ({ state, commit, dispatch, getters, rootGetters, rootState }, company) {
      try {
        // await dispatch('runAccounting', { from: rootState.from, to: rootState.to })
        const accountsQuerySnapshot = await db.collection('accounts')
          .where('company', '==', company)
          .get()
        const listenerAccounts = accountsQuerySnapshot.query.onSnapshot((querySnapshot) => {
          // if (!querySnapshot.isEqual(accountsQuerySnapshot)) dispatch('runAccounting', { from: rootState.from, to: rootState.to }).catch((e) => console.error(e))
          const accounts = []
          querySnapshot.forEach((doc) => {
            accounts.push({ ...doc.data(), uid: doc.id })
          })
          commit('setAccounts', accounts)
        })
        commit('setListenerAccounts', listenerAccounts)
        // commit('setJournal', sambleJournal);
        const journalQuerySnapshot = await db.collection('journal').where('company', '==', company).get()
        const listenerJournal = journalQuerySnapshot.query.onSnapshot((querySnapshot) => {
          // if (!querySnapshot.isEqual(accountsQuerySnapshot)) dispatch('runAccounting', { from: rootState.from, to: rootState.to }).catch((e) => console.error(e))
          const journal = []
          querySnapshot.forEach((doc) => {
            journal.push({ ...doc.data(), uid: doc.id })
          })
          commit('setJournal', journal)
        })
        commit('setListenerJournal', listenerJournal)
        return
      } catch (error) {
        console.error(error)
        throw new Error('Error al iniciar contabilidad')
      }
    },
    insertAccount ({ state, commit, dispatch, getters, rootGetters }, account) {
      return new Promise((resolve, reject) => {
        console.log({ ...account })
        db.collection('accounts')
          .where('company', '==', rootGetters['company/getCompany'].identification)
          .where('id', '==', account.id).get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              db.collection('accounts').add(account)
                .then(() => {
                  resolve(account)
                })
            } else {
              querySnapshot.forEach((doc) => {
                doc.ref.update(account)
                  .then(() => { resolve(account) })
                  .catch((error) => reject(error))
              })
            }
          })
          .catch((error) => reject(error))
      })
    },
    deleteAccount ({ state, commit, dispatch, getters, rootGetters }, account) {
      return new Promise((resolve, reject) => {
        if (!account.father) {
          db.collection('accounts')
            .where('company', '==', rootGetters['company/getCompany'].identification)
            .where('id', '==', account.id).get()
            .then((snapshot) => {
              const doc = snapshot.docs[0]
              const data = doc.data()
              data.subaccounts.forEach((item) => {
                const subacc = state.accounts.find(({ id }) => item === id)
                dispatch('deleteAccount', subacc)
                  .catch((error) => console.error(error))
              })
              doc.ref.delete()
            })
            .catch((error) => reject(error))
        } else {
          db.collection('accounts')
            .where('company', '==', rootGetters['company/getCompany'].identification)
            .where('id', '==', account.father).get()
            .then((querySnapshot) => {
              if (querySnapshot.empty) {
                return
              }
              const docRef = querySnapshot.docs[0].ref
              docRef.update({
                subaccounts: fb.firestore.FieldValue.arrayRemove(account.id)
              })
            })
            .then(() => db.collection('accounts')
              .where('company', '==', rootGetters['company/getCompany'].identification)
              .where('id', '==', account.id).get())
            .then((snapshot) => {
              const doc = snapshot.docs[0]
              const data = doc.data()
              data.subaccounts.forEach((item) => {
                const subacc = getters.getAccountsCatalog.find(({ id }) => item === id)
                dispatch('deleteAccount', subacc)
                  .catch((error) => console.error(error))
              })
              doc.ref.delete()
            })
            .then(() => {
              resolve(`La cuenta ${account.id} ha sido eliminada`)
            })
            .catch((error) => reject(error))
        }
      })
    }
  },
  getters: {
    getAccountsCatalog (state, getters, rootState, rootGetters) {
      return state.accounts
    },
    getAccountsIndexed (state, getters, rootState, rootGetters) {
      return state.accounts.reduce((acc, curr) => {
        if (!acc[curr.id]) acc[curr.id] = curr
        return acc
      }, {})
    },
    getParentAccounts (state) {
      const fathersIds = []
      state.accounts.forEach((item, i) => {
        if (!item.father) {
          fathersIds.push(item.id)
        }
      })
      return fathersIds.sort()
    },
    getAccounts (state, getters) {
      if (getters.getAccountsCatalog.length === 0) {
        return []
      }
      const accountsTypes = { subaccounts: getters.getParentAccounts }
      return getChildren(accountsTypes, [...getters.getAccountsCatalog])
    },
    // getAccountsByFromTo: (state, { getJournal, getParentAccounts }, rootState, rootGetters) => ({ from, to }) => {
    //   const initialBalance = rootGetters['company/getInitialBalance']
    //   const accounts = []
    //   state.accounts.forEach((account, i) => {
    //     const balance = accountingtools.getAccountBalance({
    //       initialBalance,
    //       journal: getJournal,
    //       account: account.code,
    //       from: from,
    //       to: to
    //     })/**/
    //     accounts.push({ ...account, ...balance })
    //   })
    //   if (accounts.length === 0) {
    //     return []
    //   }
    //   const accountsTypes = { subaccounts: getParentAccounts }
    //   return getChildren(accountsTypes, accounts)
    // },
    // getAccountsCatalogByFromTo: (state, { getJournal, getParentAccounts }, rootState, rootGetters) => ({ from, to }) => {
    //   const initialBalance = rootGetters['company/getInitialBalance']
    //   const accounts = []
    //   state.accounts.forEach((account, i) => {
    //     const balance = accountingtools.getAccountBalance({
    //       initialBalance,
    //       journal: getJournal,
    //       account: account.code,
    //       from: from,
    //       to: to
    //     })/**/
    //     accounts.push({ ...account, ...balance })
    //   })
    //   return accounts
    // },
    getAccountsDetails ({ accounts }) {
      if (accounts.length === 0) {
        return []
      }
      return accounts.filter((account) => !account.subaccounts || account.subaccounts.length === 0)
    },
    getJournal (state, getters, rootState, rootGetters) {
      const journalAux = []

      const journal = state.journal.sort((a, b) => {
        return Number(a.date) - Number(b.date)
      })
      for (let i = 0; i < journal.length; i++) {
        const entry = journal[i]
        const accounts = []
        const codes = []
        entry.accounts.forEach((account, j) => {
          const acc = isNaN(account.account)
            ? account.account
            : (state.accounts.find(({ id }) => id === Number(account.account)))
          try {
            accounts.push({ ...account, account: acc, code: acc.code, name: acc.name })
            codes.push(acc.code)
          } catch (error) {
            console.log({ ...account })
            console.log({ ...acc })
            throw error
          }
        })
        journalAux.push({ ...entry, accounts, num: i + 1, codes })
      }

      const ledger = accountingTools.summarize({ journal: journalAux, fromDate: rootGetters.getFrom, toDate: rootGetters.getTo })

      const icomes = []
      const expenses = []

      const balanceIcome = {
        previousBalance: 0,
        debit: 0,
        credit: 0,
        balance: 0
      }

      const balanceExpense = {
        previousBalance: 0,
        debit: 0,
        credit: 0,
        balance: 0
      }

      for (let index = 0; index < ledger.length; index++) {
        const element = ledger[index]
        if (element.code.startsWith('4')) {
          balanceIcome.previousBalance += Number(element.previousBalance)
          balanceIcome.debit += Number(element.debit)
          balanceIcome.credit += Number(element.credit)
          balanceIcome.balance += Number(element.balance)
          icomes.push(element)
        }
        if (element.code.startsWith('5')) {
          balanceExpense.previousBalance += Number(element.previousBalance)
          balanceExpense.debit += Number(element.debit)
          balanceExpense.credit += Number(element.credit)
          balanceExpense.balance += Number(element.balance)
          expenses.push(element)
        }
      }

      const retainedEarnings = state.accounts.find(({ id }) => id === rootGetters['company/getAccountingConfig'].retainedEarnings)
      const entryCloseI = {
        date: new Date().getTime(),
        accounts: [
          { account: retainedEarnings, code: retainedEarnings.code, name: retainedEarnings.name, debit: 0, credit: balanceIcome.balance }
        ],
        description: 'Cierre ingresos',
        docs: [
        ]
      }
      icomes.forEach((item, i) => {
        const acc = state.accounts.find(({ code }) => code === item.code)
        entryCloseI.accounts.push({ account: acc, debit: item.balance, credit: 0, code: acc.code, name: acc.name })
      })
      const entryCloseE = {
        date: new Date().getTime(),
        accounts: [
          { account: retainedEarnings, code: retainedEarnings.code, name: retainedEarnings.name, debit: balanceExpense.balance, credit: 0 }
        ],
        description: 'Cierre gastos',
        docs: [
        ]
      }
      expenses.forEach((item, i) => {
        const acc = state.accounts.find(({ code }) => code === item.code)
        entryCloseE.accounts.push({ account: acc, debit: 0, credit: item.balance, code: acc.code, name: acc.name })
      })

      if (balanceIcome.balance > 0) journalAux.push({ ...entryCloseI, num: journalAux.length + 1, codes: entryCloseI.accounts.map(({ code }) => code) })
      if (balanceExpense.balance > 0) journalAux.push({ ...entryCloseE, num: journalAux.length + 1, codes: entryCloseE.accounts.map(({ code }) => code) })

      return journalAux
    },
    getExplicitEntries (state) {
      return state.journal.filter((entry) => !entry.adjustmentEntry)
    },
    getImplicitEntries (state) {
      return state.journal.filter((entry) => entry.adjustmentEntry)
    },
    getLedger ({ ledger }, { getJournal }, rootState, { getFrom, getTo }) {
      return accountingTools.summarize({ journal: getJournal, fromDate: getFrom, toDate: getTo })
    },
    getAllLedgers (state, { getLedger, getAccountsCatalog }, rootState, { getFrom, getTo }) {
      const ledger = []
      for (let index = 0; index < getAccountsCatalog.length; index++) {
        const account = getAccountsCatalog[index]
        const accounts = getLedger.filter(({ code }) => code.startsWith(account.code))
        const balances = accounts.reduce((acc, curr) => {
          try {
            acc.previousBalance += Number(curr.previousBalance)
            acc.debit += Number(curr.debit)
            acc.credit += Number(curr.credit)
            acc.balance += Number(curr.balance)
          } catch (error) {
            console.log({ ...acc })
            throw error
          }
          return acc
        }, {
          previousBalance: 0,
          debit: 0,
          credit: 0,
          balance: 0
        })
        ledger.push({ ...account, balances })
      }
      return ledger
    },
    getDatesEntries (state, { getJournal }, rootState, { getFrom, getTo }) {
      const dates = getJournal.filter(({ date }) => Number(getFrom) < Number(date) && Number(date) < Number(getTo))
        .map(({ date }) => date)
      return dates
    },
    getDatesNumber (state, { getDatesEntries }, rootState, { getFrom, getTo }) {
      const from = getFrom || getDatesEntries[0]
      const number = tools.round10((getTo - from) / (1000 * 60 * 60 * 24))
      return number
    },
    getIncomeStatement ({ incomeStatement }) {
      return incomeStatement
    },
    getStatementOfFinancialPosition ({ statementOfFinancialPosition }) {
      return statementOfFinancialPosition
    },
    getStatementOfCashFlows ({ statementOfCashFlows }) {
      return statementOfCashFlows
    },
    getStatementOfChangesInEquity ({ statementOfChangesInEquity }) {
      return statementOfChangesInEquity
    },
    getCurrentLiquidity (state, { getAccountsCatalog, getStatementOfFinancialPosition }, rootState, { getFrom, getTo }) {
      const currentAssets = getStatementOfFinancialPosition.currentAssets.value
      const currentLiabilities = getStatementOfFinancialPosition.currentLiabilities.value
      return tools.round10(currentAssets / currentLiabilities, -5)
    },
    getAcidTest (state, { getAccountsCatalog, getStatementOfFinancialPosition }, rootState, { getFrom, getTo }) {
      const inventories = getAccountsCatalog.find(({ code }) => code === '1.02.01')?.balances.balance || 0
      const currentAssets = getStatementOfFinancialPosition.currentAssets.value
      const currentAssetsWithoutInventory = currentAssets - inventories
      const currentLiabilities = getStatementOfFinancialPosition.currentLiabilities.value
      return tools.round10(currentAssetsWithoutInventory / currentLiabilities, -5)
    },
    getInventoryTurnover (state, { getAccountsCatalog, getStatementOfFinancialPosition, getIncomeStatement }, rootState, { getFrom, getTo }) {
      const inventories = getAccountsCatalog.find(({ code }) => code === '1.02.01')?.balances.balance || 0
      const costOfSales = getIncomeStatement.costOfSales
      if (!inventories) return 0
      return tools.round10(costOfSales / inventories, -5)
    },
    getTurnoverOfAccountsReceivable (state, { getAccountsCatalog, getStatementOfFinancialPosition, getIncomeStatement }, rootState, { getFrom, getTo }) {
      const sales = getIncomeStatement.revenue
      const accountsReceivable = getAccountsCatalog.find(({ code }) => code === '1.02.02')?.balances.balance || 0
      if (!accountsReceivable) return 0
      return tools.round10(sales / accountsReceivable, -5)
    },
    getAverageCollectionPeriod (state, { getAccountsCatalog, getStatementOfFinancialPosition, getDatesNumber, getIncomeStatement }, rootState, { getFrom, getTo }) {
      const days = getDatesNumber
      const sales = getIncomeStatement.revenue
      const accountsReceivable = getAccountsCatalog.find(({ code }) => code === '1.02.02')?.balances.balance || 0
      if (!sales) return 0
      return tools.round10((accountsReceivable * days) / sales, -5)
    },
    getDebtRatio (state, { getAccountsCatalog, getStatementOfFinancialPosition, getDatesNumber, getIncomeStatement }, rootState, { getFrom, getTo }) {
      const liabilities = getStatementOfFinancialPosition.liabilities.value
      const equity = getStatementOfFinancialPosition.equity.value
      if (!equity) return 0
      return tools.round10(liabilities / equity, -5)
    },
    getInterestCoverage (state, { getAccountsCatalog, getStatementOfFinancialPosition, getDatesNumber, getIncomeStatement }, rootState, { getFrom, getTo }) {
      const profitLossBeforeTax = getIncomeStatement.profitLossBeforeTax
      const financeCosts = getIncomeStatement.financeCosts
      if (!financeCosts) return 0
      return tools.round10(profitLossBeforeTax / financeCosts, -5)
    },
    getGrossProfitMargin (state, { getIncomeStatement }, rootState, { getFrom, getTo }) {
      const grossProfit = getIncomeStatement.grossProfit
      const sales = getIncomeStatement.revenue
      if (!sales) return 0
      return tools.round10(grossProfit / sales, -5)
    },
    getOperatingSalesMargin (state, { getIncomeStatement }, rootState, { getFrom, getTo }) {
      const profitLossBeforeTax = getIncomeStatement.profitLossBeforeTax
      const sales = getIncomeStatement.revenue
      if (!sales) return 0
      return tools.round10(profitLossBeforeTax / sales, -5)
    },
    getNetProfitMargin (state, { getIncomeStatement }, rootState, { getFrom, getTo }) {
      const profitLoss = getIncomeStatement.profitLoss
      const sales = getIncomeStatement.revenue
      if (!sales) return 0
      return tools.round10(profitLoss / sales, -5)
    },
    getReturnOnOperatingInvestment (state, { getIncomeStatement, getStatementOfFinancialPosition }, rootState, { getFrom, getTo }) {
      const profitLossBeforeTax = getIncomeStatement.profitLossBeforeTax
      const assets = getStatementOfFinancialPosition.asset.value
      if (!assets) return 0
      return tools.round10(profitLossBeforeTax / assets, -5)
    },
    getTotalReturnOnInvestment  (state, { getIncomeStatement, getStatementOfFinancialPosition }, rootState, { getFrom, getTo }) {
      const profitLoss = getIncomeStatement.profitLoss
      const assets = getStatementOfFinancialPosition.asset.value
      if (!assets) return 0
      return tools.round10(profitLoss / assets, -5)
    },
    getRentOnCapital (state, { getIncomeStatement, getStatementOfFinancialPosition }, rootState, { getFrom, getTo }) {
      const profitLoss = getIncomeStatement.profitLoss
      const equity = getStatementOfFinancialPosition.equity.value
      if (!equity) return 0
      return tools.round10(profitLoss / equity, -5)
    },
    getFinancialReasons (state, getters, rootState, { getFrom, getTo }) {
      const financialReasons = [
        {
          id: 1,
          name: 'Liquidez',
          ratios: [
            { id: 1, name: 'Razón de liquidez corriente', value: getters.getCurrentLiquidity },
            { id: 2, name: 'Prueba de ácido', value: getters.getAcidTest }
          ]
        },
        {
          id: 2,
          name: 'Actividad',
          ratios: [
            { id: 1, name: 'Rotacion del inventerio', value: getters.getInventoryTurnover },
            { id: 2, name: 'Rotación de las cuentas por cobrar', value: getters.getTurnoverOfAccountsReceivable },
            { id: 3, name: 'Período medio de cobro', value: getters.getAverageCollectionPeriod },
            { id: 4, name: 'Razón de la deuda', value: getters.getDebtRatio },
            { id: 5, name: 'Cobertura de intereses', value: getters.getInterestCoverage }
          ]
        },
        {
          id: 3,
          name: 'Endeudamiento',
          ratios: [
            { id: 1, name: 'Margen de utilidad bruta', value: getters.getGrossProfitMargin },
            { id: 2, name: 'Margen de venta de explotación', value: getters.getOperatingSalesMargin },
            { id: 3, name: 'Margen neto de utilidad', value: getters.getNetProfitMargin }
          ]
        },
        {
          id: 3,
          name: 'Rentabilidad',
          ratios: [
            { id: 1, name: 'Rendimiento sobre la inversión de explotación', value: getters.getReturnOnOperatingInvestment },
            { id: 2, name: 'Rendimiento sobre la inversión total', value: getters.getTotalReturnOnInvestment },
            { id: 3, name: 'Renta sobre capital', value: getters.getRentOnCapital }
          ]
        }
      ]
      return financialReasons
    }
    // getTaxReturnsD104 (state, getters, rootState, rootGetters) {
    //   const sales = rootGetters['vouchers/getVoucherSalesItems']
    //     .reduce((acc, curr) => {
    //       if (curr.taxes) {
    //         for (let index = 0; index < curr.taxes.length; index++) {
    //           const tax = curr.taxes[index]
    //           if (tax.code === '01') {
    //             if (!acc[tax.rate]) acc[tax.rate] = []
    //             acc[tax.rate].push(curr)
    //           }
    //         }
    //       } else {
    //         if (!acc['0']) acc['0'] = []
    //         acc['0'].push(curr)
    //       }
    //       return acc
    //     }, {})
    //   const periodSales = [
    //     { id: 'CASILLA_990300_MON', text: 'Bienes y servicios afectos al 1%', value: 0, items: [], iva: '1' },
    //     { id: 'CASILLA_990400_MON', text: 'Bienes y servicios afectos al 2%', value: 0, items: [], iva: '2' },
    //     { id: 'CASILLA_990500_MON', text: 'Bienes y servicios afectos al 4%', value: 0, items: [], iva: '4' },
    //     { id: 'CASILLA_990600_MON', text: 'Bienes y servicios afectos al 8%', value: 0, items: [], iva: '8' },
    //     { id: 'CASILLA_990700_MON', text: 'Bienes y servicios afectos al 13%', value: 0, items: [], iva: '13' },
    //     { id: 'CASILLA_990800_MON', text: 'Total otros rubros a incluir en la base imponible', value: 0, items: [] },
    //     { id: 'CASILLA_990900_MON', text: 'Ventas exentas (Art.8)', value: 0, items: [], iva: '0' }
    //   ]
    //   for (const [key, value] of Object.entries(cabysD104.sales)) {
    //     const items = sales[key]
    //     if (items) {
    //       for (let index = 0; index < value.length; index++) {
    //         const { cabys, id, text } = value[index]
    //         const elementValue = items.reduce((acc, { code, taxable_base }) => {
    //           if (cabys.includes(code)) acc += Number(taxable_base)
    //           return acc
    //         }, 0)
    //         const i = periodSales.findIndex(({ iva }) => iva === key)
    //         periodSales[i].value += elementValue
    //         periodSales[i].items.push({ id, text, value: elementValue })
    //       }
    //     }
    //   }
    //   const periodPurchases = rootGetters['vouchers/getVoucherReceivedItems']
    //     .reduce((acc, curr) => {
    //       if (curr.taxes) {
    //         for (let index = 0; index < curr.taxes.length; index++) {
    //           const tax = curr.taxes[index]
    //           if (tax.code === '01') {
    //             const detailFind = services.includes(curr.unit) ? 'Servicios' : 'Bienes'
    //             const j = acc.findIndex(({ id }) => id === 'CASILLA_995300_MON')
    //             const i = acc[j].items.findIndex(({ detail }) => detail === detailFind)
    //             acc[j].items[i][tax.rate] += Number(curr.taxable_base)
    //             acc[j].value += Number(curr.taxable_base)
    //           }
    //         }
    //       } else {
    //         const j = acc.findIndex(({ id }) => id === 'CASILLA_995500_MON')
    //         const i = acc[j].items.findIndex(({ id }) => id === 'CASILLA_02611_RDB_2_1')
    //         acc[j].items[i].value += Number(curr.taxable_base)
    //         acc[j].value += Number(curr.taxable_base)
    //       }
    //       return acc
    //     }, [
    //       {
    //         id: 'CASILLA_995300_MON',
    //         text: 'Compras de bienes y servicios locales',
    //         items: [
    //           { detail: 'Bienes', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 },
    //           { detail: 'Servicios', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 }
    //         ],
    //         table: true,
    //         value: 0
    //       },
    //       {
    //         id: 'CASILLA_995400_MON',
    //         text: 'Importación de bienes y adquisición de servicios del exterior',
    //         items: [
    //           { detail: 'Bienes', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 },
    //           { detail: 'Servicios', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 }
    //         ],
    //         table: true,
    //         value: 0
    //       },
    //       {
    //         id: 'CASILLA_995500_MON',
    //         text: 'Compras sin IVA soportado y/o con IVA soportado no acreditable',
    //         items: [
    //           { id: 'CASILLA_02611_RDB_2_1', text: 'Bienes y servicios exentos comprados localmente', value: 0 },
    //           { id: 'CASILLA_02611_RDB_2_2', text: 'Bienes y servicios exentos importados', value: 0 },
    //           { id: 'CASILLA_02612_RDB_2_1', text: 'Bienes y servicios no sujetos comprados localmente', value: 0 },
    //           { id: 'CASILLA_02612_RDB_2_2', text: 'Bienes y servicios no sujetos importados', value: 0 },
    //           { id: 'CASILLA_02613_RDB_2_1', text: 'Bienes y servicios con o sin IVA soportado no acreditable (incluye compras de otros regímenes o no relacionados con la actividad) comprados localmente', value: 0 },
    //           { id: 'CASILLA_02613_RDB_2_2', text: 'Bienes y servicios con o sin IVA soportado no acreditable (incluye compras de otros regímenes o no relacionados con la actividad) importados', value: 0 },
    //           { id: 'CASILLA_027900_MON', text: 'Bienes y servicios del artículo 19 de la LIVA', value: 0 },
    //           { id: 'CASILLA_02614_RDB_2_1', text: 'Autorizadas por la Dirección General de Hacienda', value: 0 },
    //           { id: 'CASILLA_02614_RDB_2_2', text: 'Autorizadas por la Dirección General de Tributación', value: 0 }
    //         ],
    //         value: 0
    //       }
    //     ])
    //   return [
    //     {
    //       id: 'CASILLA_990100_MON',
    //       text: 'Ventas sujetas (Base Imponible)',
    //       value: periodSales.reduce((acc, { value }) => (acc += value), 0),
    //       items: periodSales
    //     },
    //     {
    //       id: 'CASILLA_995100_MON',
    //       text: 'Compras del periodo',
    //       value: periodPurchases.reduce((acc, { value }) => (acc += value), 0),
    //       items: periodPurchases
    //     }
    //   ]
    // }
  }
}

export default accounting
