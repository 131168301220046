<template>
  <div class="text-center">
    <v-text-field
      :label="label"
      v-model="selectedAccount.name"
      style="width: 180px"
      readonly
      append-outer-icon="mdi-magnify"
      v-if="showField"
      @click:append-outer.stop="dialog = true"
      :disabled="disabled"
    >
    </v-text-field>
    <v-dialog
      v-model="dialog"
      width="700"
    >
      <template v-slot:activator="{ on, attrs }" v-if="!showField">
        <v-btn
          color="secondary"
          fab
          dark
          v-bind="attrs"
          v-on="on"
          small
          :disabled="disabled"
        >
          <v-icon dark>mdi-magnify</v-icon>
        </v-btn>
      </template>

      <v-card>

        <v-card-title>{{ titleText || 'Plan de cuentas contables' }}</v-card-title>

        <v-container
        class="overflow-y-auto"
        style="max-height: 400px"
        >
          <Accounts
            :title="false"
            :titleText="titleText"
            activatable
            :filter="filter"
            @input="selectAccount($event)"
          ></Accounts>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="emitInput($event)"
            :disabled="btnDisabled"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Accounts from '@/components/Accounts.vue'

export default {
  name: 'DialogAccounts',
  props: [
    'label',
    'filter',
    'value',
    'showField',
    'titleText',
    'title',
    'disabled'
  ],
  components: {
    Accounts
  },
  data () {
    return {
      dialog: false,
      selectedAccount: {}
    }
  },
  methods: {
    selectAccount (account) {
      this.selectedAccount = { ...account }
    },
    emitInput () {
      this.$emit('input', this.selectedAccount)
      this.dialog = false
    }
  },
  computed: {
    btnDisabled () {
      return !this.selectedAccount
    },
    ...mapGetters('accounting', ['getAccountsCatalog', 'getAccountsDetails', 'getAccounts'])
  },
  mounted () {
    if (this.value) {
      const selectedAccount = this.getAccountsCatalog.find((acc) => Number(acc.id) === Number(this.value))
      if (selectedAccount) {
        this.selectedAccount = { ...selectedAccount }
      }
    }
  }
}
</script>
