<template>
  <v-card :loading="isLoading" class="elevation-0">
    <v-card-title>
      Formulario de personalización
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="info"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Vista previa
        </v-btn>
      </template>
      <v-card v-if="isMounted">
        <v-card-title>Vista previa</v-card-title>
        <v-card-text>
          <v-btn-toggle
            v-model="display"
            mandatory
          >
            <v-btn>
              Formulario
            </v-btn>
            <v-btn>
              Mostrar valores
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-card-text>
          <RedaDisplay :options="{
            inputs: items
          }" :value="tmp" @input="setTmp($event)" v-if="display"></RedaDisplay>
          <RedaForm :options="{
            inputs: items
          }" :value="tmp" v-else></RedaForm>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-card-title>
    <v-card-text>
      <CustomizationProductItem
        v-for="(input, i) in items" :key="i"
        :value="input"
        @input="update($event, i)"
        @delete="deleteItem(i)"
        class="my-2"
      ></CustomizationProductItem>
      <v-btn color="primary" @click="newItem"><v-icon left>mdi-plus</v-icon>Agregar campo</v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="save" :loading="isLoading">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import redaApp from '../api/redaApp'
// import firebaseApi from '../api/firebaseApi'
// moment.locale('es')
import CustomizationProductItem from './CustomizationProductItem'
export default {
  name: 'CustomizationProduct',
  components: { CustomizationProductItem },
  props: {
    product: Object
  },
  data: () => ({
    products: [],
    display: 0,
    isLoading: false,
    dialog: false,
    isMounted: false,
    items: [
    ],
    defaultItem: {
    },
    tmp: {
    }
  }),
  mounted () {
    this.items = this.product.customization || []
    this.isMounted = true
  },
  computed: {
    inputs () {
      return this.items
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    ...mapGetters('products', [
      'getProducts'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  watch: {
    items (val) {
      this.tmp = val?.reduce((acc, curr) => {
        console.log(acc, curr)
        acc[curr.value] = curr.default || null
        return acc
      }, {}) || {}
    }
  },
  methods: {
    save () {
      this.isLoading = true
      const payload = { customization: this.items }
      redaApp.update('products', payload, this.product.uid).then(() => {
        this.showSuccess('Producto actualizado')
        this.$emit('updated')
      }).catch((e) => {
        console.error(e)
        this.showError('Error al actualizar producto')
      }).finally(() => {
        this.isLoading = false
      })
    },
    newItem () {
      this.items.push(
        {
          id: this.items.length + 1,
          label: 'Nombre del campo',
          name: null,
          value: null,
          type: 'text',
          items: [],
          cols: 12
        })
    },
    setTmp () {},
    deleteItem (i) {
      this.items.splice(i, 1)
    },
    update (input, i) {
      this.items[i] = input
    },
    ...mapActions('message', ['showSuccess', 'showError'])
  }
}
</script>
