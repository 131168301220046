<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="getPosts"
      :loading="loading"
      sort-by="date"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Calendario de publicaciones para RRSS</v-toolbar-title>
          <v-spacer></v-spacer>
          <GeneratePosts></GeneratePosts>
        </v-toolbar>
      </template>
      <template v-for="({
          value,
          editable,
          text,
          valueName,
        }, i) in headers.filter(({editable}) => !!editable)" v-slot:[valueName]="{ item }">
        <v-edit-dialog
          :return-value.sync="item[value]"
          @save="inputEdit(item)"
          large
          persistent
          :key="i"
        >
          <div>{{ item[value] }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              {{ editable.title }}
            </div>
            <v-textarea
              v-model="item[value]"
              :label="text"
              autofocus
              v-if="editable.type === 'textarea'"
            ></v-textarea>
            <v-text-field
              :type="editable.type"
              v-model="item[value]"
              :label="text"
              single-line
              autofocus
              v-else
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          color="info"
          class="mr-2"
          @click="$router.push('/post/'+item.uid)"
        >
          mdi-information
        </v-icon>
        <v-icon
          color="error"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.dateString="{ item }">
        {{ moment(item.date, 'x').format('LLL') }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GeneratePosts from '../components/GeneratePosts.vue'
import redaApp from '../api/redaApp'
import moment from 'moment'

export default {
  name: 'Posts',
  components: {
    GeneratePosts
  },
  data () {
    return {
      loading: false,
      moment
    }
  },
  mounted () {
  },
  computed: {
    headers () {
      return [
        { text: 'Fecha', value: 'dateString' },
        { text: 'Contenido', value: 'text', editable: { type: 'textarea', title: 'Contenido' }, valueName: 'item.text' },
        { text: 'Imagenes a buscar', value: 'search_phrase', editable: { type: 'text', title: 'Contenido' }, valueName: 'item.search_phrase' },
        { text: 'Frase atractiva', value: 'catchy_phrase', editable: { type: 'text', title: 'Contenido' }, valueName: 'item.catchy_phrase' },
        { text: 'CTA', value: 'cta', editable: { type: 'text', title: 'Contenido' }, valueName: 'item.cta' },
        // { text: 'Link', value: 'action_link', editable: { type: 'text', title: 'Contenido' }, valueName: 'item.action_link' },
        { text: 'Hashtags sugeridos', value: 'hashtags', editable: { type: 'text', title: 'Contenido' }, valueName: 'item.hashtags' },
        { text: '', value: 'actions', sortable: false }
      ]
    },
    ...mapGetters('posts', ['getPosts'])
  },
  methods: {
    deleteItem (item) {
      this.loading = true
      redaApp.deleteItem('posts', item.uid).catch(error => console.error(error)).finally(() => { this.loading = false })
    },
    inputEdit (item) {
      this.loading = true
      redaApp.update('posts', item, item.uid)
        .then(() => this.showSuccess('Cambios guardados'))
        .catch((error) => {
          console.error(error)
          this.showError('Error al guardar cambios')
        })
        .finally(() => { this.loading = false })
    },
    ...mapActions('message', ['showError', 'showSuccess'])
  }
}
</script>
