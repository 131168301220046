<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mb-2 mx-2"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
      ><v-icon left>mdi-import</v-icon>
        Importar
      </v-btn>
    </template>

    <ValidationObserver v-slot="{ invalid }">
      <v-card>
        <v-card-title>
          Importación masiva de datos
        </v-card-title>

        <v-card-text>
          <p>Puedes crear varios {{ humanName }} a partir de una hoja de cálculo de Excel</p>
          <v-form>
            <v-row>
              <v-col cols="12" sm="8">
                <ValidationProvider v-slot="{ errors }" name="Llave Crptógrafica" rules="required">
                  <v-text-field label="Cargar hoja de cálculo" prepend-icon="mdi-microsoft-excel" :error-messages="errors" v-model="fileName" @click="clickUpload" clearable required readonly></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors, validate }" name="dataFile" rules="required">
                  <input type="file" ref="file" @change="handleFileUpload" @input="validate" hidden>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-text>
          Descarga la plantilla <a :href="download">aquí</a>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="invalid"
            @click="save"
          >
            Importar datos
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
import axios from 'axios'
import { auth } from '@/api/firebaseApi'
import { mapActions } from 'vuex'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    }
  },
  computesRequired: true
})

export default {
  name: 'ImportData',
  data () {
    return {
      loading: false,
      dialog: false,
      fileName: null,
      dataFile: null
    }
  },
  props: ['name', 'humanName'],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    download () {
      return `${process.env.VUE_APP_API_BASE_URL}/api/xlsx/${this.name}`
    }
  },
  methods: {
    clickUpload () {
      this.$refs.file.click()
    },
    handleFileUpload () {
      this.dataFile = this.$refs.file.files[0]
      this.fileName = this.$refs.file.files[0].name
      // console.log(document.getElementById('input-285'));
    },
    async save () {
      this.loading = true
      this.dialog = false
      const token = await auth.currentUser.getIdToken()
      const formData = new FormData()
      formData.append('dataFile', this.dataFile)
      axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/xlsx/${this.name}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${token}`
          }
        }
      )
        .then(({ data }) => {
          this.showSuccess(`${data.successes} ${this.humanName} fueron importados exitosamente`)
          if (data.errors) this.showError(`${data.errors} ${this.humanName} no pudieron ser importados`)
        })
        .catch(error => {
          this.showError = true
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions('message', ['showError', 'showSuccess'])
  }
}
</script>
