<template>
  <v-container>
    <CompaniesTable></CompaniesTable>
  </v-container>
</template>

<script>
import { /* mapState, mapActions, /* */ mapGetters } from 'vuex'
import CompaniesTable from '@/components/CompaniesTable.vue'

export default {
  tab: 0,
  name: 'Companies',
  components: { CompaniesTable },
  data: () => ({
    tab: 0
  }),
  methods: {},
  computed: {
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('user', ['getUsers'])
  }
}
</script>
