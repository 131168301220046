<template>
  <v-container>
    <NewOrder />
  </v-container>
</template>

<script>
import NewOrder from '@/components/NewOrder.vue'

export default {
  name: 'TakeOrders',

  components: {
    NewOrder
  }
}
</script>
