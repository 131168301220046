// import store from '@/store/index';

export default {
  submitText: 'Registrar',
  cancelText: 'Cancelar',
  inputs: [
    {
      id: '001',
      label: 'Fecha',
      name: 'date',
      value: 'date',
      type: 'date',
      rules: 'required',
      cols: 12
    },
    {
      id: '002',
      label: 'Cuentas',
      name: 'accounts',
      value: 'accounts',
      type: 'table',
      // rules: 'required',
      table: {
        dense: true,
        hideDefaultFooter: true,
        calculateWidths: true
      },
      options: {
        headers: [
          { text: 'Código', value: 'account' },
          { text: 'Cuenta', value: 'name' },
          { text: 'Debe', value: 'debit', editable: { type: 'number', title: 'Actualizar Debe' } },
          { text: 'Haber', value: 'credit', editable: { type: 'number', title: 'Actualizar Haber' } },
          { text: '', value: 'actions', sortable: false }
        ],
        delete: true,
        insert: {
          itemValue: 'account',
          itemText: 'name',
          items: [],
          inserts: [
            { inputKey: 'account', objKey: 'id' },
            { inputKey: 'name', objKey: 'name' }
          ],
          filter: (item, queryText) => {
            const textOne = item.name.toLowerCase()
            const textTwo = item.code.toLowerCase()
            const textThree = String(item.id).toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
              textTwo.indexOf(searchText) > -1 ||
              textThree.indexOf(searchText) > -1
          }
        },
        defaultItem: {
          account: '',
          name: '',
          debit: 0,
          credit: 0
        },
        form: {
          submitText: 'Registrar',
          cancelText: 'Cancelar',
          showSubmit: true,
          showCancel: true,
          inputs: [
            {
              id: 'de00',
              label: 'Buscar cuenta',
              name: 'search',
              value: 'search',
              type: 'autocomplete',
              // items: store.getters['accounting/getAccountsCatalog'],
              itemValue: 'code',
              itemText: 'name',
              returnObject: true,
              inserts: [
                { objKey: 'code', inputKey: 'code' },
                { objKey: 'name', inputKey: 'name' }
              ],
              cols: 12,
              filter: (item, queryText) => {
                const textOne = item.name.toLowerCase()
                const textTwo = item.code.toLowerCase()
                const textThree = String(item.id).toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 ||
                  textTwo.indexOf(searchText) > -1 ||
                  textThree.indexOf(searchText) > -1
              }
            },
            {
              id: 'de01',
              label: 'Código',
              name: 'code',
              value: 'code',
              type: 'text',
              rules: 'required',
              readonly: true,
              cols: 4
            },
            {
              id: 'de02',
              label: 'Nombre de cuenta',
              name: 'name',
              value: 'name',
              type: 'text',
              rules: 'required',
              readonly: true,
              cols: 8
            },
            {
              id: 'de03',
              label: 'Debe',
              name: 'debit',
              value: 'debit',
              type: 'number',
              rules: 'required|numeric',
              cols: 6
            },
            {
              id: 'de04',
              label: 'Haber',
              name: 'credit',
              value: 'credit',
              type: 'number',
              rules: 'required|numeric',
              cols: 6
            }
          ]
        }
      },
      cols: 12
    },
    {
      id: '003',
      label: 'Detalle',
      name: 'description',
      value: 'description',
      type: 'text',
      rules: 'required',
      cols: 12
    },
    {
      id: '004',
      label: 'Documentos',
      name: 'docs',
      value: 'docs',
      type: 'file',
      multiple: true,
      cols: 12
    }
  ],
  showSubmit: true,
  showCancel: true
}
