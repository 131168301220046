<!-- eslint-disable quote-props -->
<template>
  <v-card>

    <template
        v-for="(item, i) in getStatementOfCashFlows.items"
    >

      <v-list-item  :key="i">
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>{{ item.value | currencyFormat }}</v-list-item-action>
      </v-list-item>

      <v-card-text v-for="item2 in item.items" :key="item2.text + i">

        <v-card-subtitle v-if="item2.value !== 0">{{item2.text}}</v-card-subtitle>
        <v-simple-table
          dense
          v-if="item2.value !== 0"
        >
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(valueItem, j) in item2.items"
                :key="j"
              >
                <td>
                  {{ valueItem.text }}
                </td>
                <td class="text-right">{{ valueItem.value | currencyFormat }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>
                  {{ 'Total' }}
                </th>
                <th class="text-right">{{ item2.value | currencyFormat }}</th>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
    </template>

  </v-card>
</template>

<script>
import { /* mapState, mapActions,/**/ mapGetters } from 'vuex'
/* eslint-disable quote-props */
// import tools from '@/api/tools.js'
export default {
  name: 'StatementOfCashFlows',
  data () {
    return {}
  },
  methods: {
  },
  computed: {
    getStatementOfCashFlows () {
      const cashAndCashEquivalents = this.getAccountingConfig.cashAndCashEquivalents
      const types = {
        '1.01': 1,
        '1.02': 2,
        '2.01': 1,
        '2.02': 3,
        '3': 3,
        '4': 1,
        '5': 1
      }
      const cash = this.getAllLedgers.find(({ code }) => code === cashAndCashEquivalents)
      const transactions = []
      const initialCashBalance = cash.balances.previousBalance
      let cashBalance = cash.balances.previousBalance

      const cashEntries = this.getJournal.filter(({ codes }) => codes.find((code) => code.startsWith(cashAndCashEquivalents)))

      const increaseDecreaseInCashAndCashEquivalents = cash.balances.balance - cash.balances.previousBalance
      cashEntries.forEach(({ accounts }, i) => {
        accounts.forEach(({ code, debit, credit, name }, j) => {
          if (
            code.substring(0, cashAndCashEquivalents.length) !== cashAndCashEquivalents
          ) {
            const value = credit - debit
            transactions.push({
              code,
              value,
              name,
              type: types[code.substring(0, (Number(code[0]) > 2 ? 1 : 4))]
            })
            cashBalance += value
          }
        })
      })

      const cashFlowsFromUsedInOperatingActivities = transactions.reduce((acc, trans) => acc + (trans.type === 1 ? trans.value : 0), 0)
      const cashFlowsFromUsedInInvestingActivities = transactions.reduce((acc, trans) => acc + (trans.type === 2 ? trans.value : 0), 0)
      const cashFlowsFromUsedInFinancingActivities = transactions.reduce((acc, trans) => acc + (trans.type === 3 ? trans.value : 0), 0)

      const items = [
        {
          text: 'Flujos de efectivo procedentes de (utilizados en) actividades de operación',
          value: transactions.reduce((acc, trans) => acc + (trans.type === 1 ? trans.value : 0), 0),
          items: [
            {
              text: 'Cobros procedentes de',
              value: transactions.reduce((acc, { type, value }) => acc + (type === 1 && value > 0 ? value : 0), 0),
              items: [
                { text: 'Cuentas comerciales por cobrar y otras cuentas por cobrar corrientes', value: transactions.reduce((acc, { type, value }) => acc + (type === 1 && value > 0 ? value : 0), 0) }
              ]
            /* items: transactions.map(({ name, value, type }, i) => {
              if (type === 1 && value > 0) return { text: name, value }
            }).filter((element) => element) */
            },
            {
              text: 'Pagos por concepto de',
              value: transactions.reduce((acc, { type, value }) => acc + (type === 1 && value < 0 ? value : 0), 0),
              items: transactions.map(({ name, value, type }, i) => {
                if (type === 1 && value < 0) return { text: name, value }
              }).filter((element) => element)
            }
          ]
        },
        {
          text: 'Flujos de efectivo procedentes de (utilizados en) actividades de inversión',
          value: transactions.reduce((acc, trans) => acc + (trans.type === 2 ? trans.value : 0), 0),
          items: [
            {
              text: 'Importes por venta o cobro procedentes de',
              value: transactions.reduce((acc, { type, value }) => acc + (type === 2 && value > 0 ? value : 0), 0),
              items: transactions.map(({ name, value, type }, i) => {
                if (type === 2 && value > 0) return { text: name, value }
              }).filter((element) => element)
            },
            {
              text: 'Erogaciones por compra o por pago de',
              value: transactions.reduce((acc, { type, value }) => acc + (type === 2 && value < 0 ? value : 0), 0),
              items: transactions.map(({ name, value, type }, i) => {
                if (type === 2 && value < 0) return { text: name, value }
              }).filter((element) => element)
            }
          ]
        },
        {
          text: 'Flujos de efectivo procedentes de (utilizados en) actividades de financiación',
          value: transactions.reduce((acc, trans) => acc + (trans.type === 3 ? trans.value : 0), 0),
          items: [
            {
              text: 'Importes procedentes de',
              value: transactions.reduce((acc, { type, value }) => acc + (type === 3 && value > 0 ? value : 0), 0),
              items: transactions.map(({ name, value, type }, i) => {
                if (type === 3 && value > 0) return { text: name, value }
              }).filter((element) => element)
            },
            {
              text: 'Pagos por concepto de',
              value: transactions.reduce((acc, { type, value }) => acc + (type === 3 && value < 0 ? value : 0), 0),
              items: transactions.map(({ name, value, type }, i) => {
                if (type === 3 && value < 0) return { text: name, value }
              }).filter((element) => element)
            }
          ]
        },
        { text: 'Incremento (disminución) neto de efectivo y equivalentes al efectivo', value: increaseDecreaseInCashAndCashEquivalents },
        { text: 'Efectivo y equivalentes al efectivo al principio del periodo', value: initialCashBalance },
        { text: 'Efectivo y equivalentes al efectivo al final del periodo', value: cashBalance }
      ]

      const statementOfCashFlows = {
        items,
        cash,
        initialCashBalance,
        cashBalance,
        increaseDecreaseInCashAndCashEquivalents,
        cashFlowsFromUsedInOperatingActivities,
        cashFlowsFromUsedInInvestingActivities,
        cashFlowsFromUsedInFinancingActivities
      }
      return statementOfCashFlows
    },
    ...mapGetters('accounting', ['getAllLedgers', 'getJournal']),
    ...mapGetters('company', ['getAccountingConfig'])
  }
}
</script>
