<!-- eslint-disable vue/valid-v-for -->
<template>
  <ValidationObserver v-slot="{ invalid }" ref="form" v-if="!inactive">
    <v-container>
      <v-row>
        <v-col cols="12" :sm="input.cols" v-for="(input, i) in options.inputs" :key="i">
          <ValidationProvider
            v-if="input.type !== 'table' || input.type !== 'display'"
            v-slot="{ errors }"
            :name="input.name"
            :rules="input.rules"
          >
            <v-text-field
              v-if="input.type === 'text' || input.type === 'number'"
              :type="input.type"
              v-show="!input.hiden"
              autocomplete="off"
              :error-messages="errors"
              :value="value[input.value]"
              :append-icon="input.appendIcon || undefined"
              :append-outer-icon="input.appendOuterIcon || undefined"
              :autofocus="input.autofocus || false"
              :background-color="input.backgroundColor || undefined"
              :clear-icon="input.clearIcon || '$clear'"
              :clearable="input.clearable || false"
              :color="input.color || undefined"
              :counter="input.counter || undefined"
              :counter-value="input.counterValue || null"
              :dark="input.dark || false"
              :dense="input.dense || false"
              :disabled="input.disabled || false"
              :error="input.error || false"
              :error-count="input.errorCount || 1"
              :filled="input.filled || false"
              :flat="input.flat || false"
              :full-width="input.fullWidth || false"
              :height="input.height || undefined"
              :hide-details="input.hideDetails || undefined"
              :hint="input.hint || undefined"
              :id="input.id.toString()"
              :label="input.label || undefined"
              :light="input.light || false"
              :loader-height="input.loaderHeight || 2"
              :loading="input.loading || false"
              :messages="input.messages || []"
              :outlined="input.outlined || false"
              :persistent-hint="input.persistentHint || false"
              :placeholder="input.placeholder || undefined"
              :prefix="input.prefix || undefined"
              :prepend-icon="input.prependIcon || undefined"
              :prepend-inner-icon="input.prependInnerIcon || undefined"
              :readonly="input.readonly || false"
              :reverse="input.reverse || false"
              :rounded="input.rounded || false"
              :shaped="input.shaped || false"
              :single-line="input.singleLine || false"
              :solo="input.solo || false"
              :solo-inverted="input.soloInverted || false"
              :success="input.success || false"
              :success-messages="input.successMessages || []"
              :suffix="input.suffix || undefined"
              :validate-on-blur="input.validateOnBlur || false"
              @input="inputEmit({
                value: $event,
                item: input,
              })"
              @change="inputEmit({
                value: $event,
                item: input,
              })"
              @blur="inputEmit({
                value: $event.target.value,
                item: input,
              })"
            ></v-text-field>
            {{ input.hint }}
            <v-autocomplete
              v-if="input.type === 'autocomplete'"
              v-show="!input.hiden"
              :error-messages="errors"
              :value="value[input.value]"
              :allow-overflow="input.allowOverflow || true"
              :append-icon="input.appendIcon || '$dropdown'"
              :append-outer-icon="input.appendOuterIcon || undefined"
              :attach="input.attach || false"
              :auto-select-first="input.autoSelectFirst || false"
              :autofocus="input.autofocus || false"
              :background-color="input.backgroundColor || undefined"
              :cache-items="input.cacheItems || false"
              :chips="input.chips || false"
              :clear-icon="input.clearIcon || '$clear'"
              :clearable="input.clearable || false"
              :color="input.color || undefined"
              :counter="input.counter || undefined"
              :counter-value="input.counterValue || null"
              :dark="input.dark || false"
              :deletable-chips="input.deletableChips || false"
              :dense="input.dense || false"
              :disable-lookup="input.disableLookup || false"
              :disabled="input.disabled || false"
              :eager="input.eager || false"
              :error="input.error || false"
              :error-count="input.errorCount || 1"
              :filled="input.filled || false"
              :filter="input.filter ? input.filter : (item, queryText, itemText) => {}"
              :flat="input.flat || false"
              :full-width="input.fullWidth || false"
              :height="input.height || undefined"
              :hide-details="input.hideDetails || undefined"
              :hide-no-data="input.hideNoData || false"
              :hide-selected="input.hideSelected || false"
              :hint="input.hint || undefined"
              :id="input.id.toString()"
              :item-color="input.itemColor || 'primary'"
              :item-disabled="input.itemDisabled || 'disabled'"
              :item-text="input.itemText || 'text'"
              :item-value="input.itemValue || 'value'"
              :items="input.items || []"
              :label="input.label || undefined"
              :light="input.light || false"
              :loader-height="input.loaderHeight || 2"
              :loading="input.loading || false"
              :menu-props="input.menuProps || {
                closeOnClick: false,
                closeOnContentClick: false,
                disableKeys: true,
                openOnClick: false,
                maxHeight: 304
              }"
              :messages="input.messages || []"
              :multiple="input.multiple || false"
              :no-data-text="input.noDataText || '$vuetify.noDataText'"
              :no-filter="input.noFilter || false"
              :open-on-clear="input.openOnClear || false"
              :outlined="input.outlined || false"
              :persistent-hint="input.persistentHint || false"
              :placeholder="input.placeholder || undefined"
              :prefix="input.prefix || undefined"
              :prepend-icon="input.prependIcon || undefined"
              :prepend-inner-icon="input.prependInnerIcon || undefined"
              :readonly="input.readonly || false"
              :return-object="input.returnObject || false"
              :reverse="input.reverse || false"
              :rounded="input.rounded || false"
              :search-input="input.searchInput || undefined"
              :shaped="input.shaped || false"
              :single-line="input.singleLine || false"
              :small-chips="input.smallChips || false"
              :solo="input.solo || false"
              :solo-inverted="input.soloInverted || false"
              :success="input.success || false"
              :success-messages="input.successMessages || []"
              :suffix="input.suffix || undefined"
              :type="input.type || 'text'"
              :validate-on-blur="input.validateOnBlur || false"
              @input="inputEmit({
                value: $event,
                item: input,
              })"
              @change="inputEmit({
                value: $event,
                item: input,
              })"
            >
              <template v-slot:item="data" v-if="input.viewData">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item[input.itemValue] || data.item.value"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item[input.itemText] || data.item.text"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-select
              v-if="input.type === 'select'"
              v-show="!input.hiden"
              :error-messages="errors"
              :value="value[input.value]"
              :append-icon="input.appendIcon || '$dropdown'"
              :append-outer-icon="input.appendOuterIcon || undefined"
              :attach="input.attach || false"
              :autofocus="input.autofocus || false"
              :background-color="input.backgroundColor || undefined"
              :cache-items="input.cacheItems || false"
              :chips="input.chips || false"
              :clear-icon="input.clearIcon || '$clear'"
              :clearable="input.clearable || false"
              :color="input.color || undefined"
              :counter="input.counter || undefined"
              :counter-value="input.counterValue || null"
              :dark="input.dark || false"
              :deletable-chips="input.deletableChips || false"
              :dense="input.dense || false"
              :disable-lookup="input.disableLookup || false"
              :disabled="input.disabled || false"
              :eager="input.eager || false"
              :error="input.error || false"
              :error-count="input.errorCount || 1"
              :filled="input.filled || false"
              :flat="input.flat || false"
              :full-width="input.fullWidth || false"
              :height="input.height || undefined"
              :hide-details="input.hideDetails || undefined"
              :hide-selected="input.hideSelected || false"
              :hint="input.hint || undefined"
              :id="input.id.toString()"
              :item-color="input.itemColor || 'primary'"
              :item-disabled="input.itemDisabled || 'disabled'"
              :item-text="input.itemText || 'text'"
              :item-value="input.itemValue || 'value'"
              :items="input.items || []"
              :label="input.label || undefined"
              :light="input.light || false"
              :loader-height="input.loaderHeight || 2"
              :loading="input.loading || false"
              :menu-props="input.menuProps || {
                'closeOnClick': false,
                'closeOnContentClick': false,
                'disableKeys': true,
                'openOnClick': false,
                'maxHeight': 304
              }"
              :messages="input.messages || []"
              :multiple="input.multiple || false"
              :no-data-text="input.noDataText || '$vuetify.noDataText'"
              :open-on-clear="input.openOnClear || false"
              :outlined="input.outlined || false"
              :persistent-hint="input.persistentHint || false"
              :placeholder="input.placeholder || undefined"
              :prefix="input.prefix || undefined"
              :prepend-icon="input.prependIcon || undefined"
              :prepend-inner-icon="input.prependInnerIcon || undefined"
              :readonly="input.readonly || false"
              :return-object="input.returnObject || false"
              :reverse="input.reverse || false"
              :rounded="input.rounded || false"
              :shaped="input.shaped || false"
              :single-line="input.singleLine || false"
              :small-chips="input.smallChips || false"
              :solo="input.solo || false"
              :solo-inverted="input.soloInverted || false"
              :success="input.success || false"
              :success-messages="input.successMessages || []"
              :suffix="input.suffix || undefined"
              :validate-on-blur="input.validateOnBlur || false"
              @input="inputEmit({
                value: $event,
                item: input,
              })"
              @change="inputEmit({
                value: $event,
                item: input,
              })"
            ></v-select>
            <v-checkbox
              v-if="input.type === 'checkbox'"
              v-show="!input.hiden"
              :error-messages="errors"
              :value="value[input.value]"
              :append-icon="input.appendIcon"
              :background-color="input.backgroundColor"
              :color="input.color"
              :dark="input.dark || false"
              :dense="input.dense || false"
              :disabled="input.disabled || false"
              :error="input.error || false"
              :error-count="input.errorCount || 1"
              :false-value="input.falseValue"
              :hide-details="input.hideDetails"
              :hint="input.hint"
              :id="input.id.toString()"
              :indeterminate="input.indeterminate || false"
              :indeterminate-icon="input.indeterminateIcon || '$checkboxIndeterminate'"
              :input-value="value[input.value]"
              :label="input.label || undefined"
              :light="input.light || false"
              :messages="input.messages || []"
              :multiple="input.multiple || false"
              :off-icon="input.offIcon || '$checkboxOff'"
              :on-icon="input.onIcon || '$checkboxOn'"
              :persistent-hint="input.persistentHint || false"
              :prepend-icon="input.prependIcon"
              :readonly="input.readonly || false"
              :ripple="input.ripple || true"
              :success="input.success || false"
              :success-messages="input.successMessages || []"
              :true-value="input.trueValue"
              :validate-on-blur="input.validateOnBlur || false"
              @change="inputEmit({
                value: $event,
                item: input,
              })"
              @blur="inputEmit({
                value: $event.target.value,
                item: input,
              })"
            ></v-checkbox>
            <v-text-field
              v-if="input.type === 'date'"
              v-show="!input.hiden"
              :error-messages="errors"
              :value="dateParse(value[input.value])"
              :label="input.label"
              prepend-icon="mdi-calendar"
              readonly
              @input="inputEmit({
                value: $event,
                item: input,
              })"
              @click.stop="menu[input.id] = true"
            ></v-text-field>
            <v-file-input
              v-if="input.type === 'file'"
              show-size
              truncate-length="15"
              :multiple="input.multiple"
              :value="files[input.value]"
              @change="inputEmit({
                value: $event,
                item: input,
              })"
            ></v-file-input>
            <v-textarea
              v-if="input.type === 'textarea'"
              v-show="!input.hiden"
              :error-messages="errors"
              :value="value[input.value]"
              :append-icon="input.appendIcon || undefined"
              :append-outer-icon="input.appendOuterIcon || undefined"
              :auto-grow="input.autoGrow || false"
              :autofocus="input.autofocus || false"
              :background-color="input.backgroundColor || undefined"
              :clear-icon="input.clearIcon || '$clear'"
              :clearable="input.clearable || false"
              :color="input.color || undefined"
              :counter="input.counter || undefined"
              :counter-value="input.counterValue || null"
              :dark="input.dark || false"
              :dense="input.dense || false"
              :disabled="input.disabled || false"
              :error="input.error || false"
              :error-count="input.errorCount || 1"
              :filled="input.filled || false"
              :flat="input.flat || false"
              :full-width="input.fullWidth || false"
              :height="input.height || undefined"
              :hide-details="input.hideDetails || undefined"
              :hide-spin-buttons="input.hideSpinButtons || false"
              :hint="input.hint || undefined"
              :id="input.id.toString()"
              :label="input.label || undefined"
              :light="input.light || false"
              :loader-height="input.loaderHeight || 2"
              :loading="input.loading || false"
              :messages="input.messages || []"
              :no-resize="input.noResize || false"
              :outlined="input.outlined || false"
              :persistent-hint="input.persistentHint || false"
              :persistent-placeholder="input.persistentPlaceholder || false"
              :placeholder="input.placeholder || undefined"
              :prefix="input.prefix || undefined"
              :prepend-icon="input.prependIcon || undefined"
              :prepend-inner-icon="input.prependInnerIcon || undefined"
              :readonly="input.readonly || false"
              :reverse="input.reverse || false"
              :rounded="input.rounded || false"
              :row-height="input.rowHeight || 24"
              :rows="input.rows || 5"
              :shaped="input.shaped || false"
              :single-line="input.singleLine || false"
              :solo="input.solo || false"
              :solo-inverted="input.soloInverted || false"
              :success="input.success || false"
              :success-messages="input.successMessages || []"
              :suffix="input.suffix || undefined"
              :type="input.type || 'text'"
              :validate-on-blur="input.validateOnBlur || false"
              @input="inputEmit({
                value: $event,
                item: input,
              })"
              @change="inputEmit({
                value: $event,
                item: input,
              })"
              @blur="inputEmit({
                value: $event.target.value,
                item: input,
              })"
            ></v-textarea>
            <div   v-if="input.type === 'date'">
              <v-menu
                v-if="input.type === 'date'"
                v-model="menu[input.id]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                absolute
                min-width="auto"
              >
                <v-date-picker
                  :value="dateParse(value[input.value])"
                  @change="inputEmit({
                    value: $event,
                    item: input,
                  })"
                ></v-date-picker>
              </v-menu>

            </div>
            <div class="text-body-2 error--text" v-for="(error, i) in errors" :key="i">
              {{error}}
            </div>
          </ValidationProvider>
          <DataTableCRUD
            v-if="input.type === 'table'"
            v-show="!input.hiden"
            :table="input.table"
            :options="input.options"
            @input="inputEmit({
              value: $event,
              item: input,
            })"
            :value="value[input.value]"
          ></DataTableCRUD>
          <div
            v-if="input.type === 'title'"
            v-text="input.text"
            :class="classes"
          ></div>
          <div v-if="input.type === 'form'">
            <RedaForm
              :value="value[input.value]"
              :options="input.options"
              @input="inputEmit({
                value: $event,
                item: input,
              })"
              :inactive="input.inactive"
            ></RedaForm>
          </div>
          <v-container v-if="input.type === 'checkboxesGroup'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ input.label }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-switch
                    @change="selectAll(input, $event)"
                    v-model="selectAllData[input.value]"
                    label="Seleccionar todos"
                  ></v-switch></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(item) in input.items"
              >
                {{ item.value }}
                <v-checkbox
                  :label="item.text"
                  :value="value[input.value].includes(item.value)"
                  :disabled="selectAllData[input.value]"
                  @change="inputEmit({
                    name: item.value,
                    value: $event,
                    item: input,
                  })"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-list-item two-line v-if="input.type === 'display'">
            <v-list-item-content>
              <v-list-item-subtitle>{{ input.label }}</v-list-item-subtitle>
              <v-list-item-title>{{ input.items ? input.items.find((item) => item.value === value[input.value] ).text : value[input.value] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-btn
        color="primary"
        :disabled="invalid"
        @click="$emit('submitForm')"
        v-if="options.showSubmit"
        :loading="loading"
      >
        {{options.submitText}}
      </v-btn>

      <v-btn text @click="$emit('cancel')" v-if="options.showCancel">{{options.cancelText}}</v-btn>
    </v-container>
  </ValidationObserver>
</template>

<script>
// @ is an alias to /src
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode
} from 'vee-validate'
import moment from 'moment'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'

setInteractionMode('eager')

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    }
  },
  computesRequired: true
})

export default {
  name: 'RedaForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: [
    'value',
    'options',
    'inactive',
    'loading'
  ],
  data: () => ({
    menu: {},
    // invalid: false,
    isMounted: false,
    files: {},
    moment,
    selectAllData: {}
  }),
  mounted () {
    this.options.inputs.forEach((input) => {
      this.$set(this.menu, input.id, false)
      if (input.type === 'file') this.$set(this.files, input.id, null)
    })
    const selectAllData = {}
    this.options.inputs.forEach(({ value, type }) => {
      if (type === 'checkboxesGroup') selectAllData[value] = false
    })
    this.selectAllData = { ...selectAllData }
    this.isMounted = true
  },
  computed: {
    valid () {
      if (!this.isMounted) {
        return false
      }
      return this.$refs.form.flags.valid
    }
  },
  methods: {
    selectAll (input, value) {
      this.selectAllData[input.value] = value
      if (!value) return
      const newValue = { ...this.value }
      newValue[input.value] = input.items.map((item) => item.value)
      this.$emit('input', newValue)
    },
    dateParse (date) {
      if (!date) return date
      return moment(date, 'x').format().substr(0, 10)
    },
    emitInvalid (invalid) {
      console.log(invalid)
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate()
          .then((success) => {
            resolve(success)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    clean () {
      const items = { ...this.value }
      this.options.inputs.forEach(({ value, defaultValue }) => {
        items[value] = defaultValue || null
      })
      this.$emit('input', items)
    },
    readAsDataURLAsync (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result
          resolve(dataURL)
        }

        reader.onerror = (event) => {
          reject(event.target.error)
        }

        reader.readAsDataURL(file)
      })
    },
    async inputEmit ({ item, value, name }) {
      /* this.$refs.form.validate()
        .then((success) => {
          this.$emit('valid', success);
        })
        .catch((error) => {
          console.error(error);
        }); /* */
      let newValue
      if (item.type === 'checkbox' && value === null) {
        newValue = false
      } else if (item.type === 'number') {
        newValue = Number(value)
      } else if (item.type === 'date') {
        this.menu[item.id] = false
        newValue = Number(moment(value).format('x'))
      } else if (item.type === 'file') {
        this.files = { ...this.files, [item.id]: value }
        if (item.multiple) {
          const files = []
          for (let i = 0; i < value.length; i++) {
            const file = value[i]
            files.push(await this.readAsDataURLAsync(file))
          }
          newValue = files
        } else {
          newValue = value ? await this.readAsDataURLAsync(value) : null
        }
      } else if (item.type === 'checkboxesGroup') {
        newValue = [...this.value[item.value]]
        const i = newValue.indexOf(name)
        if (value) {
          newValue.push(value)
        } else if (i > -1) {
          newValue.splice(i, 1)
        }
      } else {
        newValue = value
      }
      const items = { ...this.value }
      const index = this.options.inputs.indexOf(item)
      if (index < 0) {
        console.error(`El valor ${item} no existe`)
        return
      }
      const inputItem = this.options.inputs[index]
      const inputValue = inputItem.value
      items[inputValue] = newValue
      if (inputItem.inserts) {
        inputItem.inserts.forEach(({ objKey, inputKey }) => {
          items[inputKey] = newValue[objKey]
        })
      }
      this.$emit('input', items) /* */
    }
  }
}
</script>
