<template>
    <v-container>
      <Receipts />
    </v-container>
  </template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import Receipts from '@/components/Receipts.vue'

export default {
  name: 'Payments',
  components: { Receipts },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  watch: {
  },

  created () {
  },

  methods: {
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
  <style type="text/css">
  </style>
