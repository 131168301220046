/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment';
// import numberPos from '@/api/numberPos.js'
import fbApi from '../../api/firebaseApi'
import tools from '@/api/tools'
import crypto from 'crypto'
import redaApp from '../../api/redaApp'

const { db, api } = fbApi

const modules = [
  'accounting',
  'cashDaily',
  'chats',
  'customers',
  'inventories',
  'messages',
  'orders',
  'products',
  'stays',
  'vouchers',
  'payments',
  'accountReceivable',
  'siinco',
  'fixedAssets',
  'subscriptions',
  'posts'
]

// initial state
const initialState = {
  companies: [],
  listinerCompany: null,
  listinerApiVoucher: null,
  company: null,
  branch: null,
  apiVoucher: { stag: false, prod: false }
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getStaff (state, { getCompany }, rootSate, rootGetters) {
    const staff = []
    for (let i = 0; i < getCompany.staff.length; i++) {
      const sta = getCompany.staff[i]
      const user = rootGetters['user/getUsers'].find(({ userUID }) => userUID === sta.userUID)
      const contact = rootGetters['customers/getCustomers'].find(({ uid, id }) => uid === sta.contact || id === sta.contact)
      staff.push({ ...sta, user, contact, id: sta.id })
    }
    return staff
  },
  getCompanies (state) {
    return state.companies
  },
  getCompany (state) {
    if (state.companies.length === 0) return {}
    const company = state.companies.find(({ identification }) => identification === state.company)
    if (!company) {
      return state.company
    }
    return company
  },
  getBranch (state, { getCompany }) {
    if (getCompany) {
      const index = getCompany.branches.findIndex(item => {
        return item.code === state.branch
      })
      return index > -1 ? getCompany.branches[index] : state.branch
    } else {
      return state.branch
    }
  },
  getInitialBalance (state, { getCompany }, rootState, rootGetters) {
    const initialBalance = []
    if (getCompany.initialBalance) {
      for (let i = 0; i < getCompany.initialBalance.length; i++) {
        const { account, balance } = getCompany.initialBalance[i]
        const acc = rootState.accounting.accounts.find(({ code }) => code === account.toString())
        initialBalance.push({ code: acc.code, name: acc.name, balance })
      }
    }
    return initialBalance
  },
  getAccountingConfig (state, { getCompany }) {
    const defaultAccountsSystem = {
      accountReceivable: getCompany.defaultAccountsSystem?.accountReceivable || 10102,
      accountPayable: getCompany.defaultAccountsSystem?.accountPayable || 20102,
      revenueFromSaleOfGoods: getCompany.defaultAccountsSystem?.revenueFromSaleOfGoods || 40101,
      revenueFromRenderingOfServices: getCompany.defaultAccountsSystem?.revenueFromRenderingOfServices || 40102,
      currentTaxLiabilitiesIVA: getCompany.defaultAccountsSystem?.currentTaxLiabilitiesIVA || 2010301,
      propertyPlantAndEquipment: getCompany.defaultAccountsSystem?.propertyPlantAndEquipment || 10201,
      inventoriesHeldForSale: getCompany.defaultAccountsSystem?.inventoriesHeldForSale || 1010101,
      materialsAndSuppliesToProduction: getCompany.defaultAccountsSystem?.materialsAndSuppliesToProduction || 1010103,
      workInProgress: getCompany.defaultAccountsSystem?.workInProgress || 1010102,
      currentTaxAssetsIVA: getCompany.defaultAccountsSystem?.currentTaxAssetsIVA || 1010301,
      administrativeExpense: getCompany.defaultAccountsSystem?.administrativeExpense || 503,
      distributionCosts: getCompany.defaultAccountsSystem?.distributionCosts || 502,
      retainedEarnings: getCompany.defaultAccountsSystem?.retainedEarnings || 302,
      cashAndCashEquivalents: getCompany.defaultAccountsSystem?.cashAndCashEquivalents || '1.01.08',
      paymentType: {
        USD: {
          '01': getCompany.defaultAccountsSystem?.paymentType?.USD['01'] || 101080101,
          '02': getCompany.defaultAccountsSystem?.paymentType?.USD['02'] || 101080102,
          '03': getCompany.defaultAccountsSystem?.paymentType?.USD['03'] || 101080102,
          '04': getCompany.defaultAccountsSystem?.paymentType?.USD['04'] || 101080102,
          99: getCompany.defaultAccountsSystem?.paymentType?.USD['99'] || 101080102
        },
        CRC: {
          '01': getCompany.defaultAccountsSystem?.paymentType?.CRC['01'] || 101080101,
          '02': getCompany.defaultAccountsSystem?.paymentType?.CRC['02'] || 101080102,
          '03': getCompany.defaultAccountsSystem?.paymentType?.CRC['03'] || 101080102,
          '04': getCompany.defaultAccountsSystem?.paymentType?.CRC['04'] || 101080102,
          99: getCompany.defaultAccountsSystem?.paymentType?.CRC['99'] || 101080102
        }
      }
    }
    return defaultAccountsSystem
  },
  getApiVoucher (state) {
    return state.apiVoucher
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, payload) {
    try {
      const companiesQuerySnapshot = await fbApi.db.collection('companies')
        .where('identification', 'in', payload)
        .get()
      const companies = []
      companiesQuerySnapshot.forEach((doc) => companies.push({ uid: doc.id, ...doc.data() }))
      commit('setCompanies', companies)
      const listinerCompany = companiesQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newCompanies = [...state.companies] || []
        querySnapshot.docChanges().forEach(({ doc }) => {
          const i = newCompanies.findIndex(({ uid }) => doc.id === uid)
          if (i === -1) {
            newCompanies.push({ uid: doc.id, ...doc.data() })
          } else {
            newCompanies[i] = { uid: doc.id, ...doc.data() }
          }
        })
        commit('setCompanies', newCompanies)
      })
      commit('setListinerCompany', listinerCompany)
      // const apiVoucherQuerySnapshot = await fbApi.db.collection('apiVouchers')
      //   .where('company', 'in', payload)
      //   .get()
      // console.log('apiVoucherQuerySnapshot', apiVoucherQuerySnapshot.empty)
      // if (apiVoucherQuerySnapshot.empty) {
      //   commit('setApiVoucher', { stag: false, prod: false })
      //   numberPos.forEach((num) => {
      //     // commit(`${num}/setVoucherType`, '10', { root: true })
      //   })
      // } else {
      //   apiVoucherQuerySnapshot.forEach((doc) => {
      //     const apiVoucher = doc.data()
      //     console.log({ ...apiVoucher })
      //     commit('setApiVoucher', { stag: !!apiVoucher.stag, prod: !!apiVoucher.prod })
      //     numberPos.forEach((num) => {
      //       // commit(`${num}/setVoucherType`, '01', { root: true })
      //     })
      //   })
      // }
      // const listinerApiVoucher = apiVoucherQuerySnapshot.query.onSnapshot((querySnapshot) => {
      //   if (querySnapshot.empty) {
      //     commit('setApiVoucher', { stag: false, prod: false })
      //     numberPos.forEach((num) => {
      //       // commit(`${num}/setVoucherType`, '10', { root: true })
      //     })
      //   } else {
      //     apiVoucherQuerySnapshot.forEach((doc) => {
      //       const apiVoucherAux = doc.data()
      //       commit('setApiVoucher', { stag: !!apiVoucherAux.stag, prod: !!apiVoucherAux.prod })
      //       numberPos.forEach((num) => {
      //         // commit(`${num}/setVoucherType`, '01', { root: true })
      //       })
      //     })
      //   }
      // })
      // commit('setListinerApiVoucher', listinerApiVoucher)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar compañias')
    }
  },
  async updateCompany ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const apiVoucherQuerySnapshot = await fbApi.db.collection('apiVouchers')
        .where('company', '==', company.id)
        .get()
      if (apiVoucherQuerySnapshot.empty) {
        commit('setApiVoucher', { stag: false, prod: false })
      } else {
        const doc = apiVoucherQuerySnapshot.docs[0]
        const apiVoucher = doc.data()
        console.log({ ...apiVoucher })
        commit('setApiVoucher', { stag: !!apiVoucher.stag, prod: !!apiVoucher.prod })
      }
      commit('setCompany', company.id)
      commit('setBranch', company.defaultBranch)
      const callStack = [dispatch('user/initUsers', getters.getCompany.staff
        .filter(({ userUID }) => userUID)
        .map(({ userUID }) => userUID), { root: true })]
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i]
        commit(`${module}/reset`, null, { root: true })
        if (module === 'accounting') commit('accounting/setDefaultAccounts', getters.getCompany.defaultAccounts, { root: true })
        callStack.push(dispatch(`${module}/init`, company.id, { root: true }))
      }
      Promise.all(callStack).catch((error) => console.error(error))
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al actualizar compañia')
    }
  },
  insertStaff ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      redaApp.newStaff(payload)
        .then(() => resolve())
        .catch(error => reject(error))
    })
  },
  getElectronicVoucher ({ state, commit, dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      const { env, identification } = getters.getCompany
      db.collection('apiVouchers').where('company', '==', identification).get().then((snapshot) => {
        if (snapshot.empty) resolve(false)
        resolve(!!snapshot.docs[0].data()[env])
      }).catch((e) => reject(e))
    })
  },
  newCompany ({ state, commit, dispatch, getters, rootGetters }, { company, userUID }) {
    return new Promise((resolve, reject) => {
      const newCompanyTmp = {
        type: company.type,
        identification: company.identification,
        name: company.name,
        tradename: company.tradename,
        phone: company.phone,
        email: company.email,
        account: userUID,
        regimen: company.regimen,
        branding: {
          primary: '#1976D2',
          secondary: '#424242',
          useInSystem: false
        },
        branches: [
          {
            code: '001',
            name: 'Casa Matriz',
            terminal: Math.floor(Math.random() * (20000 - 10000)) + 10000,
            location: {
              province: company.location.province,
              county: tools.zfill(company.location.county, 2),
              district: tools.zfill(company.location.district, 2),
              others: company.location.others
            }
          }
        ],
        staff: [
          {
            userUID: userUID,
            role: 'owner'
          }
        ],
        roles: [],
        productCategories: [],
        mainActivity: company.activities[0].code,
        priceList: [
          'general',
          'mayorista',
          'especial'
        ],
        modules: [
          'billing', 'receiverMessages'
        ],
        activities: company.activities,
        taxReturn: ['D105IVA'],
        env: company.environment
      }
      api.insertItem('companies', newCompanyTmp, 'identification', true)
        .then(() => resolve(company.identification))
        .catch((error) => reject(error))
    })
  },
  newCert ({ state, commit, dispatch, getters, rootGetters }, { cert, apiCE, environment, company }) {
    return new Promise((resolve, reject) => {
      const certApi = { company }
      certApi[environment] = {
        user: apiCE.user,
        password: apiCE.password,
        cert: { keyPair: { key: cert.key, x509: cert.x509 } },
        pin: apiCE.pin
      }
      api.insertItem('apiVouchers', certApi, 'company', true)
        .then(() => resolve(company.identification))
        .catch((error) => reject(error))
    })
  },
  newONVO ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      db.collection('companies').where('identification', '==', getters.getCompany.identification).get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const itemSort = tools.getSortedObject({ ...payload })
            const dataSort = tools.getSortedObject({ ...doc.data().onvo })
            const hashDoc = crypto.createHash('sha256').update(
              JSON.stringify(dataSort)
            ).digest('hex')
            const hashItem = crypto.createHash('sha256').update(
              JSON.stringify(itemSort)
            ).digest('hex')
            if (hashDoc === hashItem) resolve()
            doc.ref.update({ onvo: payload })
              .then(() => resolve())
              .catch((error) => reject(error))
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async reset ({ state, commit, dispatch, getters, rootGetters }) {
    try {
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i]
        commit(`${module}/reset`, null, { root: true })
      }
      commit('reset')
    } catch (error) {
      console.error(error)
      throw new Error('Error al actualizar compañia')
    }
  },
  setAccoutingConfig ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      db.collection('companies').where('identification', '==', getters.getCompany.identification).get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const itemSort = tools.getSortedObject({ ...payload })
            const dataSort = tools.getSortedObject({ ...doc.data().defaultAccounts })
            const hashDoc = crypto.createHash('sha256').update(
              JSON.stringify(dataSort)
            ).digest('hex')
            const hashItem = crypto.createHash('sha256').update(
              JSON.stringify(itemSort)
            ).digest('hex')
            if (hashDoc === hashItem) resolve()
            doc.ref.update({ defaultAccounts: payload })
              .then(() => resolve())
              .catch((error) => reject(error))
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerCompany) {
      state.listinerCompany()
    }
    if (state.listinerApiVoucher) {
      state.listinerApiVoucher()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerApiVoucher (state, listener) {
    state.listinerApiVoucher = listener
  },
  setListinerCompany (state, listener) {
    state.listinerCompany = listener
  },
  setCompany (state, payload) {
    if (!payload) state.listinerCompany()
    state.company = payload
  },
  setCompanies (state, payload) {
    state.companies = payload
  },
  setBranch (state, payload) {
    state.branch = payload
  },
  setApiVoucher (state, payload) {
    console.log('commit apiVoucher', payload)
    state.apiVoucher = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
