<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          v-if="!showSignUp"
        >
          <v-card class="elevation-12" width="448px" :loading="loading">
            <v-card-title>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row  align="center" justify="center" class="ma-10">
                  <v-col cols="12" align="center" justify="center">
                    <v-img :src="logo" max-width="200"></v-img>
                  </v-col>
                </v-row>
              <v-form>
                <v-row>
                  <v-col cols="12">
                <v-text-field
                  label="Email"
                  name="user"
                  type="text"
                  v-model="login.email"
                  autofocus
                  autocomplete="off"
                  outlined
                ></v-text-field>
                <v-text-field
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  id="password"
                  label="Contraseña"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="login.password"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="auth"
                  outlined
                  messages="true"
                  v-if="!resetPass"
                ><template v-slot:message><p class="text-right ma-0"><a @click="resetPass = !resetPass" >¿Olvidó su contraseña?</a></p></template></v-text-field>
                <div v-if="resetPass">
                  <p>Si el correo existe, le enviaremos un mensaje para restablecer la contraseña. Revisa su bandeja de entrada y sigue los pasos que se le indican en el correo.</p>
                  <p>¿No recibes el correo?</p>
                  <ul>
                    <li>Revisa su bandeja de correo no deseado.</li>
                    <li>Espera unos minutos y vuelve a intentarlo.</li>
                    <li>Contacta a nuestro equipo de soporte <a href="https://wa.me/50686237548" target="_blank">aquí</a></li>
                  </ul>
                </div>
                <div><p class="error--text">{{error}}</p></div>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                  <v-spacer></v-spacer>
                <v-btn text @click="showSignUp = !showSignUp" v-if="!resetPass">Crear cuenta</v-btn>
                <v-btn text @click="resetPass = !resetPass" v-if="resetPass">Volver</v-btn>
                <v-btn color="primary" @click="auth">{{ resetPass ? "Enviar correo" : "Iniciar sesión" }}</v-btn>
              </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              </v-card-actions>
          </v-card>
        </v-col>
        <sign-up v-else @cancel="showSignUp = false"></sign-up>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import errorAuth from '@/assets/ErrorAuth.js'
import SignUp from '@/views/SignUp.vue'
import { mapActions } from 'vuex'
import logoOnvo from '@/assets/logo2.png'
import logoRedaBits from '@/assets/logo.png'

export default {
  name: 'Login',
  components: {
    SignUp
  },
  data () {
    return {
      resetPass: false,
      loading: false,
      editing: false,
      showPassword: false,
      showSignUp: false,
      login: {
        email: '',
        password: ''
      },
      error: ''
    }
  },
  methods: {
    auth () {
      this.loading = true
      if (this.resetPass) {
        this.sendPasswordResetEmail({ email: this.login.email.trim() })
          .then(() => {
            this.loading = false
            this.resetPass = false
            this.showSuccess('Correo enviado')
            console.log('Email enviado al usuario ')
          // this.setProjects();
          })
          .catch(error => {
            this.loading = false
            console.error(error)
            this.showError('Error al enviar correo')
            this.error = errorAuth[error.code] ? errorAuth[error.code] : error.message
          })
        return
      }
      this.signIn({ email: this.login.email.trim(), password: this.login.password.trim() })
        .then(({ user }) => {
          this.loading = false
          console.log(user.uid + ' logged!')
          // this.setProjects();
        })
        .catch(error => {
          this.loading = false
          console.error(error)
          this.error = errorAuth[error.code] ? errorAuth[error.code] : error.message
        })
    },
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ]),
    ...mapActions('user', [
      'signIn',
      'sendPasswordResetEmail'
    ])
  },
  computed: {
    logo () {
      if (process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com') {
        return logoOnvo
      }
      return logoRedaBits
    }
  }
}
</script>
