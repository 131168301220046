<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <div>
    <v-app v-if="!loadingApp">
      <v-navigation-drawer clipped app :permanent="$vuetify.breakpoint.mdAndUp" :expand-on-hover="$vuetify.breakpoint.mdAndUp" v-model="drawer" v-if="getLogged && notPublic" width="284">
        <template v-slot:prepend v-if="pos">
          <v-list-item :to="pos.to"
          >
            <v-list-item-icon>
              <v-icon color="tertiary" v-if="!pos.custom"> {{ pos.icon }} </v-icon>
              <i v-html="pos.icon" v-else></i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ pos.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <div class="pa-4"><v-divider style="background: #F72585;"></v-divider></div>
        <v-list dense>
          <template v-for="(item, i) in drawerItems">
            <v-list-group
            v-if="item.subgroup"
            :key="i"
            append-icon="mdi-chevron-down"
            no-action
            >
              <template v-slot:activator>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>
              <template v-slot:prependIcon>
                <v-icon color="tertiary" v-if="!item.custom"> {{ item.icon }} </v-icon>
                <i v-html="item.icon" v-else></i>
              </template>
              <v-list-item
              link
              v-for="(sub, j) in item.subgroup"
              :to="sub.to"
              :key="j"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ sub.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
            :to="item.to"
            link
            :key="'A'+i"
            v-else
            >
              <v-list-item-icon>
                <v-icon color="tertiary" v-if="!item.custom"> {{ item.icon }} </v-icon>
                <i v-html="item.icon" v-else></i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar clipped-left dark color="primary" app v-if="getLogged && notPublic">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.mdAndDown" class="mx-0"></v-app-bar-nav-icon>
        <v-toolbar-title class="px-0 mx-0">
          <v-img :src="logo" width="160"></v-img>
          <!-- <v-select
            :items="getCompanies"
            item-value="identification"
            item-text="name"
            dense
            return-object
            class="mt-3"
            :value="getCompany.identification"
            @change="changeCompany"
          >
            <template v-slot:selection>
              {{getCompany ? getCompany.tradename : getCompany.name}}
            </template>
          </v-select> -->
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mt-6 mx-6" style="max-width: 193px;">
          <v-autocomplete
            label="Buscar"
            outlined
            filled
            rounded
            append-icon="mdi-magnify"
            dense
            :items="getViewsItems"
            item-text="title"
            return-object
            @input="$event && $event.to !== $route.path && $router.push($event.to)"
            clearable
          >
            <template v-slot:selection="data">
              {{ data.item.title }}
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <i v-html="data.item.icon" v-if="data.item.custom"></i>
                <v-icon color="primary" v-else>{{ data.item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="primary--text">
                <v-list-item-title v-html="data.item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-menu
          left
          bottom
          offset-y
          rounded="bl-xl"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-avatar>
                <v-img :src="getUser.photoURL" v-if="getUser.photoURL"></v-img>
                <v-icon dark v-else>
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <NavigationDrawer @logOut="logOut"></NavigationDrawer>
        </v-menu>
        <!-- -->
      </v-app-bar>

      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <message></message>
          <div class="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="true"></div>
          <!-- If using vue-router -->
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
        <v-col
          class="text-center"
          cols="12"
        >
          <v-row align="center" justify="center">&copy; {{ new Date().getFullYear() }} — &nbsp;<strong>redaBits</strong></v-row>
        </v-col><!--

        <v-container fluid>

          <v-row align="center" justify="center"><v-img src="@/assets/redabits.png" max-width="100"></v-img></v-row>
        </v-container>
      -->
      </v-footer>
    </v-app>
    <v-app v-else>
      <v-container style="height: 400px;">
        <v-row
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col
            class="text-subtitle-1 text-center"
            cols="12"
          >
            Cargando sistema...
          </v-col>
          <v-col cols="6" class="text-center">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import message from '@/components/Message.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import views from '@/assets/moduleViews'
import logoOnvo from '@/assets/logo2.png'
import logoRedaBits from '@/assets/logo.svg'

export default {
  name: 'App',

  components: {
    message,
    NavigationDrawer
  },

  data: () => ({
    drawerBar: false
  }),

  methods: {
    changeCompany (company) {
      this.changeCurrentCompany(company.identification)
        .then(() => {
          const authorizations = this.getUser.companies.find(({ id }) => id === company.identification)?.authorizations
          const authorizationModules = authorizations === 'admin'
            ? views.moduleViews
            : authorizations
          const auth = authorizationModules.includes(this.$router.currentRoute.name) || this.$router.currentRoute.meta.restricted
          if (!auth) {
            this.$router.push('/').catch(() => {})
          }
        })
        .catch((error) => {
          console.error(error)
          this.showError('Ocurrió un error al cambiar de compañia')
        })
    },
    logOut () {
      this.setLoadingApp(true)
      this.signOut()
        .then(() => {
          console.log('Sign Out!')
          location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    ...mapMutations([
      'setLoadingApp'
    ]),
    ...mapActions('user', [
      'signOut',
      'changeCurrentCompany'
    ])
  },

  mounted () {
  },

  computed: {
    pos () {
      return this.getViewsItems.find(({ to }) => to === '/biller') || this.getViewsItems.find(({ to }) => to === '/issueVoucher')
    },
    logo () {
      if (process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com') {
        return logoOnvo
      }
      return logoRedaBits
    },
    drawer: {
      // getter
      get () {
        return this.drawerBar
      },
      // setter
      set (newValue) {
        this.drawerBar = newValue
      }
    },
    getViewsItems () {
      return this.getViews.reduce((acc, cur) => {
        if (cur.to) {
          acc.push(cur)
          return acc
        }
        return acc.concat(cur.subgroup)
      }, [])
    },
    isMovil () {
      const aux = !this.$vuetify.breakpoint.mdAndUp
      return aux
    },
    /* branding () {
      if (this.getCompany.branding.useInSystem) {
        this.$vuetify.theme.themes.light.primary = this.getCompany.branding.primary
        this.$vuetify.theme.themes.light.tertiary = this.getCompany.branding.tertiary
      }
      return { primary: this.$vuetify.theme.themes.light.primary, tertiary: this.$vuetify.theme.themes.light.tertiary }
    }, */
    ...mapState([
      'loadingApp'
    ]),
    ...mapGetters('user', [
      'getLogged',
      'getUser',
      'getViews',
      'getAuthorizations'
    ]),
    ...mapGetters('company', [
      'getCompany',
      'getCompanies'
    ]),
    notPublic () {
      return this.$router.currentRoute.name !== 'Public'
    },
    drawerItems () {
      const menu = this.getViews.filter(({ title }) => title !== 'Punto de venta')
      return menu
    }
  }
}
</script>
<style>
.v-list-group__items .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: grey;
}
.v-list .v-list-group__header .v-list-item--active {
  color: black;
  background: #EBF1FF;
}
.v-list .v-list-item--active {
  color: black;
  background: #EBF1FF;
}
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.v-list-item {
  border-radius: 8px;
}
</style>
