import tools from '@/api/tools.js'
import { firebaseConfig } from '@/api/firebase.js'
import crypto from 'crypto'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

firebase.initializeApp(firebaseConfig)

function convertirUndefinedANull (objeto) {
  Object.keys(objeto).forEach(propiedad => {
    if (objeto[propiedad] === undefined) {
      objeto[propiedad] = null
    }
  })
  return objeto
}

export const fb = firebase
export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
export default {
  fb,
  db,
  auth,
  storage,
  api: {
    insertItem (collection, item, idItem = 'id', logger = false) {
      return new Promise((resolve, reject) => {
        convertirUndefinedANull(item)
        if (logger) console.log({ ...item })
        let id = item[idItem]
        db.collection(collection).get()
          .then((querySnapshot) => {
            id = id || querySnapshot.size + 1
            return db.collection(collection).where(idItem, '==', id).get()
          })
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              const newItem = { ...item }
              newItem[idItem] = id
              return db.collection(collection).add(newItem)
            } else {
              querySnapshot.forEach((doc) => {
                const itemSort = tools.getSortedObject(item)
                const dataSort = tools.getSortedObject(doc.data())
                const hashDoc = crypto.createHash('sha256').update(
                  JSON.stringify(dataSort)
                ).digest('hex')
                const hashItem = crypto.createHash('sha256').update(
                  JSON.stringify(itemSort)
                ).digest('hex')
                return hashDoc === hashItem || doc.ref.update(item)
              })
            }
          })
          .then((updated) => {
            resolve(updated)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getItem (collection, id, idItem = 'id', company) {
      return new Promise((resolve, reject) => {
        db.collection(collection).doc(id).get()
          .then((doc) => {
            if (!doc.exists) {
              if (company) return db.collection(collection).where('company', '==', company).where(idItem, '==', id).get()
              return db.collection(collection).where(idItem, '==', id).get()
            }
            resolve({ ...doc.data(), uid: doc.id })
          })
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              resolve({ ...doc.data(), uid: doc.id })
            })
          })
          .catch((error) => {
            console.error(collection, id, idItem, company)
            reject(error)
          })
      })
    },
    async getItemByUid (collection, id) {
      if (!id) return null
      try {
        const doc = await db.collection(collection).doc(id).get()
        if (!doc.exists) return null
        return { ...doc.data(), uid: doc.id }
      } catch (error) {
        console.log('Payload error:', collection, id)
        throw error
      }
    },
    updateItemValue (collection, id, idItem = 'id', { text, value }) {
      return new Promise((resolve, reject) => {
        db.collection(collection).where(idItem, '==', id).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              return doc.ref.update({ [text]: value })
            })
          })
          .then(() => {
            console.log('Documento ' + id + ' actualizado')
            resolve('Documento ' + id + ' actualizado')
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteItem (collection, uid) {
      return new Promise((resolve, reject) => {
        db.collection(collection).doc(uid).delete()
          .then(() => {
            resolve(`¡Documento ${uid} eliminado correctamente!`)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    uploandFile ({ path, message, format }) {
      return new Promise((resolve, reject) => {
        const storageRef = storage.ref()
        const ref = storageRef.child(path)
        ref.putString(message, format)
          .then((snapshot) => {
            resolve(snapshot)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    downloadFile (path) {
      return new Promise((resolve, reject) => {
        const storageRef = storage.ref()
        const ref = storageRef.child(path)
        ref.getDownloadURL()
          .then((url) => {
            window.open(url)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
