<template>
  <v-data-table
    :headers="headers"
    :items="blogs"
    class="elevation-1"
    sort-by="priority"
    :search="search"
    dense
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <v-dialog v-model="dialog">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on"><v-icon left>mdi-plus</v-icon>Nuevo</v-btn>
          </template>
          <v-card
            :loading="loading"
          >
          <v-toolbar elevation="0">
            <v-toolbar-title>Crear nuevo post</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

            <v-card-text>
              <BlogForm></BlogForm>
            </v-card-text>

            <v-card-actions v-if="false">
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="dialog = false">Cerrar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.publishDate="{ item }">
      {{ item.publishDate | dateFormat }}
    </template>
    <template v-slot:item.updateDate="{ item }">
      {{ item.updateDate | dateFormat }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        class="ma-1"
        v-if="item.status"
        :color="statusObj[item.status].color"
      >
      {{ statusObj[item.status].text | capitalizeWords }}
      </v-chip>
    </template>
    <template v-slot:item.author="{ item }">
      {{ item.author ? item.author.name : null }}
    </template>
    <template v-slot:item.actions="{ item }">
      <blog-dialog :item="item"></blog-dialog>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import BlogForm from '@/components/BlogForm.vue'
import BlogDialog from '@/components/BlogDialog.vue'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'BlogsTable',
  components: {
    BlogDialog,
    BlogForm
  },
  props: {
    blogs: Array
  },
  data: () => ({
    dialog: false,
    search: null,
    loading: false,
    dialogItem: false,
    priorityObj: {
      1: { text: 'Alta', color: 'error' },
      2: { text: 'Media', color: 'warning' },
      3: { text: 'Baja', color: 'success' }
    },
    statusObj: {
      published: { text: 'publicado', color: 'success' },
      draft: { text: 'borrador', color: 'info' },
      archived: { text: 'archivado', color: 'grey' }
    }
  }),

  mounted () {
  },

  computed: {
    headers () {
      return [
        { text: 'Fecha de publicación', value: 'publishDate' },
        { text: 'Título', value: 'title' },
        { text: 'Última actualización', value: 'updateDate' },
        { text: 'Autor', value: 'author' },
        { text: 'Estado', value: 'status' },
        { text: '', value: 'actions', sortable: false }
      ]
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    emitUpdated () {
      this.$emit('updated')
    },

    getDateFormat (date) {
      return moment(date, 'x').format('L')
    },

    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.code.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },

    editItem (item) {
    },

    deleteItem (item) {
    },

    save () {
    },
    ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
