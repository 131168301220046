<template>
  <v-container>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab>
        Información genral
      </v-tab>
      <v-tab>
        Comprobantes Electrónicos
      </v-tab>
      <v-tab>
        Departamentos y centros de costo
      </v-tab>
      <v-tab>
        Equipo de trabajo
      </v-tab>
      <v-tab>
        Integración ONVO
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <CompanyConfig />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <ElectronicVouchers />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <CostCenters />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <Staff />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <ONVO />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script type="text/javascript">
import CostCenters from '@/components/CostCenters.vue'
import ElectronicVouchers from '@/components/ElectronicVouchers.vue'
import Staff from '@/components/Staff.vue'
import CompanyConfig from '@/components/CompanyConfig.vue'
import ONVO from '@/components/ONVO.vue'
export default {
  name: 'Company',
  components: {
    CompanyConfig,
    CostCenters,
    Staff,
    ONVO,
    ElectronicVouchers
  },
  data: () => ({
    tab: 0
  })
}
</script>
