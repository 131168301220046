<template>
  <v-card
    max-width="600"
    class="mx-auto"
  >
    <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>Comprobantes</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-autocomplete
        class="mt-4"
        :items="items"
        item-value="key"
        item-text="key"
        multiple
        :filter="customFilter"
        v-model="selectedVouchers"
        append-icon="mdi-magnify"
        @input="emitVouchers"
        return-object
      >
        <template v-slot:selection>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <DialogVoucher :voucher="data.item.key"></DialogVoucher>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="data.item.data.sequence"></v-list-item-title>

            <v-list-item-subtitle v-text="data.item.data.receiver ? data.item.data.receiver.name : ''"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            {{ data.item.data.summary.netTotal | currencyFormat(data.item.data.summary.currency) }}
          </v-list-item-action>
        </template>
      </v-autocomplete>

    </v-toolbar>

    <v-list
      subheader
      two-line
    >

      <v-list-item
        v-for="voucher in selectedVouchers"
        :key="voucher.key"
      >
        <v-list-item-avatar>
          <DialogVoucher :voucher="voucher.key"></DialogVoucher>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="voucher.data.sequence"></v-list-item-title>

          <v-list-item-subtitle v-text="voucher.data.receiver ? voucher.data.receiver.name : ''"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="error" @click="remove(voucher)">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import DialogVoucher from './DialogVoucher.vue'

export default {
  name: 'VouchersList',
  components: {
    DialogVoucher
  },
  props: [
    'value',
    'isPayment',
    'items'
  ],
  data: () => ({
    selectedVouchers: []
  }),
  methods: {
    remove (item) {
      const index = this.selectedVouchers.indexOf(item)
      if (index >= 0) this.selectedVouchers.splice(index, 1)
    },
    emitVouchers () {
      const vouchers = this.selectedVouchers
      this.$emit('input', vouchers)
    },
    customFilter (item, queryText, itemText) {
      const textOne = item.key.toString()
      const textTwo = item.data.sequence.toString()
      const textThree = item.data.receiver?.name.toLowerCase() || ''
      const textFour = item.data.receiver?.toString() || ''
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1 ||
          textThree.indexOf(searchText) > -1 ||
          textFour.indexOf(searchText) > -1
    }
  },
  watch: {
    value (newValue, oldValue) {
      this.selectedVouchers = newValue
    }
  }
}
</script>
