/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import moment from 'moment'
import fbApi from '@/api/firebaseApi'
import tools from '@/api/tools'
import dAndC from '@/assets/deductionsAndContributions'

const { api } = fbApi

// initial state
const initialState = {
  costCenters: [],
  addressingOrders: [],
  addressingOrdersAliases: [],
  salaries: [],
  workingHours: [],
  deductions: dAndC.deductions,
  contributions: dAndC.contributions,
  baseSalary: 462200.00,
  typeConcept: [
    { value: '01', text: 'Sueldos y salarios' },
    { value: '02', text: 'Aportaciones a la seguridad social' },
    { value: '03', text: 'Otros beneficios a los empleados a corto plazo' },
    { value: '04', text: 'Gastos por beneficios post-empleo, planes de aportaciones definidas' },
    { value: '05', text: 'Gastos por beneficios post-empleo, planes de beneficios definidos' },
    { value: '06', text: 'Gastos por beneficios de terminación a empleados' },
    { value: '07', text: 'Otros beneficios a los empleados a largo plazo' },
    { value: '08', text: 'Otros gastos de personal' },
    { value: '09', text: 'Insumos' },
    { value: '10', text: 'Papeleria y artículos de oficina' },
    { value: '11', text: 'Servicio de alcantarillado y agua potable' },
    { value: '12', text: 'Servicio de elcetricidad' },
    { value: '13', text: 'Servicio de cable y/o internet' },
    { value: '14', text: 'Telefonia' },
    { value: '15', text: 'Marketing y publicidad' },
    { value: '16', text: 'Consultoria' },
    { value: '17', text: 'Servicios profecionales' },
    { value: '18', text: 'Servicios Varios' },
    { value: '19', text: 'Alquiler de inmueble' },
    { value: '20', text: 'Alquiler de bienes muebles' },
    { value: '21', text: 'Alquiler de equipos y herramientas' },
    { value: '22', text: 'Alquiler de Software' },
    { value: '23', text: 'Alquiler de licencias de uso' },
    { value: '24', text: 'Otros alquileres' },
    { value: '25', text: 'Patentes y permisos de operación' },
    { value: '26', text: 'Seguros de riego' },
    { value: '27', text: 'Siniestros' },
    { value: '28', text: 'Depreciación de activos' },
    { value: '29', text: 'Otros gastos o costos' }
  ],
  typeCC: {
    CCA: 'Centro de costo administrativo',
    CCS: 'Centro de costo de servicio',
    CCP: 'Centro de costo productivo',
    CCV: 'Centro de costo de venta'
  },
  typeAD: {
    '00': 'Materia prima directa; variable',
    '01': 'Materia prima indirecta; variable',
    '02': 'Mano de obra directa; variable',
    '03': 'Mano de obra indirecta; variable',
    '04': 'Mano de obra directa; fijo',
    '05': 'Mano de obra indirecta; fijo',
    '06': 'Cargos indirectos; variable',
    '07': 'Cargos indirectos; fijo',
    '08': 'Otros variables',
    '09': 'Otros fijos'
  },
  tmpAddressingOrder: {
    id: null,
    company: null,
    docsRef: [],
    concept: null,
    costCenter: null,
    description: null,
    type: null,
    amount: 0
  },
  tmpCostCenter: {
    code: null,
    company: null,
    name: null,
    type: null,
    description: null,
    subCostCenters: [],
    parentCostCenter: null,
    editable: true
  },
  tmpSalary: {
    id: null,
    type: null,
    employee: null,
    hourlyPay: 0,
    schoolSalary: false
  },
  tmpWorkingHour: {
    id: null,
    type: null,
    employee: null,
    entry: null,
    exit: null,
    cc: null,
    workday: 0,
    productionOrder: null
  },
  listinerCostCenters: null,
  listinerAddressingOrders: null,
  listinerAddressingOrdersAliases: null,
  listinerSalaries: null,
  listinerWorkingHours: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getWorkingHours (state, getters, rootState, rootGetters) {
    const workingHoursTmp = []
    state.workingHours.forEach((item, i) => {
      const employee = rootGetters['company/getCompany'].staff.find(({ id }) => item.employee === id)
      const time1 = moment(item.entry, 'x')
      const time2 = moment(item.exit, 'x')
      const hours = time2.diff(time1, 'm') / 60
      const ordHours = hours > 8 ? 8 : hours
      const extHours = hours > 8 ? hours - 8 : 0
      const grossSalary = (ordHours * Number(item.workday)) + (extHours * (1.5 * Number(item.workday)))
      const deductions = []
      const contributions = []
      let deductionsTotal = 0
      let contributionsTotal = 0
      state.deductions.forEach((ded, j) => {
        const deduction = ded.type === '01' ? ded.value * grossSalary : ded.value
        deductionsTotal += deduction
        deductions.push({ ...ded, deduction })
      })
      state.contributions.forEach((con, j) => {
        const contribution = con.type === '01' ? con.value * grossSalary : con.value
        contributionsTotal += contribution
        contributions.push({ ...con, contribution })
      })
      const netSalary = grossSalary - deductionsTotal
      const costTotal = grossSalary + contributionsTotal
      workingHoursTmp.push({
        ...item,
        employee,
        ordHours,
        extHours,
        grossSalary,
        deductions,
        contributions,
        deductionsTotal,
        contributionsTotal,
        netSalary,
        costTotal
      })
    })
    return workingHoursTmp
  },
  getSalaries ({ salaries }, getters, rootState, rootGetters) {
    const salariesTmp = []
    salaries.forEach((item, i) => {
      const employee = rootGetters['company/getCompany'].staff.find(({ id }) => item.employee === id)
      salariesTmp.push({ ...item, employee })
    })
    return salariesTmp
  },
  getAdminExpenses (state, getters, rootState, rootGetters) {
    const adminExpenses = getters.getAddressingOrders.filter(({ id }) => id.toString()[0] === '1')
    adminExpenses.forEach((item, i) => {
      const vouRef = item.docsRef.find(({ type }) => type === 'voucher')
      const voucher = rootGetters['vouchers/getVouchersReceived'].find(({ key }) => key === vouRef.ref)
      adminExpenses[i] = { ...item, voucher }
    })

    return adminExpenses
  },
  getbaseSalary ({ baseSalary }, getters, rootState, rootGetters) {
    return baseSalary
  },
  getbaseSalary25PC ({ baseSalary }, getters, rootState, rootGetters) {
    return baseSalary * 0.25
  },
  getTypeConcept ({ typeConcept }, getters, rootState, rootGetters) {
    return typeConcept
  },
  getTypeCCItems ({ typeCC }, getters, rootState, rootGetters) {
    const typeCCItems = []
    Object.entries(typeCC).forEach((item, i) => {
      typeCCItems.push({ value: item[0], text: item[1] })
    })
    return typeCCItems
  },
  getTypeCC ({ typeCC }, getters, rootState, rootGetters) {
    return typeCC
  },
  getTypeADItems ({ typeAD }, getters, rootState, rootGetters) {
    const typeADItems = []
    Object.entries(typeAD).forEach((item, i) => {
      typeADItems.push({ value: item[0], text: item[1] })
    })
    return typeADItems
  },
  getTypeAD ({ typeAD }, getters, rootState, rootGetters) {
    return typeAD
  },
  getAddressingOrdersAliasesIndexed: ({ addressingOrdersAliases }, getters, { to, from, products }, rootGetters) => {
    return addressingOrdersAliases.reduce((acc, curr) => {
      if (!acc[curr.code]) acc[curr.code] = curr
      return acc
    }, {})
  },
  getAddressingOrdersAliases ({ addressingOrdersAliases }, getters, rootState, rootGetters) {
    const aoa = []
    addressingOrdersAliases.forEach((item, i) => {
      aoa.push({ ...item })
    })
    return aoa
  },
  getAddressingOrders ({ addressingOrders }, { getAddressingOrdersAliases }, rootState, rootGetters) {
    // const aos = []
    // const vouchersReceivedItems = rootGetters['vouchers/getVoucherReceivedItems']
    // for (let index = 0; index < vouchersReceivedItems.length; index++) {
    //   const item = vouchersReceivedItems[index]
    //   if (item.aos && item.aos.length > 0) {
    //     for (let j = 0; j < item.aos.length; j++) {
    //       const ao = item.aos[j]
    //       aos.push(ao)
    //     }
    //     continue
    //   }
    //   const aoa = getAddressingOrdersAliases.find(({ cabys, code }) => item.code === cabys || item.commercialCode === code)
    //   if (aoa) {
    //     const ao = {
    //       ...aoa,
    //       date: item.date,
    //       voucher: item.voucher,
    //       amount: item.taxable_base
    //     }
    //     aos.push(ao)
    //   }
    // // }
    // addressingOrders.forEach((item, i) => {
    //   item.docsRef.forEach((doc, j) => {
    //     if (doc.type === 'voucher') {
    //       item.voucher = doc.ref
    //     }
    //   })
    //   aos.push({ ...item })
    // })
    return addressingOrders
  },
  getCostCentersCellar ({ costCenters }, getters, rootState, rootGetters) {
    const ccs = []
    costCenters.filter((cc) => cc.type === 'CCS' || cc.type === 'CCV' || cc.type === 'CCP')
      .forEach((item, i) => {
        ccs.push({ ...item })
      })
    return ccs
  },
  getCostCentersProduction ({ costCenters }, getters, rootState, rootGetters) {
    const ccs = []
    costCenters.filter((cc) => cc.type === 'CCP')
      .forEach((item, i) => {
        ccs.push({ ...item })
      })
    return ccs
  },
  getCostCenters ({ costCenters }, getters, rootState, rootGetters) {
    const ccs = []
    costCenters.forEach((item, i) => {
      ccs.push({ ...item })
    })
    return ccs
  },
  getParentCostCenter ({ costCenters }, getters, rootState, rootGetters) {
    const ccs = []
    costCenters.forEach((item, i) => {
      if (item.parentCostCenter === null) ccs.push(item.code)
    })
    return ccs
  },
  getCostCentersTree ({ costCenters }, getters, rootState, rootGetters) {
    if (getters.getCostCenters.length === 0) {
      return []
    }
    const subCCTypes = { subCostCenters: getters.getParentCostCenter }
    return tools.getChildren(subCCTypes, [...getters.getCostCenters], 'subCostCenters', 'code')
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters, rootState }, company) {
    try {
      const costCentersQuerySnapshot = await fbApi.db.collection('costCenters')
        .where('company', '==', company)
        .get()
      const costCenters = []
      costCentersQuerySnapshot.forEach((doc) => costCenters.push({ uid: doc.id, ...doc.data() }))
      commit('setCostCenters', costCenters)
      const listinerCostCenters = costCentersQuerySnapshot.query.onSnapshot((querySnapshot) => {
        if (!querySnapshot.isEqual(costCentersQuerySnapshot)) {
          dispatch('accounting/runAccounting', { from: rootState.from, to: rootState.to }, { root: true }).catch((e) => console.error(e))
          dispatch('vouchers/updateVouchers', null, { root: true }).catch((e) => console.error(e))
        }
        const newCostCenters = []
        querySnapshot.forEach((doc) => newCostCenters.push({ uid: doc.id, ...doc.data() }))
        commit('setCostCenters', newCostCenters)
      })
      commit('setListinerCostCenters', listinerCostCenters)
      const addressingOrdersQuerySnapshot = await fbApi.db.collection('addressingOrders')
        .where('company', '==', company)
        .get()
      const addressingOrders = []
      addressingOrdersQuerySnapshot.forEach((doc) => addressingOrders.push({ uid: doc.id, ...doc.data() }))
      commit('setAddressingOrders', addressingOrders)
      const listinerAddressingOrders = addressingOrdersQuerySnapshot.query.onSnapshot((querySnapshot) => {
        if (!querySnapshot.isEqual(addressingOrdersQuerySnapshot)) {
          dispatch('accounting/runAccounting', { from: rootState.from, to: rootState.to }, { root: true }).catch((e) => console.error(e))
          dispatch('vouchers/updateVouchers', null, { root: true }).catch((e) => console.error(e))
        }
        const newAddressingOrders = []
        querySnapshot.forEach((doc) => newAddressingOrders.push({ uid: doc.id, ...doc.data() }))
        commit('setAddressingOrders', newAddressingOrders)
      })
      commit('setListinerAddressingOrders', listinerAddressingOrders)
      const addressingOrdersAliasesQuerySnapshot = await fbApi.db.collection('addressingOrdersAliases')
        .where('company', '==', company)
        .get()
      const addressingOrdersAliases = []
      addressingOrdersAliasesQuerySnapshot.forEach((doc) => addressingOrdersAliases.push({ uid: doc.id, ...doc.data() }))
      commit('setAddressingOrdersAliases', addressingOrdersAliases)
      const listinerAddressingOrdersAliases = addressingOrdersAliasesQuerySnapshot.query.onSnapshot((querySnapshot) => {
        console.log('update addressingOrdersAliases', !querySnapshot.isEqual(addressingOrdersAliasesQuerySnapshot))
        if (!querySnapshot.isEqual(addressingOrdersAliasesQuerySnapshot)) {
          dispatch('accounting/runAccounting', { from: rootState.from, to: rootState.to }, { root: true }).catch((e) => console.error(e))
          dispatch('vouchers/updateVouchers', null, { root: true }).catch((e) => console.error(e))
        }
        const newAddressingOrdersAliases = []
        querySnapshot.forEach((doc) => newAddressingOrdersAliases.push({ uid: doc.id, ...doc.data() }))
        commit('setAddressingOrdersAliases', newAddressingOrdersAliases)
      })
      commit('setListinerAddressingOrdersAliases', listinerAddressingOrdersAliases)
      const salariesQuerySnapshot = await fbApi.db.collection('salaries')
        .where('company', '==', company)
        .get()
      const salaries = []
      salariesQuerySnapshot.forEach((doc) => salaries.push({ uid: doc.id, ...doc.data() }))
      commit('setSalaries', salaries)
      const listinerSalaries = salariesQuerySnapshot.query.onSnapshot((querySnapshot) => {
        if (!querySnapshot.isEqual(salariesQuerySnapshot)) {
          dispatch('accounting/runAccounting', { from: rootState.from, to: rootState.to }, { root: true }).catch((e) => console.error(e))
          dispatch('vouchers/updateVouchers', null, { root: true }).catch((e) => console.error(e))
        }
        const newSalaries = []
        querySnapshot.forEach((doc) => newSalaries.push({ uid: doc.id, ...doc.data() }))
        commit('setSalaries', newSalaries)
      })
      commit('setListinerSalaries', listinerSalaries)
      const workingHoursQuerySnapshot = await fbApi.db.collection('workingHours')
        .where('company', '==', company)
        .get()
      const workingHours = []
      workingHoursQuerySnapshot.forEach((doc) => workingHours.push({ uid: doc.id, ...doc.data() }))
      commit('setWorkingHours', workingHours)
      const listinerWorkingHours = workingHoursQuerySnapshot.query.onSnapshot((querySnapshot) => {
        if (!querySnapshot.isEqual(workingHoursQuerySnapshot)) {
          dispatch('accounting/runAccounting', { from: rootState.from, to: rootState.to }, { root: true }).catch((e) => console.error(e))
          dispatch('vouchers/updateVouchers', null, { root: true }).catch((e) => console.error(e))
        }
        const newWorkingHours = []
        querySnapshot.forEach((doc) => newWorkingHours.push({ uid: doc.id, ...doc.data() }))
        commit('setWorkingHours', newWorkingHours)
      })
      commit('setListinerWorkingHours', listinerWorkingHours)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar pagos recibidos')
    }
  },
  insertWorkingHour ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('workingHours', {
        ...payload,
        company: rootGetters['company/getCompany'].identification
      }, 'id', true)
        .then(() => resolve(payload))
        .catch((error) => {
          reject(error)
        })
    })
  },
  insertSalary ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('salaries', {
        ...payload,
        company: rootGetters['company/getCompany'].identification
      }, 'id', true)
        .then(() => resolve(payload))
        .catch((error) => {
          reject(error)
        })
    })
  },
  insertCostCenter ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('costCenters', {
        ...payload,
        company: rootGetters['company/getCompany'].identification
      }, 'code', true)
        .then(() => resolve(payload))
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteCostCenter ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.deleteItem('costCenters', payload.uid)
        .then((cc) => {
          resolve(cc)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  insertAddressingOrder ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('addressingOrders', {
        ...payload,
        company: rootGetters['company/getCompany'].identification
      }, 'id', true)
        .then((addressingOrder) => resolve(addressingOrder))
        .catch((error) => {
          reject(error)
        })
    })
  },
  insertAddressingOrderAlias ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.uid) {
        fbApi.db.collection('addressingOrdersAliases').doc(payload.uid).update(payload)
          .then(() => resolve())
          .catch((error) => {
            reject(error)
          })
      } else {
        fbApi.db.collection('addressingOrdersAliases').add({ ...payload, company: rootGetters['company/getCompany'].identification })
          .then(() => resolve())
          .catch((error) => {
            reject(error)
          })
      }
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerAddressingOrders) {
      state.listinerAddressingOrders()
    }
    if (state.listinerCostCenters) {
      state.listinerCostCenters()
    }
    if (state.listinerAddressingOrdersAliases) {
      state.listinerAddressingOrdersAliases()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerAddressingOrders (state, listener) {
    state.listinerAddressingOrders = listener
  },
  setListinerCostCenters (state, listener) {
    state.listinerCostCenters = listener
  },
  setAddressingOrders: (state, payload) => {
    if (!payload) state.listinerAddressingOrders()
    state.addressingOrders = payload
  },
  setCostCenters: (state, payload) => {
    if (!payload) state.listinerCostCenters()
    state.costCenters = payload
  },
  setSalaries: (state, payload) => {
    state.salaries = payload
  },
  setWorkingHours: (state, payload) => {
    state.salaries = payload
  },
  setAddressingOrdersAliases: (state, payload) => {
    state.addressingOrdersAliases = payload
  },
  setListinerAddressingOrdersAliases: (state, payload) => {
    state.listinerAddressingOrdersAliases = payload
  },
  setListinerSalaries: (state, payload) => {
    state.listinerSalaries = payload
  },
  setListinerWorkingHours: (state, payload) => {
    state.listinerWorkingHours = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
