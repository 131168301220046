<template>
  <v-card :loading="loading">
    <v-toolbar>
      <TaskActions :tasksIndexed="tasksIndexed" :item="item" @updated="emitUpdated"></TaskActions>
      <v-toolbar-title>{{ item.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn :loading="loading" icon v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <NewTask
          @close="dialog = false"
          @submit="refresh"
          :tasks="tasks"
          :staff="staff"
          :task="item"
        ></NewTask>
      </v-dialog>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <span></span>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
import { /* mapActions, */ mapGetters } from 'vuex'
import TaskActions from '@/components/TaskActions.vue'
import NewTask from '@/components/NewTask.vue'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'Task',
  components: { TaskActions, NewTask },
  props: {
    staff: Array,
    tasks: Array,
    item: Object,
    tasksIndexed: Object
  },
  data: () => ({
    loading: false,
    type: 'status',
    dialog: false,
    typeItems: [
      { text: 'Prioridad', value: 'priority' },
      { text: 'Estado', value: 'status' },
      { text: 'Responsable', value: 'assignee' }
    ],
    objs: {
      priority: {
        1: { text: 'Alta', color: 'error' },
        2: { text: 'Media', color: 'warning' },
        3: { text: 'Baja', color: 'success' }
      },
      status: {
        '01': { text: 'Pendiente', color: 'grey' },
        '02': { text: 'Iniciada', color: 'info' },
        '03': { text: 'Lista', color: 'success' },
        '04': { text: 'En peligro', color: 'warning' },
        '05': { text: 'Atrasada', color: 'error' }
      }
    }
  }),

  mounted () {
  },

  computed: {
    items () {
      return []
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    refresh () {
      this.$emit('refresh')
    },
    emitUpdated () {
      this.$emit('updated')
    }
    // ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
