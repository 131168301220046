var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getPrices,"sort-by":"priceList","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Precios")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","dark":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.getCompany.priceList,"label":"Lista de precios","required":""},model:{value:(_vm.editedItem.priceList),callback:function ($$v) {_vm.$set(_vm.editedItem, "priceList", $$v)},expression:"editedItem.priceList"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"type":"number","label":"Valor"},model:{value:(_vm.editedItem.price),callback:function ($$v) {_vm.$set(_vm.editedItem, "price", $$v)},expression:"editedItem.price"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":[
                      {text: 'Colones', value: 'CRC'},
                      {text: 'Dolares', value: 'USD'}
                    ],"label":"Moneda"},model:{value:(_vm.editedItem.currency),callback:function ($$v) {_vm.$set(_vm.editedItem, "currency", $$v)},expression:"editedItem.currency"}})],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }