/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment'
import fbApi from '@/api/firebaseApi'
import moment from 'moment/moment'
// import tools from '@/api/tools';

const { api } = fbApi

// initial state
const initialState = {
  fixedAssets: [],
  fixedAsset: {
    id: null,
    costCenter: null,
    acquisitionDate: null,
    description: null,
    type: null,
    category: null,
    annualDepreciationFee: null,
    usefulLife: null,
    depreciationMethod: '01',
    docsRef: [],
    historicalCost: 0
  },
  listinerFixedAssets: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getFixedAssets: ({ fixedAssets }, getters, rootState, rootGetters) => {
    const assets = []
    for (let index = 0; index < fixedAssets.length; index++) {
      const asset = fixedAssets[index]
      let costCentersString = ''
      const costCenters = asset.costCenters.map(({ cc, quantity }) => {
        const costC = rootGetters['siinco/getCostCenters'].find(({ code }) => code === cc)
        if (!costC) {
          console.log({ cc, quantity })
        }
        costCentersString += `${costC.name}: ${quantity}
        `
        return { ...costC, quantity }
      })
      asset.dateString = moment(asset.acquisitionDate, 'x').format('L')
      assets.push({ ...asset, costCenters, costCentersString: costCentersString.trim() })
    }
    return assets
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const fixedassetsQuerySnapshot = await fbApi.db.collection('fixedAssets')
        .where('company', '==', company)
        .get()
      const fixedassets = []
      fixedassetsQuerySnapshot.forEach((doc) => fixedassets.push({ uid: doc.id, ...doc.data() }))
      commit('setFixedAssets', fixedassets)
      const listinerFixedAssets = fixedassetsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newFixedAssets = []
        querySnapshot.forEach((doc) => newFixedAssets.push({ uid: doc.id, ...doc.data() }))
        commit('setFixedAssets', newFixedAssets)
      })
      commit('setListinerFixedAssets', listinerFixedAssets)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar activos fijos')
    }
  },
  insertFixedAsset ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('fixedAssets', {
        ...payload,
        company: rootGetters['company/getCompany'].identification
      }, 'code', true)
        .then((asset) => resolve(asset))
        .catch((error) => reject(error))
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerFixedAssets) {
      state.listinerFixedAssets()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setFixedAssets (state, payload) {
    if (!payload) state.listinerFixedAssets()
    state.fixedAssets = payload
  },
  setListinerFixedAssets (state, listener) {
    state.listinerFixedAssets = listener
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
