<template>
  <v-container fluid>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab>
        Punto de Venta
      </v-tab>
      <v-tab>
        Caja Diaria
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <v-tabs
            v-model="tabPos"
          >
            <v-tab
              v-for="(number, i) in numberPos"
              :key="i"
            >
              {{number}}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tabPos"
          >
            <v-tab-item
              v-for="(number, i) in numberPos"
              :key="i"
            >
              <v-container fluid><Pos :moduleName="number"></Pos></v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <CashDaily></CashDaily>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script type="text/javascript">
import Pos from '@/components/Pos.vue'
import CashDaily from '@/components/CashDaily.vue'
import numberPos from '@/api/numberPos.js'
export default {
  name: 'Biller',
  components: {
    CashDaily,
    Pos
  },
  data: () => ({
    tab: null,
    tabPos: null,
    numberPos
  })
}
</script>
