<template>
  <div>
    <highcharts :constructorType="'stockChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import tools from '../api/tools'

export default {
  props: ['data', 'title'],
  data () {
    return {
    }
  },
  computed: {
    chartOptions () {
      return {
        title: this.title ? {
          align: 'center',
          text: `${tools.capitalizeWords(this.getCompany.tradename || this.getCompany.name)}
          ${this.title}`
          // useHTML: true
        } : null,
        subtitle: {
          text: 'Del ' + moment(this.getFrom).format('LL') + ' al ' + moment(this.getTo).format('LL')
        },
        rangeSelector: {
          buttons: [{
            type: 'day',
            count: 1,
            text: '1D'
          }, {
            type: 'week',
            count: 1,
            text: '1S'
          }, {
            type: 'month',
            count: 1,
            text: '1M'
          }, {
            type: 'month',
            count: 3,
            text: '3M'
          }, {
            type: 'month',
            count: 6,
            text: '6M'
          }, {
            type: 'year',
            count: 1,
            text: '1A'
          }, {
            type: 'all',
            count: 1,
            text: 'Máx.'
          }],
          selected: 2,
          inputEnabled: true
        },
        series: this.data
      }
    },
    ...mapGetters(['getFrom', 'getTo']),
    // ...mapGetters('accounting', ['getDatesEntries']),
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
