/* global FB */

function statusChangeCallback (response) { // Called with the results from FB.getLoginStatus().
  console.log('statusChangeCallback')
  console.log(response) // The current login status of the person.
  if (response.status === 'connected') { // Logged into your webpage and Facebook.
    testAPI()
  } else { // Not logged into your webpage or we are unable to tell.
    FB.login(function (response) {
      console.log({ ...response })
      if (response.status === 'connected') statusChangeCallback(response)
    })
  }
}

function checkLoginState () { // Called when a person is finished with the Login Button.
  FB.getLoginStatus(function (response) { // See the onlogin handler
    statusChangeCallback(response)
  })
}

function testAPI () { // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
  console.log('Welcome!  Fetching your information.... ')
  FB.api('/me', function (response) {
    console.log('Successful login for: ' + response.name)
    alert('Thanks for logging in, ' + response.name + '!')
  })
}
export default { checkLoginState, statusChangeCallback }
