var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"num","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Ordenes de producción")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"secondary","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Nueva")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Nueva orden de producción")])]),_c('v-card-text',[_c('RedaForm',{attrs:{"options":_vm.form},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)],1)]},proxy:true},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commercialCode)+": "+_vm._s(item.product.description)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push('/productionOrder/' + item.uid)}}},[_vm._v(" mdi-information ")]),(false)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }