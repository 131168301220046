/* eslint new-cap: ["error", { "newIsCap": false }] */
import axios from 'axios'
import jsPDF from 'jspdf'
import moment from 'moment'
import 'jspdf-autotable'
import XLSX from 'xlsx'
export default {
  toCamelCase (str) {
    return str
      .toLowerCase() // Convertir todo a minúsculas
      .split(' ') // Separar palabras por espacios
      .map((word, index) => {
        // Si no es la primera palabra, capitalizar la primera letra
        return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join('') // Unir las palabras
  },
  capitalizeWords (str) {
    return str.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }).join(' ')
  },
  handleAxiosError (error) {
    if (error.response) {
      // El servidor ha respondido con un código de estado fuera del rango 2xx
      console.error('Error de respuesta del servidor:', error.response.data)
      console.error('Código de estado:', error.response.status)
      console.error('Encabezados de respuesta:', error.response.headers)
    } else if (error.request) {
      // La solicitud fue realizada, pero no se recibió ninguna respuesta
      console.error('No se recibió respuesta del servidor:', error.request)
    } else if (error.config) {
      // Ocurrió un error al configurar la solicitud
      console.error('Error al configurar la solicitud:', error.config)
    } else {
      console.error('Error al enviar la solicitud:', error.message)
    }
  },
  findByCodeOrId (array, value, key = 'id', children = 'children') {
    for (const item of array) {
      if (item[key] === value) {
        return item
      }
      if (item[children] && item[children].length > 0) {
        const found = this.findByCodeOrId(item[children], value, key)
        if (found) {
          return found
        }
      }
    }
    return null
  },
  getChildren (account, accounts, nameChildren, idName = 'id') {
    const children = []
    account[nameChildren].forEach((item) => {
      const accountTmpFilter = accounts.find((acc) => acc[idName] === item)
      const accountTmp = { ...accountTmpFilter }
      accountTmp[nameChildren] = this.getChildren(accountTmpFilter, accounts, nameChildren, idName)
      children.push(accountTmp)
    })
    return children
  },
  getSortedObject (object) {
    // New object which will be returned with sorted keys
    const sortedObject = {}

    // Get array of keys from the old/current object
    const keys = Object.keys(object)
    // Sort keys (in place)
    keys.sort()

    // Use sorted keys to copy values from old object to the new one
    for (let i = 0, size = keys.length; i < size; i++) {
      const key = keys[i]
      const value = object[key]
      sortedObject[key] = value
    }

    // Return the new object
    return sortedObject
  },
  table2excel (data, headers, nameFile = 'excel') {
    const excel = []
    data.forEach((item) => {
      const row = {}
      headers.forEach((header) => {
        let value = Object.assign({}, item)
        const key = header.value.split('.')
        key.forEach((i) => {
          if (value) { value = value[i] }
        })
        if (header.type === 'date') {
          value = moment(value).format()
        }
        if (header.type === 'number') {
          value = Number(value)
        }
        if (header.enum) {
          value = header.enum[value]
        }
        row[header.text] = value || ''
      })
      excel.push(row)
    })
    /* Create a worksheet */
    const ws = XLSX.utils.json_to_sheet(excel)

    /* Create a new empty workbook, then add the worksheet */
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, nameFile)

    /* Generate xlsx files */
    XLSX.writeFile(wb, nameFile + '.xlsx')
  },
  round10 (value, exp) {
    const type = 'round'
    // Si el exp no está definido o es cero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value)
    }
    if (exp === -5) {
      exp = -2
    }
    value = +value
    exp = +exp
    // Si el valor no es un número o el exp no es un entero...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split('e')
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
    // Shift back
    value = value.toString().split('e')
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
  },
  zfill (number, width) {
    var numberOutput = Math.abs(number) /* Valor absoluto del número */
    var length = number.toString().length /* Largo del número */
    var zero = '0' /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return ('-' + numberOutput.toString())
      } else {
        return numberOutput.toString()
      }
    } else {
      if (number < 0) {
        return ('-' + (zero.repeat(width - length)) + numberOutput.toString())
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString())
      }
    }
  },
  parseVouchers (xml2js) {
    let doc
    let documentType
    if (xml2js.FacturaElectronica) {
      doc = xml2js.FacturaElectronica
      documentType = '01'
    }
    if (xml2js.NotaDebitoElectronica) {
      doc = xml2js.NotaDebitoElectronica
      documentType = '02'
    }
    if (xml2js.NotaCreditoElectronica) {
      doc = xml2js.NotaCreditoElectronica
      documentType = '03'
    }
    if (xml2js.TiqueteElectronico) {
      doc = xml2js.TiqueteElectronico
      documentType = '04'
    }
    if (xml2js.FacturaElectronicaCompra) {
      doc = xml2js.FacturaElectronicaCompra
      documentType = '08'
    }
    if (xml2js.TiqueteRegimenSimplificado) {
      doc = xml2js.TiqueteRegimenSimplificado
      documentType = '10'
    }
    const issuer = {
      type: doc.Emisor[0].Identificacion[0].Tipo[0],
      identification: doc.Emisor[0].Identificacion[0].Numero[0],
      name: doc.Emisor[0].Nombre[0],
      tradename: doc.Emisor[0].NombreComercial ? doc.Emisor[0].NombreComercial[0] : null,
      email: doc.Emisor[0].CorreoElectronico[0],
      phone: doc.Emisor[0].Telefono ? doc.Emisor[0].Telefono[0].NumTelefono[0] : 0,
      location: {
        province: doc.Emisor[0].Ubicacion[0].Provincia[0],
        county: doc.Emisor[0].Ubicacion[0].Canton[0],
        district: doc.Emisor[0].Ubicacion[0].Distrito[0],
        others: doc.Emisor[0].Ubicacion[0].OtrasSenas[0]
      }
    }
    const items = []
    doc.DetalleServicio[0].LineaDetalle.forEach(item => {
      const tmpItem = {
        line: item.NumeroLinea[0],
        code: item.Codigo ? item.Codigo[0] : null,
        commercialCode: item.CodigoComercial ? item.CodigoComercial[0].Codigo[0] : null,
        quantity: item.Cantidad[0],
        unit: item.UnidadMedida[0],
        description: item.Detalle[0],
        unit_price: item.PrecioUnitario[0],
        total: item.MontoTotal[0],
        subtotal: item.SubTotal[0],
        taxable_base: item.BaseImponible ? item.BaseImponible[0] : item.SubTotal[0],
        tax_net: item.ImpuestoNeto ? item.ImpuestoNeto[0] : 0,
        net_total: item.MontoTotalLinea[0]
      }
      if (item.Impuesto) {
        const taxesItem = item.Impuesto
        const taxes = []
        taxesItem.forEach(tax => {
          taxes.push({
            code: tax.Codigo[0],
            rateCode: tax.CodigoTarifa ? tax.CodigoTarifa[0] : null,
            rate: tax.Tarifa ? tax.Tarifa[0] : 0,
            total: tax.Monto ? tax.Monto[0] : 0
          })
        })
        tmpItem.taxes = taxes
      }
      items.push(tmpItem)
    })
    const summary = {
      currency: doc.ResumenFactura[0].CodigoTipoMoneda ? doc.ResumenFactura[0].CodigoTipoMoneda[0].CodigoMoneda[0] : 'CRC',
      exchangeRate: doc.ResumenFactura[0].CodigoTipoMoneda ? doc.ResumenFactura[0].CodigoTipoMoneda[0].TipoCambio[0] : 1,
      servicesTaxableTotal: doc.ResumenFactura[0].TotalServGravados ? doc.ResumenFactura[0].TotalServGravados[0] : 0,
      servicesExentTotal: doc.ResumenFactura[0].TotalServExentos ? doc.ResumenFactura[0].TotalServExentos[0] : 0,
      servicesExoneTotal: doc.ResumenFactura[0].TotalServExonerado ? doc.ResumenFactura[0].TotalServExonerado[0] : 0,
      goods_taxable_total: doc.ResumenFactura[0].TotalMercanciasGravadas ? doc.ResumenFactura[0].TotalMercanciasGravadas[0] : 0,
      goodsExentTotal: doc.ResumenFactura[0].TotalMercanciasExentas ? doc.ResumenFactura[0].TotalMercanciasExentas[0] : 0,
      goodsExoneTotal: doc.ResumenFactura[0].TotalMercExonerada ? doc.ResumenFactura[0].TotalMercExonerada[0] : 0,
      taxableTotal: doc.ResumenFactura[0].TotalGravado ? doc.ResumenFactura[0].TotalGravado[0] : 0,
      exentTotal: doc.ResumenFactura[0].TotalExento ? doc.ResumenFactura[0].TotalExento[0] : 0,
      exoneTotal: doc.ResumenFactura[0].TotalExonerado ? doc.ResumenFactura[0].TotalExonerado[0] : 0,
      subtotal: doc.ResumenFactura[0].TotalVenta[0],
      discountTotal: doc.ResumenFactura[0].TotalDescuentos ? doc.ResumenFactura[0].TotalDescuentos[0] : 0,
      grossTotal: doc.ResumenFactura[0].TotalVentaNeta[0],
      taxTotal: doc.ResumenFactura[0].TotalImpuesto[0],
      vatReturned: doc.ResumenFactura[0].TotalIVADevuelto ? doc.ResumenFactura[0].TotalIVADevuelto[0] : 0,
      otherChargesTotal: doc.ResumenFactura[0].TotalOtrosCargos ? doc.ResumenFactura[0].TotalOtrosCargos[0] : 0,
      netTotal: doc.ResumenFactura[0].TotalComprobante[0]
    }
    const voucher = {
      documentType: documentType,
      key: doc.Clave[0],
      activityCode: doc.CodigoActividad[0],
      sequence: doc.NumeroConsecutivo[0],
      date: doc.FechaEmision[0],
      issuer: issuer,
      condition: doc.CondicionVenta[0],
      paymentType: doc.MedioPago[0],
      items: items,
      summary: summary
      // regulation: regulation
    }
    if (doc.PlazoCredito) {
      voucher.creditTerm = doc.PlazoCredito[0]
    }
    if (doc.Receptor) {
      const receiver = {
        type: doc.Receptor[0].Identificacion[0].Tipo[0],
        identification: doc.Receptor[0].Identificacion[0].Numero[0],
        name: doc.Receptor[0].Nombre[0],
        tradename: doc.Receptor[0].NombreComercial ? doc.Receptor[0].NombreComercial[0] : null,
        email: doc.Receptor[0].CorreoElectronico ? doc.Receptor[0].CorreoElectronico[0] : null,
        phone: doc.Receptor[0].Telefono ? doc.Receptor[0].Telefono[0].NumTelefono[0] : null
      }
      if (doc.Receptor[0].Ubicacion) {
        receiver.location = {
          province: doc.Receptor[0].Ubicacion[0].Provincia[0],
          county: doc.Receptor[0].Ubicacion[0].Canton[0],
          district: doc.Receptor[0].Ubicacion[0].Distrito[0],
          others: doc.Receptor[0].Ubicacion[0].OtrasSenas[0]
        }
      }
      voucher.receiver = receiver
    }
    if (doc.InformacionReferencia) {
      const reference = {
        documentType: doc.InformacionReferencia[0].TipoDoc[0],
        number: doc.InformacionReferencia[0].Numero[0],
        date: doc.InformacionReferencia[0].FechaEmision[0],
        code: doc.InformacionReferencia[0].Codigo[0],
        reason: doc.InformacionReferencia[0].Razon[0]
      }
      voucher.reference = reference
    }
    return voucher
  },
  getLocation (province, county, district) {
    return new Promise((resolve, reject) => {
      const location = {}
      axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
        .then(response => {
          const provinces = response.data
          location.province = provinces[Number(province)]
          return axios.get('https://ubicaciones.paginasweb.cr/provincia/' + Number(province) + '/cantones.json')
        })
        .then(response => {
          const counties = response.data
          location.county = counties[Number(county)]
          return axios.get('https://ubicaciones.paginasweb.cr/provincia/' + Number(province) + '/canton/' + Number(county) + '/distritos.json')
        })
        .then(response => {
          const districts = response.data
          location.district = districts[Number(district)]
          resolve(location)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  genPDF (voucher, company = { branding: { primary: '#4C79AF' } }) {
    return new Promise((resolve, reject) => {
      this.getLocation(voucher.issuer.location.province, voucher.issuer.location.county, voucher.issuer.location.district)
        .then(location => {
          const doc = new jsPDF('p', 'mm', 'a4')
          var centeredText = function (text, y, options) {
            var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor
            var textOffset = (doc.internal.pageSize.width - textWidth) / 2
            doc.text(textOffset, y, text, options)
          }
          if (voucher.issuer.tradename) {
            doc.setFont('Helvetica', 'bold')
            doc.setFontSize(14)
            centeredText(voucher.issuer.tradename, 20)
            doc.setFontSize(10)
            doc.setFont('helvetica', 'normal')
            // centeredText(voucher.issuer.name,30);
            // centeredText(voucher.issuer.identification,35);
            centeredText(location.district + ' de ' + location.county + ', ' + location.province, 30)
            centeredText(voucher.issuer.location.others, 35)
            centeredText('Tel: ' + voucher.issuer.phone, 40)
          } else {
            doc.setFont('Helvetica', 'bold')
            doc.setFontSize(14)
            centeredText(voucher.issuer.name, 20)
            doc.setFontSize(10)
            doc.setFont('helvetica', 'normal')
            centeredText(voucher.issuer.identification, 30)
            centeredText(location.district + ' de ' + location.county + ', ' + location.province, 35)
            centeredText(voucher.issuer.location.others, 40)
            centeredText('Tel: ' + voucher.issuer.phone, 45)
          }
          doc.line(15, 55, doc.internal.pageSize.width - 15, 55)

          doc.text('Clave: ' + voucher.key, 15, 60)
          doc.text('Consecutivo: ' + voucher.sequence, 15, 65)
          doc.text('Fecha: ' + voucher.date, 15, 70)
          doc.text('Nombre del cliente: ' + (voucher.receiver ? voucher.receiver.name : ''), 15, 75)
          doc.text('Cedula del cliente: ' + (voucher.receiver ? voucher.receiver.identification : ''), 15, 80)
          doc.text('Condición de Venta: ' + voucher.condition, 15, 85)
          doc.text('Medio de pago: ' + voucher.paymentType, 15, 90)

          const columns = [[
            { content: 'Ct.', styles: { fillColor: company.branding.primary } },
            { content: 'Descripción', styles: { fillColor: company.branding.primary } },
            { content: 'Precio Unitario', styles: { fillColor: company.branding.primary } },
            { content: 'IVA', styles: { fillColor: company.branding.primary } },
            { content: 'Total', styles: { fillColor: company.branding.primary } }
          ]]
          const data = []
          let x = 110

          voucher.items.forEach(item => {
            const row = [
              item.quantity,
              item.description,
              item.unit_price,
              item.tax_net,
              item.net_total
            ]
            data.push(row)
            x = x + 7
          })

          // doc.autoTable(columns,data,{ margin:{ top: 95 },tableWidth:doc.internal.pageSize.width - 15});
          doc.autoTable({ head: columns, body: data, margin: { top: 95 }, tableWidth: doc.internal.pageSize.width - 15 })

          doc.text('Moneda: ' + voucher.summary.currency, 15, x + 5)
          doc.text('Tipo Cambio: ' + voucher.summary.exchangeRate, 15, x + 10)
          doc.text('SubTotal: ' + voucher.summary.subtotal, 15, x + 15)
          doc.text('Descuento: ' + voucher.summary.discountTotal, 15, x + 20)
          doc.text('IVA: ' + voucher.summary.taxTotal, 15, x + 25)
          doc.setFont('helvetica', 'bold')
          doc.setFontSize(14)
          doc.text('TOTAL: ' + voucher.summary.netTotal, 15, x + 30)
          doc.setFont('helvetica', 'normal')
          doc.setFontSize(10)

          doc.line(15, x + 35, doc.internal.pageSize.width - 15, x + 35)

          centeredText('Autorizado mediante el Oficio Número DGT-R-033-2019 del 20/06/2019 de la DGTD', x + 40)
          centeredText('Régimen de Tributación Simplificada', x + 45)
          centeredText('Gracias por su preferencia', x + 50)

          doc.setProperties({
            title: 'PDF-' + voucher.key + '.pdf'
          })

          resolve(doc.output('dataurlstring'))
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  b64DecodeUnicode (str) {
    /* console.log(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')) */
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  },
  dataURItoBlob (dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length)

    // create a view into the buffer
    var ia = new Uint8Array(ab)

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString })
    return blob
  },
  pem2js (pem) {
    const key = pem.match(/-----BEGIN PRIVATE KEY-----[\S\s]*?-----END PRIVATE KEY-----/g)[0]
    const chain = pem.match(/-----BEGIN CERTIFICATE-----[\S\s]*?-----END CERTIFICATE-----/g)
    return { key, chain }
  },
  preparePem (pem) {
    return pem
      // remove BEGIN/END
      .replace(/-----(BEGIN|END)[\w\d\s]+-----/g, '')
      // remove \r, \n
      .replace(/[\r\n]/g, '')
  },
  codeToTextValue (obj) {
    const textValue = {}
    Object.entries(obj).forEach(entry => {
      const [key, value] = entry
      textValue.code = key
      textValue.text = value
      textValue.key = key
      textValue.value = key
    })
    return textValue
  },
  getProvince () {
    return new Promise((resolve, reject) => {
      axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
        .then((response) => {
          const provinces = []
          const entries = Object.entries(response.data)
          for (const [key, value] of entries) {
            provinces.push({
              text: value,
              value: String(key)
            })
          }
          resolve(provinces)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCounty (province) {
    return new Promise((resolve, reject) => {
      axios.get(`https://ubicaciones.paginasweb.cr/provincia/${province}/cantones.json`)
        .then((response) => {
          const counties = []
          const entries = Object.entries(response.data)
          for (const [key, value] of entries) {
            counties.push({
              text: value,
              value: String(key)
            })
          }
          resolve(counties)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDistrict (province, county) {
    return new Promise((resolve, reject) => {
      axios.get(`https://ubicaciones.paginasweb.cr/provincia/${province}/canton/${county}/distritos.json`)
        .then((response) => {
          const districts = []
          const entries = Object.entries(response.data)
          for (const [key, value] of entries) {
            districts.push({
              text: value,
              value: String(key)
            })
          }
          resolve(districts)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sortItems (items, keyName) {
    return [...items].sort((a, b) => {
      if (a[keyName] > b[keyName]) {
        return 1
      }
      if (a[keyName] < b[keyName]) {
        return -1
      }
      // a must be equal to b
      return 0
    })
  }
}
