var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('Period'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.voucherItems,"sort-by":"date","search":_vm.search,"sort-desc":"","loading":_vm.loadingVouchers || _vm.loading},on:{"click:row":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.items[0].callback.apply(null, arguments)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Comprobantes Electrónicos")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-3 py-2 mr-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('NewReceipt',{attrs:{"voucher":_vm.tmp.voucher,"amountOutstanding":_vm.tmp.amountOutstanding,"currency":_vm.tmp.currency},on:{"cancel":function($event){_vm.dialog = false}}})],1),_c('ImportData',{attrs:{"name":"vouchers","humanName":"Facturas proforma"}}),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"secondary","loading":_vm.uploadingVoucher},on:{"click":_vm.clickUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v("Subir")],1),_c('input',{ref:"xml",attrs:{"type":"file","accept":"application/xml","hidden":""},on:{"change":_vm.handleFileUpload}}),_c('v-btn',{on:{"click":_vm.downloadExcel}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1)]},proxy:true},{key:"item.voucherTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.data.summary.netTotal,item.data.summary.currency))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format('l'))+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.condition === '02' ? _vm.moment(item.data.date).add(Number(item.data.creditTerm), 'days').format('l') : _vm.moment(item.datetime).format('l'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.loading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_c('div',{staticClass:"d-flex flex-row"},_vm._l((_vm.items),function(option,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([(!(option.text === 'Descargar Respuesta Hacienda' && item.data.documentType === '10'))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","color":option.color || 'info',"disabled":(option.text === 'Realizar pago' && (Number(item.amountOutstanding) <= 0 || item.deleted || !item.isVoucherSale)) || (option.text === 'Descargar Respuesta Hacienda' && (item.data.documentType === '11' || item.data.documentType === '10'))},on:{"click":function($event){$event.stopPropagation();option.text === 'Realizar pago' ? _vm.newPayVoucher(item) : option.callback(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(option.icon)+" ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(option.text))])])}),1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vouchersType[item.data.documentType])+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.deleted)?_c('span',{class:['error--text']},[_vm._v("Anulada")]):(['02', '03', '11'].includes(item.data.documentType))?_c('span',[_vm._v("N/A")]):_c('span',{class:[item.collected ? 'success--text' : 'error--text']},[_vm._v(_vm._s(item.collected ? 'Cobrada' : 'Por Cobrar'))])]}},{key:"item.treasuryMessage",fn:function(ref){
var item = ref.item;
return [(item.data.documentType === '11' || item.data.documentType === '10')?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(item.treasuryMessage))])]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateString(item.date))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }