<template>
  <v-data-table
    dense
    :headers="headers"
    :items="getOrders"
    item-key="uid"
    :search="search"
    class="elevation-1"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Pedidos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <v-btn @click="downloadExcel" v-if="false"><v-icon>mdi-file-excel</v-icon></v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.products="{ item }">
      <div v-for="(product, i) in item.items" :key="i">{{product.product.description}}: {{product.quantity}} {{product.product.unit}}</div>
    </template>
    <template v-slot:item.created="{ item }">
      {{ item.created | dateFormat }}
    </template>
    <template v-slot:item.deadline="{ item }">
      {{ item.deadline | dateFormat }}
    </template>
    <template v-slot:item.customer="{ item }">
      {{ item.customer.name | capitalizeWords }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        class="ma-1"
        v-if="item.status"
        :color="statusObj[item.status].color"
      >
      {{ statusObj[item.status].text }}
      </v-chip>
    </template>
    <template v-slot:item.priority="{ item }">
      <v-chip
        class="ma-1"
        v-if="item.priority"
        :color="priorityObj[item.priority].color"
      >
        {{ priorityObj[item.priority].text }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <OrderDialog :item="item"></OrderDialog>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import tools from '@/api/tools'
import OrderDialog from '@/components/OrderDialog.vue'

export default {
  name: 'OrdersTable',
  props: ['orders', 'loading'],
  components: {
    OrderDialog
  },
  data: () => ({
    search: null,
    headersExcel: [
      { text: 'Nombre', value: 'client.name' },
      { text: 'Contacto', value: 'client.phone' },
      { text: 'Cantón', value: 'address.conty.text' },
      { text: 'Distrito', value: 'address.district.text' },
      { text: 'Dirección', value: 'address.others' },
      { text: 'Masas', value: 'mmn01.quantity' },
      { text: 'Chorreadas', value: 'cpn01.quantity' },
      { text: 'Observaciones', value: 'observations' }
    ],
    priorityObj: {
      1: { text: 'Alta', color: 'error' },
      2: { text: 'Media', color: 'warning' },
      3: { text: 'Baja', color: 'success' }
    },
    statusObj: {
      '01': { text: 'Atrasada', color: 'error' },
      '02': { text: 'En peligro', color: 'warning' },
      '03': { text: 'Pendiente', color: 'grey' },
      '04': { text: 'Iniciada', color: 'info' },
      '05': { text: 'Lista', color: 'primary' },
      '06': { text: 'Entregada', color: 'success' }
    }
  }),
  methods: {
    downloadExcel () {
      tools.table2excel(this.getOrdersExcel, this.headersExcel, 'Pedidos')
    }
  },
  computed: {
    getOrders () {
      return this.orders
    },
    headers () {
      return [
        { text: 'ID', value: 'num' },
        { text: 'Fecha de creación', value: 'created' },
        { text: 'Fecha de entrega', value: 'deadline' },
        { text: 'Cliente', value: 'customer' },
        // { text: 'Artículos', value: 'products', sortable: false },
        { text: 'Estado', value: 'status' },
        { text: 'Prioridad', value: 'priority' },
        { text: 'Observaciones', value: 'others' },
        { text: '', value: 'actions', sortable: false }
      ]
    },
    ...mapGetters('user', []),
    getOrdersExcel () {
      const items = []
      this.getOrders.forEach((item) => {
        items.push({
          ...item,
          mmn01: item.items.find((line) => (line.commercialCode === 'mmn01')),
          cpn01: item.items.find((line) => (line.commercialCode === 'cpn01'))
        })
      })
      return items
    }
  }
}
</script>
