<template>
  <v-card>
    <v-card-title>
    </v-card-title>

    <v-tabs
      v-model="tab"
    >
      <v-tab>
        Detalles de catálogo
      </v-tab>
      <v-tab>
        Componentes
      </v-tab>
      <v-tab>
        Personalización
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-title v-if="false">
            Detalles
          </v-card-title>
          <v-card-text>
            <RedaForm
              v-model="item"
              :options="detailsForm"
            ></RedaForm>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-title>
            Componentes del producto
          </v-card-title>
          <v-card-text>
            <DataTableCRUD
              :options="{
                headers,
                newItemText: 'Agregar producto',
                editItemText: 'Actualizar producto',
                delete: true,
                defaultItem: {
                  products: [],
                  quantity: 1,
                },
                insert: {
                  itemValue: 'commercialCode',
                  itemText: 'description',
                  items: products,
                  inserts: [
                    { inputKey: 'products', objKey: 'commercialCode' },
                  ],
                  filter: (item, queryText) => {
                    const textOne = item.description.toLowerCase()
                    const textTwo = item.commercialCode.toLowerCase()
                    const textThree = String(item.commercialCode).toLowerCase()
                    const searchText = queryText.toLowerCase()

                    return textOne.indexOf(searchText) > -1 ||
                      textTwo.indexOf(searchText) > -1 ||
                      textThree.indexOf(searchText) > -1
                  }
                },
                form: {
                  submitText: 'Guardar',
                  showSubmit: true,
                  inputs: [
                    {
                      id: 'de01',
                      label: 'Productos',
                      items: products,
                      name: 'products',
                      value: 'products',
                      type: 'autocomplete',
                      cols: 8,
                      itemText: 'description',
                      itemValue: 'commercialCode',
                      multiple: true,
                      chips: true,
                      deletableChips: true,
                      hideSelected: true
                    },
                    {
                      id: 'de02',
                      label: 'Cantidad',
                      name: 'quantity',
                      value: 'quantity',
                      type: 'number',
                      cols: 4
                    },
                  ]
                }
              }"
              :table="{
                dense: true,
                hideDefaultFooter: true,
              }"
              v-model="item.itemsOptions"
            ></DataTableCRUD>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <customization-product :product="product" @updated="$emit('update')"></customization-product>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import firebaseApi from '../api/firebaseApi'
import CustomizationProduct from './CustomizationProduct'
moment.locale('es')
export default {
  name: 'AdvancedProductInfo',
  components: { CustomizationProduct },
  props: {
    product: Object
  },
  data: () => ({
    tab: 0,
    products: [],
    isLoading: false,
    item: {},
    defaultItem: {
      itemsOptions: [],
      categories: [],
      keywords: [],
      longDescription: null,
      name: null,
      // stock: Joi.boolean(),
      shops: [],
      photoURL: [],
      details: [],
      packaging: {
        singleGrossWeight: null
      },
      personalization: null
    },
    tmp: {
      searchCode: null
    }
  }),
  mounted () {
    this.isLoading = true
    this.item = { ...this.defaultItem, ...this.product }
    firebaseApi.db.collection('products')
      .where('company', '==', this.getCompany.identification)
      .get()
      .then(snapshot => {
        const products = snapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
        this.products = products
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.isLoading = false))
  },
  computed: {
    headers () {
      return [
        {
          text: 'Productos',
          value: 'products',
          editable: {
            type: 'autocomplete',
            title: 'Actualizar Productos',
            items: this.products,
            itemText: 'description',
            itemValue: 'commercialCode',
            multiple: true,
            chips: true
          }
        },
        { text: 'Cantidad', value: 'quantity', editable: { type: 'number', title: 'Actualizar Cantidad' } },
        { text: '', value: 'actions' }
      ]
    },
    detailsForm () {
      return {
        inputs: [
          {
            id: '001',
            label: 'Nombre',
            name: 'name',
            value: 'name',
            type: 'text',
            cols: 12
          },
          {
            id: '001',
            label: 'Descripción larga',
            name: 'longDescription',
            value: 'longDescription',
            type: 'textarea',
            cols: 12
          },
          {
            id: '001',
            label: 'Catégorias',
            name: 'categories',
            value: 'categories',
            type: 'autocomplete',
            items: [],
            cols: 6
          },
          {
            id: '001',
            label: 'Palabras clave',
            name: 'keywords',
            value: 'keywords',
            type: 'text',
            cols: 6
          }
        ]
      }
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    ...mapGetters('products', [
      'getProducts'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  watch: {
  },
  methods: {
    ...mapActions('products', ['insertProduct'])
  }
}
</script>
