<template>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="code"
      sort-desc
      class="elevation-1"
      dense
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Saldos</v-toolbar-title>
          <div class="flex-grow-1"></div>
        </v-toolbar>
      </template>
      <template v-slot:item.previousBalance="{ item }">
        {{ item.previousBalance | currencyFormat }}
      </template>
      <template v-slot:item.debit="{ item }">
        {{ item.debit | currencyFormat }}
      </template>
      <template v-slot:item.credit="{ item }">
        {{ item.credit | currencyFormat }}
      </template>
      <template v-slot:item.balance="{ item }">
        {{ item.balance | currencyFormat }}
      </template>
      <template v-slot:body.append>
        <tr>
          <th>Total</th>
          <th class="text-right title">
            {{ total.previousBalance | currencyFormat }}
          </th>
          <th class="text-right title">
            {{ total.debit | currencyFormat }}
          </th>
          <th class="text-right title">
            {{ total.credit | currencyFormat }}
          </th>
          <th class="text-right title" :class="total.balance > 0 ? 'success--text' : 'error--text'">
            {{ total.balance | currencyFormat }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import axios from 'axios'

export default {
  name: 'BanksAndCashBalances',
  components: {},
  props: [
    'voucher'
  ],
  data: () => ({
    loading: false,
    headers: [
      { text: 'Cuenta', value: 'name' },
      { text: 'Saldo anterior', value: 'previousBalance', align: 'end' },
      { text: 'Débitos', value: 'debit', align: 'end' },
      { text: 'Créditos', value: 'credit', align: 'end' },
      { text: 'Saldo actual', value: 'balance', align: 'end' }
    ]
  }),

  computed: {
    items () {
      // const items = []
      const { cashAndCashEquivalents } = this.getAccountingConfig
      const accountsDetails = this.getAccountsDetails
        .filter(({ code }) => code.toString().startsWith(cashAndCashEquivalents))
        .map(({ code }) => code)
      const ledger = this.getLedger.filter(({ code }) => accountsDetails.includes(code))
      return ledger
    },
    total () {
      let debit = 0
      let credit = 0
      let previousBalance = 0
      let balance = 0
      this.items.forEach((acc) => {
        previousBalance += Number(acc.previousBalance)
        debit += Number(acc.debit)
        credit += Number(acc.credit)
        balance += Number(acc.balance)
      })
      return { debit, credit, previousBalance, balance }
    },
    ...mapGetters('accounting', [
      'getAccountsDetails', 'getLedger'
    ]),
    ...mapGetters('company', ['getAccountingConfig'])
  },

  watch: {
  },

  /* created () {
        this.initialize()
      }, */

  methods: {
    ...mapActions('accountReceivable', ['cancelReceipt']),
    ...mapActions('message', ['showSuccess', 'showError'])
  }
}
</script>
  <style type="text/css">

  </style>
