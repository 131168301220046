<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Cuenta {{ id }}: {{ account.name }}
    </div>
    <Period></Period>
    <v-card
      elevation="2"
      class="d-flex flex-row justify-space-around my-10"
    >
      <div class="ma-5 pl-11">
        <div class="text-center caption">
          Saldo inical
        </div>
        <div class="title text-center">
          {{ balances.prevBalance | currencyFormat(currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Débitos
        </div>
        <div class="title text-center">
          {{ balances.debit | currencyFormat(currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Créditos
        </div>
        <div class="title text-center">
          {{ balances.credit | currencyFormat(currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Saldo final
        </div>
        <div class="title text-center">
          {{ balances.balance | currencyFormat(currency) }}
        </div>
      </div>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
      <v-tabs v-model="tab">
        <v-tab>
          Información generales
        </v-tab>
        <v-tab>
          Transacciones
        </v-tab>
        <v-tab>
          Asientos
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <RedaForm
            :options="form"
            v-model="tmp.account"
            @submitForm="submitAccount"
            ></RedaForm>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text v-if="isDollar">
            <p>Saldos en:</p>
          <v-btn-toggle
            v-model="currencyBtn"
            mandatory
          >
            <v-btn>
              <v-icon>₡</v-icon>
            </v-btn>
            <v-btn>
              <v-icon>mdi-currency-usd</v-icon>
            </v-btn>
          </v-btn-toggle></v-card-text>
          <v-data-table
            :headers="headers"
            :items="tAccount"
            dense
            sort-by="num"
            sort-desc
          >
            <template v-slot:item.initialBalance="{ item }">
              {{ item.initialBalance | currencyFormat(currency) }}
            </template>
            <template v-slot:item.debit="{ item }">
              {{ item.debit | currencyFormat(currency) }}
            </template>
            <template v-slot:item.credit="{ item }">
              {{ item.credit | currencyFormat(currency) }}
            </template>
            <template v-slot:item.balance="{ item }">
              {{ item.balance | currencyFormat(currency) }}
            </template>
            <template v-slot:item.exchangeRate="{ item }">
              {{ item.exchangeRate | numberFormat }}
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>{{ account.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="title">Saldo final: {{ balances.balance | currencyFormat(currency) }}</div>
                <v-btn @click="downloadExcel"><v-icon>mdi-file-excel</v-icon></v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.account="{ item }">
              {{ item.code }}: {{ item.name }}
            </template>
            <template v-slot:foot>
              <tfoot>
                <tr>
                  <th class="title" :colspan="isCash ? 6 : 3">
                    Total
                  </th>
                  <th class="title text-right">
                    {{ balances.prevBalance | currencyFormat(currency) }}
                  </th>
                  <th class="title text-right">
                    {{ balances.debit | currencyFormat(currency) }}
                  </th>
                  <th class="title text-right">
                    {{ balances.credit | currencyFormat(currency) }}
                  </th>
                  <th class="title text-right">
                    {{ balances.balance | currencyFormat(currency) }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <Entries :entries="entries"></Entries>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Period from '@/components/Period.vue'
import Entries from '@/components/Entries.vue'
import accounting from '../api/accounting'
import tools from '../api/tools'
import moment from 'moment'

export default {
  name: 'Account',
  props: ['id'],
  components: { Period, Entries },
  data: () => ({
    tab: 0,
    currencyBtn: 0,
    tmp: {
      account: {}
    }
  }),
  methods: {
    getExchangeRate (date) {
      const findDate = Number(moment(date, 'x').hour(0).minute(0).second(0).millisecond(0).format('x'))
      try {
        const item = this.USDHistory.find(({ fecha }) => fecha === findDate)
        return item.venta
      } catch (error) {
        console.log('findDate', findDate)
        throw error
      }
    },
    submitAccount () {
      this.insertAccount({ ...this.tmp.account })
    },
    downloadExcel () {
      tools.table2excel(this.tAccount, this.headersExcel, 'Comprobantes')
    },
    ...mapActions('accounts', ['insertAccount'])
  },
  computed: {
    currency () {
      return this.currencyBtn ? 'USD' : 'CRC'
    },
    isDollar () {
      return Object.entries(this.getAccountingConfig.paymentType.USD).map((entry) => entry[1]).includes(Number(this.id))
    },
    isCash () {
      return this.account.code.startsWith(this.getAccountingConfig.cashAndCashEquivalents)
    },
    headersExcel () {
      const headersExcel = [
        { text: 'Fecha', value: 'date', type: 'date' },
        { text: 'Asiento', value: 'num' },
        { text: 'Código de cuenta', value: 'code' },
        { text: 'Cuenta', value: 'name' },
        { text: 'Saldo inicial', value: 'initialBalance', type: 'number' },
        { text: 'Debitos', value: 'debit', type: 'number' },
        { text: 'Créditos', value: 'credit', type: 'number' },
        { text: 'Saldo final', value: 'balance', type: 'number' }
      ]
      if (this.isCash) {
        headersExcel.splice(3, 0, { text: 'Transacción', value: 'transaction' })
        headersExcel.splice(4, 0, { text: 'Moneda', value: 'currency' })
        headersExcel.splice(5, 0, { text: 'Tipo de cambio', value: 'exchangeRate', type: 'number' })
      }
      return headersExcel
    },
    headers () {
      const headers = [
        { text: 'Fecha', value: 'dateString' },
        { text: 'Asiento', value: 'num' },
        { text: 'Cuenta', value: 'account' },
        { text: 'Saldo inicial', value: 'initialBalance', align: 'end' },
        { text: 'Debitos', value: 'debit', align: 'end' },
        { text: 'Créditos', value: 'credit', align: 'end' },
        { text: 'Saldo final', value: 'balance', align: 'end' }
      ]
      if (this.isCash) {
        headers.splice(3, 0, { text: 'Transacción', value: 'transaction' })
        headers.splice(4, 0, { text: 'Moneda', value: 'currency' })
        headers.splice(5, 0, { text: 'Tipo de cambio', value: 'exchangeRate', align: 'end' })
      }
      return headers
    },
    form () {
      return {
        submitText: 'Actualizar cuenta',
        showSubmit: true,
        inputs: [
          {
            id: 'de01',
            text: 'Código',
            label: 'Código',
            name: 'code',
            value: 'code',
            type: 'display',
            cols: 4
          },
          {
            id: 'de02',
            label: 'Nombre',
            name: 'name',
            value: 'name',
            type: 'text',
            cols: 8
          }
        ]
      }
    },
    account () {
      const account = this.accounts.find((acc) => acc.id === Number(this.id))
      return account
    },
    journal () {
      return this.getJournal.filter(({ date }) => date < this.to)
    },
    entries () {
      return this.accountBalance.entries.filter(({ date }) => this.from < date && date < this.to)
    },
    isDebitBalance () {
      return this.account.code.startsWith('1') || this.account.code.startsWith('5')
    },
    tAccount () {
      const trans = this.entries.reduce((acc, { accounts, num, date, docs }) => {
        for (let index = 0; index < accounts.length; index++) {
          const account = accounts[index]
          if (account.code.startsWith(this.account.code)) {
            const uidReceipt = (docs?.find(({ type }) => type === 'receipt'))?.ref
            const uidPayment = (docs?.find(({ type }) => type === 'payment'))?.ref
            const receipt = uidReceipt ? this.getReceipts.find(({ uid }) => uidReceipt === uid) : null
            const payment = uidPayment ? this.getPayments.find(({ uid }) => uidPayment === uid) : null
            const transaction = receipt?.transaction || payment?.transaction || null
            const currency = receipt?.currency || payment?.currency || 'CRC'
            let exchangeRate = receipt?.exchangeRate || payment?.exchangeRate || this.getExchangeRate(date)
            if (exchangeRate === 1) exchangeRate = this.getExchangeRate(date)
            const prevIndex = acc.length - 1
            const debit = this.currency === 'CRC' ? account.debit : account.debit / exchangeRate
            const credit = this.currency === 'CRC' ? account.credit : account.credit / exchangeRate
            const accountPrev = prevIndex === -1 ? { balance: this.accountBalance.balances.prevBalance } : acc[prevIndex]
            const initialBalance = accountPrev.balance
            const balance = this.isDebitBalance
              ? initialBalance + debit - credit
              : initialBalance + credit - debit
            acc.push({ num, ...account, debit, credit, initialBalance, balance, dateString: moment(date).format('L'), transaction, currency, exchangeRate })
          }
        }
        return acc
      }, [])
      return trans
    },
    // getInitialBalance () {
    //   const initialBalance = []
    //   if (this.companyItem.initialBalance) {
    //     for (let i = 0; i < this.companyItem.initialBalance.length; i++) {
    //       const { account, balance } = this.companyItem.initialBalance[i]
    //       const acc = this.state.accounts.find(({ code }) => code === account.toString())
    //       initialBalance.push({ code: acc.code, name: acc.name, balance })
    //     }
    //   }
    //   return initialBalance
    // },
    accountBalance () {
      const accountBalance = accounting.getAccountBalance({
        initialBalance: this.getInitialBalance,
        journal: this.journal,
        account: this.account.code,
        from: this.from,
        to: this.to
      })
      return accountBalance
    },
    balances () {
      const balances = {
        prevBalance: 0,
        debit: 0,
        credit: 0,
        balance: 0
      }
      if (this.tAccount.length > 0) {
        balances.prevBalance = this.tAccount[0].initialBalance
        balances.debit = this.tAccount.reduce((acc, { debit }) => acc + debit, 0)
        balances.credit = this.tAccount.reduce((acc, { credit }) => acc + credit, 0)
        balances.balance = this.tAccount[this.tAccount.length - 1].balance
      }
      return balances
    },
    ...mapState(['from', 'to', 'USDHistory']),
    ...mapState('accounting', ['accounts']),
    ...mapGetters('payments', ['getPayments']),
    ...mapGetters('accountReceivable', ['getReceipts']),
    ...mapGetters('accounting', ['getJournal']),
    ...mapGetters('company', ['getCompany', 'getInitialBalance', 'getAccountingConfig'])
  },
  mounted () {
    this.tmp.account = { ...this.account }
  }
}
</script>
