<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('delete')">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col :cols="12" :sm="6">
          <v-text-field
            label="Nombre del campo"
            v-model="value.label"
            @input="emitInput"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="6">
          <v-select
            :items="itemTypes"
            v-model="value.type"
            @input="emitInput"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-subtitle v-if="itemsRequired.includes(value.type)">
      Opciones
    </v-card-subtitle>
    <v-card-text  v-if="itemsRequired.includes(value.type)">
      <v-col cols="6">
        <v-list dense>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
          >
            <v-text-field
              dense
              :value="item"
              @input="inputItem($event, i)"
            >
              <template v-slot:prepend>
                {{ i + 1 }}.
              </template>
              <template v-slot:append-outer>
                <v-btn @click="deleteItem(i)" icon color="error">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-list-item>
        </v-list>
        <v-btn text color="secondary" small @click="newItem"><v-icon left>mdi-plus</v-icon>Agregar opción</v-btn>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-switch
        v-model="required"
        :label="'Obligatoria'"
      ></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
// import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import tools from '../api/tools'
// moment.locale('es')
export default {
  name: 'CustomizationProductItem',
  components: {},
  props: {
    value: Object
  },
  data: () => ({
    itemsRequired: [
      'select'
    ],
    required: false,
    options: {},
    defaultItem: {
    },
    tmp: {
    },
    itemTypes: [
      { text: 'Texto corto', value: 'text' },
      { text: 'Texto largo', value: 'textarea' },
      { text: 'Casilla de verificación', value: 'checkbox' },
      { text: 'Lista deplegable', value: 'select' },
      { text: 'Número', value: 'number' },
      { text: 'Carga de archivos', value: 'file' }
    ]
  }),
  mounted () {
    this.options = { inputs: [{ ...this.value, readonly: true }] }
  },
  computed: {
    items () {
      return this.value.items || []
    },
    getOptions () {
      return this.options.inputs ? this.options : { inputs: [] }
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    ...mapGetters('products', [
      'getProducts'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  watch: {
    value (val) {
      this.options = { inputs: [{ ...val, readonly: true }] }
      console.log({ ...val })
      this.required = !!val.rules?.includes('required')
    }
  },
  methods: {
    deleteItem (i) {
      this.value.items.splice(i, 1)
      this.emitInput()
    },
    newItem () {
      if (!Array.isArray(this.value.items)) this.value.items = []
      this.value.items.push(`Opción ${this.value.items.length + 1}`)
      this.emitInput()
    },
    inputItem (item, i) {
      this.value.items[i] = item
      this.emitInput()
    },
    emitInput () {
      const input = {
        id: this.value.id,
        label: this.value.label,
        name: this.value.label,
        value: tools.toCamelCase(this.value.label),
        type: this.value.type,
        rules: this.required ? 'required' : null,
        items: this.value.items || []
      }
      this.$emit('input', input)
    },
    ...mapActions('products', ['insertProduct'])
  }
}
</script>
