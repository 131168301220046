<template>
  <v-container>
    <Period></Period>
    <v-data-table
      :headers="headers"
      :items="voucherItems"
      sort-by="date"
      class="elevation-1"
      :search="search"
      sort-desc
      @click:row.self="items[0].callback"
      :loading="loadingVouchers || loading"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Comprobantes Electrónicos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="mdi-magnify"
            class="mt-3 py-2 mr-2"
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="600px">
            <NewReceipt
              :voucher="tmp.voucher"
              :amountOutstanding="tmp.amountOutstanding"
              :currency="tmp.currency"
              @cancel="dialog = false"
            ></NewReceipt>
          </v-dialog>
          <ImportData name="vouchers" humanName="Facturas proforma"></ImportData>
          <v-btn @click="clickUpload" color="secondary" class="mx-2"  :loading="uploadingVoucher"><v-icon left>mdi-upload</v-icon>Subir</v-btn>
          <input type="file" ref="xml" accept="application/xml" @change="handleFileUpload" hidden>
          <v-btn @click="downloadExcel"><v-icon>mdi-file-excel</v-icon></v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.voucherTotal="{ item }">
        {{ item.data.summary.netTotal | currencyFormat(item.data.summary.currency) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{moment(item.date).format('l')}}
      </template>
      <template v-slot:item.dueDate="{ item }">
        {{item.data.condition === '02' ? moment(item.data.date).add(Number(item.data.creditTerm), 'days').format('l') : moment(item.datetime).format('l')}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-row v-if="loading" align="center" justify="center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <div class="d-flex flex-row" v-else>
          <v-tooltip bottom v-for="(option, index) in items" :key="index">
            <template v-slot:activator="{ on, attrs }" v-if="!(option.text === 'Descargar Respuesta Hacienda' && item.data.documentType === '10')">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-1"
                :color="option.color || 'info'"
                @click.stop="option.text === 'Realizar pago' ? newPayVoucher(item) : option.callback(item)"
                :disabled="(option.text === 'Realizar pago' && (Number(item.amountOutstanding) <= 0 || item.deleted || !item.isVoucherSale)) || (option.text === 'Descargar Respuesta Hacienda' && (item.data.documentType === '11' || item.data.documentType === '10'))"
              >
                {{ option.icon }}
              </v-icon>
            </template>
            <span>{{ option.text }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        {{vouchersType[item.data.documentType]}}
      </template>
      <template v-slot:item.status="{ item }">
        <span :class="['error--text']" v-if="item.deleted">Anulada</span>
        <span v-else-if="['02', '03', '11'].includes(item.data.documentType)">N/A</span>
        <span :class="[item.collected ? 'success--text' : 'error--text']" v-else>{{item.collected ? 'Cobrada' : 'Por Cobrar'}}</span>
      </template>
      <template v-slot:item.treasuryMessage="{ item }">
        <span v-if="item.data.documentType === '11' || item.data.documentType === '10'">N/A</span>
        <span v-else>{{item.treasuryMessage}}</span>
      </template>
      <template v-slot:item.datetime="{ item }">
        {{getDateString(item.date)}}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import store from '@/store/index.js'
import router from '@/router/index.js'
import tools from '@/api/tools.js'
import redaApp from '@/api/redaApp.js'
// import axios from 'axios'
import moment from 'moment'
import vex from 'vex-js'
import printer from '@/api/printer.js'
import NewReceipt from '@/components/NewReceipt.vue'
import Period from '@/components/Period.vue'
import ImportData from '@/components/ImportData.vue'
import vexDialog from 'vex-dialog'

// Main css
import 'vex-js/dist/css/vex.css'

// Themes (Import all themes you want to use here)
import 'vex-js/dist/css/vex-theme-default.css'
import 'vex-js/dist/css/vex-theme-os.css'
import axios from 'axios'

// Options
vex.defaultOptions.className = 'vex-theme-default'

// Register vex-dialog
vex.registerPlugin(vexDialog)
// const smalltalk = require('smalltalk');

export default {
  name: 'Vouchers',
  components: { NewReceipt, Period, ImportData },
  data: () => ({
    // showTable: false,
    uploadingVoucher: false,
    electronicVoucher: false,
    moment,
    search: null,
    status: {
      1: 'Aceptado',
      2: 'Aceptación parcial',
      3: 'Rechazado',
      0: 'Enviado'
    },
    tmp: {
      voucher: null,
      amountOutstanding: null
    },
    vouchersType: {
      '01': 'Factura electrónica',
      '02': 'Nota de débito',
      '03': 'Nota de crédito',
      '04': 'Tiquete',
      10: 'Factura de venta',
      11: 'Factura proforma'
    },
    dialog: false,
    headersExcel: [
      { text: 'Fecha', value: 'date', type: 'date' },
      { text: 'Consecutivo', value: 'data.sequence' },
      {
        text: 'Tipo',
        value: 'data.documentType',
        enum: {
          '01': 'Factura',
          '02': 'Nota Débito',
          '03': 'Nota Crédito',
          '04': 'Tiquete',
          10: 'Factura de Venta'
        }
      },
      { text: 'Cliente', value: 'data.receiver.name' },
      { text: 'I.V.A Colones', value: 'totalVouchersTaxCRC', type: 'number' },
      { text: 'Total Colones', value: 'totalVouchersCRC', type: 'number' },
      { text: 'Moneda', value: 'data.summary.currency' },
      { text: 'Estado', value: 'treasuryMessage' }
    ],
    itemsTable: [],
    currentVoucher: null,
    renderPDFhtml: false,
    showHtml: false,
    loading: false,
    loadingText: 'Cargando... Por favor espere'
  }),

  computed: {

    items () {
      return [
        { text: 'Ver Comprobante', callback (item) { router.push('/voucher/' + item.key) }, icon: 'mdi-information' },
        {
          text: 'Realizar pago',
          icon: 'mdi-cash-plus'
        },
        // { text: 'XML', callback (item) { store.dispatch('vouchers/downloadXmlFE', { key: item.key, xml: 'xml' }).catch(error => { store.dispatch('showError', error) }) }, icon: 'fa-file-code' },
        // { text: 'Respuesta Hacienda', callback (item) { if (item.treasuryMessage === '0') { store.dispatch('showInfo', 'El Comprobante no tiene respuesta de Hacienda todavía') } else { store.dispatch('vouchers/downloadXmlFE', { key: item.key, xml: 'mh' }) } }, icon: 'fa-clipboard-check' },
        {
          text: 'Descargar pdf',
          callback: (item) => {
            this.loading = true
            store.dispatch('vouchers/downloadPdf', item.key)
              .catch((error) => console.error(error))
              .finally(() => {
                this.loading = false
              })
          },
          icon: 'mdi-file-download'
        },
        {
          text: 'Descargar xml',
          callback: (item) => {
            this.loading = true
            store.dispatch('vouchers/downloadDoc', { path: `xml/${item.key}.xml`, fileName: `FE-${item.key}` })
              .catch((error) => console.error(error))
              .finally(() => {
                this.loading = false
              })
          },
          icon: 'mdi-file-code'
        },
        {
          text: 'Descargar Respuesta Hacienda',
          callback: (item) => {
            this.loading = true
            store.dispatch('vouchers/downloadDoc', { path: `mh/${item.key}.xml`, fileName: `MH-${item.key}` })
              .catch((error) => console.error(error))
              .finally(() => {
                this.loading = false
              })
          },
          icon: 'mdi-file-code-outline'
        },
        /* {
        text: 'Nota de Crédito',
        callback (item) {
          store.commit('setSendingVoucher', true)
          store.dispatch('submitNDNC', { type: '03', action: '01', reason: 'Error en los montos', voucher: item })
            .then(() => {
              store.dispatch('showSuccess', 'El Comprobante ' + item.key + ' fue emitido correctamente')
            })
            .catch(error => {
              console.error(error)
              store.dispatch('showError', 'Error al emitir comprobante')
            })
            .finally(() => {
              store.commit('setSendingVoucher', false)
            })
        },
        icon: 'fa-file-alt'
      }, /* */
        /* {
        text: 'Nota de Débito',
        callback (item) {
          store.commit('setSendingVoucher', true)
          store.dispatch('submitNDNC', { type: '02', action: '01', reason: 'Error en los montos', voucher: item })
            .then(() => {
              store.dispatch('showSuccess', 'El Comprobante ' + item.key + ' fue emitido correctamente')
            })
            .catch(error => {
              console.error(error)
              store.dispatch('showError', 'Error al emitir comprobante')
            })
            .finally(() => {
              store.commit('setSendingVoucher', false)
            })
        },
        icon: 'fa-file'
      },/* */
        {
          text: 'Reenviar Correo',
          callback: (item) => {
            vex.dialog.open({
              message: 'Reenviar correo electrónico',
              input: [
                '<input name="to" type="email" placeholder="Enviar a..." required />',
                '<input name="cc" type="email" placeholder="Con copia a..." />'
              ].join(''),
              buttons: [
                { ...vex.dialog.buttons.YES, text: 'Enviar' },
                { ...vex.dialog.buttons.NO, text: 'Cancelar' }
              ],
              callback: (data) => {
                if (!data) {
                  return
                }
                const payload = {
                  to: data.to,
                  cc: data.cc,
                  key: item.key
                }
                this.loading = true
                redaApp.sendEmailVoucher(payload)
                  .then(() => store.dispatch('message/showSuccess', 'Comprobante reenviado a ' + data.to))
                  .catch((error) => {
                    store.dispatch('message/showError', 'Error al reenviar correo')
                    console.error(error)
                  })
                  .finally(() => {
                    this.loading = false
                  })
              }/* ,
              callback: function (value) {
                store.commit('setSendingVoucher', true)
                const payload = {
                  dest: value,
                  key: item.key
                }
                console.log(payload)
                axios.post('https://us-central1-facturacionelectronica-387a7.cloudfunctions.net/api/sendMail', payload)
                  .then(() => {
                    store.commit('setSendingVoucher', false)
                    // alert('Correo electrónico enviado');
                  })
                  .catch(error => {
                    console.log(error)
                    store.commit('setSendingVoucher', false)
                    // alert('El correo electrónico no pudó ser enviado');
                  })
              } */
            })
          },
          icon: 'mdi-email-fast'
        },
        { text: 'Imprimir Comprobante', callback (item) { printer.printVoucher(item.data, item.receipts); console.log(item) }, icon: 'mdi-printer' }
      ].filter((data) => !!data)
    },

    qr () {
      return 'https://chart.googleapis.com/chart?cht=qr&chs=100x100&chl=' + encodeURI('http//fe.redaBits.com/getxml.php?clave=' + this.currentVoucher.key)
    },

    headers () {
      return [
        { text: 'Fecha de emisión', value: 'date' },
        {
          text: 'Consecutivo',
          sortable: false,
          value: 'data.sequence'
        },
        { text: 'Tipo', value: 'type' },
        { text: 'Cliente', value: 'data.receiver.name' },
        { text: 'Total', value: 'voucherTotal', align: 'end' },
        // { text: 'Pendiente', value: 'amountOutstanding' },
        // { text: 'Vencimiento', value: 'expirationDate' },
        { text: 'Estado', value: 'status' },
        (this.electronicVoucher ? { text: 'Estado FE', value: 'treasuryMessage' } : undefined),
        (this.issueBy.length > 1 ? { text: 'Emitido por', value: 'user.name' } : undefined),
        { text: 'Acciones', value: 'action', sortable: false }
      ]
        .filter((dato) => dato !== undefined)
    },

    issueBy () {
      const issueBy = []
      this.voucherItems.forEach((voucher, i) => {
        if (voucher.user) issueBy.push(voucher.user.name)
      })
      return issueBy.filter((item, i) => issueBy.indexOf(item) === i)
    },

    voucherItems () {
      const types = {
        '01': true,
        '02': true,
        '03': true,
        '04': true,
        '05': false,
        '06': false,
        '07': false,
        '08': false,
        '09': true,
        10: true,
        11: true
      }
      return this.getVouchers.filter((item) => this.from < item.date && item.date < this.to && types[item.data.documentType])
    },

    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    ...mapState(['from', 'to']),
    ...mapState('vouchers', ['loadingVouchers']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('vouchers', ['getVouchers'])

  },

  created () {
    this.getElectronicVoucher()
      .then(electronicVoucher => {
        this.electronicVoucher = electronicVoucher
      })
      .catch(error => {
        console.log(error)
      })
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    clickUpload () {
      this.$refs.xml.click()
    },
    handleFileUpload () {
      this.uploadingVoucher = true
      const xmlFile = this.$refs.xml.files[0]
      // this.certName = this.$refs.file.files[0].name;
      if (xmlFile.type !== 'text/xml' && xmlFile.type !== 'application/xml') {
        alert('El archivo debe ser un XML')
        return
      }
      const formData = new FormData()
      formData.append('xml', xmlFile)
      axios.post(process.env.VUE_APP_API_BASE_URL + '/api/upload-xml-voucher', formData)
        .then(() => {
          return this.showSuccess('Comprobante agragado.')
        })
        .catch(error => {
          console.error(error)
          return this.showError(error)
        })
        .finally(() => {
          this.uploadingVoucher = false
        })
      // console.log(document.getElementById('input-285'));
    },
    close () {
      this.dialog = false
    },
    newPayVoucher (item) {
      /* eslint-disable */
      this.tmp.voucher = { ...item }
      this.tmp.amountOutstanding = item.amountOutstanding
      this.tmp.currency = item.data.summary.currency
      this.dialog = true
      /* eslint-disable */
    },

    downloadExcel () {
      tools.table2excel(this.voucherItems, this.headersExcel, 'Comprobantes')
    },

    getDateString (dateTime) {
      return moment(dateTime).format()
    },

    downloadXML (voucher) {
      this.downloadXmlFE({ key: voucher.key, xml: 'xml' })
    },

    downloadMH () {
      console.log('downloadMH')
    },

    downloadPDF () {
      console.log('downloadPDF')
    },

    submitNC () {
      console.log('submitNC')
    },

    submitND () {
      console.log('submitND')
    },

    ...mapActions(['submitNDNC']),
    ...mapActions('company', [
      'getElectronicVoucher'
    ]),
    ...mapMutations(['setSendingVoucher'])

  }
}
</script>
<style type="text/css">

</style>
