import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import invoice from '@/store/modules/invoice.js'
import vuetify from './plugins/vuetify'
import RedaForm from '@/components/RedaForm.vue'
import RedaDisplay from '@/components/RedaDisplay.vue'
import DataTableCRUD from '@/components/DataTableCRUD.vue'
import DisplayObject from '@/components/DisplayObject.vue'
import numberPos from '@/api/numberPos.js'
import Highcharts from 'highcharts'
import Stock from 'highcharts/modules/stock'
import Gantt from 'highcharts/modules/gantt'
import accessibility from 'highcharts/modules/accessibility'
import exportingInit from 'highcharts/modules/exporting'
import HighchartsVue from 'highcharts-vue'
import { auth } from '@/api/firebaseApi.js'
import apiCE from './api/apiCE'
import redaApp from './api/redaApp'
import moment from 'moment'
import { rpp } from './api/redaPosPrinter'

(async () => {
  const miStorage = window.localStorage
  // window.fbAsyncInit = function () {
  //   FB.init({
  //     appId: '749510166715934',
  //     cookie: true, // Enable cookies to allow the server to access the session.
  //     xfbml: true, // Parse social plugins on this webpage.
  //     version: '17' // Use this Graph API version for this call.
  //   })

  //   FB.getLoginStatus(function (response) { // Called after the JS SDK has been initialized.
  //     fb.statusChangeCallback(response) // Returns the login status.
  //   })
  // }

  if (auth.isSignInWithEmailLink(window.location.href)) {
    const values = window.location.search
    const urlParams = new URLSearchParams(values)
    const userUID = urlParams.get('uid')
    const { email } = await redaApp.getUserByUID(userUID)
    auth.signInWithEmailLink(email, window.location.href)
      .catch((error) => console.error(error))
  }
  rpp.itsRunning().then((running) => miStorage.setItem('redaPrinterItsRunning', running)).catch((e) => console.error(e))
  Highcharts.setOptions({
    lang: {
      // Textos de la interfaz
      decimalPoint: ',',
      thousandsSep: '.',
      loading: 'Cargando...',
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      exportButtonTitle: 'Exportar',
      printButtonTitle: 'Imprimir',
      rangeSelectorFrom: 'Desde',
      rangeSelectorTo: 'Hasta',
      rangeSelectorZoom: 'Zoom',
      downloadPNG: 'Descargar imagen PNG',
      downloadJPEG: 'Descargar imagen JPEG',
      downloadPDF: 'Descargar documento PDF',
      downloadSVG: 'Descargar imagen SVG',
      resetZoom: 'Restablecer zoom',
      resetZoomTitle: 'Restablecer nivel de zoom 1:1',
      contextButtonTitle: 'Menú contextual',
      printChart: 'Imprimir gráfico',
      drillUpText: 'Volver a {series.name}'
    },
    // Formato de números
    numberFormat: {
      decimalPoint: ',',
      thousandsSep: '.'
    }
  })

  Stock(Highcharts)
  Gantt(Highcharts)
  exportingInit(Highcharts)
  accessibility(Highcharts)

  Vue.component('RedaForm', RedaForm)
  Vue.component('RedaDisplay', RedaDisplay)
  Vue.component('DataTableCRUD', DataTableCRUD)
  Vue.component('DisplayObject', DisplayObject)

  Vue.use(HighchartsVue)

  Vue.config.productionTip = false

  numberPos.forEach((item) => {
    store.registerModule(item, invoice)
  })

  if (router.currentRoute.name !== 'Public') {
    apiCE.getDollarExcachange().then(({ sell }) => store.commit('setUSD', sell))
      .catch((error) => console.error(error))
    apiCE.getDollarHistoryExcachange(Number(moment('2018-01-01').format('x'))).then((res) => store.commit('setUSDHistory', res))
      .catch((error) => console.error(error))
    auth.onAuthStateChanged(async (userObj) => {
      store.commit('setLoadingApp', true)
      if (userObj) {
        await store.dispatch('user/init', userObj)
        if (router.currentRoute.name !== 'Public') {
          router.push('/').catch(() => {}).finally(() => store.commit('setLoadingApp', false))
        } else {
          store.commit('setLoadingApp', false)
        }
      } else {
        await store.dispatch('user/reset')
        store.commit('setLoadingApp', false)
        // router.push('/login').catch(() => {}).finally(() => store.commit('setLoadingApp', false))
      }
    })
  }

  Vue.filter('currencyFormat', function (value, currency = 'CRC') {
    const formatNumber = currency === 'CRC' ? 'es-CR' : 'es-US'
    if (isNaN(Number(value))) return value
    const numberFormat = new Intl.NumberFormat(formatNumber, { style: 'currency', currency })
    if (!value) return numberFormat.format(0)
    return numberFormat.format(value)
  })

  Vue.filter('numberFormat', function (value, currency = 'CRC') {
    if (isNaN(Number(value))) return value
    const numberFormat = new Intl.NumberFormat('es-CR', { maximumFractionDigits: 2 })
    if (!value) return numberFormat.format(0)
    return numberFormat.format(value)
  })

  Vue.filter('capitalizeWords', function (str) {
    return str.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }).join(' ')
  })

  Vue.filter('dateFormat', function (date) {
    if (!date || date === '') return date
    return moment(date, 'x').format('L')
  })

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})()
