var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.getOrders,"item-key":"uid","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Pedidos")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-3 py-2 mr-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(false)?_c('v-btn',{on:{"click":_vm.downloadExcel}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):_vm._e()],1)]},proxy:true},{key:"item.products",fn:function(ref){
var item = ref.item;
return _vm._l((item.items),function(product,i){return _c('div',{key:i},[_vm._v(_vm._s(product.product.description)+": "+_vm._s(product.quantity)+" "+_vm._s(product.product.unit))])})}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created))+" ")]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.deadline))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalizeWords")(item.customer.name))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.statusObj[item.status].color}},[_vm._v(" "+_vm._s(_vm.statusObj[item.status].text)+" ")]):_vm._e()]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.priorityObj[item.priority].color}},[_vm._v(" "+_vm._s(_vm.priorityObj[item.priority].text)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('OrderDialog',{attrs:{"item":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }