<template>
  <v-container>
    <RedaTableView moduleName="subscriptions"></RedaTableView>
  </v-container>
</template>

<script>
import { /* mapState, mapActions, /* */ mapGetters } from 'vuex'
import RedaTableView from '@/components/RedaTableView.vue'

export default {
  name: 'Subscriptions',
  components: { RedaTableView },
  data: () => ({
  }),
  methods: {},
  computed: {
    getThisStaff () {
      return this.staff.find(({ id }) => this.staff === id)
    },
    ...mapGetters('company', ['getStaff'])
  }
}
</script>
