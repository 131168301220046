<template>
  <v-container>
    <v-alert type="success" v-model="showSuccess" transition="fade-transition" dismissible>
      ¡Cuenta creada con exito!
    </v-alert>
    <v-alert type="error" v-model="showError" transition="fade-transition" dismissible>
      ¡La cuenta no pudo ser creada!
    </v-alert>
    <v-row v-if="!success">
      <v-col cols="12" class="title mt-4" align="center" justify="center">Registrarse en <v-img max-width="100" :src="logo"></v-img></v-col>
    </v-row>
    <v-row v-if="!success">
      <v-col cols="12">
        <v-row  align="center" justify="center">
          <v-stepper v-model="e1" vertical elevation="0" v-show="!loading">
              <v-stepper-step :complete="e1 > 1" step="1" @click="e1 = 1">Información del usuario</v-stepper-step>

              <v-stepper-content step="1">

                <ValidationObserver v-slot="{ invalid }">
                  <v-container>
                    <v-form>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider v-slot="{ errors }" name="name" rules="required|max:100">
                            <v-text-field label="Nombre*" :error-messages="errors" v-model="account.name" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider v-slot="{ errors }" name="lastname" rules="required|max:100">
                            <v-text-field label="Apellidos*" :error-messages="errors" v-model="account.lastname" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider v-slot="{ errors }" name="user" rules="required|email">
                            <v-text-field
                              label="Email*"
                              name="user"
                              v-model="account.email"
                              :error-messages="errors"
                              autofocus
                              autocomplete="off"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider v-slot="{ errors }" name="password" rules="required">
                            <v-text-field
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              id="password"
                              label="Contraseña*"
                              name="password"
                              :error-messages="errors"
                              :type="showPassword ? 'text' : 'password'"
                              v-model="account.password"
                              @click:append="showPassword = !showPassword"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider v-slot="{ errors }" name="confirm" rules="required">
                            <v-text-field
                              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                              id="confirm"
                              label="Confirmar contraseña*"
                              name="confirm"
                              :error-messages="errors"
                              :type="showConfirm ? 'text' : 'password'"
                              v-model="account.confirm"
                              @click:append="showConfirm = !showConfirm"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <!--<v-row>
                        <v-col cols="12"  sm="6">
                          <ValidationProvider v-slot="{ errors }" name="phone" rules="digits:8">
                            <v-text-field label="Telefono" v-model="account.phone" :error-messages="errors"></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider v-slot="{ errors }" name="plan" rules="required">
                            <v-select
                            :error-messages="errors"
                            :items="[
                              {value: 'pp01', text: 'Plan Profesional Mensual ₡ 7 910 IVAI' },
                              {value: 'pp02', text: 'Plan Profesional Anual ₡ 79 100 IVAI' },
                              {value: 'pc01', text: 'Plan Comercial Mensual ₡ 31 640 IVAI' },
                              {value: 'pc02', text: 'Plan Comercial Anual ₡ 316 400 IVAI' },
                              {value: 'pe01', text: 'Plan Empresarial Mensual ₡ 63 280 IVAI' },
                              {value: 'pe02', text: 'Plan Empresarial Anual ₡ 632 800 IVAI' },
                            ]"
                            label="Plan*"
                            v-model="account.plan"
                            required
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>-->
                    </v-form>
                  </v-container>

                  <v-btn
                    color="primary"
                    @click="e1 = 2"
                    :disabled="invalid"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="$emit('cancel')">Cancel</v-btn>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-step :complete="e1 > 2" step="2" @click="e1 = 2">Información del Negocio</v-stepper-step>

              <v-stepper-content step="2">

                <ValidationObserver v-slot="{ invalid }">
                  <v-container>
                    <v-form>
                      <v-row>
                        <v-col cols="12" sm="6" v-show="false">
                          <v-select
                          :items="[
                            {value: '01', text: 'Física' },
                            {value: '02', text: 'Jurídica' },
                            {value: '03', text: 'DIMEX' },
                            {value: '04', text: 'NITE' },
                          ]"
                          label="Tipo*"
                          v-model="company.type"
                          readonly
                          required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider v-slot="{ errors }" name="identification" rules="required|numeric">
                            <v-text-field type="text" :error-messages="errors" label="Numero de identificación*" autofocus hint="Sin guiones ni espacios" v-model="company.identification" @change="getById(company.identification)" ref="ident" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>{{documentTipes[company.type]}}</v-list-item-title>
                              <v-list-item-subtitle>Tipo de Cédula</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>{{company.name}}</v-list-item-title>
                              <v-list-item-subtitle>Nombre completo/Razón social</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-text-field  v-show="false" label="Nombre completo/Razón social*" v-model="company.name" readonly required></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="[
                              { text: 'Código', value: 'code' },
                              { text: 'Descripcion', value: 'description' },
                              { text: 'Estado', value: 'state' },
                            ]"
                            :items="company.activities"
                            dense
                            hide-default-footer
                          >
                          <template v-slot:top>
                            <v-subheader>Actividades Económicas</v-subheader>
                          </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider v-slot="{ errors }" name="tradename" rules="max:80">
                            <v-text-field label="Nombre comercial" :error-messages="errors"  :counter="80"  v-model="company.tradename" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12"  sm="6">
                          <ValidationProvider v-slot="{ errors }" name="email" rules="email|required">
                            <v-text-field type="email" label="Email*" :error-messages="errors" v-model="company.email" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12"  sm="6">
                          <ValidationProvider v-slot="{ errors }" name="phone" rules="digits:8|required">
                            <v-text-field label="Teléfono*" :error-messages="errors" v-model="company.phone" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12"  sm="4">
                          <ValidationProvider v-slot="{ errors }" name="province" rules="required">
                            <v-select label="Provincia*" :error-messages="errors" v-model="company.location.province" @change="getCounty" :items="provinces" required></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12"  sm="4">
                          <ValidationProvider v-slot="{ errors }" name="county" rules="required">
                            <v-select label="Cantón*" :error-messages="errors" v-model="company.location.county" :items="counties" @change="getDistrict" :disabled="company.location.province === null" required></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12"  sm="4">
                          <ValidationProvider v-slot="{ errors }" name="district" rules="required">
                            <v-select label="Distrito*" :error-messages="errors" v-model="company.location.district" :items="districts" :disabled="company.location.county === null" required></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider v-slot="{ errors }" name="others" rules="max:250|required">
                            <v-text-field label="Dirección exacta*" :error-messages="errors" :counter="250" v-model="company.location.others" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>

                  <v-btn
                    color="primary"
                    @click="e1 = 3"
                    :disabled="invalid"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="$emit('cancel')">Anterior</v-btn>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-step :complete="e1 > 3" step="3" @click="e1 = 3" v-if="showCert">Comprobantes Electrónicos</v-stepper-step>

              <v-stepper-content step="3" v-if="showCert">

                <ValidationObserver v-slot="{ invalid }">
                  <v-container>
                    <v-form>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider v-slot="{ errors }" name="user" rules="required">
                            <v-text-field label="Usuario para la facturación electrónica*" :error-messages="errors" autofocus  v-model="api.user" :readonly="apiValid" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider v-slot="{ errors }" name="passwordApi" rules="required">
                          <v-text-field
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            id="passwordApi"
                            label="Contraseña para la facturación electrónica*"
                            :error-messages="errors"
                            name="passwordApi"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="api.password"
                            @click:append="showPassword = !showPassword"
                            :readonly="apiValid"
                            required
                          ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <ValidationProvider name="apiValid" rules="required">
                          <v-checkbox v-model="apiValid" style="display:none"></v-checkbox>
                        </ValidationProvider>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-btn
                            color="secondary"
                            @click="validateApi"
                            v-show="!apiValid"
                          >
                            Verificar Usuario
                          </v-btn>
                          <v-btn
                            @click="apiValid = false"
                            v-show="apiValid"
                          >
                            Editar Usuario
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-container class="success--text text-left" v-if="apiValid">Usuario verificado</v-container>
                          <v-container class="error--text text-left" v-if="apiError">{{apiError}}</v-container>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="8">
                          <ValidationProvider v-slot="{ errors }" name="Llave Crptógrafica" rules="required">
                            <v-text-field label="Llave Criptográfica*" prepend-icon="attach_file" :error-messages="errors" v-model="certName" @click="clickUpload" :clearable="!certValid" required readonly></v-text-field>
                          </ValidationProvider>
                          <ValidationProvider v-slot="{ errors, validate }" name="certName" rules="required|mimes:application/x-pkcs12">
                            <input type="file" ref="file" accept="application/x-pkcs12" @change="handleFileUpload" @input="validate" hidden>
                            <!--<v-text-field label="Llave Criptográfica*" prepend-icon="attach_file" v-model="certName" @focus="clickUpload" required></v-text-field>-->
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ValidationProvider v-slot="{ errors }" name="pin" rules="required|digits:4">
                            <v-text-field label="Pin*" :error-messages="errors" v-model="api.pin" :readonly="certValid" required></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <ValidationProvider name="certName" rules="required|mimes:application/x-pkcs12">
                        </ValidationProvider>
                        <ValidationProvider name="certValid" rules="required">
                          <v-checkbox v-model="certValid" style="display:none"></v-checkbox>
                        </ValidationProvider>
                        <ValidationProvider name="" rules="required">
                          <v-radio-group
                            v-model="environment"
                            row
                          >
                            <v-radio
                              label="Pruebas"
                              value="stag"
                            ></v-radio>
                            <v-radio
                              label="Producción"
                              value="prod"
                            ></v-radio>
                          </v-radio-group>
                        </ValidationProvider>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-btn
                            color="secondary"
                            @click="checkcert"
                            v-show="!certValid"
                          >
                            Verificar Llave
                          </v-btn>
                          <v-btn
                            @click="certValid = false"
                            v-show="certValid"
                          >
                            Editar Llave
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-container class="success--text text-left" v-if="certValid">Llave verificada</v-container>
                          <v-container class="error--text text-left" v-if="certError">{{certError}}</v-container>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-checkbox v-model="previousSystem" label="Seleccione si ha facturado anteriormente con otro sistema" v-show="false"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>

                  <v-btn
                    color="primary"
                    @click="e1 = e1 + 1"
                    :disabled="invalid"
                  >
                    Continuar
                  </v-btn>

                  <v-btn text @click="$emit('cancel')">Anterior</v-btn>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-step :step="showCert ? 4 : 3" @click="e1 = showCert ? 4 : 3">Elegir plan</v-stepper-step>

              <v-stepper-content :step="showCert ? 4 : 3">
                <v-row>
                  <v-col cols="12">
                    <p v-if="false">Frecuencia de pago</p>
                    <v-btn-toggle v-model="annuity" dense v-if="false">
                      <v-btn>
                        Mensual
                      </v-btn>
                      <v-btn>
                        Anual
                      </v-btn>
                    </v-btn-toggle>
                    <v-list>
                      <v-list-item-group
                        v-model="plan"
                      >
                        <template v-for="(item, i) in plans">
                          <v-divider
                            v-if="!item"
                            :key="`divider-${i}`"
                          ></v-divider>

                          <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :value="item"
                          >
                            <template v-slot:default="{ active }">
                              <v-list-item-icon v-if="active">
                                <v-icon v-text="'mdi-check-circle'" color="primary" v-if="active"></v-icon>
                              </v-list-item-icon>
                              <v-list-item-action v-else>
                                <v-radio></v-radio>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.content"></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action v-if="false">
                                <v-list-item-action-text v-text="'¡Te regaramos dos meses!'" v-if="annuity" class="success--text"></v-list-item-action-text>
                                {{ annuity ? item.annuityPayment : item.monthlyPayment }}
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>

                <v-btn
                  color="primary"
                  @click="e1 = e1 + 1"
                  :disabled="!plan"
                >
                  Continuar
                </v-btn>

                <v-btn text @click="$emit('cancel')">Anterior</v-btn>
              </v-stepper-content>

              <v-stepper-step :step="showCert ? 5 : 4" @click="e1 = showCert ? 5 : 4">Términos y Condiciones</v-stepper-step>

              <v-stepper-content :step="showCert ? 5 : 4">
                  <v-container fluid v-if="false">
                    <CreditCard ref="card" :hideSubmit="true"></CreditCard>
                  </v-container>

                  <v-container fluid>
                    <a :href="termsURL" target="_blank">Terminos y condiciones de servicio de redaBits</a>
                    <v-checkbox v-model="agree" label="Acepto los terminos y condiciones"></v-checkbox>
                  </v-container>

                <v-btn
                  :disabled="!agree/* || !cardValid */"
                  color="primary"
                  @click="logUp"
                >
                  Crear
                </v-btn>

                <v-btn text @click="$emit('cancel')">Cancel</v-btn>
              </v-stepper-content>
          </v-stepper>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            v-show="loading"
          ></v-progress-circular>
        </v-row>
      </v-col>
    </v-row>
    <v-row  align="center" justify="center" class="my-auto" v-if="success">
      <v-card align="center" justify="center">
        <v-img
        ><v-icon size="100px"
        >mdi-check-circle</v-icon></v-img>
        <v-card-title class="headline">Cuenta creada</v-card-title>
        <v-card-text>
          <v-alert type="success">
            ¡Su cuenta se ha creado exitosamente!
          </v-alert>
          <div>
            <p>Para ingresar dé clic en "Finalizar"</p>
          </div>
        </v-card-text>
        <v-card-actions><v-btn color="primary" :href="site" block x-large>Finalizar</v-btn></v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
import { mapActions } from 'vuex'
import axios from 'axios'
import logoRedaBits from '@/assets/logoBlack.svg'
// import api from '@/api/firebaseApi.js'
import CreditCard from '@/components/CreditCard'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    }
  },
  computesRequired: true
})

export default {
  name: 'SignUp',
  components: {
    ValidationProvider,
    ValidationObserver,
    CreditCard
  },
  mounted () {
    this.getProvince()
  },
  data () {
    return {
      e1: 1,
      logo: logoRedaBits,
      showCert: false,
      annuity: 0,
      plan: null,
      plans: [
        { id: 1, title: 'Esencial', content: 'Pensado para negocios del sector servicios', monthlyPayment: '₡ 7 910 IVAI', annuityPayment: '₡ 79 100 IVAI' },
        { id: 2, title: 'Profesional', content: 'Pensado para tiendas y negocios detallistas', monthlyPayment: '₡ 22 600 IVAI', annuityPayment: '₡ 226 000 IVAI' },
        { id: 3, title: 'Master Empresarial', content: 'Pensado para negocios productivos (agricola, industrial, alimentos y bebidas, etc.)', monthlyPayment: '₡ 45 200 IVAI', annuityPayment: '₡ 452 000 IVAI' }
      ],
      paymentMethod: 'card',
      paymentMethods: {
        card: {},
        sinpe: {}
      },
      documentTipes: {
        '01': 'Física',
        '02': 'Jurídica',
        '03': 'DIMEX',
        '04': 'NITE'
      },
      showSuccess: false,
      showError: false,
      showConfirm: false,
      showPassword: false,
      certName: null,
      apiValid: false,
      certValid: false,
      certError: '',
      apiError: '',
      previousSystem: true,
      loading: false,
      account: {
        email: null,
        password: null,
        confirm: null,
        plan: null,
        name: null,
        lastname: null,
        phone: null
      },
      company: {
        type: null,
        identification: null,
        name: null,
        tradename: null,
        email: null,
        phone: null,
        regimen: null,
        location: {
          province: null,
          county: null,
          district: null,
          others: null
        },
        activities: []
      },
      api: {
        user: null,
        password: null,
        certFile: null,
        certPath: null,
        pin: null,
        environment: 'stag'
      },
      environment: 'stag',
      agree: false,
      provinces: [],
      counties: [],
      districts: []
    }
  },
  methods: {
    focusIdent () {
      return new Promise((resolve) => {
        this.$refs.ident.focus()
        resolve()
      })
    },
    checkcert () {
      const formData = new FormData()
      formData.append('pin', this.api.pin)
      formData.append('certFile', this.api.certFile)
      axios.post(
        'https://us-central1-facturacionelectronica-387a7.cloudfunctions.net/api/cert',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(() => {
          this.certValid = true
          this.certError = ''
        })
        .catch(error => {
          this.certError = 'Llave Criptográfica o el pin invalido'
          console.error(error)
        })
    },
    validateApi () {
      const data = {}
      data.user = this.api.user
      data.password = this.api.password
      data.environment = this.environment
      console.log(data)
      axios.post(
        'https://us-central1-facturacionelectronica-387a7.cloudfunctions.net/api/validateApi',
        data
      )
        .then(() => {
          this.apiError = ''
          this.apiValid = true
        })
        .catch(error => {
          this.apiError = 'Usuario o contraseña invalida'
          console.error(error)
        })
    },
    loadingStag () {
      this.loading = true
      setTimeout(() => (this.loading = false), 3000)
    },
    clickUpload () {
      if (this.certValid) { return }
      this.$refs.file.click()
    },
    handleFileUpload () {
      this.api.certFile = this.$refs.file.files[0]
      this.certName = this.$refs.file.files[0].name
      // console.log(document.getElementById('input-285'));
    },
    getById () {
      axios
        .get('https://api.hacienda.go.cr/fe/ae?identificacion=' + this.company.identification)
        .then(response => {
          this.company.activities = []
          // console.log(response.data)
          // var receiver = {id: id, typeId: response.data.tipoIdentificacion, name: response.data.nombre}
          this.company.type = response.data.tipoIdentificacion
          this.company.name = response.data.nombre
          this.company.regimen = response.data.regimen
          response.data.actividades.forEach(activities => {
            this.company.activities.push({
              code: activities.codigo,
              description: activities.descripcion,
              state: activities.estado,
              type: activities.tipo
            })
          })
        })
    },
    logUp () {
      this.loading = true
      const account = {
        email: this.account.email,
        password: this.account.password,
        fullName: this.fullName
      }
      const company = { ...this.company }
      const card = this.cardPayload ? { ...this.cardPayload } : null
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/signup`,
        { account, company, card, subscriptionPlan: this.subscriptionPlan }
      )
        .then(() => {
          this.showSuccess = true
          this.success = true
        })
        .catch(error => {
          this.showError = true
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getProvince () {
      axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
        .then(response => {
          const provinces = []
          const entries = Object.entries(response.data)
          for (const [key, value] of entries) {
            provinces.push({
              text: value,
              value: String(key)
            })
          }
          this.provinces = provinces
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCounty () {
      axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.company.location.province + '/cantones.json')
        .then(response => {
          const counties = []
          const entries = Object.entries(response.data)
          for (const [key, value] of entries) {
            counties.push({
              text: value,
              value: String(key)
            })
          }
          this.counties = counties
        })
        .catch(error => {
          console.log(error)
        })
    },
    getDistrict () {
      axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.company.location.province + '/canton/' + this.company.location.county + '/distritos.json')
        .then(response => {
          const districts = []
          const entries = Object.entries(response.data)
          for (const [key, value] of entries) {
            districts.push({
              text: value,
              value: String(key)
            })
          }
          this.districts = districts
        })
        .catch(error => {
          console.log(error)
        })
    },
    ...mapActions('user', [
      'signIn'
    ])
  },
  watch: {
    certName: (/* val, oldVal/**/) => {
      this.api.certFile = (this.certName === null || this.certName === '') ? null : this.api.certFile
      console.log(this.api.certFile)
    },
    e1 () {
      if (this.e1 === 2) {
        setTimeout(() => { this.focusIdent() }, 200)
      }
    }
  },
  computed: {
    site () {
      return process.env.VUE_APP_WEB_BASE_URL + '/login'
    },
    cardPayload () {
      return this.$refs.card?.payload
    },
    cardValid () {
      // Validar que todos los campos estén completos
      return this.$refs.card?.formIsValid
    },
    subscriptionPlan () {
      const plans = {
        0: {
          1: 'pm01',
          2: 'pm02',
          3: 'pm03'
        },
        1: {
          1: 'pa01',
          2: 'pa02',
          3: 'pa03'
        }
      }
      return this.plan ? { code: plans[this.annuity][this.plan.id], frequency: this.annuity } : null
    },
    termsURL () {
      return 'https://app.redabitscr.com/public/terms'
    },
    /* certFileNull () {
      this.api.certFile = (this.certName == null || this.certName == '') ? null : this.api.certFile
      console.log(this.api.certFile)
    },/**/
    fullName () {
      return this.account.name + ' ' + this.account.lastname
    }
  }
}
</script>
<style type="text/css">

</style>
