var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getLedger,"item-key":"code","sort-by":['code'],"hide-default-footer":"","items-per-page":_vm.getLedger.length,"dense":""},scopedSlots:_vm._u([{key:"item.previousBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.previousBalance))+" ")]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.debit))+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.credit))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.balance))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Balance de comprobación")]),_c('v-spacer')],1)]},proxy:true},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_vm._v("Total")]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.total.debit))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.total.credit))+" ")]),_c('td')])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }