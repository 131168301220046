<template>
  <v-card>
    <v-tabs
      v-model="tab"
    >
      <v-tab>Resultados</v-tab>
      <v-tab>Situación financiera</v-tab>
      <v-tab>Flujos de efectivo</v-tab>
      <v-tab>Cambios en el patrimonio</v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-card-text v-if="false">
      <v-btn-toggle v-model="display">
        <v-btn>
          <v-icon>mdi-table</v-icon>
        </v-btn>

        <v-btn>
          <v-icon>mdi-chart-line</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-text>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <IncomeStatement v-if="display === 0"></IncomeStatement>
        <Chart v-else :data="getIncomeStatement.series" title="Histórico de estado de resultados"></Chart>
      </v-tab-item>
      <v-tab-item>
        <StatementFinancialPosition v-if="display === 0"></StatementFinancialPosition>
        <Chart v-else :data="getStatementOfFinancialPosition.series" title="Histórico de estado de situación financiera"></Chart>
      </v-tab-item>
      <v-tab-item>
        <StatementOfCashFlows v-if="display === 0"></StatementOfCashFlows>
        <Chart v-else :data="getStatementOfCashFlows.series" title="Histórico de estado de flujos de efectivo"></Chart>
      </v-tab-item>
      <v-tab-item>
        <StatementOfChangesInEquity v-if="display === 0"></StatementOfChangesInEquity>
        <Chart v-else :data="getStatementOfChangesInEquity.series" title="Histórico de estado de cambios en el patrimonio"></Chart>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import IncomeStatement from '@/components/IncomeStatement'
import StatementFinancialPosition from '@/components/StatementFinancialPosition'
import StatementOfCashFlows from '@/components/StatementOfCashFlows'
import StatementOfChangesInEquity from '@/components/StatementOfChangesInEquity'
import Chart from '@/components/Chart'
export default {
  components: {
    IncomeStatement,
    StatementFinancialPosition,
    StatementOfCashFlows,
    StatementOfChangesInEquity,
    Chart
  },
  data: () => ({
    tab: 0,
    display: 0
  }),

  computed: {
    ...mapGetters('accounting', ['getIncomeStatement', 'getStatementOfFinancialPosition', 'getStatementOfCashFlows', 'getStatementOfChangesInEquity'])
  },

  methods: {
    ...mapMutations([]),
    ...mapActions([])
  }
}
</script>
