<template>
  <div>
    <highcharts :constructorType="'ganttChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
// import moment from 'moment'
import { mapGetters } from 'vuex'
// import tools from '../api/tools'

export default {
  props: ['data', 'title', 'columns'],
  data () {
    return {
    }
  },
  computed: {
    chartOptions () {
      return {

        title: {
          text: this.title
        },

        scrollbar: {
          enabled: true
        },

        rangeSelector: {
          enabled: true,
          selected: 0
        },

        navigator: {
          enabled: true,
          liveRedraw: true,
          series: {
            type: 'gantt',
            pointPlacement: 0.5,
            pointPadding: 0.25,
            accessibility: {
              enabled: false
            }
          },
          yAxis: {
            min: 0,
            reversed: true,
            categories: []
          }
        },

        accessibility: {
          point: {
            descriptionFormat: '{#if milestone}' +
            '{name}, milestone for {yCategory} at {x:%Y-%m-%d}.' +
            '{else}' +
            '{name}, assigned to {yCategory} from {x:%Y-%m-%d} to ' +
            '{x2:%Y-%m-%d}.' +
            '{/if}'
          }
        },

        // yAxis: {
        //   type: 'category',
        //   grid: {
        //     enabled: true,
        //     columns: this.columns
        //   }
        // },

        series: this.data,

        exporting: {
          sourceWidth: 1000
        }
      }
    },
    ...mapGetters(['getFrom', 'getTo']),
    // ...mapGetters('accounting', ['getDatesEntries']),
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
