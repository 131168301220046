<template>
  <v-container>
    <Accounts></Accounts>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Accounts from '@/components/Accounts.vue'
// import accounting from '@/api/accounting';
// import { db } from '@/api/firebase';

export default {
  name: 'ChartOfAccounts',
  components: {
    Accounts
  },
  data: () => ({
  }),
  methods: {
    /* setAccountsDefault() {
      accounting.accountsDefault.forEach((obj) => {
        db.collection('accounts').add({
          id: obj.id,
          code: obj.code,
          name: obj.name,
          description: obj.description,
          subaccounts: obj.subaccounts,
          editable: obj.editable,
          company: '114760094',
        })
          .then((docRef) => {
            console.log('Document written with ID: ', docRef.id);
          })
          .catch((error) => {
            console.error('Error adding document: ', error);
          });
      });
    }, /* */
    ...mapActions([]),
    ...mapActions('accounting', [])
  },
  computed: {
    ...mapState([]),
    ...mapState('accounting', []),
    ...mapGetters([]),
    ...mapGetters('accounting', [])
  }
}
</script>
