<template>
  <v-card :loading="loading">
    <v-card-title>Compañia</v-card-title>
    <v-card-text>
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" name="user" rules="required">
                  <v-text-field autocomplete="off" label="Usuario para la facturación electrónica*" :error-messages="errors" autofocus  v-model="api.user" :readonly="apiValid" required></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" name="passwordApi" rules="required">
                  <v-text-field
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    id="passwordApi"
                    label="Contraseña para la facturación electrónica*"
                    :error-messages="errors"
                    name="passwordApi"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="api.password"
                    @click:append="showPassword = !showPassword"
                    :readonly="apiValid"
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <ValidationProvider name="apiValid" rules="required">
                <v-checkbox v-model="apiValid" style="display:none"></v-checkbox>
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-btn
                  color="secondary"
                  @click="validateApi"
                  v-show="!apiValid"
                  :loading="loadingApi"
                >
                  Verificar Usuario
                </v-btn>
                <v-btn
                  @click="apiValid = false"
                  v-show="apiValid"
                >
                  Editar Usuario
                </v-btn>
              </v-col>
              <v-col>
                <v-container class="success--text text-left" v-if="apiValid">Usuario verificado</v-container>
                <v-container class="error--text text-left" v-if="apiError">{{apiError}}</v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8">
                <ValidationProvider v-slot="{ errors }" name="Llave Crptógrafica" rules="required">
                  <v-text-field label="Llave Criptográfica*" prepend-icon="mdi-paperclip" :error-messages="errors" v-model="certName" @click="clickUpload" :clearable="!certValid" required readonly></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors, validate }" name="certName" rules="required|mimes:application/x-pkcs12">
                  <input type="file" ref="file" accept="application/x-pkcs12" @change="handleFileUpload" @input="validate" hidden>
                  <!--<v-text-field label="Llave Criptográfica*" prepend-icon="attach_file" v-model="certName" @focus="clickUpload" required></v-text-field>-->
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="4">
                <ValidationProvider v-slot="{ errors }" name="pin" rules="required|digits:4">
                  <v-text-field label="Pin*" :error-messages="errors" v-model="api.pin" :readonly="certValid" required></v-text-field>
                </ValidationProvider>
              </v-col>
              <ValidationProvider name="certName" rules="required|mimes:application/x-pkcs12">
              </ValidationProvider>
              <ValidationProvider name="certValid" rules="required">
                <v-checkbox v-model="certValid" style="display:none"></v-checkbox>
              </ValidationProvider>
              <ValidationProvider name="" rules="required">
                <v-radio-group
                  v-model="environment"
                  row
                >
                  <v-radio
                    label="Pruebas"
                    value="stag"
                  ></v-radio>
                  <v-radio
                    label="Producción"
                    value="prod"
                  ></v-radio>
                </v-radio-group>
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-btn
                  color="secondary"
                  @click="checkcert"
                  v-show="!certValid"
                  :loading="loadingCert"
                >
                  Verificar Llave
                </v-btn>
                <v-btn
                  @click="certValid = false"
                  v-show="certValid"
                >
                  Editar Llave
                </v-btn>
              </v-col>
              <v-col>
                <v-container class="success--text text-left" v-if="certValid">Llave verificada</v-container>
                <v-container class="error--text text-left" v-if="certError">{{certError}}</v-container>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-btn
          color="primary"
          @click="newApiVouchers"
          :disabled="invalid"
        >
          Guardar
        </v-btn>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
import axios from 'axios'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    }
  },
  computesRequired: true
})

export default {
  name: 'ElectronicVouchers',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
  // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      loading: false,
      loadingCert: false,
      loadingApi: false,
      showCert: false,
      showSuccess: false,
      showError: false,
      showConfirm: false,
      showPassword: false,
      certName: null,
      apiValid: false,
      certValid: false,
      certError: '',
      apiError: '',
      api: {
        user: null,
        password: null,
        certFile: null,
        certPath: null,
        pin: null,
        environment: 'stag'
      },
      defaultApi: {
        user: null,
        password: null,
        certFile: null,
        certPath: null,
        pin: null,
        environment: 'stag'
      },
      environment: 'stag'
    // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
  // Aquí es donde se declararían las funciones computadas de tu componente
    ...mapGetters('company', ['getCompany'])
  },
  watch: {
  // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    checkcert () {
      this.loadingCert = true
      const formData = new FormData()
      formData.append('pin', this.api.pin)
      formData.append('certFile', this.api.certFile)
      axios.post(
      `${'https://us-central1-facturacionelectronica-387a7.cloudfunctions.net'}/api/cert`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      )
        .then(() => {
          this.certValid = true
          this.certError = ''
        })
        .catch(error => {
          this.certError = 'Llave Criptográfica o el pin invalido'
          console.error(error)
        })
        .finally(() => {
          this.loadingCert = false
        })
    },
    validateApi () {
      this.loadingApi = true
      const data = {}
      data.user = this.api.user
      data.password = this.api.password
      data.environment = this.environment
      console.log(data)
      axios.post(
        process.env.VUE_APP_API_BASE_URL + '/api/validateApi',
        data
      )
        .then(() => {
          this.apiError = ''
          this.apiValid = true
        })
        .catch(error => {
          this.apiError = 'Usuario o contraseña invalida'
          console.error(error)
        })
        .finally(() => {
          this.loadingApi = false
        })
    },
    loadingStag () {
      this.loading = true
      setTimeout(() => (this.loading = false), 3000)
    },
    clickUpload () {
      if (this.certValid) { return }
      this.$refs.file.click()
    },
    handleFileUpload () {
      this.api.certFile = this.$refs.file.files[0]
      this.certName = this.$refs.file.files[0].name
    // console.log(document.getElementById('input-285'));
    },
    newApiVouchers () {
      this.loading = true
      const formData = new FormData()
      formData.append('pin', this.api.pin)
      formData.append('certFile', this.api.certFile)
      axios.post(
      `${'https://us-central1-facturacionelectronica-387a7.cloudfunctions.net'}/api/cert`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      )
        .then(data => {
          const cert = data.data
          return this.newCert({ cert, apiCE: this.api, environment: this.environment, company: this.getCompany.identification })
        })
        .then(() => this.clean())
        .catch(error => {
          this.showError = true
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    clean () {
      this.api = { ...this.defaultApi }
    },
    ...mapActions('company', ['newCert'])
  // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
  // Acciones antes de crear el componente
  },
  created () {
  // Acciones al crear el componente
  },
  beforeMount () {
  // Acciones antes de montar el componente
  },
  mounted () {
  // Acciones al montar el componente
  },
  beforeUpdate () {
  // Acciones antes de actualizar el componente
  },
  updated () {
  // Acciones al actualizar el componente
  },
  beforeDestroy () {
  // Acciones antes de destruir el componente
  },
  destroyed () {
  // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
