var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title'),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Detalles de catálogo ")]),_c('v-tab',[_vm._v(" Componentes ")]),_c('v-tab',[_vm._v(" Personalización ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',[(false)?_c('v-card-title',[_vm._v(" Detalles ")]):_vm._e(),_c('v-card-text',[_c('RedaForm',{attrs:{"options":_vm.detailsForm},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_vm._v(" Componentes del producto ")]),_c('v-card-text',[_c('DataTableCRUD',{attrs:{"options":{
              headers: _vm.headers,
              newItemText: 'Agregar producto',
              editItemText: 'Actualizar producto',
              delete: true,
              defaultItem: {
                products: [],
                quantity: 1,
              },
              insert: {
                itemValue: 'commercialCode',
                itemText: 'description',
                items: _vm.products,
                inserts: [
                  { inputKey: 'products', objKey: 'commercialCode' } ],
                filter: function (item, queryText) {
                  var textOne = item.description.toLowerCase()
                  var textTwo = item.commercialCode.toLowerCase()
                  var textThree = String(item.commercialCode).toLowerCase()
                  var searchText = queryText.toLowerCase()

                  return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1 ||
                    textThree.indexOf(searchText) > -1
                }
              },
              form: {
                submitText: 'Guardar',
                showSubmit: true,
                inputs: [
                  {
                    id: 'de01',
                    label: 'Productos',
                    items: _vm.products,
                    name: 'products',
                    value: 'products',
                    type: 'autocomplete',
                    cols: 8,
                    itemText: 'description',
                    itemValue: 'commercialCode',
                    multiple: true,
                    chips: true,
                    deletableChips: true,
                    hideSelected: true
                  },
                  {
                    id: 'de02',
                    label: 'Cantidad',
                    name: 'quantity',
                    value: 'quantity',
                    type: 'number',
                    cols: 4
                  } ]
              }
            },"table":{
              dense: true,
              hideDefaultFooter: true,
            }},model:{value:(_vm.item.itemsOptions),callback:function ($$v) {_vm.$set(_vm.item, "itemsOptions", $$v)},expression:"item.itemsOptions"}})],1)],1)],1),_c('v-tab-item',[_c('customization-product',{attrs:{"product":_vm.product},on:{"updated":function($event){return _vm.$emit('update')}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }